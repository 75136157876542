import { useQuery } from '@tanstack/react-query'

import { experienceKeys, fetchData } from '@/utils'
import { Experience } from '@/features/job-seeker'

interface ExperienceResponse {
  status: string
  message: string
  data: Experience[]
}

export const useApplicantExperience = (userId: string | undefined) => {
  return useQuery<ExperienceResponse>({
    queryKey: experienceKeys.list(userId || ''),
    queryFn: async () => fetchData(`/user/work-experiences/${userId}`),
    enabled: !!userId
  })
}