import { useQuery } from '@tanstack/react-query'

import { fetchData } from '@/utils'
import { Currency } from '@/types'

interface CurrencyResponse {
  status: string
  message: string
  data: Currency[]
}

export const useCurrencies = () => {
  return useQuery<CurrencyResponse>({
    queryKey: ['currencies'],
    queryFn: async () => fetchData('/common/currencies'),
  })
}
