

export const stepsToFindJobs = [
  {
    number: '1',
    step: 'Search for a job',
    description:
      'Create an account and access your saved settings on any device.',
    images: '/assets/search-for-a-job.png',
  },
  {
    number: '2',
    step: 'Apply on our website',
    description:
      "Don't just find jobs. Be found. Put your CV in front of great employers.",
    images: '/assets/apply.png',
  },
  {
    number: '3',
    step: 'Get interview invites',
    description:
      'Your next career move starts here. Choose a Job from thousands of companies.',
    images: '/assets/interview.png',
  },
]
export interface JobDataProps {
  title: string
  description: string
  company: string
  location: string
  employmentType: string
  level: string
  jobLogo: string
  time: string
  deadline: string
  applicants: number
  workType: string
  isAnonymous?: boolean
}

export const companies = [
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597502/scout4job/audrey_s4yzld.png',
    id: 1,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597502/scout4job/voiaj_hdjufo.png',
    id: 2,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597502/scout4job/the_codes_ddrijd.png',
    id: 3,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597502/scout4job/heroldews_f3d7io.png',
    id: 4,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597502/scout4job/tasklyte_emrvfk.png',
    id: 5,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597502/scout4job/melos_ilropd.png',
    id: 6,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597503/scout4job/Ollo_vycewa.png',
    id: 7,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597503/scout4job/siatechlogo_1_sbik83.png',
    id: 8,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597502/scout4job/neurms_tiuerk.png',
    id: 9,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682604067/scout4job/SS_Logo_nrlv1z.png',
    id: 10,
  },
  {
    image:
      'https://res.cloudinary.com/softstructure/image/upload/v1682597845/scout4job/lomoto_azs9z9.png',
    id: 11,
  },
]

export const countriesFlag = [
  {
    image: '/assets/flag1.png',
    id: 1,
  },
  {
    image: '/assets/flag2.png',
    id: 2,
  },
  {
    image: '/assets/flag3.png',
    id: 3,
  },
  {
    image: '/assets/flag4.png',
    id: 4,
  },
  {
    image: '/assets/flag5.png',
    id: 5,
  },
]

export const onlineRecruit = [
  {
    icon: '/assets/icon1.png',
    title: 'Manage candidates easily',
    description:
      'Our easy-to-use software lets you evaluate candidates and hire them faster.',
  },
  {
    icon: '/assets/icon2.png',
    title: 'User-friendly hiring platform',
    description:
      'We’ve made it easy and quick for top candidates to find your jobs.',
  },
  {
    icon: '/assets/icon3.png',
    title: 'Make the perfect hire',
    description:
      'Reduce hiring time by finding qualified candidates quickly and easily.',
  },
]

export const gettingStarted = [
  {
    number: '01',
    num: 1,
    navTitle: 'Register and Login',
    img: '/assets/register.png',
    navDesc: 'Create an account and access your saved settings on any device.',
    vieDesc: 'Create an account and access your saved settings on any device.',

    points: [
      'Easy and straightforward. it takes only two steps to do this thing that make us be friends',
      'Privacy Secured. Let’s go straight to it, alright we will act like we don’t have your data stored in our mission control',
    ],
  },
  {
    number: '02',
    num: 2,
    navTitle: 'Fill in your company data',
    img: '/assets/company-profile.png',
    navDesc: 'Fill in your company data and access your saved information',
    vieDesc:
      'Fill in your company data and access your saved settings on any device and also check for repetitions',
    points: [
      'Easy and straightforward. it takes just two steps.',
      'Data Secured. Let’s go straight to it.',
    ],
  },
  {
    number: '03',
    num: 3,
    navTitle: 'Vet applications',
    img: '/assets/vet-applicants.png',
    navDesc: 'Vet applications that has been submitted and access them',
    vieDesc:
      'Vet an application and access your saved settings on any device and also check for repetitions',
    points: [
      'Easy and straightforward. it takes only two steps to do this thing that make us be friends',
      'Efficient data. Let’s go straight to it, alright we will act like we don’t have your data stored in our mission control',
    ],
  },
  {
    number: '04',
    num: 4,
    navTitle: 'Find your match',
    img: '/assets/perfect-match.png',
    navDesc: 'Find a complete match to the search you made ...',
    vieDesc:
      'Find a match and access your saved settings on any device and also check for repetitions',
    points: [
      'Easy and straightforward. it takes only two steps to do this thing that make us be friends',
      'We have got the best matches in the history of time. Let’s go straight to it, alright we will act like we don’t have your data stored in our mission control',
    ],
  },
]

export const jobDataScroll1 = [
  {
    title: 'Senior Level Quality Assurance Human (automate)',
    description:
      'We are looking for a motivated and goal driven quality assurance specialist that can work under pressure',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job1.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Marketer',
    description:
      'We are looking for Marketers who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job2.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Design and Development',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job3.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Design and Development',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job4.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Design and Development',
    description:
      'We are looking for someone who can design and develop quality website. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job5.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Design and Development',
    description:
      'We are looking for someone who can design and develop quality website. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job6.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Design and Development',
    description:
      'We are looking for someone who can design and develop quality website. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job4.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Development',
    description:
      'We are looking for someone who can design and develop quality website. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job5.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'UI Design',
    description:
      'We are looking for someone who can design quality website. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job6.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
]

export const jobDataScroll2 = [
  {
    title: 'Junior Quality Assurance Human (automate)',
    description:
      'Scout4Job is a web hosting platform that does this and that that wil be benefitial...',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job1.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Design',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job2.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Development',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job2.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'UI Developer',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job3.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Hr Specialist',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job3.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Full stack developer',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job6.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Business Intelligent',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job3.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Website Design and Development',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job4.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
  {
    title: 'Marketer',
    description:
      'We are looking for Enrollment Advisors who are looking to take 30-35 appointments per week. All leads are pre-scheduled',
    company: 'Scout4Job',
    location: 'Lagos',
    employmentType: 'Full-time',
    level: 'Entry Level',
    jobLogo: '/assets/job6.png',
    time: '7 days ago',
    status: 'Screening',
    deadline: '3 days',
    applicants: 2,
    workType: 'remote',
  },
]

export const WORK_PLACE_CULTURE = [
  {
    name: 'remote',
    id: 1,
  },
  {
    name: 'hybrid',
    id: 2,
  },
  {
    name: 'onsite',
    id: 3,
  },
]

export const categories = [
  {
    name: 'Work Place Culture',
    id: 1,
    options: [
      {
        type: 'remote',
        available: 0,
      },
      {
        type: 'hybrid',
        available: 0,
      },
      {
        type: 'onsite',
        available: 0,
      },
    ],
  },
  {
    name: 'Employment Type',
    id: 2,
    options: [
      {
        type: 'Full time',
        available: 0,
      },
      {
        type: 'Part time',
        available: 0,
      },
      {
        type: 'Internship',
        available: 0,
      },
      {
        type: 'Contract',
        available: 0,
      },
      {
        type: 'Training Jobs',
        available: 0,
      },
    ],
  },
  {
    name: 'Job Level',
    id: 3,
    options: [
      {
        type: 'Internship',
        available: '0',
      },
      {
        type: 'Entry Level',
        available: '0',
      },
      {
        type: 'Mid Level',
        available: '0',
      },
      {
        type: 'Senior Level',
        available: '0',
      },
      {
        type: 'Executive Level',
        available: '0',
      },
      {
        type: 'VP or Above',
        available: '0',
      },
    ],
  },
]

export const currency = [
  {
    name: 'All',
    id: '1',
  },
  {
    name: 'naira',
    id: '2',
  },
  {
    name: 'dollars',
    id: '3',
  },
  {
    name: 'Cedis',
    id: '4',
  },
]

export const salaryCategories = [
  {
    name: 'Salary Range',
    currency: 'naira',
    options: [
      {
        type: '₦10,000 - ₦50,000',
        available: '0',
        id: '1',
      },
      {
        type: '₦50,000 - ₦100,000',
        available: '0',
        id: '2',
      },
      {
        type: '₦100,000 - ₦250,000',
        available: '0',
        id: '3',
      },
      {
        type: '₦250,000 - ₦400,000',
        available: '0',
        id: '4',
      },
      {
        type: '₦400,000 - ₦700,000',
        available: '0',
        id: '5',
      },
      {
        type: '₦10,000 - ₦50,000',
        available: '0',
        id: '6',
      },
      {
        type: '₦700,000 - ₦1,000,000',
        available: '0',
        id: '7',
      },
    ],
  },
  {
    name: 'Salary Range',
    currency: 'dollars',
    options: [
      {
        type: '$1,000 - $5,000',
        available: '0',
        id: '8',
      },
      {
        type: '$5,000 - $10,000',
        available: '0',
        id: '9',
      },
      {
        type: '$10,000 - $25,000',
        available: '0',
        id: '10',
      },
      {
        type: '$25,000 - $40,000',
        available: '0',
        id: '11',
      },
      {
        type: '$40, 000 - $70,000',
        available: '0',
        id: '12',
      },
      {
        type: '₦70,000 - $100,000',
        available: '0',
        id: '13',
      },
      {
        type: '$100,000 - $200,000',
        available: '0',
        id: '14',
      },
    ],
  },
]

export const whatImportantInJob = [
  'Having a say in what I work on and how I work',
  'Challenging problems to work on',
  'Learn new things and develop my skills',
  'Team members I can learn from',
  'Mentorship opportunities',
  "Having a say in the company's and or my team's direction",
  'Team members I can learn from',
]
export const RwhatImportantInJob = [
  'They have a say in what they work on and how they work',
  'Challenging problems to work on',
  'Learn new things and develop my skills',
  'Team members they can learn from one another',
  'Mentorship opportunities',
  "Having a say in the company's and or my team's direction",
  'Good growth trajectory',
  'Mentorship opportunities',
  'A diverse team',
]

export const appliedFilter = [
  {
    name: 'applied',
    color: '#00000',
  },
  {
    name: 'screening',
    color: '#00000',
  },
  {
    name: 'shortlisted',
    color: '#E2B93B',
  },
  {
    name: 'declined',
    color: '#EB5757',
  },
  {
    name: 'interview',
    color: '#2F80ED',
  },
  {
    name: 'offer',
    color: '#27AE60',
  },
  {
    name: 'hire',
    color: '#27AE60',
  },
]

//  edit preferences

export const option2 = ['Graphics', 'UI/UX Designer', 'Product Designer']

export const option1 = ['Fulltime', 'Onsite', 'Remote']

export const option3 = ['Lagos, Nigeria', 'London, UK']

export const salaryRange = ['100k - 200k', '200 - 400k', '400k - 600k']

export const profileStatus = [
  {
    icon: '/assets/briefcase.png',
    text: 'actively looking',
    status: 'active',
    color: '#14b43e',
    background: 'rgb(20, 180, 62, 0.1)',
  },
  {
    icon: '/assets/open.png',
    text: 'open to opportunities',
    status: 'inactive',
    color: '#E2B93B',
    background: 'rgb(226, 185, 59, 0.1)',
  },
  {
    icon: '/assets/lock.png',
    text: 'not looking',
    status: 'inactive',
    color: 'rgb(235, 87, 87)',
    background: 'rgb(235, 87, 87, 0.1)',
  },
]

export const filterSort = [
  'Today',
  'Yesterday',
  'This Week',
  'Last Week',
  'Last Two Weeks',
  'Last Three Weeks',
  'This Month',
  'Last Month',
  'Last Two Months',
  'Last Three Months',
  'This Year',
  'Last Year',
  'No Time Limit',
]

export const spotlightAds = [
  {
    nmme: 'Leafly',
    description: 'UI/UX designer',
    image: '/assets/',
  },
  {
    nmme: 'Epplify',
    description: 'UI/UX designer',
    image: '/assets/',
  },
  {
    nmme: 'FarFetch',
    description: 'Product Designer',
    image: '/assets/',
  },
  {
    nmme: 'WattPad',
    description: 'UI Researcher',
    image: '/assets/',
  },
  {
    nmme: 'Baba Blue',
    description: 'Graphics Designer',
    image: '/assets/',
  },
  {
    nmme: 'Soundifyr',
    description: 'UX Researcher',
    image: '/assets/',
  },
  {
    nmme: 'Leafly',
    description: 'UX Researcher',
    image: '/assets/',
  },
  {
    nmme: 'Elonmily',
    description: 'Graphics Designer',
    image: '/assets/',
  },
  {
    nmme: 'Patrify',
    description: 'Graphics Designer',
  },
]

interface doughnutProps {
  detail: string
  color: string
}

export const doughnutDetails: doughnutProps[] = [
  {
    detail: 'Shortlisted',
    color: '#E2B93B',
  },
  {
    detail: 'Interviewed',
    color: '#191919',
  },
  {
    detail: 'Hired Stage',
    color: '#27AE60',
  },
  {
    detail: 'Disqualified',
    color: '#EB5757',
  },
]

interface tags {
  name: string
  color: string
  textColor: string
}
export interface recentJobAppProps {
  title: string
  department: string
  closing: string
  synched: string
  spotlight: string
  postTime: string
  totalApplicants: string
  progressColor: string
  tags: tags[]
}

export const candidateApplicationDetailIcons = [
  {
    name: 'Interview Info',
    id: 2,
    image: '/assets/applicantIcon2.png',
    bg: '#2F80ED',
  },
  // {
  //   name: 'Disqualify Applicant',
  //   id: 4,
  //   image: '/assets/applicantIcon7.png',
  //   bg: '#EB5757',
  // },
  // {
  //   name: 'Proceed to Hire Applicant',
  //   id: 5,
  //   image: '/assets/applicantIcon6.png',
  //   bg: '#27AE60',
  // },
]

export const JobSeekerApplicationDetailIcons = [
  {
    name: 'Interview Info',
    id: 1,
    image: '/assets/applicantIcon2.png',
    bg: '#2F80ED',
  },
  // {
  //   name: 'Decline Interview',
  //   id: 2,
  //   image: '/assets/decline.png',
  //   bg: '#2F80ED',
  // },
  // {
  //   name: 'Accept Interview',
  //   id: 3,
  //   image: '/assets/accept.png',
  //   bg: '#2F80ED',
  // },
]

// post a job selection arrays

export const industry = [
  {
    name: 'Agriculture',
    id: '1',
  },
  {
    name: 'Data/Software',
    id: '2',
  },
  {
    name: 'Transport',
    id: '3',
  },
  {
    name: 'Engineering',
    id: '4',
  },
]

export const jobLevel = [
  {
    name: 'Senior Level',
    id: '1',
  },
  {
    name: 'Mid-Senior Level',
    id: '2',
  },
  {
    name: 'Junior Level',
    id: '3',
  },
  {
    name: 'intern Level',
    id: '4',
  },
]

export const COLORS = [
  '#62AA49',
  '#ef6c00',
  '#00695c',
  '#F4B736',
  '#6a1b9a',
  '#884B98',
  '#1ebfe7',
  '#254885',
  '#d52782',
  '#1f77b4',
  '#90CB6B',
  '#D65858',
  '#4D79F6',
  '#12344D',
  '#63798A',
]

export const SALARY_TERM_OPTIONS = [
  {
    name: 'Hourly',
    id: 'hour',
  },
  {
    name: 'Weekly',
    id: 'week',
  },
  {
    name: 'Fortnightly',
    id: 'fortnight',
  },
  {
    name: 'Monthly',
    id: 'month',
  },
  {
    name: 'Annually',
    id: 'annum',
  },
]
export const SALARY_TERM_OPTIONS_CALCULATOR = [
  {
    name: 'Hour',
    id: 'hour',
  },
  {
    name: 'Day',
    id: 'day',
  },
  {
    name: 'Week',
    id: 'week',
  },
  {
    name: 'Fortnight',
    id: 'fortnight',
  },
  {
    name: 'Month',
    id: 'month',
  },
  {
    name: 'Annum',
    id: 'annum',
  },
]

export const SALARY_TERM_DIVISOR = [
  {
    name: 'hour',
    divisor: 8760,
  },
  {
    name: 'day',
    divisor: 365,
  },
  {
    name: 'week',
    divisor: 52,
  },
  {
    name: 'Fortnight',
    divisor: 26,
  },
  {
    name: 'Month',
    divisor: 12,
  },
  {
    name: 'Annual',
    divisor: 1,
  },
]

export const AVAILABLE_OPENING_OPTIONS = Array.from(Array(100).keys()).map(
  (i) => {
    const number = i + 1
    return {
      name: number.toString(),
      id: number.toString(),
    }
  }
)

export const coloredRating = [
  {
    color: 'rgba(235, 87, 87, 1)',
    bgColor: 'rgba(235, 87, 87, 0.1)',
    description: 'Below Average',
    percentage: '2%',
  },
  {
    color: 'rgba(226, 130, 59, 1)',
    bgColor: 'rgba(226, 130, 59, 0.1)',
    description: 'Average',
    percentage: '5%',
  },
  {
    color: 'rgba(226, 185, 59, 1)',
    bgColor: 'rgba(226, 185, 59, 0.1)',
    description: 'Good',
    percentage: '12%',
  },
  {
    color: 'rgba(39, 174, 96, 1)',
    bgColor: 'rgba(39, 174, 96, 0.1)',
    description: 'Very Good',
    percentage: '36%',
  },
  {
    color: 'rgba(47, 128, 237, 1)',
    bgColor: 'rgba(47, 128, 237, 0.1)',
    description: 'Exceptional',
    percentage: '45%',
  },
]

export const overallRecommendation = [
  {
    name: 'Do not hire',
    color: 'rgba(235, 87, 87, 1)',
    bgColor: 'rgba(235, 87, 87, 0.1)',
    numberRep: 1,
  },
  {
    name: 'Further interview',
    color: 'rgba(47, 128, 237, 1)',
    bgColor: 'rgba(47, 128, 237, 0.1)',
    numberRep: 2,
  },
  {
    name: 'Hire',
    color: 'rgba(39, 174, 96, 1)',
    bgColor: 'rgba(39, 174, 96, 0.1)',
    numberRep: 3,
  },
]

export const articleDemoImages = [
  {
    url: '/assets/ArticleC1.png',
    id: 1,
  },
  {
    url: '/assets/ArticleC2.png',
    id: 2,
  },
  {
    url: '/assets/ArticleC3.png',
    id: 3,
  },
]

export const companySizes = [
  {
    name: 'Seed',
    size: '1-10',
  },
  {
    name: 'Early',
    size: '11-50',
  },
  {
    name: 'Mid-size',
    size: '51-100',
  },
  {
    name: 'Large',
    size: '101-500',
  },
  {
    name: 'Very Large',
    size: '501-1000',
  },
  {
    name: 'Extra Large',
    size: '1000+',
  },
]

export const jobSeekersFeedbackQuestions = [
  {
    no: 1,
    question: 'How was your experience registering your account?',
  },
  {
    no: 2,
    question: 'What challenges did you encounter on creating your profile?',
  },
  {
    no: 3,
    question: 'Was it easy to add your job search profile credentials?',
  },
  {
    no: 4,
    question: 'How difficult was it to apply for a job?',
  },
  {
    no: 5,
    question: 'Tell us your overall feedback on the application',
  },
]

export const jobSeekerOption1 = [
  'Annoying',
  'Complex',
  'Neutral',
  'Easy',
  'Exciting',
]

export const emojis = [
  '/assets/angry.svg',
  '/assets/complex.svg',
  '/assets/neutral.svg',
  '/assets/easy.svg',
  '/assets/exciting.svg',
]

export const jobSeekerFeedbackSubQuestion3 = [
  'Resume/CV Upload',
  'Work Experience Upload',
  'Education Upload',
  'Skills Upload',
]

export const jobSeekerOption2 = [
  'No challenge at all (everything was seamless)',
  'Some buttons are not working',
  'Some questions are quite unnecessary',
  'The questions are difficult to understand',
  'Too many questions',
]

export const jobSeekerOption3 = [
  'Fun and exciting',
  'Quite interesting',
  'Not so different from other platforms',
  'Complicated to use',
  'Has a lot of issues/difficulties',
  'Terrible platform',
]

export const jobSeekerWithColors = [
  {
    name: 'Annoying',
    color: 'rgba(235, 87, 87, 1)',
    bgColor: 'rgba(235, 87, 87, 0.1)',
  },
  {
    name: 'Complex',
    color: 'rgba(226, 130, 59, 1)',
    bgColor: 'rgba(226, 130, 59, 0.1)',
  },
  {
    name: 'Neutral',
    color: 'rgba(39, 174, 96, 1)',
    bgColor: 'rgba(39, 174, 96, 0.1)',
  },
  {
    name: 'Easy',
    color: 'rgba(69, 192, 200, 1)',
    bgColor: 'rgba(69, 192, 200, 0.1)',
  },
  {
    name: 'Exciting',
    color: 'rgba(47, 128, 237, 1)',
    bgColor: 'rgba(47, 128, 237, 0.1)',
  },
]

export const JOB_SEEKERS_FAQ = [
  {
    question: 'Is Scout4job only for fresh graduates?',
    answers:
      'No. The Scout4job platform is for anyone seeking a job, whether a young university leaver or an executive-level professional. When signing up, you can always fill in your level of qualifications, and you can edit your information as often as you acquire more.',
  },
  {
    question: 'Do I have to pay to use Scout4job?',
    answers:
      'No. Scout4job is a platform that is absolutely free to use. What’s more, we can even help you earn with the Scout4job Business feature.',
  },
  {
    question: 'If I make a mistake in my application, can I edit it?',
    answers:
      'You can edit your profile anytime, and as many times as you want, but once you send an application, it’s already sent to the employer and can’t be edited.',
  },
  {
    question:
      'Is there a limit to the number of jobs I can apply for on Scout4job?',
    answers:
      'No. You can apply for as many jobs as you need to on our app. We understand that even some jobs you get may not  fully meet all your needs, or you may need two or more part-time jobs at the same time.',
  },
  {
    question: 'Can disabled people get jobs on Scout4job?',
    answers:
      'Totally. Disabilities of any kind are not a barrier to getting your dream job on the Scout4Job platform, and if you want to, you can always let your employers know by checking the disability field during your application.',
  },
  {
    question:
      'What if I have different resumes for different jobs? Can I upload all of them?',
    answers:
      "On your profile, you may upload a resume that matches your general professional summary. However, we understand that a different, job-specific CV may be needed for the type of job you’re applying for, which is why there’s room for you to upload as many different resumes as you'd like, each time you’re applying for a job. So, one resume remains static on your profile, but you can upload others that match your specific job. You can upload different resumes here.",
  },
]

export const STATE_IN_NIGERIA = [
  { name: 'Abia', id: 'abia' },
  { name: 'Adamawa', id: 'adamawa' },
  { name: 'Akwa Ibom', id: 'akwa-ibom' },
  { name: 'Anambra', id: 'anambra' },
  { name: 'Bauchi', id: 'bauchi' },
  { name: 'Bayelsa', id: 'bayelsa' },
  { name: 'Benue', id: 'benue' },
  { name: 'Borno', id: 'borno' },
  { name: 'Cross River', id: 'cross-river' },
  { name: 'Delta', id: 'delta' },
  { name: 'Ebonyi', id: 'ebonyi' },
  { name: 'Edo', id: 'edo' },
  { name: 'Ekiti', id: 'ekiti' },
  { name: 'Enugu', id: 'enugu' },
  { name: 'FCT - Abuja', id: 'fct-abuja' },
  { name: 'Gombe', id: 'gombe' },
  { name: 'Imo', id: 'imo' },
  { name: 'Jigawa', id: 'jigawa' },
  { name: 'Kaduna', id: 'kaduna' },
  { name: 'Kano', id: 'kano' },
  { name: 'Katsina', id: 'katsina' },
  { name: 'Kebbi', id: 'kebbi' },
  { name: 'Kogi', id: 'kogi' },
  { name: 'Kwara', id: 'kwara' },
  { name: 'Lagos', id: 'lagos' },
  { name: 'Nasarawa', id: 'nasarawa' },
  { name: 'Niger', id: 'niger' },
  { name: 'Ogun', id: 'ogun' },
  { name: 'Ondo', id: 'ondo' },
  { name: 'Osun', id: 'osun' },
  { name: 'Oyo', id: 'oyo' },
  { name: 'Plateau', id: 'plateau' },
  { name: 'Rivers', id: 'rivers' },
  { name: 'Sokoto', id: 'sokoto' },
  { name: 'Taraba', id: 'taraba' },
  { name: 'Yobe', id: 'yobe' },
  { name: 'Zamfara', id: 'zamfara' },
]


export const GRADES_OPTIONS = [
  { name: 'First Class', id: 'first-class' },
  { name: 'Second Class Upper', id: 'second-class-upper' },
  { name: 'Second Class Lower', id: 'second-class-lower' },
  { name: 'Third Class', id: 'third-class' },
  { name: 'Pass', id: 'pass' },
  { name: 'Distinction', id: 'distinction' },
  { name: 'Upper Credit', id: 'upper-credit' },
  { name: 'Lower Credit', id: 'lower-credit' },
  { name: 'Merit', id: 'merit' },
  { name: 'Non-graded', id: 'non-graded' },
];