import { spotlightAds } from '@/data'

export const Spotlight = () => {
  return (
    <div className=" text-black">
      {spotlightAds.map((job, index) => (
        <div className="" key={index}>
          <div className="flex items-center gap-6">
            {/* <Image
                                src={jobInfo.jobLogo}
                                alt="jobLogo"
                                height={80}
                                width={80}
                            /> */}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Spotlight
