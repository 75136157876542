import Link from 'next/link'
import React, { Dispatch, SetStateAction } from 'react'

import {  User } from '@/features/auth'
import { useRouter } from 'next/router'
import { publicRoutes } from '@/utils'

interface ProfileDropDownProps {
  showDropDown: boolean
  openEditProfileModal?: () => void
  user: User | null | undefined
  handleLogout: () => void
  setIsDropdown: Dispatch<SetStateAction<boolean>>
}

export const ProfileDropDown = React.forwardRef<HTMLDivElement, ProfileDropDownProps>(({
  user,
  showDropDown,
  openEditProfileModal,
  handleLogout,
  setIsDropdown
}, ref) => {
  const { firstName, lastName, currentJobType, photoUrl } = user || {}

  const router = useRouter()
  const isPublicRoute = publicRoutes.includes(router.pathname) ? true : false

  return (
    <div ref={ref} className="absolute top-[120px] right-12 z-[1000] -mt-[10px]">
      {showDropDown && (
        <div className="rounded-lg bg-white px-9  pt-7 pb-5 text-black shadow-lg">
          <div className="[w-232px] flex items-center  gap-6 ">
            <div>
              <img
                className="profile-picture w-[50px] h-[50px]"
                src={photoUrl || ''}
                alt="profile picture"
              />
            </div>
            <div className=" leading-1 ">
              <p className="block text-sm font-bold">
                {firstName} {lastName}
              </p>
              <p className="mt-1 block text-xs">{currentJobType || '-'}</p>
             {!isPublicRoute && <button
                onClick={openEditProfileModal}
                className="pointer-cursor mt-1 block cursor-pointer text-xs text-[#2F80ED]"
              >
                Edit
              </button>}
            </div>
          </div>
          <div className="mt-5 leading-7">
            <p className="block text-[10px] text-gray-250">Personal</p>
            <Link
              href="/job-seeker/dashboard"
              className="mt-2 block text-xs"
              onClick={() => setIsDropdown(false)}
            >
              Dashboard
            </Link>
            <Link
              href="/job-seeker/dashboard/profile"
              className="mt-2 block text-xs"
              onClick={() => setIsDropdown(false)}
            >
              Profile
            </Link>
            <Link
              href="/job-seeker/dashboard/settings"
              className="mt-2 block border-b pb-4 text-xs"
              onClick={() => setIsDropdown(false)}
            >
              Settings
            </Link>
            <button
              className="mt-2 block pb-4 text-xs  text-red-400"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  )
})

ProfileDropDown.displayName = 'ProfileDropDown';
