import { Sling as Hamburger } from 'hamburger-react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import clsx from 'clsx'

import { DropDownInfo, Dropdown } from '@/features/resources'
import { useComponentVisible, useUserInfo } from '@/hooks'
import { ProfileDropDown } from './ProfileDropDown'
import { useHandleLogout } from '@/features/auth'
import { Dispatch, SetStateAction } from 'react'
import { RECRUITER_SIGN_UP } from '@/utils'
import { FaTimes } from 'react-icons/fa'

type NavProp = {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
}
export default function Navbar({ setIsOpen, isOpen }: NavProp) {
  const router = useRouter()
  const { user, isLoading: isLoadingUserInfo } = useUserInfo()
  const { photoUrl, isRecruiter } = user || {}
  const [showMobileDownload, setShowMobileDownload] = React.useState(true)
  const { handleLogout } = useHandleLogout()

  const navLinks = [
    {
      name: 'Jobs',
      url: '/jobs',
    },
    {
      name: 'Recruiter',
      url: '/recruiter',
    },
    {
      name: 'Resources',
      url: '/resources',
    },
    {
      name: 'Training',
      url: '/training',
    },
    {
      name: 'Login',
      url: '/login',
    },
  ]

  const resourcesOption: DropDownInfo[] = [
    {
      linkName: 'Articles',
      linkUrl: '/resources/articles',
      icon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 8.24423V3.50423C16.5 2.60423 15.765 1.93673 14.8725 2.01173H14.8275C13.2525 2.14673 10.86 2.94923 9.525 3.78923L9.3975 3.87173C9.18 4.00673 8.82 4.00673 8.6025 3.87173L8.415 3.75923C7.08 2.92673 4.695 2.13173 3.12 2.00423C2.2275 1.92923 1.5 2.60423 1.5 3.49673V12.5567C1.5 13.2767 2.085 13.9517 2.805 14.0417L3.0225 14.0717C4.65 14.2892 7.1625 15.1142 8.6025 15.9017L8.6325 15.9167C8.835 16.0292 9.1575 16.0292 9.3525 15.9167C10.7925 15.1217 13.3125 14.2892 14.9475 14.0717L15.195 14.0417C15.915 13.9517 16.5 13.2767 16.5 12.5567V11.2667M9 4.11623V15.3662M5.8125 6.36623H4.125M6.375 8.61623H4.125"
            stroke="#4F4F4F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      linkName: 'Salary Calculator',
      linkUrl: '/resources/salary-calculator',
      icon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 11.28V13.5C15 15.75 13.6575 16.5 12 16.5H6C4.3425 16.5 3 15.75 3 13.5V6.1875C3 3.75 4.3425 3.1875 6 3.1875C6 3.6525 6.1875 4.0725 6.495 4.38C6.8025 4.6875 7.2225 4.875 7.6875 4.875H10.3125C11.2425 4.875 12 4.1175 12 3.1875C13.6575 3.1875 15 3.75 15 6.1875V7.5525"
            stroke="#4F4F4F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 9.75H9M6 12.75H12M12 3.1875C12 4.1175 11.2425 4.875 10.3125 4.875H7.6875C7.2225 4.875 6.8025 4.6875 6.495 4.38C6.1875 4.0725 6 3.6525 6 3.1875C6 2.2575 6.7575 1.5 7.6875 1.5H10.3125C10.7775 1.5 11.1975 1.6875 11.505 1.995C11.8125 2.3025 12 2.7225 12 3.1875Z"
            stroke="#4F4F4F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      linkName: 'CV-Templates',
      linkUrl: '/resources/cv-templates',
      icon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.25 9.7275V11.25C2.25 15 3.75 16.5 7.5 16.5H10.5C14.25 16.5 15.75 15 15.75 11.25V6.75C15.75 3 14.25 1.5 10.5 1.5H7.5C3.75 1.5 2.25 3 2.25 6.75"
            stroke="#4F4F4F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.375 5.68359V6.43359C12.375 7.04859 11.8725 7.55859 11.25 7.55859H6.75C6.135 7.55859 5.625 7.05609 5.625 6.43359V5.68359C5.625 5.06859 6.1275 4.55859 6.75 4.55859H11.25C11.8725 4.55859 12.375 5.06109 12.375 5.68359Z"
            stroke="#4F4F4F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.10156 10.5H6.11056M8.99581 10.5H9.00481M11.8908 10.5H11.8991M6.10156 13.125H6.11056M8.99581 13.125H9.00481M11.8908 13.125H11.8991"
            stroke="#4F4F4F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    // {
    //   linkName: 'Discover Companies',
    //   linkUrl: '/resources/discover-companies',
    //   icon: (
    //     <svg
    //       width="18"
    //       height="17"
    //       viewBox="0 0 18 17"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M0.75 15.5H17.25M14.835 15.5075V12.1625M1.575 3.515C1.575 2.0075 2.325 1.25 3.8175 1.25H8.49C9.9825 1.25 10.725 2.0075 10.725 3.515V15.5M1.575 15.5015V6.509M4.35 5.1875H8.0625M4.35 8H8.0625M6.1875 15.5V12.6875M14.85 7.1675C13.935 7.1675 13.2 7.9025 13.2 8.8175V10.52C13.2 11.435 13.935 12.17 14.85 12.17C15.765 12.17 16.5 11.435 16.5 10.52V8.8175C16.5 7.9025 15.765 7.1675 14.85 7.1675Z"
    //         stroke="#4F4F4F"
    //         strokeWidth="1.5"
    //         stroke-miterlimit="10"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   linkName: 'Interview Question',
    //   linkUrl: '/resources/interview-question',
    //   icon: (
    //     <svg
    //       width="18"
    //       height="18"
    //       viewBox="0 0 18 18"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M15 11.28V13.5C15 15.75 13.6575 16.5 12 16.5H6C4.3425 16.5 3 15.75 3 13.5V6.1875C3 3.75 4.3425 3.1875 6 3.1875C6 3.6525 6.1875 4.0725 6.495 4.38C6.8025 4.6875 7.2225 4.875 7.6875 4.875H10.3125C11.2425 4.875 12 4.1175 12 3.1875C13.6575 3.1875 15 3.75 15 6.1875V7.5525"
    //         stroke="#4F4F4F"
    //         strokeWidth="1.5"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //       <path
    //         d="M6 9.75H9M6 12.75H12M12 3.1875C12 4.1175 11.2425 4.875 10.3125 4.875H7.6875C7.2225 4.875 6.8025 4.6875 6.495 4.38C6.1875 4.0725 6 3.6525 6 3.1875C6 2.2575 6.7575 1.5 7.6875 1.5H10.3125C10.7775 1.5 11.1975 1.6875 11.505 1.995C11.8125 2.3025 12 2.7225 12 3.1875Z"
    //         stroke="#4F4F4F"
    //         strokeWidth="1.5"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />
    //     </svg>
    //   ),
    // },
  ]

  const {
    ref: refDropdown,
    isComponentVisible: isDropdown,
    setIsComponentVisible: setIsDropdown,
  } = useComponentVisible()

  return (
    <>
      {showMobileDownload && (
        <div className=" hidden h-12 w-full items-center justify-between gap-3 bg-black px-4 py-2 text-white lg:flex">
          <div className="flex items-center gap-6">
            <p className="text-[11px]">
              Download our mobile app for a better experience
            </p>
            <div className="flex items-center gap-1">
              <Link
                className="decoration-barbie  rounded-sm border border-white underline-offset-2 hover:underline"
                href="https://apps.apple.com/app/scout4job/id6449973743"
                target="_blank"
                rel="noopener nofollow"
              >
                <Image
                  src="https://res.cloudinary.com/scout4job/image/upload/v1692276489/app-store_ds5b9e_vuizdl.png"
                  alt="app"
                  width={80}
                  height={24}
                />
              </Link>

              <Link
                className="decoration-barbie rounded-sm border border-white underline-offset-2 hover:underline"
                href="https://play.google.com/store/apps/details?id=com.scout4job"
                target="_blank"
                rel="noopener nofollow"
              >
                <Image
                  src="https://res.cloudinary.com/scout4job/image/upload/v1692276490/play-store_fcph2r_rrcqdm.png"
                  alt="app"
                  width={80}
                  height={24}
                />
              </Link>
            </div>
          </div>
          <button onClick={() => setShowMobileDownload(false)}>
            <FaTimes />
          </button>
        </div>
      )}
      <div
        className={clsx(
          'sticky top-0 z-[150]',
          isOpen
            ? 'duration-400 absolute h-fit w-full bg-white transition lg:px-16 lg:shadow-[0px_4px_9px_-7px_#a0aec0]'
            : 'duration-400 w-full bg-white transition lg:px-16 lg:shadow-[0px_4px_9px_-7px_#a0aec0]'
        )}
      >
        <div
          className={
            isOpen
              ? 'duration-400 visible fixed top-[64px] z-50 flex h-[100vh] w-full flex-col items-center justify-center bg-white opacity-100 transition'
              : 'duration-400 top-0 z-50 mx-auto box-border max-w-[1500px] items-center text-sm opacity-0 transition sm:invisible sm:w-[0%] lg:visible  lg:flex lg:h-fit lg:w-full lg:flex-row lg:justify-between lg:bg-white lg:py-6 lg:opacity-100'
          }
        >
          <Link href="/">
            <div
              className={
                isOpen
                  ? 'hidden'
                  : 'hidden lg:block lg:w-[150px] lg:cursor-pointer'
              }
            >
              <Image
                src="/assets/logo-black.png"
                alt="scout4Job-logo"
                className={
                  isOpen ? 'hidden' : 'hidden lg:block lg:cursor-pointer'
                }
                height={29.65}
                width={150.05}
              />
            </div>
          </Link>
          <div
            className={
              isOpen
                ? 'mt-[-15rem] flex flex-col items-center gap-10'
                : 'hidden gap-10 lg:flex lg:flex-row lg:items-center lg:justify-end'
            }
          >
            {/* nav links */}
            {navLinks.map((link, Index) => {
              if (link.name == 'Login') {
                return (
                  <Link
                    href={link.url}
                    onClick={() => setIsOpen(false)}
                    key={Index}
                  >
                    {!user && !isRecruiter && (
                      <button className=" w-fit items-center bg-white py-[11px] px-[26.8px] text-sm  font-bold text-secondary shadow-[-6px_6px_0px_0px_#191919] transition duration-200 hover:shadow-[6px_6px_0px_0px_#191919]">
                        Login
                      </button>
                    )}
                    {/* {isRecruiter && (
                      <button className=" w-fit items-center bg-white py-[11px] px-[26.8px] text-sm  font-bold text-secondary shadow-[-6px_6px_0px_0px_#191919] transition duration-200 hover:shadow-[6px_6px_0px_0px_#191919]">
                        Login
                      </button>
                    )} */}
                  </Link>
                )
              }
              if (link.name == 'Resources') {
                return (
                  <div
                    key={Index}
                    className={clsx(
                      'active group -mt-1 whitespace-nowrap font-semibold text-secondary transition duration-200 lg:text-secondary',
                      router.asPath == 'Resources' ||
                        router.pathname == '/resources/articles' ||
                        router.pathname == '/resources/interview-questions' ||
                        router.pathname == '/resources/discover-companies' ||
                        router.pathname == '/resources/salary-calculator'
                        ? 'nav-hover'
                        : ''
                    )}
                  >
                    <Link href={link.url} onClick={() => setIsOpen(false)}>
                      {link.name}
                    </Link>
                    <div className="absolute top-11 hidden w-[100px] bg-transparent pt-4 group-hover:block">
                      <Dropdown
                        dropDownInfo={resourcesOption}
                        className="w-56"
                      />
                    </div>
                  </div>
                )
              }
              return (
                <Link
                  href={link.url}
                  onClick={() => setIsOpen(false)}
                  className={
                    router.asPath == link.url
                      ? 'active nav-hover font-semibold text-secondary transition duration-200'
                      : 'nav-hover whitespace-nowrap font-semibold text-secondary transition duration-200 lg:text-secondary'
                  }
                  key={link.name}
                >
                  {link.name}
                </Link>
              )
            })}
            <div className=" items-center space-x-4 lg:flex">
              <Link href={RECRUITER_SIGN_UP}>
                <button className="whitespace-nowrap bg-secondary py-[11px] px-[26.8px] text-sm font-bold text-white shadow-[6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919]">
                  Post a Job
                </button>
              </Link>
            </div>
            {user && (
              <div className="relative hidden lg:block">
                {isRecruiter ? (
                  <Link
                    href="/recruiter/dashboard"
                    className=" flex items-center gap-4 pl-8 text-left"
                  >
                    <>
                      {photoUrl ? (
                        <img
                          className="profile-picture h-[40px] w-[40px] min-w-[40px] mt-1 rounded-full"
                          src={photoUrl || ''}
                          alt="recruiter"
                        />
                      ) : (
                        <p className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black font-bold text-white">
                          {user?.firstName[0] || ''}
                        </p>
                      )}
                    </>
                  </Link>
                ) : (
                  <div>
                    {isLoadingUserInfo ? (
                      ''
                    ) : (
                      <button onClick={() => setIsDropdown(!isDropdown)}>
                        <img
                          className="profile-picture h-[40px] min-w-[40px] mt-2 w-[40px]"
                          src={photoUrl || ''}
                          alt="picture"
                        />
                      </button>
                    )}
                  </div>
                )}
                <div className="absolute -top-6 w-[302px] -left-[210px]">
                  {user && (
                    <ProfileDropDown
                      user={user}
                      ref={refDropdown}
                      handleLogout={handleLogout}
                      showDropDown={isDropdown}
                      setIsDropdown={setIsDropdown}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={
            ' fixed top-0 z-[100] flex w-full items-center justify-between bg-white/30 py-2 px-4 shadow backdrop-blur-lg lg:hidden'
          }
        >
          <Link href="/" onClick={() => setIsOpen(false)}>
            <Image
              src="/assets/logo-black.png"
              alt="scout4Job-logo"
              className="lg:cursor-pointer"
              height={18.65}
              width={130.05}
            />
          </Link>

          <div className="flex items-center gap-3">
            {isRecruiter ? (
              <Link
                href="/recruiter/dashboard"
                className=" flex items-center gap-4 pl-8 text-left"
              >
                <>
                  {photoUrl ? (
                    <img
                      className="profile-picture h-[40px] w-[40px] rounded-full"
                      src={photoUrl || ''}
                      alt="recruiter"
                    />
                  ) : (
                    <p className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black font-bold text-white">
                      {user?.firstName[0] || ''}
                    </p>
                  )}
                </>
              </Link>
            ) : (
              <div className="">
                {isLoadingUserInfo ? (
                  ''
                ) : (
                  <button onClick={() => setIsDropdown(!isDropdown)}>
                    <img
                      className="profile-picture h-[40px] w-[40px]"
                      src={photoUrl || ''}
                      alt="picture"
                    />
                  </button>
                )}
              </div>
            )}
            <div>
              <Hamburger
                toggled={isOpen}
                toggle={setIsOpen}
                easing="ease-in"
                rounded
                size={18}
                color="#1b2c19"
              />
            </div>
          </div>
        </div>

        {/* mobiile app download section */}
        {showMobileDownload && (
          <div className="fixed top-16 z-[100] flex h-12 w-full items-center justify-between gap-3 bg-black px-4 py-2 text-white lg:hidden">
            <p className="text-[11px]">
              Download our mobile app for a better experience
            </p>
            <div className="flex items-center gap-1">
              <Link
                className="decoration-barbie  rounded-sm border border-white underline-offset-2 hover:underline"
                href="https://apps.apple.com/app/scout4job/id6449973743"
              >
                <Image
                  src="https://res.cloudinary.com/scout4job/image/upload/v1692276489/app-store_ds5b9e_vuizdl.png"
                  alt="app"
                  width={110}
                  height={30}
                />
              </Link>

              <Link
                className="decoration-barbie rounded-sm border border-white underline-offset-2 hover:underline"
                href="https://play.google.com/store/apps/details?id=com.scout4job"
                target="_blank"
                rel="noopener nofollow"
              >
                <Image
                  src="https://res.cloudinary.com/scout4job/image/upload/v1692276490/play-store_fcph2r_rrcqdm.png"
                  alt="app"
                  width={110}
                  height={30}
                />
              </Link>
            </div>
            <button onClick={() => setShowMobileDownload(false)}>
              <FaTimes />
            </button>
          </div>
        )}
      </div>
    </>
  )
}
