import { EmptyState } from '@/components'
import {  NewInterview } from '@/features/job-seeker'
import { Personalcard } from 'iconsax-react'
import React from 'react'
import { InterviewCard } from './InterviewCard'

interface InterviewQuickviewProp {
  upcomingInterviews: (NewInterview | undefined)[]
  data: NewInterview[]
}

export const InterviewQuickview = ({
  upcomingInterviews,
  data
}: InterviewQuickviewProp) => {
  data
  return (
    <div>
      {upcomingInterviews.length > 0 ? (
        upcomingInterviews.map((interview, index) => (
          interview && <InterviewCard key={index} interview={interview} index={index} />
        ))
      ) : (
        <EmptyState
          icon={
            <Personalcard
              className="mx-auto"
              size="68"
              color="#27AE60"
              variant="Bulk"
            />
          }
          className="mt-16"
          description="No interview scheduled yet"
        />
      )}
    </div>
  )
}
