import { useQuery } from '@tanstack/react-query'

// import { companyIDStorage } from '@/features/auth'
import { CompanyJob } from '../types'
import { companyJobKeys, fetchData } from '@/utils'
import { useCompanyId } from '../hooks'

interface CompanyJobsResponse {
  status: string
  message: string
  data: CompanyJob[]
}

export const useCompanyJobs = (id = '') => {
  const companyId =  useCompanyId() || id
  return useQuery<CompanyJobsResponse>({
    queryKey: companyJobKeys.list(companyId),
    queryFn: async () => fetchData(`/company/all-jobs/${companyId}`),
    enabled: !!companyId
  })
}
