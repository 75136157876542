import { CompanyJob, useChangeJobStatus } from '@/features/recruiter'
import { useComponentVisible } from '@/hooks'
import { ModalChangeStatus } from './Modals'
import React from 'react'

import {
  Eye,
  ClipboardTick,
  Calendar2,
  DirectboxSend,
  ExportCurve,
  UserRemove,
  ArchiveBox,
  Edit,
  Export,
  Trash,
  ArrowSquareUp,
} from 'iconsax-react'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface ActiveFilterProp {
  applicantId: string
  activeFilter: number
  handleViewApplicant?: () => void
  openConfirmDisqualifyModal?: boolean
  setOpenConfirmDisqualifyModal?: (value: boolean) => void
  openOfferLetterModal: boolean
  setOpenOfferLetterModal: (value: boolean) => void
  openDiscardEmployee: boolean
  setOpenDiscardEmployee: (value: boolean) => void
  handleScreenModal?: () => void
  handleShortlistModal?: () => void
  handleTalentPoolModal?: () => void
  handleSendOfferModal?: () => void
}

// options for  applicant page in each jobs page with route /jobs/applicant/id
export const ApplicantOptionGeneral: React.FC<ActiveFilterProp> = ({
  applicantId,
  handleViewApplicant,
  openConfirmDisqualifyModal,
  setOpenConfirmDisqualifyModal,
  openDiscardEmployee,
  setOpenDiscardEmployee,
  activeFilter,
  handleScreenModal,
  handleShortlistModal,
  handleTalentPoolModal,
  handleSendOfferModal,
}) => {
  const router = useRouter()

  const applicationId = router.query.id as string
  const arrayOfApplicant = applicantId //I know just one Id can be sent but for the sake of the schedule another interview in the interviewInfo section we are converting the applicantId here to array so it can match with that
  const arrayOfApplicantId =  JSON.stringify(arrayOfApplicant)
  return (
    <div className="absolute top-5 right-5 w-[90%] max-w-[201px] overflow-hidden rounded-lg bg-white   shadow-lg">
      {activeFilter !== 5 && (
        <div
          className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px] pt-4 transition ease-in-out hover:bg-[#E5E5E5]"
          onClick={handleViewApplicant}
        >
          <Eye size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">View Applicant Profile</p>
        </div>
      )}

      {activeFilter == (0 || 3) && handleScreenModal && (
        <button
          className="flex w-full cursor-pointer items-center justify-start gap-3 px-4 py-2 transition ease-in-out hover:bg-[#E5E5E5]"
          onClick={handleScreenModal}
        >
          <ArchiveBox size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Screen Applicant</p>
        </button>
      )}

      {activeFilter <= 1 && handleShortlistModal && (
        <button
          onClick={handleShortlistModal}
          className="flex w-full cursor-pointer items-center justify-start gap-3 px-4 py-2 transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <ClipboardTick size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Shortlist Applicant</p>
        </button>
      )}

      {activeFilter < 3 && (
        <Link
          href={`/recruiter/dashboard/interview/schedule/${applicationId}?applicantId=${arrayOfApplicantId}`}
          className="flex w-full cursor-pointer items-center justify-start gap-3 px-4 py-2 transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <Calendar2 size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Schedule Interview</p>
        </Link>
      )}
      {activeFilter < 3 && activeFilter !== 5 && (
        <div
          onClick={handleTalentPoolModal}
          className="flex cursor-pointer items-center justify-start gap-3 px-4 py-2 transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <DirectboxSend size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Move To Talent Pool</p>
        </div>
      )}
      {activeFilter < 3 &&
        activeFilter !== 5 &&
        setOpenConfirmDisqualifyModal && (
          <div
            className="flex cursor-pointer items-center justify-start gap-3 px-4 py-2 pb-4 transition ease-in-out hover:bg-[#E5E5E5]"
            onClick={() =>
              setOpenConfirmDisqualifyModal(!openConfirmDisqualifyModal)
            }
          >
            <UserRemove size="16" color="#EB5757" variant="TwoTone" />
            <p className="text-xs font-bold text-[#EB5757]">
              Disqualify Applicant
            </p>
          </div>
        )}
      {activeFilter == 5 && (
        <button
          onClick={handleSendOfferModal}
          className="flex w-full cursor-pointer  items-center justify-start gap-3 px-4 py-2 transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <UserRemove size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold text-black">Send Offer Letter</p>
        </button>
      )}
      {activeFilter == 5 && (
        <button
          className="flex w-full cursor-pointer items-center justify-start gap-3 px-4 py-2 pb-4 transition ease-in-out hover:bg-[#E5E5E5]"
          onClick={() => setOpenDiscardEmployee(!openDiscardEmployee)}
        >
          <UserRemove size="16" color="#EB5757" variant="TwoTone" />
          <p className="text-xs font-bold text-[#EB5757]">Discard Employee</p>
        </button>
      )}
    </div>
  )
}

export interface IOpenViewPost {
  setOpenViewJobPostModal: (value: boolean) => void
  openViewJobPostModal: boolean
  jobPostDetail?: CompanyJob | undefined
  jobPostId?: string
}

export interface IApplicationOpenViewPost {
  setOpenViewJobPostModal: (value: boolean) => void
  openViewJobPostModal: boolean
  jobPostDetail?: CompanyJob | undefined
  jobPostId?: string
  title: string
  isPublished: boolean
  setOpenExtendDeadlineModal: (value: boolean) => void
  openExtendDeadlineModal: boolean
}

export interface IJobOpenViewPost {
  setOpenViewJobPostModal: (value: boolean) => void
  openViewJobPostModal: boolean
  jobPostDetail?: CompanyJob | undefined
  jobPostId?: string
  title: string
  isPublished: boolean
  setOpenExtendDeadlineModal: (value: boolean) => void
  openExtendDeadlineModal: boolean
}

export interface IOpenApplicantModal {
  handleViewApplicant: () => void
  setOpenScheduleInterviewModal: (value: boolean) => void
  openScheduleInterviewModal: boolean
  openConfirmDisqualifyModal: boolean
  setOpenConfirmDisqualifyModal: (value: boolean) => void
  openOfferLetterModal: boolean
  setOpenOfferLetterModal: (value: boolean) => void
  activeFilter: number
  setOpenDiscardEmployee: (value: boolean) => void
  openDiscardEmployee: boolean
  // useCompany: UseCompanyProp
}

// options for applicants in applicants page
export const OptionInAllApplicants = ({
  handleViewApplicant,
  setOpenScheduleInterviewModal,
  openScheduleInterviewModal,
  openConfirmDisqualifyModal,
  setOpenConfirmDisqualifyModal,
  openOfferLetterModal,
  setOpenOfferLetterModal,
  openDiscardEmployee,
  setOpenDiscardEmployee,
  activeFilter,
}: IOpenApplicantModal) => {
  return (
    <div className="absolute top-12 right-5 z-10 w-[90%] max-w-[201px] overflow-hidden rounded-lg bg-white   shadow-lg">
      {activeFilter !== 5 && (
        <button
          className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px] pt-4 transition ease-in-out hover:bg-[#E5E5E5]"
          onClick={handleViewApplicant}
        >
          <Eye size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">View Applicant Profile</p>
        </button>
      )}
      {activeFilter == 0 && (
        <div className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]">
          <ArchiveBox size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Review Applicant</p>
        </div>
      )}
      {activeFilter <= 1 && (
        <button className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]">
          <Eye size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Shortlist Applicant</p>
        </button>
      )}
      {activeFilter < 3 && (
        <div
          className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]"
          onClick={() =>
            setOpenScheduleInterviewModal(!openScheduleInterviewModal)
          }
        >
          <Edit size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Schedule Interview</p>
        </div>
      )}
      {activeFilter < 3 && activeFilter !== 5 && (
        <div className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]">
          <Export size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Move to Talent Pool</p>
        </div>
      )}
      {activeFilter !== 5 && (
        <div className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]">
          <ExportCurve size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Share Applicant Profile</p>
        </div>
      )}
      {activeFilter === 5 && (
        <div
          onClick={() => setOpenOfferLetterModal(!openOfferLetterModal)}
          className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <ExportCurve size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Send Offer Letter</p>
        </div>
      )}

      {activeFilter < 3 && (
        <div
          onClick={() =>
            setOpenConfirmDisqualifyModal(!openConfirmDisqualifyModal)
          }
          className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px] pb-4 transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <UserRemove size="16" color="red" variant="TwoTone" />
          <p className="text-xs font-bold text-red-600">Disqualify Applicant</p>
        </div>
      )}
      {activeFilter == 5 && (
        <div
          className="flex cursor-pointer items-center justify-start gap-3 px-4 py-2 pb-4 transition ease-in-out hover:bg-[#E5E5E5]"
          onClick={() => setOpenDiscardEmployee(!openDiscardEmployee)}
        >
          <UserRemove size="16" color="#EB5757" variant="TwoTone" />
          <p className="text-xs font-bold text-[#EB5757]">Discard Employee</p>
        </div>
      )}
    </div>
  )
}

// option for applicants in job page
export const ApplicantOptionInJobs = ({
  setOpenViewJobPostModal,
  openViewJobPostModal,
  openExtendDeadlineModal,
  setOpenExtendDeadlineModal,
  jobPostId,
  title,
  isPublished,
}: IApplicationOpenViewPost) => {
  const mutationChangeStatus = useChangeJobStatus(jobPostId as string)
  const { isComponentVisible, setIsComponentVisible } = useComponentVisible()

  const handleChangeStatus = () => {
    mutationChangeStatus.mutate(
      {
        status: isPublished ? 'unpublished' : 'published',
      },
      {
        onSuccess: () => {
          setIsComponentVisible(false)
        },
      }
    )
  }

  const handleCloseModal = () => {
    setIsComponentVisible(false)
  }

  const handleExtendDeadline = () => {
    setOpenExtendDeadlineModal(!openExtendDeadlineModal)
  }

  return (
    <>
      <div className="absolute top-12 right-5 z-10 w-[90%] max-w-[201px] overflow-hidden rounded-lg bg-white   shadow-lg">
        <button
          className="flex w-full cursor-pointer items-center  justify-start gap-3 px-4 py-[9px] pt-4 transition ease-in-out hover:bg-[#E5E5E5]"
          onClick={() => setOpenViewJobPostModal(!openViewJobPostModal)}
        >
          <Eye size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">View Job Post</p>
        </button>
        <Link
          href={`/recruiter/dashboard/jobs/applicants/${jobPostId}`}
          className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <Eye size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">View Applicants</p>
        </Link>
        <Link
          href={`/recruiter/dashboard/post-job?postId=${jobPostId}`}
          className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <Edit size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Edit Job Post</p>
        </Link>
        <button
          onClick={handleExtendDeadline}
          className="flex w-full cursor-pointer items-center justify-start gap-3 px-4 py-[9px] transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <ArrowSquareUp size="16" color="#000" variant="TwoTone" />
          <p className="text-xs font-bold">Extend deadline</p>
        </button>
        {/* <div className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]">
        <Clock size="16" color="#000" variant="TwoTone" />
        <p className="text-xs font-bold">Extend Deadline</p>
      </div> */}
        {/* <div className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]">
        <ExportCurve size="16" color="#000" variant="TwoTone" />
        <p className="text-xs font-bold">Share Job Post</p>
      </div>
      <div className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px]  transition ease-in-out hover:bg-[#E5E5E5]">
        <Image
          src="/assets/spotlightOption.png"
          alt="spotlight-icon"
          width={16}
          height={16}
        />
        <p className="text-xs font-bold">Spotlight post</p>
      </div>
      <div className="flex cursor-pointer items-center  justify-start gap-3 px-4 py-[9px] pb-4 transition ease-in-out hover:bg-[#E5E5E5]">
        <Image
          src="/assets/syncOption.png"
          alt="spotlight-icon"
          width={16}
          height={16}
        />
        <p className="text-xs font-bold">Sync ATS</p>
      </div>
    */}
        <button
          onClick={() => setIsComponentVisible(true)}
          className="flex w-full items-center  justify-start gap-3 px-4 py-[9px] pb-4 transition ease-in-out hover:bg-[#E5E5E5]"
        >
          <Trash size="16" color="red" variant="TwoTone" />
          <p className="mt-1.5 text-xs font-bold text-red-600">
            {isPublished ? 'Unpublish Post' : 'Publish Post'}
          </p>
        </button>
      </div>

      <ModalChangeStatus
        title={title}
        isPublished={isPublished}
        isLoading={mutationChangeStatus.isLoading}
        showDialog={isComponentVisible}
        closeModal={handleCloseModal}
        handleChangeStatus={handleChangeStatus}
      />
    </>
  )
}
