import { AnimatePresence, motion } from 'framer-motion'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, { useRef, useEffect } from 'react'
import { BsXLg } from 'react-icons/bs'
import clsx from 'clsx'
// import { CgAsterisk } from 'react-icons/cg'


// import dynamic from 'next/dynamic'
// import Image from 'next/image'
import dayjs from 'dayjs'
// import clsx from 'clsx'

dayjs.extend(relativeTime)

import { backdropVariant, jobDetailsModalVariant } from '../../utils/animation'
import { InternalJobs } from '../InternalJobs'




interface JobDetailsProps {
  jobId: string
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
}

export const JobDetails: React.FC<JobDetailsProps> = ({
  jobId,
  modalOpen,
  setModalOpen,
}) => {
  const [openReportJob, setOpenReportJob] = React.useState(false)
  const [openShareJob, setOpenShareJob] = React.useState(false)
  const [openCompanyProfile, setOpenCompanyProfile] = React.useState(false)


  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [modalOpen])

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          className="fixed top-0 left-0 z-[400] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            className={clsx("scrollbar-hide absolute bottom-0 left-0 z-50 h-[100%] w-full  rounded-t-3xl bg-white px-5  py-20 lg:px-16", openReportJob || openCompanyProfile ? 'overflow-hidden': 'overflow-scroll')}
            variants={jobDetailsModalVariant}
            ref={ref}
          >
            <button
              type="button"
              className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
              onClick={() => setModalOpen(false)}
            >
              <BsXLg />
            </button>
            <InternalJobs
              modalOpen={modalOpen}
              openReportJob={openReportJob}
              setOpenReportJob={setOpenReportJob}
              jobId={jobId}
              openShareJob={openShareJob}
              setOpenShareJob={setOpenShareJob}
              openCompanyProfile={openCompanyProfile}
              setOpenCompanyProfile={setOpenCompanyProfile}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
