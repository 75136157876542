import React from 'react'
import DateTimeDisplay from './DateTimeDisplay'
import { useCountdown } from '../hooks/useCountdown'

const JobClosedNotice = () => {
  return (
    <div className="">
      <span className='text-red-700'>Job Closed!</span>
    </div>
  )
}

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number
  hours: number
  minutes: number
  seconds: number
}) => {
  return (
    <div className="flex space-x-2">
      <p>Closing in</p>
      <DateTimeDisplay value={days} type={'d'} isDanger={days <= 3} />
      <p>:</p>
      <DateTimeDisplay value={hours} type={'h'} isDanger={false} />
      <p>:</p>
      <DateTimeDisplay value={minutes} type={'m'} isDanger={false} />
      <p>:</p>
      <DateTimeDisplay value={seconds} type={'s'} isDanger={false} />
    </div>
  )
}

export const CountdownTimer = ({ targetDate }: { targetDate: Date }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  if (days + hours + minutes + seconds <= 0) {
    return <JobClosedNotice />
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    )
  }
}
