import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { experienceKeys, transformError } from '@/utils';
import {fetchData} from '@/utils';

export const useDeleteExperience = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError, string>(
    (experienceId: string) => fetchData(`/user/delete-work-experience/${experienceId}`, 'DELETE'),
    {
      onSuccess: () => {
        toast.success('Experience successfully deleted.');
        queryClient.invalidateQueries({
          queryKey: experienceKeys.lists(),
        });
      },
      onError: (error) => {
        toast.error(transformError(error));
      },
    }
  );
};
