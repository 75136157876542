import React from 'react'
import clsx from 'clsx'

import { Company } from '@/features/recruiter'
import Link from 'next/link'
import CompanyProfileModal from '../Modals/CompanyProfileModal'

interface RecruiterProfileDropDownProps {
  showDropDown: boolean
  company: Company | undefined
  handleLogout: () => void
  setShowDropDown: React.Dispatch<React.SetStateAction<boolean>>
}

export const RecruiterProfileDropDown = React.forwardRef<
  HTMLDivElement,
  RecruiterProfileDropDownProps
>(({ company, showDropDown, handleLogout, setShowDropDown }, ref) => {
  
  const { companyName, companyEmail, companyLogo } = company || {}

  const [openCompanyProfile, setOpenCompanyProfile] = React.useState(false)

  return (
    <div ref={ref} className="absolute top-[90px] z-[30] ml-[20px] -mt-[10px]">
      <div
        className={clsx(
          'rounded-lg border border-gray-100  bg-white  px-9 pt-7 pb-5 text-black shadow-lg',
          showDropDown ? 'block opacity-100' : 'hidden opacity-0'
        )}
      >
        <div className="[w-232px] flex items-center  gap-6 ">
          <div>
            <img
              className="profile-picture h-[50px] w-[50px]"
              src={companyLogo || ''}
              alt="profile picture"
            />
          </div>
          <div className=" leading-1 ">
            <p className="block text-sm font-bold">{companyName}</p>
            <p className="mt-1 block text-xs">{companyEmail || '-'}</p>
          </div>
        </div>
        <div className="mt-5 leading-7">
          <Link
            href="/recruiter/dashboard/company"
             onClick={
              () =>{ 
                setShowDropDown(false)
              }
            }
              className="py-2 block text-xs"
            >
              Company Profile
            </Link>
          <Link
            onClick={() => setShowDropDown(false)}
            href="/recruiter/settings"
            className="py-2 block border-b pb-4 text-xs"
          >
            Settings
          </Link>
          <button
            className="mt-2 block w-full pb-4 text-left text-xs  text-red-400"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      <CompanyProfileModal openCompanyProfile={openCompanyProfile} setOpenCompanyProfile={setOpenCompanyProfile}/>
    </div>
  )
})

RecruiterProfileDropDown.displayName = 'RecruiterProfileDropDown'
