import { Control, useFormContext, useFieldArray } from 'react-hook-form'
import { nanoid } from 'nanoid'
import React from 'react'
import clsx from 'clsx'

import { Checkbox, InputDateField, Label, SelectField } from '@/components'
import { InterviewSlot, ScheduleInterviewForm } from '../types'
import {
  AVAILABLE_SLOT_OPTIONS,
  EMPTY_OPTION,
  INTERVIEW_DURATION_OPTIONS,
} from '@/utils'
import { Slot } from '.'

interface ScheduleOptionTwoProps {
  index: number
}

export const ScheduleOptionTwo: React.FC<ScheduleOptionTwoProps> = ({
  index,
}) => {
  const [isSameDuration, setIsSameDuration] = React.useState(true)
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<ScheduleInterviewForm>()

  const availableSlot = watch(`interviewRounds.${index}.availableSlot`)

  const interviewSlots = useFieldArray({
    control,
    name: `interviewRounds.${index}.interviewSlots`,
  })

  const interviewRoundErrorObj = errors.interviewRounds?.[index]

  React.useEffect(() => {
    const numberOfSlot = parseInt(availableSlot.id)
    if (numberOfSlot > 0) {
      const newSlots: InterviewSlot[] = Array.from(
        { length: numberOfSlot },
        () => ({
          id: nanoid(),
          interviewDate: null,
          interviewStartTime: null,
          interviewDuration: EMPTY_OPTION,
        })
      )
      interviewSlots.replace(newSlots)
    }
  }, [availableSlot])

  const interviewSlotsWatch = watch(`interviewRounds.${index}.interviewSlots`)
  const firstInterviewSlotDuration = watch(
    `interviewRounds.${index}.interviewSlots.0.interviewDuration`
  )

  React.useEffect(() => {
    if (firstInterviewSlotDuration?.id && isSameDuration) {
      const newInterviewSlots = interviewSlotsWatch.map((slot) => ({
        ...slot,
        interviewDuration: interviewSlotsWatch[0].interviewDuration,
      }))
      interviewSlots.replace(newInterviewSlots)
    }
  }, [firstInterviewSlotDuration?.id, isSameDuration])

  const handleSameDuration = () => {
    setIsSameDuration((prev) => !prev)
  }

  return (
    <div>
      <div className="my-7">
        <Label id="availableSlot" isRequired>
          Available Slots
        </Label>
        <SelectField
          control={control as unknown as Control}
          hasError={interviewRoundErrorObj?.availableSlot}
          arr={AVAILABLE_SLOT_OPTIONS}
          name={`interviewRounds.${index}.availableSlot`}
        />
        <div className="mt-3 flex items-center space-x-2">
          <Checkbox
            id={`same-duration-${index}`}
            checked={isSameDuration}
            onChange={handleSameDuration}
          >
            Use same duration for all
          </Checkbox>
        </div>
      </div>

      <div className="my-7">
        {interviewSlots.fields.map((field, idx) => {
          const isFirst = idx === 0
          const isLast = idx === interviewSlots.fields.length - 1
          const isDurationDisabled = isSameDuration && !isFirst
          return (
            <div
              className={clsx(
                'mb-7 grid items-center gap-7 border-b sm:grid-cols-3 sm:gap-3 sm:border-b-0 sm:pb-0',
                isLast ? 'border-b-0 pb-0' : 'pb-7'
              )}
              key={field.id}
            >
              <div>
                <InputDateField
                  label={isFirst ? 'Date Options' : ''}
                  control={control as unknown as Control}
                  placeholder="Date option"
                  minDate={new Date()}
                  name={`interviewRounds.${index}.interviewSlots.${idx}.interviewDate`}
                  hasError={
                    interviewRoundErrorObj?.interviewSlots?.[idx]?.interviewDate
                  }
                  isRequired
                />
              </div>
              <div>
                <InputDateField
                  label={isFirst ? 'Start Time' : ''}
                  control={control as unknown as Control}
                  placeholder="Start time"
                  name={`interviewRounds.${index}.interviewSlots.${idx}.interviewStartTime`}
                  hasError={
                    interviewRoundErrorObj?.interviewSlots?.[idx]
                      ?.interviewStartTime
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  isRequired
                />
              </div>
              <div>
                {isFirst && (
                  <Label id="duration" isRequired>
                    Duration
                  </Label>
                )}
                <SelectField
                  control={control as unknown as Control}
                  hasError={
                    interviewRoundErrorObj?.interviewSlots?.[idx]
                      ?.interviewDuration
                  }
                  placeholder="Select duration"
                  arr={INTERVIEW_DURATION_OPTIONS}
                  name={`interviewRounds.${index}.interviewSlots.${idx}.interviewDuration`}
                  isDisabled={isDurationDisabled}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div>
        {interviewSlotsWatch && interviewSlotsWatch.length > 0 && (
          <>
            <h3 className="text-sm font-semibold">Interview Slots</h3>
            <div className="mt-3 grid gap-4 text-center sm:grid-cols-3 md:grid-cols-4">
              {interviewSlotsWatch.map((slot) => (
                <Slot key={slot.id} slot={slot} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
