import { AnimatePresence, motion } from 'framer-motion'
import { BsXLg } from 'react-icons/bs'
import { useEffect, useRef } from 'react'
// import { Calendar2, Clock } from 'iconsax-react'

import { useAcceptOffer, useDeclineOffer, useJob } from '@/features/job-seeker'
import { ViewJobPostAnim, backdropVariant } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { Button } from '../Elements'

interface OfferLetterModalProps {
  openOfferResponseModal: boolean
  setOpenOfferResponseModal: (value: boolean) => void
  jobId: string
}

export const OfferResponseModal: React.FC<OfferLetterModalProps> = ({
  openOfferResponseModal,
  setOpenOfferResponseModal,
  jobId,
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const userId = userIDStorage.getUserID()
  const mutateAcceptOffer = useAcceptOffer()
  const mutateDeclineOffer = useDeclineOffer()

  const payload = {
    userId: userId,
    jobId: jobId,
  }

  const declinePayload = {
    userId: userId,
    jobId: jobId,
    reason: ''
  }

  const { data } = useJob(jobId)

  const {
    companyName,
    companyLocation,
    companyLogo,
    title,
    // employeeCount,
  } = data?.data || {}


  const handleAcceptOffer = () => {
    mutateAcceptOffer.mutate(payload, {
      onSuccess: () => {
        setOpenOfferResponseModal(false)
      }
    })
  }

  const handleDeclineOffer = () => {
    mutateDeclineOffer.mutate(declinePayload, {
      onSuccess: () => {
        setOpenOfferResponseModal(false)
      }
    })
  }

  //   form resolver

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openOfferResponseModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenOfferResponseModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openOfferResponseModal])

  return (
    <AnimatePresence>
      {openOfferResponseModal && (
        <motion.div
          className=" scrollbar-hide absolute top-0 right-0 z-[100] h-[100vh] w-screen overflow-y-scroll bg-black bg-opacity-40 text-left text-black"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            variants={ViewJobPostAnim}
            initial="initial"
            animate="show"
            ref={ref}
            exit="exit"
            className="absolute top-0 right-0 min-h-screen w-[85%] bg-white px-12 pb-10 md:w-[630px]"
          >
            <div className="sticky top-0 flex items-center justify-between gap-4 bg-white pt-[20px] pb-2">
              <h3>Offer Details</h3>
              <button
                className="top-14 right-0 rounded-full bg-gray-200 p-2 text-[9px] "
                onClick={() => setOpenOfferResponseModal(false)}
              >
                <BsXLg />
              </button>
            </div>
            <div className="mt-6">
              <div className="flex items-start gap-2">
                <img
                  src={companyLogo || ''}
                  className="h-[57px] w-[57px] min-w-[57px] rounded-md"
                  alt=""
                />
                <div className="">
                  <p className="text-sm font-bold">{companyName || ''}</p>
                  <p className="text-xs">{companyLocation || ''}</p>
                </div>
              </div>
            </div>
            <div>
              <p className="mt-10 text-[20px] font-bold">{title || ''}</p>
              {/* <div className="flex items-center gap-4">
                <div className="flex items-center gap-4">
                  <Calendar2 size="16" color="#000" variant="TwoTone" />
                  <p>Expires Wenesday 22nd Mar 2023</p>
                </div>
                <BsDot />
                <div className="flex items-center gap-2">
                  <Clock size="16" color="#000" variant="TwoTone" />
                  <p>1:00 PM</p>
                </div>
              </div> */}
            </div>

            {/* place to download offer letter */}
            <div>

            </div>
            <div className='flex items-center justify-center gap-5 mt-10'>
              <Button onClick={handleDeclineOffer} isLoading={mutateDeclineOffer.isLoading} variant='dangerTwo'>{mutateAcceptOffer.isLoading ? "Declining..." : "Decline"}</Button>
              <Button onClick={handleAcceptOffer} isLoading={mutateAcceptOffer.isLoading} >{mutateAcceptOffer.isLoading ? "Accepting" : "Accept"}</Button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
