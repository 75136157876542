
import { AxiosError } from 'axios'
import dayjs from 'dayjs'

import { AllJobsForApprovalData, Interview, IntervieweeData, Job, JobApplicant } from '@/features/job-seeker'
import { CompanyJob } from '@/features/recruiter'
import { AllCompanyList, Currency, Posts } from '@/types'

export const transformError = (error: AxiosError) =>
  (error.response?.data as AxiosError).message

export const addCommaToNumber = (num: number) => {
  return num.toLocaleString()
}

export const getFormattedTimeAgoText = (date: string | undefined) => {
  if (!date) return ''
  return dayjs().to(dayjs(date))
}

export const getFormattedDayMonthYear = (date: Date | null) => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DD')
}

export const getFormattedDayMonth = (date: string | undefined) => {
  if (!date) return ''
  return dayjs(date).format('MMM DD')
}

export const getFormattedDayMonthYearWords = (date: string | undefined) => {
  if (!date) return ''
  return dayjs(date).format('DD MMMM, YYYY')
}

export const getFormattedHourMinSec = (date: Date | null) => {
  if (!date) return ''
  return dayjs(date).format('HH:mm:ss')
}

export const getDates = (startDate: Date, stopDate: Date) => {
  const dateArray = []
  let currentDate = dayjs(startDate)
  const stopDateInDayjs = dayjs(stopDate)
  while (currentDate <= stopDateInDayjs) {
    dateArray.push(getFormattedDayMonthYear(currentDate.toDate()))
    currentDate = dayjs(currentDate).add(1, 'days')
  }
  return dateArray
}

export const getAge = (birthDateInput:string) => {
  const today = new Date()
  const birthDate = new Date(birthDateInput)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age -= 1
  return age
}

export const filterJob = (job: Job | CompanyJob  , searchTerm: string) => {
  const jobLevel = job.level || ''
  const jobTitle = job.title.toString().toLowerCase()
  const jobLevelLowercase = jobLevel.toString().toLowerCase()
  const employmentType = job.employmentType.toString().toLowerCase()
  const workType = job.workType.toString().toLowerCase()
  const companyName = job?.companyName?.toString().toLowerCase()
  const jobLocation =  job?.jobLocation?.toString().toLowerCase()

  const searchTermLowercased = searchTerm.toString().toLowerCase()

  if (searchTermLowercased === '') {
    return job
  } else if (
    jobTitle.includes(searchTermLowercased) ||
    jobLevelLowercase.includes(searchTermLowercased) ||
    employmentType.includes(searchTermLowercased) ||
    workType.includes(searchTermLowercased) ||
    jobLocation?.includes(searchTermLowercased) || 
    companyName?.includes(searchTermLowercased)
  ) {
    return job
  }
}

export const filterApprovalJobs = (job:AllJobsForApprovalData , searchTerm: string) => {
  const jobLevel = job.level || ''
  const jobTitle = job.title.toString().toLowerCase()
  const jobLevelLowercase = jobLevel.toString().toLowerCase()
  const employmentType = job.employmentType.toString().toLowerCase()
  const workType = job.workType.toString().toLowerCase()
  const searchTermLowercased = searchTerm.toString().toLowerCase()

  if (searchTermLowercased === '') {
    return job
  } else if (
    jobTitle.includes(searchTermLowercased) ||
    jobLevelLowercase.includes(searchTermLowercased) ||
    employmentType.includes(searchTermLowercased) ||
    workType.includes(searchTermLowercased) 
  ) {
    return job
  }
}



export const filterInterview = (interview: Interview | IntervieweeData, searchTerm: string) => {
  const name = (interview.firstName + ' ' + interview.lastName).toLowerCase()

  const searchTermLowercased = searchTerm.toString().toLowerCase()

  if (searchTermLowercased === '') {
    return interview
  } else if (name.includes(searchTermLowercased)) {
    return interview
  }
}
export const filterMember = (interview: IntervieweeData, searchTerm: string) => {
  const name = (interview.firstName + ' ' + interview.lastName).toLowerCase()

  const searchTermLowercased = searchTerm.toString().toLowerCase()

  if (searchTermLowercased === '') {
    return interview
  } else if (name.includes(searchTermLowercased)) {
    return interview
  }
}

export const filterApplicant = (
  applicant: JobApplicant,
  searchTerm: string
) => {
  const applicantName =
    `${applicant.firstName} ${applicant.lastName}`.toLowerCase()
  const email = applicant.email.toString().toLowerCase()
  const phoneNumber = applicant.phone.toString().toLowerCase()
  const searchTermLowercased = searchTerm.toString().toLowerCase()

  if (searchTermLowercased === '') {
    return applicant
  } else if (
    applicantName.includes(searchTermLowercased) ||
    email.includes(searchTermLowercased) ||
    phoneNumber.includes(searchTermLowercased)
  ) {
    return applicant
  }
}



export const filterApplicantMain = (
  applicant: JobApplicant,
  searchTerm: string,
  tagsArray: {name: string, index:number, tag: string | number}[],
) => {
  // const allApplicantValues = Object.values(applicant).map((value) => value && value.toString().toLowerCase())

  const allApplicantValues = Object.entries(applicant)
  .filter(([key]) => key !== 'experienceLength' && key !== 'nyscCompletionYearAgo')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .map(([_, value]) => value && value.toString().toLowerCase());

  const workPlaceType = allApplicantValues[allApplicantValues.length - 1]
  const workPlaceTypeValue = workPlaceType ? workPlaceType.split(',') : []
 const allValues = [...allApplicantValues?.slice(0, allApplicantValues.length - 1), ...workPlaceTypeValue]
 const yearsOfExperience = applicant.experienceLength?.toString().toLowerCase()
 const nyscYearsValue = getAge(applicant.nyscCompletionDate as string)
 const ageValue = getAge(applicant.dateOfBirth)

  const tagFilters = tagsArray && tagsArray?.every(items => {
    // this part of the filter adds tags that are numbers to the filter so the date of birth, years of experience and nysc years can be filtered. when it's available in the date it'll be tested with this.
    if (items.index === 0) {
      const yearsOfExperienceNumber = parseInt(yearsOfExperience as string)
      const tagNumber = parseInt(items.tag.toString())
      console.log({items})
      return yearsOfExperienceNumber >= tagNumber
    } else if(items.index === 4) {
      const tagNumber = parseInt(items.tag.toString())
      return nyscYearsValue >= tagNumber
    } else if (items.index === 5) {
      const [minAge, maxAge] = items.tag.toString().split('-').map(Number)
      return ageValue >= minAge && ageValue <= maxAge
    } else {
      return allValues.includes(items?.name?.toLowerCase())
    }

  })

  const applicantName =
    `${applicant?.firstName} ${applicant?.lastName}`?.toLowerCase()
  const email = applicant?.email?.toString()?.toLowerCase()
  const phoneNumber = applicant?.phone?.toString()?.toLowerCase()
  const jobSearchStatus = applicant?.jobSearchStatus?.toString()?.toLowerCase()
  const searchTermLowercased = searchTerm?.toString()?.toLowerCase()


  if (searchTermLowercased === '' && tagsArray?.length === 0) {
    return applicant
  } else if (
    (applicantName?.includes(searchTermLowercased) ||
    email?.includes(searchTermLowercased) ||
    phoneNumber?.includes(searchTermLowercased) || jobSearchStatus?.includes(searchTermLowercased)) && tagFilters
  ) {
    return applicant
  }
}

export const filterCurrency= ( currencies:Currency, searchTerm:string) => {
  const searchWord = searchTerm.toString().toLowerCase()
  const countryNames = currencies.countryName.toString().toLowerCase()
  const currencyNames = currencies.name.toString().toLowerCase()
  const currencySymbols = currencies.symbol.toString().toLowerCase()

  if (searchTerm === ''){
    return currencies
  } else if (
    countryNames.includes(searchWord) || currencyNames.includes(searchWord) || currencySymbols.includes(searchWord)
  ) {
    return currencies
  }
} 

export const filterCompany = (companyList:AllCompanyList, searchTerm: string) => {
  const searchWord = searchTerm?.toString()?.toLowerCase()
  const companyEmail  = companyList.companyEmail?.toString()?.toLowerCase()
  const companyName = companyList.companyName?.toString()?.toLowerCase()
  const companyLocation = companyList.companyLocation?.toString()?.toLowerCase()
  const companyIndustry = companyList.industry?.toString()?.toLowerCase()
  const companyEmployeeSize = companyList.employeeCount?.toString()?.toLowerCase()

  if (searchTerm === '') {
    return companyList
  } else if (companyEmail?.includes(searchWord) || companyName?.includes(searchWord) || companyLocation?.includes(searchWord) || companyIndustry?.includes(searchWord) || companyEmployeeSize?.includes?.(searchWord) 
  ) {
    return companyList
  }
}

export const filterArticles = (articleList:Posts, searchTerm:string) => {
  const searchWord = searchTerm?.toString()?.toLowerCase()
  const articleTitle = articleList.title?.toString()?.toLowerCase()
  const articleCategory = articleList.category?.toString()?.toLowerCase()
  const articleTags = articleList.tags.map((tags) => tags.toString().toLowerCase())

  if(searchTerm === '') {
    return articleList
  } else if (articleTitle?.includes(searchWord) || articleCategory?.includes(searchWord) || articleTags?.some((tag) => tag.includes(searchWord))) {
    return articleList
  }
}

export const checkIfDateIsAfter = (date: Date | string | undefined) => {
  if (!date) return false
  return dayjs().isAfter(date, 'day')
}

