import clsx from 'clsx'
import { ArrowDown2 } from 'iconsax-react'
import React from 'react'

interface DropDownFilterProps {
  filterTitle: string
  filterOptions:
    | {
        name: string
        id: number | string
      }[]
  index: number | null
  handleSelectTag: (value: number | null) => void
  openFilterTab: number | null
  setOpenFilterTab: React.Dispatch<React.SetStateAction<null | number>>
  filterTagsArray: { tag: string | number; name: string; index: number }[]
  isRotating: boolean
  setFilterTagsArray: React.Dispatch<
    React.SetStateAction<
      { tag: string | number; name: string; index: number }[]
    >
  >
}

export const DropDownFilter = ({
  filterTitle,
  filterOptions,
  openFilterTab,
  handleSelectTag,
  setOpenFilterTab,
  index,
  setFilterTagsArray,
  filterTagsArray,
  isRotating,
}: //   ref,
//   dropDownButtonRef,
DropDownFilterProps) => {
  const [selectedOpt, setSelectedOpt] = React.useState(filterTitle)
  const [min, setMin] = React.useState<null | number>(null)
  const [max, setMax] = React.useState<null | number>(null)

  React.useEffect(() => {
    setSelectedOpt(filterTitle)
  }, [isRotating])

  const ref = React.useRef<HTMLDivElement>(null)
  const dropDownButtonRef = React.useRef<HTMLButtonElement>(null)

  const handleClickOutside = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    if (
      openFilterTab &&
      ref.current &&
      !ref.current.contains(e.target) &&
      !dropDownButtonRef?.current?.contains(e.target)
    ) {
      setOpenFilterTab(null)
    }
  }

  const handleHideDropDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setOpenFilterTab(null)
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleHideDropDown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleHideDropDown)
    }
  }, [openFilterTab])

  const handleSelectedOption = (name: string, id: string | number) => {
    setSelectedOpt(name)
    setOpenFilterTab(null)
    if (filterTagsArray && filterTagsArray.find((tag) => tag.index === index)) {
      const filterTagsArrayCopy = [...filterTagsArray]
      filterTagsArrayCopy.splice(
        filterTagsArrayCopy.findIndex((tag) => tag.index === index),
        1
      )
      if (index !== 0 && index !== null && index !== 4 && index !== 5) {
        setFilterTagsArray([
          ...filterTagsArrayCopy,
          { tag: name, name: name, index: index || 0 },
        ])
      } else if (index === 0 || index === 4) {
        setFilterTagsArray([
          ...filterTagsArrayCopy,
          { tag: id, name: name, index: index || 0 },
        ])
      } else if (index === 5) {
        setFilterTagsArray([
          ...filterTagsArrayCopy,
          { tag: `${min}-${max}`, name: name, index: index || 0 },
        ])
      }
    } else {
      // index === 0
      //   ? setFilterTagsArray([
      //       ...filterTagsArray,
      //       { tag: id, name: name, index: index || 0 },
      //     ])
      //   : setFilterTagsArray([
      //       ...filterTagsArray,
      //       { tag: name, name: name, index: index || 0 },
      //     ])
      if (index !== 0 && index !== null && index !== 4 && index !== 5) {
        setFilterTagsArray([
          ...filterTagsArray,
          { tag: name, name: name, index: index || 0 },
        ])
      } else if (index === 0 || index === 4) {
        setFilterTagsArray([
          ...filterTagsArray,
          { tag: id, name: name, index: index || 0 },
        ])
      } else if (index === 5) {
        setFilterTagsArray([
          ...filterTagsArray,
          { tag: `${min}-${max}`, name: name, index: index || 0 },
        ])
      }
    }
  }


  return index !== null && index !== 5 ? (
    <div className="relative">
      <button
        onClick={() => handleSelectTag(index)}
        ref={dropDownButtonRef}
        className="gray-100 flex w-[250px] items-center justify-between gap-2 whitespace-nowrap rounded-[5px] border px-2 py-2 text-xs font-[600] text-black"
      >
        <p className="whitespace-nowrap border-gray-150">{selectedOpt}</p>
        <ArrowDown2 size="10" color="#000" />
      </button>
      {openFilterTab === index && (
        <div
          ref={ref}
          className={clsx(
            'absolute z-[2] mt-2 min-w-[250px] rounded-[5px] border border-gray-150 bg-gray-100 shadow-sm',
            (index === 1 || index === 0 || index === 4 || index === 6) &&
              'max-h-[300px] overflow-y-scroll ',
            selectedOpt
          )}
        >
          {filterOptions.map((data, index) => {
            return (
              <button
                onClick={() => handleSelectedOption(data.name, data.id)}
                className={clsx(
                  'w-full border-b px-2 py-2 text-left text-xs font-[500] transition-colors ',
                  selectedOpt === data.name
                    ? 'bg-black text-white'
                    : 'bg-transparent hover:bg-gray-200'
                )}
                key={index}
              >
                {data.name}
              </button>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    <div className="relative">
      <button
        onClick={() => handleSelectTag(index)}
        ref={dropDownButtonRef}
        className="gray-100 flex w-[250px] items-center justify-between gap-2 whitespace-nowrap rounded-[5px] border px-2 py-2 text-xs font-[600] text-black"
      >
        <p className="whitespace-nowrap border-gray-150">{selectedOpt}</p>
        <ArrowDown2 size="10" color="#000" />
      </button>
      {openFilterTab === index && (
        <div
          ref={ref}
          className={clsx(
            'absolute z-[2] mt-2 min-w-[250px] rounded-[5px] border border-gray-150 bg-gray-100 shadow-sm',
            selectedOpt
          )}
        >
          <div
            className={clsx(
              ' w-[250px] border-b px-2 py-2 text-left text-xs transition-colors '
            )}
            key={index}
          >
            <div className="flex w-full items-start justify-between gap-4">
              <div className="">
                <p className="text-xs font-medium">Min</p>
                <input
                  value={min as number}
                  onChange={(e) => setMin(+e.target.value)}
                  type="number"
                  className="mt-2 h-[30px] w-full rounded-sm border px-2 outline-none"
                />
              </div>
              <div className="">
                <p className="text-xs font-medium">Max</p>
                <input
                  value={max as number}
                  onChange={(e) => setMax(+e.target.value)}
                  type="number"
                  className="mt-2 h-[30px] w-full rounded-sm border px-2 outline-none"
                />
              </div>
            </div>
            <button
              onClick={() => handleSelectedOption('age', `${min}-${max}`)}
              className="mt-4 rounded-sm bg-black px-3 py-1 text-xs font-semibold text-white"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

// this
