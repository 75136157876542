import { useQuery, useQueryClient } from '@tanstack/react-query'

import { userIDStorage } from '@/features/auth'
import { companyKeys } from '@/utils'
import { fetchData } from '@/utils'
import { Company } from '../types'

interface CompanyResponse {
  status: string
  message: string
  data: Company[]
}

export const useCompanies = (userId = '') => {
  const queryClient = useQueryClient()

  const userID = userIDStorage.getUserID() || userId

  queryClient.invalidateQueries({
    queryKey: companyKeys.list(userID),
  })
  return useQuery<CompanyResponse>({
    queryKey: companyKeys.list(userID),
    queryFn: async () => fetchData(`/company/user-companies/${userID}`),
    enabled: !!userID
  })
}
