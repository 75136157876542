import { useQuery } from '@tanstack/react-query'

import { experienceKeys, fetchData } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { Experience } from '../types'

interface ExperienceResponse {
  status: string
  message: string
  data: Experience[]
}

export const useExperiences = () => {
  const userId = userIDStorage.getUserID();
  return useQuery<ExperienceResponse>({
    queryKey: experienceKeys.list(userId),
    queryFn: async () => fetchData(`/user/work-experiences/${userId}`),
    enabled: !!userId
  })
}