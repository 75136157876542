import { ArrowDown2, ClipboardImport, ClipboardTick, Copy } from 'iconsax-react'
import React from 'react'
import clsx from 'clsx'
import { Tooltip } from '@mui/material'
import { FeedbackFormModal, InterviewerFeedback } from './modals'
import { interviewRoundProp } from '@/features/job-seeker'
import dayjs from 'dayjs'
import { useClipboard } from '@/components/Elements/useClipboard'
import { useGetInterviewFeedback } from '../api'
import { useUserInfo } from '@/hooks'

// import { StarRatingInput } from '@/components'

export interface InterviewSectionCardProp {
  roundsData: interviewRoundProp
  setOpenFeedbackFormModal: (value: boolean) => void
  openFeedbackFormModal: boolean
  interviewId: string
  userId: string
  key: number
  giveAccess: boolean | string | null
  pending: boolean
  hasDeclined: boolean
}

const InterviewSectionCard = ({
  roundsData,
  openFeedbackFormModal,
  userId,
  pending,
  interviewId,
  giveAccess,
  hasDeclined,
  setOpenFeedbackFormModal,
}: InterviewSectionCardProp) => {
  const [showMore, setShowMore] = React.useState(false)
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null)
  const [openInterviewerFeedback, setOpenInterviewerFeedback] =
    React.useState(false)
  const { copy } = useClipboard()

  const { data: feedbackData, isLoading: isLoadingFeedback } =
    useGetInterviewFeedback(interviewId)

  const { user } = useUserInfo()

  const feedbackInfo = feedbackData?.data

  const interviewerHasFeedback = feedbackInfo?.some(
    (interviewerName) =>
      interviewerName.firstName.toLowerCase() == user?.firstName.toLowerCase()
  )

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  const handleMouseOver = (index: number) => {
    setHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredIndex(null)
  }

  const handleFeedback = () => {
    if (interviewerHasFeedback) {
      return setOpenInterviewerFeedback(true)
    }
    return setOpenFeedbackFormModal(true)
  }

  return (
    <div
      className={clsx(
        'mt-10 rounded-md border-t-[3px] bg-white shadow-md',
        !roundsData.hasAccepted ? 'border-t-red-600' : 'border-t-primary'
      )}
    >
      <div className="py-10 px-14 ">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-12">
            <div className="mb-4 flex items-center justify-between gap-6">
              <p className="text-lg font-bold capitalize">
                {roundsData.interviewRound}
              </p>
              {roundsData.hasAccepted && (
                <p className="rounded-md bg-primary px-6 py-2 text-xs font-[600] text-white">
                  Interview Accepted
                </p>
              )}
              {/* <p className='bg-red-100 px-6 py-2 text-xs text-red-400 rounded-md'>{hasDeclined && <p>{roundsData.reasonForDecline}</p>}</p> */}
            </div>
            {/* <p className="bg-primary px-4 py-1 text-xs text-white">Completed</p> */}
          </div>
          <div>
            {/* tooltip on hover */}
            {/* <div className="flex items-center gap-3">
              <Tooltip title="Mark 'as no show'"><div><ProfileDelete size="32" color="#FF8A65" variant="Bulk"/></div></Tooltip>
              <Tooltip title="Edit Interview"><div><Edit size="32" color="#FF8A65" variant="Bulk"/></div></Tooltip>
              <Tooltip title="cancel interview"><div><Trash size="32" color="#EB5757" variant="Bulk"/></div></Tooltip>
            </div> */}
            {!isLoadingFeedback && !hasDeclined && !pending && (
              <button
                onClick={handleFeedback}
                className="flex items-center gap-3 rounded-md bg-black px-4 py-2 text-white"
              >
                {!isLoadingFeedback && (
                  <p className="text-xs font-bold">
                    {interviewerHasFeedback ? 'View Feedback' : 'Add Feedback'}
                  </p>
                )}
                <ClipboardImport size="15" color="#f7f4f4" variant="Bulk" />
              </button>
            )}
            {pending && (
              <p className="mt-2 rounded-md bg-red-100 px-6 py-2 text-xs font-[600] text-red-400">
                Candidate is yet to select an interview schedule
              </p>
            )}
          </div>
          {hasDeclined && (
            <p className="rounded-md bg-red-100 px-6 py-2 text-xs font-[600] text-red-400">
              Interview declined
            </p>
          )}
        </div>
        {hasDeclined && (
          <p className="mt-4 text-xs text-red-400">
            Decline reason: {roundsData.reasonForDecline}
          </p>
        )}
        {/* date view in show more */}
        {showMore &&
          (giveAccess ? (
            <div className="mt-10 mb-[60px] flex items-center gap-3">
              {roundsData?.interviewDate && (
                <div className="flex min-w-[145px] flex-col items-center justify-center rounded-lg bg-[#2B2B2B] text-white">
                  {roundsData?.interviewDate
                    ?.split('-')
                    .map((content, index) => (
                      <p
                        className={clsx(
                          'w-full py-3 text-center',
                          index == 0
                            ? 'border-b border-b-[#4F4F4F] text-xs'
                            : index == 1
                            ? 'border-y border-y-[#4F4F4F] text-[20px] font-bold'
                            : 'border-y border-y-[#4F4F4F] text-xs'
                        )}
                        key={index}
                      >
                        {content}
                      </p>
                    ))}
                  <p className="py-3 text-center text-xs">
                    {dayjs(
                      '2023-03-03' + roundsData?.interviewStartTime
                    ).format('hh:mm A')}{' '}
                  </p>
                </div>
              )}
              <div className="">
                <p className="w-fit rounded-[3px] bg-[#191919] bg-opacity-10 px-4 py-[2px] text-sm capitalize text-black">
                  {roundsData.interviewType}
                </p>
                {roundsData.interviewLink && (
                  <div className="flex items-center">
                    <p className="mt-3 flex h-[40px] w-[545px] items-center truncate rounded-[3px] bg-[#191919] bg-opacity-10 px-4 py-[6px] text-sm text-black underline">
                      {roundsData.interviewLink}
                    </p>
                    <button
                      onClick={() => copy(roundsData.interviewLink || '')}
                      className="mt-3 flex h-10 w-10 items-center justify-center"
                    >
                      <Copy size="18" color="#27AE60" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          ))}

        {/* date view in show less */}

        {!showMore && (
          <>
            {giveAccess && (
              <div>
                <div className="mt-4 flex items-center gap-3">
                  <p className="rounded-[3px] bg-[#191919] bg-opacity-10 px-4 py-[2px] text-xs text-black">
                    {dayjs(roundsData?.interviewDate).format('MMMM D, YYYY')}
                  </p>
                  <p className="rounded-[3px] bg-[#191919] bg-opacity-10 px-4 py-[2px] text-xs text-black">
                    {/* interview duration would be gotten from subtracting from the endTime  and fixed into the empty object*/}
                    {dayjs(
                      '2023-03-03' + roundsData?.interviewStartTime
                    ).format('hh:mm A')}{' '}
                    • {}
                  </p>
                  <p className="rounded-[3px] border-t border-t-white bg-[#191919] bg-opacity-10 px-4 py-[2px] text-xs text-black">
                    {roundsData.interviewType}
                  </p>
                </div>
              </div>
            )}
            <div>
              {/* <p className="text-red-400 text-xs mt-4">
                Decline reason: {roundsData.reasonForDecline}
              </p> */}
              {!giveAccess && !roundsData.reasonForDecline && (
                <p className="mt-2 rounded-md bg-red-100 px-6 py-2 text-xs font-[600] text-red-400">
                  Candidate is yet to select an interview schedule
                </p>
              )}
            </div>
          </>
        )}

        {/* interviewers section*/}

        <div className="mt-8">
          <p className="text-sm font-semibold">Interviewers</p>
          <div
            className={clsx(
              'mt-3',
              showMore
                ? 'mt-2 grid grid-cols-2 items-center gap-5 2xl:grid-cols-3'
                : 'flex gap-5'
            )}
          >
            {roundsData.interviewers.map((member, index) => (
              <div
                key={index}
                onClick={() => setOpenInterviewerFeedback(true)}
                onMouseOver={() => handleMouseOver(index)}
                onMouseLeave={() => handleMouseLeave()}
                className={clsx(
                  'gap mt-1 flex cursor-pointer items-center',
                  showMore ? 'flex items-center gap-4' : 'gap-0'
                )}
              >
                <Tooltip title={member.name}>
                  <button
                    key={index}
                    className="flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full bg-[#2F80ED] text-lg font-normal text-white"
                  >
                    {member?.name?.toString()[0]}
                  </button>
                </Tooltip>
                <button
                  className={clsx(
                    '',
                    showMore ? 'mt-1 text-left text-xs' : 'hidden'
                  )}
                >
                  <div className="flex items-end gap-8">
                    <p className="font-semibold">{member.name} </p>
                    {hoveredIndex === index && (
                      <button className="">
                        <ClipboardTick
                          className=""
                          size="18"
                          color="#27AE60"
                          variant="Bulk"
                        />
                      </button>
                    )}
                  </div>
                  <p>{member.email}</p>
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* interview scoreboard */}
        <div className={clsx(showMore ? 'my-[60px]' : 'mt-4')}>
          <p className="text-sm font-semibold">Interview Scorecard</p>
          <div className="mt-2 flex flex-wrap items-center gap-3">
            {roundsData.interviewScorecard.map((scoreCard, index) => (
              <p
                key={index}
                className="rounded-[3px] bg-black px-4 py-1 text-sm text-white"
              >
                {scoreCard.name}
              </p>
            ))}
          </div>
        </div>

        {/* Interview instructions */}
        {showMore && (
          <div>
            <p className="mt-12 text-sm font-semibold">
              Interview Instructions
            </p>
            {roundsData && (
              <div className="mt-4 flex h-[38px] items-center gap-4 rounded-md border border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 px-5">
                <p className="flex h-[18px] w-[18px] items-center justify-center rounded-full border-2 border-[#E2B93B] text-[#E2B93B]">
                  i
                </p>
                {roundsData.instruction && (
                  <p className="text-xs text-black">{roundsData.instruction}</p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <button
        onClick={handleShowMore}
        className="my-4 flex w-full items-center justify-center gap-2 bg-[#EFEFEF] py-3 text-primary"
      >
        {showMore ? (
          <p className="text-xs font-semibold">Show Less</p>
        ) : (
          <p className="text-xs font-semibold">Show More</p>
        )}
        <ArrowDown2 size="12" color="#27AE60" />
      </button>
      <FeedbackFormModal
        userId={userId}
        roundsData={roundsData}
        setOpenFeedbackFormModal={setOpenFeedbackFormModal}
        openFeedbackFormModal={openFeedbackFormModal}
      />
      <InterviewerFeedback
        interviewId={interviewId}
        roundsData={roundsData}
        openInterviewerFeedback={openInterviewerFeedback}
        setOpenInterviewerFeedback={setOpenInterviewerFeedback}
        feedbackData={feedbackInfo}
        isLoadingFeedback={isLoadingFeedback}
      />
    </div>
  )
}

export default InterviewSectionCard
