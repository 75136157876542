import { Control, useFormContext } from 'react-hook-form'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import setMinutes from 'date-fns/setMinutes'
import setHours from 'date-fns/setHours'
import { Trash } from 'iconsax-react'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import React from 'react'
import clsx from 'clsx'

import {
  INTERVIEW_DURATION_OPTIONS,
  getDates,
  getFormattedDayMonthYear,
  getFormattedHourMinSec,
} from '@/utils'
import { InterviewSlot, ScheduleInterviewForm, SlotProps } from '../types'
import { InputDateField, Label, SelectField } from '@/components'
import { Slot } from '.'

dayjs.extend(advancedFormat)

interface ScheduleOptionThreeProps {
  index: number
}

export const ScheduleOptionThree: React.FC<ScheduleOptionThreeProps> = ({
  index,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ScheduleInterviewForm>()

  const interviewRoundErrorObj = errors.interviewRounds?.[index]

  const slots = watch(`interviewRounds.${index}.slots`)

  const dateRangeStartDate = watch(
    `interviewRounds.${index}.dateRangeStartDate`
  )
  const dateRangeEndDate = watch(`interviewRounds.${index}.dateRangeEndDate`)
  const timeRangeStartTime = watch(
    `interviewRounds.${index}.timeRangeStartTime`
  )
  const timeRangeEndTime = watch(`interviewRounds.${index}.timeRangeEndTime`)
  const timeInterval = watch(`interviewRounds.${index}.timeInterval`)
  const interviewDuration = watch(`interviewRounds.${index}.interviewDuration`)

  const minTimeRangeEnd = setHours(setMinutes(new Date(), dayjs(timeRangeStartTime).get('minute')), dayjs(timeRangeStartTime).get('hour'))
  const maxTimeRangeEnd = setHours(setMinutes(new Date(), 55), 23)


  React.useEffect(() => {
    if (
      dateRangeStartDate &&
      dateRangeEndDate &&
      timeRangeStartTime &&
      timeRangeEndTime &&
      timeInterval.id &&
      interviewDuration.id
    ) {
      const newSlots: SlotProps[] = []

      const startDateAndTime = dayjs(
        getFormattedDayMonthYear(dateRangeStartDate) +
          'T' +
          getFormattedHourMinSec(timeRangeStartTime)
      )
      const startDateAndEndTime = dayjs(
        getFormattedDayMonthYear(dateRangeStartDate) +
          'T' +
          getFormattedHourMinSec(timeRangeEndTime)
      )
      const endDateAndTime = dayjs(
        getFormattedDayMonthYear(dateRangeEndDate) +
          'T' +
          getFormattedHourMinSec(timeRangeEndTime)
      )

      const dates = getDates(startDateAndTime.toDate(), endDateAndTime.toDate())

      const diff = startDateAndEndTime.diff(startDateAndTime, 'minute')
      const interval = parseInt(timeInterval.id)
      const duration = parseInt(interviewDuration.id)
      const minute = interval + duration
      const numberOfSlot = Math.floor(diff / minute)

      for (let i = 0; i <= dates.length; i++) {
        const currentStartDateAndTime = dayjs(
          dates[i] + 'T' + getFormattedHourMinSec(timeRangeStartTime)
        )
        const currentEndDateAndTime = dayjs(
          dates[i] + 'T' + getFormattedHourMinSec(timeRangeEndTime)
        )

        for (let j = 0; j <= numberOfSlot; j++) {
          const slot = currentStartDateAndTime.add(j * minute, 'minute')
          const lastDateDiff = currentEndDateAndTime
            .add(j * duration, 'minute')
            .diff(slot, 'minute')

          if (lastDateDiff >= minute) {
            newSlots.push({
              id: nanoid(),
              slotNumber: newSlots.length + 1,
              month: dayjs(slot).format('Do MMM, YY'),
              time: dayjs(slot).format('hh:mm a'),
              interviewDate: getFormattedDayMonthYear(slot.toDate()),
              interviewStartTime: getFormattedHourMinSec(slot.toDate()),
            })
          }
        }
      }
      setValue(`interviewRounds.${index}.slots`, newSlots)
    }
  }, [
    dateRangeStartDate,
    dateRangeEndDate,
    timeRangeStartTime,
    timeRangeEndTime,
    timeInterval.id,
    interviewDuration.id,
  ])

  return (
    <>
      <div className="grid gap-7 sm:grid-cols-2 sm:gap-x-5 sm:gap-y-7">
        <div>
          <Label isRequired>Date range</Label>
          <div className="flex gap-4">
            <InputDateField
              control={control as unknown as Control}
              placeholder="Date range start"
              name={`interviewRounds.${index}.dateRangeStartDate`}
              minDate={new Date()}
              hasError={interviewRoundErrorObj?.dateRangeStartDate}
              isRequired
            />
            <InputDateField
              control={control as unknown as Control}
              placeholder="Range end"
              name={`interviewRounds.${index}.dateRangeEndDate`}
              minDate={dateRangeStartDate ? dateRangeStartDate : new Date()}
              hasError={interviewRoundErrorObj?.dateRangeEndDate}
              isRequired
            />
          </div>
        </div>
        <div>
          <Label isRequired>Time range</Label>
          <div className="flex gap-4">
            <InputDateField
              control={control as unknown as Control}
              placeholder="Start time"
              hasError={interviewRoundErrorObj?.timeRangeStartTime}
              name={`interviewRounds.${index}.timeRangeStartTime`}
              showTimeSelect
              showTimeSelectOnly
              isRequired
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            <InputDateField
              control={control as unknown as Control}
              placeholder="End time"
              hasError={interviewRoundErrorObj?.timeRangeEndTime}
              name={`interviewRounds.${index}.timeRangeEndTime`}
              showTimeSelect
              showTimeSelectOnly
              isRequired
              minTime={minTimeRangeEnd}
              maxTime={maxTimeRangeEnd}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1">
            <Label id={`interviewRounds.${index}.interviewDuration`} isRequired>
              Interview Duration
            </Label>
            <SelectField
              control={control as unknown as Control}
              hasError={interviewRoundErrorObj?.interviewDuration}
              arr={INTERVIEW_DURATION_OPTIONS}
              name={`interviewRounds.${index}.interviewDuration`}
            />
          </div>
          <div className="flex-1">
            <Label id={`interviewRounds.${index}.timeInterval`} isRequired>
              Time Interval
            </Label>
            <SelectField
              control={control as unknown as Control}
              hasError={interviewRoundErrorObj?.timeInterval}
              arr={INTERVIEW_DURATION_OPTIONS}
              name={`interviewRounds.${index}.timeInterval`}
            />
          </div>
        </div>
        <div>
          <Label className="invisible">Empty</Label>
          <p className="flex h-12 items-center justify-center rounded-md bg-[#2F80ED] font-semibold text-white">
            {slots.length} slot{slots.length > 1 && 's'} available
          </p>
        </div>
      </div>

      {slots.length > 0 && (
        <>
          <hr className="my-7 border-[#E5E5E5]" />
          <h3 className="text-sm font-semibold">Available Slots Preview</h3>
          <div className="relative mt-2 h-[264px] overflow-y-auto rounded-[10px] border border-[#E5E5E5]">
            <div
              className="sticky top-0 left-0 right-4 h-[110px] w-full rounded-t-[10px]"
              style={{
                background:
                  'linear-gradient(180deg, #FAFAFA 9.52%, rgba(250, 250, 250, 0) 100%)',
              }}
            ></div>
            <div className="space-y-[22px] px-10">
              {slots.map((slot, index) => {
                const slotNumber = index + 1;
                const handleRemoveSlot = (id: string) => {
                  const newSlots = slots.filter(
                    (item) => item.id !== id
                  )
                  setValue(`interviewRounds.${index}.slots`, newSlots)
                }
                return (
                  <div
                    key={slot.id}
                    className={clsx(
                      'flex justify-between ',
                      false
                        ? 'text-xl text-[#333333] sm:text-3xl'
                        : ' text-[20px] text-[#333333]'
                    )}
                  >
                    <p>{slotNumber}</p>
                    <p>{slot.time}</p>
                    <p>{slot.month}</p>
                    <button type='button' onClick={() => handleRemoveSlot(slot.id)} className='relative z-30'>
                      <Trash className='text-red-500' size={14} />
                    </button>
                  </div>
                )
              })}
            </div>
            <div
              className="sticky left-0 bottom-0 right-4 h-[110px] w-full rounded-b-[10px]"
              style={{
                background:
                  'linear-gradient(180deg, #FAFAFA 9.52%, rgba(250, 250, 250, 0) 100%)',
                transform: 'matrix(1, 0, 0, -1, 0, 0)',
              }}
            ></div>
          </div>
        </>
      )}

      <div className="mt-7">
        {slots && slots.length > 0 && (
          <>
            <h3 className="text-sm font-semibold">Interview Slots</h3>
            <div className="mt-3 grid gap-4 text-center sm:grid-cols-3 md:grid-cols-4">
              {slots.map((slot) => {
                const formattedSlot: InterviewSlot = {
                  id: slot.id,
                  interviewDate: new Date(slot.interviewDate),
                  interviewStartTime: new Date(
                    slot.interviewDate + 'T' + slot.interviewStartTime
                  ),
                  interviewDuration: timeInterval,
                }
                return <Slot key={slot.id} slot={formattedSlot} />
              })}
            </div>
          </>
        )}
      </div>
    </>
  )
}
