import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
// import { companyIDStorage } from '@/features/auth'
import { companyKeys, fetchData, transformError, userKeys } from '@/utils'
import { CompanyEditPayload, useCompanyId } from '@/features/recruiter'
import { AxiosError } from 'axios'


export const useEditCompanyProfile = () => {
  const queryClient = useQueryClient()
  const companyID =  useCompanyId()
  return useMutation(
    (payload: CompanyEditPayload) =>
      fetchData(
        `/company/${companyID}/edit`,
        'PUT',
        payload
      ),
    {
      onSuccess: () => {
        toast.success('Company profile edited successfully')
        queryClient.invalidateQueries({
          queryKey: companyKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: userKeys.all,
        })
      },
      onError: (error:AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
