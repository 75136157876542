import { useQuery } from '@tanstack/react-query'

import {  fetchData, interviewFeedbacksKeys} from '@/utils'
import { InterviewFeedback } from '@/features/job-seeker'

interface InterviewFeedbackResponseProp {
  status: string
  message: string
  data: InterviewFeedback[]
}

export const useGetInterviewFeedback = (interviewId:string) => {

  return useQuery<InterviewFeedbackResponseProp>({
    queryKey: interviewFeedbacksKeys.list(interviewId),
    queryFn: async () => fetchData(`/company/applicants/interview-feedbacks/${interviewId}`),
  })
}
