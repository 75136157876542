import React from 'react';

interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
}

export const RoundCheckbox: React.FC<CheckboxProps> = ({ checked, onChange }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="opacity-0 absolute h-0 w-0"
          checked={checked}
          onChange={onChange}
        />
        <div className={`bg-white border-2 overflow-hidden rounded-full w-5 h-5 ${checked ? 'border-green-500' : 'border-gray-400'}`}></div>
        {checked && (
          <div className="absolute text-white inset-0 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-white rounded-full bg-primary"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8.293 13.707l5-5a1 1 0 10-1.414-1.414L9 10.586 6.707 8.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
    </label>
  );
};
