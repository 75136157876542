import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { userIDStorage } from '@/features/auth'
import { fetchData, userKeys } from '@/utils'

interface ResumePayload {
  resumeUrl: string
}

export const useResumeUpload = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const userID = userIDStorage.getUserID()
  if (!userID) router.push('/login')
  return useMutation(
    (payload: ResumePayload) =>
      fetchData<ResumePayload>(
        `/auth/onboarding/resume/${userID}`,
        'PUT',
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['user-details'],
        })
        queryClient.invalidateQueries({
          queryKey: userKeys.all,
        })
        toast.success('Resume successfully uploaded.')
      },
      onError: () => {
        toast.error('Resume not uploaded.')
      },
    }
  )
}
