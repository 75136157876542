import { useQuery, useQueryClient } from '@tanstack/react-query'

import { User, userIDStorage } from '@/features/auth'
import { axiosInstance } from '@/lib/axios'
import { userKeys } from '@/utils'

export const getUser = async (userID: string): Promise<User> => {
  const { data } = await axiosInstance.get(`/user/${userID}`)
  return data.data
}

export const useUser = (userId = '') => {

  const queryClient = useQueryClient()

  let userID = userId
  queryClient.invalidateQueries({
    queryKey: userKeys.list(userID),
  })

  if (!userId) {
    userID = userIDStorage.getUserID()
  }

  return useQuery({
    queryKey: userKeys.list(userID),
    queryFn: async () => getUser(userID),
    // cacheTime: 1000 * 60 * 5,
    // Fetch for user only when user is authenticated and user exists
    enabled:  !!userID,
  })
}
