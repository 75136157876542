import { useMutation } from '@tanstack/react-query'

import { toast } from 'react-toastify'
import { fetchData } from '@/utils'

interface Payload {
  email: string
}

export const useResendVerificationCode = () => {
  return useMutation<Response, unknown, Payload>(
    (payload) =>
      fetchData<Payload>('/auth/resend-verification-code', 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Verification code has been sent to your email')
      },
    }
  )
}
