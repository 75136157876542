import { AnimatePresence, motion } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'
import { Control, useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import {
  Experience,
  ManageExperienceForm,
  ManageExperiencePayload,
  manageExperienceSchema,
  useAddExperience,
  useEditExperience,
} from '@/features/job-seeker'
import {
  backdropVariant,
  editProfile,
  EMPTY_OPTION,
  getFormattedDayMonthYear,
  WORKSPACE_OPTIONS,
} from '@/utils'
import {
  Button,
  Checkbox,
  InputDateField,
  InputField,
  InputJobTitlesAutoComplete,
  Label,
  SelectField,
  TextAreaField,
} from '..'
import { useJobTypeOptions } from '@/hooks'

interface ManageExperienceProps {
  isEdit?: boolean
  experience?: Experience
  OpenAddExperience: boolean
  setOpenAddExperience: (value: boolean) => void
}

export const ManageExperience: React.FC<ManageExperienceProps> = ({
  isEdit,
  experience,
  OpenAddExperience,
  setOpenAddExperience,
}) => {
  const mutationAddExperience = useAddExperience()
  const mutationEditExperience = useEditExperience(experience?.id)
  const ref = React.useRef<HTMLInputElement>(null)
  
  const {
    reset,
    watch,
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ManageExperienceForm>({
    defaultValues: {
      jobTitle: EMPTY_OPTION,
      typeOfEmployment: EMPTY_OPTION,
      workCulture: EMPTY_OPTION,
      startDate: null,
      endDate: null,
      isCurrentlyEmployed: false,
    },
    resolver: yupResolver(manageExperienceSchema),
  })
  const jobDescription = watch('jobDescription')
  const isCurrentlyEmployed = watch('isCurrentlyEmployed')

  
  const endDate = watch('endDate')
  const startDate = watch('startDate')
  const { jobTypes } = useJobTypeOptions()

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (OpenAddExperience && ref.current && !ref.current.contains(e.target)) {
        setOpenAddExperience(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [OpenAddExperience])

  React.useEffect(() => {
    if (isEdit && experience) {
      const {
        companyName,
        title,
        titleId,
        startDate,
        endDate,
        responsibilities,
        workType,
        employmentType,
      } = experience

      const employmentTypeOption =
        jobTypes.find(
          (jobType) =>
            jobType.name.toLowerCase() ===
            employmentType.toString().toLowerCase()
        ) || EMPTY_OPTION

      const workCultureOption =
        WORKSPACE_OPTIONS.find(
          (workspace) =>
            workspace.id.toLowerCase() === workType.toString().toLowerCase()
        ) || EMPTY_OPTION

      const jobTitleOption = {
        name: title,
        id: titleId,
      }
      reset({
        companyName,
        jobTitle: jobTitleOption,
        startDate: new Date(startDate),
        endDate: endDate ? new Date(endDate) : null,
        jobDescription: responsibilities,
        typeOfEmployment: employmentTypeOption,
        workCulture: workCultureOption,
        isCurrentlyEmployed: !!!endDate,
      })
    }
  }, [experience])

  const onSubmit = (values: ManageExperienceForm) => {
    const {
      companyName,
      jobTitle,
      startDate,
      endDate,
      isCurrentlyEmployed,
      jobDescription,
      typeOfEmployment,
      workCulture,
    } = values

    const payload: ManageExperiencePayload = {
      companyName,
      titleId: jobTitle.id,
      typeId: typeOfEmployment.id,
      workType: workCulture.id,
      startDate: getFormattedDayMonthYear(startDate),
      endDate: getFormattedDayMonthYear(endDate),
      responsibilities: jobDescription,
      isCurrentlyEmployed,
    }

    const handleSuccess = () => {
      setOpenAddExperience(false)
      reset()
    }

    if (isEdit) {
      mutationEditExperience.mutate(payload, {
        onSuccess: handleSuccess,
      })
    } else {
      mutationAddExperience.mutate(payload, {
        onSuccess: handleSuccess,
      })
    }
  }

  const isLoading =
    mutationAddExperience.isLoading || mutationEditExperience.isLoading

  return (
    <AnimatePresence>
      {OpenAddExperience && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide scrollbar-hide absolute top-[50%] left-[50%] w-[90%]  md:w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            style={{ height: 'calc(100vh - 156px)' }}
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={editProfile}
          >
            <div>
              <div>
                <p className="text-base mt-10 md:mt-0 font-[800]">Work Experience</p>
                <button
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setOpenAddExperience(false)}
                >
                  <BsXLg />
                </button>
              </div>
              <p className="mb-4 text-xs">
                Adding more work experience helps boost your profile
              </p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-6">
              <div>
                <div>
                  <Label htmlFor="companyName" isRequired>
                    Company name
                  </Label>
                  <InputField
                    className="mt-2"
                    registration={{ ...register('companyName') }}
                    hasError={errors.companyName}
                  />
                </div>
              </div>
              <div>
                <Label isRequired>Designation</Label>
                <InputJobTitlesAutoComplete
                  className="mt-2"
                  control={control as unknown as Control}
                  hasError={errors.jobTitle}
                  name="jobTitle"
                />
              </div>
              <div className="flex  flex-col md:flex-row md:space-x-5">
                <div className="flex-1">
                  <Label isRequired>Employment Type</Label>
                  <SelectField
                    className="mt-2"
                    control={control as unknown as Control}
                    hasError={errors.typeOfEmployment}
                    arr={jobTypes}
                    name="typeOfEmployment"
                  />
                </div>

                <div className="flex-1 mt-6 md:mt-0">
                  <Label isRequired>Work Culture</Label>
                  <SelectField
                    className="mt-2"
                    control={control as unknown as Control}
                    hasError={errors.typeOfEmployment}
                    arr={WORKSPACE_OPTIONS}
                    name="workCulture"
                  />
                </div>
              </div>
              <div>
                <Label isRequired>Duration</Label>
                <div className="flex items-center space-x-3">
                  <div>
                    <InputDateField
                      name="startDate"
                      control={control as unknown as Control}
                      placeholder="Start date"
                      hasError={errors.startDate}
                      maxDate={endDate || new Date()}
                      isRequired
                    />
                  </div>
                  <p className="text-sm text-gray-150">to</p>
                  <div>
                    <InputDateField
                      name="endDate"
                      disabled= {isCurrentlyEmployed}
                      control={control as unknown as Control}
                      placeholder="End date"
                      hasError={errors.endDate}
                      maxDate={new Date()}
                      minDate={startDate}
                      isRequired
                    />
                  </div>
                </div>
              </div>

              <Checkbox
                checked={isCurrentlyEmployed}
                onChange={(e) =>
                  {
                    setValue('isCurrentlyEmployed', e.target.checked)
                    setValue('endDate', null)
                  }
                }
                id="isCurrentlyEmployed"
              >
                I currently work here
              </Checkbox>
              <div>
                <Label htmlFor="jobDescription">
                  Job Description
                </Label>
                <TextAreaField
                  className="mt-2"
                  id="jobDescription"
                  placeholder="Describe your role in the company"
                  registration={{ ...register('jobDescription') }}
                  hasError={errors.jobDescription}
                  rows={3}
                  value={jobDescription}
                />
              </div>
              <div>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                >
                  {isEdit ? (
                    <>
                      {mutationEditExperience.isLoading ? 'Saving...' : 'Save'}
                    </>
                  ) : (
                    <>{mutationAddExperience.isLoading ? 'Adding...' : 'Add'}</>
                  )}
                </Button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
