import { useQuery } from '@tanstack/react-query'

import { fetchData, interviewInfoKeys } from '@/utils'
import { InterviewRounds } from '@/features/job-seeker'

interface InterviewInfoResponse {
  status: string
  message: string
  data: InterviewRounds
}

export const useInterviewRounds = (userId:string, jobId:string | undefined | string[]) => {
  
  return useQuery<InterviewInfoResponse>({
    queryKey: interviewInfoKeys.list(`${userId} + ${jobId}`),
    queryFn: async () =>
      fetchData(`/company/applicants/interview-rounds/${userId}/${jobId}`),
      enabled:  !!userId && !!jobId,
      // staleTime: 5000
  })
  
  // Manually refetch the data when userId or jobId changes
}


