import React from 'react'
import { Button } from './Elements'

const CookiesNotification = () => {
  return (
    <div className='bg-white bottom-0 w-full sticky px-6 lg:px-16'>
      <div className="mx-auto flex max-w-[1500px] items-center justify-between py-4">
        <div className="flex items-start gap-6">
          <img src="/assets/cookies.svg" alt=""  className='lg:block hidden'/>
          <p className="text-sm xl:w-[684px]">
            We use cookies to improve usability, personalize your experience,
            and improve our marketing. You privacy is important to us, and we
            will never sell your data. By clicking “Accept All Cookies”, you
            agree to the storing of cookies on your device.{' '}
            <span className="text-[#2F80ED]">Privacy Policy.</span>
          </p>
        </div>
        <div className="flex items-center gap-6">
          <Button variant="outline" className='whitespace-nowrap'>Reject All Cookies</Button>
          <Button className='whitespace-nowrap'>Accept All Cookies</Button>
        </div>
      </div>
    </div>
  )
}

export default CookiesNotification
