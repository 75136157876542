import { useState, useEffect } from 'react'

export interface ArticleDemoImagesType {
  articleDemoImages: {
    id: number
    url: string
    description: string
    headingTitle: string
    heading: string
  }[]
}

export const Carousel = ({ articleDemoImages }: ArticleDemoImagesType) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % articleDemoImages.length)
    }, 8000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="relative mx-auto max-w-[1500px] h-[526px] overflow-hidden rounded-lg">
      {articleDemoImages.map((image, index) => (
        <div
          key={image.id}
          className={`absolute h-full w-full transition-transform ${
            index === currentIndex ? '-translate-x-0' : '-translate-x-full'
          }`}
          style={{ transitionDuration: '0.8s' }}
        >
          <img src={image.url} alt={`Image ${image.id}`} className="w-full" />
          <div className="absolute z-[50] mt-[-270px] w-full  px-10 text-white">
            <h3 className="text-[40px]">{image.headingTitle}</h3>
            <h3 className="text-[40px]">{image.heading}</h3>
            <div className='flex items-center justify-between w-full'>
              <p className="w-[767px] text-base opacity-50">
                {image.description}
              </p>
              <img
                src="/assets/Union.png"
                className="h-[44.32px] w-[60.89px] min-w-[60.89px]"
                alt=""
              />
            </div>
          </div>
        </div>
      ))}
      <style jsx>
        {`
          @keyframes fade-in-up {
            from {
              opacity: 0;
              transform: translateY(10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .animate-fade-in-up {
            animation-name: fade-in-up;
            animation-duration: 1s;
          }

          .animation-delay-1s {
            animation-delay: 1s;
          }

          .animation-delay-2s {
            animation-delay: 2s;
          }
        `}
      </style>
    </div>
  )
}

export default Carousel
