import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { fetchData, interviewJobKeys, interviewKeys } from '@/utils'
import { AcceptInterviewPayload } from '../types'

export const useAcceptInterview = (interviewId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: AcceptInterviewPayload) => fetchData(`/user/interview/accept/${interviewId}`, 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Interview successfully accepted')
        queryClient.invalidateQueries({
          queryKey: interviewJobKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: interviewKeys.all,
        })
      },
      onError: () => {
        toast.error('Interview not accepted')
      },
    }
  )
}
