import { AnimatePresence, motion } from 'framer-motion'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { EmptyWallet } from 'iconsax-react'
import { useEffect, useRef } from 'react'
import { BsXLg } from 'react-icons/bs'
import { Avatar } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import dayjs from 'dayjs'


import { backdropVariant, publicProfile } from '../../utils/animation'
import { useEducation, useExperiences, useSkills } from '@/features/job-seeker'
import { COLORS } from '@/data'
import { PageSpinner, EmptyState } from '..'
import { User } from '@/features/auth'
import { useUserInfo } from '@/hooks'
import { FaLinkedinIn } from 'react-icons/fa'
import { useClipboard } from '../Elements/useClipboard'
import { userIDStorage } from '@/features/auth'
import { Tooltip } from '@mui/material'
// import { ExternalPublicProfile } from '../PublicProfileComponent'

interface PublicProfileProps {
  user: User | null | undefined
  // applicant: JobApplicant | undefined
  openPublicProfile: boolean
  setOpenPublicProfile: (value: boolean) => void
}

export const PublicProfile: React.FC<PublicProfileProps> = ({
  user,
  openPublicProfile,
  setOpenPublicProfile,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const { firstName, lastName, photoUrl, description } = user || {}

  const { data: educationData, isLoading: isLoadingEducation } = useEducation()
  const educations = educationData?.data || []

  const { data: skillsData, isLoading: isLoadingSkills } = useSkills()
  const skills = skillsData?.data || []
  const { user: userInfo } = useUserInfo()
  const {
    // currentJobType,
    currentJob,
    socials,
    yearsOfExperience,
    website,
    // currentCompany,
    jobSearchStatus,
    resumeUrl,
  } = userInfo || {}

  const { data: experienceData, isLoading: isLoadingExperience } =
    useExperiences()
  const experiences = experienceData?.data || []

  const sortedExperience = experiences.sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
  )

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (openPublicProfile && ref.current && !ref.current.contains(e.target)) {
        setOpenPublicProfile(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openPublicProfile])

  const checkCompleteProfile =
    resumeUrl === '' || educations.length <= 0 || skills.length <= 0
  const userID = userIDStorage.getUserID()
  const { copy } = useClipboard()
  const BASE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL as string
  const profileLink = `${BASE_URL}/profile/${userID}`
  return (
    <AnimatePresence>
      {openPublicProfile && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            className=" absolute bottom-0 left-0 z-50 h-full w-full overflow-scroll bg-[#EFEFEF] px-5 pt-8  pb-16 text-black md:px-16"
            variants={publicProfile}
            ref={ref}
          >
            <div className="top-0  left-0 mx-auto max-w-[1500px] ">
              <div className="flex items-center justify-between">
                <div className="flex flex-wrap gap-8">
                  <p className="whitespace-nowrap text-left text-2xl font-bold">
                    Public Profile
                  </p>
                  <div className=" flex w-fit  items-center gap-3 rounded-md border-2 border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 px-3 py-2 text-black ">
                    <AiOutlineInfoCircle className="text-[#E2B93B] " />
                    <p className="text-xs ">
                      Your public profile is a preview of what recruiters see
                      when they view your profile
                    </p>
                  </div>
                  <div>
                    {checkCompleteProfile ? (
                      <Tooltip title="Complete your Profile to copy link">
                        <button
                          type="button"
                          className="cursor-not-allowed whitespace-nowrap rounded-md border border-gray-250 p-2 text-sm text-gray-250"
                        >
                          Copy profile link
                        </button>
                      </Tooltip>
                    ) : (
                      <button
                        onClick={() => copy(profileLink)}
                        className="whitespace-nowrap rounded-md border border-gray-50 p-1 text-sm lg:p-2"
                      >
                        Copy profile link
                      </button>
                    )}
                  </div>
                </div>

                <button
                  className="absolute top-8 right-12 flex min-h-[32px] min-w-[32px] items-center justify-center rounded-full bg-gray-200 text-[9px]"
                  onClick={() => setOpenPublicProfile(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>

            <div className="relative mt-10 flex flex-col items-start justify-between gap-8 rounded-lg lg:flex-row">
              <div
                className="  scrollbar-hide relative flex-1 overflow-y-scroll   rounded-lg"
                style={{ height: 'calc(100vh - 60px)' }}
              >
                <Image
                  src="/assets/backdropProfile.png"
                  alt="backdrop picture"
                  height={100}
                  width={1160}
                />
                <div className="flex flex-col flex-wrap gap-2 rounded-b-lg bg-white pb-6 text-black md:flex-row">
                  <div className="h-fit w-fit rounded-full pl-[30px]">
                    <img
                      className="profile-picture mt-[-30px] h-[100px] w-[100px] flex-1"
                      src={photoUrl || ''}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 pl-6 pr-6 text-left md:pl-0">
                    <div className="flex flex-col items-start justify-between md:flex-row">
                      <div>
                        <p className="mt-3 text-xl font-bold">
                          {firstName} {lastName}
                        </p>
                        <p className="mt-1  text-xs">
                          {currentJob || ''} • {yearsOfExperience}{' '}
                          {yearsOfExperience && Number(yearsOfExperience) > 1
                            ? 'years of experience'
                            : 'year of experience'}
                        </p>
                        {/* <p className="mt-1  text-xs">{currentCompany}</p> */}
                      </div>
                      <div className="mt-5 flex items-center  justify-end gap-2">
                        <div className=" flex items-center  gap-1  rounded-[4px] bg-primary bg-opacity-20 px-3">
                          <Image
                            src="/assets/briefcase.png"
                            alt="briefcase"
                            className="cursor-pointer"
                            height={16}
                            width={16}
                          />
                          <p className="cursor-pointer py-2 text-xs  font-bold text-primary">
                            {jobSearchStatus || '-'}
                          </p>
                        </div>
                        <div className="flex gap-2">

                        <div>
                          {website != '' && (
                            <Link href={`${website}`} target="_blank">
                              <Tooltip title="Website">
                                <Image
                                  src="/assets/website-icon.svg"
                                  alt="website icon"
                                  className="cursor-pointer"
                                  height={26}
                                  width={26}
                                />
                              </Tooltip>
                            </Link>
                          )}
                        </div>

                        <div>
                          {socials?.url != null && (
                            <Link
                              href={socials?.url}
                              target="_blank"
                              className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-[#0A7BBA] text-sm text-white"
                            >
                              <Tooltip title="LinkedIn">
                                <FaLinkedinIn />
                              </Tooltip>
                            </Link>
                          )}
                        </div>
                        {/* {socials?.map((social) => {
                            return (
                              <>
                              {(social.url != null) && (
                                <Link
                                  key={social.name}
                                  href={social.url}
                                  className={clsx(
                                    'flex h-6 w-6 cursor-pointer items-center justify-center',
                                    social.name === 'linkedin' &&
                                      'rounded-full bg-[#0A7BBA] text-sm text-white'
                                  )}
                                >
                                  {social.name === 'linkedin' && (
                                    <Tooltip title={social.name}>
                                      <FaLinkedinIn />
                                    </Tooltip>
                                  )}
                                  {social.name === 'website' && (
                                    <Tooltip title={social.name}>
                                      <Image
                                        src="/assets/website-icon.svg"
                                        alt="website icon"
                                        className="cursor-pointer"
                                        height={26}
                                        width={26}
                                      />
                                    </Tooltip>
                                  )}
                                </Link>
                              )

                              }
                              </>
                            )
                          })} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6">
                    <p className="mt-2 w-full text-[12px] leading-6">
                      {description || '-'}
                    </p>
                  </div>
                </div>

                <div className="mt-6 rounded-lg bg-white px-6 py-8 text-black">
                  <div className="text flex justify-between">
                    <p className="text-base font-bold">Work Experience</p>
                  </div>
                  {!isLoadingExperience ? (
                    <>
                      {experiences.length > 0 ? (
                        <>
                          <div className="mt-4 grid gap-4 md:grid-cols-2">
                            {sortedExperience.map(
                              (
                                {
                                  id,
                                  companyName,
                                  title,
                                  employmentType,
                                  workType,
                                  startDate,
                                  endDate,
                                  // responsibilities,
                                },
                                index
                              ) => {
                                const formattedStartDate =
                                  dayjs(startDate).format('MMM, YYYY')
                                const formattedEndDate = endDate
                                  ? dayjs(endDate).format('MMM, YYYY')
                                  : 'Present'
                                let duration = ''
                                if (endDate) {
                                  const e = dayjs(endDate)
                                  duration = dayjs(startDate).to(e, true)
                                } else {
                                  duration = dayjs(startDate).to(dayjs(), true)
                                }
                                return (
                                  <div
                                    className="flex  gap-5 border-gray-100 py-3"
                                    key={id}
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: COLORS[index],
                                        width: 40,
                                        height: 40,
                                        borderRadius: '5px',
                                      }}
                                    >
                                      <span className="text-2xl uppercase">
                                        {companyName[0]}
                                      </span>
                                    </Avatar>
                                    <div className="group relative flex-1">
                                      <div className="flex justify-between">
                                        <p className="flex-1  text-sm font-bold line-clamp-1">
                                          {title}
                                        </p>
                                      </div>
                                      <p className="text-xs">{companyName}</p>
                                      <p className="text-xs text-gray-50">
                                        {formattedStartDate} -{' '}
                                        {formattedEndDate}
                                        {' • '}
                                        {duration}
                                      </p>
                                      <p className="mt-2 text-xs">
                                        <span className="bg-[#F2F7FF] py-1 px-2 font-medium capitalize text-[#575698]">
                                          {employmentType}
                                        </span>{' '}
                                        <span className="bg-[#FCF8F3] py-1 px-2 font-medium capitalize text-[#ED7200]">
                                          {workType}
                                        </span>
                                      </p>
                                      {/* <p className="text-xs">{responsibilities}</p> */}
                                    </div>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <EmptyState
                          className="mt-16"
                          maxWidthClass="max-w-[260px]"
                          icon={
                            <EmptyWallet
                              size="32"
                              className="mx-auto text-primary"
                              variant="Bulk"
                            />
                          }
                          description="Experience hasn't been added yet."
                        />
                      )}
                    </>
                  ) : (
                    <PageSpinner className="mt-14" />
                  )}
                </div>

                {/* education section */}
                <div className="mt-6 rounded-lg bg-white px-6 py-8 text-black">
                  <div className="text flex justify-between">
                    <p className="text-base font-bold">Education</p>
                  </div>
                  {isLoadingEducation ? (
                    <PageSpinner className="mt-14" />
                  ) : (
                    <>
                      {educations.length > 0 ? (
                        <div className="mt-4 grid grid-cols-2">
                          {educations?.map((info, Index) => (
                            <div key={Index} className="group flex gap-4">
                              <div>
                                <Image
                                  src="/assets/school.png"
                                  height={40}
                                  width={40}
                                  alt="logo"
                                />
                              </div>
                              <div>
                                <p className="text-sm font-bold">
                                  {info.institute}
                                </p>
                                <p className="mt-1 text-xs">
                                  {info.qualification}
                                </p>
                                <p className="mt-1 text-xs">
                                  {dayjs(info.startDate).format('MMMM, YYYY')} -{' '}
                                  {dayjs(info.endDate).format('MMMM, YYYY')}
                                </p>
                                <p className="mt-1 text-xs">{info.grade}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <EmptyState
                          className="mt-16"
                          maxWidthClass="max-w-[260px]"
                          icon={
                            <EmptyWallet
                              size="32"
                              className="mx-auto text-primary"
                              variant="Bulk"
                            />
                          }
                          description="Education hasn't been added yet."
                        />
                      )}
                    </>
                  )}
                </div>

                {/* skills */}
                <div className="mt-6 rounded-lg bg-white px-6 py-8 text-black">
                  <div className="text flex justify-between">
                    <p className="text-base font-bold">Top Skills</p>
                  </div>
                  {isLoadingSkills ? (
                    <PageSpinner className="mt-14" />
                  ) : (
                    <>
                      {skills?.length > 0 ? (
                        <>
                          <div className="mt-4 flex flex-wrap justify-start gap-4">
                            {skills.map((info, index) => (
                              <div
                                key={index}
                                className="flex items-center justify-between gap-2  overflow-hidden rounded-full bg-black py-2 px-4 text-sm group transition duration-100  ease-in-out"
                              >
                                <p className="w-fit whitespace-nowrap text-sm text-white ">
                                  {info.name}
                                </p>
                                {/* <span className="-mr-2  hidden rounded-full bg-gray-600 p-1 text-[7px] text-white group-hover:block"></span> */}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <EmptyState
                          className="mt-16"
                          maxWidthClass="max-w-[260px]"
                          icon={
                            <EmptyWallet
                              size="32"
                              className="mx-auto text-primary"
                              variant="Bulk"
                            />
                          }
                          description="Skills hasn't been added yet."
                        />
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="h-full w-full rounded-lg border border-green-600 bg-white lg:w-[600px]">
                {resumeUrl === null || resumeUrl === '' ? (
                  <div className="grid  place-items-center py-80">
                    <Image
                      src="/assets/upload.png"
                      height={40}
                      width={40}
                      alt="school logo"
                      className="mt-10 mb-4"
                    />
                    <p className="text-xs">No resume to show</p>
                  </div>
                ) : (
                  <div className="p-5 ">
                    <iframe
                      src={resumeUrl || '-'}
                      style={{ width: '100%', height: '725px' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
