import { useUserEducation} from '@/features/auth'

export const useUserEducationInfo = () => {
  const { data: userEducation, isLoading } = useUserEducation()
  return {
    isLoading,
    userEducation,
  }
}

