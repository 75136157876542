import { AnimatePresence, motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import { backdropVariant, lookingFor } from '@/utils'
import { InputField, TextAreaField } from '../../../../components/Form'
import { Button } from '../../../../components/Elements'
import { CompanyPerkData, CompanyPerkForm, CompanyPerksPayload } from '../../types'
import { CompanyManagePerkSchema } from '@/utils/validationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEditPerk, usePostCompanyPerks } from '../../api'

interface CompanyManagePerksProps {
  companyManagePerk: boolean
  setCompanyManagePerk: (value: boolean) => void
  clickedPerk: CompanyPerkData
  isEdit: boolean
}

export const CompanyManagePerksModal: React.FC<CompanyManagePerksProps> = ({
  companyManagePerk,
  setCompanyManagePerk,
  clickedPerk,
  isEdit,
}) => {
  const {
    reset,
    register,
    formState: { errors },

    handleSubmit,
  } = useForm<CompanyPerkForm>({
    defaultValues: {
      perkName: clickedPerk.name,
      perkDescription: clickedPerk.description,
    },
    resolver: yupResolver(CompanyManagePerkSchema),
  })

  const mutationAddPerk = usePostCompanyPerks()
  const mutationEditPerk = useEditPerk(clickedPerk.id)
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (companyManagePerk && ref.current && !ref.current.contains(e.target)) {
        reset()
        setCompanyManagePerk(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [companyManagePerk])

  React.useEffect(() => {
    if (clickedPerk) {
      reset({
        perkName: clickedPerk.name,
        perkDescription: clickedPerk.description,
      })
    }
  }, [clickedPerk])



  const onSubmit = (data: CompanyPerkForm) => {
    const { perkName, perkDescription } = data


    const payload:CompanyPerksPayload = {
      name: perkName,
      description: perkDescription
    }

    const handleSuccess = () => {
      setCompanyManagePerk(false)
      reset()
    }

    if (isEdit) {
      mutationEditPerk.mutate(payload, {
        onSuccess: handleSuccess,
      })
    } else {
      mutationAddPerk.mutate(payload, {
        onSuccess: handleSuccess,
      })
    }
  }

  return (
    <AnimatePresence>
      {companyManagePerk && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[50%] left-[50%]  w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={lookingFor}
          >
            <div>
              <div>
                <p className="text-base font-bold">Perks and benefit</p>
                <button
                  type="button"
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() =>{reset(); setCompanyManagePerk(false)}}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <p className="mt-6 text-sm font-bold">Title</p>
                <InputField
                  className="mt-2"
                  placeholder=""
                  registration={{ ...register('perkName') }}
                  hasError={errors.perkName}
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">Description</p>
                <TextAreaField
                  hasError={errors.perkDescription}
                  className="mt-4"
                  registration={{ ...register('perkDescription') }}
                  id="perkDescription"
                />
              </div>

              <div className="mt-8">
              {isEdit && <Button type="submit">{mutationEditPerk.isLoading ? 'Saving...' : 'Save Changes'}</Button>}
                {!isEdit && <Button type="submit">{mutationAddPerk.isLoading ? 'Adding...' : 'Add'}</Button>}
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
