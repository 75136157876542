import { companyJobKeys, fetchData } from "@/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"

export interface ExtendDeadlinePayload {
    validThrough: string
}

export const useExtendDeadline = (jobId: string) => {
    const queryClient = useQueryClient()

    return useMutation(
        (payload: ExtendDeadlinePayload) => fetchData(`job/extend-deadline/${jobId}`, 'PUT', payload),
        {
            onSuccess: () => {
                toast.success('Deadline extended successfully')
                queryClient.invalidateQueries({
                    queryKey: companyJobKeys.all
                })
            },
            onError: () => {
                toast.error("And error occurred, deadline could not be extended")
            }
        }
    )
}