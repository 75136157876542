import { allJobsForApprovalKeys, fetchData, jobApplicantKeys, transformError } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ApproveApplicantPayload {
    jobId: string | undefined | string[] 
    userInfo:  {userId: string, interviewId: string}[]
}
export const useApproveApplicants = () => {
    const queryClient = useQueryClient();
    
    return useMutation<Response, AxiosError, ApproveApplicantPayload>(
        (payload) => fetchData(`/company/applicants/approve-applicants`, 'POST', payload),
        {
            onSuccess: () => {
                toast.success('List Approved')
                queryClient.invalidateQueries({
                    queryKey: allJobsForApprovalKeys.all
                });
                queryClient.invalidateQueries({
                    queryKey: jobApplicantKeys.all
                });
            },
            onError: (error) => {
                toast.error(transformError(error))
            },
        }
    )
}
