import { useQuery } from '@tanstack/react-query'

import {  scorecardKeys } from '@/utils'
import { fetchData } from '@/utils'
import {  Scorecard } from '../types'

interface ScorecardResponse {
  status: string
  message: string
  data: Scorecard[]
}

export const useScorecards = () => {
  return useQuery<ScorecardResponse>({
    queryKey: scorecardKeys.lists(),
    queryFn: async () => fetchData("/common/scorecards"),
  })
}