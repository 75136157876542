import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { experienceKeys, fetchData } from '@/utils'
import { ManageExperiencePayload } from '../types'

export const useEditExperience = (experienceId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: ManageExperiencePayload) =>
      fetchData<ManageExperiencePayload>(
        `/user/edit-work-experience/${experienceId}`,
        'PUT',
        payload
      ),
    {
      onSuccess: () => {
        toast.success('Experience successfully edited.')
        queryClient.invalidateQueries({
          queryKey: experienceKeys.all,
        })
      },
      onError: () => {
        toast.error('Experience not edited.')
      },
    }
  )
}
