import { CompanyPublicProfile } from "@/features/recruiter"
import { companyKeys, fetchData } from "@/utils"
import { useQuery } from "@tanstack/react-query"

interface CompanyResponse {
    status: string
    message: string
    data: CompanyPublicProfile
  }

export const useViewCompanyProfile = (companyID: string) => {
    
     return useQuery<CompanyResponse>({
       queryKey: companyKeys.list(companyID),
       queryFn: async () => fetchData(`/company/public/profile/${companyID}`),
     })
   }