import { Accept, useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import Image from 'next/image'
import React from 'react'
import clsx from 'clsx'

import { OnboardingResumeUploadForm } from '../../types'
import { useFileUpload, useResumeUpload } from '@/api'
import { Button } from '@/components'

export const DOCUMENT_ACCEPT: Accept = {
  'application/pdf': ['.pdf'],
}

export const OnboardingResumeUpload = () => {
  const router = useRouter()
  const [resumeUrl, setResumeUrl] = React.useState<string | null>(null)
  const { watch, setValue } = useForm<OnboardingResumeUploadForm>({
    defaultValues: {
      resume: null,
    },
  })
  const mutationFileUpload = useFileUpload()
  const mutationResumeUpload = useResumeUpload()

  const { acceptedFiles, isDragActive, getRootProps, getInputProps, open } =
    useDropzone({
      accept: DOCUMENT_ACCEPT,
      maxFiles: 1,
      onDropAccepted: (acceptedFiles: File[]) => {
        setValue('resume', acceptedFiles)
      },

      onDropRejected: (error) => {
        if (error.length > 1) {
          toast.error('Multiple files not allowed!')
          return
        }

        // Just one file
        const { code, message } = error[0].errors[0]

        if (code === 'file-invalid-type') {
          toast.error(message)
          return
        }

        if (code === 'file-too-large') {
          toast.error('File must be 100kb or less')
        }
      },
    })

  const resumes = watch('resume')

  React.useEffect(() => {
    if (resumes && resumes.length > 0) {
      const formData = new FormData()
      formData.append('file', resumes[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setResumeUrl(data.fileUrl)
        },
        onError: () => {
          setValue('resume', null)
        },
      })
    }
  }, [resumes])

  React.useEffect(() => {
    if (resumeUrl) {
      mutationResumeUpload.mutate(
        { resumeUrl },
        {
          onSuccess: () => {
            router.push('/job-seeker/onboarding/4')
          },
          onError: () => {
            setValue('resume', null)
          },
        }
      )
    }
  }, [resumeUrl])

  const handleSkip = () => {
    router.push('/job-seeker/onboarding/4')
  }

  const isLoading = mutationResumeUpload.isLoading || mutationFileUpload.isLoading

  return (
    <div className="pb-10">
      <div className="m-auto max-w-[652px] text-center">
        <p>Step 3/4</p>
        <h2 className="text-2xl md:text-[40px]">Upload your resumé / CV </h2>
        <p className="">
          Autocomplete your profile in just a few seconds by uploading a
          resume.
        </p>
        <button
          {...getRootProps({ className: 'dropzone' })}
          className={clsx(
            'padding mt-4 flex w-full flex-col items-center justify-center rounded-xl  border-2 border-dashed border-[#E6E5E5] py-20',
            isDragActive && 'border-[#2F80ED] bg-[#F2F8FF]'
          )}
          onClick={open}
        >
          <Image
            src="/assets/uploadBig.png"
            alt="jobLogo"
            height={70}
            width={70}
          />
          <div className="mt-6">
            {acceptedFiles[0] ? (
              <div>
                <p className="text-[#2F80ED]">{acceptedFiles[0].name}</p>
              </div>
            ) : (
              <p className="text-center text-base leading-7 px-1">
                <span className="text-[#2F80ED]">Browse</span> or drag & drop to
                upload your resume as a{' '}
                <span className="font-[700]">.doc, .docx</span> or{' '}
                <span className="font-[700]">.pdf file</span>
              </p>
            )}
          </div>
        </button>
        <input {...getInputProps()} />
        <div className="flex justify-center">
          <div>
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              className="mt-10 w-72"
              onClick={open}
            >
              {isLoading
                ? 'Uploading...'
                : 'Upload Resume'}
            </Button>
            <div className="mx-auto mt-10 flex max-w-sm justify-center">
              <Button
                disabled={isLoading}
                variant="outline"
                type="submit"
                onClick={handleSkip}
              >
                Skip for now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
