import { userIDStorage } from '@/features/auth'
import { useCompanyId } from '@/features/recruiter'
import { Notification } from '@/types'
import { fetchData, notificationKeys } from '@/utils'
import { useQuery } from '@tanstack/react-query'

interface NotificationResponse {
  status: string
  message: string
  data: Notification[]
}

export const useNotifications = (isRecruiter = false) => {
  const userId = userIDStorage.getUserID()
 const newCompanyID =  useCompanyId()

  let companyId = ''
  let url = `/user/notifications/${userId}`
  if (isRecruiter) {
    companyId = newCompanyID
    url = `/company/notifications/${companyId}`
  }
  const isEnabled = isRecruiter ? !!newCompanyID : true

  return useQuery<NotificationResponse>({
    queryKey: notificationKeys.list(isRecruiter ? companyId : userId),
    queryFn: async () => fetchData(url),
    enabled: isEnabled,
  })
}
