import { useIndustries } from '@/api'

export const useIndustryOptions = () => {
  const { data, isLoading:industryTypeLoading } = useIndustries()
  const industryOptions = data?.data || []
  return {
    industryOptions,
    industryTypeLoading
  }
}
