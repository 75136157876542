import { Button } from '@/components'
import Link from 'next/link'

export const RecruiterOnboardingAccess = () => {
  return (
    <div className="pb-10 ">
      <div className="m-auto max-w-[652px] text-center">
        <p>Step 2/4</p>
        <h2 className="text-[40px]">Get recruiter access</h2>

        <p className="">
          Verify your company email. We’ll use your work email to <br /> confirm
          you’re an employee
        </p>
      </div>
      <div className='max-w-4xl mx-auto text-center'>
        <div>
          <p className="mt-10 text-base font-bold">
            <span className="text-xl text-primary">*</span> Enter your work
            email
          </p>
          <div className="mt-4 flex mx-auto h-[45px] w-full items-center gap-2 rounded-md border border-[#A6A6A6] bg-transparent px-4 lg:w-1/2">
            <input
              type="text"
              className="w-full bg-transparent text-sm outline-none"
              placeholder="e.g, workemail@company.com"
            />
          </div>
        </div>
        <div>
          <p className="mt-10 text-base font-bold">Add team members</p>
          <p className="mt-4 text-sm text-[#A6A6A6]">
            Enter your team member&lsquo;s email, we will send them an invite to
            join <br />
            Scout4Job. Inviting team members speeds up recruiting.
          </p>
          <div className="mt-4 flex mx-auto h-[45px] w-full items-center gap-2 rounded-md border border-[#A6A6A6] bg-transparent px-4 lg:w-1/2">
            <input
              type="text"
              className="w-full bg-transparent text-sm outline-none"
              placeholder="e.g, workemail@company.com"
            />
          </div>
          <div className="mt-4 flex mx-auto h-[45px] w-full items-center gap-2 rounded-md border border-[#A6A6A6] bg-transparent px-4 lg:w-1/2">
            <input
              type="text"
              className="w-full bg-transparent text-sm outline-none"
              placeholder="e.g, workemail@company.com"
            />
          </div>
          <div className="mt-4 flex mx-auto h-[45px] w-full items-center gap-2 rounded-md border border-[#A6A6A6] bg-transparent px-4 lg:w-1/2">
            <input
              type="text"
              className="w-full bg-transparent text-sm outline-none"
              placeholder="e.g, workemail@company.com"
            />
          </div>
          <p className="text-sm text-primary">+ add more team members</p>
        </div>
        <div>
          <p className="mt-10 text-base font-bold">
            <span className="text-xl text-primary">*</span> What kind of role(s)
            are you looking to hire?
          </p>
          <div className="mt-4 mx-auto flex h-[45px] w-full items-center gap-2 rounded-md border border-[#A6A6A6] bg-transparent px-4 lg:w-1/2">
            <input
              type="text"
              className="w-full bg-transparent text-sm outline-none"
              placeholder="Select roles"
            />
          </div>
          <p className="mt-4 text-sm text-[#A6A6A6]">
            Tip: You can select more than one
          </p>
        </div>
        <div className="mx-auto mt-10 flex max-w-sm justify-center">
          <Link href="/recruiter/onboarding/3">
            <Button className="w-full" type="submit">
              Done
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
