import { RiInstagramFill, RiTwitterFill } from 'react-icons/ri'
import Image from 'next/image'
import Link from 'next/link'

export default function Footer() {
  return (
    <footer className=" bg-secondary px-5 pt-10 text-white sm:px-28">
      <div className="grid place-items-center text-center">
        <div className="mb-4 flex flex-col items-center gap-4 md:flex-row md:gap-0">
          <Link href="/">
            <Image
              src="/assets/logo.png"
              alt="logo"
              className="mx-auto md:cursor-pointer"
              height={21.65}
              width={188.53}
            />
          </Link>
        </div>
        <p className="mt-4 mb-8 text-3xl font-bold md:mb-0 md:text-4xl">
          Don&#39;t miss any job interview invite
        </p>
        <span className=" mt-4 text-gray-400">
          Get job notifications anytime, anywhere. Download the scout4job app
          now.
        </span>
        <nav className="my-8">
          <ul className="flex">
            <li className="px-1.5">
              <Link
                className="decoration-barbie underline-offset-2 hover:underline"
                href="https://apps.apple.com/app/scout4job/id6449973743"
                target="_blank"
                rel="noopener nofollow"
              >
                <Image
                  src="https://res.cloudinary.com/scout4job/image/upload/v1692276489/app-store_ds5b9e_vuizdl.png"
                  alt="app"
                  width={140}
                  height={140}
                />
              </Link>
            </li>
            <li className="px-1.5">
              <Link
                className="decoration-barbie underline-offset-2 hover:underline"
                href="https://play.google.com/store/apps/details?id=com.scout4job&hl=en&gl=US"
                target="_blank"
                rel="noopener nofollow"
              >
                <Image
                  src="https://res.cloudinary.com/scout4job/image/upload/v1692276490/play-store_fcph2r_rrcqdm.png"
                  alt="app"
                  width={140}
                  height={140}
                />
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="space-y-3 border-t border-slate-200 py-5 text-center text-gray-400 dark:border-slate-200/5 md:flex md:space-y-0">
        <div className="flex-1">
          <div className="mx-2 text-sm">
            <ul className="flex">
              {/* <li className="px-1.5">
                <Link
                  className="decoration-barbie underline-offset-2 hover:underline"
                  href=""
                >
                  Terms of Service
                </Link>
              </li> */}
              <li className="px-1.5">
                <Link
                  className="decoration-barbie underline-offset-2 hover:underline"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-1">
          <div className="mx-2 text-sm">
            <p className="text-center">
              © Scout4Job {new Date().getFullYear()} • All Rights Reserved
            </p>
          </div>
        </div>
        <div className="flex-1">
          <div className="mx-2 pt-1 text-sm">
            <ul className="flex justify-center">
              <li className="px-1.5">
                <a
                  className="transition duration-200 hover:text-primary"
                  href="https://www.instagram.com/scout4job_/?igshid=MzRlODBiNWFlZA%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiInstagramFill />
                </a>
              </li>
              {/* <li className="px-1.5">
                <Link
                  href=""
                  className="transition duration-200 hover:text-primary"
                  >
                  <RiFacebookFill />
                </Link>
              </li> */}
              <li className="px-1.5">
                <a
                  href="https://twitter.com/scout4job?s=21&t=Xkrhvbyx-GilYxjQUCYMjQ"
                  className="transition duration-200 hover:text-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiTwitterFill />
                </a>
              </li>
              <li className="mt-[-4px] px-1.5">hello@scout4job.com</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
