import { BsThreeDotsVertical } from 'react-icons/bs'
import { useComponentVisible } from '@/hooks'
import React from 'react'
import clsx from 'clsx'

import {
  ApplicantOptionGeneral,
  ClosedToOffers,
  OpenToOffers,
  ReadyToInterview,
} from '@/components'
import { JobApplicant } from '@/features/job-seeker'

interface HiredProps {
  applicantInfo: JobApplicant
  formattedDateApplied: string
  isReadyForInterview: boolean
  isClosedToOffers: boolean
  isOpenToOffers: boolean
  activeFilter: number
  handleQualifyModal: (value: boolean) => void
  handleViewApplicant: (applicant: JobApplicant) => void
  openConfirmDisqualifyModal: boolean
  openOfferLetterModal: boolean
  setOpenOfferLetterModal: (value: boolean) => void
  openDiscardEmployee: boolean
  setOpenDiscardEmployee: (value: boolean) => void
  index: number
  handleGetApplicantId: (value: string) => void
  handleTalentPoolModal: () => void
  handleViewPicture: (photoUrl: string) => void
}

export const HiredCard = ({
  applicantInfo,
  activeFilter,
  formattedDateApplied,
  isReadyForInterview,
  isClosedToOffers,
  isOpenToOffers,
  handleQualifyModal,
  handleViewApplicant,
  openConfirmDisqualifyModal,
  openOfferLetterModal,
  setOpenOfferLetterModal,
  openDiscardEmployee,
  handleTalentPoolModal,
  setOpenDiscardEmployee,
  handleGetApplicantId,
  handleViewPicture,
  index,
}: HiredProps) => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible()

  return (
    <div className=" flex flex-col items-center" key={index}>
      <div className="absolute top-2 left-4 h-5 w-5 rounded-full bg-[#27AE60]"></div>
      <button
        onClick={() => {
          setIsComponentVisible(!isComponentVisible)
          handleGetApplicantId(applicantInfo?.userId)
        }}
      >
        <BsThreeDotsVertical className="absolute top-2 right-4 text-gray-250 " />
      </button>
      {applicantInfo.photoUrl ? (
        <button onClick={() => handleViewPicture(applicantInfo.photoUrl || '')}>
          <img
            src={applicantInfo.photoUrl}
            alt="setUpProfile"
            className="profile-picture mb-2 h-[60px] w-[60px]"
          />
        </button>
      ) : (
        <div>
          <p className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-black text-2xl font-[600] text-white">
            {applicantInfo.firstName[0]}
          </p>
        </div>
      )}

      <p className="mt-3 text-center text-lg font-bold">
        {applicantInfo.firstName} {applicantInfo.lastName}
      </p>
      <p className="mt-1 text-center text-xs">{applicantInfo.email}</p>
      <p className="mt-1 text-center text-xs">{applicantInfo.phone}</p>
      <p className="mt-1 text-center text-xs">{applicantInfo.company}</p>
      <div className="mt-4">
        {isReadyForInterview && <ReadyToInterview />}
        {isOpenToOffers && <OpenToOffers />}
        {isClosedToOffers && <ClosedToOffers />}
      </div>

      <p className="mt-3 text-xs text-[#A6A6A6]">
        Applied: {formattedDateApplied}
      </p>
      <div
        ref={ref}
        className={clsx('', isComponentVisible ? 'block' : 'hidden')}
      >
        <ApplicantOptionGeneral
          activeFilter={activeFilter}
          handleViewApplicant={() => handleViewApplicant(applicantInfo)}
          applicantId={applicantInfo.userId}
          openConfirmDisqualifyModal={openConfirmDisqualifyModal}
          setOpenConfirmDisqualifyModal={handleQualifyModal}
          openOfferLetterModal={openOfferLetterModal}
          setOpenOfferLetterModal={setOpenOfferLetterModal}
          openDiscardEmployee={openDiscardEmployee}
          setOpenDiscardEmployee={setOpenDiscardEmployee}
          handleTalentPoolModal={handleTalentPoolModal}
        />
      </div>
    </div>
  )
}
