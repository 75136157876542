import { useQuery, useQueryClient } from '@tanstack/react-query';

import { userIDStorage, UserProfile } from '@/features/auth';
import { axiosInstance } from '@/lib/axios';
import { useAuth } from '@/contexts/Authentication';
import { profileKeys, } from '@/utils';

export const getUserProfile = async (userID: string): Promise<UserProfile> => {
  const { data } = await axiosInstance.get(`/user/profile/${userID}`); 
    return data.data;
  };

  export const useUserProfile = () => {
    const queryClient = useQueryClient()

    const { authState } = useAuth();
    const userID = userIDStorage.getUserID();
    queryClient.invalidateQueries({ queryKey: profileKeys.list(userID)})
  
    return useQuery({
      queryKey: profileKeys.list(userID),
      queryFn: async () => getUserProfile(userID),
      

      // cacheTime: 1000 * 60 * 5,
      // Fetch for user only when user is authenticated and user exists
      enabled: authState.isAuthenticated && !!userID,
    });
  }