import { Profile2User } from 'iconsax-react'
import Image from 'next/image'
import React from 'react'
import { BsDot } from 'react-icons/bs'
import { FullPageLoader } from '../auth'
import { AllCompanyList } from '@/types'


interface Discovercompaniesprops {
  allCompanyList: AllCompanyList[]
  isLoading: boolean
} 

export const DiscoverCompanies = ({allCompanyList, isLoading}:Discovercompaniesprops) => {
  
  
  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <div className="mb-10 mx-auto grid max-w-[1500px] grid-cols-3 items-stretch justify-between gap-6">
          {allCompanyList?.map(
            (
              {
                companyLogo,
                companyName,
                employeeCount,
                industry,
                companyLocation,
              },
              index
            ) => {
            
              return (
                <div
                  key={index}
                  className=" rounded-lg bg-[#FFFFF] px-3 py-6 text-center shadow-md"
                >
                  {companyLogo ? (<div className='min-w-[70px] min-h-[70px] h-[70px] w-[70px] mx-auto'>
                    <Image
                      src={companyLogo || ''}
                      alt="logo"
                      width={70}
                      height={70}
                      className="mx-auto"
                    />
                  </div>) : (
                    <p className='min-w-[70px] min-h-[70px] h-[70px] w-[70px] mx-auto text-2xl font-[600] flex items-center justify-center bg-black text-white'>{companyName[0]}</p>
                  )}
                  <p className="mt-2 text-[16px] font-semibold">
                    {companyName || ''}
                  </p>
                  <div className="mx-auto mt-1 h-[50px] mb-2 flex w-10/12 items-center justify-center text-center text-sm text-[#A6A6A6]">
                    <p>{industry || ''}</p> <BsDot />{' '}
                    <p>{companyLocation || ''}</p>
                  </div>
                  <div className="mx-auto flex w-9/12 items-center justify-center space-x-2 ">
                    {' '}
                    <Profile2User size="16" color="#27AE60" variant="Bulk" />{' '}
                    <p className="text-sm">{employeeCount || ''}</p>
                  </div>
                </div>
              )

            }
            
          )}
        </div>
      )}
    </>
  )
}
