import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {  fetchData, userKeys } from '@/utils';
import { LookingForPayload } from '../types';
import { userIDStorage } from '@/features/auth';


export const useLookingFor = () => {
  const queryClient = useQueryClient();
  const userId = userIDStorage.getUserID();

  return useMutation(
    (payload: LookingForPayload) =>
      fetchData<LookingForPayload>(`/user/bio/update/${userId}`, 'PUT', payload),
    {
      onSuccess: () => {
        toast.success('bio successfully updated.');
        queryClient.invalidateQueries({
          queryKey: userKeys.all,
        });
      },
      onError: () => {
        toast.error('bio not added.');
      },
    }
  );
};