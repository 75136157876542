import { useQuery } from '@tanstack/react-query'

import { fetchData, postKeys } from '@/utils'
import { PostDetails } from '@/types'

interface JobInterviewResponse {
  status: string
  message: string
  data: PostDetails
}

export const usePostDetails = (postSlug: string) => {
  return useQuery<JobInterviewResponse>({
    queryKey: postKeys.list(postSlug),
    queryFn: async () =>
      fetchData(`/posts/detail/${postSlug}`),
  })
}
