import { useMutation } from '@tanstack/react-query'

import { ForgotPasswordProps } from '../types'
import { fetchData } from '@/utils'
import { toast } from 'react-toastify'

export const useForgotPassword = () => {
  return useMutation<Response, unknown, ForgotPasswordProps>((payload) =>
    fetchData<ForgotPasswordProps>('/auth/forgot-password', 'POST', payload), {
      onSuccess: () => {
        toast.success('Password reset link has been sent to your email');
      },
      onError: () => {
        toast.error('Password reset link failed');
      }
    }
  )
}
