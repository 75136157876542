import { ConvertSalaryToNumber } from '@/hooks';
import { OPTION_VALIDATION } from '@/utils'
import { array, date, object, string } from 'yup'

export const postAJobValidationSchema = object({
  jobTitle: OPTION_VALIDATION,
  jobDuration: OPTION_VALIDATION,
  workExperience: OPTION_VALIDATION,
  salaryTerm: OPTION_VALIDATION,
  address: string().required('Required'),
  typeOfEmployment: OPTION_VALIDATION,
  workPlaceType: OPTION_VALIDATION,
  jobSummary: string().required('Required'),
  availableOpenings: OPTION_VALIDATION,
  minimumQualification: OPTION_VALIDATION,
  industries: OPTION_VALIDATION,
  jobLevel: OPTION_VALIDATION,
  currency: OPTION_VALIDATION,
  minSalary: string().required('Minimum salary is required'),
  maxSalary: string().required('Maximum salary is required')
  .test('maxSalary', 'Maximum salary should not be less than minimum salary', function (value) {
    const { minSalary } = this.parent;
    return ConvertSalaryToNumber(value) >= ConvertSalaryToNumber(minSalary);
  }),
})



export const reschdeduleInterviewSchema = object({
  interviewDate: string().required('Required'),
  interviewStartTime: string().required('Required'),
  interviewEndTime: string().required('Required'),
  interviewType: OPTION_VALIDATION,
  rescheduleReason: string().required('Required'),
  // users: OPTIONS_VALIDATION,
})

export const DisqualifyApplicantSchema = object({
  disQualifyReasons: string().required('Required'),
})

export const discardEmployeeSchema = object({
  discardEmployee: string().required('Required'),
})

export const openScheduleInterviewModalSchema = object({
  interviewLink: string().required('Required'),
  membersEmail: string().required('Required'),
  membersName: string().required('Required'),
  interviewMessage: string().required('Required'),
})

export const OfferLetterSchema = object({
  offerLetter: string().required('Required'),
})

export const scheduleInterviewSchema = object({
  interviewRounds: array().of(
    object().shape({
      scheduleType: string().oneOf(['option 1', 'option 2', 'option 3']),
      interviewRound: string().required('Required'),
      interviewStartTime: date().when(
        'scheduleType',
        (scheduleType, schema) => {
          if (scheduleType[0] === 'option 1') {
            return schema.required('Required')
          }
          return schema.nullable()
        }
      ),
      interviewDate: date().when('scheduleType', (scheduleType, schema) => {
        if (scheduleType[0] === 'option 1') {
          return schema.required('Required')
        }
        return schema.nullable()
      }),
      dateRangeStartDate: date().when('scheduleType', (scheduleType, schema) => {
        if (scheduleType[0] === 'option 3') {
          return schema.required('Required')
        }
        return schema.nullable()
      }),
      dateRangeEndDate: date().when('scheduleType', (scheduleType, schema) => {
        if (scheduleType[0] === 'option 3') {
          return schema.required('Required')
        }
        return schema.nullable()
      }),
      timeRangeStartTime: date().when('scheduleType', (scheduleType, schema) => {
        if (scheduleType[0] === 'option 3') {
          return schema.required('Required')
        }
        return schema.nullable()
      }),
      timeRangeEndTime: date().when('scheduleType', (scheduleType, schema) => {
        if (scheduleType[0] === 'option 3') {
          return schema.required('Required')
        }
        return schema.nullable()
      }),
      interviewDuration: object().when(
        'scheduleType',
        (scheduleType, schema) => {
          if (scheduleType[0] === 'option 1' || scheduleType[0] === 'option 3') {
            return schema
              .shape({
                name: string().required('Required'),
                id: string().required('Required'),
              })
              .required('Required')
          }
          return schema.notRequired()
        }
      ),
      timeInterval: object().when(
        'scheduleType',
        (scheduleType, schema) => {
          if (scheduleType[0] === 'option 3') {
            return schema
              .shape({
                name: string().required('Required'),
                id: string().required('Required'),
              })
              .required('Required')
          }
          return schema.notRequired()
        }
      ),
      availableSlot: object().when('scheduleType', (scheduleType, schema) => {
        if (scheduleType[0] === 'option 2') {
          return schema
            .shape({
              name: string().required('Required'),
              id: string().required('Required'),
            })
            .required('Required')
        }
        return schema.notRequired()
      }),
      interviewSlots: array().when('scheduleType', (scheduleType, schema) => {
        if (scheduleType[0] === 'option 2') {
          return schema.of(
            object()
              .shape({
                interviewDate: string().required('Required'),
                interviewStartTime: string().required('Required'),
                interviewDuration: OPTION_VALIDATION,
              })
              .required('Required')
          )
        }
        return schema.notRequired()
      }),
      interviewType: OPTION_VALIDATION,
      interviewLink: string().when('interviewType', (interviewType, schema) => {
        if (
          interviewType[0].id === 'web conference' ||
          interviewType[0].id === 'video conference'
        ) {
          return schema.required('Required')
        }
        return schema.notRequired()
      }),
    })
  ),
})



export const sendOfferLetterSchema = object({
  offerExpiryDate: string().required('Required'),
  offerExpiryTime: string().required('Required'),
})