export const ConvertSalaryToNumber = (salary: string) => {
  return parseInt(salary?.toString().replace(/,/g, ''), 10)
}

export const FormatMoney = (value:string) => {
    // Remove all non-digit characters
    const rawValue = value?.replace(/[^\d]/g, '');
    // Add commas for thousands separator
    const formattedValue = rawValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  };


  export const formatNumber = (value:number | undefined) => {
    const formattedValue = Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  export const FormatCurrency = (value:string) => {
    // Remove all non-digit characters
    const rawValue = value?.replace(/[^\d]/g, '');
    // Convert the string to a number
    const numberValue = Number(rawValue);
    // Format the number as Nigerian Naira
    const formattedValue = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN'
    }).format(numberValue);
    return formattedValue;
  };