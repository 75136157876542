import { useQuery } from '@tanstack/react-query';


import { fetchData, postKeys } from '@/utils';
import {Posts } from '@/types';

interface PostResponse {
  status: string;
  message: string;
  data: Posts[];
}



export const usePost = (limit: string) => {
  //mr moses inverted this so that's why it's this way
  const limitPost = limit?.length === 0 ? 'three-post' : 'all-posts' 
  return useQuery<PostResponse>({
    queryKey: postKeys.list(limitPost),
    queryFn: async () => fetchData(`/posts/${limitPost}`),
  });
};