import { useQuery } from '@tanstack/react-query'

import { fetchData, skillsKeys } from '@/utils'
import { Skill } from '@/features/job-seeker'

interface SkillsResponse {
  status: string
  message: string
  data: Skill[]
}

export const useGetSkills = (userId: string) => {
  return useQuery<SkillsResponse>({
    queryKey: skillsKeys.list(userId),
    queryFn: async () => fetchData(`/user/skills/${userId}`),
    enabled: !!userId,
  })
}
