import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { appliedJobKeys, fetchData, transformError } from '@/utils';
import { DeclineOfferPayload } from '../types';
import { AxiosError } from 'axios';


export const useDeclineOffer = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError, DeclineOfferPayload>(
    (payload: DeclineOfferPayload) =>
      fetchData("/user/job/offer/decline", 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Job Offer Declined');
        queryClient.invalidateQueries({
          queryKey: appliedJobKeys.all,
        });
      },
      onError: (error) => {
        toast.error(transformError(error));
      },
    }
  );
};