// import { Message2, Monitor } from 'iconsax-react'
import Image from 'next/image'
import React from 'react'
import clsx from 'clsx'

import { useNotifications } from '@/features/job-seeker'
import { getFormattedTimeAgoText } from '@/utils'
import { PageSpinner } from '@/components'

import dayjs from 'dayjs'

interface NotificationsProps {
  show: boolean
}

export const Notifications = React.forwardRef<
  HTMLDivElement,
  NotificationsProps
>(({ show }, ref) => {
  const { data: notificationsData, isLoading } = useNotifications()
  const notifications = notificationsData?.data || []
  const sortedNotifications = notifications?.sort((a, b) => {
    const item1 = dayjs(b.createdAt).unix()
    const item2 = dayjs(a.createdAt).unix()
    return item1 - item2
  })
  const isEmpty = notifications && notifications?.length === 0

  return (
    <div
      ref={ref}
      className={clsx(
        'absolute top-[80px] right-3 md:right-10 z-50 w-80 rounded-sm bg-white text-left  text-black shadow-md transition duration-200',
        show
          ? 'translate-y-0 scale-100 opacity-100'
          : '-translate-y-2 scale-0 opacity-0'
      )}
    >
      <div className="h-1 rounded-t-sm bg-gray-50"></div>
      <div className="flex justify-between space-x-4 bg-gray-350 py-3 px-6">
        <h3 className="text-[15px]">Notifications</h3>
        {/* <Monitor size="32" color="#4f4f4f" variant="TwoTone" /> */}
      </div>
      <hr />
      <div
        className={clsx('lg:overflow-y-auto', isEmpty ? 'flex h-20' : 'lg:h-[350px]')}
      >
        {isLoading ? (
          <PageSpinner />
        ) : (
          <div className="text-primary-500 space-y-3 pt-3 text-[12px]">
            {!isEmpty ? (
              <>
                {sortedNotifications.map(
                  ({
                    createdAt,
                    interviewId,
                    read,
                    message,
                    // companyLocation,
                    companyLogo,
                    // companyName,
                  }) => (
                    <div
                      className={clsx(
                        'border-b',
                        read === true ? 'bg-white' : ''
                      )}
                      key={interviewId}
                    >
                      <div className="flex items-start space-x-4 px-4 pb-3">
                        <Image
                          src={companyLogo}
                          className="rounded-md"
                          width={50}
                          height={50}
                          alt="logo"
                        />
                        <div className="">
                          <p className="">
                            {/* Congratulations!,{' '}
                            <span className="font-semibold">
                              SoftStructures
                            </span>{' '}
                            has scheduled you for an interview{' '} */}
                            {message}
                          </p>
                          <p>{getFormattedTimeAgoText(createdAt)}</p>
                          {/* <button className="mt-3 rounded-md bg-black py-1 px-2 text-xs text-white transition duration-100 ease-in-out hover:bg-primary hover:text-white">
                            More Details
                          </button> */}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <p className="-mt-3 text-center inline-block px-6 pt-6">No notifications</p>
            )}
          </div>
        )}
      </div>
      {/* <div className="bg-gray-25 rounded-b-lg p-2 text-center">
          <p className="text-sm font-light">View All</p>
        </div> */}
    </div>
  )
})

Notifications.displayName = 'Notifications'
