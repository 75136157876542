import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef } from 'react'
import { backdropVariant, reportJob } from '@/utils'
import { BsXLg } from 'react-icons/bs'
import {
  manageReportJobSchema,
  useReportJob,
  useReportJobPayload,
} from '@/features/job-seeker'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '../Elements'
import { InputField } from '../Form'

interface ReportJobProps {
  openReportJob: boolean
  setOpenReportJob: (value: boolean) => void
  jobId: string
}

interface ReportJobForm {
  reason: string
  comment: string
}

export const ReportJob: React.FC<ReportJobProps> = ({
  openReportJob,
  setOpenReportJob,
  jobId,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const mutateReportJob = useReportJob(jobId)

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReportJobForm>({
    defaultValues: {
      reason: '',
      comment: ''
    },
    resolver: yupResolver(manageReportJobSchema),
  })

  const onSubmit = (values: ReportJobForm) => {
    const { comment, reason } = values
    const payload: useReportJobPayload = {
      comment,
      reason,
    }
    const handleSuccess = () => {
      setOpenReportJob(false)
      reset()
    }

    mutateReportJob.mutate(payload, {
      onSuccess: handleSuccess,
    })
  }

  return (
    <AnimatePresence>
      {openReportJob && (
        <motion.div
          className="absolute bottom-0 left-0 z-[100] h-screen w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide scrollbar-hide absolute top-[50%] left-[50%] w-[90%] overflow-scroll rounded-lg  bg-white px-4 py-6 pt-7 md:w-[524px] md:px-8 md:pt-6"
            // style={{ height: 'calc(100vh - 156px)' }}
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={reportJob}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <p className="mt-2 mb-5 text-lg font-medium">Report Job Post</p>
                <span
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setOpenReportJob(false)}
                >
                  <BsXLg />
                </span>
              </div>

              <div>
              <p className="mt-5 text-[16px] font-semibold">
                  <span className="text-sm text-primary">*</span> Choose a reason
                </p>
                <div className="my-2 flex items-center space-x-3">
                  <input
                    type="radio"
                    value="low-quality"
                    {...register('reason')}
                  />
                  <p className="text-sm">It is offensive, discriminatory</p>
                </div>
                <div className="my-2 flex items-center space-x-3">
                  <input
                    type="radio"
                    value="spam-scam"
                    {...register('reason')}
                  />
                  <p className="text-sm">It seems like a fake or scam job</p>
                </div>
                <div className="my-2 flex items-center space-x-3">
                  <input type="radio" value="abusive" {...register('reason')} />
                  <p className="text-sm">It is inaccurate</p>
                </div>
                <div className="my-2 flex items-center space-x-3">
                  <input
                    type="radio"
                    value="duplicate-profile"
                    {...register('reason')}
                  />
                  <p className="text-sm">It is an advertisement</p>
                </div>
                <div className="my-2 flex items-center space-x-3">
                  <input type="radio" value="other" {...register('reason')} />
                  <p className="text-sm">Other</p>
                </div>
                <InputField
                  className="mt-2"
                  placeholder="Add a comment"
                  registration={{ ...register('comment') }}
                  hasError={errors.comment}
                />

                <div className="my-5 flex items-center space-x-5 ">
                  <Button
                    type="submit"
                    variant="dangerTwo"
                    disabled={mutateReportJob.isLoading}
                    isLoading={mutateReportJob.isLoading}
                  >
                    {mutateReportJob.isLoading ? 'Submitting' : 'Submit'}
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setOpenReportJob(false)}
                  >
                    Cancel
                  </Button>
                </div>
                <hr className='my-4' />
                <p className="text-xs font-light">
                  All job ads are subject to Scout4Job Terms of Service. We
                  allow users to flag posting that may be in violation of those
                  terms. Job ads may also be flagged by Scout4Job. However, no
                  moderation system is perfect, and flagging a posting does not
                  ensure that it will be removed.
                </p>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
