'use client'
import React from 'react'

interface CheckboxProps {
  id: string
  checked?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactNode
}


export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  checked = false,
  onChange,
  children,
}) => {

  
  return (
    <div className="flex flex-1 items-center space-x-2">
      <input
        className="form-check-input float-left h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top outline-none transition duration-200 checked:border-primary checked:bg-primary focus:outline-none"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        name={id}
        id={id}
      />
      <label className="cursor-pointer text-[15px]" htmlFor={id}>
        {children}
      </label>
    </div>
  )
}

