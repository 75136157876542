import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { BsXLg } from 'react-icons/bs'

import {
  backdropVariant,
  editProfile,
  EMPTY_OPTION,
  getFormattedDayMonthYear,
} from '@/utils'
import {
  InputDateField,
  InputField,
  InputUseInstitutionAutoComplete,
  SelectField,
} from '../Form'
import {
  Education,
  ManageEducationForm,
  ManageEducationPayload,
  manageEducationSchema,
  useAddEducation,
  useEditEducation,
} from '@/features/job-seeker'
import { useQualificationOptions } from '@/hooks'
import { Control, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface AddEducationProps {
  openAddEducation: boolean
  setOpenAddEducation: (value: boolean) => void
  isEdit?: boolean
  education?: Education
}

export const AddEducation: React.FC<AddEducationProps> = ({
  isEdit,
  education,
  openAddEducation,
  setOpenAddEducation,
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const { qualificationOptions } = useQualificationOptions()
  const mutateAddEducation = useAddEducation()
  const mutateEditEducation = useEditEducation(education?.id)

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (openAddEducation && ref.current && !ref.current.contains(e.target)) {
        setOpenAddEducation(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openAddEducation])

  const {
    reset,
    register,
    control,
    // setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ManageEducationForm>({
    defaultValues: {
      qualification: EMPTY_OPTION,
      institute: EMPTY_OPTION,
      course: '',
      grade: '',
      startDate: null,
      endDate: null,
    },
    resolver: yupResolver(manageEducationSchema),
  })


  useEffect(() => {
    if (isEdit && education) {
      const { course, grade,  qualificationId, qualification, startDate, endDate } =
        education

        const qualificationOption = {
          name: qualification,
          id: qualificationId
        }




      reset({
        qualification: qualificationOption,
        // schoolName: institute.id,
        course,
        grade,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
      })
    }
  }, [education])

  const onSubmit = (values: ManageEducationForm) => {
    const { grade, startDate, endDate, course, institute, qualification } =
      values
    const payload: ManageEducationPayload = {
      qualificationId: qualification.id,
      instituteId: institute?.id,
      course,
      grade,
      startDate: getFormattedDayMonthYear(startDate),
      endDate: getFormattedDayMonthYear(endDate),
      workType: '',
    }
    const handleSuccess = () => {
      setOpenAddEducation(false)
      reset()
    }

    if (isEdit) {
      mutateEditEducation.mutate(payload, {
        onSuccess: handleSuccess,
      })
    } else {
      mutateAddEducation.mutate(payload, {
        onSuccess: handleSuccess,
      })
    }
  }


  return (
    <AnimatePresence>
      {openAddEducation && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide scrollbar-hide absolute flexibleHeight top-[50%] left-[50%] w-full sm:w-[90%]  md:w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            // style={{ height: 'calc(100vh - 156px)' }}
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={editProfile}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div>
                  <p className="text-base font-[800]">Education</p>
                  <span
                    className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                    onClick={() => setOpenAddEducation(false)}
                  >
                    <BsXLg />
                  </span>
                </div>
                <p className="mb-4 text-xs">
                  Adding your education background boosts your profile strength
                </p>
              </div>

              <div>
                <p className="mt-6 text-sm font-semibold">
                  <span className="text-sm text-primary">*</span> Institute name
                </p>
                <InputUseInstitutionAutoComplete
                  className="mt-2"
                  control={control as unknown as Control}
                  hasError={errors.institute}
                  name="institute"
                />
              </div>
              <div>
                <p className="mt-5 text-sm font-semibold">
                  <span className="text-sm text-primary">*</span> Degree
                </p>
                <SelectField
                  className="mt-2"
                  control={control as unknown as Control}
                  hasError={errors.qualification}
                  arr={qualificationOptions}
                  name="qualification"
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-semibold">
                  <span className="text-sm text-primary">*</span> Course of
                  Study
                </p>
                <InputField
                  className="mt-2"
                  placeholder=""
                  registration={{ ...register('course') }}
                  hasError={errors.course}
                />
              </div>
              <div className="flex space-x-5">
                <div className="flex-1">
                  <p className="mt-5 text-sm font-semibold">
                    <span className="text-sm text-primary">*</span> Start Date
                  </p>
                  <InputDateField
                    name="startDate"
                    className="mt-2"
                    control={control as unknown as Control}
                    placeholder="Start date"
                    hasError={errors.startDate}
                    //maxDate={endDate || new Date()}
                    isRequired
                  />
                </div>
                <div className="flex-1">
                  <p className="mt-5 text-sm font-semibold">
                    <span className="text-sm text-primary">*</span> End Date
                  </p>
                  <InputDateField
                    name="endDate"
                    className="mt-2"
                    control={control as unknown as Control}
                    placeholder="End date"
                    hasError={errors.endDate}
                    // maxDate={endDate || new Date()}
                    isRequired
                  />
                </div>
              </div>

              <div>
                <p className="mt-5 text-sm font-semibold">
                  <span className="text-sm text-primary">*</span> Grade
                </p>
              </div>
              <InputField
                className="mt-2"
                placeholder=""
                registration={{ ...register('grade') }}
                hasError={errors.grade}
              />
              <div className="mt-2 mb-20 sm:mb-0">
                <button className="display mx-auto mt-[2rem] flex w-[232px] items-center justify-center gap-5 bg-secondary py-[12.5px] px-[38px] text-xs font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919] md:text-sm ">
                  Save
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
