import {
  option1,
  option2,
  option3,
  salaryRange,
  whatImportantInJob,
} from '@/data'

import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { BsXLg } from 'react-icons/bs'

import { backdropVariant, editPreference } from '@/utils'
import { MultipleInput } from '../Form'

interface EditPreferencesProps {
  openPreference: boolean
  setOpenPreference: (value: boolean) => void
}

export const EditPreferences: React.FC<EditPreferencesProps> = ({
  openPreference,
  setOpenPreference,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (openPreference && ref.current && !ref.current.contains(e.target)) {
        setOpenPreference(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openPreference])

  return (
    <AnimatePresence>
      {openPreference && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide absolute top-[50%] left-[50%] z-50 m-auto h-[666px] max-h-[666px] w-[1010px]  overflow-scroll rounded-lg bg-white  px-6 py-8"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={editPreference}
          >
            <div className="flex w-full justify-between">
              <span
                className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                onClick={() => setOpenPreference(false)}
              >
                <BsXLg />
              </span>
              <div className="text-left">
                <h3 className="text-base">Edit Preferences</h3>
                <p className="mt-2 text-xs">
                  We recommend jobs for you based on your work preferences
                </p>
              </div>
            </div>
            <div>
              <div className="">
                <div className="flex gap-5">
                  <div className="mt-4 flex-1">
                    <p className="mb-2 text-left text-sm font-[700]">
                      Location
                    </p>
                    <div>
                      <MultipleInput options={option1} />
                    </div>
                  </div>

                  <div className="mt-4 flex-1">
                    <p className="mb-2 text-left text-sm font-[700]">
                      Looking to work
                    </p>
                    <div>
                      <select
                        className="border-1 h-12 w-full rounded-md border border-[#d3d3d3] outline-none"
                        name=""
                        id=""
                      >
                        <option value="">Senior Level</option>
                        <option value="">Mid-senior Level</option>
                        <option value="">Junior Level</option>
                        <option value="">Inern Level</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="mt-4 flex-1">
                    <p className="mb-2 text-left text-sm font-[700]">Role</p>
                    <div>
                      <MultipleInput options={option2} />
                    </div>
                  </div>

                  <div className="mt-4 flex-1">
                    <p className="mb-2 text-left text-sm font-[700]">
                      Looking to work
                    </p>
                    <select
                      className="outline-nonex  border-1 h-12 w-full    rounded-md border border-[#d3d3d3]"
                      name=""
                      id=""
                    >
                      {salaryRange.map((salary, Index) => (
                        <option key={Index} value="">
                          {salary}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-4 flex-1">
                    <p className="mb-2 text-left text-sm font-[700]">
                      in (Locations)
                    </p>
                    <div>
                      <MultipleInput options={option3} />
                    </div>
                  </div>
                  <div className="flex-1"></div>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-8">
                <p className="text-sm font-bold">
                  What environment do you work better in?
                </p>
                <div className="mt-4 items-center gap-[4rem]">
                  <div className="flex items-center gap-2">
                    <input id="Intern" type="radio" name="responsibity" /> {' '}
                    <label className="text-sm" htmlFor="Intern">
                      Clear role and set of responsibilities. Consistent
                      feedback from management.
                    </label>
                  </div>
                  <div className="mt-4 flex items-center gap-2">
                    <input id="Co-founder" type="radio" name="responsibity" /> {' '}
                    <label className="text-sm" htmlFor="Co-founder">
                      Employees wear a lot of hats. Assignments often require
                      employees to figure it out on their own.
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <p className="text-sm font-bold">
                  What is most important to you in your next job? (Max 2)
                </p>
                <div className=" flex w-full flex-wrap gap-x-[10rem]">
                  {whatImportantInJob.map((stuff, Index) => (
                    <div
                      className="mt-4 flex  w-[40%] items-center gap-6 self-start"
                      key={Index}
                    >
                      <div className="round">
                        <input type="checkbox" id="jobImportant" />
                        <label htmlFor="jobImportant"></label>
                      </div>
                      <p className="text-sm">{stuff}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-8">
                <p className="text-sm font-bold">
                  How important is it that your next job has a flexible remote
                  work policy?
                </p>
                <div className="mt-4 flex items-center gap-[4rem]">
                  <div className="flex items-center gap-2">
                    <input id="Intern" type="radio" name="remote-work" /> {' '}
                    <label className="text-sm" htmlFor="Intern">
                      Very important
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input id="Co-founder" type="radio" name="remote-work" /> {' '}
                    <label className="text-sm" htmlFor="Co-founder">
                      Important
                    </label>
                  </div>
                  <div className="flex items-center  gap-2">
                    <input id="Co-founder" type="radio" name="remote-work" /> {' '}
                    <label className="text-sm" htmlFor="Co-founder">
                      Not important
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <p className="text-sm font-bold">
                  How important is it that you work in a quiet office?
                </p>
                <div className="mt-4 flex items-center gap-[4rem]">
                  <div className="flex items-center gap-2">
                    <input id="Intern" type="radio" name="work-policy" /> {' '}
                    <label className="text-sm" htmlFor="Intern">
                      Very important
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input id="Co-founder" type="radio" name="work-policy" /> {' '}
                    <label className="text-sm" htmlFor="Co-founder">
                      Important
                    </label>
                  </div>
                  <div className="flex items-center  gap-2">
                    <input id="Co-founder" type="radio" name="work-policy" /> {' '}
                    <label className="text-sm" htmlFor="Co-founder">
                      Not important
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-center">
              <button className="mx-auto  mt-8 w-[232px] whitespace-nowrap  bg-secondary py-[12.5px] px-[38px] text-xs font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44]  hover:shadow-[6px_6px_0px_0px_#191919] md:text-sm">
                Apply
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
