import { Button, Modal } from '@/components'
import { Warning2 } from 'iconsax-react'
import React from 'react'
import { useSDiscardApplicants } from '../../api'
// import { useForm } from 'react-hook-form'
// import { DiscardWithReasonForm } from '../../types'

interface ConfirmDiscardApplicantsProp {
  showDialog: boolean
  closeModal: () => void
  userInfo: { userId: string; interviewId: string }[]
  jobId: string | string[]
  setSelectedItems: React.Dispatch<React.SetStateAction<{ userId: string; interviewId: string; }[]>>
}

export const ConfirmDiscardApplicantModal: React.FC<
  ConfirmDiscardApplicantsProp
> = ({ showDialog, userInfo, jobId, closeModal, setSelectedItems }) => {
  const mutateDiscardApplicants = useSDiscardApplicants()
  const isLoading = mutateDiscardApplicants.isLoading

  const handleClose = () => {
    if (!isLoading) {
      closeModal()
    }
  }


  // const { register, watch } = useForm<DiscardWithReasonForm>()

  // const discardReasonValue = watch('discardReason')

  const handleDiscardApplicants = () => {
    const payload = {
      jobId: jobId,
      userInfo: userInfo,
      // reason: "",
    }


    mutateDiscardApplicants.mutate(payload)
    setSelectedItems([])
    closeModal()
  }

  

  return (
    <Modal
      className="mt-48"
      variant="middle"
      showDialog={showDialog}
      closeModal={handleClose}
    >
      <div className="mx-auto flex h-auto py-10 w-[560px] items-center overflow-y-auto border border-gray-100 bg-white px-8">
        <div>
          <div className="flex items-start space-x-4">
            <Warning2 size="32" color="#FF8A65" />
            <p className="text-[15px]">
              Are you sure you want to Discard the selected {userInfo.length > 1 ?
"applicants" : "applicant"}
            </p>
          </div>
          {/* <TextAreaField
            id="discardReason"
            className="mt-4"
            registration={{ ...register('discardReason') }}
            placeholder="Reason..."
            hasError={undefined}
            value={discardReasonValue}
          /> */}
          {/* <hr className="border-gray-450 my-6" /> */}

          <div className="mt-6 flex justify-end space-x-6">
            <Button
              disabled={isLoading}
              onClick={handleClose}
              variant="outline"
              size="xs"
            >
              Cancel
            </Button>
            <Button
              className="bg-error-300"
              // disabled={isLoading || discardReasonValue?.length < 6}
              isLoading={isLoading}
              type="button"
              onClick={handleDiscardApplicants}
              size="xs"
            >
              {isLoading ? 'Discarding...' : 'Discard'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
