import React from 'react';
import { BsChevronDown } from 'react-icons/bs';

export const Pagination = () => {
  return (
    <div className="flex h-[50px] absolute bottom-0 items-center justify-between rounded-b-lg border-t border-[#EFEFEF] bg-white px-6 text-xs">
          <div className="flex items-center gap-4">
            <p>View</p>
            <div className="flex gap-3 border border-[#EFEFEF]  py-1 px-2 rounded-lg items-center ">
              <p>10</p>
              <BsChevronDown />
            </div>
            <p>of 2000</p>
            <p>employees per page</p>
          </div>
          {/* pagination */}
          <div>pagination</div>
        </div>
  );
}


