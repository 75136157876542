import Link from 'next/link'
import React from 'react'
import clsx from 'clsx'

interface EmptyStateProps {
  className?: string
  maxWidthClass?: string
  description: string
  icon?: React.ReactNode
  linkText?: string
  linkUrl?: string
  children?: React.ReactNode
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  className = 'mt-16',
  maxWidthClass = 'max-w-sm',
  description,
  icon,
  linkText,
  linkUrl,
  children,
}) => {
  return (
    <div className={clsx('mx-auto text-center', maxWidthClass, className)}>
      <>
        {icon ? (
          icon
        ) : (
          <svg
            className="mx-auto"
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.8">
              <path
                d="M59.7579 19.7767C57.3495 17.1133 53.3262 15.7817 47.4895 15.7817H46.8095V15.6683C46.8095 10.9083 46.8095 5.01501 36.1562 5.01501H31.8495C21.1962 5.01501 21.1962 10.9367 21.1962 15.6683V15.81H20.5162C14.6512 15.81 10.6562 17.1417 8.24787 19.805C5.44287 22.9217 5.52787 27.115 5.81121 29.9767L5.83954 30.175L6.12287 33.15C6.15121 33.1783 6.20787 33.235 6.26454 33.2633C7.19954 33.8867 8.16287 34.51 9.18287 35.0767C9.57954 35.3317 10.0045 35.5583 10.4295 35.785C15.2745 38.4483 20.6012 40.2333 26.0129 41.1117C26.2679 43.775 27.4295 46.8917 33.6345 46.8917C39.8395 46.8917 41.0579 43.8033 41.2562 41.055C47.0362 40.12 52.6179 38.1083 57.6612 35.1617C57.8312 35.0767 57.9445 34.9917 58.0862 34.9067C59.3895 34.17 60.6079 33.3767 61.7979 32.4983C61.8583 32.4664 61.9076 32.4171 61.9395 32.3567L62.0529 31.3367L62.1945 30.005C62.2229 29.835 62.2229 29.6933 62.2512 29.495C62.4779 26.6333 62.4212 22.7233 59.7579 19.7767ZM37.0912 39.185C37.0912 42.1883 37.0912 42.6417 33.6062 42.6417C30.1212 42.6417 30.1212 42.1033 30.1212 39.2133V35.6433H37.0912V39.185ZM25.2479 15.7817V15.6683C25.2479 10.8517 25.2479 9.06668 31.8495 9.06668H36.1562C42.7579 9.06668 42.7579 10.88 42.7579 15.6683V15.81H25.2479V15.7817Z"
                fill="#191919"
              />
              <path
                opacity="0.4"
                d="M58.0825 34.8443L57.6575 35.0993C52.5921 38.043 47.0331 40.04 41.2525 40.9926C41.0258 43.7126 39.8358 46.8293 33.6308 46.8293C27.4258 46.8293 26.2358 43.741 26.0091 41.0493C20.5975 40.1993 15.2708 38.4143 10.4258 35.7226C10.0008 35.496 9.57581 35.2693 9.17914 35.0143C8.15914 34.4476 7.19581 33.8243 6.26081 33.201C6.20414 33.1726 6.14747 33.116 6.11914 33.0876L7.84747 51.5326C8.44247 57.171 10.7658 62.9793 23.2325 62.9793H44.8225C57.2891 62.9793 59.6125 57.171 60.2075 51.5043L61.9925 32.2943C61.9606 32.3547 61.9112 32.4041 61.8508 32.436C60.6325 33.3143 59.3858 34.136 58.0825 34.8443Z"
                fill="#27AE60"
              />
            </g>
          </svg>
        )}

        <p className="mt-4 mb-1 text-[15px] text-gray-50">{description}</p>
        {linkText && linkUrl && (
          <Link className="text-sm text-primary" href={linkUrl}>
            {linkText}
          </Link>
        )}

        {children && <div className="mt-4 flex justify-center">{children}</div>}
      </>
    </div>
  )
}
