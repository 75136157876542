import { useClickAdvert } from '@/api/useClickAdvert'
import { BusinessAdsData } from '@/types'
import { ArrowRight2, Gallery } from 'iconsax-react'
import React from 'react'

interface BusinessAdsCardProps {
  businessAds: BusinessAdsData
}

export const BusinessAdsCard = ({ businessAds }: BusinessAdsCardProps) => {
  const {
    adActionButton,
    adClickUrl,
    adDescription,
    adMedia,
    businessName,
    businessLogo,
    id,
  } = businessAds

  const businessDataLoading = false

  const mutateClickAds = useClickAdvert(id as string)

  const handleClickAd = () => {
    mutateClickAds.mutate()
    window.open(adClickUrl, '_blank');
  }

  return (
    <button onClick={handleClickAd} className="group">
      <div className="relative overflow-hidden rounded-lg bg-white shadow-lg transition duration-200 hover:shadow-sm">
        <div
          className="h-[260px] bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${adMedia})` }}
        >
          {adMedia ? (
            <p></p>
          ) : (
            // <img className="object-contain" src={adMedia || ''} alt="" />
            <Gallery size="32" color="#000" variant="Bulk" />
          )}
        </div>
        <div className="h-[40px] bg-blue-400"></div>
        <div className="absolute bottom-0 z-[5] max-h-[80px] w-full bg-black text-white transition-all duration-300 group-hover:max-h-[200px] group-hover:pb-10">
          <div className="flex items-center gap-2 px-4 pt-3">
            <div className="circle-perfect h-[25px] w-[25px] min-w-[25px] overflow-hidden rounded-full text-sm font-bold text-white">
              {!businessDataLoading ? (
                <img
                  className="profile-picture h-[25px] min-h-[25px] w-[25px] min-w-[25px] rounded-full"
                  src={businessLogo || ''}
                  alt=""
                />
              ) : (
                <p className="flex h-[50px] min-h-[50px] w-[50px] min-w-[50px] items-center justify-center rounded-full bg-black text-[20px] font-bold text-white">
                  {businessName[0]}
                </p>
              )}
            </div>
            {!businessDataLoading ? (
              <p className=" text-[14px] font-[600] ">
                {businessName || ''} <span className="ml-1">•</span>
                <span className="ml-[9px] text-xs font-normal [word-spacing:5px]">
                  Sponsored
                </span>
              </p>
            ) : (
              <p className="text-xs font-bold">Business Name </p>
            )}
          </div>
          <p className="mt-[11px] mb-3 px-4 text-left text-xs line-clamp-1 group-hover:line-clamp-none">
            {adDescription}
          </p>
        </div>
        <a
          href={adClickUrl}
          target="_blank"
          rel="noreferrer"
          className="absolute bottom-0 z-[7] mt-3 flex w-full
            items-center justify-between bg-[#2B2B2B] px-4 py-3 text-[11px] text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        >
          <p className="text-xs font-[600]">{adActionButton}</p>
          <ArrowRight2 size="12" color="#fff" variant="Linear" />
        </a>
      </div>
    </button>
  )
}
