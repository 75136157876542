import { useMutation } from '@tanstack/react-query';


import { fetchData } from '@/utils';

interface Institutes {
    id: number
    name: string
  }

interface InstitutionResponse {
  status: string;
  message: string;
  data: Institutes[];
}

interface InstitutionPayload {
    name: string
}

export const useInstitution = () => {
    return useMutation<InstitutionResponse, unknown, InstitutionPayload>((payload) =>
      fetchData<InstitutionPayload>('/common/institutes', 'POST', payload)
    )
};
