import { useCurrencies } from '@/api'
import { Option } from '@/types'

export const useCurrencyOptions = () => {
  const { data, isLoading:isCurrencyLoading } = useCurrencies()
  const currencyOptions = data?.data.map(({ name, symbol, countryName }) => ({
    name: countryName + ' ' +'('+ name + ' ' + symbol + ')',
    id: symbol,
  })) || [
    {
      name: 'Nigeria (Naira ₦)',
      id: '₦',
    },
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tempObj: any = {}
  currencyOptions.forEach((n) => (tempObj[n.name] = n))
  const options = Object.values(tempObj)

  return {
    currencyOptions:options as Option[],
    isCurrencyLoading
  }
}
