import React from 'react'
import { AiFillStar } from 'react-icons/ai'

export const AddSkillHint = () => {
  const SKILL_CONTENTS = [
    {
      rating: 1,
      id: 1,
      title: 'General concept',
      description: '',
    },
    {
      rating: 2,
      id: 2,
      title: 'Professional Knowledge gathered through practices',
      description: '',
    },
    {
      rating: 3,
      id: 3,
      title: 'Professional Knowledge gathered through independent work',
      description: '',
    },
    {
      rating: 4,
      id: 4,
      title: 'Extensive Professional Knowledge in the domain',
      description: '',
    },
    {
      rating: 5,
      id: 5,
      title: 'Cutting-edge Professional Knowledge in the domain',
      description: '',
    },
  ]

  return (
    <div className="rounded-[10px] rounded-tr-none border bg-white px-6 py-2 shadow-md">
      {SKILL_CONTENTS.map((content, index) => {
        const statusColor =
          content.rating == 0
            ? 'rgba(25, 25, 25, 0.1)'
            : content.rating == 5
            ? 'rgba(47, 128, 237, 1)'
            : content.rating == 4
            ? 'rgba(39, 174, 96, 1)'
            : content.rating == 3
            ? 'rgba(226, 185, 59, 1)'
            : content.rating == 2
            ? 'rgba(226, 130, 59, 1)'
            : 'rgba(235, 87, 87, 1)'
        return (
          <div
            key={index}
            className="my-2 flex items-center gap-4 border-b py-2"
          >
            <div className="flex min-w-[100px] items-center gap-1">
              {Array(content.rating)
                .fill(content.rating)
                .map((_, index) => {
                  return (
                    <AiFillStar
                      className="text-[18px]"
                      key={index}
                      // onMouseOver={() => !number && setHoverStar(index + 1)}
                      // onMouseLeave={() => setHoverStar(undefined)}
                      style={{ color: statusColor }}
                    />
                  )
                })}
            </div>
            <p className="text-sm">{content.title}</p>
          </div>
        )
      })}
    </div>
  )
}

