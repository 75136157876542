import { useQuery } from '@tanstack/react-query'

import {  fetchData, hasAppliedKeys } from '@/utils'
import { HasApplied } from '../types'
import { userIDStorage } from '@/features/auth'

interface HasAppliedResponse {
  status: string
  message: string
  data: HasApplied
}

export const useHasApplied = (jobId: string) => {
  const userID = userIDStorage.getUserID();
  return useQuery<HasAppliedResponse>({
    queryKey: hasAppliedKeys.list(jobId),
    queryFn: async () => fetchData(`/user/job/has-applied/${jobId}`),
    enabled: !!jobId && !!userID
  })
}