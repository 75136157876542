import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import {
  fetchData,
  invitedMembersKeys,
  transformError,
  userKeys,
} from '@/utils'
import { InviteUserPayload } from '../types'
import { useCompanyId } from '../hooks'
import { AxiosError } from 'axios'

interface InviteUserResponse {
  status: string
  message: string
  data: {
    email: string
    id: string
  }
}

export const useInviteUser = () => {
  const queryClient = useQueryClient()
  const companyID = useCompanyId()
  return useMutation<InviteUserResponse, AxiosError, InviteUserPayload>(
    (payload) =>
      fetchData<InviteUserPayload>(
        `/company/user/invite/${companyID}`,
        'POST',
        payload
      ),
    {
      onSuccess: () => {
        toast.success('Member successfully added.')
        queryClient.invalidateQueries({
          queryKey: userKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: invitedMembersKeys.all,
        })
      },
      onError: (error: AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
