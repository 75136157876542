import { useRouter } from 'next/router'

import { User } from '../types'

export const useRedirection = () => {
  const router = useRouter()
  const redirect = (user: User | null) => {
    if (!user) return
    
    // if (!user.emailVerifiedAt) {
    //   return router.push(`/verification?email=${user.email}`)
    // }

    const isRecruiter = user.isRecruiter;
    const isCompanyCount = user.companyCount > 0;

    if (isRecruiter && !isCompanyCount) {
      router.push('/recruiter/onboarding/1')
      return
    }

    if (isRecruiter && isCompanyCount) {
      return router.push('/recruiter/dashboard')
    }


    if (!user.photoUrl) {
      router.push('/job-seeker/onboarding/1')
    } else if (user.photoUrl && !user.jobSearchStatus) {
      router.push('/job-seeker/onboarding/2')
    } else {
      router.push('/job-seeker/dashboard')
    }
  }

  return {
    redirect,
  }
}
