import Link from 'next/link'

import { whatImportantInJob } from '@/data'
import { Button } from '@/components'

export const OnboardingCultureFit = () => {
  return (
    <div className="pb-10">
      <div className="m-auto max-w-[652px] text-center">
        <p>Step 3/5</p>
        <h2 className="text-[40px]">Find your culture fit</h2>
        <p className="">
          Tell us about your interests and preferences and we will <br /> find
          you the perfect jobs.
        </p>
      </div>
      <div className="mt-10">
        <h4>Are you a student or new grad?</h4>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="student" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Solving technical problems
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="student" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Building products
            </label>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h4>
          Over the next five years, what career track do you want to follow?
        </h4>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="career" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Individual contributor
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="career" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Manager
            </label>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h4>What environment do you work better in?</h4>
        <div className="mt-4 items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="responsibity" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Clear role and set of responsibilities. Consistent feedback from
              management.
            </label>
          </div>
          <div className="mt-4 flex items-center gap-2">
            <input id="Co-founder" type="radio" name="responsibity" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Employees wear a lot of hats. Assignments often require employees
              to figure it out on their own.
            </label>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h4>What is most important to you in your next job? (Max 2)</h4>
        <div className=" flex w-full flex-wrap gap-x-[10rem]">
          {whatImportantInJob.map((stuff, Index) => (
            <div
              className="mt-4 flex  w-[40%] items-center gap-6 self-start"
              key={Index}
            >
              <div className="round">
                <input type="checkbox" id="jobImportant" />
                <label htmlFor="jobImportant"></label>
              </div>
              <p className="text-sm">{stuff}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-10">
        <h4>
          How important is it that your next job has a flexible remote work
          policy?
        </h4>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="work-policy" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Very important
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="work-policy" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Important
            </label>
          </div>
          <div className="flex items-center  gap-2">
            <input id="Co-founder" type="radio" name="work-policy" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Not important
            </label>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h4>How important is it that you work in a quiet office?</h4>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="work-policy" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Very important
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="work-policy" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Important
            </label>
          </div>
          <div className="flex items-center  gap-2">
            <input id="Co-founder" type="radio" name="work-policy" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Not important
            </label>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h4>How important is it that you work in a quiet office?</h4>
        <p className="mt-4 text-[#A6A6A6]">
          Startups tell us this is one of the first things they look at in a
          profile
        </p>
        <textarea
          name=""
          id=""
          placeholder="I'm looking for e.g, an enthusiastic engineering team to work for that will provide me with..."
          className="mt-4 h-[10rem] w-full resize-none rounded-sm border  border-[#A6A6A6] p-6 text-sm outline-none"
        ></textarea>
      </div>
      <Link
        href="/job-seeker/onboarding/4"
        className="mx-auto mt-10 block max-w-sm"
      >
        <Button type="submit">Save and Continue</Button>
      </Link>
    </div>
  )
}
