import { externalJobData } from "@/types";
import { companyKeys, fetchData } from "@/utils";
import { useQuery } from "@tanstack/react-query";


interface CompanyListResponse {
    status: string;
    message: string;
    data: externalJobData[];
}

export const useFetchExternalJob = () => {
    return useQuery<CompanyListResponse>({
        queryKey: companyKeys.lists(),
        queryFn: async () => fetchData('/common/external-jobs') 
    });
}