import {  RoundCheckbox } from '@/components'
import { companySizes } from '@/data'
import React from 'react'
// import { useForm, Control } from 'react-hook-form'
// import { BsChevronDown } from 'react-icons/bs'

interface CompanyFilterProps {
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  selectedItems: string[]
  // setSearchIndustry: React.Dispatch<React.SetStateAction<string>>
  searchTerm: string
  handleSearchChange: React.ChangeEventHandler<HTMLInputElement>
}

export const CompanyFilter = ({
  selectedItems,
  handleSearchChange,
  searchTerm,
  // setSearchIndustry,
  setSelectedItems,
}: CompanyFilterProps) => {
  // const {
  //   // control,
  //   // register,
  //   // handleSubmit,
  // } = useForm({})

  const handleCheckboxChange = (itemId: string) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((item) => item !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  return (
    <div className="w-full bg-white px-8 py-8">
      <p className="text-sm font-bold text-black">Industry</p>
      <div className="mt-4 flex flex-1 items-center gap-4 rounded-[5px] py-3 border border-[#A6A6A6] bg-[white] pr-3">
        {/* <CustomSelectFieldIndustry
          className="border-none bg-transparent py-5 px-7 placeholder-[#A6A6A6]"
          control={control as unknown as Control}
          hasError={undefined}
          name="industries"
          background="transparent"
          setSearchIndustry={setSearchIndustry}
        /> */}
        <input
          type="text"
          className="w-full rounded-md border-none px-4 font-WorkSans text-sm placeholder-[#A6A6A6] outline-none"
          placeholder="Search Industry"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {/* <p className="flex h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-sm bg-white text-[#A6A6A6]">
          <BsChevronDown />
        </p> */}
      </div>
      <p className="mt-6 border-t border-t-[#A6A6A6] pt-4 text-sm font-bold text-black">
        Company Size
      </p>
      <div>
        {companySizes.map((sizeData, index) => {
          return (
            <button
              key={index}
              className="mt-6 flex items-center gap-4 text-sm"
              onClick={() => handleCheckboxChange(sizeData.size)}
            >
              <RoundCheckbox
                checked={selectedItems.includes(sizeData.size)}
                onChange={() => handleCheckboxChange(sizeData.size)}
              />
              <p>
                {sizeData.name} ({sizeData.size})
              </p>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default CompanyFilter
