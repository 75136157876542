import { yupResolver } from '@hookform/resolvers/yup'
import { Control, useForm } from 'react-hook-form'
import React from 'react'

import {
  OnboardingCreateCompanyProfileForm,
  OnboardingCreateCompanyProfilePayload,
} from '../../types'
import { onboardingCompanyDetailSchema } from '../../utils'
import { useFileUpload } from '@/api'
import { useObjectURL } from '@/hooks'
import {
  Button,
  CustomSelectFieldIndustry,
  InputField,
  InputFieldPhoneNo,
  // InputLocationAutoComplete,
  SelectField,
  TextAreaField,
} from '@/components'
import { LabelTitle } from '..'
import { EMPTY_OPTION, NUMBER_OF_EMPLOYEES_OPTIONS } from '@/utils'
import { useOnboardingCreateCompanyProfile } from '../../api'
import { toast } from 'react-toastify'

export const RecruiterOnboardingCompanyProfile = () => {
  const [companyLogoUrl, setCompanyLogoUrl] = React.useState('')
  const mutationFileUpload = useFileUpload()
  const mutationCompanyProfile = useOnboardingCreateCompanyProfile()

  const {
    formState: { errors },
    watch,
    control,
    register,
    setValue,
    handleSubmit,
  } = useForm<OnboardingCreateCompanyProfileForm>({
    defaultValues: {
      industry: EMPTY_OPTION,
      companyLogos: null,
      employeeCount: EMPTY_OPTION,
    },
    resolver: yupResolver(onboardingCompanyDetailSchema),
    mode: 'onBlur',
  })

  const companyPhone = watch('companyPhone')
  // const companyLocation = watch('companyLocation')
  const companyLogos = watch('companyLogos')
  const companyLogo = useObjectURL(companyLogos && companyLogos[0])

  React.useEffect(() => {
    if (companyLogos && companyLogos.length > 0) {
      const formData = new FormData()
      formData.append('file', companyLogos[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setCompanyLogoUrl(data.fileUrl)
        },
        onError: () => {
          setValue('companyLogos', null)
          toast.error('Logo was not uploaded')
        },
      })
    }
  }, [companyLogos])

  // const handleSelect = (companyLocation: string) => {
  //   setValue('companyLocation', companyLocation)
  // }

  const isFreshUpload = !companyLogo || companyLogo?.length === 0

  const onSubmit = (data: OnboardingCreateCompanyProfileForm) => {
    if (!companyLogoUrl) {
      return toast.error('Upload company logo')
    }
    const {
      facebook,
      twitter,
      instagram,
      industry,
      linkedin,
      companyDescription,
      companyEmail,
      companyLocation,
      companyName,
      companyPhone,
      companyWebsite,
      employeeCount,
      userRole,
    } = data

    const companySocials = [
      {
        name: 'facebook',
        url: facebook,
      },
      {
        name: 'twitter',
        url: twitter,
      },
      {
        name: 'instagram',
        url: instagram,
      },
      {
        name: 'linkedin',
        url: linkedin,
      },
    ]

    const payload: OnboardingCreateCompanyProfilePayload = {
      companyName,
      companyLocation,
      companyEmail,
      companyDescription,
      companyLogo: companyLogoUrl,
      industryId: industry.id,
      companySocials,
      companyPhone,
      companyWebsite,
      employeeCount: employeeCount.id,
      userRole,
    }

    mutationCompanyProfile.mutate(payload)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" text-black">
      <div className="m-auto max-w-[652px] text-center">
        <p>Step 1/2</p>
        <h2 className="py-2 text-2xl md:text-[40px] md:leading-10">
          Let’s create your company&lsquo;s Profile
        </h2>
        <p className="">
          Search for your company and we’ll look for it for you and tell us more
          about your company
        </p>
      </div>
      <div className="mt-10 md:mt-16 md:flex">
        <div className="box-border md:flex-1 md:pr-10 lg:border-r lg:pr-20">
          <div>
            <LabelTitle text="Your company name" id="companyName" isRequired />

            <InputField
              className="mt-4"
              placeholder="eg. Scout4Job"
              registration={{ ...register('companyName') }}
              errorMessage={errors.companyName?.message}
              hasError={errors.companyName}
            />
          </div>
          <div className="mt-10">
            <div>
              <LabelTitle
                text="Your company logo"
                isRequired
                id="company-logo"
              />
              {isFreshUpload ? (
                <label className="cursor-pointer" htmlFor="company-logo">
                  <p className="mt-4 text-xs">
                    <span className="text-[#2F80ED]">Browse</span> upload your
                    company picture
                  </p>

                  <div className="mt-5 inline-flex h-[100px] w-[200px] items-center justify-center rounded-lg border border-dotted border-primary bg-primary/10">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.9991 13.8991V16.1891C21.9991 19.8291 19.8291 21.9991 16.1891 21.9991H7.80908C5.25908 21.9991 3.41908 20.9291 2.55908 19.0291L2.66908 18.9491L7.58908 15.6491C8.38908 15.1091 9.51908 15.1691 10.2291 15.7891L10.5691 16.0691C11.3491 16.7391 12.6091 16.7391 13.3891 16.0691L17.5491 12.4991C18.3291 11.8291 19.5891 11.8291 20.3691 12.4991L21.9991 13.8991Z"
                        fill="#27AE60"
                      />
                      <path
                        opacity="0.4"
                        d="M20.97 8H18.03C16.76 8 16 7.24 16 5.97V3.03C16 2.63 16.08 2.29 16.22 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03L2.67 18.95L7.59 15.65C8.39 15.11 9.52 15.17 10.23 15.79L10.57 16.07C11.35 16.74 12.61 16.74 13.39 16.07L17.55 12.5C18.33 11.83 19.59 11.83 20.37 12.5L22 13.9V7.78C21.71 7.92 21.37 8 20.97 8Z"
                        fill="#27AE60"
                      />
                      <path
                        d="M9.00109 10.381C9.63231 10.381 10.2377 10.1303 10.684 9.68391C11.1303 9.23758 11.3811 8.63222 11.3811 8.001C11.3811 7.36979 11.1303 6.76442 10.684 6.31809C10.2377 5.87175 9.63231 5.621 9.00109 5.621C8.36988 5.621 7.76452 5.87175 7.31818 6.31809C6.87184 6.76442 6.62109 7.36979 6.62109 8.001C6.62109 8.63222 6.87184 9.23758 7.31818 9.68391C7.76452 10.1303 8.36988 10.381 9.00109 10.381ZM20.9701 1H18.0301C16.7601 1 16.0001 1.76 16.0001 3.03V5.97C16.0001 7.24 16.7601 8 18.0301 8H20.9701C22.2401 8 23.0001 7.24 23.0001 5.97V3.03C23.0001 1.76 22.2401 1 20.9701 1ZM21.9101 4.93C21.8101 5.03 21.6601 5.1 21.5001 5.11H20.0901L20.1001 6.5C20.0901 6.67 20.0301 6.81 19.9101 6.93C19.8101 7.03 19.6601 7.1 19.5001 7.1C19.1701 7.1 18.9001 6.83 18.9001 6.5V5.1L17.5001 5.11C17.3401 5.10738 17.1875 5.04195 17.0752 4.92784C16.963 4.81372 16.9001 4.66006 16.9001 4.5C16.9001 4.17 17.1701 3.9 17.5001 3.9L18.9001 3.91V2.51C18.9001 2.18 19.1701 1.9 19.5001 1.9C19.8301 1.9 20.1001 2.18 20.1001 2.51L20.0901 3.9H21.5001C21.8301 3.9 22.1001 4.17 22.1001 4.5C22.0899 4.66136 22.0225 4.81381 21.9101 4.93Z"
                        fill="#27AE60"
                      />
                    </svg>
                  </div>
                </label>
              ) : (
                <div className="group relative w-[150px]">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={companyLogo || companyLogoUrl}
                    alt="profile image"
                    className="mx-auto mb-4 max-w-full bg-white object-cover"
                  />
                  <label
                    htmlFor="company-logo"
                    className="absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 px-2 text-center text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                  >
                    <span className="text-xs">Change</span>
                  </label>
                </div>
              )}
              <input
                className="sr-only"
                type="file"
                id="company-logo"
                accept="image/png, image/jpeg"
                {...register('companyLogos')}
              />
            </div>
          </div>

          <div className="mt-10">
            <LabelTitle
              text="Your company's official number"
              isRequired
              id="companyPhone"
            />
            <InputFieldPhoneNo
              className="mt-4"
              control={control as unknown as Control}
              defaultValue={companyPhone}
              name="companyPhone"
              hasError={errors.companyPhone}
              errorMessage={errors.companyPhone?.message}
              isRequired
            />
          </div>

          <div className="mt-10">
            <div>
              <LabelTitle
                text="Your company's location"
                isRequired
                id="companyLocation"
              />
             <InputField
                className="mt-4"
                placeholder="eg. City, state, or country"
                registration={{ ...register('companyLocation') }}
                hasError={errors.companyLocation}
              />
            </div>
          </div>

          <div className="mt-10">
            <div>
              <LabelTitle text="Your company's email" isRequired id="" />

              <InputField
                type="email"
                className="mt-4"
                placeholder="email address"
                registration={{ ...register('companyEmail') }}
                errorMessage={errors.companyEmail?.message}
                hasError={errors.companyEmail}
              />
            </div>
            <p className="mt-2 text-sm text-gray-250">
              We will need this to confirm your email in the next step
            </p>
          </div>

          <div className="mt-10">
            <LabelTitle
              text="Your company's size"
              isRequired
              id="employeeCount"
            />
            <SelectField
              className="mt-4"
              control={control as unknown as Control}
              hasError={errors.employeeCount}
              arr={NUMBER_OF_EMPLOYEES_OPTIONS}
              placeholder="Select company size"
              name="employeeCount"
            />
          </div>
        </div>

        <div className="pt-10 md:flex-1 md:pt-0 md:pl-10 lg:pl-20">
          <div>
            <LabelTitle
              text="Your role in the company"
              id="userRole"
              isRequired
            />

            <InputField
              className="mt-4"
              placeholder="e.g founder, H.R"
              registration={{ ...register('userRole') }}
              hasError={errors.userRole}
            />
          </div>

          <div className="mt-10 text-base">
            <LabelTitle text="Your company's website" id="companyWebsite" />

            <InputField
              className="mt-4"
              placeholder="eg. www.scout4Job.com"
              registration={{ ...register('companyWebsite') }}
              errorMessage={errors.companyWebsite?.message}
              hasError={errors.companyWebsite}
            />
          </div>

          <div className="mt-10 text-base">
            <div className="mt-10">
              <LabelTitle
                id="industry"
                text="Your organization's industry"
                isRequired
              />
              <CustomSelectFieldIndustry
                className="mt-4"
                control={control as unknown as Control}
                hasError={errors.industry}
                placeholder="Start typing to search..."
                name="industry"
              />
            </div>
          </div>

          <div className="mt-10 text-base">
            <LabelTitle id="socialMedia" text="Social media Links" />
            <InputField
              className="mt-4"
              placeholder="eg. facebook.com/scout4job"
              registration={{ ...register('facebook') }}
              hasError={undefined}
            />
            <InputField
              className="mt-4"
              placeholder="eg. linkedin.com/scout4job"
              registration={{ ...register('linkedin') }}
              hasError={undefined}
            />
            <InputField
              className="mt-4"
              placeholder="eg. instagram.com/scout4job"
              registration={{ ...register('instagram') }}
              hasError={undefined}
            />
            <InputField
              className="mt-4"
              placeholder="eg. twitter.com/scout4job"
              registration={{ ...register('twitter') }}
              hasError={undefined}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <LabelTitle
          id="socialMedia"
          text="Describe your company
          in few words"
          isRequired
        />

        <p className="my-2 text-sm text-gray-250">
          Job seekers tell us this is one of the first things they look at in a
          company&lsquo;s profile
        </p>
        <TextAreaField
          hasError={errors.companyDescription}
          className="mt-4"
          placeholder="What's special about your company? What hard problems are you solving? What's great about your culture?"
          registration={{ ...register('companyDescription') }}
          id="companyDescription"
        />
        <p className="mt-10 text-center text-sm">
          By continuing you accept our standard{' '}
          <span className="text-primary">Guideline</span> and our{' '}
          <span className="text-primary">Terms of Service.</span>{' '}
        </p>
      </div>

      <div className="mx-auto mt-10 flex max-w-sm justify-center">
        <Button
          disabled={
            mutationCompanyProfile.isLoading || mutationFileUpload.isLoading
          }
          isLoading={
            mutationCompanyProfile.isLoading || mutationFileUpload.isLoading
          }
          className="w-full"
          type="submit"
        >
          {mutationFileUpload.isLoading ? (
            'Uploading Logo'
          ) : (
            <>
              {mutationCompanyProfile.isLoading
                ? 'Creating a profile...'
                : 'Create profile'}
            </>
          )}
        </Button>
      </div>
    </form>
  )
}
