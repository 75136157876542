import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import Image from 'next/image'

import { backdropVariant, editProfile } from '@/utils'
import { JobApplicant } from '@/features/job-seeker'
import { useChangeApplicantStatus } from '../../api'
import { Button } from '@/components'
import { useRouter } from 'next/router'

interface ConfirmDisqualifyApplicants {
  applicant: JobApplicant | undefined
  openConfirmDisqualifyModal: boolean
  setOpenConfirmDisqualifyModal: (value: boolean) => void
}

export const ConfirmDisqualifyApplicants: React.FC<
  ConfirmDisqualifyApplicants
> = ({
  applicant,
  openConfirmDisqualifyModal,
  setOpenConfirmDisqualifyModal,
}) => {
  const router = useRouter()
  const id = router.query.id as string
  const { firstName, lastName, photoUrl } = applicant || {}
  const ref = useRef<HTMLInputElement>(null)
  const { mutate, isLoading } = useChangeApplicantStatus(id || '')

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openConfirmDisqualifyModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenConfirmDisqualifyModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openConfirmDisqualifyModal])

  const handleQualify = () => {
    mutate(
      { status: 'disqualified', applicants: [applicant?.userId || ''] },
      {
        onSuccess: () => {
          setOpenConfirmDisqualifyModal(false)
        },
      }
    )
  }

  return (
    <AnimatePresence>
      {openConfirmDisqualifyModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  jus absolute top-[50%]  left-[50%] flex max-h-[612px] w-[511px] flex-col items-center overflow-scroll  rounded-lg bg-[#FAFAFA] px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            style={{ height: 'calc(100vh - 156px)' }}
            variants={editProfile}
          >
            <div className="flex flex-col items-center justify-center text-center">
              {/* <Image  /> */}
              <Image
                height={213}
                width={206}
                alt="qualified"
                src="/assets/confrmDisqualify.gif"
              />

              <h4 className="my-2 text-2xl">Disqualify Applicant</h4>

              <p className="mt-4 text-sm">
                Are you sure you want to discard this applicant?
              </p>

              <div className="my-2  flex items-center gap-4 border-b-[#E5E5E5] pb-6">
                <div>
                  <img
                    className="profile-picture h-[57px] w-[57px]"
                    alt="applicant"
                    src={photoUrl || ''}
                  />
                </div>
                <div className=" pr-10">
                  <p className="text-sm font-bold">
                    {firstName} {lastName}
                  </p>
                </div>
              </div>

              <div className="mt-8 flex items-center gap-4">
                <Button
                  disabled={isLoading}
                  variant="outline"
                  onClick={() => setOpenConfirmDisqualifyModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="outline"
                  style={{ background: '#EB5757', color: 'white' }}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={handleQualify}
                >
                  {isLoading ? 'Disqualifying...' : 'Disqualify'}
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
