import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef } from 'react'
import { backdropVariant, shareJob } from '@/utils'
import { BsXLg } from 'react-icons/bs'
import { useClipboard } from '../Elements/useClipboard'
import Image from 'next/image'

interface ShareJobProps {
  openShareJob: boolean
  setOpenShareJob: (value: boolean) => void
  jobId: string
}

export const ShareJob: React.FC<ShareJobProps> = ({
  openShareJob,
  setOpenShareJob,
  jobId,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const { copy } = useClipboard()
  const BASE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL as string
  const jobLink = `${BASE_URL}/job/${jobId}`
  const text = 'Check%20out%20this%20job%20on%20Scout4job'

  return (
    <AnimatePresence>
      {openShareJob && (
        <motion.div
          className="absolute bottom-0 left-0 z-[100] h-screen w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide scrollbar-hide absolute top-[50%] left-[50%] w-[90%] overflow-scroll rounded-lg  bg-white px-4 py-6 pt-7 md:w-[524px] md:px-8 md:pt-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={shareJob}
          >
            <div>
              <div>
                <span
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setOpenShareJob(false)}
                >
                  <BsXLg />
                </span>
              </div>

              <div>
                <p className="mt-5 text-center text-3xl font-bold">
                  Share this job with your <br />
                  community
                </p>
              </div>

              <div>
                <div className=" my-4 ">
                  <div className="my-6 flex justify-evenly">
                    <div className="items-center">
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${jobLink}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          className="min-w-[50px]"
                          src={`https://res.cloudinary.com/dbgbfutq9/image/upload/v1683369018/800px-LinkedIn_icon_circle.svg_wgdv3h.png`}
                          alt="linkedin icon"
                          height={50}
                          width={50}
                        />
                        <p className="mt-2 text-center text-xs">LinkedIn</p>
                      </a>
                    </div>

                    <div className="text-center">
                      <a
                        href={`https://wa.me/?text=${text}%20${jobLink}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          className="min-w-[50px]"
                          src={`https://res.cloudinary.com/dbgbfutq9/image/upload/v1683369499/whatsapp_PNG17_u8s6sj.png`}
                          alt="whatsapp icon"
                          height={50}
                          width={50}
                        />
                        <p className="mt-2 text-center text-xs">WhatsApp</p>
                      </a>
                    </div>

                    <div className="text-center">
                      <a
                        href={`https://twitter.com/intent/tweet?text=${text}&url=${jobLink}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          className="min-w-[50px]"
                          src={`https://res.cloudinary.com/dbgbfutq9/image/upload/v1653996973/twitter_jum9w6.png`}
                          alt="twitter icon"
                          height={50}
                          width={50}
                        />
                        <p className="mt-2 text-center text-xs">Twitter</p>
                      </a>
                    </div>

                    <div className="text-center">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${jobLink}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          className="min-w-[50px]"
                          src={`https://res.cloudinary.com/dbgbfutq9/image/upload/v1667037874/circle-facebook_-512_mirg5e.webp`}
                          alt="facebook icon"
                          height={50}
                          width={50}
                        />
                        <p className="mt-2 text-center text-xs">Facebook</p>
                      </a>
                    </div>

                    <div className="text-center">
                      <a
                        href={`https://t.me/share/url?url=${jobLink}&text=${text}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          className="min-w-[50px]"
                          src={`https://res.cloudinary.com/dbgbfutq9/image/upload/v1683369259/768px-Telegram_logo.svg_ovuqij.png`}
                          alt="telegram icon"
                          height={50}
                          width={50}
                        />
                        <p className="mt-2 text-center text-xs">Telegram</p>
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center justify-between rounded-md border">
                    <div className=" rounded-l-md bg-gray-200 py-2 w-full">                   
                      <p className=" w-[17rem] md:w-[26rem] truncate px-3">{jobLink}</p>
                    </div>
                    <div className="py-2.5 bg-[#16BF44] text-white text-sm rounded-r-md">
                      <button type="button" onClick={() => copy(jobLink)} className='px-1'>
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
