import { useQuery } from '@tanstack/react-query'

import { fetchData } from '@/utils'
import { RolesAndPermissions } from '@/types'

interface RolesAndPermissionsResp {
  status: string
  message: string
  data: RolesAndPermissions[]
}

export const useRolesAndPermissions = () => {
  return useQuery<RolesAndPermissionsResp>({
    queryKey: ['rolesAndPermissions'],
    queryFn: async () => fetchData('/common/roles-and-permissions'),
  })
}
