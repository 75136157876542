import { profileStatus } from '@/data'

export const ReadyToInterview = () => (
  <div
    className="status flex items-center justify-between rounded-md bg-opacity-10 py-2 px-4"
    style={{
      color: profileStatus[0].color,
      background: profileStatus[0].background,
    }}
  >
    <div className="flex  items-center">
      <div
        className="border-r pr-2"
        style={{ borderColor: profileStatus[0].color }}
      >
        <img
          src={profileStatus[0].icon}
          alt=""
          className="cursor-pointer"
          height={20}
          width={20}
        />
      </div>
      <p
        className=" whitespace-nowrap pl-2 text-xs font-bold capitalize"
        style={{ color: profileStatus[0].color }}
      >
        {profileStatus[0].text}
      </p>
    </div>
  </div>
)

export const OpenToOffers = () => (
  <div
    className="status flex items-center justify-between rounded-md bg-opacity-10 py-2 px-4"
    style={{
      color: profileStatus[1].color,
      background: profileStatus[1].background,
    }}
  >
    <div className="flex  items-center">
      <div
        className="border-r pr-2"
        style={{ borderColor: profileStatus[1].color }}
      >
        <img
          src={profileStatus[1].icon}
          alt=""
          className="cursor-pointer"
          height={20}
          width={20}
        />
      </div>
      <p
        className=" whitespace-nowrap pl-2 text-xs font-bold capitalize"
        style={{ color: profileStatus[1].color }}
      >
        {profileStatus[1].text}
      </p>
    </div>
  </div>
)
export const ClosedToOffers = () => (
  <div
    className="status flex items-center justify-between rounded-md bg-opacity-10 py-2 px-4"
    style={{
      color: profileStatus[2].color,
      background: profileStatus[2].background,
    }}
  >
    <div className="flex  items-center">
      <div
        className="border-r pr-2"
        style={{ borderColor: profileStatus[2].color }}
      >
        <img
          src={profileStatus[2].icon}
          alt=""
          className="cursor-pointer"
          height={20}
          width={20}
        />
      </div>
      <p
        className=" whitespace-nowrap pl-2 text-xs font-bold capitalize"
        style={{ color: profileStatus[2].color }}
      >
        {profileStatus[2].text}
      </p>
    </div>
  </div>
)
