import { useUser} from '@/features/auth'

export const useUserInfo = () => {
  const { data: user, isLoading } = useUser()
  return {
    isLoading,
    user,
  }
}

