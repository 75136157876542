import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { BsStarFill } from 'react-icons/bs'
import clsx from 'clsx'
import React from 'react'

import { coloredRating, overallRecommendation } from '@/data'
import { useGetInterviewFeedback } from '../api'
import { Scorecard, overallRecommendationProps } from '../types'
import { EmptyState } from '@/components'
import { AlignBottom } from 'iconsax-react'

interface InterviewAnalysisProp {
  interviewId: string
  scoreCardData: Scorecard[]
}

export const InterviewAnalysis = ({
  interviewId,
  scoreCardData,
}: InterviewAnalysisProp) => {
  const { data: feedbackData } = useGetInterviewFeedback(interviewId)

  const averageScore = feedbackData?.data?.map(
    (averageRating) => averageRating.overallRating
  )
  const averageScoreNum = averageScore?.map((score) => Number(score)) || []
  const scoreForAnalysis =
    averageScoreNum.length > 0 && averageScoreNum?.reduce((a, b) => a + b)
  const averageScoreForAnalysis =
    (scoreForAnalysis as number) / averageScoreNum?.length

  const interviewRatingData = feedbackData?.data.map((data) => {
    return data.interviewScore.map((data) => {
      return data
    })
  })

  const numberOfInterviewersFeedback = feedbackData?.data.length || 0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const averages: any = {}
  interviewRatingData?.forEach((group) => {
    group.forEach((item) => {
      if (!averages[item.name]) {
        averages[item.name] = { total: 0, count: 0 }
      }
      averages[item.name].total += item.rating
      averages[item.name].count++
    })
  })

  Object.keys(averages).forEach((name) => {
    averages[name].average = averages[name].total / averages[name].count
    delete averages[name].total
    delete averages[name].count
  })

  const preppedScoreArr = Object.entries(averages).map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ([name, { average }]: any) => ({ name, average })
  )

  const preppedArray = preppedScoreArr.map((data) => data.average)
 

  const ranges = [
    { min: 1, max: 2, description: 'Below Average' },
    { min: 2, max: 3, description: 'Average' },
    { min: 3, max: 4, description: 'Above Average' },
    { min: 4, max: 5, description: 'Excellent' },
    { min: 5, max: 6, description: 'Outstanding' },
  ];
  
  const totalCount = preppedArray.length;
  
  const result = ranges.map((range) => {
    const filteredItems = preppedArray.filter((item) => item >= range.min && item < range.max);
    const occurrence = filteredItems.length;
    const percentage = (occurrence / totalCount) * 100;
  
    return {
      number: range.min,
      occurrence,
      percentage: `${percentage.toFixed(2)}%`,
      description: range.description,
    };
  });


  // finds average of the hire, notHire, stronglyHire, and stronglyNotHire

  const overallCommentArr: number[] = []

  function SearchNumRating(
    nameKey: string,
    myArray: overallRecommendationProps[]
  ) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
        overallCommentArr.push(myArray[i].numberRep)
      }
    }
  }
  feedbackData?.data?.map((data) => {
    SearchNumRating(data?.overallRecommendation, overallRecommendation)
  })

  const averageOverallComment =
    overallCommentArr.length &&
    overallCommentArr?.reduce((a, b) => a + b) / overallCommentArr.length

  averageOverallComment

  const ratingDescription = (ratingValue: number) => {
    return ratingValue >= 5
      ? 'Exceptional'
      : ratingValue >= 4 && ratingValue < 5
      ? 'Very Good'
      : ratingValue >= 3 && ratingValue < 4
      ? 'Good'
      : ratingValue >= 2 && ratingValue < 3
      ? 'Average'
      : ratingValue == 0
      ? 'Not Assessed'
      : 'Below Average'
  }
  const ratingNumber = ratingDescription(averageScoreForAnalysis)
  const colorPicker = (ratingValue: number) => {
    return ratingValue >= 5
      ? ['rgba(47, 128, 237, 1)', 'rgba(47, 128, 237, 0.1)']
      : ratingValue >= 4 && ratingValue < 5
      ? ['rgba(39, 174, 96, 1)', 'rgba(39, 174, 96, 0.1)']
      : ratingValue >= 3 && ratingValue < 4
      ? ['rgba(226, 185, 59, 1)', 'rgba(226, 185, 59, 0.1)']
      : ratingValue >= 2 && ratingValue < 3
      ? ['rgba(226, 130, 59, 1)', 'rgba(226, 130, 59, 0.1)']
      : ratingValue >= 1 && ratingValue < 2
      ? ['rgba(235, 87, 87, 1)', 'rgba(235, 87, 87, 0.1)']
      : ['rgba(166, 166, 166, 1)', 'rgba(166, 166, 166, 1)']
  }
  const colorPicked = colorPicker(Number(averageScoreForAnalysis))

  return (
    <>
      <div className="scrollbar-hide left-0 z-50 h-full overflow-scroll bg-white px-5 pt-8  pb-16 text-black ">
        <div className="flex gap-20">
          {/* first row */}
          <div className="">
            {feedbackData && feedbackData?.data?.length > 0 && <p className="text-[20px] font-semibold">Overall Recommendation</p>}
            {feedbackData && feedbackData?.data.length > 0 && (
              <div className="my-4 flex flex-wrap items-center gap-2">
                {overallRecommendation.map((recommendation, index) => {
                  return (
                    <div
                      key={index}
                      className={clsx(
                        'bg whitespace-nowrap rounded-sm bg-opacity-10 px-4 py-1 text-xs'
                      )}
                      style={{
                        backgroundColor: recommendation.bgColor,
                        color: recommendation.color,
                        border: '1px solid',
                        borderColor: recommendation.color,
                      }}
                    >
                      {recommendation.name}
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          {/* second row */}
          {feedbackData && feedbackData?.data.length > 0 && (
            <div>
              <div className="flex items-center">
                <div className="min-w-[44px] border-r border-r-[#E5E5E5] pr-[30px] text-yellow-400">
                  <BsStarFill className="text-[44px]" />
                </div>
                <div className="pl-[30px]">
                  <div className=" flex items-center gap-3">
                    <p className="whitespace-nowrap text-[20px] font-bold">
                      {averageScoreForAnalysis} Rating
                    </p>
                    <p
                      style={{
                        background: colorPicked[1],
                        color: colorPicked[0],
                        border: '1px solid',
                        borderColor: colorPicked[0],
                      }}
                      className="flex items-center justify-center rounded-sm px-4  py-1 text-xs"
                    >
                      {ratingNumber}
                    </p>
                  </div>
                  <p className="text-s, text-[#A6A6A6]">
                    Based on {numberOfInterviewersFeedback} individual review(s)
                  </p>
                </div>
              </div>
              <div className="my-4 flex flex-col gap-3">
                {result.map((rating, index) => {
                  return (
                    <div key={index} className="flex items-center">
                      <div className="w-[135px] min-w-[105px]">
                        <button
                          value={rating.description}
                          type="button"
                          className={clsx(
                            'bg rounded-sm bg-opacity-10 px-4 py-1 text-xs'
                          )}
                          style={{
                            backgroundColor: coloredRating[index].bgColor,
                            color: coloredRating[index].color,
                            border: '1px solid',
                            borderColor: coloredRating[index].color,
                          }}
                        >
                          {rating.description}
                        </button>
                      </div>

                      {/* bar chart */}
                      <div className="h-3 w-[240px] min-w-[240px] bg-[#EFEFEF]">
                        <div
                          className="h-full rounded-sm"
                          style={{
                            width: `${rating.percentage}`,
                            background: coloredRating[index].color,
                          }}
                        ></div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {feedbackData && feedbackData?.data.length > 0 ? (
          <>
            <p className="text-[20px]">Competence Ratings</p>
            <div>
              {preppedScoreArr.map((rating, index) => {
                const textColor =
                  rating.average >= 5
                    ? ['rgba(47, 128, 237, 1)', 'rgba(47, 128, 237, 0.1)']
                    : rating.average >= 4 && rating.average < 5
                    ? ['rgba(39, 174, 96, 1)', 'rgba(39, 174, 96, 0.1)']
                    : rating.average >= 3 && rating.average < 4
                    ? ['rgba(226, 185, 59, 1)', 'rgba(226, 185, 59, 0.1)']
                    : rating.average >= 2 && rating.average < 3
                    ? ['rgba(226, 130, 59, 1)', 'rgba(226, 130, 59, 0.1)']
                    : rating.average >= 1 && rating.average < 2
                    ? ['rgba(235, 87, 87, 1)', 'rgba(235, 87, 87, 0.1)']
                    : ['rgba(166, 166, 166, 1)', 'rgba(166, 166, 166, 1)']
                return (
                  <div key={index} className="mt-6 py-4 px-4 shadow">
                    <div className="mt-6 flex items-center gap-3">
                      <p className="text-sm font-bold">{rating.name}</p>
                      <div className="flex">
                        {Array(5)
                          .fill(rating.average)
                          .map((_, index) =>
                            rating.average >= index + 1 ? (
                              <AiFillStar
                                className="text-[18px]"
                                key={index}
                                style={{ color: textColor[0] }}
                              />
                            ) : (
                              <AiOutlineStar
                                key={index}
                                className="text-[18px]"
                                // onMouseOver={() => !number && setHoverStar(index + 1)}
                                // onMouseLeave={() => setHoverStar(undefined)}
                                style={{ color: '#A6A6A6' }}
                              />
                            )
                          )}
                      </div>
                      <p
                        className="w-fit rounded-sm px-4 py-1 text-xs"
                        style={{ background: textColor[1], border: '1px solid', borderColor:textColor[0], color: textColor[0]  }}
                      >
                        {rating.average}
                      </p>
                      <p
                        className="w-fit rounded-sm px-4 py-1 text-xs"
                        style={{ background: textColor[1], border: '1px solid', borderColor:textColor[0], color: textColor[0]  }}
                      >
                        {ratingDescription(rating.average)}
                      </p>
                    </div>

                    {scoreCardData.map((scoreRating, index) => {
                      if (scoreRating.name == rating.name)
                        return (
                          <p className="mt-4 text-sm rounded-sm" key={index}>
                            {scoreRating.description}
                          </p>
                        )
                    })}
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <EmptyState
            className="mt-[15rem]"
            maxWidthClass="max-w-[260px]"
            icon={<AlignBottom size="32" color="#FF8A65" className="mx-auto" />}
            description="No Feedback rating"
          />
        )}
      </div>
    </>
  )
}
