import { AnimatePresence, motion } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'
import { Control, useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import { useObjectURL } from '@/hooks'
import {
  Company,
  CompanyEditPayload,
  CompanyEditProfileForm,
} from '@/features/recruiter'
import { useFileUpload } from '@/api'
import { CompanyEditProfileSchema } from '@/utils/validationSchema'
import {
  EMPTY_OPTION,
  NUMBER_OF_EMPLOYEES_OPTIONS,
  backdropVariant,
  editProfile,
} from '@/utils'
import {
  Button,
  CustomSelectFieldIndustry,
  InputField,
  InputFieldPhoneNo,
  InputLocationAutoComplete,
  SelectField,
  TextAreaField,
} from '@/components'
import { useEditCompanyProfile } from '@/features/job-seeker'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'

interface CompanyEditProfileProps {
  isLoadingCompanyData: boolean
  companyDescription: string
  companyData: Company | undefined
  OpenCompanyEditProfileModal: boolean
  setOpenCompanyEditProfileModal: (value: boolean) => void
}

export const CompanyEditProfile: React.FC<CompanyEditProfileProps> = ({
  isLoadingCompanyData,
  companyData,
  companyDescription,
  setOpenCompanyEditProfileModal,
  OpenCompanyEditProfileModal,
}) => {
  const mutationEditProfile = useEditCompanyProfile()
  const mutationFileUpload = useFileUpload()
  const [companyImgUrl, setCompanyImgUrl] = React.useState('')
  const ref = React.useRef<HTMLInputElement>(null)
  const {
    companyName,
    industryId,
    industry,
    employeeCount,
    companySocials,
    companyEmail,
    companyWebsite,
    companyLogo,
    companyPhone,
    companyLocation,
  } = companyData || {}

  const {
    control,
    formState: { errors },
    watch,
    register,
    setValue,
    handleSubmit,
    reset,
  } = useForm<CompanyEditProfileForm>({
    defaultValues: {
      companyLogos: null,
      industries: EMPTY_OPTION,
      address: '',
      companyName: '',
      companyAbout: '',
      employeeCount: EMPTY_OPTION,
      twitterUrl: '',
      facebookUrl: '',
      companyPhone: '',
      instagramUrl: '',
      companyWebsite: '',
    },
    resolver: yupResolver(CompanyEditProfileSchema),
  })
  const address = watch('address')
  const companyAboutValue = watch('companyAbout')

  const handleAddressChange = (address: string) => {
    setValue('address', address)
  }



  React.useEffect(() => {
    if (companyData) {
      const EMPLOYEE_COUNT = NUMBER_OF_EMPLOYEES_OPTIONS.find(
        (empOpt) =>
          empOpt.id.toLowerCase() === employeeCount?.toString().toLowerCase()
      )

      const instagram = companySocials?.find((socialUrl) => {
        return socialUrl?.name.toLowerCase() == 'instagram'
      })

      const facebook = companySocials?.find((socialUrl) => {
        return socialUrl?.name.toLowerCase() == 'facebook'
      })

      const twitter = companySocials?.find((socialUrl) => {
        return socialUrl?.name.toLowerCase() == 'twitter'
      })

      reset({
        companyName,
        companyLogos: null,
        industries: { name: industry, id: industryId },
        address: companyLocation,
        companyWebsite: companyWebsite,
        employeeCount: EMPLOYEE_COUNT,
        companyAbout: companyDescription || '',
        companyEmail: companyEmail || '',
        instagramUrl: instagram?.url,
        facebookUrl: facebook?.url,
        twitterUrl: twitter?.url,
        companyPhone: companyPhone || '',
      })
    }
  }, [companyData, isLoadingCompanyData])

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        OpenCompanyEditProfileModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        reset()
        setOpenCompanyEditProfileModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [OpenCompanyEditProfileModal])

  const companyLogos = watch('companyLogos')
  const companyLogo2 = useObjectURL(companyLogos && companyLogos[0])

  React.useEffect(() => {
    if (companyLogos && companyLogos.length > 0) {
      const formData = new FormData()
      formData.append('file', companyLogos[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setCompanyImgUrl(data?.fileUrl)
        },
        onError: () => {
          setValue('companyLogos', null)
        },
      })
    }
  }, [companyLogos])

  // const isFreshUpload = !companyLogo || companyLogo.length === 0 || !imgUrl

  const onSubmit = (values: CompanyEditProfileForm) => {
    const {
      address,
      companyAbout,
      companyName,
      industries,
      employeeCount,
      companyEmail,
      companyWebsite,
      companyPhone,
      facebookUrl,
      twitterUrl,
    } = values

    const payload: CompanyEditPayload = {
      companyDescription: companyAbout,
      companyEmail: companyEmail,
      companyLocation: address,
      companyLogo: companyImgUrl,
      companyPhone: companyPhone,
      companyName: companyName,
      industryId: industries.id,
      companySocials: [
        {
          url: facebookUrl,
          name: 'facebook',
        },
        {
          url: twitterUrl,
          name: 'twitter',
        },
      ],
      companyWebsite: companyWebsite,
      employeeCount: employeeCount?.id,
    }

    mutationEditProfile.mutate(payload)
  }

  return (
    <AnimatePresence>
      {OpenCompanyEditProfileModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[50%] left-[50%] w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            initial="initial"
            animate="show"
            exit="exit"
            ref={ref}
            style={{ height: 'calc(100vh - 156px)' }}
            variants={editProfile}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div>
                  <p className="text-base font-bold">Edit Profile</p>
                  <button
                    type="button"
                    className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                    onClick={() => setOpenCompanyEditProfileModal(false)}
                  >
                    <BsXLg />
                  </button>
                </div>
                <p className="mb-4 text-xs">
                  The stronger your profile , the higher your chances for a
                  potential hire
                </p>
              </div>
              <div className="flex items-center gap-5">
                <div>
                  <input
                    className="sr-only"
                    type="file"
                    id="profile-picture"
                    accept="image/png, image/jpeg"
                    {...register('companyLogos')}
                  />
                  <div className="group relative mt-3 h-[90px] w-[90px] rounded-full">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={companyLogo2 || companyLogo}
                      alt="profile image"
                      className="mx-auto mb-4 h-[90px] w-[90px] rounded-full border bg-white object-cover"
                    />
                    <label
                      htmlFor="profile-picture"
                      className="absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 px-2 text-center text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                    >
                      <span className="text-xs">Change</span>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">
                  <span className="text-sm text-primary">*</span> Your company
                  name
                </p>
                <InputField
                  className="mt-2"
                  placeholder=""
                  registration={{ ...register('companyName') }}
                  hasError={errors.companyName}
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">
                  <span className="text-sm text-primary">*</span> Company Email
                </p>
                <InputField
                  className="mt-2"
                  placeholder=""
                  registration={{ ...register('companyEmail') }}
                  hasError={undefined}
                  isDisabled
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">
                  <span className="text-sm text-primary">*</span> Company
                  Website
                </p>
                <InputField
                  className="mt-2"
                  placeholder=""
                  registration={{ ...register('companyWebsite') }}
                  hasError={undefined}
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">Company Phone</p>
                <InputFieldPhoneNo
                  className="mt-4"
                  control={control as unknown as Control}
                  defaultValue={companyPhone}
                  name="companyPhone"
                  hasError={errors.companyPhone}
                  errorMessage={errors.companyPhone?.message}
                  isRequired
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">
                  <span className="text-sm text-primary">*</span> About your
                  company
                </p>
                <TextAreaField
                  className="mt-2 text-sm"
                  id="companyAbout"
                  placeholder="Describe your role in the company"
                  registration={{ ...register('companyAbout') }}
                  hasError={errors.companyAbout}
                  rows={3}
                  limit={350}
                  value={companyAboutValue}
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">
                  <span className="text-sm text-primary">*</span> Your
                  organization&lsquo;s industry
                </p>
                <CustomSelectFieldIndustry
                  className="mt-2"
                  control={control as unknown as Control}
                  hasError={errors.industries}
                  name="industries"
                />
              </div>
              <div className="">
                <p className="mt-6 -mb-2 text-sm font-bold">
                  <span className="text-sm text-primary">*</span> Your company’s
                  location
                </p>
                <InputLocationAutoComplete
                  isError={!!errors.address}
                  address={address}
                  handleChange={handleAddressChange}
                  handleSelect={handleAddressChange}
                />
              </div>
              <div>
                <div className="">
                  <p className="mt-6 text-sm font-bold">
                    <span className="text-sm text-primary">*</span> Company Size
                  </p>
                  <SelectField
                    className="mt-2"
                    control={control as unknown as Control}
                    hasError={errors.employeeCount}
                    arr={NUMBER_OF_EMPLOYEES_OPTIONS}
                    placeholder="Select company size"
                    name="employeeCount"
                  />
                </div>

                <p className="mt-6 text-sm font-bold">Socials</p>

                <div className="mt-2 flex items-stretch gap-3">
                  <p className="flex h-12 w-12 min-w-[48px] items-center justify-center rounded-md border border-gray-150 text-lg font-bold text-primary">
                    <FaTwitter />
                  </p>
                  <div className="w-full">
                    <InputField
                      className="w-full"
                      placeholder=""
                      registration={{ ...register('twitterUrl') }}
                      hasError={undefined}
                    />
                  </div>
                </div>

                <div className="mt-4 flex items-stretch gap-3">
                  <p className="flex h-12 w-12 min-w-[48px] items-center justify-center rounded-md border border-gray-150 text-lg font-bold text-primary">
                    <FaFacebookF />
                  </p>
                  <div className="w-full">
                    <InputField
                      className=""
                      placeholder=""
                      registration={{ ...register('facebookUrl') }}
                      hasError={undefined}
                    />
                  </div>
                </div>

                <div className="mt-4 flex items-stretch gap-3">
                  <p className="flex h-12 w-12 min-w-[48px] items-center justify-center rounded-md border border-gray-150 text-xl font-bold text-primary">
                    <AiFillInstagram />
                  </p>
                  <div className="w-full">
                    <InputField
                      className=""
                      placeholder=""
                      registration={{ ...register('instagramUrl') }}
                      hasError={undefined}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <Button type="submit" isLoading={mutationEditProfile.isLoading}>
                  {mutationEditProfile.isLoading ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
