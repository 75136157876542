import { useForm } from 'react-hook-form'
import React from 'react'

import {
  DOCUMENT_ACCEPT,
  OnboardingResumeUploadForm,
} from '@/features/onboarding'
import { useFileUpload, useResumeUpload } from '@/api'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

export const useResume = () => {
  const [resumeUrl, setResumeUrl] = React.useState<string | null>(null)
  const { watch, setValue } = useForm<OnboardingResumeUploadForm>({
    defaultValues: {
      resume: null,
    },
  })
  const mutationFileUpload = useFileUpload()
  const mutationResumeUpload = useResumeUpload()

  const { acceptedFiles, isDragActive, getRootProps, getInputProps, open } =
    useDropzone({
      accept: DOCUMENT_ACCEPT,
      maxFiles: 1,
      onDropAccepted: (acceptedFiles: File[]) => {
        setValue('resume', acceptedFiles)
      },

      onDropRejected: (error) => {
        if (error.length > 1) {
          toast.error('Multiple files not allowed!')
          return
        }

        // Just one file
        const { code, message } = error[0].errors[0]

        if (code === 'file-invalid-type') {
          toast.error(message)
          return
        }

        if (code === 'file-too-large') {
          toast.error('File must be 100kb or less')
        }
      },
    })

  const resumes = watch('resume')

  React.useEffect(() => {
    if (resumes && resumes.length > 0) {
      const formData = new FormData()
      formData.append('file', resumes[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setResumeUrl(data.fileUrl)
        },
        onError: () => {
          setValue('resume', null)
        },
      })
    }
  }, [resumes])

  React.useEffect(() => {
    if (resumeUrl) {
      mutationResumeUpload.mutate(
        { resumeUrl },

        {
          onError: () => {
            setValue('resume', null)
          },
        }
      )
    }
  }, [resumeUrl])

  return { acceptedFiles, isDragActive, getRootProps, getInputProps, open }
}
