import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { OnboardingProfileDetailPayload } from '@/features/onboarding'
import { userIDStorage } from '@/features/auth'
import { fetchData, userKeys } from '@/utils'

export const useEditProfile = () => {
    const queryClient = useQueryClient()
    const userID = userIDStorage.getUserID()
    return useMutation(
        (payload: OnboardingProfileDetailPayload) => fetchData(
            `/user/profile/edit/${userID}`,
            'PUT',
            payload
        ),
        {
            onSuccess: () => {
                toast.success('Profile successfully edited')
               
                queryClient.invalidateQueries({
                    queryKey: userKeys.list(userID)
                })
            },
            onError: () => {
                toast.error('Profile could not be edited')
            }
        }
  )
}
