import { companyJobKeys, fetchData } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify";

export interface useReportJobPayload {
    reason: string;
    comment: string
}

export const useReportJob = (jobId: string) => {
    const queryClient = useQueryClient()

    return useMutation(
        (payload: useReportJobPayload) => fetchData<useReportJobPayload>(`/user/job/report/${jobId}`, "POST", payload),
        {
            onSuccess: () => {
                toast.success('Report sent successfully');
                queryClient.invalidateQueries({
                    queryKey: companyJobKeys.all
                });
            },
            onError: () => {
                toast.error('Report did not go through');
            }
        }
    )
}