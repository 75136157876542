import { toast } from 'react-toastify'
import React from 'react'
import clsx from 'clsx'

import { UserAvailabilityProp, useUserAvailability } from '../api'
import { JOB_SEARCH_STATUS_OPTIONS } from '@/utils'

interface ProfileStatus {
  icon: string
  text: string
  status: string
  color: string
  background: string
}

export const useChangeProfileStatus = (
  jobSearchStatus: string | null | undefined,
  isLoading: boolean
) => {
  const jobSearchStatusLowerCase = jobSearchStatus?.toString().toLowerCase()

  const isFirstJobSearchOption =
    jobSearchStatusLowerCase === JOB_SEARCH_STATUS_OPTIONS[0].id.toLowerCase()
  const isSecondJobSearchOption =
    jobSearchStatusLowerCase === JOB_SEARCH_STATUS_OPTIONS[1].id.toLowerCase()
  const isThirdJobSearchOption =
    jobSearchStatusLowerCase === JOB_SEARCH_STATUS_OPTIONS[2].id.toLowerCase()

  const [active, setActive] = React.useState<number|null>(0)
  const [isReady, setIsReady] = React.useState(false)

  const [currentProfileStatus, setCurrentProfileStatus] =
  React.useState<ProfileStatus>({
      icon: '',
      text: '',
      status: 'active',
      color: '',
      background: '',
    })

  React.useEffect(() => {
    if (!isLoading) {
      setCurrentProfileStatus({
        icon: clsx(
          isFirstJobSearchOption && '/assets/briefcase.png',
          isSecondJobSearchOption && '/assets/open.png',
          isThirdJobSearchOption && '/assets/lock.png'
        ),
        text: jobSearchStatus as string,
        status: 'active',
        color: clsx(
          isFirstJobSearchOption && '#14b43e',
          isSecondJobSearchOption && '#E2B93B',
          isThirdJobSearchOption && 'rgb(235, 87, 87)'
        ),
        background: clsx(
          isFirstJobSearchOption && 'rgb(20, 180, 62, 0.1)',
          isSecondJobSearchOption && 'rgb(226, 185, 59, 0.1)',
          isThirdJobSearchOption && 'rgb(235, 87, 87, 0.1)'
        ),
      })
      setIsReady(true);
      setActive(null)
    }
  }, [isLoading])

  const profileStatusOptions = JOB_SEARCH_STATUS_OPTIONS.map(
    ({ name }, index) => {
      let obj = {
        icon: '',
        text: name,
        status: 'active',
        color: '',
        background: '',
      }

      if (index === 0) {
        obj = {
          icon: '/assets/briefcase.png',
          text: name,
          status: 'active',
          color: '#14b43e',
          background: 'rgb(20, 180, 62, 0.1)',
        }
      }

      if (index === 1) {
        obj = {
          icon: '/assets/open.png',
          text: name,
          status: 'inactive',
          color: '#E2B93B',
          background: 'rgb(226, 185, 59, 0.1)',
        }
      }

      if (index === 2) {
        obj = {
          icon: '/assets/lock.png',
          text: name,
          status: 'inactive',
          color: 'rgb(235, 87, 87)',
          background: 'rgb(235, 87, 87, 0.1)',
        }
      }

      return obj
    }
  )

  const mutateUserAvailability = useUserAvailability()

  const handleProfileStatus = (info:ProfileStatus, index: number) => {

    setCurrentProfileStatus(info)
    setActive(index)


    const handleError = () => {
      toast.error('user availability not updated.');
      setCurrentProfileStatus(currentProfileStatus)
    }

    const payload:UserAvailabilityProp = {
      status: info.text.toLowerCase()
    }

    mutateUserAvailability.mutate(payload, {
      onError: handleError
    })
    // FIXME: MAKE A REQUEST TO UPDATE THE PROFILE STATUS

  }

  return {
    isReady,
    currentProfileStatus,
    setCurrentProfileStatus,
    profileStatusOptions,
    active,
    handleProfileStatus
  }
}
