import React from 'react'
import Image from 'next/image'
import clsx from 'clsx'

interface profileSetupStagesProps {
  icon: string
  profileStepName: string
  onClick?: () => void
  disabled?: boolean
}
export const ProfileStatus = ({
  icon,
  profileStepName,
  onClick,
  disabled = true,
}: profileSetupStagesProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'flex items-center space-x-2 rounded-md bg-[#2B2B2B] p-2 w-full mb-3 text-gray-300 md:w-4/12 lg:w-2/12 lg:space-x-4'
      )}
      disabled={disabled}
    >
      <Image src={icon} alt="icon" width={40} height={40} />
      <p className="text-xs lg:text-[13px]">{profileStepName}</p>
    </button>
  )
}
