import { useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { backdropVariant, editProfile } from '../../utils/animation'
import { BsXLg } from 'react-icons/bs'
import {
  editProfileDetailSchema,
  EditUserProfilePayload,
  EditUserProfileProps,
  LabelTitle,
} from '@/features/onboarding'
import {
  Checkbox,
  InputField,
  InputJobTitlesAutoComplete,
  InputLocationAutoComplete,
  SelectField,
} from '../Form'
import { Control, useForm } from 'react-hook-form'
import {
  useJobTypeOptions,
  useLevelOptions,
  useObjectURL,
  useQualificationOptions,
  useUserProfileInfo,
} from '@/hooks'
import { EMPTY_OPTION, EXPERIENCE_OPTIONS } from '@/utils'
import React from 'react'
import { useFileUpload } from '@/api'
import { useEditProfile } from '@/features/job-seeker'
import { Button } from '../Elements'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

interface EditProfileProps {
  photoUrl: string | undefined | null
  OpenEditProfileModal: boolean
  setOpenEditProfileModal: () => void
}

export const EditProfile: React.FC<EditProfileProps> = ({
  // photoUrl,
  OpenEditProfileModal,
  setOpenEditProfileModal,
}) => {
  const mutationEditProfile = useEditProfile()
  const { jobTypes } = useJobTypeOptions()
  const { qualificationOptions } = useQualificationOptions()
  const { levelOptions } = useLevelOptions()
  const [imgUrl, setImgUrl] = React.useState('')
  const mutationFileUpload = useFileUpload()
  const { userProfile, isLoading: isProfileLoading } = useUserProfileInfo()

  const ref = useRef<HTMLInputElement>(null)

  //handle form
  const {
    formState: { errors },
    reset,
    watch,
    control,
    register,
    setValue,
    handleSubmit,
  } = useForm<EditUserProfileProps>({
    defaultValues: {
      location: '',
      currentJobType: EMPTY_OPTION,
      currentJobTitle: EMPTY_OPTION,
      currentLevel: EMPTY_OPTION,
      qualification: EMPTY_OPTION,
      yearsOfExperience: EMPTY_OPTION,
      // dateOfBirth: "",
      isEmployed: false,
      currentOrganizationName: '',
      website: '',
      profilePictures: null,
      linkedin: '',
    },
    resolver: yupResolver(editProfileDetailSchema),
  })

  // reset Form
  React.useEffect(() => {
    if (isProfileLoading) return

    const { profile } = userProfile || {}

    if (!isProfileLoading && profile?.photoUrl) {
      setImgUrl(profile?.photoUrl)
    }
    const currentJobTypeOption =
      jobTypes.find(
        (jobType) =>
          jobType.id.toLowerCase() ===
          profile?.jobTypeId?.toString().toLowerCase()
      ) || EMPTY_OPTION

    const currentJobTitleOption = {
      name: profile?.jobTitle,
      id: profile?.jobTitleId,
    }

    const currentLevelOption =
      levelOptions.find(
        (levelOption) =>
          levelOption.id.toLowerCase() ===
          profile?.levelId?.toString().toLowerCase()
      ) || EMPTY_OPTION

    const qualificationOption =
      qualificationOptions.find(
        (qualificationOption) =>
          qualificationOption.id.toLowerCase() ===
          profile?.qualificationId?.toString().toLowerCase()
      ) || EMPTY_OPTION

    const yearsOfExperienceOption =
      EXPERIENCE_OPTIONS.find(
        (experienceOption) =>
          experienceOption.id.toLowerCase() ===
          profile?.yearsOfExperience?.toString().toLowerCase()
      ) || EMPTY_OPTION

    reset({
      location: profile?.location,
      currentJobType: currentJobTypeOption,
      currentJobTitle: currentJobTitleOption,
      currentLevel: currentLevelOption,
      qualification: qualificationOption,
      yearsOfExperience: yearsOfExperienceOption,
      // dateOfBirth: "",
      isEmployed: profile?.isEmployed,
      currentOrganizationName: profile?.currentCompany,
      website: profile?.website,
      profilePictures: null,
      linkedin: profile?.socials?.url,
    })
  }, [isProfileLoading, userProfile])

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        OpenEditProfileModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenEditProfileModal()
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [OpenEditProfileModal])

  const isEmployed = watch('isEmployed')
  const location = watch('location')
  const profilePictures = watch('profilePictures')

  const profilePicture = useObjectURL(profilePictures && profilePictures[0])

  React.useEffect(() => {
    if (profilePictures && profilePictures.length > 0) {
      const formData = new FormData()
      formData.append('file', profilePictures[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setImgUrl(data.fileUrl)
        },
        onError: () => {
          setValue('profilePictures', null)
        },
      })
    }
  }, [profilePictures])

  const handleSelect = (location: string) => {
    setValue('location', location)
  }

  const onSubmit = (values: EditUserProfileProps) => {
    if (imgUrl === '') {
      return toast.error('Please upload your profile picture')
    }
    const {
      location,
      yearsOfExperience,
      // dateOfBirth,
      currentOrganizationName,
      website,
      currentJobTitle,
      qualification,
      currentLevel,
      isEmployed,
      currentJobType,
      linkedin,
    } = values

    const socials = {
      name: 'linkedin',
      url: linkedin,
    }

    const payload: EditUserProfilePayload = {
      website,
      currentCompany: isEmployed === false ? '' : currentOrganizationName,
      isEmployed,
      location,
      yearsOfExperience: yearsOfExperience.id,
      titleId: isEmployed === false ? '' : currentJobTitle.id,
      typeId: isEmployed === false ? '' : currentJobType.id,
      photoUrl: imgUrl,
      socials,
      qualificationId: qualification.id,
      levelId: currentLevel.id,
      gender: '',
      date_of_birth: '',
    }

    const handleSuccess = () => {
      setOpenEditProfileModal()
      reset()
    }

    mutationEditProfile.mutate(payload, {
      onSuccess: handleSuccess,
    })
  }

  return (
    <AnimatePresence>
      {OpenEditProfileModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide flexibleHeight absolute top-[50%] left-[50%] w-full  overflow-scroll rounded-lg bg-white  px-12 py-8 sm:w-10/12 lg:w-[1204px]"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={editProfile}
          >
            <div>
              <div>
                <p className="text-base font-bold">Edit Profile</p>
                <button
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={setOpenEditProfileModal}
                >
                  <BsXLg />
                </button>
              </div>
              <p className="mb-4 text-sm">
                The stronger your profile, the higher your chances for a
                potential hire
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-16 lg:flex">
                <div className="flex-1 space-y-10 lg:border-r lg:pr-10">
                  <div>
                    <LabelTitle
                      text="Your profile image"
                      isRequired
                      id="profile-picture"
                    />
                    {/* {isFreshUpload ? (
                      <label className="cursor-pointer" htmlFor="profile-picture">
                        <p className="mt-3 text-xs">
                          <span className="text-[#2F80ED]">Browse</span> change your
                          profile picture
                        </p>

                        <div className="mt-3 inline-flex h-[90px] w-[90px] items-center justify-center rounded-full border border-dotted border-primary bg-primary/10">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.9991 13.8991V16.1891C21.9991 19.8291 19.8291 21.9991 16.1891 21.9991H7.80908C5.25908 21.9991 3.41908 20.9291 2.55908 19.0291L2.66908 18.9491L7.58908 15.6491C8.38908 15.1091 9.51908 15.1691 10.2291 15.7891L10.5691 16.0691C11.3491 16.7391 12.6091 16.7391 13.3891 16.0691L17.5491 12.4991C18.3291 11.8291 19.5891 11.8291 20.3691 12.4991L21.9991 13.8991Z"
                              fill="#27AE60"
                            />
                            <path
                              opacity="0.4"
                              d="M20.97 8H18.03C16.76 8 16 7.24 16 5.97V3.03C16 2.63 16.08 2.29 16.22 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03L2.67 18.95L7.59 15.65C8.39 15.11 9.52 15.17 10.23 15.79L10.57 16.07C11.35 16.74 12.61 16.74 13.39 16.07L17.55 12.5C18.33 11.83 19.59 11.83 20.37 12.5L22 13.9V7.78C21.71 7.92 21.37 8 20.97 8Z"
                              fill="#27AE60"
                            />
                            <path
                              d="M9.00109 10.381C9.63231 10.381 10.2377 10.1303 10.684 9.68391C11.1303 9.23758 11.3811 8.63222 11.3811 8.001C11.3811 7.36979 11.1303 6.76442 10.684 6.31809C10.2377 5.87175 9.63231 5.621 9.00109 5.621C8.36988 5.621 7.76452 5.87175 7.31818 6.31809C6.87184 6.76442 6.62109 7.36979 6.62109 8.001C6.62109 8.63222 6.87184 9.23758 7.31818 9.68391C7.76452 10.1303 8.36988 10.381 9.00109 10.381ZM20.9701 1H18.0301C16.7601 1 16.0001 1.76 16.0001 3.03V5.97C16.0001 7.24 16.7601 8 18.0301 8H20.9701C22.2401 8 23.0001 7.24 23.0001 5.97V3.03C23.0001 1.76 22.2401 1 20.9701 1ZM21.9101 4.93C21.8101 5.03 21.6601 5.1 21.5001 5.11H20.0901L20.1001 6.5C20.0901 6.67 20.0301 6.81 19.9101 6.93C19.8101 7.03 19.6601 7.1 19.5001 7.1C19.1701 7.1 18.9001 6.83 18.9001 6.5V5.1L17.5001 5.11C17.3401 5.10738 17.1875 5.04195 17.0752 4.92784C16.963 4.81372 16.9001 4.66006 16.9001 4.5C16.9001 4.17 17.1701 3.9 17.5001 3.9L18.9001 3.91V2.51C18.9001 2.18 19.1701 1.9 19.5001 1.9C19.8301 1.9 20.1001 2.18 20.1001 2.51L20.0901 3.9H21.5001C21.8301 3.9 22.1001 4.17 22.1001 4.5C22.0899 4.66136 22.0225 4.81381 21.9101 4.93Z"
                              fill="#27AE60"
                            />
                          </svg>
                        </div>
                      </label>
                    ) : ( */}
                    <div className="group relative mt-3 h-[90px] w-[90px] rounded-full">
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={profilePicture || imgUrl}
                        alt="profile image"
                        className="mx-auto mb-4 h-[90px] w-[90px] rounded-full border bg-white object-cover"
                      />
                      <label
                        htmlFor="profile-picture"
                        className="absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 px-2 text-center text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                      >
                        <span className="text-xs">Change</span>
                      </label>
                    </div>
                    {/* )} */}
                    <input
                      className="sr-only"
                      type="file"
                      id="profile-picture"
                      accept="image/png, image/jpeg"
                      {...register('profilePictures')}
                    />
                  </div>

                  <div>
                    <LabelTitle text="Where are you based?" isRequired id="" />
                    <InputLocationAutoComplete
                      address={location}
                      handleChange={handleSelect}
                      handleSelect={handleSelect}
                      isError={!!errors.location}
                    />
                  </div>

                  <div>
                    <LabelTitle
                      text="How many years of experience do you have?"
                      isRequired
                      id="yearsOfExperience"
                    />
                    <SelectField
                      className="mt-4"
                      control={control as unknown as Control}
                      hasError={errors.yearsOfExperience}
                      arr={EXPERIENCE_OPTIONS}
                      name="yearsOfExperience"
                    />
                  </div>

                  <div>
                    <LabelTitle text="Your website" id="website" />
                    <InputField
                      className="mt-4"
                      registration={{ ...register('website') }}
                      placeholder="eg. https://scout4job.com"
                      errorMessage={errors.website?.message}
                      hasError={errors.website}
                    />
                  </div>

                  <div>
                    <LabelTitle text="Your Linkedin" id="linkedin" />
                    <InputField
                      className="mt-4"
                      hasError={undefined}
                      registration={{ ...register('linkedin') }}
                      placeholder="eg. https://linkedin.com/in/scout4job"
                    />
                  </div>
                </div>

                <div className="flex-1 space-y-10 lg:pl-10">
                  <div className="mt-4 lg:mt-1">
                    <LabelTitle
                      text="What is your current level?"
                      isRequired
                      id="currentLevel"
                    />
                    <SelectField
                      className="mt-4"
                      control={control as unknown as Control}
                      hasError={errors.currentLevel}
                      arr={levelOptions}
                      name="currentLevel"
                    />
                  </div>

                  <div>
                    <LabelTitle
                      text="What is your qualification?"
                      isRequired
                      id="qualification"
                    />
                    <SelectField
                      className="mt-4"
                      control={control as unknown as Control}
                      hasError={errors.qualification}
                      arr={qualificationOptions}
                      name="qualification"
                    />
                  </div>

                  <Checkbox
                    checked={isEmployed}
                    onChange={(e) => setValue('isEmployed', e.target.checked)}
                    id="isEmployed"
                  >
                    I&lsquo;m currently employed
                  </Checkbox>

                  <div>
                    <LabelTitle
                      text="Where do you work currently"
                      id="currentOrganizationName"
                      isRequired
                    />

                    <InputField
                      className="mt-4"
                      placeholder="eg. Scout4Job"
                      registration={{ ...register('currentOrganizationName') }}
                      hasError={errors.currentOrganizationName}
                      isDisabled={!isEmployed}
                    />
                  </div>

                  <div>
                    <LabelTitle
                      text="What is your current job title"
                      id="currentJobTitle"
                      isRequired
                    />
                    <InputJobTitlesAutoComplete
                      className="mt-4"
                      control={control as unknown as Control}
                      hasError={errors.currentJobTitle}
                      placeholder="Eg. Frontend developer"
                      isDisabled={!isEmployed}
                      name="currentJobTitle"
                    />
                  </div>

                  <div>
                    <LabelTitle
                      text="What is your current job type?"
                      isRequired
                      id="jobType"
                    />
                    <SelectField
                      className="mt-4"
                      control={control as unknown as Control}
                      hasError={errors.currentJobType}
                      isDisabled={!isEmployed}
                      arr={jobTypes}
                      name="currentJobType"
                    />
                  </div>

                  <div className="mt-auto"></div>
                </div>
              </div>
              <div className="mx-auto mb-28 mt-10 flex max-w-sm justify-center sm:mb-2">
                <Button
                  disabled={mutationEditProfile.isLoading}
                  isLoading={mutationEditProfile.isLoading}
                  className="w-full"
                  type="submit"
                >
                  {mutationEditProfile.isLoading
                    ? 'Editing profile...'
                    : 'Edit profile'}
                </Button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
