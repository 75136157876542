import { allJobsForApprovalKeys, fetchData, interviewInfoKeys, interviewKeys, jobApplicantKeys, transformError } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface DiscardApplicantsType {
    jobId: string | undefined | string[] 
    userInfo:  {userId: string, interviewId: string}[]
}
export const useSDiscardApplicants = () => {
    const queryClient = useQueryClient();
    
    return useMutation<Response, AxiosError, DiscardApplicantsType>(
        (payload) => fetchData(`/company/applicants/discard-applicant`, 'POST', payload),
        {
            onSuccess: () => {
                toast.success('List discarded')
                queryClient.invalidateQueries({
                    queryKey: allJobsForApprovalKeys.all
                });
                queryClient.invalidateQueries({
                    queryKey: jobApplicantKeys.all
                });
                queryClient.invalidateQueries({
                    queryKey: interviewInfoKeys.all
                });
                queryClient.invalidateQueries({
                    queryKey: interviewKeys.all
                });
                
            },
            onError: (error) => {
                toast.error(transformError(error))
            },
        }
    )
}
