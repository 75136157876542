import { useUserProfile } from "@/features/auth"


export const useUserProfileInfo = () => {
  const { data: userProfile, isLoading } = useUserProfile()
  return {
    isLoading,
    userProfile,
  }
}

