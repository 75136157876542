import dayjs from 'dayjs'
import React from 'react'

import { InterviewSlot } from '../types'

interface SlotProps {
  slot: InterviewSlot
}

export const Slot: React.FC<SlotProps> = ({ slot }) => {
  const month = dayjs(slot.interviewDate).format('MMM')
  const date = dayjs(slot.interviewDate).format('DD')
  const day = dayjs(slot.interviewDate).format('ddd')
  const startTime = dayjs(slot.interviewStartTime).format('h:mm a')
  const duration = slot.interviewDuration.name

  if (
    slot.interviewDate &&
    slot.interviewStartTime &&
    slot.interviewDuration.id
  ) {
    return (
      <div className="space-y-0.5 text-center text-xs" key={slot.id}>
        <p className="rounded-t bg-[#e5e5e5] px-5 py-2">{month}</p>
        <p className="bg-[#e5e5e5] px-5 py-2 text-xl font-bold">{date}</p>
        <p className="bg-[#e5e5e5] px-5 py-2">{day}</p>
        <p className="rounded-b bg-[#e5e5e5] px-5 py-2">
          {startTime} • {duration}
        </p>
      </div>
    )
  }
  return null
}
