import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ToastContainer } from 'react-toastify'
import { Hydrate } from '@tanstack/react-query'
import { AnimatePresence } from 'framer-motion'
import 'react-toastify/dist/ReactToastify.css'
import { Work_Sans } from '@next/font/google'
import 'react-phone-number-input/style.css'
import TagManager from 'react-gtm-module'
import { Outfit } from '@next/font/google'
import { useRouter } from 'next/router'
import { AppProps } from 'next/app'
import NProgress from 'nprogress'
import React from 'react'

const workSans = Work_Sans({
  subsets: ['latin'],
})
const outfit = Outfit({
  subsets: ['latin'],
})

import {
  EmptyLayout,
  JobSeekerDashboardLayout,
  RecruiterDashboardLayout,
  WebsiteLayout,
} from '@/components'
import { AuthProvider } from '@/contexts/Authentication'
import { ProtectedRouteGuard } from '@/features/auth'
import ReactQueryProvider from '@/lib/react-query'
import '@/styles/globals.css'
import Script from 'next/script'


export default function App({ Component, pageProps, ...appProps }: AppProps) {



  const router = useRouter()

  const websiteRoutes = [
    '/',
    '/jobs',
    '/recruiter',
    '/privacy-policy',
    '/training-board',
    '/training',
    '/training/get-in-touch',
    '/resources',
    '/resources/articles',
    '/resources/discover-companies',
    '/resources/interview-questions',
    '/resources/salary-calculator',
    '/resources/articles/[id]',
    '/resources/cv-templates'
  ]

  const recruiterRoutes = [
    '/recruiter/dashboard',
    '/recruiter/settings',
    '/recruiter/dashboard/jobs',
    '/recruiter/dashboard/interview',
    '/recruiter/dashboard/talent-pool',
    '/recruiter/dashboard/all-applicants',
    '/recruiter/dashboard/jobs/[id]',
    '/recruiter/dashboard/jobs/applicants/[id]',
    '/recruiter/dashboard/company',
    '/recruiter/dashboard/post-job',
    '/recruiter/dashboard/approval',
    '/recruiter/dashboard/approval/applicants/[id]',
  ]

  const jobSeekerRoutes = [
    '/job-seeker/dashboard',
    '/job-seeker/dashboard/jobs',
    '/job-seeker/dashboard/applied',
    '/job-seeker/dashboard/saved-jobs',
    '/job-seeker/dashboard/profile',
    '/job-seeker/dashboard/settings',
  ]

  const publicRoutes = [
    ...websiteRoutes,
    '/login',
    'sign-up',
    '/jobs/[id]',
    '/resources',
    '/resources/articles',
    '/resources/discover-companies',
    '/resources/interview-questions',
    '/resources/salary-calculator',
    '/resources/articles/[id]',
    '/resources/cv-templates'
  ]

  const protectedRoutes = [
    ...recruiterRoutes,
    ...jobSeekerRoutes,
    '/recruiter/dashboard/interview/schedule/[id]',
  ]

  const hasWebsiteLayout = websiteRoutes.some(
    (route) => route === appProps.router.route
  )
  const hasJobSeekerLayout = jobSeekerRoutes.some(
    (route) => route === appProps.router.route
  )
  const hasRecruiterLayout = recruiterRoutes.some(
    (route) => route === appProps.router.route
  )

  const getLayoutComponent = () => {
    if (hasWebsiteLayout) {
      return WebsiteLayout
    } else if (hasJobSeekerLayout) {
      return JobSeekerDashboardLayout
    } else if (hasRecruiterLayout) {
      return RecruiterDashboardLayout
    }
    return EmptyLayout
  }

  const LayoutComponent = getLayoutComponent()

  const isPublicRoute = publicRoutes.some(
    (route) => route === appProps.router.route
  )

  React.useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER as string,
    })
  }, [])

  React.useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start()
    })

    router.events.on('routeChangeComplete', () => {
      NProgress.done()
    })

    router.events.on('routeChangeError', () => {
      NProgress.done()
    })

    return () => {
      NProgress.done()
    }
  }, [router.events])

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload" id="google-tag">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
        page_path: window.location.pathname,
        });
    `}
      </Script>
     

      <ReactQueryProvider>
        <Hydrate state={pageProps.dehydratedState}>
          <ToastContainer position="top-right" />
          <div>
            <AuthProvider>
              <ProtectedRouteGuard
                protectedRoutes={protectedRoutes}
                isPublicRoute={isPublicRoute}
              >
                <AnimatePresence
                  mode="wait"
                  initial={false}
                  onExitComplete={() => window.scrollTo(0, 0)}
                >
                  <div>
                    <LayoutComponent>
                      <Component {...pageProps} key={router.asPath} />
                    </LayoutComponent>
                  </div>
                </AnimatePresence>
              </ProtectedRouteGuard>
            </AuthProvider>

            <style jsx global>
              {`
                :root {
                  --workSans-font: ${workSans.style.fontFamily};
                  --outfit-font: ${outfit.style.fontFamily};
                }
              `}
            </style>
          </div>
        </Hydrate>
      </ReactQueryProvider>
    </>
  )
}
