import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { OnboardingProfileDetailPayload } from '../types'
import { userIDStorage } from '@/features/auth'
import { fetchData, transformError } from '@/utils'
import { AxiosError } from 'axios'

export const useOnboardingCreateProfileDetail = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  const userID = userIDStorage.getUserID()
  if (!userID) router.push('/login')

  return useMutation(
    (payload: OnboardingProfileDetailPayload) =>
      fetchData(`/auth/onboarding/profile/${userID}`, 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['user-details'],
        })
        toast.success('Profile created.')
        router.push('/job-seeker/onboarding/2')
      },
      onError: (error: AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
