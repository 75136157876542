import { useQuery } from '@tanstack/react-query';


import { fetchData } from '@/utils';
import { Option } from '@/types';

interface LevelResponse {
  status: string;
  message: string;
  data: Option[];
}

export const useLevels = () => {
  return useQuery<LevelResponse>({
    queryKey: ['levels'],
    queryFn: async () => fetchData('/common/job-levels'),
  });
};