import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {  fetchData, transformError } from '@/utils';
import { AxiosError } from 'axios';


export const useClickAdvert = (advertId: string) => {
//   const queryClient = useQueryClient();
  return useMutation<Response, AxiosError>(
    () =>
      fetchData(`/common/click-advert/${advertId}`, 'PUT'),
    {
      onSuccess: () => {
        // toast.success('ad clicked');
        ''
      },
      onError: (error) => {
        toast.error(transformError(error));
      },
    }
  );
};