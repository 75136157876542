import React from 'react';
import { Chart, ArcElement, ChartOptions, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { formatNumber } from '@/hooks';


// Register the ArcElement

interface ChartData {
  labels: string[];
  datasets: {
    data: number[] | undefined[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

interface CalulatorChartProp  {
  data: ChartData
  selectedSymbol: string
}

export const CalculatorChart = ({data, selectedSymbol}:CalulatorChartProp ) => {
  Chart.register(ArcElement, Tooltip);

  const options: ChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Chart Title'
    },
      tooltip: {
        callbacks: {
          label: (context) => {
            // const label = data.labels[context.dataIndex] || '';
            const value = (selectedSymbol + formatNumber(data.datasets[0].data[context.dataIndex])) || 0;
            return `${value}`;
          },
        },
      },
    },
  };
  
  return (
    <div>
      <Doughnut data={data} options={options}/>
    </div>
  );
};