import dayjs from 'dayjs'
import React from 'react'
import clsx from 'clsx'

import { InterviewOptionName, colorSet } from './NewInterviewComponent'
import { NewInterview, useJob } from '@/features/job-seeker'

interface InterviewCardProps {
  index: number
  interview: NewInterview
}

export const InterviewCard: React.FC<InterviewCardProps> = ({
  index,
  interview,
}) => {
  const {
    interviewLink,
    jobId,
    interviewers,
    interviewType,
    interviewRound,
    interviewOption,
    interviewSchedule,
    interviews: applicantsData,
  } = interview

  const jobData = useJob(jobId)
  const jobTitle = jobData?.data?.data?.title || ''

  const interviewDateRange = interviewSchedule.map(
    (scheduleData) => scheduleData.interviewDate
  )
  const sortedDate = interviewDateRange.sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  )
  const formattedStartDate = dayjs(sortedDate[0]).format('MMM DD, YYYY')
  const formattedEndDate = dayjs(sortedDate[sortedDate.length - 1]).format(
    'MMM DD, YYYY'
  )

  

  return (
    <div key={index} className="border-b border-[#EFEFEF]">
      <div
        className={clsx(
          'group grid grid-cols-4 px-6 sm:px0 items-start justify-start gap-6 gap-y-5 py-8 shadow-md hover:bg-gray-100 sm:gap-0 sm:px-0 sm:shadow-none sm:hover:bg-transparent'
        )}
      >
        <div className="flex flex-col pr-6">
          <p className='font-bold -mt-1'>{jobTitle}</p>
          <p className="font-medium truncate whitespace-nowrap text-sm">
            {InterviewOptionName(interviewOption)}
          </p>
        <p className="text-xs">{interviewRound}</p>
        </div>

        <div>
          <p className="text-xs">{interviewType}</p>
          <p className="text-xs">
            {formattedStartDate} - {formattedEndDate}
          </p>
          {!(interviewLink === '' || interviewLink === null) && (
            <button className="text-xs text-[#2F80ED] underline">
              Interview Link
            </button>
          )}
        </div>
        <div className="flex items-center justify-start">
          {applicantsData &&
            applicantsData.map((applicantData, index) => {
              return (
                <p
                  key={index}
                  className="flex items-center"
                >
                  {applicantData.photoUrl ? (
                    <img
                      src={applicantData.photoUrl}
                      alt="applicants"
                      className={clsx(
                        'h-[45px] w-[45px] min-w-[45px] rounded-full border-2 border-white object-cover transition hover:scale-125',
                        index > 3 ? 'hidden' : 'block',
                        index > 0 && '-ml-2'
                      )}
                    />
                  ) : (
                    <p
                    style={{
                      backgroundColor:
                        colorSet[(index + 1 - 1) % colorSet.length],
                    }}
                      className={clsx(
                        'flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full border-2 border-white object-cover hover:scale-105',
                        index > 3 ? 'hidden' : 'block',
                        index > 0 && '-ml-2'
                      )}
                    >
                      {applicantData.firstName[0]}
                    </p>
                  )}
                </p>
              )
            })}
          {applicantsData.length > 4 && (
            <button className="-ml-2 flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full border-2 border-white bg-black text-sm font-semibold text-white">
              +{applicantsData.length - 4}
            </button>
          )}
        </div>

        <div className="flex items-center">
          {interviewers &&
            interviewers.map((interviewer, index) => {
              const startIndex = interviewer.name.indexOf('(')
              const endIndex = interviewer.name.indexOf(')')
              const name = interviewer.name.slice(0, startIndex).trim()
              const email = interviewer.name.slice(startIndex + 1, endIndex)

              // Create the object with name and email properties
              const result = {
                name,
                email,
              }

              return (
                <div key={index} className="flex items-center">
                  {interviewer && (
                    <div className="">
                      <p
                        className={clsx(
                          'interviewer_avatar flex h-[45px] w-[45px] min-w-[45px] items-center capitalize justify-center rounded-full border-2 border-white text-sm font-semibold text-black',
                          index > 3 ? 'hidden' : 'block',
                          index > 0 && '-ml-2'
                        )}
                        style={{
                          backgroundColor:
                            colorSet[(index + 1 - 1) % colorSet.length],
                        }}
                      >
                        {interviewer?.name[0]}
                      </p>

                      {/* interviewer moreInfo */}

                      <div className="interviewer_info absolute ml-[-30px] mt-3 flex items-start gap-3 rounded-md bg-black text-xs text-white">
                        <span className="arrow-icon"></span>
                        <p
                          className={clsx(
                            'interviewer_avatar flex h-[35px] capitalize w-[35px] min-w-[35px] items-center justify-center rounded-full text-sm font-semibold text-black'
                          )}
                          style={{
                            backgroundColor:
                              colorSet[(index + 1 - 1) % colorSet.length],
                          }}
                        >
                          {interviewer?.name[0]}
                        </p>
                        <div>
                          <p className="font-bold">{result.name}</p>
                          <p className="mt-1">{result.email}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          {interviewers.length > 4 && (
            <button className="-ml-2 flex h-[45px] w-[45px] min-w-[45px] items-center justify-start rounded-full border-2 border-white bg-black text-sm font-semibold text-white">
              +{interviewers.length - 4}
            </button>
          )}
        </div>
      </div>
      <style>
        {`
          .interviewer_info{
            width:0;
            height:0;
          }
          .interviewer_avatar:hover + .interviewer_info {
            height: 60px;
            width: 220px;
            opacity: 1;
            transition: height 0.3s ease, width 0.3s ease, opacity 0.5s ease;
            padding: 10px 12px;
          }
          .interviewer_info {
            height: 0;
            width: 0;
            opacity: 0;
            overflow: hidden;
            transition: opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
          }
          .interviewer_avatar:hover + .interviewer_info p{
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.8s ease, transform 0.6s ease;
          }
          
          .interviewer_info p{
            opacity: 0;
            transform: translateY(20px);
            transition: opacity 0.3s ease, transform 0.3s ease;
          }

          .arrow-icon {
            width: 10px;
            height: 10px;
           margin-left: 20px;
            margin-top: -15px;
            background-color: black;
            z-index: 1000;
            clip-path: polygon(100% 50%, 0 0, 0 100%);
            position: absolute;
            transform: rotate(30deg);
          }
          interviewer_avatar:hover + .arrow-icon {
            display:block;
          }

        `}
      </style>
    </div>
  )
}
