import { useQuery, useQueryClient } from '@tanstack/react-query'

import { fetchData, savedJobsStatus } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { jobSavedSatatus } from '../types'

interface SavedJobJobResponse {
  status: string
  message: string
  data: jobSavedSatatus
}

export const useCheckSavedJobsStatus = (jobId:string) => {
  const queryClient = useQueryClient()
  const userId = userIDStorage.getUserID()
  return useQuery<SavedJobJobResponse>({
    queryKey: savedJobsStatus.detail(jobId),
    queryFn: async () => fetchData(`/user/job-saved-status/${userId}/${jobId}`),
    onSuccess: (data) => {
     
      const isSaved = data.data.isSaved;
      isSaved
      queryClient.invalidateQueries(savedJobsStatus.detail(jobId))}
  })
}