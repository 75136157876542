import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { companyJobKeys, fetchData, transformError } from '@/utils'
// import { companyIDStorage } from '@/features/auth'
import { PostAJobFormPayload } from '../types'
import { useCompanyId } from '../hooks'
import { AxiosError } from 'axios'

export const usePostAJob = () => {
  const queryClient = useQueryClient();
  const router = useRouter()
  const companyID =  useCompanyId()
  return useMutation(
    (payload: PostAJobFormPayload) =>
      fetchData(`/job/post-job/${companyID}`, 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: companyJobKeys.all,
        });
        toast.success('Job posted successfully')
        router.push('/recruiter/dashboard/jobs')
      },
      onError: (error:AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
