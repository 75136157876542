import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { OnboardingJobPreferencePayload } from '../types'
import { userIDStorage } from '@/features/auth'
import { fetchData } from '@/utils'

export const useCreateJobPreference = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  const userID = userIDStorage.getUserID()
  if (!userID) router.push('/login')

  return useMutation(
    (payload: OnboardingJobPreferencePayload) =>
      fetchData(
        `/auth/onboarding/job-search-preferences/${userID}`,
        'POST',
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['user-details'],
        })
        toast.success('Job preference created.')
        router.push('/job-seeker/onboarding/3')
      },
      onError: () => {
        toast.error('Job preference not created.')
      },
    }
  )
}
