import { AnimatePresence, motion } from 'framer-motion'
import React, { Dispatch, SetStateAction } from 'react'
import { BsXLg } from 'react-icons/bs'
import Image from 'next/image'
import clsx from 'clsx'

import {
  ViewIntervieweeModalAnim,
  backdropVariant,
  filterMember,
} from '@/utils'
import { IntervieweeData } from '@/features/job-seeker'
import { useFilterBySearch } from '@/hooks'
import { InterviewDetails } from './InterviewDetails'
import { colorSet } from '../NewInterviewComponent'

interface ViewIntervieweeModalProps {
  openViewIntervieweeModal: boolean
  setOpenViewIntervieweeModal: Dispatch<SetStateAction<boolean>>
  applicantsData: IntervieweeData[]
  jobTitle: string
  jobId: string | string[]
}

export const ViewIntervieweeModal: React.FC<ViewIntervieweeModalProps> = ({
  // photoUrl,
  applicantsData,
  openViewIntervieweeModal,
  jobTitle,
  jobId,
  setOpenViewIntervieweeModal,
}) => {
  const { searchTerm, handleSearchChange } = useFilterBySearch()
  const [applicantId, setApplicantId] = React.useState('')
  const filteredInterviewee = applicantsData?.filter((interviewee) =>
    filterMember(interviewee, searchTerm)
  )
  const [showInterviewDetails, setShowInterviewDetails] = React.useState(false)
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openViewIntervieweeModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenViewIntervieweeModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openViewIntervieweeModal])

  const handleApplicantClick = (userId: string) => {
    setApplicantId(userId)
    setShowInterviewDetails(true)
  }
  return (
    <>
      <AnimatePresence>
        {openViewIntervieweeModal && (
          <motion.div
            className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
            initial="initial"
            animate="show"
            exit="exit"
            variants={backdropVariant}
          >
            <motion.div
              className="scrollbar-hide flexibleHeight absolute top-[50%] left-[50%] w-[669px] overflow-scroll rounded-lg bg-white px-7 py-8"
              ref={ref}
              initial="initial"
              animate="show"
              exit="exit"
              variants={ViewIntervieweeModalAnim}
            >
              <div>
                <div>
                  <p className="text-base font-bold"></p>
                  <button
                    className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                    onClick={() => setOpenViewIntervieweeModal(false)}
                  >
                    <BsXLg />
                  </button>
                  <div>
                    <p className="text-base font-bold">Job Interviewees</p>
                  </div>
                </div>
                <div className="mt-5 flex items-center justify-between rounded-md">
                  <p>
                    Interview for{' '}
                    <span className="font-semibold">
                      &quot;{jobTitle}&quot;
                    </span>
                  </p>
                  <p className="w-fit bg-[#191919] bg-opacity-10 px-2 py-1 text-sm">
                    {applicantsData.length} Interviewees
                  </p>
                </div>
              </div>
              {
                <div className="mt-4 flex h-[45px] w-full flex-row-reverse items-center gap-2 rounded-3xl border border-[#EFEFEF] bg-transparent  px-4">
                  <input
                    type="text"
                    className="w-full bg-transparent font-WorkSans text-sm outline-none"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <Image
                    src="/assets/globalsearch.png"
                    width={20}
                    height={20}
                    alt="setUpProfile"
                  />
                </div>
              }

              {filteredInterviewee.map((data, index) => {
                return (
                  <button
                    className="mt-5 w-full border-b border-b-[#E5E5E5] py-4"
                    key={index}
                    onClick={() => {
                      handleApplicantClick(data?.userId)}}
                  >
                    <div className="flex items-center gap-4">
                      {data.photoUrl ? (
                        <img
                          src={data.photoUrl}
                          alt="applicants"
                          className={clsx(
                            'h-[45px] w-[45px] min-w-[45px] rounded-full object-cover border-2 border-white',
                            index > 3 ? 'hidden' : 'block',
                            index > 0 && '-ml-2'
                          )}
                        />
                      ) : (
                        <p
                          className={clsx(
                            'flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full border-2 border-white',
                            index > 3 ? 'hidden' : 'block',
                            index > 0 && '-ml-2'
                          )}
                          style={{
                            backgroundColor:
                              colorSet[(index + 1 - 1) % colorSet.length],
                          }}
                        >
                          {data.firstName[0]}
                        </p>
                      )}
                      <div className="flex flex-col items-start">
                        <p className="text-sm font-bold">
                          {data.firstName || ''} {data.lastName || ''}
                        </p>
                        <p className="text-sm">{data.email}</p>
                      </div>
                    </div>
                  </button>
                )
              })}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <InterviewDetails
        getInterviewId=''
        data={applicantsData}
        jobTitle={jobTitle}
        showInterviewDetails={showInterviewDetails}
        setShowInterviewDetails={setShowInterviewDetails}
        userId={applicantId || ''}
        jobId={jobId || ''}
      />
    </>
  )
}
