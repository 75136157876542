export const userKeys = {
  all: ['users'] as const,
  lists: () => [...userKeys.all, 'list'] as const,
  list: (id: string) => [...userKeys.all, 'list', id] as const,
  details: () => [...userKeys.all, 'detail'] as const,
  detail: (id: string) => [...userKeys.details(), id] as const,
}

export const profileKeys = {
  all: ['usersProfile'] as const,
  lists: () => [...profileKeys.all, 'list'] as const,
  list: (id: string) => [...profileKeys.all, 'list', id] as const,
  details: () => [...profileKeys.all, 'detail'] as const,
  detail: (id: string) => [...profileKeys.details(), id] as const,
}
export const userEducation = {
  all: ['userEducation'] as const,
  lists: () => [...userEducation.all, 'list'] as const,
  list: (id: string) => [...userEducation.all, 'list', id] as const,
  details: () => [...userEducation.all, 'detail'] as const,
  detail: (id: string) => [...userEducation.details(), id] as const,
}

export const invitedMembersKeys = {
  all: ['invitedMembers'] as const,
  lists: () => [...invitedMembersKeys.all, 'list'] as const,
  list: (id: string) => [...invitedMembersKeys.all, 'list', id] as const,
  details: () => [...invitedMembersKeys.all, 'detail'] as const,
  detail: (id: string) => [...invitedMembersKeys.details(), id] as const,
}
export const companyKeys = {
  all: ['companies'] as const,
  lists: () => [...companyKeys.all, 'list'] as const,
  list: (id: string) => [...companyKeys.all, 'list', id] as const,
  details: () => [...companyKeys.all, 'detail'] as const,
  detail: (id: string) => [...companyKeys.details(), id] as const,
}
export const imageKeys = {
  all: ['image'] as const,
  lists: () => [...imageKeys.all, 'list'] as const,
  list: (id: string) => [...imageKeys.all, 'list', id] as const,
  details: () => [...imageKeys.all, 'detail'] as const,
  detail: (id: string) => [...imageKeys.details(), id] as const,
}
export const companyJobKeys = {
  all: ['company-jobs'] as const,
  lists: () => [...companyJobKeys.all, 'list'] as const,
  list: (id: string) => [...companyJobKeys.all, 'list', id] as const,
  details: () => [...companyJobKeys.all, 'detail'] as const,
  detail: (id: string) => [...companyJobKeys.details(), id] as const,
}

export const companyPerkKeys = {
  all: ['company-perks'] as const,
  lists: () => [...companyPerkKeys.all, 'list'] as const,
  list: (id: string) => [...companyPerkKeys.all, 'list', id] as const,
  details: () => [...companyPerkKeys.all, 'detail'] as const,
  detail: (id: string) => [...companyPerkKeys.details(), id] as const,
}
export const jobApplicantKeys = {
  all: ['applicants'] as const,
  lists: () => [...jobApplicantKeys.all, 'list'] as const,
  list: (id: string) => [...jobApplicantKeys.all, 'list', id] as const,
  details: () => [...jobApplicantKeys.all, 'detail'] as const,
  detail: (id: string) => [...jobApplicantKeys.details(), id] as const,
}

export const recommendedJobKeys = {
  all: ['recommended-jobs'] as const,
  lists: () => [...recommendedJobKeys.all, 'list'] as const,
  list: (id: string) => [...recommendedJobKeys.all, 'list', id] as const,
  details: () => [...recommendedJobKeys.all, 'detail'] as const,
  detail: (id: string) => [...recommendedJobKeys.details(), id] as const,
}

export const appliedJobKeys = {
  all: ['applied-jobs'] as const,
  lists: () => [...appliedJobKeys.all, 'list'] as const,
  list: (id: string) => [...appliedJobKeys.all, 'list', id] as const,
  details: () => [...appliedJobKeys.all, 'detail'] as const,
  detail: (id: string) => [...appliedJobKeys.details(), id] as const,
}
export const interviewJobKeys = {
  all: ['interview-jobs'] as const,
  lists: () => [...interviewJobKeys.all, 'list'] as const,
  list: (id: string) => [...interviewJobKeys.all, 'list', id] as const,
  details: () => [...interviewJobKeys.all, 'detail'] as const,
  detail: (id: string) => [...interviewJobKeys.details(), id] as const,
}

export const savedJobKeys = {
  all: ['saved-jobs'] as const,
  lists: () => [...savedJobKeys.all, 'list'] as const,
  list: (id: string) => [...savedJobKeys.all, 'list', id] as const,
  details: () => [...savedJobKeys.all, 'detail'] as const,
  detail: (id: string) => [...savedJobKeys.details(), id] as const,
}
export const savedJobsStatus = {
  all: ['saved-jobs-status'] as const,
  lists: () => [...savedJobsStatus.all, 'list'] as const,
  list: (id: string) => [...savedJobsStatus.all, 'list', id] as const,
  details: () => [...savedJobsStatus.all, 'detail'] as const,
  detail: (id: string) => [...savedJobsStatus.details(), id] as const,
}

export const experienceKeys = {
  all: ['experiences'] as const,
  lists: () => [...experienceKeys.all, 'list'] as const,
  list: (id: string) => [...experienceKeys.all, 'list', id] as const,
  details: () => [...experienceKeys.all, 'detail'] as const,
  detail: (id: string) => [...experienceKeys.details(), id] as const,
}
export const calculatorKeys = {
  all: ['calculator'] as const,
  lists: () => [...calculatorKeys.all, 'list'] as const,
  list: (id: string) => [...calculatorKeys.all, 'list', id] as const,
  details: () => [...calculatorKeys.all, 'detail'] as const,
  detail: (id: string) => [...calculatorKeys.details(), id] as const,
}

export const lookingForKeys = {
  all: ['lookingFor'] as const,
  lists: () => [...lookingForKeys.all, 'list'] as const,
  list: (id: string) => [...lookingForKeys.all, 'list', id] as const,
  details: () => [...lookingForKeys.all, 'detail'] as const,
  detail: (id: string) => [...lookingForKeys.details(), id] as const,
}
export const educationKeys = {
  all: ['education'] as const,
  lists: () => [...educationKeys.all, 'list'] as const,
  list: (id: string) => [...educationKeys.all, 'list', id] as const,
  details: () => [...educationKeys.all, 'detail'] as const,
  detail: (id: string) => [...educationKeys.details(), id] as const,
}

export const offerKeys = {
  all: ['offer'] as const,
  lists: () => [...offerKeys.all, 'list'] as const,
  list: (id: string) => [...offerKeys.all, 'list', id] as const,
  details: () => [...offerKeys.all, 'detail'] as const,
  detail: (id: string) => [...offerKeys.details(), id] as const,
}

export const hasAppliedKeys = {
  all: ['has-applied'] as const,
  lists: () => [...hasAppliedKeys.all, 'list'] as const,
  list: (id: string) => [...hasAppliedKeys.all, 'list', id] as const,
  details: () => [...hasAppliedKeys.all, 'detail'] as const,
  detail: (id: string) => [...hasAppliedKeys.details(), id] as const,
}

export const skillsKeys = {
  all: ['skills'] as const,
  lists: () => [...skillsKeys.all, 'list'] as const,
  list: (id: string) => [...skillsKeys.all, 'list', id] as const,
  details: () => [...skillsKeys.all, 'detail'] as const,
  detail: (id: string) => [...skillsKeys.details(), id] as const,
}

export const userAvailabilityKeys = {
  all: ['userAvailability'] as const,
  lists: () => [...userAvailabilityKeys.all, 'list'] as const,
  list: (id: string) => [...userAvailabilityKeys.all, 'list', id] as const,
  details: () => [...userAvailabilityKeys.all, 'detail'] as const,
  detail: (id: string) => [...userAvailabilityKeys.details(), id] as const,
}
export const interviewKeys = {
  all: ['interviews'] as const,
  lists: () => [...interviewKeys.all, 'list'] as const,
  list: (id: string) => [...interviewKeys.all, 'list', id] as const,
  details: () => [...interviewKeys.all, 'detail'] as const,
  detail: (id: string) => [...interviewKeys.details(), id] as const,
}
export const interviewInfoKeys = {
  all: ['interviewsInfo'] as const,
  lists: () => [...interviewInfoKeys.all, 'list'] as const,
  list: (id: string) => [...interviewInfoKeys.all, 'list', id] as const,
  details: () => [...interviewInfoKeys.all, 'detail'] as const,
  detail: (id: string) => [...interviewInfoKeys.details(), id] as const,
}

export const talentPoolKeys = {
  all: ['talentPool'] as const,
  lists: () => [...talentPoolKeys.all, 'list'] as const,
  list: (id: string) => [...talentPoolKeys.all, 'list', id] as const,
  details: () => [...talentPoolKeys.all, 'detail'] as const,
  detail: (id: string) => [...talentPoolKeys.details(), id] as const,
}

export const interviewFeedbacksKeys = {
  all: ['interviewFeedback'] as const,
  lists: () => [...interviewFeedbacksKeys.all, 'list'] as const,
  list: (id: string) => [...interviewFeedbacksKeys.all, 'list', id] as const,
  details: () => [...interviewFeedbacksKeys.all, 'detail'] as const,
  detail: (id: string) => [...interviewFeedbacksKeys.details(), id] as const,
}

export const companyLogoKeys = {
  all: ['company-logo'] as const,
  lists: () => [...companyLogoKeys.all, 'list'] as const,
  list: (id: string) => [...companyLogoKeys.all, 'list', id] as const,
  details: () => [...companyLogoKeys.all, 'detail'] as const,
  detail: (id: string) => [...companyLogoKeys.details(), id] as const,
}

export const postKeys = {
  all: ['posts'] as const,
  lists: () => [...postKeys.all, 'list'] as const,
  list: (id: string) => [...postKeys.all, 'list', id] as const,
  details: () => [...postKeys.all, 'detail'] as const,
  detail: (id: string) => [...postKeys.details(), id] as const,
}


export const notificationKeys = {
  all: ['notification'] as const,
  lists: () => [...notificationKeys.all, 'list'] as const,
  list: (id: string) => [...notificationKeys.all, 'list', id] as const,
  details: () => [...notificationKeys.all, 'detail'] as const,
  detail: (id: string) => [...notificationKeys.details(), id] as const,
}

export const scorecardKeys = {
  all: ['scorecards'] as const,
  lists: () => [...scorecardKeys.all, 'list'] as const,
  list: (id: string) => [...scorecardKeys.all, 'list', id] as const,
  details: () => [...scorecardKeys.all, 'detail'] as const,
  detail: (id: string) => [...scorecardKeys.details(), id] as const,
}
export const companyListKeys = {
  all: ['companies'] as const,
  lists: () => [...companyListKeys.all, 'list'] as const,
  list: (id: string) => [...companyListKeys.all, 'list', id] as const,
  details: () => [...companyListKeys.all, 'detail'] as const,
  detail: (id: string) => [...companyListKeys.details(), id] as const,
}

export const allJobsForApprovalKeys = {
  all: ['allJobsForApproval'] as const,
  lists: () => [...allJobsForApprovalKeys.all, 'list'] as const,
  list: (id: string) => [...allJobsForApprovalKeys.all, 'list', id] as const,
  details: () => [...allJobsForApprovalKeys.all, 'detail'] as const,
  detail: (id: string) => [...allJobsForApprovalKeys.details(), id] as const,
}

export const businessAdsKeys = {
  all: ['businessAds'] as const,
  lists: () => [...businessAdsKeys.all, 'list'] as const,
  list: (id: string) => [...businessAdsKeys.all, 'list', id] as const,
  details: () => [...businessAdsKeys.all, 'detail'] as const,
  detail: (id: string) => [...businessAdsKeys.details(), id] as const,
}

export const trainingKeys = {
  all: ['trainings'] as const,
  lists: () => [...trainingKeys.all, 'list'] as const,
  list: (id: string) => [...trainingKeys.all, 'list', id] as const,
  details: () => [...trainingKeys.all, 'detail'] as const,
  detail: (id: string) => [...trainingKeys.details(), id] as const,
}

export const resumeTemplateKeys  = {
  all: ['resumeTemplate'] as const,
  lists: () => [...resumeTemplateKeys.all, 'list'] as const,
  list: (id: string) => [...resumeTemplateKeys.all, 'list', id] as const,
  details: () => [...resumeTemplateKeys.all, 'detail'] as const,
  detail: (id: string) => [...resumeTemplateKeys.details(), id] as const,
}
