import relativeTime from 'dayjs/plugin/relativeTime'
import { BsDot } from 'react-icons/bs'
import Image from 'next/image'
import React from 'react'
import dayjs from 'dayjs'

dayjs.extend(relativeTime)

import {
  Job,
  // useCheckSavedJobsStatus,
  // useCheckSavedJobsStatus,
  // usePostSavedJobs,
  // useUnSaveJob,
  // useUnSaveJob,
} from '@/features/job-seeker'
import { addCommaToNumber, checkIfDateIsAfter } from '@/utils'
import { Avatar } from '@mui/material'
// import { Save2 } from 'iconsax-react'
// import { toast } from 'react-toastify'
// import { useAuth } from '@/contexts/Authentication'
// import { useRouter } from 'next/router'

interface JobCardProps {
  job: Job
  isApplied?: boolean
  handleOpenModal: () => void
}

export const JobCard: React.FC<JobCardProps> = ({
  job,
  isApplied,
  handleOpenModal,
}) => {
  const {
    title,
    companyName,
    companyLogo,
    jobLocation,
    summary,
    applicants,
    workType,
    level,
    employmentType,
    salary,
    isAnonymous,
    isSpotlighted,
    validThrough,
    // isSaved,  //is saved is used when it's been added to the database
    // id,
  } = job || {}

  const { currency, min, max, term, hidden } = salary || {}
  const formattedPostedDate = dayjs(job?.datePosted).fromNow()
  const formattedValidThrough = dayjs(job?.validThrough).toNow(true)
  // const { authState } = useAuth()
  // const isUserLoggedIn = authState.isAuthenticated

  const locationArr = jobLocation?.split(',')
  const state = locationArr && locationArr[locationArr?.length - 2]
  const country = locationArr && locationArr[locationArr?.length - 1]

  // const mutatePostSavedJobs = usePostSavedJobs()
  // const mutateUnSaveJob = useUnSaveJob()
  // const mutateJobSaveStatus = useCheckSavedJobsStatus(id)

  // const isSaved = mutateJobSaveStatus.data?.data.isSaved

  // const router = useRouter()
  // const [saveState, setSaveState] = React.useState<undefined|boolean>(isSaved)

  // const { data: savedStatus } = useCheckSavedJobsStatus(id)
  // const isSaved = savedStatus?.data?.isSaved

  // const {data:savedStatus} = useCheckSavedJobsStatus(id)

  const isApplicationClosed = checkIfDateIsAfter(validThrough)
  const postedDate = dayjs(job?.datePosted)
  const now = dayjs()
  const differenceInHours = now.diff(postedDate, 'hour')
  const showNumberOfApplicant =
    differenceInHours < 12 || (differenceInHours > 12 && applicants > 0)
      ? true
      : false
  // const handleSaveJobs = (payload: { jobId: string }) => {
  //   if (authState.isAuthenticated === false) {
  //     router.push('/login')
  //   }
  //   const handleError = () => {
  //     toast.error('job not saved')
  //     setSaveState(false)
  //   }

  //   setSaveState(true)
  //   mutatePostSavedJobs.mutate(payload, {
  //     onError: handleError,
  //   })
  // }

  // const handleUnSaveJobs = (payload:string) => {
  //   if (authState.isAuthenticated === false) {
  //     router.push('/login')
  //   }
  //   const handleError = () => {
  //     toast.error('job not unsaved')
  //     setSaveState(true)
  //   }

  //   setSaveState(false)
  //   mutateUnSaveJob.mutate(payload, {
  //     onError: handleError,
  //   })
  // }

  return (
    <button
      onClick={handleOpenModal}
      className="relative cursor-pointer rounded-md border border-gray-100 bg-white p-3 shadow-lg transition duration-200 hover:shadow-sm sm:p-6"
    >
      {isSpotlighted && (
        <div className="absolute top-6 right-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#BA3C40"
            viewBox="0 0 256 256"
          >
            <path d="M235.33,104l-53.47,53.65c4.56,12.67,6.45,33.89-13.19,60A15.93,15.93,0,0,1,157,224c-.38,0-.75,0-1.13,0a16,16,0,0,1-11.32-4.69L96.29,171,53.66,213.66a8,8,0,0,1-11.32-11.32L85,159.71l-48.3-48.3A16,16,0,0,1,38,87.63c25.42-20.51,49.75-16.48,60.4-13.14L152,20.7a16,16,0,0,1,22.63,0l60.69,60.68A16,16,0,0,1,235.33,104Z"></path>
          </svg>
        </div>
      )}
      <div className="flex items-start justify-between gap-6">
        <button onClick={handleOpenModal} className="flex gap-6">
          {isAnonymous ? (
            <Avatar sx={{ bgcolor: '#000', width: 60, height: 60 }}>
              <span className="text-2xl">A</span>
            </Avatar>
          ) : (
            <>
              {companyLogo ? (
                <div className="max-h-[60px] max-w-[60px] overflow-hidden">
                  <Image
                    src={companyLogo}
                    alt={`${companyName} logo`}
                    height={60}
                    width={60}
                  />
                </div>
              ) : (
                <Avatar sx={{ bgcolor: '#000', width: 60, height: 60 }}>
                  <span className="text-2xl">
                    {companyName && companyName[0]}
                  </span>
                </Avatar>
              )}
            </>
          )}
          <div>
            <h2 className="mt-2 text-left text-xs font-bold sm:text-sm">
              {title}
            </h2>
            <p className="flex items-center text-left text-[10px] text-gray-400 sm:text-xs">
              {isAnonymous ? 'Anonymous' : companyName}
              <BsDot className="text-left text-xs text-gray-400" /> {state}
              {state && country && ','} {country}
            </p>
          </div>
        </button>
        {/* {false && isUserLoggedIn && <div className="">
          {saveState === true && (
            <button onClick={() => handleUnSaveJobs(id)}>
              <Save2 size="20" color="#2F80ED" variant="Bold" className="" />
            </button>
          )}
          {saveState === false && (
            <button onClick={() => handleSaveJobs({ jobId: id })}>
              <Save2 size="20" color="#2F80ED" variant="Outline" />
            </button>
          )}
        </div>} */}
      </div>
      <div className="mt-5 flex flex-wrap gap-3 text-[11px] capitalize">
        <div className="whitespace-nowrap bg-[#F2F7FF] py-1 px-4 font-medium   text-[#575698]">
          {employmentType}
        </div>
        <div className="whitespace-nowrap bg-[#FCF8F3] py-1 px-4  font-medium  text-[#ED7200]">
          {workType}
        </div>
        <div className="whitespace-nowrap bg-[#D3FFE0] py-1 px-4  font-medium  text-[#19BE48]">
          {level}
        </div>
      </div>
      <p className="mt-4 text-left text-xs line-clamp-2">{summary}</p>
      <p className="mt-3 flex items-center gap-2">
        <div className="mt-1">
          <Image
            src="/assets/usdcoin.png"
            alt="jobLogo"
            height={16}
            width={16}
          />
        </div>
        <p className="mt-1 whitespace-nowrap text-xs font-light line-clamp-1">
          Salary:{' '}
        </p>
        {hidden ? (
          <p className="mt-1 whitespace-nowrap text-xs font-normal line-clamp-1">
            Confidential
          </p>
        ) : (
          <>
            <p className=" mt-1 whitespace-nowrap text-xs font-normal line-clamp-1 ">
              {currency}
              {addCommaToNumber(min || 0)}
              {(max || 0) > 0 && (
                <span>
                  {' - '}
                  {currency}
                  {addCommaToNumber(max || 0)}{' '}
                </span>
              )}{' '}
              / <span>{term}</span>
            </p>
          </>
        )}
      </p>

      {isApplied && (
        <div className="items mt-5 flex items-center gap-1 text-xs">
          <span className="text-blue-400">
            Applied {dayjs(job.dateApplied).fromNow()}
          </span>
          <div className="text-lg text-gray-400">
            <BsDot />
          </div>
          <span className="ml-1 text-red-600">
            {' '}
            {isApplicationClosed ? (
              'Application Closed'
            ) : (
              <>Closing in {formattedValidThrough}</>
            )}
          </span>
        </div>
      )}
      {!isApplied && (
        <div className="items mt-5 flex items-center justify-between">
          <div className="items flex items-center gap-1 text-xs">
            <span className="text-xs text-gray-400">
              Posted {formattedPostedDate}
            </span>
            {!isSpotlighted && showNumberOfApplicant && (
              <>
                <div className="text-lg text-gray-400">
                  <BsDot />
                </div>
                <span className="text-blue-400">
                  {applicants} Applicant{applicants > 1 ? 's' : ''}
                </span>
              </>
            )}
            <div className="text-lg text-gray-400">
              <BsDot />
            </div>
            <span className="ml-1 text-red-600">
              {' '}
              {isApplicationClosed ? (
                'Application Closed'
              ) : (
                <>Closing in {formattedValidThrough}</>
              )}
            </span>
          </div>
        </div>
      )}
    </button>
  )
}
