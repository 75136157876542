import { useRouter } from 'next/router'
// import { toast } from 'react-toastify'
import React from 'react'

import { useAuth } from '@/contexts/Authentication'
import { FullPageLoader } from '.'

interface ProtectedRouteGuardProps {
  isPublicRoute: boolean
  protectedRoutes: string[]
  children: React.ReactNode
}

export const ProtectedRouteGuard: React.FunctionComponent<
  ProtectedRouteGuardProps
> = ({ isPublicRoute, protectedRoutes, children }) => {
  const router = useRouter()
  const is404Page = router.pathname === '/404'
  const { authState } = useAuth()
  const { isAuthenticated, isLoading } = authState

  const isPathProtected = protectedRoutes.indexOf(router.pathname) !== -1

  React.useEffect(() => {
    if (isLoading || isPublicRoute) return

    if (!isAuthenticated && isPathProtected) {
      router.push('/login')
    }

    // if (isAuthenticated && !isPathProtected && !isPublicRoute && !is404Page) {
    //   toast.error('Oops. You do not have access.')
    //   router.push('/dashboard')
    // }
  }, [
    isLoading,
    isAuthenticated,
    isPathProtected,
    router,
    isPublicRoute,
    is404Page,
  ])

  if (is404Page) {
    return <>{children}</>
  }

  if ((isLoading || !isAuthenticated) && isPathProtected && !isPublicRoute) {
    return <FullPageLoader />
  }

  if (isLoading && !isPublicRoute) {
    return <FullPageLoader />
  }

  return <>{children}</>
}
