import { AnimatePresence, motion } from 'framer-motion'
import React, { Dispatch, SetStateAction } from 'react'
import { BsXLg } from 'react-icons/bs'
import Image from 'next/image'
import clsx from 'clsx'

import { ViewIntervieweeModalAnim, backdropVariant } from '@/utils'
import { InvitedMembers } from '../../types'
import { RoundCheckbox, Spinner } from '@/components'
import { MembersToBeGiveAccessInterface } from './InterviewMoreInfoModal'

interface AddMembersProp {
  showAddMember: boolean
  setShowAddMembers: Dispatch<SetStateAction<boolean>>
  membersToBeGivenAccess: InvitedMembers[]
  handleAddMemberAccessChange: (data: MembersToBeGiveAccessInterface) => void
  approvalLoading: boolean
  handleSendForApproval: () => void
  selectedMembers: MembersToBeGiveAccessInterface[]
}

export const AddMemberAccessToApproveModal: React.FC<AddMembersProp> = ({
  showAddMember,
  setShowAddMembers,
  membersToBeGivenAccess,
  // handleShowMembersModal,
  handleAddMemberAccessChange,
  approvalLoading,
  handleSendForApproval,
  selectedMembers
}) => {
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (showAddMember && ref.current && !ref.current.contains(e.target)) {
        setShowAddMembers(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showAddMember])

 

  return (
    <>
      <AnimatePresence>
        {showAddMember && (
          <motion.div
            className="fixed top-0 left-0 z-[300] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
            initial="initial"
            animate="show"
            exit="exit"
            variants={backdropVariant}
          >
            <motion.div
              className="scrollbar-hide flexibleHeight absolute top-[50%] left-[50%] w-[669px] overflow-scroll rounded-lg bg-white px-7 py-8"
              ref={ref}
              initial="initial"
              animate="show"
              exit="exit"
              variants={ViewIntervieweeModalAnim}
            >
              <div>
                <div>
                  <p className="text-base font-bold"></p>
                  <button
                    className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                    onClick={() => setShowAddMembers(false)}
                  >
                    <BsXLg />
                  </button>
                  <div>
                    <p className="text-base font-bold">Company Members</p>
                  </div>
                  <p className='text-xs mt-4'>Add members that will have direct accesss to the approval list</p>
                </div>
              </div>
              {
                <div className="mt-4 flex h-[45px] w-full flex-row-reverse items-center gap-2 rounded-3xl border border-[#EFEFEF] bg-transparent  px-4">
                  <input
                    type="text"
                    className="w-full bg-transparent font-WorkSans text-sm outline-none"
                    placeholder="Search"
                    // value={searchTerm}
                    // onChange={handleSearchChange}
                  />
                  <Image
                    src="/assets/globalsearch.png"
                    width={20}
                    height={20}
                    alt="setUpProfile"
                  />
                </div>
              }

              {membersToBeGivenAccess.map((data, index) => {

                const memberArray = {
                  name: data?.firstName + " " + data?.lastName,
                  id: data?.id,
                  email: data?.email
                }
                return (
                  <div
                    className="mt-5 w-full flex item-center gap-4 border-b border-b-[#E5E5E5] py-4"
                    key={index}
                  >
                    <RoundCheckbox
                      checked={selectedMembers.some(
                          (member) => member.id === data.id
                      )}
                      onChange={() =>
                        handleAddMemberAccessChange(memberArray)
                      }
                    />
                    <div className="flex items-center gap-4">
                      <p
                        className={clsx(
                          'flex h-[45px] w-[45px] bg-blue-400 text-white font-bold min-w-[45px] items-center justify-center rounded-full border-2 border-white'
                        )}
                      >
                        {data.firstName[0]}
                      </p>
                      <div className="flex flex-col items-start">
                        <p className="text-sm font-bold">
                          {data.firstName || ''} {data.lastName || ''}
                        </p>
                        <p className="text-sm">{data.email}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

              <button
                className="flex items-center gap-2 rounded-md bg-primary px-4 py-2 text-xs text-white mx-auto mt-6 font-semibold"
                onClick={handleSendForApproval}
              >
                {approvalLoading ? (
                  <p>Fowarding</p>
                ) : (
                  <p>Forward for Approval</p>
                )}
                {approvalLoading && (
                  <Spinner size="md" className="text-current" />
                )}
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
