import { Option } from '@/types'

export const WORKSPACE_OPTIONS: Option[] = [
  {
    name: 'Onsite',
    id: 'onsite',
  },
  {
    name: 'Remote',
    id: 'remote',
  },
  {
    name: 'Hybrid',
    id: 'hybrid',
  },
]

export const JOB_DEADLINE_OPTIONS : Option[] = [
  {
    name: '7 days',
    id: '7',
  },
  {
    name: '12 days',
    id: '12',
  },
  {
    name: '15 days',
    id: '15',
  },
  {
    name: '21 days',
    id: '21',
  },
  {
    name: '30 days',
    id: '30',
  },
]

export const JOB_SEARCH_STATUS_OPTIONS: Option[] = [
  {
    name: 'Actively looking',
    id: 'actively looking',
  },
  {
    name: 'Open to opportunities',
    id: 'open to opportunities',
  },
  {
    name: 'Not looking',
    id: 'not looking',
  },
]

const firstTwentyNumbers = Array.from(Array(20).keys()).map((number) => {
  const year = number + 1
  return {
    name: `${year} year${year > 1 ? 's' : ''}`,
    id: `${year}`,
  }
})


export const EXPERIENCE_OPTIONS: Option[] = [
  {
    name: 'No experience',
    id: '0',
  },
  {
    name: 'Less than a year',
    id: '0-1',
  },
  ...firstTwentyNumbers,
  {
    name: 'More than 20 years',
    id: '20+',
  },
]

export const POST_NYSC: Option[] = [
  {
    name: 'Less than a year',
    id: '0-1',
  },
  ...firstTwentyNumbers,
  {
    name: 'More than 20 years',
    id: '20+',
  },
]


export const EMPTY_OPTION: Option = {
  name: '',
  id: '',
}

export const CURRENCY_OPTIONS: Option[] = [
  {
    name: '₦',
    id: 'naira',
  },
  {
    name: '$',
    id: 'dollar',
  },
  {
    name: '€',
    id: 'euro',
  },
  {
    name: '£',
    id: 'pound',
  },
  {
    name: 'GH₵',
    id: 'ghanaian cedi',
  },
  {
    name: '¥',
    id: 'yen',
  },
  {
    name: '₹',
    id: 'rupee',
  },
]

export const NUMBER_OF_EMPLOYEES_OPTIONS: Option[] = [
  {
    name: '1-10',
    id: '1-10',
  },
  {
    name: '11-50',
    id: '11-50',
  },
  {
    name: '51-100',
    id: '51-100',
  },
  {
    name: '101-500',
    id: '101-500',
  },
  {
    name: '501-1000',
    id: '501-1000',
  },
  {
    name: '1000+',
    id: '1000+',
  },
]

export const INTERVIEW_TYPE_OPTIONS: Option[] = [
  {
    name: 'Onsite',
    id: 'onsite',
  },
  {
    name: 'Telephone',
    id: 'telephone',
  },
  {
    name: 'Web conference',
    id: 'web conference',
  },
  {
    name: 'Video conference',
    id: 'video conference',
  },
  {
    name: 'Online Assessment Test',
    id: 'online assessment test',
  },
  {
    name: 'Onsite Assessment Test',
    id: 'Onsite assessment test',
  },

]

export const INTERVIEW_DURATION_OPTIONS: Option[] = [
  {
    name: '15 minutes',
    id: '15',
  },
  {
    name: '30 minutes',
    id: '30',
  },
  {
    name: '45 minutes',
    id: '45',
  },
  {
    name: '1 hour',
    id: '60',
  },
  {
    name: '1 hour 30 minutes',
    id: '90',
  },
  {
    name: '2 hours',
    id: '120',
  },
  {
    name: '2 hours 30 minutes',
    id: '150',
  },
  {
    name: '3 hours',
    id: '180',
  },
]

export const AVAILABLE_SLOT_OPTIONS: Option[] = [
  {
    name: '2 Time Slots',
    id: '2',
  },
  {
    name: '3 Time Slots',
    id: '3',
  },
  {
    name: '4 Time Slots',
    id: '4',
  },
  {
    name: '5 Time Slots',
    id: '5',
  },
  {
    name: '6 Time Slots',
    id: '6',
  },
  {
    name: '7 Time Slots',
    id: '7',
  },
  {
    name: '8 Time Slots',
    id: '8',
  },
  {
    name: '9 Time Slots',
    id: '9',
  },
  {
    name: '10 Time Slots',
    id: '10',
  },
]
