import React, { useState } from 'react'
import { motion } from 'framer-motion'

// import CookiesNotification from '../CookiesNotification'
import Footer from '@/components/Layout/Footer'
import Navbar from '@/components/Layout/Navbar'
// import { MobileAppDownload } from '../Modals'
// import Cookies from 'js-cookie'

interface WebsiteLayoutProps {
  children: React.ReactNode
}

export const WebsiteLayout: React.FC<WebsiteLayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  // const [openMobileAppModal, setOpenMobileAppModal] = useState(false)


  //sets modal to show in a day

  // React.useEffect(() => {
  //   const hasModalShownToday = Cookies.get('modalShown');

  //   if (!hasModalShownToday) {
  //     // Show the modal
  //     setOpenMobileAppModal(true);
  //     // Set the cookie to expire in 24 hours
  //     Cookies.set('modalShown', 'true', { expires: 1 });
  //   }
  // }, []);
 

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'linear', duration: 0.1 }}
      className="relative"
    >
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div>{children}</div>
      <Footer />
      {/* <MobileAppDownload
        openMobileAppModal={openMobileAppModal}
        setOpenMobileAppModal={setOpenMobileAppModal}
      /> */}
      {/* <CookiesNotification /> */}
    </motion.div>
  )
}
