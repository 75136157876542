import React from 'react'

import { companyIDStorage } from '@/features/auth'
import { CompanyIdContext } from '@/features/CompanyIdContext'


const useCompanyId = () => {
    const { contextCompanyID } = React.useContext(CompanyIdContext)

    const companyID = contextCompanyID ? contextCompanyID : companyIDStorage.getCompanyID()
    return companyID;
}

export { useCompanyId };