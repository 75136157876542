import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

import { axiosInstance, setAxiosDefaultToken } from '@/lib/axios'
import { LoginFormProps, LoginResponse } from '../types'
import { tokenStorage, userIDStorage } from '../utils'
import { useAuth } from '@/contexts/Authentication'
import { fetchData, transformError } from '@/utils'
import { useRedirection } from '../hooks'
import { getUser } from '.'

export const useLogin = () => {
  const { authDispatch } = useAuth()
  const { redirect } = useRedirection()

  return useMutation<LoginResponse, AxiosError, LoginFormProps>(
    (payload) => fetchData<LoginFormProps>('/auth/login', 'POST', payload),
    {
      onSuccess: async (response) => {
        const {
          data: { userId, accessToken, },
        } = response
        // set token
        tokenStorage.setToken(accessToken)
        setAxiosDefaultToken(accessToken, axiosInstance)

        userIDStorage.setUserID(userId)

        const user = await getUser(userId)

        if (authDispatch && user) {
         
          authDispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          })

          authDispatch({ type: 'STOP_LOADING' })
          redirect(user);
        }
      },
      onError: async (error) => {
        toast.error(transformError(error))
      },
    }
  )
}
