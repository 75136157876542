import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { fetchData, transformError } from '@/utils'
import { AxiosError } from 'axios'

interface AddInstitutionProp {
  name: string
}

export const useAddInstitution = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: AddInstitutionProp) =>
      fetchData<AddInstitutionProp>('/common/add-institute', 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['institute'],
        })
      },
      onError: (error: AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
