import { WORK_PLACE_CULTURE } from '@/data'
import { useChangeVisibility, useCurrencyOptions, useJobTypeOptions, useLevelOptions } from '@/hooks'
import clsx from 'clsx'
// import { Slider } from '@mui/material'
import { useState, useEffect } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
// import { Button } from './Elements'

type SelectedFilterProps = {
  segment: string
  keywords: string[]
}
interface FilterProp {
  selectedFilters: SelectedFilterProps[]
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<SelectedFilterProps[]>
  >
  setOpenFilterMobile?: (value: boolean) => void
}

export const JobDropDownFilter = ({
  setSelectedFilters,
  selectedFilters,
}: // setOpenFilterMobile,
FilterProp) => {
  const {dropDownButtonRef:cultureButton, ref:cultureRef, setIsComponentVisible:setOpenCulture, isComponentVisible:openCulture } = useChangeVisibility()
  const {dropDownButtonRef:jobTypesButton, ref:jobTypesRef, setIsComponentVisible:setOpenEmployment, isComponentVisible:openEmployment } = useChangeVisibility()
  const {dropDownButtonRef:jobLevelButton, ref:jobLevelRef, setIsComponentVisible:setOpenJobLevel, isComponentVisible:openJobLevel } = useChangeVisibility()
  const {dropDownButtonRef:currencyButton, ref:currencyRef, setIsComponentVisible:setCurrencyDropDown, isComponentVisible:currencyDropDown } = useChangeVisibility()
  const { currencyOptions } = useCurrencyOptions()
  const { jobTypes, jobTypeLoading } = useJobTypeOptions()
  const { levelOptions, isLevelsLoading } = useLevelOptions()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState<
    { name: string; id: string }[]
  >([])

  useEffect(() => {
    setFilteredData(
      searchTerm
        ? currencyOptions?.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : currencyOptions
    );
  }, [searchTerm]);


  const checkedSVG = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#27AE60" />
      <path
        d="M14 8L9.1875 13L7 10.7273"
        stroke="#FEFEFE"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  const handleCheckboxChange = (segment: string, name: string) => {
    setSelectedFilters((prevFilters) => {
      const existingFilterIndex = prevFilters?.findIndex(
        (filter) => filter.segment === segment
      )

      if (existingFilterIndex !== -1) {
        // If the filter exists, update its keywords
        const updatedFilter = {
          ...prevFilters[existingFilterIndex],
          keywords:
            segment === 'currency'
              ? [name] // If the segment is 'currency', replace the existing keyword with the new one
              : prevFilters[existingFilterIndex].keywords.includes(name)
              ? prevFilters[existingFilterIndex].keywords.filter(
                  (keyword) => keyword !== name
                )
              : [...prevFilters[existingFilterIndex].keywords, name],
        }
        const updatedFilters = [...prevFilters]
        updatedFilters[existingFilterIndex] = updatedFilter

        // If the updated filter has no keywords, remove it from the array
        if (updatedFilter.keywords.length === 0) {
          updatedFilters.splice(existingFilterIndex, 1)
        }

        return updatedFilters
      } else {
        // If the filter doesn't exist, add a new one
        return [...prevFilters, { segment, keywords: [name] }]
      }
    })
  }

  

  const handleClickCurrency = (id: string, name: string) => {
    if (searchTerm === name) {
      setSearchTerm('')
    } else {
      setSearchTerm(name)
    }
    handleCheckboxChange('CURRENCY', id)
  }

  const handleClear = () => {
    setSelectedFilters([])
    setOpenCulture(false)
    setCurrencyDropDown(false)
    setOpenJobLevel(false)
    setOpenEmployment(false)
  }

 
  return (
    <div className="px-6">
      <div className="flex flex-wrap select-none items-center gap-8 mb-8">
        {/* Office culture */}
        <div className="relative"ref={cultureRef}>
          <button
          ref={cultureButton}
            className="flex w-[250px] cursor-pointer items-center justify-between rounded-full border border-gray-350 bg-white py-2 px-4"
            onClick={() => setOpenCulture(!openCulture)}
          >
            <p className="text-sm font-[700]">Office Culture</p>
            <div className="">
              <FaChevronDown
                className={clsx(
                  'mr-[5px] text-sm text-gray-400 transition-all duration-300 ease-in-out',
                  openCulture && 'rotate-180'
                )}
              />
            </div>
          </button>

          {/*  */}

          <div
            className={clsx(
              'absolute top-4 z-10 mt-6 w-[250px] rounded-[10px] bg-white px-6 transition-all duration-300 ease-in-out',
              openCulture
                ? 'max-h-[200px] border border-gray-350 shadow-sm'
                : 'max-h-0 overflow-hidden'
            )}
          >
            {WORK_PLACE_CULTURE.map((option, index) => (
              <div
                className={clsx(
                  ' flex items-center justify-between',
                  index > 0 ? 'my-6' : 'mt-6'
                )}
                key={index}
              >
                <div className="flex">
                  <div className="flex items-center gap-6">
                    <div className="flex items-center gap-6">
                      <button
                        onClick={() =>
                          handleCheckboxChange('WORKPLACE_CULTURE', option.name)
                        }
                        className={clsx(
                          'h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full'
                          // !selectedFilters?.keywords?.includes(option.name) &&
                          //   'border'
                        )}
                      >
                        {selectedFilters
                          .find(
                            (filter) => filter.segment === 'WORKPLACE_CULTURE'
                          )
                          ?.keywords.includes(option.name) ? (
                          checkedSVG
                        ) : (
                          <div className="h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full border border-[#A6A6A6]"></div>
                        )}
                      </button>
                      <p className="text-sm font-bold capitalize">{option.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Type of Employment */}
        <div className="relative" ref={jobTypesRef}>
          <button
          ref={jobTypesButton}
            className="flex w-[250px] cursor-pointer items-center justify-between rounded-full border border-gray-350 py-2 px-4"
            onClick={() => setOpenEmployment(!openEmployment)}
          >
            <p className="text-sm font-[700]">Type of Employment</p>
            <div className="">
              <FaChevronDown
                className={clsx(
                  'mr-[5px] text-sm text-gray-400 transition-all duration-300 ease-in-out',
                  openEmployment && 'rotate-180'
                )}
              />
            </div>
          </button>

          {/*  */}

          <div
            className={clsx(
              'absolute z-10 top-4 mt-6 w-[250px] rounded-[10px] bg-white px-6 transition-all duration-300 ease-in-out',
              openEmployment
                ? 'max-h-[350px] overflow-y-scroll border border-gray-350'
                : 'max-h-0 overflow-hidden'
            )}
          >
            {jobTypeLoading === false &&
              jobTypes.map((option, index) => (
                <div
                  className={clsx(
                    'mt-4 flex items-center justify-between',
                    index === jobTypes.length - 1 && 'mb-4'
                  )}
                  key={index}
                >
                  <div className="flex ">
                    <div className="flex items-center gap-6">
                      <div className="flex items-center gap-6">
                        <button
                          onClick={() =>
                            handleCheckboxChange('EMPLOYMENT_TYPE', option.name)
                          }
                          className={clsx(
                            'h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full'
                            // !selectedFilters?.keywords?.includes(option.name) &&
                            //   'border'
                          )}
                        >
                          {selectedFilters
                            .find(
                              (filter) => filter.segment === 'EMPLOYMENT_TYPE'
                            )
                            ?.keywords.includes(option.name) ? (
                            checkedSVG
                          ) : (
                            <div className="h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full border border-[#A6A6A6]"></div>
                          )}
                        </button>
                        <p className="text-sm capitalize font-bold">{option.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Job Level */}
        <div className="relative" ref={jobLevelRef}>
          <button
          ref={jobLevelButton}
            className=" flex w-[250px] cursor-pointer items-center justify-between rounded-full border border-gray-350 py-2 px-4 "
            onClick={() => setOpenJobLevel(!openJobLevel)}
          >
            <p className="text-sm font-[700]">Job Level</p>
            <div className="">
              <FaChevronDown
                className={clsx(
                  'mr-[5px] text-sm text-gray-400 transition-all duration-300 ease-in-out',
                  openJobLevel && 'rotate-180'
                )}
              />
            </div>
          </button>

          {/*  */}

          <div
            className={clsx(
              'absolute top-4 mt-6 overflow-y-scroll z-10 w-[250px] rounded-[10px] bg-white px-6 transition-all duration-300 ease-in-out',
              openJobLevel ? 'h-[350px] border border-gray-350' : 'max-h-0'
            )}
          >
            {isLevelsLoading === false &&
              levelOptions?.map((option, index) => (
                <div
                  className={clsx(
                    'flex items-center justify-between',
                    index > 0 ? 'my-4' : 'mt-4'
                  )}
                  key={index}
                >
                  <div className="flex ">
                    <div className="flex items-center gap-6">
                      <div className="flex items-center gap-6">
                        <button
                          onClick={() =>
                            handleCheckboxChange('JOB_LEVEL', option.name)
                          }
                          className={clsx(
                            'h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full'
                            // !selectedFilters?.keywords?.includes(option.name) &&
                            //   'border'
                          )}
                        >
                          {selectedFilters
                            .find((filter) => filter.segment === 'JOB_LEVEL')
                            ?.keywords.includes(option.name) ? (
                            checkedSVG
                          ) : (
                            <div className="h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full border border-[#A6A6A6]"></div>
                          )}
                        </button>
                        <p className="text-sm font-bold capitalize">{option.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* currency section */}
        <div className="relative" ref={currencyRef}>
          <div
            onClick={() => setCurrencyDropDown(!currencyDropDown)}
            ref={currencyRef}
            className=" flex w-[250px] cursor-pointer justify-between rounded-full border border-gray-350 py-2 px-4"
          >
            <p className="text-sm font-[700]">Currency</p>

            <button ref={currencyButton}>
              <FaChevronUp
                className={
                  currencyDropDown
                    ? 'mr-[5px] text-sm text-gray-400 transition duration-300 ease-in-out'
                    : 'mr-[5px] rotate-180  text-sm text-gray-400 transition duration-300 ease-in-out'
                }
              />
            </button>
          </div>
          <div
            className={clsx(
              'absolute top-4 mt-6 w-[250px] rounded-[10px] bg-white py-4',
              currencyDropDown && 'border border-gray-350'
            )}
          >
            {/* currency type drop down options */}
            {currencyDropDown && (
              <div className="rounded-full text-xs text-white">
                <div className="px-4">
                  <div className="flex items-center justify-between overflow-hidden rounded-full bg-black">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search currency/country"
                      className="h-8 rounded-full bg-black pl-4 outline-none"
                    />
                    <FaChevronDown className="pr-4 text-gray-400" />
                  </div>
                </div>
                <div className=" z-30 mt-2 flex max-h-[275px] w-full flex-col overflow-y-scroll pb-4 text-left">
                  {filteredData.map((item, index) => (
                    <button
                      onClick={() => handleClickCurrency(item.id, item.name)}
                      className="py-2 px-4 text-left text-sm text-black hover:bg-black hover:text-white"
                      key={index}
                    >
                      <p className="font-bold">{item.name}</p>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex h-fit justify-end">
          <button className="text-sm text-red-400" onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>
    </div>
  )
}
