import { CreateJobAlertProp, useJobAlert } from '@/features/job-seeker'
import { AnimatePresence, motion } from 'framer-motion'
import {
  InputJobTitlesAutoComplete,
  InputLocationAutoComplete,
  RoundCheckbox,
} from './Form'
import React from 'react'
import { Control, useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import { EMPTY_OPTION, backdropVariant, lookingFor } from '@/utils'

import { Button } from './Elements'

interface JobAlertProps {
  jobAlertShow: boolean
  setJobAlertShow: (value: boolean) => void
}

export const CreateJobAlert = ({
  jobAlertShow,
  setJobAlertShow,
}: JobAlertProps) => {
  const {
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<CreateJobAlertProp>({
	defaultValues: {
		jobLocation: '',
		jobTitle: EMPTY_OPTION,
		workPreference: []

	}
  })

  const location = watch('jobLocation')
  const mutateJobAlert = useJobAlert()
  const jobAlertLoading = mutateJobAlert.isLoading

  const ref = React.useRef<HTMLInputElement>(null)
  const [selectedItems, setSelectedItems] = React.useState<string[]>([])
  const workPreference = ['Onsite', 'Remote', 'Hybrid']

  const handleCheckboxChange = (preference: string) => {
    if (selectedItems.includes(preference)) {
      setSelectedItems(selectedItems.filter((item:string) => item !== preference))
    } else {
      setSelectedItems([...selectedItems, preference])
    }
  }

  const handleSelect = (location: string) => {
    setValue('jobLocation', location)
  }
  const onSubmit = (values: CreateJobAlertProp) => {
	
	const payload:CreateJobAlertProp = {
		jobLocation: values.jobLocation,
		jobTitle: values.jobTitle,
		workPreference: selectedItems
	}

	console.log(payload)
  }

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (jobAlertShow && ref.current && !ref.current.contains(e.target)) {
        setJobAlertShow(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [jobAlertShow])

  return (
    <AnimatePresence>
      {jobAlertShow && (
        <motion.div
          className="fixed top-0 left-0 z-[100]  h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[50%] left-[50%] w-[90%] overflow-scroll rounded-lg  bg-white px-8 py-6 md:w-[524px]"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={lookingFor}
          >
            <div>
              <div>
                <p className="text-base font-bold">
                  What kind of jobs are you looking for?
                </p>
                <button
                  type="button"
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setJobAlertShow(false)}
                >
                  <BsXLg />
                </button>
                <p className="text-xs">
                  We’ll send you relevant job alerts based on your work
                  preference
                </p>
              </div>
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <p className="mt-6 mb-2 text-sm font-bold">
                    <span className="text-primary">*</span> Job Title
                  </p>
                  <InputJobTitlesAutoComplete
                    className=""
                    control={control as unknown as Control}
                    hasError={errors.jobTitle}
                    placeholder="Eg. Frontend developer"
                    name="jobTitle"
                  />

                  <p className="-mb-2 mt-4 text-sm font-bold">
                    <span className="text-primary">*</span> Job Location
                  </p>
                  <InputLocationAutoComplete
                    address={location}
                    handleChange={handleSelect}
                    handleSelect={handleSelect}
                    isError={!!errors.jobLocation}
                  />
                  <p className="mt-4 text-sm font-bold">
                    <span className="text-primary">*</span> Work Preference
                  </p>
                  <div className="mt-2 flex items-center gap-4">
                    {workPreference.map((preference, index) => {
                      return (
                        <div className="flex items-center gap-4" key={index}>
                          <RoundCheckbox
                            checked={selectedItems.includes(preference)}
                            onChange={() => handleCheckboxChange(preference)}
                          />
                          <p className="text-sm">{preference}</p>
                        </div>
                      )
                    })}
                  </div>
                  <Button isLoading={jobAlertLoading} className='mt-8 mx-auto' type='submit'>Create Job Alert</Button>
                </form>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CreateJobAlert
