import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

import { CustomApplyPayload } from '@/features/job-seeker'
import { appliedJobKeys, companyJobKeys, fetchData, hasAppliedKeys, transformError } from '@/utils'

export const useEasyApply = () => {
  const queryClient = useQueryClient()
  return useMutation<Response, AxiosError, CustomApplyPayload>(
    (payload) => fetchData('/user/job/easy-apply', 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Application submitted successfully')
        queryClient.invalidateQueries({
          queryKey: appliedJobKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: hasAppliedKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: companyJobKeys.all,
        })
      },
      onError: (error) => {
        toast.error(transformError(error))
      },
    }
  )
}
