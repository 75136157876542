import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { companyKeys, fetchData } from '@/utils'
// import { companyIDStorage } from '@/features/auth'
import { CompanyPerksPayload } from '../types'
import { useCompanyId } from '../hooks'

export const usePostCompanyPerks = () => {
  const queryClient = useQueryClient();
  const companyID =  useCompanyId()
  return useMutation(
    (payload: CompanyPerksPayload) =>
      fetchData(`/company/perks/add/${companyID}`, 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: companyKeys.list(companyID),
        });
        toast.success('Perk added successfully')
        // router.push('/recruiter/dashboard/jobs')
      },
      onError: () => {
        toast.error('Failed to add perk')
      },
    }
  )
}
