import Image from 'next/image'
import React from 'react'
import clsx from 'clsx'

import { candidateApplicationDetailIcons } from '@/data'
// import { BsThreeDotsVertical } from 'react-icons/bs'
import { NewInterview } from '@/features/job-seeker'
// import dayjs from 'dayjs'
import {
  InterviewDetails,
  InterviewMoreInfoModal,
  // DisqualifiedApplicantModal,
  // HiredApplicantModal,
  // InterviewDetails,
  // ReschdeduleInterview,
  ViewIntervieweeModal,
} from './modals'
import { useJobApplicants } from '../api'
import { useRouter } from 'next/router'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { useClipboard } from '@/components'

interface InterviewComponentProp {
  data: NewInterview
  setReschdeduleInterview?: (value: boolean) => void
  setShowInterviewDetails?: (value: boolean) => void
  setOpenHiredApplicantModal?: (value: boolean) => void
  setOpenDisqualifiedApplicantModal?: (value: boolean) => void
}

export const InterviewComponent = ({ data }: InterviewComponentProp) => {
  // modal states
  // const [showInterviewDetails, setShowInterviewDetails] = React.useState(false)
  // const [reschdeduleInterview, setReschdeduleInterview] = React.useState(false)
  // const [openHiredApplicantModal, setOpenHiredApplicantModal] =
  //   React.useState(false)
  // const [openDisqualifiedApplicantModal, setOpenDisqualifiedApplicantModal] =
  //   React.useState(false)
  const [openViewIntervieweeModal, setOpenViewIntervieweeModal] =
    React.useState(false)
    const { copy } = useClipboard()
  const [applicantId, setApplicantId] = React.useState('')
  const [showInterviewDetails, setShowInterviewDetails] = React.useState(false)
  const [getInterviewId, setGetInterviewId] = React.useState('')
  const[openInterviewMoreInfoModal, setShowInterviewMoreInfoModal] = React.useState(false)
  const handleApplicantClick = (userId: string) => {
    setApplicantId(userId)
    setShowInterviewDetails(true)
  }
  // const [openInterviewMoreInfoModal, setShowInterviewMoreInfoModal] =
  //   React.useState(false)

  const router = useRouter()
  const { id } = router.query

  const { data: jobData } = useJobApplicants(id as string)
  const colorSet = [
    'rgba(47, 128, 237, 1)',
    'rgba(39, 174, 96, 1)',
    'rgba(226, 185, 59, 1)',
    'rgba(226, 130, 59, 1)',
    'rgba(235, 87, 87, 1)',
  ]
 

  const jobTitle = jobData?.data?.jobTitle
  

  const {
    interviewLink,
    interviewers,
    interviewType,
    interviewRound,
    interviewOption,
    interviewSchedule,
    interviews: applicantsData,
  } = data

  const InterviewOptionName = (option: string) => {
    return option.toLowerCase() == 'option 1'
      ? 'Instant Schedule'
      : option.toLowerCase() == 'option 2'
      ? 'Flight Seat Slot'
      : 'Candidate Self Schedule'
  }

  const handleCopyLink = () => {
    copy(interviewLink || '')
  }
  
  const interviewDateRange = interviewSchedule.map((scheduleData) => scheduleData.interviewDate)
  const sortedDate = interviewDateRange.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const formattedStartDate = dayjs(sortedDate[0]).format('MMM DD, YYYY')
  const formattedEndDate = dayjs(sortedDate[sortedDate.length - 1]).format('MMM DD, YYYY')
  


  

  return (
    <>
      <div className="border-b border-[#EFEFEF] px-6">
        <div
          className={clsx(
            'group grid grid-cols-6 item-start justify-start gap-6 gap-y-5  py-8 shadow-md hover:bg-gray-100 sm:gap-0 sm:px-0 sm:shadow-none sm:hover:bg-transparent'
          )}
        >
          <button onClick={() => setShowInterviewMoreInfoModal(true)} className="flex flex-col pr-6 text-left">
            <p className=" text-sm font-bold">
              {InterviewOptionName(interviewOption)}
            </p>
          </button>
          <p className="text-xs">{interviewRound}</p>

          <div>
            <p className="text-xs">{interviewType}</p>
            <p className="text-xs">{formattedStartDate} - {formattedEndDate}</p>
            {!(interviewLink === '' || interviewLink === null) && (
              <button  onClick={handleCopyLink} className="text-xs text-left text-[#2F80ED] underline">
                Interview Link
              </button>
            )}
          </div>

          {/* interviewee section */}
          <div className="flex items-center justify-start">
            {applicantsData &&
              applicantsData.map((applicantData, index) => {
                return (
                  <button
                    onClick={() => {
                      if (applicantsData.length < 4) {
                        handleApplicantClick(applicantData?.userId)
                        setGetInterviewId(applicantsData[index].interviewId)
                      }
                    }}
                    key={index}
                    className="flex items-center transition duration-100 hover:scale-110 hover:animate-bounce"
                  >
                    {applicantData.photoUrl ? (
                      <img
                        src={applicantData.photoUrl}
                        alt="applicants"
                        className={clsx(
                          'h-[45px] w-[45px] min-w-[45px] rounded-full border-2 border-white object-cover hover:scale-105',
                          index > 3 ? 'hidden' : 'block',
                          index > 0 && '-ml-2'
                        )}
                      />
                    ) : (
                      <p
                        className={clsx(
                          'flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full border-2 border-white object-cover hover:scale-105',
                          index > 3 ? 'hidden' : 'block',
                          index > 0 && '-ml-2'
                        )}
                        style={{
                          backgroundColor:
                            colorSet[(index + 1 - 1) % colorSet.length],
                        }}
                      >
                        {applicantData.firstName[0]}
                      </p>
                    )}
                  </button>
                )
              })}
            {applicantsData.length > 4 && (
              <button
                onClick={() => {
                  if (applicantsData.length > 4) {
                    setOpenViewIntervieweeModal(true)
                  }
                }}
                className="-ml-2 flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full border-2 border-white bg-black text-sm font-semibold text-white"
              >
                +{applicantsData.length - 4}
              </button>
            )}
          </div>

          {/* interviewers */}
          <div className="flex items-center">
            {interviewers &&
              interviewers.map((interviewer, index) => {
                const startIndex = interviewer.name.indexOf('(')
                const endIndex = interviewer.name.indexOf(')')
                const name = interviewer.name.slice(0, startIndex).trim()
                const email = interviewer.name.slice(startIndex + 1, endIndex)

                // Create the object with name and email properties
                const result = {
                  name,
                  email,
                }

                return (
                  <div key={index} className="flex items-center">
                    {interviewer && (
                      <div className="">
                        <p
                          className={clsx(
                            'interviewer_avatar flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full border-2 capitalize border-white text-sm font-semibold text-black',
                            index > 3 ? 'hidden' : 'block',
                            index > 0 && '-ml-2'
                          )}
                          style={{
                            backgroundColor:
                              colorSet[(index + 1 - 1) % colorSet.length],
                          }}
                        >
                          {interviewer?.name[0]}
                        </p>

                        {/* interviewer moreInfo */}

                        <div className="interviewer_info absolute ml-[-30px] mt-3 flex items-start gap-3 rounded-md bg-black text-xs text-white">
                          <span className="arrow-icon"></span>
                          <p
                            className={clsx(
                              'interviewer_avatar flex h-[35px] capitalize w-[35px] min-w-[35px] items-center justify-center rounded-full text-sm font-semibold text-black'
                            )}
                            style={{
                              backgroundColor:
                                colorSet[(index + 1 - 1) % colorSet.length],
                            }}
                          >
                            {interviewer?.name[0]}
                          </p>
                          <div>
                            <p className="font-bold">{result.name}</p>
                            <p className="mt-1">{result.email}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            {interviewers.length > 4 && (
              <button className="-ml-2 flex h-[45px] w-[45px] min-w-[45px] items-center justify-start rounded-full border-2 border-white bg-black text-sm font-semibold text-white">
                +{interviewers.length - 4}
              </button>
            )}
          </div>
          <div className="  ml-auto -mr-7 flex w-fit items-center justify-end gap-3 rounded-md bg-white py-2 px-4 md:mr-0 md:w-[25%] md:bg-transparent md:py-0 md:px-0">
            {candidateApplicationDetailIcons.map((icon) => (
              <Tooltip title={icon.name} key={icon.id}>
                <button
                  className=" min-h-[30px] min-w-[30px]"
                  onClick={() => {
                    return icon.id == 2
                      ? setShowInterviewMoreInfoModal(true)
                      : ''
                      // : icon.id == 3
                      // ? setReschdeduleInterview(true)
                      // : icon.id == 4
                      // ? setOpenDisqualifiedApplicantModal(true)
                      // : icon.id == 5
                      // ? setOpenHiredApplicantModal(true)
                      // : icon.id == 6
                  }}
                >
                  <Image
                    key={icon.id}
                    src={icon.image}
                    alt="functions"
                    width={30}
                    height={30}
                  />
                </button>
              </Tooltip>
            ))}
            {/* <BsThreeDotsVertical /> */}
          </div>
          <ViewIntervieweeModal
            jobTitle={jobTitle || ''}
            jobId={id || ''}
            applicantsData={applicantsData}
            openViewIntervieweeModal={openViewIntervieweeModal}
            setOpenViewIntervieweeModal={setOpenViewIntervieweeModal}
          />
          <InterviewDetails
            data={applicantsData}
            jobTitle={jobTitle}
            showInterviewDetails={showInterviewDetails}
            setShowInterviewDetails={setShowInterviewDetails}
            userId={applicantId || ''}
            jobId={id || ''}
            getInterviewId={getInterviewId || ''}
          />
          <InterviewMoreInfoModal
            jobTitle={jobTitle || ''}
            jobId={id || ''}
            data={data}
            openInterviewMoreInfoModal={openInterviewMoreInfoModal}
            setShowInterviewMoreInfoModal={setShowInterviewMoreInfoModal}
          />
          {/*
            <ReschdeduleInterview
              data={data}
              jobTitle={jobTitle}
              reschdeduleInterview={reschdeduleInterview}
              setReschdeduleInterview={setReschdeduleInterview}
            />
            <HiredApplicantModal
              data={data}
              jobTitle={jobTitle}
              setOpenHiredApplicantModal={setOpenHiredApplicantModal}
              openHiredApplicantModal={openHiredApplicantModal}
            />
            <DisqualifiedApplicantModal
              data={data}
              openDisqualifiedApplicantModal={openDisqualifiedApplicantModal}
              setOpenDisqualifiedApplicantModal={
                setOpenDisqualifiedApplicantModal
              }
            /> */}
        </div>
        <style>
          {`
          .interviewer_info{
            width:0;
            height:0;
          }
          .interviewer_avatar:hover + .interviewer_info {
            height: 60px;
            width: 220px;
            opacity: 1;
            transition: height 0.3s ease, width 0.3s ease, opacity 0.5s ease;
            padding: 10px 12px;
          }
          .interviewer_info {
            height: 0;
            width: 0;
            opacity: 0;
            overflow: hidden;
            transition: opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
          }
          .interviewer_avatar:hover + .interviewer_info p{
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.8s ease, transform 0.6s ease;
          }
          
          .interviewer_info p{
            opacity: 0;
            transform: translateY(20px);
            transition: opacity 0.3s ease, transform 0.3s ease;
          }

          .arrow-icon {
            width: 10px;
            height: 10px;
           margin-left: 20px;
            margin-top: -15px;
            background-color: black;
            z-index: 1000;
            clip-path: polygon(100% 50%, 0 0, 0 100%);
            position: absolute;
            transform: rotate(30deg);
          }
          
        `}
        </style>
      </div>
    </>
  )
}
