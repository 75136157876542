import { Button } from '@/components'
import Image from 'next/image'
import Link from 'next/link'

export const RecruiterOnboardingPublishJob = () => {
  return (
    <div className="pb-10">
      <div className="m-auto  text-center">
        <p>Step 3/4</p>
        <h2 className="text-[40px]">Publish your first job</h2>
        <p className="">
          Tell us about your open role. We’ll help you reach Scout4Job users{' '}
          <br /> who are looking for a new job now.
        </p>
      </div>

      <div className="mt-16 flex">
        <div className="box-border flex-1 border-r pr-20">
          <div>
            <p className="font-bold">
              <span className="mt-4 text-base  text-primary">*</span> Job title
            </p>
            <div className="mt-4 flex h-[45px] items-center gap-2 rounded-md border border-[#E6E5E5] bg-transparent px-4">
              <input
                type="text"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="e.g. Product Designer, H.R Assistant"
              />
            </div>
          </div>
          <div>
            <p className="mt-8 text-base font-bold">
              <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
              Primary role
            </p>
            <div className="mt-4 flex h-[45px] items-center gap-2 rounded-md border border-[#E6E5E5] bg-transparent px-4">
              <input
                type="text"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="e.g. Product Designer, H.R Assistant"
              />
            </div>
          </div>
          <div>
            <p className="mt-8 text-base font-bold">
              <span className="text-primary">*</span> Primary role
            </p>
            <div className="mt-4 flex h-[45px] items-center gap-2 rounded-md border border-[#E6E5E5] bg-transparent px-4">
              <input
                type="text"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="e.g. Product Designer, H.R Assistant"
              />
            </div>
          </div>

          <p className="mt-8 text-base font-bold">
            <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
            Work level
          </p>
          <select
            name=""
            className="mt-4 h-[45px] w-full rounded-md border border-[#E6E5E5] bg-transparent px-4 text-sm text-[#6BF44] outline-none"
          >
            <option value="">Senior</option>
            <option value="">mid-senior</option>
            <option value="">Junior</option>
            <option value="">intern</option>
          </select>
          <div className="mt-8 ">
            <p className="text-base font-bold">
              <span className="text-xl text-primary">*</span> Work location
            </p>
            <p className="mt-4 text-sm text-[#A6A6A6]">
              Tip: You can select more than one
            </p>
            <div className="mt-2 flex h-[45px] items-center gap-2 rounded-md border border-[#E6E5E5] bg-transparent px-4">
              <input
                type="text"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="eg: Lagos"
              />
              <Image
                src="/assets/globalsearch.png"
                width={20}
                height={20}
                alt="setUpProfile"
              />
            </div>
            <p className="mt-2 text-sm text-[#A6A6A6]">
              Where are you hiring for this role?
            </p>
          </div>

          <div className="mt-8 ">
            <p className="text-base font-bold">
              <span className="text-xl text-primary">*</span> Hiring regions for
              remote workers
            </p>
            <div className="mt-2 flex h-[45px] items-center gap-2 rounded-md border border-[#E6E5E5] bg-transparent px-4">
              <input
                type="text"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="eg: Europe"
              />
              <Image
                src="/assets/globalsearch.png"
                width={20}
                height={20}
                alt="setUpProfile"
              />
            </div>
            <div className="flex items-center">
              <input
                id="Full-time Employee"
                className="w-10px"
                type="radio"
                name="accept-anywhere"
              />
               {' '}
              <label className="text-sm" htmlFor="Full-time Employee">
                Accept from anywhere in the world
              </label>
            </div>

            <p className="mt-8 text-base font-bold">
              <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
              Currency
            </p>
            <select
              name=""
              className="mt-4 h-[45px] w-full rounded-md border border-[#E6E5E5] bg-transparent px-4 text-sm text-[#6BF44] outline-none"
            >
              <option value="">Nigerian Naira</option>
              <option value="">US Dollars</option>
              <option value="">Pounds</option>
            </select>
          </div>
        </div>

        <div className="flex-1 pl-20">
          <p className="text-base font-bold ">
            <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
            Types of position
          </p>
          <select
            name=""
            className="mt-4 h-[45px] w-full rounded-md border border-[#E6E5E5] px-4 text-sm text-[#6BF44]"
            placeholder="Select years of experience"
          >
            <option value="">Full time</option>
            <option value="">Part time</option>
            <option value="">Contract based</option>
          </select>
          <p className="mt-8 text-base font-bold">
            <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
            Work experience
          </p>
          <select
            name=""
            className="mt-4 h-[45px] w-full rounded-md border border-[#E6E5E5] px-4 text-sm text-[#6BF44]"
          >
            <option value="">1 - 2 years</option>
            <option value="">2- 5 years</option>
            <option value="">5+ years</option>
          </select>

          <p className="mt-8 text-base font-bold">
            <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
            Additiona roles(s)
          </p>
          <select
            name=""
            className="mt-4 h-[45px] w-full rounded-md border border-[#E6E5E5] px-4 text-sm text-[#6BF44]"
            placeholder="e.g Software Engineer"
          >
            <option value="">Software Engineer</option>
            <option value="">Frontend engineer</option>
            <option value="">Backend engineer</option>
            <option value="">product designer</option>
          </select>

          <p className="mt-10 font-bold">
            <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
            What is your remote policy for this job?
          </p>
          <div className="mt-4 flex items-center gap-[4rem]">
            <div className="flex items-center gap-1">
              <input id="Intern" type="radio" name="remote-policy" /> {' '}
              <label className="text-sm" htmlFor="Intern">
                Fully remote
              </label>
            </div>
            <div className="flex items-center gap-1">
              <input id="Co-founder" type="radio" name="remote-policy" /> {' '}
              <label className="text-sm" htmlFor="Co-founder">
                Hybrid
              </label>
            </div>
            <div className="flex items-center gap-1">
              <input id="Co-founder" type="radio" name="remote-policy" /> {' '}
              <label className="text-sm" htmlFor="Co-founder">
                Onsite
              </label>
            </div>
          </div>

          <p className="mt-10 font-bold">
            <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
            What is your remote culture?
          </p>
          <div className=" mt-4 gap-[4rem]">
            <div className="flex items-center gap-1">
              <input id="Intern" type="radio" name="remote-culture" /> {' '}
              <label className="text-sm" htmlFor="Intern">
                We mostly have an in-person set-up
              </label>
            </div>
            <div className="mt-2 flex items-center gap-1">
              <input id="Co-founder" type="radio" name="remote-culture" /> {' '}
              <label className="text-sm" htmlFor="Co-founder">
                We are mostly remote or distributed
              </label>
            </div>
          </div>

          <p className="mt-10 font-bold">
            <span className="mt-4 text-base font-bold text-primary">*</span>{' '}
            What rank do you currently play
          </p>
          <div className="flex items-center gap-4">
            <div className="mt-2 flex h-[45px] items-center gap-2 rounded-md border border-[#E6E5E5] bg-transparent px-1">
              <p className="flex h-[34px] w-[45px] items-center justify-center bg-[#EFEFEF]">
                N
              </p>
              <input
                type="number"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="200,000"
              />
            </div>
            <p>-</p>
            <div className="mt-2 flex h-[45px] items-center gap-2 rounded-md border border-[#E6E5E5] bg-transparent px-1">
              <p className="flex h-[34px] w-[45px] items-center justify-center bg-[#EFEFEF]">
                N
              </p>
              <input
                type="number"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="700,000"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="mt-10 text-base font-bold">
          <span className="text-xl text-primary">*</span> Describe job
          requirements and roles
        </p>
        <textarea
          className="mt-4 h-40 w-full resize-none rounded-md border border-[#A6A6A6] bg-transparent px-4 py-4 text-sm outline-none"
          placeholder="Describe the responsibilities of the position. You can always change this later"
        ></textarea>
      </div>
      <div className="mx-auto mt-10 flex max-w-sm justify-center">
        <Link href="/recruiter/onboarding/4">
          <Button className="w-full" type="submit">
            Post Job
          </Button>
        </Link>
      </div>
    </div>
  )
}
