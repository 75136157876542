import {
  backdropVariant,
  getFormattedDayMonthYear,
  getFormattedHourMinSec,
  InterviewDetailsModalAnim,
  INTERVIEW_TYPE_OPTIONS,
} from '@/utils'
import { Calendar2, Clock } from 'iconsax-react'
import { AnimatePresence, motion } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Control } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'
import dayjs from 'dayjs'
// import clsx from 'clsx'

import { reschdeduleInterviewSchema } from '../../utils'
import {
  Button,
  InputDateField,
  Label,
  SelectField,
  TextAreaField,
} from '@/components'
import { Interview } from '@/features/job-seeker'
import {
  RescheduleInterviewForm,
  RescheduleInterviewPayload,
} from '../../types'
import { useReschedule } from '../../api'

interface reschdeduleInterviewProps {
  reschdeduleInterview: boolean
  setReschdeduleInterview: (value: boolean) => void
  data?: Interview | undefined
  jobTitle?: string | undefined
}

export const ReschdeduleInterview: React.FC<reschdeduleInterviewProps> = ({
  reschdeduleInterview,
  setReschdeduleInterview,
  data,
  // jobTitle,
}) => {
  const ref = React.useRef<HTMLInputElement>(null)

  const mutateReschedule = useReschedule(data?.id as string)

  const {
    firstName,
    lastName,
    phone,
    email,
    // interviewers,
    interviewDate,
    interviewStartTime,
    interviewEndTime,
    interviewLink,
    interviewType,
  } = data || {}

  const startTime = dayjs('2023-03-03' + interviewStartTime).format('hh:mm A')
  const endTime = dayjs('2023-03-03' + interviewEndTime).format('hh:mm A')

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        reschdeduleInterview &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setReschdeduleInterview(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [reschdeduleInterview])

  const {
    register,
    // reset,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RescheduleInterviewForm>({
    defaultValues: {},
    resolver: yupResolver(reschdeduleInterviewSchema),
  })

  const onSubmit = (values: RescheduleInterviewForm) => {
    const {
      interviewEndTime,
      interviewStartTime,
      interviewDate,
      rescheduleReason,
      interviewType,
    } = values

    const payload: RescheduleInterviewPayload = {
      interviewSchedule: [
        {
          interviewDate: getFormattedDayMonthYear(interviewDate),
          interviewStartTime: getFormattedHourMinSec(interviewStartTime),
          interviewEndTime: getFormattedHourMinSec(interviewEndTime),
        },
      ],
      interviewType: interviewType.name,
      rescheduleReason: rescheduleReason,
    }

    mutateReschedule.mutate(payload)
    setReschdeduleInterview(false)
  }

  const rescheduleReasonLimit = watch('rescheduleReason')

  return (
    <AnimatePresence>
      {reschdeduleInterview && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-0 right-0 h-screen w-[85%]  overflow-scroll rounded-lg  bg-white px-6 py-8 md:w-[662px]"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={InterviewDetailsModalAnim}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2  ">
                <p className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-[#E2B93B] bg-opacity-10">
                  <Calendar2 size="18" color="#E2B93B" variant="Bulk" />
                </p>
                <h3 className="text-base font-bold">Reschedule Interview</h3>
              </div>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  className=" rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setReschdeduleInterview(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <div>
              <div>
                <div className="mt-5 flex flex-col gap-4 border-y border-y-[#E5E5E5] py-6 md:flex-row md:items-center md:gap-0 ">
                  <div className="border-b[#E5E5E5] flex  items-center gap-2 border-b pb-10 md:border-b-0 md:pb-0">
                    <div>
                      {data?.photoUrl == '' ? (
                        <div>
                          <p
                            style={{ background: 'black' }}
                            className="-ml-[10px] flex h-[45px] w-[45px] items-center justify-center rounded-full border-[3px] border-white text-sm font-bold"
                          >
                            {data?.firstName} {data?.lastName}
                          </p>
                        </div>
                      ) : (
                        <img
                          src={data?.photoUrl || ''}
                          className="profile-picture h-[45px] w-[45px] rounded-full border-[3px] border-white"
                          alt="applicant"
                        />
                      )}
                    </div>
                    <div className="md:border-r md:border-r-[#E5E5E5] md:pr-10">
                      <p className="text-sm font-bold">
                        {firstName + ' ' + lastName}
                      </p>
                      <p className="text-xs">{email}</p>
                      <p className="text-xs">{phone}</p>
                    </div>
                  </div>
                  <div className="md:pl-10">
                    <div className="mb-1 flex items-center gap-2">
                      <Calendar2 size="16" color="#000" variant="TwoTone" />
                      <p className="text-sm">
                        {interviewDate &&
                          dayjs(interviewDate).format(
                            'dddd D MMM, YYYY'
                          )}
                      </p>
                    </div>
                    <div className="mb-1 flex items-center gap-2">
                      <Clock size="16" color="#000" variant="TwoTone" />
                      <p className="text-sm">
                        {startTime} - {endTime}
                      </p>
                    </div>
                    {interviewLink && (
                      <div className="mb-1 flex items-center gap-2">
                        <Clock size="16" color="#000" variant="TwoTone" />
                        <p className="max-w-[417px] text-sm text-blue-800 underline">
                          {interviewLink}
                        </p>
                      </div>
                    )}
                    <div className="mb-1 flex items-center gap-2">
                      <Clock size="16" color="#000" variant="TwoTone" />
                      <p className="text-sm capitalize">{interviewType}</p>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mt-6 flex flex-col gap-6 md:flex-row">
                    <div>
                      <p className="text-sm font-bold">
                        <span className="text-sm text-primary">*</span>{' '}
                        Interview Date
                      </p>
                      <InputDateField
                        name="interviewDate"
                        className="mt-2"
                        control={control as unknown as Control}
                        placeholder="Interview date"
                        hasError={errors.interviewDate}
                        //maxDate={endDate || new Date()}
                        isRequired
                      />
                    </div>
                    <div>
                      <p className=" text-sm font-bold">
                        <span className="text-sm text-primary">*</span> Start
                        Time
                      </p>
                      <InputDateField
                        name="interviewStartTime"
                        control={control as unknown as Control}
                        placeholder="Start Time"
                        hasError={errors.interviewStartTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="mt-2"
                      />
                    </div>
                    <div>
                      <p className=" text-sm font-bold">
                        <span className="text-sm text-primary">*</span> End Time
                      </p>
                      <InputDateField
                        control={control as unknown as Control}
                        placeholder="End time"
                        hasError={errors.interviewEndTime}
                        name="interviewEndTime"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="mt-2"
                      />
                    </div>
                  </div>
                  <div className="mt-8">
                    <Label id="interviewType" isRequired>
                      Interview Type
                    </Label>
                    <SelectField
                      className="mt-4"
                      control={control as unknown as Control}
                      hasError={errors.interviewType}
                      arr={INTERVIEW_TYPE_OPTIONS}
                      name="interviewType"
                    />
                  </div>
                  <div>
                    <p className="mt-8  text-sm font-bold">
                      Reason for Rescheduling
                    </p>
                    <p className="text-xs">
                      {/* We’ve setup a template for you to use, but feel free to
                      customize to your taste */}
                    </p>
                    <div>
                      <TextAreaField
                        className="txt-sm mt-2"
                        id="rescheduleReason"
                        registration={{ ...register('rescheduleReason') }}
                        hasError={errors.rescheduleReason}
                        value={rescheduleReasonLimit}
                      ></TextAreaField>
                    </div>
                    <Button
                      type="submit"
                      className="mx-auto mt-10 mb-6 w-[365px] min-w-fit"
                    >
                      Reschedule
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
