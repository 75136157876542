import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { fetchData, transformError } from '@/utils';
import { AddFeedbackPayload} from '../types';
import { AxiosError } from 'axios';


export const useSendFeedback = () => {
  return useMutation<Response, AxiosError, AddFeedbackPayload>(
    (payload: AddFeedbackPayload) =>
      fetchData<AddFeedbackPayload>("/user/feedback/add", 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Feedback sent');
      },
      onError: (error) => {
        toast.error(transformError(error));
      },
    }
  );
};