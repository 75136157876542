import { StarSlash } from 'iconsax-react'
import React from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'

interface StarRatingInputProp {
  activeStar: number
  setActiveStar: (value: number) => void
  count: number
}

export const StarRatingInput = ({
  activeStar,
  setActiveStar,
  count,
}: StarRatingInputProp) => {
  // short trick
  // const stars = Array.from({length: count}, (index:number) => <BsStar key={index} />)

  // Internal handle change function
  // const handleChange = (value) => {
  //   onChange(value + 1);
  // }

  return (
    <div>
      <div className="flex items-center gap-4">
        <button type="button" onClick={() => setActiveStar(0)}>
          <StarSlash size="18" color="#A6A6A6" variant="Outline" />
        </button>

        <div className="flex">
          {Array(count)
            .fill(count)
            .map((_, index) => {
              const statusColor =
                activeStar == 0
                  ? 'rgba(25, 25, 25, 0.1)'
                  : activeStar == 5
                  ? 'rgba(47, 128, 237, 1)'
                  : activeStar == 4
                  ? 'rgba(39, 174, 96, 1)'
                  : activeStar == 3
                  ? 'rgba(226, 185, 59, 1)'
                  : activeStar == 2
                  ? 'rgba(226, 130, 59, 1)'
                  : 'rgba(235, 87, 87, 1)'

              return activeStar >= index + 1 ? (
                <button type="button" onClick={() => setActiveStar(index + 1)}>
                  <AiFillStar
                    className="text-[18px]"
                    key={index}
                    // onMouseOver={() => !number && setHoverStar(index + 1)}
                    // onMouseLeave={() => setHoverStar(undefined)}
                    style={{ color: statusColor }}
                  />
                </button>
              ) : (
                <button type="button" onClick={() => setActiveStar(index + 1)}>
                  <AiOutlineStar
                    key={index}
                    className="text-[18px]"
                    // onMouseOver={() => !number && setHoverStar(index + 1)}
                    // onMouseLeave={() => setHoverStar(undefined)}
                    style={{ color: '#A6A6A6' }}
                  />
                </button>
              )
            })}
        </div>
      </div>
      {/* {value} */}
    </div>
  )
}
