import {
  AddEducation,
  AddFeedbackModal,
  FAQModal,
  ManageExperience,
  NavDashboard,
  SkillModal,
} from '@/components'
import { FullPageLoader } from '@/features/auth'
import {
  ProfileStatus,
  useEducation,
  useExperiences,
  useResume,
  useSkills,
} from '@/features/job-seeker'
import { motion } from 'framer-motion'
import { useUserInfo } from '@/hooks'
import { useState } from 'react'
import Link from 'next/link'
import { ClipboardTick } from 'iconsax-react'

interface JobSeekerDashboardLayoutProps {
  children: React.ReactNode
}

export const JobSeekerDashboardLayout: React.FC<
  JobSeekerDashboardLayoutProps
> = ({ children }) => {
  const [openAddExperience, setOpenAddExperience] = useState(false)
  const [openAddEducation, setOpenAddEducation] = useState(false)
  const [skillOpen, setSkillOpen] = useState(false)
  const [openFAQ, setOpenFAQ] = useState(false)
  const { isLoading, user } = useUserInfo()
  const { resumeUrl } = user || {}

  const { isLoading: isLoadingEducation, data: educationData } = useEducation()
  const education = educationData?.data || []
  const { isLoading: isLoadingExperiences, data: experienceData } =
    useExperiences()
  const experience = experienceData?.data || []
  const { isLoading: isLoadingSkills, data: skillsData } = useSkills()
  const skills = skillsData?.data || []
  const { open } = useResume()
  const [openAddFeedbackModal, setOpenAddFeedbackModal] = useState(false)

  const handleOpenExperienceModal = () => setOpenAddExperience(true)
  const handleOpenEducationModal = () => setOpenAddEducation(true)
  const handleOpenSkillsModal = () => setSkillOpen(true)

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'linear', duration: 0.1 }}
      className=''
    >
      <NavDashboard />
     
      <div className="h-screen bg-[#EFEFEF] overflow-x-hidden relative">
      <button onClick={() => setOpenAddFeedbackModal(true)} className="absolute right-[-70px] top-[50%] flex rotate-[270deg] flex-col items-center justify-center rounded-md bg-black px-6 py-2 transition-all hover:right-[-40px] z-[200]">
        <div className="flex items-center gap-2 ">
          <ClipboardTick size="14" color="white" className="" variant="Bulk" />
          <p className="font-[500] text-white">Feedback</p>
        </div>
        <p className="mt-2 w-fit rounded-md bg-primary px-2 py-1 text-[10px] text-white">
          Click Me
        </p>
      </button>
        <div className="px-5 pt-[100px] md:px-[50px] md:pt-[140px] ">
          {isLoading ||
          isLoadingEducation ||
          isLoadingExperiences ||
          isLoadingSkills ? (
            <FullPageLoader />
          ) : resumeUrl === null ||
            experience.length <= 0 ||
            education.length <= 0 ||
            skills.length <= 0 ? (
            <div className="mx-auto mb-5 w-full max-w-[1500px] rounded-lg bg-[#191919] px-6 py-4 text-white">
              <div className="flex items-center justify-between">
                <h3 className="text-[15px] md:text-2xl">
                  Complete Account Setup
                </h3>
                <div className="flex space-x-5">
                  <Link
                    href="/job-seeker/dashboard/profile"
                    className="text-xs text-[#27AE60] md:text-sm"
                  >
                    View Profile
                  </Link>
                  {/* <button onClick={() =>setCompleteProfileStatus(false) } className="text-sm text-[#EB5757]">Dismiss</button> */}
                </div>
              </div>
              <p className="text-left text-xs text-[#A6A6A6] md:text-sm">
                Recruiters cannot find your profile because it lacks critical
                information.
              </p>
              <div className="mt-4 flex flex-wrap gap-2 lg:flex-nowrap">
                <ProfileStatus
                  icon="/assets/profile-status/profile-icon.svg"
                  profileStepName="Profile Details"
                />
                <ProfileStatus
                  icon="/assets/profile-status/Job-icon.svg"
                  profileStepName="Job Preferences"
                />
                <ProfileStatus
                  icon={
                    resumeUrl === null
                      ? '/assets/profile-status/Cv-icon-gray.svg'
                      : '/assets/profile-status/Cv-icon-green.svg'
                  }
                  profileStepName="Resume/CV"
                  disabled={false}
                  onClick={open}
                />
                <ProfileStatus
                  icon={
                    experience && experience.length > 0
                      ? '/assets/profile-status/experience-green.svg'
                      : '/assets/profile-status/experience-gray.svg'
                  }
                  profileStepName="Experience"
                  disabled={false}
                  onClick={handleOpenExperienceModal}
                />
                <ProfileStatus
                  icon={
                    education && education.length > 0
                      ? '/assets/profile-status/education-icon-green.svg'
                      : '/assets/profile-status/education-icon-gray.svg'
                  }
                  profileStepName="Education"
                  disabled={false}
                  onClick={handleOpenEducationModal}
                />
                <ProfileStatus
                  icon={
                    skills && skills.length > 0
                      ? '/assets/profile-status/skills-icon-green.svg'
                      : '/assets/profile-status/skills-icon-gray.svg'
                  }
                  profileStepName="Skills"
                  disabled={false}
                  onClick={handleOpenSkillsModal}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {children}
        <button
          onClick={() => setOpenFAQ(true)}
          className="overflow fixed bottom-[10px] right-[40px] flex h-12 w-12  z-[60] cursor-pointer items-center justify-center rounded-full bg-primary text-lg text-white"
        >
          ?
        </button>
      </div>
      <AddFeedbackModal
        openAddFeedbackModal={openAddFeedbackModal}
        setOpenAddFeedbackModal={setOpenAddFeedbackModal}
      />
      <AddEducation
        setOpenAddEducation={setOpenAddEducation}
        openAddEducation={openAddEducation}
      />
      <ManageExperience
        setOpenAddExperience={setOpenAddExperience}
        OpenAddExperience={openAddExperience}
      />
      <SkillModal skillOpen={skillOpen} setSkillOpen={setSkillOpen} />
      <FAQModal openFAQ={openFAQ} setOpenFAQ={setOpenFAQ} />
    </motion.div>
  )
}
