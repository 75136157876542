import { Modal } from '@/components'
import React from 'react'

interface ViewPictureModalProps {
  showDialog: boolean
  imgUrl: string
  handleCloseViewPicture: () => void
  photoRef: React.RefObject<HTMLDivElement>
}

export const ViewPictureModal: React.FC<ViewPictureModalProps> = ({
  handleCloseViewPicture,
  showDialog,
  imgUrl,
  photoRef
}) => {
  return (
    <Modal
      className="mt-48"
      variant="middle"
      showDialog={showDialog}
      closeModal={handleCloseViewPicture}
    >
      <div ref={photoRef} className="mx-auto flex items-center justify-center w-[600px] max-h-[600px] overflow-hidden my-auto bg-transparent px-8">
        <img className='' src={imgUrl} alt="job-seeker" />
      </div>
    </Modal>
  )
}
