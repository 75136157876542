import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import dayjs from 'dayjs'

dayjs.extend(relativeTime)

import { ExternalJob } from '@/types'
import { useClickExternalJobs } from '@/api'

interface ExternalJobCardProps {
  job: ExternalJob
}

export const ExternalJobCard: React.FC<ExternalJobCardProps> = ({ job }) => {
  const { imageUrl, link } = job || {}
  

const mutationClickJobs = useClickExternalJobs(job?.id || '')
  const handleClickExternalJob = () => {
    mutationClickJobs.mutate()
  }

  return (
    <>
      {/* mobile */}
      <a
        href={link}
        onClick={handleClickExternalJob}
        target="_blank"
        rel="noreferrer"
        className="h-full cursor-pointer rounded-md border border-gray-100 bg-white bg-cover bg-no-repeat shadow-lg transition duration-200 hover:shadow-sm sm:p-6 xl:hidden"
      >
        <img className='w-full' src={imageUrl} alt="eternal job" />
      </a>

      {/* tablet and higher */}
      <a
      onClick={handleClickExternalJob}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
        href={link}
        target="_blank"
        rel="noreferrer"
        className="hidden h-full cursor-pointer rounded-md border border-gray-100 bg-white bg-cover bg-no-repeat p-3 shadow-lg transition duration-200 hover:shadow-sm xl:block sm:p-6"
      ></a>
    </>
  )
}
