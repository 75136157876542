import React from 'react'

interface ToggleProp {
  isToggled: boolean
  onToggle: () => void
}

export const ToggleSwitch = ({ isToggled, onToggle }: ToggleProp) => {
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="switch" />

      <style jsx>
        {`
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 10px;
  }
  .toggle-switch input[type="checkbox"] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #bfbdbd;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: -5px;
    width: 21px;
    height: 21px;
    background-color: #16BF44;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: #16BF44;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: #bfbdbd;
  }
        `}
      </style>
    </label>
  )
}
