import { createContext } from "react";
import { companyIDStorage } from "./auth";



interface CompanyIdContextProps {
    contextCompanyID: string
 setContextCompanyID: (c: string)=> void
}
const companyID = companyIDStorage.getCompanyID()



export const CompanyIdContext = createContext<CompanyIdContextProps>({
    contextCompanyID: companyID,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setContextCompanyID: () => {}
});