import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { fetchData, userKeys } from '@/utils'
import { BasicInfo } from '../types'

export const useEditBasicInfo = (userId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: BasicInfo) =>
      fetchData<BasicInfo>(`/user/basic-info/edit/${userId}`, 'PUT', payload),
    {
      onSuccess: () => {
        toast.success('Your information has been successfully updated.')
        queryClient.invalidateQueries({
          queryKey: userKeys.all,
        })
      },
      onError: () => {
        toast.error('Information not updated.')
      },
    }
  )
}
