import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { fetchData, interviewKeys } from '@/utils'
import { RescheduleInterviewPayload } from '../types'

export const useReschedule = (interviewId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: RescheduleInterviewPayload) =>
      fetchData(`/company/applicants/reschedule-interview/${interviewId}`, 'POST', payload),
    {
      onSuccess: () => {
          toast.success('rescheduled successfully')
          queryClient.invalidateQueries({
            queryKey: interviewKeys.all,
          });
      },
      onError: () => {
        toast.error('reschedule failed')
      },
    }
  )
}
