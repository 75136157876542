import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { ChangeApplicantStatusPayload } from '../types'
import { companyKeys, fetchData, jobApplicantKeys } from '@/utils'
import { useCompanyId } from '../hooks'

export const useChangeApplicantStatus = (jobId: string) => {
  const companyID =  useCompanyId()
  const queryClient = useQueryClient()
  return useMutation(
    (payload: ChangeApplicantStatusPayload) => fetchData(`/company/applicants/change-status/${jobId}`, 'PUT', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: jobApplicantKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: companyKeys.list(companyID),
        })

      },
      onError: () => {
        toast.error('Status not changed')
      },
    }
  )
}
