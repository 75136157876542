import { useQuery } from '@tanstack/react-query'
import { interviewKeys, fetchData } from '@/utils'
import {  NewInterview } from '@/features/job-seeker'
import { useCompanyId } from '../hooks'

interface CompanyInterviewResponse {
  status: string
  message: string
  data: NewInterview[]
}

export const useCompanyInterviews = () => {

  const companyId =  useCompanyId()

  return useQuery<CompanyInterviewResponse>({
    queryKey: interviewKeys.list(companyId),
    queryFn: async () => fetchData(`/company/applicants/interview-schedules/${companyId}`),
    enabled: !!companyId
  })
}
