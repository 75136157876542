import { AnimatePresence, motion } from 'framer-motion'
import React, { Dispatch, SetStateAction } from 'react'

import { searchModal } from '@/utils'
import { BsXLg } from 'react-icons/bs'
import { Spinner } from '@/components'

interface SelectedApplicantsActionModalProps {
  showSelectedApplicantsAction: boolean
  selectedItems: number
  setSelectedItems: Dispatch<
    SetStateAction<{ userId: string; interviewId: string }[]>
  >
  approvalLoading?: boolean
  isApproveStage: boolean
  handleShowConfirmDiscardModal?: () => void
  handleShowConfirmApprovalModal?: () => void
  handleShowMembersModal?:() => void 
  handleScheduleNewInterview?: () => void
}

export const SelectedApplicantsActionModal: React.FC<
  SelectedApplicantsActionModalProps
> = ({
  showSelectedApplicantsAction,
  selectedItems,
  setSelectedItems,
  approvalLoading,
  isApproveStage,
  handleShowConfirmApprovalModal,
  handleShowConfirmDiscardModal,
  handleShowMembersModal,
  handleScheduleNewInterview
}) => {
  return (
    <AnimatePresence>
      {showSelectedApplicantsAction && (
        <motion.div
          className="scrollbar-hide absolute bottom-[5%] left-[50%] overflow-scroll rounded-lg bg-[#191919] px-1 py-2"
          initial="initial"
          animate="show"
          exit="exit"
          variants={searchModal}
        >
          <div className="mx-auto flex h-[45px] w-[838px] max-w-[838px] items-center justify-between border-r-[0] bg-transparent px-2  text-white ">
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  setSelectedItems([])
                }}
                className="text-xs text-white"
              >
                <BsXLg />
              </button>
              <p className="rounded-sm ml-6 bg-[#2B2B2B] px-3 py-[1.5px] text-sm font-semibold text-white">
                {selectedItems}
              </p>
              <p className="text-sm">Selected</p>
            </div>
            <div className="flex items-center gap-6">
              <button onClick={handleScheduleNewInterview} className="rounded-md bg-white px-4 py-2 text-xs font-semibold text-black">
                Schedule new Interview
              </button>
              <button onClick={handleShowConfirmDiscardModal} className="rounded-md bg-[#EB5757] px-4 py-2 text-xs font-semibold">
                Discard Candidate
              </button>


              {!isApproveStage && (
                <button
                  className="flex items-center gap-2 rounded-md bg-primary px-4 py-2 text-xs font-semibold"
                  onClick={handleShowMembersModal}
                >
                  {approvalLoading ? (
                    <p>Fowarding</p>
                  ) : (
                    <p>Forward for Approval</p>
                  )}
                  {approvalLoading && (
                    <Spinner size="md" className="text-current" />
                  )}
                </button>
              )}


              {isApproveStage && (
                <button
                  className="flex items-center gap-2 rounded-md bg-primary px-4 py-2 text-xs font-semibold"
                  onClick={handleShowConfirmApprovalModal}
                >
                  {approvalLoading ? <p>Approving</p> : <p>Approve</p>}
                  {approvalLoading && (
                    <Spinner size="md" className="text-current" />
                  )}
                </button>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

// ;<Button
//   disabled={mutationApplyToAJob.isLoading}
//   isLoading={mutationApplyToAJob.isLoading}
//   type="submit"
// >
//   {mutationApplyToAJob.isLoading ? 'Applying...' : 'Apply'}
// </Button>
