import React from 'react'
import { NavType } from './modals'
import clsx from 'clsx'
import { BsXLg } from 'react-icons/bs'

interface InterviewNavProp {
  setActiveNavId: React.Dispatch<React.SetStateAction<string>>
  activeNavId: string
  setShowInterviewDetails: (value: boolean) => void
  nav: NavType[],
  showInterviewDetails?:boolean
  showCancelButton: boolean
}

export const InterviewNav = ({
  setActiveNavId,
  activeNavId,
  nav,
  showCancelButton,
  setShowInterviewDetails,
}: InterviewNavProp) => {
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="sticky top-0 ml-10 flex space-x-[100px] text-xs">
          {nav.map((title) => {
            return (
              <button
                onClick={() => setActiveNavId(title.id)}
                className={clsx(
                  'buttonNav items-center bg-transparent',
                  title.id === activeNavId ? 'active' : ''
                )}
                key={title.id}
              >
                {title.name}
              </button>
            )
          })}
        </div>

       { showCancelButton && <button
          onClick={() => setShowInterviewDetails(false)}
          className=" rounded-full bg-gray-200 p-2 text-[9px] mr-6 mb-2"
        >
          <BsXLg />
        </button>}
      </div>
      <style>
        {`
       .buttonNav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        transition: all 200ms ease-in-out;
       }
       .buttonNav:hover {
        font-weight: 700;
        transition: all 200ms ease-in-out;
       }
       .buttonNav:after {
        content: '';
        width: 0%;
        border-radius: 5px 5px 0  0 ;
        border-bottom: 4px solid #16bf44;
        transition: all 200ms ease-in-out;
      }
      .buttonNav:hover:after {
        width: 150%;
      }
      .active:after {
        content: '';
        width: 150%;
        border-bottom: 4px solid #16bf44;
        transition: all 200ms ease-in-out;
      }
      .active {
        font-weight: 700;
      }
        `}
      </style>
    </>
  )
}
