import { useQuery } from '@tanstack/react-query'

import { fetchData, allJobsForApprovalKeys } from '@/utils'
import { ToBeApprovedApplicantsData,  } from '@/features/job-seeker'
// import { companyIDStorage } from '@/features/auth'

interface ToBeApprovedApplicantsResponse {
  status: string
  message: string
  data: ToBeApprovedApplicantsData[]
}

export const useGetToBeApprovedApplicants = (id: string) => {
  return useQuery<ToBeApprovedApplicantsResponse>({
    queryKey: allJobsForApprovalKeys.detail(id),
    queryFn: async () => fetchData(`company/applicants/applicant-approve-list/${id}`),
  })
}
