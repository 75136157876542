
import { TrainingData, } from "@/types";
import { trainingKeys, fetchData } from "@/utils";
import { useQuery } from "@tanstack/react-query";


interface BusinessAdsResponseProps {
    status: string;
    message: string;
    data: TrainingData[];
}

export const useFetchTrainings = () => {
    return useQuery<BusinessAdsResponseProps>({
        queryKey: trainingKeys.lists(),
        queryFn: async () => fetchData('/common/display-trainings') 
    });
}