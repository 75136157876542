import { useQuery } from '@tanstack/react-query'

import { fetchData, allJobsForApprovalKeys } from '@/utils'
import { AllJobsForApprovalData,  } from '@/features/job-seeker'
// import { companyIDStorage } from '@/features/auth'
import { useCompanyId } from '../hooks'

interface AllJobsForApprovalResponse {
  status: string
  message: string
  data: AllJobsForApprovalData[]
}

export const useAllApprovalJobs = () => {
  const companyId =  useCompanyId()
  return useQuery<AllJobsForApprovalResponse>({
    queryKey: allJobsForApprovalKeys.list(companyId),
    queryFn: async () => fetchData(`/company/applicants/approval-list/${companyId}`),
    enabled: !!companyId
  })
}
