import { BsThreeDotsVertical } from 'react-icons/bs'
import { useComponentVisible } from '@/hooks'
import React from 'react'
import clsx from 'clsx'

import { HiredApplicantModal, ModalConfirmHire } from './modals'
import { JobApplicant } from '@/features/job-seeker'
import { ApplicantOptionGeneral } from '@/components'
import { useHireApplicants } from '../api'
import { useRouter } from 'next/router'

interface HiredProps {
  applicantInfo: JobApplicant
  formattedDateApplied: string
  activeFilter: number
  openConfirmDisqualifyModal: boolean
  openOfferLetterModal: boolean
  setOpenOfferLetterModal: (value: boolean) => void
  openDiscardEmployee: boolean
  setOpenDiscardEmployee: (value: boolean) => void
  index: number
  handleGetApplicantId: (value: string) => void
  handleSendOfferModal: () => void
  handleViewPicture: (photoUrl: string) => void
  jobTitle: string
}

export const OfferCard = ({
  applicantInfo,
  activeFilter,
  handleSendOfferModal,
  openOfferLetterModal,
  setOpenOfferLetterModal,
  openDiscardEmployee,
  setOpenDiscardEmployee,
  handleGetApplicantId,
  handleViewPicture,
  index,
  jobTitle,
}: HiredProps) => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible()
  const [confirmModal, setConfirmModal] = React.useState(false)
  const [openHiredApplicantModal, setOpenHiredApplicantModal] =
    React.useState(false)
  const applicantName = applicantInfo?.firstName + ' ' + applicantInfo?.lastName
  const mutateHireApplicant = useHireApplicants()

  const router = useRouter()
  const jobId = router.query.id
  const userId = applicantInfo.userId || ''

  const hireStatusFromData = applicantInfo.hireStatus
  const offerStatusFromData = applicantInfo.offerStatus

  const handleHireClick = () => {
    const payload = {
      jobId: jobId,
      userId: userId,
    }
    mutateHireApplicant.mutate(payload, {
      onSuccess: () => {
        setOpenHiredApplicantModal(true)
      },
    })
  }

  return (
    <div className=" flex flex-col items-center" key={index}>
      <div className="absolute top-2 left-4 h-5 w-5 rounded-full bg-[#2F80ED]"></div>
      <button
        onClick={() => {
          setIsComponentVisible(!isComponentVisible)
          handleGetApplicantId(applicantInfo?.userId)
        }}
      >
        <BsThreeDotsVertical className="absolute top-2 right-4 text-gray-250 " />
      </button>
      {applicantInfo.photoUrl ? (
        <button onClick={() => handleViewPicture(applicantInfo.photoUrl || '')}>
          <img
            src={applicantInfo.photoUrl}
            alt="setUpProfile"
            className="profile-picture mb-2 h-[60px] w-[60px]"
          />
        </button>
      ) : (
        <div>
          <p className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-black text-2xl font-[600] text-white">
            {applicantInfo.firstName[0]}
          </p>
        </div>
      )}

      <p className="mt-3 text-center text-lg font-bold">
        {applicantInfo.firstName} {applicantInfo.lastName}
      </p>
      <p className="mt-1 text-center text-xs">{applicantInfo.email}</p>
      <p className="mt-1 text-center text-xs">{applicantInfo.phone}</p>
      <p className="mt-1 text-center text-xs">{applicantInfo.company}</p>

      {/* offer action button */}
      <>
        {hireStatusFromData == 'pending' && offerStatusFromData == 'sent' && (
          <p className="mt-[18px] rounded-sm bg-[#E2B93B] bg-opacity-10 px-4 py-[5px] text-xs text-[#E2B93B]">
            Pending Response
          </p>
        )}
        {offerStatusFromData == 'pending' &&
          hireStatusFromData == 'pending' && (
            <p className="mt-[18px] rounded-sm bg-[#191919] bg-opacity-10 px-4 py-[5px] text-xs">
              Potential Hire
            </p>
          )}
        {offerStatusFromData == 'pending' &&
          hireStatusFromData == 'pending' && (
            <button
              onClick={handleSendOfferModal}
              className="mt-4 flex w-[147px] items-center justify-center rounded-md bg-[#191919] py-3 text-xs font-bold text-white transition hover:bg-primary"
            >
              <p>Send Offer</p>
            </button>
          )}

        {offerStatusFromData == 'accepted' &&
          hireStatusFromData == 'pending' && (
            <p className="mt-[18px] rounded-sm bg-primary bg-opacity-10 px-4 py-[5px] text-xs text-primary">
              Accepted Offer
            </p>
          )}
        {offerStatusFromData == 'negotiation' && (
          <button
            onClick={handleSendOfferModal}
            className="mt-4 flex w-[147px] items-center justify-center rounded-md bg-[#191919] py-3 text-xs font-bold text-white transition hover:bg-primary"
          >
            <p>Send Offer</p>
          </button>
        )}
        {offerStatusFromData == 'sent' && (
          <p className="mt-4 flex w-[147px] items-center justify-center rounded-md bg-[#191919] bg-opacity-5 py-3 text-xs font-bold text-black transition">
            <p>Offer Sent</p>
          </p>
        )}
        {offerStatusFromData == 'rejected' && (
          <button
            onClick={handleSendOfferModal}
            className="mt-4 flex w-[147px] items-center justify-center rounded-md bg-[#191919] py-3 text-xs font-bold text-white transition hover:bg-primary"
          >
            <p>Resend Offer</p>
          </button>
        )}
        {offerStatusFromData == 'accepted' && (
          <button
            type="button"
            onClick={() => setConfirmModal(true)}
            className="mt-4 flex w-[147px] items-center justify-center rounded-md bg-[#191919] py-3 text-xs font-bold text-white transition hover:bg-primary"
          >
            <p>Hire Candidate</p>
          </button>
        )}
      </>

      {/* <p className="mt-3 text-xs text-[#A6A6A6]">
        Applied: {formattedDateApplied}
      </p> */}
      <div
        ref={ref}
        className={clsx('', isComponentVisible ? 'block' : 'hidden')}
      >
        <ApplicantOptionGeneral
          activeFilter={activeFilter}
          handleSendOfferModal={handleSendOfferModal}
          applicantId={applicantInfo.userId}
          openOfferLetterModal={openOfferLetterModal}
          setOpenOfferLetterModal={setOpenOfferLetterModal}
          openDiscardEmployee={openDiscardEmployee}
          setOpenDiscardEmployee={setOpenDiscardEmployee}
        />
      </div>
      <ModalConfirmHire
        handleHireClick={handleHireClick}
        setOpenHiredApplicantModal={setOpenHiredApplicantModal}
        isLoading={false}
        setShowModal={setConfirmModal}
        showDialog={confirmModal}
        applicantName={applicantName || ''}
        jobTitle={jobTitle || ''}
      />
      <HiredApplicantModal
        openHiredApplicantModal={openHiredApplicantModal}
        setOpenHiredApplicantModal={setOpenHiredApplicantModal}
        jobTitle={jobTitle}
        data={applicantInfo}
      />
    </div>
  )
}
