import { useMutation } from '@tanstack/react-query';


import { fetchData } from '@/utils';


interface Skills {
  name: string
  id: string
}

interface SkillsResponse {
  status: string;
  message: string;
  data: Skills[];
}
interface SkillsPayload {
    name: string
}

export const useCreateSkills = () => {
    return useMutation<SkillsResponse, unknown, SkillsPayload>((payload) =>
      fetchData<SkillsPayload>('/common/skills', 'POST', payload)
    )
};
