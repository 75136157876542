import { useQuery } from '@tanstack/react-query'
// import React from 'react'

// import { companyIDStorage } from '@/features/auth'
// import { CompanyIdContext } from '@/features/CompanyIdContext'
import { companyKeys } from '@/utils'
import { fetchData } from '@/utils'
import { Company } from '../types'
import { useCompanyId } from '../hooks'

interface CompanyResponse {
  status: string
  message: string
  data: Company
}

export const useCompanyDetail = () => {
 const companyID =  useCompanyId()
  return useQuery<CompanyResponse>({
    queryKey: companyKeys.list(companyID),
    queryFn: async () => fetchData(`/company/${companyID}`),
  })
}
