import { useQualifications } from "@/api"


export const useQualificationOptions = () => {
  const { data } = useQualifications()
  const qualificationOptions = data?.data || []
  return {
    qualificationOptions,
  }
}
