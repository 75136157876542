import { AnimatePresence, motion } from 'framer-motion'
import { Calendar2, Copy } from 'iconsax-react'
import { BsXLg } from 'react-icons/bs'
import Image from 'next/image'
import dayjs from 'dayjs'
import React from 'react'
import clsx from 'clsx'

import {
  backdropVariant,
  INTERVIEW_DURATION_OPTIONS,
  InterviewDetailsModalAnim,
} from '@/utils'
import {
  InterviewScheduleOption,
  SlotOption,
  useInterviewInfo,
} from '@/features/job-seeker'
import { InterviewSlot, Slot } from '@/features/recruiter'
import { useClipboard } from '../Elements/useClipboard'
import { PageSpinner } from '../PageSpinner'
import { Button } from '../Elements'
import { Tooltip } from '@mui/material'

interface LoadingObj {
  accept: boolean
  decline: boolean
}

interface InterviewModalProps {
  interviewId: string
  slotOptions: SlotOption[]
  setSlotOptions: React.Dispatch<React.SetStateAction<SlotOption[]>>
  showInterviewDetailsForJobSeeker: boolean
  setShowInterviewDetailsForJobSeeker: (value: boolean) => void
  handleAccept: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    slot: InterviewScheduleOption[]
  ) => void
  handleDecline: () => void
  loadingObj: LoadingObj
}

export const InterviewDetailsJobSeekerModal: React.FC<InterviewModalProps> = ({
  interviewId,
  slotOptions,
  setSlotOptions,
  showInterviewDetailsForJobSeeker,
  setShowInterviewDetailsForJobSeeker,
  handleAccept,
  handleDecline,
  loadingObj,
}) => {
  const { copy } = useClipboard()
  const { data, isLoading } = useInterviewInfo(interviewId)
  const {
    photoUrl,
    companyName,
    companyLocation,
    companyLogo,
    hasAccepted,
    hasDeclined,
    jobTitle,
    interviewRound,
    interviewScheduleOptions,
    firstName,
    lastName,
    interviewType,
    interviewOption,
    interviewLink,
    instruction,
    interviewDate,
    interviewStartTime,
    interviewEndTime,
  } = data?.data || {}

  const hasResponded = hasAccepted || hasDeclined



  const interviewStartDate = dayjs(interviewDate + 'T' + interviewStartTime)
  const interviewEndDate = dayjs(interviewDate + 'T' + interviewEndTime)

  const durationInMinutes = interviewEndDate.diff(interviewStartDate, 'minutes')

  const durationOption = INTERVIEW_DURATION_OPTIONS.find(
    (interview) => interview.id === String(durationInMinutes)
  )

  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (!isLoading && interviewScheduleOptions) {
      const transformedInterviewScheduleOptions = interviewScheduleOptions.map(
        (option) => ({
          ...option,
          isSelected: false,
        })
      )
      setSlotOptions(transformedInterviewScheduleOptions)
    }
  }, [isLoading])

  const [selectedSlot, setSelectedSlot] = React.useState(" ")

  const handleGetSelectedSlotId = (id:string) => {
    if(id == selectedSlot){
      setSelectedSlot(" ")
    } else {
      setSelectedSlot(id)
    }
  }

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        showInterviewDetailsForJobSeeker &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowInterviewDetailsForJobSeeker(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showInterviewDetailsForJobSeeker])

  const isSecondOption = interviewOption === 'option 2'
  const isThirdOption = interviewOption === 'option 3'
  const isOptionTwoOrThree = isSecondOption || isThirdOption

  const slot: InterviewSlot = {
    id: '332',
    interviewStartTime: new Date(
      interviewStartTime ? interviewDate + 'T' + interviewStartTime : ''
    ),
    interviewDate: new Date(interviewDate ? interviewDate : ''),
    interviewDuration: durationOption || {
      name: '0 minute',
      id: '0',
    },
  }

  return (
    <AnimatePresence>
      {showInterviewDetailsForJobSeeker && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-0 right-0 h-screen max-w-[85%] overflow-scroll rounded-lg  bg-white px-6 pt-8 lg:min-w-[662px]"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={InterviewDetailsModalAnim}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2  ">
                <p className="flex h-[30px] w-[30px] justify-center rounded-full bg-[#E2B93B] bg-opacity-10 md:items-center">
                  <Calendar2 size="18" color="#E2B93B" variant="Bulk" />
                </p>
                <h3 className="text-base font-medium">Interview Details</h3>
              </div>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  className=" rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setShowInterviewDetailsForJobSeeker(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            {isLoading ? (
              <div className="mt-16 flex items-center justify-center">
                <PageSpinner />
              </div>
            ) : (
              <>
                <div className="mt-5 h-[80vh] overflow-y-auto">
                  <div className="flex items-center py-6">
                    <div className="flex items-center gap-2">
                      <div>
                        {photoUrl == '' ? (
                          <div>
                            <p
                              style={{ background: 'black' }}
                              className="-ml-[10px] flex h-[60px] w-[60px] items-center justify-center rounded-full border-[3px] border-white text-sm font-bold"
                            >
                              {firstName} {lastName}
                            </p>
                          </div>
                        ) : (
                          <Image
                            src={companyLogo || ''}
                            className="h-[50px] w-[50px] rounded-lg  border-[3px] border-white"
                            alt="applicant"
                            height={60}
                            width={60}
                          />
                        )}
                      </div>
                      <div className="">
                        <p className="mb-0 text-sm font-bold">{companyName}</p>
                        <p className=" text-sm">{companyLocation}</p>
                      </div>
                    </div>
                  </div>

                  {interviewStartTime && (
                    <>
                      <div className="rounded-md border-y py-6">
                        <div className="flex items-center gap-4">
                          <Slot slot={slot} />
                          <div>
                            <p className="text-lg font-normal">
                              Interview for {'"'}
                              {jobTitle}
                              {'"'}
                            </p>

                            <div className="mt-2 flex items-center gap-2 text-xs text-secondary ">
                              <p className="rounded-[3px] bg-secondary/10 px-[10px] py-1">
                                {interviewType}
                              </p>
                              <p className="rounded-[3px] bg-secondary/10 px-[10px] py-1">
                                {interviewRound}
                              </p>
                            </div>
                          </div>
                        </div>

                        {interviewLink && (
                          <div className="mt-6">
                            <h3 className="text-sm font-bold">
                              Interview Link
                            </h3>
                            <div className="flex items-center">
                              <p className="w-90 mt-1 h-10 truncate bg-secondary/10 p-2 text-sm font-light text-secondary text-[#007aff] underline">
                                {interviewLink}
                              </p>
                              <button
                                onClick={() => copy(interviewLink)}
                                className="mt-1 flex h-10 w-10 items-center justify-center border"
                              >
                                <Copy size="18" color="#27AE60" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {instruction && (
                    <div className="mt-6 flex items-center space-x-3 rounded border border-dashed border-[#E2B93B]  bg-[#E2B93B] bg-opacity-20 p-4 text-secondary">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_7214_12284)">
                          <path
                            opacity="0.2"
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            fill="#E2B93B"
                          />
                          <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#E2B93B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.25 11.25H12V16.5H12.75"
                            stroke="#E2B93B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z"
                            fill="#E2B93B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7214_12284">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <p className="w-[500px] text-sm font-light">
                        {instruction}
                      </p>
                    </div>
                  )}

                  {isOptionTwoOrThree && !hasResponded && (
                    <>
                      <div className="mt-5 flex items-center gap-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_7214_12426)">
                            <path
                              opacity="0.2"
                              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                              fill="#191919"
                            />
                            <path
                              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                              stroke="#191919"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.25 11.25H12V16.5H12.75"
                              stroke="#191919"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z"
                              fill="#191919"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7214_12426">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <p className="text-sm">
                          The company has given you the possibility to select
                          any available slot for your interview
                        </p>
                      </div>
                      <div className="mt-6 flex flex-wrap gap-4">
                        {slotOptions &&
                          slotOptions.map((slot, index) => {

                          
                            const isTaken =
                              interviewScheduleOptions &&
                              interviewScheduleOptions[index].isTaken
                            const month = dayjs(slot.interviewDate).format(
                              'MMM'
                            )
                            const date = dayjs(slot.interviewDate).format('DD')
                            const day = dayjs(slot.interviewDate).format('ddd')

                            const interviewStartDate = dayjs(
                              slot.interviewDate + 'T' + slot.interviewStartTime
                            )
                            const interviewEndDate = dayjs(
                              slot.interviewDate + 'T' + slot.interviewEndTime
                            )
                            const startTime =
                              dayjs(interviewStartDate).format('h:mm a')

                            const durationInMinutes = interviewEndDate.diff(
                              interviewStartDate,
                              'minutes'
                            )

                            const durationOption =
                              INTERVIEW_DURATION_OPTIONS.find(
                                (interview) =>
                                  interview.id === String(durationInMinutes)
                              )

                            const duration = durationOption?.name || '0 minute'

                            const handleSlot = (
                              slot: InterviewScheduleOption
                            ) => {
                              const updatedSlots =
                                interviewScheduleOptions?.map((item) => {
                                  if (item.id === slot.id) {
                                    return {
                                      ...item,
                                      isTaken: !slot.isTaken,
                                      isSelected: !slot.isTaken,
                                    }
                                  }

                                  return {
                                    ...item,
                                    isSelected: false,
                                  }
                                }) || []
                              setSlotOptions(updatedSlots)
                            }
                            const isSlotTakenAndSecondOption =
                              slot.isTaken && isSecondOption

                            const isButtonDisabled =
                              isTaken || hasAccepted || hasDeclined

                            const getToolTipText = () => {
                              let text = ''
                              if (isSlotTakenAndSecondOption ) {
                                text = 'Slot has been taken'
                              } else if (hasAccepted || slot.isSelected) {
                                text = 'Slot is selected'
                              } else if (!isButtonDisabled) {
                                text = 'Slot is available'
                              }
                              return text
                            }
                            const toolTipText = getToolTipText()
                            return (
                              <Tooltip key={slot.id} title={toolTipText}>
                                <button
                                  className="space-y-0.5 text-center text-xs"
                                  disabled={isButtonDisabled}
                                  onClick={() =>{
                                    handleSlot(slot)
                                    handleGetSelectedSlotId(slot?.id)
                                  } }
                                >
                                  <p
                                    className={clsx(
                                      'rounded-t px-5 py-2',
                                      isSlotTakenAndSecondOption &&
                                        'bg-[#EB5757] text-white',
                                      slot.isSelected &&
                                        'bg-green-500 text-white',
                                      !slot.isTaken && 'bg-[#e5e5e5]'
                                    )}
                                  >
                                    {month}
                                  </p>
                                  <p
                                    className={clsx(
                                      'px-5 py-2 text-xl font-bold',
                                      isSlotTakenAndSecondOption &&
                                        'bg-[#EB5757] text-white',
                                      slot.isSelected &&
                                        'bg-green-500 text-white',
                                      !slot.isTaken && 'bg-[#e5e5e5]'
                                    )}
                                  >
                                    {date}
                                  </p>
                                  <p
                                    className={clsx(
                                      'px-5 py-2',
                                      isSlotTakenAndSecondOption &&
                                        'bg-[#EB5757] text-white',
                                      slot.isSelected &&
                                        'bg-green-500 text-white',
                                      !slot.isTaken && 'bg-[#e5e5e5]'
                                    )}
                                  >
                                    {day}
                                  </p>
                                  <p
                                    className={clsx(
                                      'rounded-b px-5 py-2',
                                      isSlotTakenAndSecondOption &&
                                        'bg-[#EB5757] text-white',
                                      slot.isSelected &&
                                        'bg-green-500 text-white',
                                      !slot.isTaken && 'bg-[#e5e5e5]'
                                    )}
                                  >
                                    {startTime} • {duration}
                                  </p>
                                </button>
                              </Tooltip>
                            )
                          })}
                      </div>
                    </>
                  )}
                </div>
                {!hasResponded && (
                  <div className=" bottom-0 left-0 right-0 flex justify-center gap-6 bg-white py-5">
                    <Button
                      isLoading={loadingObj.decline}
                      disabled={loadingObj.accept || loadingObj.decline}
                      onClick={handleDecline}
                      variant="dangerTwo"
                    >
                      {loadingObj.decline ? 'Declining...' : 'Decline'}
                    </Button>
                    <Button
                      isLoading={loadingObj.accept}
                      disabled={(selectedSlot == " " && isOptionTwoOrThree) || (loadingObj.accept || loadingObj.decline)}
                      onClick={(e) => handleAccept(e, slotOptions)}
                      className=""
                    >
                      {loadingObj.accept ? 'Accepting...' : 'Accept'}
                    </Button>
                  </div>
                )}
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
