import { Control, useFormContext } from 'react-hook-form'
import React from 'react'

import { InputDateField, Label, SelectField } from '@/components'
import { INTERVIEW_DURATION_OPTIONS } from '@/utils'
import { ScheduleInterviewForm } from '../types'
import dayjs from 'dayjs'
import setMinutes from 'date-fns/setMinutes'
import setHours from 'date-fns/setHours'
interface ScheduleOptionOneProps {
  index: number
}

export const ScheduleOptionOne: React.FC<ScheduleOptionOneProps> = ({
  index,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<ScheduleInterviewForm>()

  const interviewRoundErrorObj = errors.interviewRounds?.[index]
  const interviewDate = watch(`interviewRounds.${index}.interviewDate`)
  const minTimeRangeEnd = setHours(
    setMinutes(new Date(), dayjs(interviewDate).get('minute')),
    dayjs(interviewDate).get('hour')
  )
  const maxTimeRangeEnd = setHours(setMinutes(new Date(), 55), 23)
  const minTime = dayjs(interviewDate).get('date') === dayjs(new Date()).get('date') ? setHours(setMinutes(new Date(), dayjs(new Date()).get('minute')), dayjs(new Date()).get('hour')) : minTimeRangeEnd
  
  

  return (
    <div className="grid gap-7 sm:grid-cols-3 sm:gap-5">
      <div className="flex-1">
        <div>
          <InputDateField
            label="Interview Date"
            control={control as unknown as Control}
            placeholder="Interview date"
            name={`interviewRounds.${index}.interviewDate`}
            minDate={new Date()}
            hasError={interviewRoundErrorObj?.interviewDate}
            isRequired
          />
        </div>
      </div>
      <div className="flex-1">
        <div>
          <InputDateField
            label="Start Time"
            control={control as unknown as Control}
            placeholder="Start time"
            hasError={interviewRoundErrorObj?.interviewStartTime}
            name={`interviewRounds.${index}.interviewStartTime`}
            showTimeSelect
            showTimeSelectOnly
            isRequired
            timeIntervals={15}
            minTime={minTime}
            maxTime={maxTimeRangeEnd}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </div>
      </div>
      <div className="flex-1">
        <div>
          <Label id={`interviewRounds.${index}.interviewDuration`} isRequired>
            Duration
          </Label>
          <SelectField
            control={control as unknown as Control}
            hasError={interviewRoundErrorObj?.interviewDuration}
            arr={INTERVIEW_DURATION_OPTIONS}
            name={`interviewRounds.${index}.interviewDuration`}
          />
        </div>
      </div>
    </div>
  )
}
