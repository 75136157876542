import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { ResetPasswordPayload } from '../types'
import { fetchData, transformError } from '@/utils'
import { AxiosError } from 'axios'

export const useResetPassword = () => {
  return useMutation<Response, AxiosError, ResetPasswordPayload>(
    (payload) =>
      fetchData<ResetPasswordPayload>('/auth/reset-password', 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Password successfully changed.')
      },
      onError: (error) => {
        toast.error(transformError(error))
      },
    }
  )
}
