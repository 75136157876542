import {
  backdropVariant,
  filterApplicant,
  InterviewDetailsModalAnim,
} from '@/utils'
import { AnimatePresence, motion } from 'framer-motion'
import { UseFormReturn } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import Image from 'next/image'
import React from 'react'

import { JobApplicant } from '@/features/job-seeker'
import { useFilterBySearch } from '@/hooks'
import { Button } from '@/components'
import { ScheduleInterviewForm } from '../../types'

interface SelectCandidateModalProps {
  jobTitle: string | undefined
  jobApplicants: JobApplicant[]
  selectedApplicants: JobApplicant[]
  showModal: boolean
  closeModal: () => void
  handleAddApplicant: (
    applicant: JobApplicant,
    selectedApplicants: JobApplicant[]
  ) => void
  handleRemoveApplicant: (
    userId: string,
    selectedApplicants: JobApplicant[]
  ) => void
  selectAllApplicants: boolean
  setSelectAllApplicants: React.Dispatch<React.SetStateAction<boolean>>
  reactHookForm: UseFormReturn<ScheduleInterviewForm>
}

export const SelectCandidateModal: React.FC<SelectCandidateModalProps> = ({
  jobTitle,
  selectedApplicants,
  jobApplicants,
  closeModal,
  showModal,
  handleAddApplicant,
  handleRemoveApplicant,
  setSelectAllApplicants,
  selectAllApplicants,
  reactHookForm,
}) => {
  const { searchTerm, handleSearchChange } = useFilterBySearch()

  const applicants =
    jobApplicants?.filter((applicant: JobApplicant) => {
      const applicantStatus = applicant.status
      if (
        applicantStatus === 'disqualified' ||
        applicantStatus === 'hire' ||
        applicantStatus === 'applied' ||
        applicantStatus === 'screening'
      ) {
        return false
      }
      return true
    }) || []

  const filteredApplicants = applicants.filter((applicant: JobApplicant) =>
    filterApplicant(applicant, searchTerm)
  )

  const handleSelectAllActiveNavApplicants = () => {
    if (selectAllApplicants) {
      reactHookForm.setValue('users', [])
      setSelectAllApplicants(false)
      return
    } else {
      setSelectAllApplicants(true)
      reactHookForm.watch('users').length
      const allApplicantSelected = [...applicants]
      reactHookForm.setValue('users', allApplicantSelected)
    }
  }

  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal()
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showModal])

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-0 right-0 h-screen  w-[662px] overflow-scroll bg-white px-6 py-8"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={InterviewDetailsModalAnim}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <h3 className="text-base font-bold">
                  Select Multiple Candidates
                </h3>
              </div>
              <div className="flex items-center gap-5">
                <Button type="button" onClick={closeModal}>
                  Save
                </Button>
                <button
                  type="button"
                  className=" rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={closeModal}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <div className="mt-10 flex items-center justify-between">
              <p>
                Shortlisted Candidates for {'"'}
                <span className="text-sm font-semibold">{jobTitle}</span>
                {'"'}
              </p>
              <span className="rounded-sm bg-[#1919191A] py-1 px-3 text-sm">
                {' '}
                {applicants.length} Applicants
              </span>
            </div>
            <div>
              <div className="my-4 flex h-[45px] w-[636px] items-center gap-2 rounded-md border border-[#EFEFEF] bg-transparent  px-4 ">
                <Image
                  src="/assets/globalsearch.png"
                  width={20}
                  height={20}
                  alt="search icon"
                />
                <input
                  type="text"
                  className="w-full bg-transparent font-WorkSans text-sm outline-none"
                  placeholder="Search job applicant"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <button
                type="button"
                className='flex items-center gap-2 my-4'
                onClick={handleSelectAllActiveNavApplicants}
              >
            
                {selectAllApplicants ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="#27AE60" />
                    <path
                      d="M14 8L9.1875 13L7 10.7273"
                      stroke="#FEFEFE"
                      stroke-width="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <div className="h-[20px] w-[20px] rounded-full border"></div>
                )}
                <p>Select All</p>
              </button>
              <ul className="my-4 flex flex-wrap items-center gap-5">
                {selectedApplicants.map(
                  (
                    { firstName, lastName, location, userId, photoUrl },
                    index
                  ) => {
                    const name = `${firstName} ${lastName}`

                    return (
                      <li
                        className="group inline-flex items-center space-x-3 rounded-md bg-black px-4 py-2 text-white"
                        key={index}
                      >
                       {photoUrl ? <img
                          src={photoUrl || ''}
                          alt="setUpProfile"
                          className="profile-picture h-[40px] w-[40px]"
                        /> : <div className="h-[40px] w-[40px] min-w-[40px] font-bold items-center justify-center flex min-h-[40px] rounded-full border">{name[0]}</div>}
                        <div>
                          <h4 className="text-sm font-semibold">{name}</h4>
                          <p className="text-xs">{location}</p>
                        </div>
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveApplicant(userId, selectedApplicants)
                          }
                          className="flex h-5 w-5 items-center justify-center rounded-full bg-white opacity-0 group-hover:opacity-100"
                        >
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.92969 3.09619L2.73758 8.2883"
                              stroke="#191919"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                            <path
                              d="M7.92969 8.28809L2.73758 3.09597"
                              stroke="#191919"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                        </button>
                      </li>
                    )
                  }
                )}
              </ul>

              <ul className="mt-3 space-y-5">
                {filteredApplicants.map((applicant, index) => {
                  const { firstName, lastName, location, photoUrl, userId } =
                    applicant
                  const isActive = selectedApplicants.some(
                    (applicant) => applicant.userId === userId
                  )

                  const handleAdd = () => {
                    if (isActive) {
                      return handleRemoveApplicant(userId, selectedApplicants)
                    }
                    handleAddApplicant(applicant, selectedApplicants)
                  }

                  return (
                    <li
                      className="flex items-center border-t border-t-[#e5e5e5] pt-5"
                      key={index}
                    >
                      <button
                        type="button"
                        onClick={handleAdd}
                        className="flex items-center space-x-3 text-left"
                      >
                        {isActive ? (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="10" cy="10" r="10" fill="#27AE60" />
                            <path
                              d="M14 8L9.1875 13L7 10.7273"
                              stroke="#FEFEFE"
                              stroke-width="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <div className="h-[20px] w-[20px] rounded-full border"></div>
                        )}
                      {photoUrl ? <img
                          src={photoUrl || ''}
                          alt="setUpProfile"
                          className="profile-picture h-[57px] w-[57px]"
                        /> : <div className="h-[57px] w-[57px] min-w-[57px] bg-black text-white text-[20px] font-bold items-center justify-center flex min-h-[57px] rounded-full border">{firstName[0]}</div>}
                        <div>
                          <h3>
                            {firstName} {lastName}
                          </h3>
                          <p>{location}</p>
                        </div>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
