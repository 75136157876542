import { AnimatePresence, motion } from 'framer-motion'
import { BsDot, BsXLg } from 'react-icons/bs'
import React from 'react'
import dayjs from 'dayjs'

import {
  addCommaToNumber,
  backdropVariant,
  checkIfDateIsAfter,
  ViewJobPostAnim,
} from '@/utils'
import { IOpenViewPost, PageSpinner } from '@/components'
import { useJob } from '@/features/job-seeker'
import { useClipboard } from '@/components/Elements/useClipboard'
import { Tooltip } from '@mui/material'

export interface JobDetailDataType {
  applicants: number
  createdAt: string
  datePosted: string
  employmentType: string
  id: string
  isSpotlighted: boolean
  level: string
  status: string
  summary: string
  title: string
  updatedAt: string
  validThrough: string
  workType: string
}

export const ViewJobPostModal = ({
  openViewJobPostModal,
  setOpenViewJobPostModal,
  jobPostDetail,
}: // useCompanyPost
IOpenViewPost) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const { data, isLoading } = useJob(jobPostDetail?.id || '')

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openViewJobPostModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenViewJobPostModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  })

  const {
    // companyName,
    // companyLocation,
    title,
    applicants,
    description,
    minQualification,
    // employeeCount,
    //availableOpenings,
    jobLocation,
    employmentType,
    level,
    summary,
    validThrough,
    datePosted,
    experience,
    salary,
    workType,
    // isAnonymous,
  } = data?.data || {}

  const { currency, min, max, term, hidden } = salary || {}

  const isApplicationClosed = checkIfDateIsAfter(validThrough)

  const formattedPostedDate = dayjs(datePosted).fromNow()

  const formattedDeadline = dayjs(validThrough).fromNow()

  // const locationArr = companyLocation?.split(',') || []
  // const state = locationArr[locationArr.length - 2]
  // const country = locationArr[locationArr.length - 1]

  const { copy } = useClipboard()
  const BASE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL as string
  const jobLink = `${BASE_URL}/job/${jobPostDetail?.id}`

  return (
    <AnimatePresence>
      {openViewJobPostModal && (
        <motion.div
          className=" scrollbar-hide absolute top-0 right-0 z-[100] h-[100vh] w-screen overflow-y-scroll bg-black bg-opacity-40 text-left text-black"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            variants={ViewJobPostAnim}
            initial="initial"
            animate="show"
            ref={ref}
            exit="exit"
            className="absolute top-0 right-0 min-h-screen w-[85%] bg-white pb-10 md:w-[830px] "
          >
            <div className="sticky top-0 flex items-center justify-end gap-4 bg-white px-[50px] pt-[20px] pb-2">
              <button
                className="top-14 right-0 rounded-full bg-gray-200 p-2 text-[9px] "
                onClick={() => setOpenViewJobPostModal(false)}
              >
                <BsXLg />
              </button>
            </div>

            {isLoading ? (
              <PageSpinner className="mt-36" />
            ) : (
              <>
                <div className="flex h-fit flex-wrap items-end justify-between gap-6 px-[50px]">
                  <div className="">
                    <div>
                      <h2 className="text-left text-sm font-bold sm:text-base">
                        {title}
                      </h2>
                      <div className="items mt-1 flex flex-wrap items-center gap-1 whitespace-nowrap text-[10px]">
                        <span className="text-xs  text-gray-400">
                          Posted {formattedPostedDate}
                        </span>
                        <div className="text-base text-gray-400">
                          <BsDot />
                        </div>
                        <span className="text-xs text-[#EB5757]">
                          {isApplicationClosed ? (
                            'Application Closed'
                          ) : (
                            <p>Closing {formattedDeadline}</p>
                          )}
                        </span>
                        <div className="hidden text-base text-gray-400 sm:block">
                          <BsDot />
                        </div>
                        <span className="text-xs text-blue-400">
                          {' '}
                          {applicants} Applicant
                          {applicants && applicants > 1 ? 's' : ''}
                        </span>
                      </div>
                    </div>

                    {/*  */}
                  </div>
                  <div>
                    <div className="mt-4">
                      <button
                        type="button"
                        className="whitespace-nowrap rounded-md border bg-black py-1.5 px-2 text-xs capitalize text-white transition duration-100 ease-in-out hover:bg-primary hover:text-white"
                        onClick={() => copy(jobLink)}
                      >
                        Copy job link
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-10 flex gap-10 px-[50px]">
              <div className="flex flex-1 items-start gap-4 border border-black bg-white py-3 px-4 text-secondary shadow-[-6px_6px_0px_0px_#191919] transition  duration-100 ease-in-out hover:shadow-[6px_6px_0px_0px_#191919] disabled:hover:shadow-[-6px_6px_0px_0px_#191919]">
                <div className="flex items-center justify-center rounded-lg border border-[#E5E5E5] p-2">
                  <Image
                    src="/assets/atsBig.png"
                    alt="ats-logo"
                    height={34.62}
                    width={34.62}
                  />
                </div>
                <div className="">
                  <p className="text-sm font-[600]">ATS Assistant</p>
                  <p className="mt-2 text-xs ">
                    Sync our ATS assistant for better selection.
                  </p>
                </div>
              </div>
              <div className="flex flex-1  items-start gap-4 border border-black bg-white py-3 px-4 text-secondary shadow-[-6px_6px_0px_0px_#191919] transition  duration-100 ease-in-out hover:shadow-[6px_6px_0px_0px_#191919] disabled:hover:shadow-[-6px_6px_0px_0px_#191919]">
                <div className="flex items-center justify-center rounded-lg border border-[#E5E5E5] p-2">
                  <Image
                    src="/assets/spotlightBig.png"
                    alt="ats-logo"
                    height={34.62}
                    width={34.62}
                  />
                </div>
                <div className="">
                  <p className="text-sm font-[600]">Spotlight Post</p>
                  <p className="mt-2 text-xs ">
                    Spotlight this job post to make it standout
                  </p>
                </div>
              </div>
            </div> Minimum Qualification */}
                <div className="mx-[50px] mt-10 grid gap-4 rounded-lg border border-[#E6E5E5] py-6 px-4  md:grid-cols-3 md:flex-row">
                  <div className="border-r border-[#E6E5E5] px-2">
                    <p className="whitespace-nowrap  text-xs ">Job Location</p>
                    <Tooltip title={jobLocation}>
                      <p className=" mt-1 cursor-pointer truncate whitespace-nowrap text-sm font-medium">
                        {jobLocation}
                      </p>
                    </Tooltip>
                    <hr className="my-2" />
                    <p className="whitespace-nowrap  text-xs ">
                      Minimum Qualification
                    </p>
                    <p className=" mt-1  whitespace-nowrap  text-sm font-medium ">
                      {minQualification}
                    </p>
                    {/* <p className="whitespace-nowrap  text-xs ">
                      Available Openings{' '}
                    </p>
                    <p className=" mt-1  whitespace-nowrap  text-sm font-medium ">
                      {availableOpenings}
                    </p> */}
                  </div>
                  <div className="border-r border-[#E6E5E5] px-2">
                    <p className="whitespace-nowrap text-xs  ">
                      Experience Level
                    </p>
                    <p className=" mt-1 whitespace-nowrap  text-sm font-medium line-clamp-1">
                      {level}
                    </p>
                    <hr className="my-2" />
                    <p className="whitespace-nowrap  text-xs ">
                      Experience Length
                    </p>
                    <p className=" mt-1  whitespace-nowrap  text-sm font-medium ">
                      {experience}
                    </p>
                  </div>
                  <div className=" px-2">
                    <p className="whitespace-nowrap  text-xs ">
                      Employment Type
                    </p>
                    <p className=" mt-1  whitespace-nowrap text-sm font-medium capitalize">
                      {employmentType} ({workType})
                    </p>
                    <hr className="my-2" />
                    <p className="whitespace-nowrap  text-xs ">Salary</p>
                    {hidden ? (
                   
                   <p className='text-sm'>Confidential</p>
                 ) : (
                   <>
                   <p className=" mt-1  whitespace-nowrap text-sm font-medium line-clamp-1 ">
                     {currency}
                     {addCommaToNumber(min || 0)}
                     {(max || 0) > 0 && (
                       <span>
                         {' - '}
                         {currency}
                         {addCommaToNumber(max || 0)}{' '}
                       </span>
                     )}{' '}
                     / <span>{term}</span>
                   </p>
                 </>
                 )}
                  </div>
                </div>
                <div className="px-[50px] text-sm">
                  <p className=" mt-8 text-base font-bold">Overview</p>

                  <p className="mt-2 font-WorkSans font-light leading-7">
                    {summary}
                  </p>
                </div>
                <div className="mt-8 px-[50px]">
                  {/* TODO: SANITIZE HTML */}
                  <div
                    className="job-description mt-2 text-sm"
                    dangerouslySetInnerHTML={{
                      __html: description || '',
                    }}
                  ></div>
                </div>
                <div></div>
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
