import { Button, Modal } from '@/components'
import { Warning2 } from 'iconsax-react'
import React from 'react'


interface ModalScreeningProps {
  name: string
  isLoading: boolean
  showDialog: boolean
  handleMove: () => void
  closeModal: () => void
}

export const ModalScreening: React.FC<ModalScreeningProps> = ({
  name,
  isLoading,
  showDialog,
  handleMove,
  closeModal,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      closeModal()
    }
  }
  return (
    <Modal
      className="mt-48"
      variant="middle"
      showDialog={showDialog}
      closeModal={handleClose}
    >
      <div className="mx-auto border border-gray-100 flex h-[190px] w-[560px] items-center overflow-y-auto bg-white px-8">
        <div>
          <div className="flex items-start space-x-4">
            <Warning2 size="32" color="#FF8A65" />
            <p className="text-[15px]">
              Are you sure you want to move{' '}
              <span className="font-semibold">{name}</span> to screening stage.
            </p>
          </div>

          <hr className="border-gray-450 my-6" />

          <div className="flex justify-end space-x-6">
            <Button
              disabled={isLoading}
              onClick={handleClose}
              variant="outline"
              size="xs"
            >
              Cancel
            </Button>
            <Button
              className="bg-error-300"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleMove}
              size="xs"
            >
              {isLoading ? 'Moving...' : 'Move'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
