import { Warning2 } from 'iconsax-react'
import React from 'react'

import { Button } from '..'
import { Modal } from '.'

interface ModalChangeStatusProps {
  isPublished: boolean
  title: string | undefined
  isLoading: boolean
  showDialog: boolean
  handleChangeStatus: () => void
  closeModal: () => void
}

export const ModalChangeStatus: React.FC<ModalChangeStatusProps> = ({
  isPublished,
  title,
  isLoading,
  showDialog,
  handleChangeStatus,
  closeModal,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      closeModal()
    }
  }
  return (
    <Modal
      className="mt-48"
      variant="middle"
      showDialog={showDialog}
      closeModal={handleClose}
    >
      <div className="mx-auto flex h-[190px] w-[560px] items-center overflow-y-auto border border-gray-100 bg-white px-8">
        <div>
          <div className="flex items-start space-x-4">
            <Warning2 size="32" color="#FF8A65" />
            <p className="text-[15px]">
              Are you sure you want to {isPublished ? 'unpublish' : 'published'}{' '}
              <span className="font-semibold">{title}</span>
            </p>
          </div>

          <hr className="border-gray-450 my-6" />

          <div className="flex justify-end space-x-6">
            <Button
              disabled={isLoading}
              onClick={handleClose}
              variant="outline"
              size="xs"
            >
              Cancel
            </Button>
            <Button
              className="bg-error-300"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleChangeStatus}
              size="xs"
            >
              {isPublished ? (
                <>{isLoading ? 'Unpublishing...' : 'Unpublish'}</>
                ) : (
                <>{isLoading ? 'Publishing...' : 'publish'}</>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
