import { useQuery } from '@tanstack/react-query'

import {  fetchData, userKeys } from '@/utils'
import { User } from '@/features/auth'

interface AllApplicantResponse {
  status: string
  message: string
  data: User | undefined
}

export const useGetUserProfile = (userId: string) => {

  return useQuery<AllApplicantResponse>({
    queryKey: userKeys.list(userId),
    queryFn: async () => fetchData(`/user/${userId}`),
    enabled: !!userId
  })
}
