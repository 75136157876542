import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { educationKeys, fetchData } from '@/utils';
import { ManageEducationPayload} from '../types';


export const useAddEducation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ManageEducationPayload) =>
      fetchData<ManageEducationPayload>("/user/education/add", 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Education successfully added.');
        queryClient.invalidateQueries({
          queryKey: educationKeys.all,
        });
      },
      onError: () => {
        toast.error('Education not added.');
      },
    }
  );
};