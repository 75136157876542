import React, { useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { backdropVariant, editProfile } from '@/utils'
import clsx from 'clsx'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import {
  AdditionalSkills,
  InterviewFeedback,
  interviewRoundProp,
} from '@/features/job-seeker'
import { overallRecommendation } from '@/data'
import { overallRecommendationProps } from '../../types'
import { Button, EmptyState, PageSpinner } from '@/components'
import { AlignBottom, InfoCircle, Slash, TickCircle } from 'iconsax-react'
import { AddSkillHint } from './AddSkillHint'
// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'

interface InterviewerFeedbackProp {
  setOpenInterviewerFeedback: (value: boolean) => void
  openInterviewerFeedback: boolean
  roundsData: interviewRoundProp
  interviewId: string
  feedbackData: InterviewFeedback[] | undefined
  isLoadingFeedback: boolean
}

export const InterviewerFeedback = ({
  setOpenInterviewerFeedback,
  openInterviewerFeedback,
  isLoadingFeedback,
  feedbackData,
  roundsData,
}: InterviewerFeedbackProp) => {
  const ref = useRef<HTMLInputElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openInterviewerFeedback &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenInterviewerFeedback(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openInterviewerFeedback])

  // const printDocument = () => {
  //   const input = document.getElementById('divToPrint');
  //   if (input) {
  //     // Save the original CSS
  //     const originalOverflow = input.style.overflow;
  //     const originalHeight = input.style.height;

  //     // Temporarily change the CSS
  //     input.style.overflow = 'visible';
  //     input.style.height = 'auto';

  //     html2canvas(input)
  //       .then((canvas) => {
  //         const imgData = canvas.toDataURL('image/png');
  //         const pdf = new jsPDF('p', 'mm', [canvas.width, canvas.height]);
  //         const imgProps= pdf.getImageProperties(imgData);
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //         pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //         pdf.save("download.pdf");

  //         // Reset the CSS
  //         input.style.overflow = originalOverflow;
  //         input.style.height = originalHeight;
  //       });
  //   }
  // }

  const [feedbackTab, setFeedbackTab] = React.useState('teamFeedback')

  const selectedFeedbackData = feedbackData && feedbackData[selectedIndex]
  const colorPicker = (ratingValue: number) => {
    return ratingValue == 5
      ? 'rgba(47, 128, 237, 1)'
      : ratingValue == 4
      ? 'rgba(39, 174, 96, 1)'
      : ratingValue == 3
      ? 'rgba(226, 185, 59, 1)'
      : ratingValue == 2
      ? 'rgba(226, 130, 59, 1)'
      : ratingValue == 0
      ? 'rgba(166, 166, 166, 1)'
      : 'rgba(235, 87, 87, 1)'
  }

  const hiredColor = (ratingValue: string) => {
    return ratingValue == 'Further interview'
      ? 'rgba(47, 128, 237, 1)'
      : ratingValue == 'Hire'
      ? 'rgba(39, 174, 96, 1)'
      : 'rgba(235, 87, 87, 1)'
  }
  const ratingDescription = (ratingValue: number) => {
    return ratingValue >= 5
      ? 'Exceptional'
      : ratingValue === 0
      ? 'Not Assessed'
      : ratingValue >= 4 && ratingValue < 5
      ? 'Very Good'
      : ratingValue >= 3 && ratingValue < 4
      ? 'Good'
      : ratingValue <= 2 && ratingValue < 3
      ? 'Average'
      : 'Below Average'
  }

  const interviewRatingData = feedbackData?.map((data) => {
    return data.interviewScore.map((data) => {
      return data
    })
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const averages: any = {}
  interviewRatingData?.forEach((group) => {
    group.forEach((item) => {
      if (!averages[item.name]) {
        averages[item.name] = { total: 0, count: 0 }
      }
      averages[item.name].total += item.rating
      averages[item.name].count++
    })
  })

  Object.keys(averages).forEach((name) => {
    averages[name].average = averages[name].total / averages[name].count
    delete averages[name].total
    delete averages[name].count
  })

  const prepedScoreArr = Object.entries(averages).map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ([name, { average }]: any) => ({ name, average })
  )

  // finds average of the hire, notHire, stronglyHire, and stronglyNotHire

  const overallCommentArr: number[] = []

  function SearchNumRating(
    nameKey: string,
    myArray: overallRecommendationProps[]
  ) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
        overallCommentArr.push(myArray[i].numberRep)
      }
    }
  }
  feedbackData?.map((data) => {
    SearchNumRating(data?.overallRecommendation, overallRecommendation)
  })

  // const averageOverallComment =
  //   overallCommentArr.length &&
  //   overallCommentArr?.reduce((a, b) => a + b) / overallCommentArr.length

  const hireNo = []
  const furtherInterviewNo = []
  const doNotHire = []

  feedbackData?.forEach((data) => {
    if (data.overallRecommendation.toLowerCase() === 'hire') {
      hireNo.push('hire')
    } else if (
      data.overallRecommendation.toLowerCase() === 'further interview'
    ) {
      furtherInterviewNo.push('further')
    } else if ('do not hire') {
      doNotHire.push('doNotHire')
    }
  })

  const obj =
    selectedFeedbackData?.professionalKnowledge &&
    JSON.parse(selectedFeedbackData?.professionalKnowledge)
  const professionalKnowledgeArray: AdditionalSkills[] =
    obj && Object?.values(obj)

  return (
    <AnimatePresence>
      {openInterviewerFeedback && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide absolute top-[50%] left-[50%] w-[1204px] overflow-scroll  rounded-lg bg-white px-12 pb-8"
            initial="initial"
            animate="show"
            exit="exit"
            style={{ height: 'calc(100vh - 50px)' }}
            variants={editProfile}
            ref={ref}
          >
            <div>
              <div className="sticky top-0 bg-white pt-6 pb-2">
                <h4 className="tex-base font-bold">interview Feedback</h4>
                {/* feedback Nav */}

                <div className="sticky top-0 mt-4 mb-4 flex items-center justify-between overflow-hidden text-xs">
                  <div className="rounded-md border border-[#E5E5E5]">
                    <button
                      onClick={() => setFeedbackTab('teamFeedback')}
                      className={clsx(
                        'text-sx px-4 py-2',
                        feedbackTab == 'teamFeedback'
                          ? 'bg-black text-white'
                          : 'bg-white text-black'
                      )}
                    >
                      Team Feedback
                    </button>
                    <button
                      onClick={() => setFeedbackTab('consolidatedFeedback')}
                      className={clsx(
                        'text-sx px-4 py-2',
                        feedbackTab == 'consolidatedFeedback'
                          ? 'bg-black text-white'
                          : 'bg-white text-black'
                      )}
                    >
                      Consolidated Feedback
                    </button>
                  </div>
                  <Button
                    onClick={() => setOpenInterviewerFeedback(false)}
                    className="mb-4"
                  >
                    Close
                  </Button>
                </div>
              </div>
              {feedbackTab == 'teamFeedback' && (
                <>
                  {isLoadingFeedback ? (
                    <div>
                      <PageSpinner className="mt-6" />
                    </div>
                  ) : (
                    <>
                      {feedbackData && feedbackData.length > 0 ? (
                        <div className="flex gap-4 ">
                          <div className="flex flex-col items-start space-y-2">
                            {feedbackData?.map((data, index) => {
                              return (
                                <button
                                  onClick={() => setSelectedIndex(index)}
                                  key={index}
                                  className={clsx(
                                    'mt-2 flex w-[250px] min-w-[250px] items-start gap-4 truncate whitespace-nowrap rounded-md px-6 py-3 text-left',
                                    selectedIndex == index
                                      ? 'bg-black text-white'
                                      : 'bg-[#EFEFEF]'
                                  )}
                                >
                                  <p className="flex h-[41px] w-[41px] min-w-[41px] items-center  justify-center overflow-hidden rounded-full bg-[#2F80ED] font-bold capitalize text-white">
                                    {data?.firstName[0]}
                                  </p>
                                  <div className="">
                                    <p className="truncate text-xs font-bold">
                                      {data.firstName} {data.lastName}
                                    </p>

                                    <p
                                      style={{
                                        background: hiredColor(
                                          data.overallRecommendation || ''
                                        ),
                                      }}
                                      className="mt-1 w-fit whitespace-nowrap rounded-sm  px-4 py-[2px] text-center text-[10px] text-white"
                                    >
                                      {data.overallRecommendation}
                                    </p>
                                  </div>
                                </button>
                              )
                            })}
                          </div>
                          <div
                            id="divToPrint"
                            style={{ height: 'calc(100vh - 250px)' }}
                            className="scrollbar-hide mt-2 w-full overflow-y-scroll rounded-md border border-primary px-6 pb-10"
                          >
                            {/* <button onClick={printDocument}>Download</button> */}

                            <div className="flex items-center justify-between">
                              <h4 className="sticky top-0 bg-white py-5 text-base font-bold">
                                Interview Scorecard
                              </h4>
                              <div>
                                {(selectedFeedbackData?.proceedToNextStep !==
                                  null ||
                                  selectedFeedbackData?.proceedToNextStep !==
                                    undefined) &&
                                  (selectedFeedbackData?.proceedToNextStep ? (
                                    <div className="flex w-fit items-center gap-2 rounded-md bg-primary px-4 py-2 text-white">
                                      <p className="b">Proceed to Next Step</p>
                                      <TickCircle size={18} color="#FFFFFF" />
                                    </div>
                                  ) : (
                                    <div className="flex w-fit items-center gap-2 rounded-md bg-[#EB5757] px-2 py-1 text-xs text-white">
                                      <p className="b">Do not proceed</p>
                                      <Slash
                                        size={12}
                                        color="#FFFFFF"
                                        variant="Linear"
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="mt-6 px-4">
                              <div className="flex items-center gap-4">
                                <h4 className="tex-base py-1 font-bold">
                                  Overall Recommendation
                                </h4>
                                <p
                                  className="hiredColor flex w-fit items-center justify-center rounded-sm  px-4 py-1 text-xs text-white"
                                  style={{
                                    background: hiredColor(
                                      selectedFeedbackData?.overallRecommendation ||
                                        ''
                                    ),
                                  }}
                                >
                                  {selectedFeedbackData?.overallRecommendation}
                                </p>
                              </div>
                              <div className="mt-4 flex items-center gap-4 rounded-md border border-blue-500 bg-blue-500 bg-opacity-10 py-3 px-5">
                                <p className="text-sm text-black ">
                                  {selectedFeedbackData?.overallComment}
                                </p>
                              </div>
                              {selectedFeedbackData?.pointToBeClarified && (
                                <>
                                  <div className="flex items-center gap-4">
                                    <h4 className="tex-base py-1 font-bold">
                                      Points to be Clarified
                                    </h4>
                                  </div>
                                  <div className="mt-1 flex items-center gap-4 rounded-md border border-blue-500 bg-blue-500 bg-opacity-10 py-3 px-5">
                                    <p className="text-sm text-black ">
                                      {selectedFeedbackData?.pointToBeClarified}
                                    </p>
                                  </div>
                                </>
                              )}

                              {selectedFeedbackData?.weaknesses && (
                                <>
                                  <div className="">
                                    <h4 className="tex-base mb-2 py-1 font-bold">
                                      Weaknesses
                                    </h4>
                                  </div>
                                  <div className="mt-1 flex items-center gap-4 rounded-md border border-[#e2b93b] bg-[#e2b93b] bg-opacity-10 py-3 px-5">
                                    <p className="text-sm text-black ">
                                      {selectedFeedbackData?.weaknesses}
                                    </p>
                                  </div>
                                </>
                              )}
                              <div className="mt-8">
                                <div className="mb-2 flex items-center gap-2">
                                  <h4 className="tex-base py-1 font-bold">
                                    Skill Set
                                  </h4>
                                  <div className="relative flex items-center gap-1">
                                    <button
                                      type="button"
                                      className="hint-button mx-2"
                                    >
                                      <InfoCircle
                                        variant="Linear"
                                        size={18}
                                        color="#e2b93b"
                                      />
                                      {/* <p className="text-sm font-[500] text-primary">Hint</p> */}
                                    </button>
                                    <div className="hint-content absolute top-5 z-50 w-[500px]">
                                      <AddSkillHint />
                                    </div>
                                  </div>
                                </div>
                                <div className="rounded-md border border-[#e2b93b] bg-[#e2b93b] bg-opacity-10 p-5">
                                  {selectedFeedbackData?.skillset?.map(
                                    (skill, index) => {
                                      const textColor = colorPicker(
                                        skill?.rating || 0
                                      )
                                      const ratingText = ratingDescription(
                                        skill?.rating || 0
                                      )
                                      return (
                                        <div
                                          className={clsx("flex items-center gap-4", index > 0 && 'mt-4')}
                                          key={skill.assessmentItem}
                                        >
                                          <p className="font-semibold capitalize min-w-[20px] w-[250px] truncate">
                                            {skill?.assessmentItem || ''}
                                          </p>
                                          <div className="flex">
                                            {Array(5)
                                              .fill(skill.rating)
                                              .map((_, index) =>
                                                skill.rating >= index + 1 ? (
                                                  <AiFillStar
                                                    className="text-[18px]"
                                                    key={index}
                                                    style={{ color: textColor }}
                                                  />
                                                ) : (
                                                  <AiOutlineStar
                                                    key={index}
                                                    className="text-[18px]"
                                                    // onMouseOver={() => !number && setHoverStar(index + 1)}
                                                    // onMouseLeave={() => setHoverStar(undefined)}
                                                    style={{ color: '#A6A6A6' }}
                                                  />
                                                )
                                              )}
                                          </div>
                                          <p
                                            className="px-4 py-1 text-xs text-white"
                                            style={{ background: textColor }}
                                          >
                                            {ratingText}
                                          </p>
                                        </div>
                                      )
                                    }
                                  )}
                                </div>
                              </div>
                              <div className="mt-8">
                                <div className="mb-2 flex items-center gap-2">
                                  <h4 className=" py-1 text-base font-bold">
                                    Professional Knowledge
                                  </h4>
                                  <div className="relative flex items-center gap-1">
                                    <button
                                      type="button"
                                      className="hint-button mx-2"
                                    >
                                      <InfoCircle
                                        variant="Linear"
                                        size={18}
                                        color="#24ae60"
                                      />
                                      {/* <p className="text-sm font-[500] text-primary">Hint</p> */}
                                    </button>
                                    <div className="hint-content absolute top-5 z-50 w-[500px]">
                                      <AddSkillHint />
                                    </div>
                                  </div>
                                </div>
                                <div className="rounded-md border border-primary bg-primary bg-opacity-10 p-5">
                                  {professionalKnowledgeArray?.map((skill, index) => {
                                    const textColor = colorPicker(
                                      skill?.rating || 0
                                    )
                                    const ratingText = ratingDescription(
                                      skill?.rating || 0
                                    )
                                    return (
                                      <div
                                        className={clsx("flex items-center gap-4 ", index > 0 && 'mt-4')}
                                        key={skill.assessmentItem}
                                      >
                                        <p className="font-semibold capitalize min-w-[20px] w-[250px] truncate">
                                          {skill?.assessmentItem}
                                        </p>
                                        <div className="flex">
                                          {Array(5)
                                            .fill(skill.rating)
                                            .map((_, index) =>
                                              skill.rating >= index + 1 ? (
                                                <AiFillStar
                                                  className="text-[18px]"
                                                  key={index}
                                                  style={{ color: textColor }}
                                                />
                                              ) : (
                                                <AiOutlineStar
                                                  key={index}
                                                  className="text-[18px]"
                                                  // onMouseOver={() => !number && setHoverStar(index + 1)}
                                                  // onMouseLeave={() => setHoverStar(undefined)}
                                                  style={{ color: '#A6A6A6' }}
                                                />
                                              )
                                            )}
                                        </div>
                                        <p
                                          className="px-4 py-1 text-xs text-white"
                                          style={{ background: textColor }}
                                        >
                                          {ratingText}
                                        </p>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>

                              <div>
                                {selectedFeedbackData?.interviewScore?.map(
                                  (skillData, index) => {
                                    const textColor = colorPicker(
                                      skillData.rating
                                    )
                                    const ratingText = ratingDescription(
                                      skillData.rating
                                    )
                                    return (
                                      <div key={index} className="mt-12">
                                        <div className="flex items-center gap-4">
                                          <p className="font-bold">
                                            {skillData.name}
                                          </p>
                                          <div className="flex">
                                            {Array(5)
                                              .fill(skillData.rating)
                                              .map((_, index) =>
                                                skillData.rating >=
                                                index + 1 ? (
                                                  <AiFillStar
                                                    className="text-[18px]"
                                                    key={index}
                                                    style={{ color: textColor }}
                                                  />
                                                ) : (
                                                  <AiOutlineStar
                                                    key={index}
                                                    className="text-[18px]"
                                                    // onMouseOver={() => !number && setHoverStar(index + 1)}
                                                    // onMouseLeave={() => setHoverStar(undefined)}
                                                    style={{ color: '#A6A6A6' }}
                                                  />
                                                )
                                              )}
                                          </div>
                                          <p
                                            className="px-4 py-1 text-xs text-white"
                                            style={{ background: textColor }}
                                          >
                                            {ratingText}
                                          </p>
                                        </div>
                                        <p className="mt-2 mb-6 text-sm">
                                          {skillData.description}
                                        </p>
                                        {skillData.comment !== ('' || null) && (
                                          <p
                                            className={clsx(
                                              'mt-2 max-h-32 rounded-md border border-gray-150 bg-[#E5E5E5] px-6 py-5 text-sm'
                                            )}
                                          >
                                            {skillData.comment}
                                          </p>
                                        )}
                                        {skillData.comment == ('' || null) && (
                                          <p
                                            className={clsx(
                                              'mt-2 max-h-32 overflow-y-auto rounded-md border border-gray-150 bg-[#E5E5E5] px-6 py-5 text-sm'
                                            )}
                                          >
                                            No Additional Comment Added
                                          </p>
                                        )}
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <EmptyState
                          className="mt-[15rem]"
                          maxWidthClass="max-w-[260px]"
                          icon={
                            <AlignBottom
                              size="32"
                              color="#FF8A65"
                              className="mx-auto"
                            />
                          }
                          description="No Team feedback"
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {/* consolidated Feedback */}
              {feedbackTab === 'consolidatedFeedback' &&
                (feedbackData && feedbackData.length > 0 ? (
                  <div>
                    <div className="mt-4 flex min-h-[38px] items-center gap-4 rounded-md border border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 px-5">
                      <p className="flex h-[18px] w-[18px] items-center justify-center rounded-full border-2 border-[#E2B93B] text-[#E2B93B]">
                        i
                      </p>
                      <p className="text-sm text-black">
                        The system feedback is based on a rounded assessment
                        gathered fro the team feedback
                      </p>
                    </div>
                    <div className="mt-4 rounded-lg bg-black px-6 py-4 text-white">
                      <div className="flex items-center gap-3">
                        <h4 className="text-sm font-bold">
                          Overall Recommendation
                        </h4>
                        <div className="flex gap-4">
                          {hireNo.length > 0 && (
                            <p className="flex w-fit items-center justify-center gap-1 rounded-sm bg-primary px-4 py-1 text-xs text-white">
                              Hire{' '}
                              <p className="flex h-[14px] w-[14px] min-w-[14px] items-center justify-center rounded-full border border-white bg-white bg-opacity-40 text-[10px] font-bold ">
                                {hireNo.length}
                              </p>
                            </p>
                          )}
                          {doNotHire.length > 0 && (
                            <p className="flex w-fit items-center justify-center gap-1 rounded-sm bg-[#eb5757]  px-4 py-1 text-xs text-white">
                              Do Not Hire{' '}
                              <p className="flex h-[14px] w-[14px] min-w-[14px] items-center justify-center rounded-full border border-white bg-white bg-opacity-40 text-[10px] font-bold ">
                                {' '}
                                {doNotHire.length}
                              </p>
                            </p>
                          )}
                          {furtherInterviewNo.length > 0 && (
                            <p className="flex w-fit items-center justify-center gap-1 rounded-sm bg-[#2f80ed]  px-4 py-1 text-xs text-white">
                              Further Interview{' '}
                              <p className="flex h-[14px] w-[14px] min-w-[14px] items-center justify-center rounded-full border border-white bg-white bg-opacity-40 text-[10px] font-bold ">
                                {furtherInterviewNo.length}
                              </p>
                            </p>
                          )}
                        </div>
                      </div>
                      <p className="mt-2 text-sm">
                        This is a consolidated recommendation about this
                        candidate based on the team’s feedback
                      </p>
                    </div>

                    <div>
                      {prepedScoreArr.map((rating, index) => {
                        const textColor =
                          rating.average >= 5
                            ? 'rgba(47, 128, 237, 1)'
                            : rating.average >= 4 && rating.average < 5
                            ? 'rgba(39, 174, 96, 1)'
                            : rating.average >= 3 && rating.average < 4
                            ? 'rgba(226, 185, 59, 1)'
                            : rating.average >= 2 && rating.average < 3
                            ? 'rgba(226, 130, 59, 1)'
                            : rating.average == 0
                            ? 'rgba(166, 166, 166, 1)'
                            : 'rgba(235, 87, 87, 1)'
                        return (
                          <div key={index} className="mt-6 py-4 px-4 shadow">
                            <div className="mt-6 flex items-center gap-3">
                              <p className="text-sm font-bold">{rating.name}</p>
                              <div className="flex">
                                {Array(5)
                                  .fill(rating.average)
                                  .map((_, index) =>
                                    rating.average >= index + 1 ? (
                                      <AiFillStar
                                        className="text-[18px]"
                                        key={index}
                                        style={{ color: textColor }}
                                      />
                                    ) : (
                                      <AiOutlineStar
                                        key={index}
                                        className="text-[18px]"
                                        // onMouseOver={() => !number && setHoverStar(index + 1)}
                                        // onMouseLeave={() => setHoverStar(undefined)}
                                        style={{ color: '#A6A6A6' }}
                                      />
                                    )
                                  )}
                              </div>
                              <p
                                className="w-fit px-4 py-1 text-xs text-white"
                                style={{ background: textColor }}
                              >
                                {ratingDescription(rating.average)}
                              </p>
                            </div>
                            {roundsData.interviewScorecard.map(
                              (scoreRating, index) => {
                                if (scoreRating.name == rating.name)
                                  return (
                                    <p className="mt-4 text-sm" key={index}>
                                      {scoreRating.description}
                                    </p>
                                  )
                              }
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : (
                  <EmptyState
                    className="mt-[10rem]"
                    maxWidthClass="max-w-[260px]"
                    icon={
                      <AlignBottom
                        size="32"
                        color="#FF8A65"
                        className="mx-auto"
                      />
                    }
                    description="No consolidated Feedback"
                  />
                ))}
            </div>
          </motion.div>
          <style jsx>{`
            .hint-content {
              display: none;
            }

            .hint-button:hover + .hint-content {
              display: block;
            }
          `}</style>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
