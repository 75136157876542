import { useQuery } from '@tanstack/react-query'

import { fetchData, recommendedJobKeys } from '@/utils'
import {  userIDStorage } from '@/features/auth'
import { Job } from '../types'

interface RecommendJobResponse {
  status: string
  message: string
  data: Job[]
}

export const useRecommendedJobs = () => {
  const userId = userIDStorage.getUserID()
  return useQuery<RecommendJobResponse>({
    queryKey: recommendedJobKeys.list(userId),
    queryFn: async () => fetchData(`/user/recommended-jobs/${userId}`),
  })
}
