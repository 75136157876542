import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {  fetchData, userKeys } from '@/utils';
import { CompanyEditPreferencePayload } from '../types';



export const useCompanyEditPreference = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: CompanyEditPreferencePayload) =>
      fetchData<CompanyEditPreferencePayload>("", 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Company Profile successfully updated.');
        queryClient.invalidateQueries({
          queryKey: userKeys.all,

        //   change the keys when the apii comes
        });
      },
      onError: () => {
        toast.error('Company Preference successfully updated.');
      },
    }
  );
};