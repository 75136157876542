import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
// import dayjs from 'dayjs'

import { backdropVariant, InterviewDetailsModalAnim } from '@/utils'
// import { Calendar2, Clock, Copy } from 'iconsax-react'
// import { BsXLg } from 'react-icons/bs'
// import { InterviewModalOpenIcons } from './ModalIcons'
// import clsx from 'clsx'
import { IntervieweeData } from '@/features/job-seeker'
import { ApplicantInterviewSideView } from '../ApplicantInterviewSideView'
import { InterviewNav } from '../InterviewNav'
import { ApplicantProfileView } from '../ApplicantProfileView'
import Image from 'next/image'
import { useGetUserProfile, useScorecards } from '../../api'
import { InterviewSectionView } from '../InterviewSectionView'
import { InterviewAnalysis } from '../InterviewAnalysis'
import ActivityLog from '../ActivityLog'
// import { useRouter } from 'next/router'

interface InterviewModalProps {
  showInterviewDetails: boolean
  setShowInterviewDetails: (value: boolean) => void
  data?: IntervieweeData[]
  jobTitle?: string | undefined
  userId: string
  jobId: string | string[]
  getInterviewId: string
}

export const interViewStages = [
  {
    name: 'Aptitude and written test round',
    id: '1',
    activated: true,
    passed: true,
  },
  {
    name: 'Technical Interview round',
    id: '2',
    activated: true,
    passed: true,
  },
  {
    name: 'HR Interview Round',
    id: '3',
    activated: false,
    passed: false,
  },
  {
    name: 'Culture Fit Round',
    id: '4',
    activated: false,
    passed: false,
  },
  // {
  //   name: 'Stage 1',
  //   id: '5',
  //   activated: false,
  //   passed: false,
  // },
]

export interface NavType {
  name: string
  id: string
}
export const nav: NavType[] = [
  {
    name: 'Profile',
    id: '1',
  },
  {
    name: 'CV/Resume',
    id: '2',
  },
  {
    name: 'Interviews',
    id: '3',
  },
  {
    name: 'Analysis',
    id: '4',
  },
 
]

export const InterviewDetails: React.FC<InterviewModalProps> = ({
  showInterviewDetails,
  setShowInterviewDetails,
  data,
  userId,
  jobId,
  getInterviewId,
}) => {
  const [activeNavId, setActiveNavId] = React.useState('1')
  const { data: userProfile } = useGetUserProfile(userId as string)
  const [openFeedbackFormModal, setOpenFeedbackFormModal] =
    React.useState(false)

  const resumeUrl = userProfile?.data?.resumeUrl

  // const router = useRouter()
  // const { id: jobId } = router.query

  const { data: scoreCards } = useScorecards()
  const scoreCardData = scoreCards?.data

  const sentApplicantData = data?.find((dataSent) => dataSent.userId == userId)

  // const startTime =
  //   interviewSchedule &&
  //   dayjs('2023-03-03' + interviewSchedule[0].interviewStartTime).format(
  //     'hh:mm A'
  //   )
  // const endTime =
  //   interviewSchedule &&
  //   dayjs('2023-03-03' + interviewSchedule[0].interviewEndTime).format(
  //     'hh:mm A'
  //   )

  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        showInterviewDetails &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowInterviewDetails(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showInterviewDetails])

  // const activityLog = [
  //   {
  //     activityInitiator: 'Toluwani Abiola (Me)',
  //     activity: 'Declined reschedule date',
  //     time: '13 Mar 2023 • 5:30 PM',
  //     reason: 'recruitment schedule is rigid and can not be altered',
  //   },
  //   {
  //     activityInitiator: 'Sayo Abiola (Applicant)',
  //     activity: 'reschedule interview date',
  //     time: '13 Mar 2023 • 5:00 PM',
  //     reason: 'schedule clashed with an important even in my family',
  //   },
  // ]

  return (
    <AnimatePresence>
      {showInterviewDetails && (
        <motion.div
          className="fixed top-0 left-0  z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide absolute top-0 right-0 box-border h-screen w-[100%] overflow-scroll rounded-lg  bg-[#EFEFEF] px-6 pt-5 "
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={InterviewDetailsModalAnim}
          >
            <div className="flex items-stretch gap-6">
              {/* first row Applicant quick side view */}
              <div className="sticky top-0">
                <ApplicantInterviewSideView
                  data={sentApplicantData || undefined}
                />
              </div>

              {/* second row */}
              <div className="w-full">
                <div className="sticky top-0 border-b-4 bg-white pl-10 pt-5">
                  <InterviewNav
                    showCancelButton={true}
                    nav={nav}
                    activeNavId={activeNavId}
                    setActiveNavId={setActiveNavId}
                    setShowInterviewDetails={setShowInterviewDetails}
                  />
                </div>
                <div className="mainContent scrollbar-hide w-full overflow-y-scroll rounded-md bg-white px-10 ">
                  {activeNavId == '1' && (
                    <div>
                      <ApplicantProfileView
                        showExperience={true}
                        id={userId || ''}
                      />
                    </div>
                  )}

                  {activeNavId == '2' && (
                    <div className="px-10">
                      <div className="mt-8 h-[80%] w-full rounded-lg border border-green-600 bg-white px-8 pt-5">
                        {resumeUrl ? (
                          <div className="p-5">
                            <iframe
                              src={resumeUrl || '-'}
                              style={{ width: '100%', height: '725px' }}
                            ></iframe>
                          </div>
                        ) : (
                          <div className="grid  place-items-center py-80">
                            <Image
                              src="/assets/upload.png"
                              height={40}
                              width={40}
                              alt="school logo"
                              className="mt-10 mb-4"
                            />
                            <p className="text-xs">No resume to show</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {activeNavId == '3' && (
                    <div>
                      <InterviewSectionView
                        openFeedbackFormModal={openFeedbackFormModal}
                        setOpenFeedbackFormModal={setOpenFeedbackFormModal}
                        id={userId || ''}
                        interviewId={getInterviewId || ''}
                        jobId={jobId || ''}
                      />
                    </div>
                  )}
                  {activeNavId == '4' && (
                    <div>
                      <InterviewAnalysis
                        scoreCardData={scoreCardData || []}
                        interviewId={getInterviewId || ''}
                      />
                    </div>
                  )}
                  {activeNavId == '5' && (
                    <div className="mt-5">
                      <ActivityLog />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>

          <style>
            {`
                .mainContent{
                  height: calc(100vh - 6.5rem)
                }
                `}
          </style>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
