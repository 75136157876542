import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { companyJobKeys, fetchData, transformError } from '@/utils'
import { ChangeJobStatusPayload } from '../types'
import { AxiosError } from 'axios'
import { useCompanyId } from '../hooks'

export const useChangeJobStatus = (jobId: string | string[] | undefined) => {
  interface changeStatusResponse {
    status: string
    message: string
    data: null
}
const companyId =  useCompanyId()
  const queryClient = useQueryClient()
  return useMutation<changeStatusResponse, AxiosError, ChangeJobStatusPayload>(
    (payload: ChangeJobStatusPayload) => fetchData(`/job/change-job-status/${jobId}`, 'PUT', payload),
    {
      onSuccess: () => {
        toast.success('Job status successfully changed')
        queryClient.invalidateQueries({
          queryKey: companyJobKeys.list(companyId),
        })
      },
      onError: (error: AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
