import { useQuery } from '@tanstack/react-query';

import { UserEducation, userIDStorage, } from '@/features/auth';
import { axiosInstance } from '@/lib/axios';
import { useAuth } from '@/contexts/Authentication';
import { userEducation } from '@/utils';

export const getUserEducation = async (userID: string): Promise<UserEducation> => {
    const { data } = await axiosInstance.get(`/user/education/${userID}`);
    return data.data;
  };

  export const useUserEducation = () => {
    const { authState } = useAuth();
    const userID = userIDStorage.getUserID();
  
    return useQuery({
      queryKey: userEducation.list(userID),
      queryFn: async () => getUserEducation(userID),
      // cacheTime: 1000 * 60 * 5,
      // Fetch for user only when user is authenticated and user exists
      enabled: authState.isAuthenticated && !!userID,
    });
  }