import { useMutation } from '@tanstack/react-query'

import { fetchData } from '@/utils'

interface JobTitle {
  id: number
  jobTitle: string
}

interface ResumeJobTitleResponse {
  status: string
  message: string
  data: JobTitle[]
}

interface JobTitlePayload {
  title: string
}

export const useJobTitles = () => {
  return useMutation<ResumeJobTitleResponse, unknown, JobTitlePayload>({
    mutationKey: ['job-titles'],
    mutationFn: (payload: JobTitlePayload) =>
      fetchData<JobTitlePayload>('/common/job-titles', 'POST', payload),
  })
}
