import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { toast } from 'react-toastify';

import { fetchData, userAvailabilityKeys } from '@/utils';

export interface UserAvailabilityProp {
  status: string
}

export const useUserAvailability = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UserAvailabilityProp) =>
      fetchData<UserAvailabilityProp>("/user/change-job-search-availability", 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: userAvailabilityKeys.all,
        });
      },
    }
  );
};