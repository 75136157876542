import { useQuery } from '@tanstack/react-query'

import { fetchData, interviewJobKeys } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { JobInterviewForJobSeekers } from '../types'

interface InterviewJobResponse {
  status: string
  message: string
  data: JobInterviewForJobSeekers[]
}

export const useInterviewJobs = () => {
  const userId = userIDStorage.getUserID()
  return useQuery<InterviewJobResponse>({
    queryKey: interviewJobKeys.list(userId),
    queryFn: async () => fetchData(`/user/interviews/${userId}`),
  })
}