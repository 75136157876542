import { BsThreeDotsVertical } from 'react-icons/bs'

import { ClosedToOffers, OpenToOffers, ReadyToInterview } from './Elements'

interface interviewObj {
  image: string
  name: string
  bg: string
}

export interface Applicant {
  firstName: string
  lastName?: string
  role: string
  company?: string
  email: string
  phone: string
  status: string
  photoUrl: string
  stage?: string
  interviewer?: undefined | interviewObj[]
}

interface ApplicantCardProps {
  key: number
  applicantInfo: Applicant
}

export const ApplicantCard = ({ applicantInfo, key }: ApplicantCardProps) => {

  return (
    <div
      className="relative mt-5 flex flex-col border border-[#efefef] items-center overflow-hidden rounded-lg bg-white py-6 px-6 shadow-lg transition duration-100 ease-in-out hover:shadow-sm"
      key={key}
    >
      <BsThreeDotsVertical className="absolute top-2 right-1 text-[#A6A6A6] " />
      {applicantInfo.photoUrl ? (
        <img
          src={applicantInfo.photoUrl}
          alt="setUpProfile"
          className="profile-picture mb-2 w-[60px] h-[60px] min-h-[60px] min-w-[60px]"
        />
      ) : (
        <div>
          <p className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-black text-2xl font-[600] text-white">
            {applicantInfo.firstName[0]}
          </p>
        </div>
      )}

      <p className="mt-3 text-center text-md font-medium capitalize">
        {applicantInfo.firstName} {applicantInfo.lastName}
      </p>
      <p className="mt-1 text-center text-xs">{applicantInfo.email}</p>
      <p className="mt-1 text-center text-xs">{applicantInfo.phone}</p>
      {/* <p className="mt-1 text-center text-xs">{applicantInfo.company}</p> */}
      <div className="mt-4">
        {applicantInfo.status == 'actively looking' ? (
          <ReadyToInterview />
        ) : applicantInfo.status == 'open to opportunities' ? (
          <OpenToOffers />
        ) : (
          <ClosedToOffers />
        )}
      </div>
    </div>
  )
}
