
import { fetchData, savedJobKeys, transformError } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const useUnSaveJob = () => {
    const queryClient = useQueryClient();
    
    return useMutation<Response, AxiosError, string>(
        (jobId: string) => fetchData(`/user/unsaved-job/${jobId}`, 'DELETE'),
        {
            onSuccess: () => {
                toast.success('job unsaved')
                queryClient.invalidateQueries({
                    queryKey: savedJobKeys.all
                });
                // queryClient.invalidateQueries({
                //     queryKey: savedJobKeys.detail(jobId)
                // });
            },
            onError: (error) => {
                toast.error(transformError(error))
            },
        }
    )
}