import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { BsXLg } from 'react-icons/bs'
import Image from 'next/image'

import { backdropVariant, publicProfile } from '@/utils'
import { JobApplicant } from '@/features/recruiter'
import {
  useApplicantExperience,
  useGetEducation,
  useGetSkills,
} from '../../api'
import { EmptyState, PageSpinner } from '@/components'
import dayjs from 'dayjs'
import { Call, EmptyWallet, Location, Sms } from 'iconsax-react'
import { User } from '@/features/auth'
import { Avatar } from '@mui/material'
import { COLORS } from '@/data'

interface ExperienceDescriptionProps {
  text: string
}

const ExperienceDescription = ({ text }: ExperienceDescriptionProps) => {
  const [showMore, setShowMore] = useState(false)

  return (
    <>
      {text != null && (
        <p className="mt-2 text-xs">
          {showMore ? text : `${text.substring(0, 120)}`}{' '}
          {text.length > 80 && (
            <button
              className="text-blue-500 "
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? ' show less' : ' ...show more'}
            </button>
          )}
        </p>
      )}
    </>
  )
}

interface ApplicantProfileModalProps {
  applicant: JobApplicant | undefined
  openApplicantProfileModal: boolean
  setOpenApplicantProfileModal: (value: boolean) => void
  userProfileData: User | undefined
}

export const ApplicantProfileModal: React.FC<ApplicantProfileModalProps> = ({
  applicant,
  openApplicantProfileModal,
  setOpenApplicantProfileModal,
  userProfileData,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const {
    photoUrl,
    firstName,
    resumeUrl,
    lastName,
    email,
    phone,
    location,
    description,
  } = userProfileData || {}

  // const {applications } = useJobApplicants || {}

  const jobResumeUrl = applicant?.jobResumeUrl

  const { data: experienceData, isLoading: isLoadingExperience } =
    useApplicantExperience(applicant?.userId)

  const experiences = experienceData?.data || []

  const sortedExperience = experiences.sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
  )

  const { data: educationData } = useGetEducation(applicant?.userId as string)
  const educations = educationData?.data || []
  const { data: skillsData, isLoading: isLoadingSkills } = useGetSkills(
    applicant?.userId as string
  )
  const skills = skillsData?.data || []

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openApplicantProfileModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenApplicantProfileModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openApplicantProfileModal])


console.log({resumeUrl})
  return (
    <AnimatePresence>
      {openApplicantProfileModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            className="absolute bottom-0 left-0 z-50 h-full w-full overflow-scroll bg-[#EFEFEF] px-5 pt-8 text-black sm:px-16"
            variants={publicProfile}
            ref={ref}
          >
            <div className="top-0  left-0 mx-auto max-w-[1500px] ">
              <div className="flex justify-between">
                <div className="flex gap-8">
                  {/* <p className="text-left text-2xl font-bold">
                  {firstName} {lastName}
                  </p> */}
                </div>
                <div className="flex items-center gap-5 ">
                  <button
                    className="rounded-full bg-gray-200 p-2 text-[9px]"
                    onClick={() => setOpenApplicantProfileModal(false)}
                  >
                    <BsXLg />
                  </button>
                </div>
              </div>
            </div>
            <div className="relative mt-5 grid grid-cols-1 items-start justify-between gap-8 rounded-lg xl:grid-cols-2">
              <div
                className=" relative  flex-1 overflow-y-scroll rounded-lg"
                style={{ height: 'calc(100vh - 100px)' }}
              >
                <img
                  src="/assets/backdropProfile.png"
                  alt="backdrop picture"
                  height={100}
                  className="w-full"
                />
                <div className="flex flex-wrap gap-2 rounded-b-lg bg-white pb-6 text-black">
                  <div className="h-fit w-fit rounded-full pl-[30px]">
                    <img
                      className="profile-picture mt-[-40px] h-[85px] w-[85px] flex-1"
                      src={photoUrl || ''}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 pr-6 pl-8 text-left sm:pl-0">
                    <div className="flex items-start justify-between">
                      <div>
                        <p className="mt-3 text-2xl font-medium">
                          {firstName} {lastName}
                        </p>
                        {/* <p className="mt-1  text-sm">
                          {currentJob} • {yearsOfExperience} years
                        </p> */}
                        <p className="flex items-center text-[14px] font-light">
                          <Sms size="14" color="#FF8A65" />{' '}
                          <span className="mx-2">{email}</span>
                          <Call size="14" color="#FF8A65" />{' '}
                          <span className="ml-2">{phone}</span>
                        </p>
                        <p className="flex items-center text-[14px] font-light">
                          <Location size="14" color="#FF8A65" />{' '}
                          <span className="ml-2">{location}</span>
                        </p>
                      </div>
                    </div>

                    <hr className="mt-4" />
                    <p className="mt-2 w-full text-sm font-light leading-6">
                      {description}
                    </p>
                  </div>
                </div>

                <div className="mt-6 rounded-lg bg-white px-6 py-8 text-black">
                  <div className="text flex justify-between">
                    <p className="text-base font-bold">Work Experience</p>
                  </div>
                  {!isLoadingExperience ? (
                    <>
                      {experiences.length > 0 ? (
                        <>
                          <div className="mt-4 grid gap-4 md:grid-cols-2">
                            {sortedExperience.map(
                              (
                                {
                                  id,
                                  companyName,
                                  title,
                                  employmentType,
                                  workType,
                                  startDate,
                                  endDate,
                                  responsibilities,
                                },
                                index
                              ) => {
                                const formattedStartDate =
                                  dayjs(startDate).format('MMM, YYYY')
                                const formattedEndDate = endDate
                                  ? dayjs(endDate).format('MMM, YYYY')
                                  : 'Present'
                                let duration = ''
                                if (endDate) {
                                  const e = dayjs(endDate)
                                  duration = dayjs(startDate).to(e, true)
                                } else {
                                  duration = dayjs(startDate).to(dayjs(), true)
                                }
                                return (
                                  <div
                                    className="flex  gap-5 border-gray-100 py-3"
                                    key={id}
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: COLORS[index],
                                        width: 40,
                                        height: 40,
                                        borderRadius: '5px',
                                      }}
                                    >
                                      <span className="text-2xl uppercase">
                                        {companyName[0]}
                                      </span>
                                    </Avatar>
                                    <div className="group relative flex-1">
                                      <div className="flex justify-between">
                                        <p className="flex-1  text-sm font-bold line-clamp-1">
                                          {title}
                                        </p>
                                      </div>
                                      <p className="text-xs">{companyName}</p>
                                      <p className="text-xs text-gray-250">
                                        {formattedStartDate} -{' '}
                                        {formattedEndDate}
                                        {' • '}
                                        {duration}
                                      </p>
                                      <p className="mt-2 text-xs">
                                        <span className="bg-[#F2F7FF] py-1 px-2 font-medium capitalize text-[#575698]">
                                          {employmentType}
                                        </span>{' '}
                                        <span className="bg-[#FCF8F3] py-1 px-2 font-medium capitalize text-[#ED7200]">
                                          {workType}
                                        </span>
                                      </p>
                                      <ExperienceDescription
                                        text={responsibilities}
                                      />
                                    </div>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <EmptyState
                          className="mt-16"
                          maxWidthClass="max-w-[260px]"
                          icon={
                            <EmptyWallet
                              size="32"
                              className="mx-auto text-primary"
                              variant="Bulk"
                            />
                          }
                          description="Experience hasn't been added yet."
                        />
                      )}
                    </>
                  ) : (
                    <PageSpinner className="mt-14" />
                  )}
                </div>

                {/* education section */}
                <div className="mt-6 rounded-lg bg-white px-6 py-8 text-black">
                  <div className="text flex justify-between">
                    <p className="text-base font-bold">Education</p>
                  </div>
                  <div className="mt-4 grid grid-cols-1 sm:grid-cols-2">
                    {educations.map(
                      ({
                        endDate,
                        grade,
                        institute,
                        qualification,
                        startDate,
                        id,
                      }) => (
                        <div key={id} className="mb-5 flex gap-4">
                          <div>
                            <Image
                              src="/assets/school.png"
                              height={40}
                              width={40}
                              alt="school logo"
                            />
                          </div>
                          <div>
                            <p className="text-sm font-bold">{institute}</p>
                            <p className="mt-1 text-xs">
                              {qualification} {' - '}{' '}
                              <span className="font-medium capitalize">
                                {grade}
                              </span>
                            </p>
                            <p className="mt-1 text-xs">
                              {dayjs(startDate).format('MMMM, YYYY')} -{' '}
                              {dayjs(endDate).format('MMMM, YYYY')}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                {/* skills */}
                <div className="mt-6 rounded-lg bg-white px-6 py-8 text-black">
                  <div className="text flex justify-between">
                    <p className="text-base font-bold">Skills</p>
                  </div>
                  {isLoadingSkills ? (
                    <PageSpinner className="mt-14" />
                  ) : (
                    <>
                      {skills && skills?.length > 0 ? (
                        <>
                          <div className="mt-4 flex flex-wrap justify-start gap-5">
                            {skills?.map((info, index) => (
                              <div
                                key={index}
                                className="group flex items-center justify-between gap-2  overflow-hidden rounded-full bg-black py-2 px-4 text-sm  transition duration-100  ease-in-out"
                              >
                                <p className="w-fit whitespace-nowrap text-sm text-white ">
                                  {info.name}
                                </p>
                                <span className="-mr-2  hidden rounded-full bg-gray-600 p-1 text-[7px] text-white group-hover:block"></span>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <EmptyState
                          className="mt-10"
                          maxWidthClass="max-w-[260px]"
                          icon={
                            <EmptyWallet
                              size="32"
                              className="mx-auto text-primary"
                              variant="Bulk"
                            />
                          }
                          description="No skills to show."
                        />
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* second grid column  */}
              <div className="h-full w-full rounded-lg border border-green-600 bg-white">
                {!resumeUrl && !jobResumeUrl ? (
                  <div className="grid  place-items-center py-80">
                    <Image
                      src="/assets/upload.png"
                      height={40}
                      width={40}
                      alt="school logo"
                      className="mt-10 mb-4"
                    />
                    <p className="text-xs">No resume to show</p>
                  </div>
                ) : (
                  <div className="p-5 ">
                    <iframe
                      src={jobResumeUrl || resumeUrl || '-'}
                      style={{ width: '100%', height: '765px' }}
                    />
                  </div>
                )}
              </div>
              {/* <div className="w-full lg:w-[423px]">
                <div className="flex h-fit items-center justify-between rounded-t-lg bg-white px-6 pt-8 pb-5">
                  <h1 className="text-base text-black">Work Experience</h1>
                </div>
                <div
                  className="scrollbar-hide overflow-scroll rounded-b-lg text-left text-black"
                  style={{ height: 'calc(100vh - 233px)' }}
                >
                  <div className="-mt-10 h-full bg-white px-6 pt-5 pb-6 ">
                    {!isLoadingExperience ? (
                      <>
                        {experiences.length > 0 ? (
                          experiences.map(
                            ({
                              companyName,
                              endDate,
                              responsibilities,
                              startDate,
                              title,
                              workType,
                              id,
                            }) => (
                              <div className="mt-8 flex gap-5" key={id}>
                                
                                <div className="flex-1">
                                  <p className="text-sm  font-bold">{title}</p>
                                  <p className="text-xs">{companyName}</p>
                                  <p className="text-xs">
                                    {dayjs(startDate).format('MMMM, YYYY')} -{' '}
                                    {dayjs(endDate).format('MMMM, YYYY')}
                                  </p>
                                  <p className="text-xs">{workType}</p>
                                  <p className="text-xs">{responsibilities}</p>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <EmptyState
                            className="mt-16"
                            maxWidthClass="max-w-[260px]"
                            icon={
                              <EmptyWallet
                                size="32"
                                className="mx-auto text-primary"
                                variant="Bulk"
                              />
                            }
                            description="No work experience"
                          />
                        )}
                      </>
                    ) : (
                      <PageSpinner className="mt-14" />
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
