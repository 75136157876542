import { CompanyIdContext } from '@/features/CompanyIdContext'
import { RecruiterNavDashboard } from './RecruiterNavDashboard'
// import { FAQModal } from '@/components'
import { motion } from 'framer-motion'
import { companyIDStorage } from '@/features/auth'
import { useState, useMemo } from 'react'

interface RecruiterDashboardLayoutProps {
  children: React.ReactNode
}

export const RecruiterDashboardLayout: React.FC<
  RecruiterDashboardLayoutProps
> = ({ children }) => {
  // const [openFAQ, setOpenFAQ] = useState(false)
  const companyID = companyIDStorage.getCompanyID()

  const getCompanyId = (id: string) => {
    return id
  }

  const [contextCompanyID, setContextCompanyID] = useState<string>(
    getCompanyId(companyID)
  )
  const providedCompanyId = useMemo(
    () => ({ contextCompanyID, setContextCompanyID }),
    [contextCompanyID, setContextCompanyID]
  )
  return (
    <CompanyIdContext.Provider value={providedCompanyId}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'linear', duration: 0.1 }}
        className="scrollbar-hide h-screen overflow-y-auto bg-[#EFEFEF] px-[15px] pt-[80px] sm:px-[50px] md:pt-[110px]"
      >
        <RecruiterNavDashboard />
        <div>
          {children}
          {/* <p
          onClick={() => setOpenFAQ(true)}
          className="overflow fixed bottom-[10px] right-[40px] flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-primary text-lg text-white"
        >
          ?
        </p> */}
        </div>
        {/* <FAQModal openFAQ={openFAQ} setOpenFAQ={setOpenFAQ} /> */}
      </motion.div>
    </CompanyIdContext.Provider>
  )
}
