import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useEffect } from 'react'
import { BsXLg } from 'react-icons/bs'

import { backdropVariant, lookingFor } from '@/utils'
import { Control, useForm } from 'react-hook-form'
import { InputUseSkillsAutoComplete } from '../Form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  ManageSkillForm,
  manageSkillSchema,
  useAddSkill,
} from '@/features/job-seeker'
import { AddCircle } from 'iconsax-react'

interface SkillModalProps {
  skillOpen: boolean
  setSkillOpen: (value: boolean) => void
  // skillNames: string[]
}

export const SkillModal: React.FC<SkillModalProps> = ({
  skillOpen,
  setSkillOpen,
  // skillNames
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const mutateAddSkills = useAddSkill()
  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (skillOpen && ref.current && !ref.current.contains(e.target)) {
        setSkillOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [skillOpen])


  const {
    reset,
    // register,
    control,
    // setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ManageSkillForm>({
    defaultValues: {
      skills: [],
    },

    resolver: yupResolver(manageSkillSchema),
  })

  const handleSuccess = () => {
    setSkillOpen(false)
    reset()
  }

  const onSubmit = (values: ManageSkillForm) => {
    mutateAddSkills.mutate(values, {
      onSuccess: handleSuccess,
    })
  }

  return (
    <AnimatePresence>
      {skillOpen && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] overflow-y-auto w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[50%] left-[50%] min-h-[300px] w-[90%] md:w-[524px] overflow-scroll  rounded-lg bg-white px-4 md:px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={lookingFor}
          >
            <div>
              <div>
                <div>
                  <p className="text-base font-bold">Top Skills</p>
                  <button
                    className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                    onClick={
                      () => {
                        setSkillOpen(false)
                        reset()
                      } 
                    }
                  >
                    <BsXLg />
                  </button>
                </div>
              </div>
              <p className="text-xs mt-5 md:mt-0">
                Adding your skills boosts your chances of getting employed.
                profile
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-8 flex gap-2"
              >
                <InputUseSkillsAutoComplete
                  control={control as unknown as Control}
                  hasError={errors.skills}
                  isMultiple
                  name="skills"
                  // skillNames={skillNames}
                />
                <button className="flex h-[3rem] w-[3rem] items-center justify-center bg-black text-center text-2xl text-white shadow-[3px_3px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-3px_3px_0px_0px_#191919]">
                  <AddCircle />
                </button>
              </form>
              {/* <p className="mt-4 text-xs">You can add up to skills</p> */}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
