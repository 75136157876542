import { StarRatingInput } from '@/components'
import React from 'react'

type Props = {
  value: number
  onChange: (rating: number) => void
}
export const StarRatingComponent = ({ value, onChange }: Props) => {
  const count = 5
  return (
    <div>
      <StarRatingInput
        setActiveStar={onChange}
        activeStar={value}
        count={count}
      />
    </div>
  )
}
