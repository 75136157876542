import { AnimatePresence, motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import { backdropVariant, lookingFor } from '@/utils'
import { TextAreaField } from '../Form'
import { Button } from '../Elements'
import {
  bioSchema,
  LookingForForm,
  LookingForPayload,
  useLookingFor,
} from '@/features/job-seeker'
import { yupResolver } from '@hookform/resolvers/yup'

interface LookingForModalProps {
  lookingForModal: boolean
  setLookingForModal: (value: boolean) => void
  bioData: string | null | undefined
}

export const LookingForModal: React.FC<LookingForModalProps> = ({
  lookingForModal,
  setLookingForModal,
  bioData
}) => {
  const {
    register,
    watch,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<LookingForForm>({
    defaultValues: {
      bio: '',
    },
    resolver: yupResolver(bioSchema)
  })
  const mutationAddLookingFor = useLookingFor()  //looking for has been changed to bio in the ui so this applies to it
  const ref = React.useRef<HTMLInputElement>(null)
  const bio = watch('bio')

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (lookingForModal && ref.current && !ref.current.contains(e.target)) {
        setLookingForModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [lookingForModal])

  const onSubmit = (data: LookingForForm) => {

    const payload: LookingForPayload = {
      bio: data.bio
    }

    const handleSuccess = () => {
      setLookingForModal(false)
      reset()
    }

    mutationAddLookingFor.mutate(payload, {
      onSuccess: handleSuccess
    })
  }

  React.useEffect(() => {
    if (bioData) {
      reset({
        bio: bioData
      })
    }
  }, [bioData])




  return (
    <AnimatePresence>
      {lookingForModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100]  h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[50%] left-[50%] w-[90%] md:w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={lookingFor}
          >
            <div>
              <div>
                <p className="text-base font-bold">Bio</p>
                <button
                  type="button"
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setLookingForModal(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className=" mt-4 flex w-fit items-center gap-3 rounded-md border-2 border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 px-3 py-2 text-black ">
                <p className="text-xs ">
                  Your public profile is a preview of what recruiters see when
                  they view your profile
                </p>
              </div> */}
              <div className='mt-5'>
                <TextAreaField
                  className="mt-2"
                  id="lookingFor"
                  placeholder="Enter your bio info"
                  registration={{ ...register('bio') }}
                  hasError={errors.bio}
                  rows={5}
                  value={bio}
                />
              </div>
              <div className="mt-8">
                <Button type="submit">{'Save'}</Button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
