import { useQuery } from '@tanstack/react-query'

import { invitedMembersKeys } from '@/utils'
import { InvitedMembers } from '../types'
import { useCompanyId } from '../hooks'
import { fetchData } from '@/utils'

interface InvitedMembersResp {
  status: string
  message: string
  data: InvitedMembers[]
}

export const useGetInvitedMembers = () => {
  const companyID =  useCompanyId()
  return useQuery<InvitedMembersResp>({
    queryKey: invitedMembersKeys.list(companyID),
    queryFn: async () => fetchData(`/company/team-members/${companyID}`),
  })
}
