import { FaChevronDown } from 'react-icons/fa'
import React from 'react'

type optionsType = {
  options: string[]
}

export const MultipleInput = ({ options }: optionsType) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const [selectOpen, setSelectOpen] = React.useState(false)
  const [selected, setSelected] = React.useState<string[]>([])

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (selectOpen && ref.current && !ref.current.contains(e.target)) {
        setSelectOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [selectOpen])

  return (
    <div>
      <div
        className={
          selected.length > 3
            ? 'scrollbar-hide relative flex h-12 w-full items-start justify-between overflow-scroll rounded-md border border-[#d3d3d3] px-3 py-2 '
            : 'scrollbar-hide flex h-12 w-full items-center justify-between overflow-scroll rounded-md border border-[#d3d3d3] px-3 py-2'
        }
        ref={ref}
        onClick={() => setSelectOpen(!selectOpen)}
      >
        <div className="flex flex-wrap gap-1">
          {selected.map((select, Index) => (
            <p
              key={Index}
              className="rounded-sm bg-[#d3d3d3] px-2 py-1 text-xs text-black"
            >
              {select}
            </p>
          ))}
        </div>
        {selected.length > 3 ? (
          ''
        ) : (
          <FaChevronDown className="justify-self-start text-[#d3d3d3]" />
        )}
      </div>
      {selectOpen && (
        <div
          ref={ref}
          className="scrollbar-hide absolute mt-4 h-[200px] w-full overflow-y-scroll  rounded-md bg-[#f7f7f7] shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
        >
          {options.map((name: string, Index) => (
            <div key={Index}>
              <p
                className="py-2 px-3 text-left text-sm font-[500] transition ease-in-out hover:bg-black hover:text-white"
                onClick={() => setSelected([...selected, name])}
              >
                {name}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
