import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import React from 'react'

import { backdropVariant, searchModal } from '@/utils'

interface SearchModalProps {
  showSearch: boolean
  setShowSearch: (value: boolean) => void
}

export const SearchModal: React.FC<SearchModalProps> = ({
  setShowSearch,
  showSearch,
}) => {
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (showSearch && ref.current && !ref.current.contains(e.target)) {
        setShowSearch(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showSearch])

  return (
    <AnimatePresence>
      {showSearch && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[20%] left-[50%]  w-[524px] overflow-scroll  rounded-lg bg-white px-1 py-1"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={searchModal}
          >
            <div className="flex h-[45px] w-full flex-row-reverse items-center gap-2 border-r-[0] bg-transparent  px-4 ">
              <input
                type="text"
                className="w-full bg-transparent text-sm outline-none"
                placeholder="eg. jobs"
              />
              <Image
                src="/assets/globalsearch.png"
                width={20}
                height={20}
                alt="setUpProfile"
              />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
