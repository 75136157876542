import { allJobsForApprovalKeys, companyKeys, fetchData, jobApplicantKeys, transformError } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface HireApplicantPayload {
    jobId: string | undefined | string[] 
    userId:  string | undefined
}
export const useHireApplicants = () => {
    const queryClient = useQueryClient();
    
    return useMutation<Response, AxiosError, HireApplicantPayload>(
        (payload) => fetchData('/company/applicants/hire-candidate', 'POST', payload),
        {
            onSuccess: () => {
                toast.success('Candidate hired')
                queryClient.invalidateQueries({
                    queryKey: allJobsForApprovalKeys.all
                });
                queryClient.invalidateQueries({
                    queryKey: jobApplicantKeys.all
                });
                queryClient.invalidateQueries({
                    queryKey: companyKeys.all
                });
               
            },
            onError: (error) => {
                toast.error(transformError(error))
            },
        }
    )
}
