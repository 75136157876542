import { useMutation } from '@tanstack/react-query'
import { useAuth } from '@/contexts/Authentication'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

import { axiosInstance, setAxiosDefaultToken } from '@/lib/axios'
import { tokenStorage, userIDStorage } from '../utils'
import { VerificationResponse } from '../types'
import { useRedirection } from '../hooks'
import { fetchData } from '@/utils'
import { getUser } from './'

interface Payload {
  email: string
  verificationToken: string
}

export const useVerificationCode = () => {
  const { authDispatch } = useAuth()
  const { redirect } = useRedirection()

  return useMutation<VerificationResponse, AxiosError, Payload>(
    (payload) => fetchData<Payload>('/auth/verify', 'POST', payload),
    {
      onSuccess: async (response) => {
        const {
          data: { userId, accessToken },
        } = response

        // set token
        tokenStorage.setToken(accessToken)
        setAxiosDefaultToken(accessToken, axiosInstance)

        userIDStorage.setUserID(userId)

        const user = await getUser(userId)

        if (authDispatch) {
          authDispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          })

          authDispatch({ type: 'STOP_LOADING' })
        }
        redirect(user)
      },
      onError: () => {
        toast.error('Invalid verification code')
      },
    }
  )
}
