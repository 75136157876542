import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'
import Image from 'next/image'
import React from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'

import { ApplicantOptionInJobs } from '@/components'
import { CompanyJob } from '../types'
import { BsThreeDotsVertical } from 'react-icons/bs'

interface AppliedJobCardProps {
  jobInfo: CompanyJob
  companyData: CompanyJob[]
  handleOptionClick: (index: number) => void
  index: number
  active: number[]
  jobId: string[] | undefined
  selectedJobId: number
  openViewJobPostModal: boolean
  setOpenViewJobPostModal: (value: boolean) => void
  handleSetId: (index: number) => void
  openExtendDeadlineModal: boolean
  setOpenExtendDeadlineModal: (value: boolean) => void
}

export const AppliedJobCard: React.FC<AppliedJobCardProps> = ({
  jobInfo,
  companyData,
  handleOptionClick,
  index,
  active,
  jobId,
  selectedJobId,
  openViewJobPostModal,
  setOpenViewJobPostModal,
  openExtendDeadlineModal,
  setOpenExtendDeadlineModal,
  handleSetId,
}) => {
  const formattedPostedDate = dayjs(jobInfo.datePosted).fromNow()
  const formattedValidThrough = dayjs(jobInfo.validThrough).toNow(true)

  const handleOpenOptions = () => {
    handleOptionClick(index)
  }

  return (
    <div
      className="relative flex flex-col items-center rounded-lg bg-white py-6 px-6 shadow-lg transition duration-100 ease-in-out hover:shadow-sm"
      key={index}
    >
      {active.includes(index) && (
        <ApplicantOptionInJobs
          setOpenViewJobPostModal={setOpenViewJobPostModal}
          openViewJobPostModal={openViewJobPostModal}
          jobPostDetail={companyData && companyData[selectedJobId]}
          jobPostId={jobId && jobId[selectedJobId]}
          title={jobInfo.title}
          isPublished={jobInfo.status === 'published'}
          setOpenExtendDeadlineModal={setOpenExtendDeadlineModal}
          openExtendDeadlineModal={openExtendDeadlineModal}
        />
      )}
      <button
        className="absolute  top-2 right-0 p-5 text-[#A6A6A6] hover:text-black"
        onClick={handleOpenOptions}
      >
        <BsThreeDotsVertical />
      </button>

      <div
        className="mt-5 cursor-pointer text-center text-lg font-bold line-clamp-1"
        onClick={() => setOpenViewJobPostModal(true)}
      >
        {jobInfo.title}
      </div>
      <div className="mt-2 flex gap-2">
        <p
          style={{
            backgroundColor: 'rgba(229, 79, 32, 0.1)',
            color: '#E54F20',
          }}
          className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
        >
          {jobInfo.employmentType}
        </p>
        <p
          style={{
            backgroundColor: 'rgba(39, 174, 96, 0.1)',
            color: '#27AE60',
          }}
          className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
        >
          {jobInfo.workType}
        </p>
        <p
          style={{
            backgroundColor: 'rgba(229, 32, 222, 0.1)',
            color: '#E520DE',
          }}
          className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
        >
          {jobInfo.level}
        </p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <button
          style={{
            width: 121,
            height: 121,
            marginTop: '1rem',
          }}
          onClick={() => {
            handleSetId(index)
          }}
        >
          <CircularProgressbarWithChildren
            value={jobInfo.applicants}
            strokeWidth={3}
            styles={buildStyles({
              textColor: 'black',
              pathColor: '#27AE60',
            })}
          >
            <div className="flex flex-col items-center">
              <p className="text-[32px] font-bold ">{jobInfo.applicants}</p>
              <p className="text-xs">
                Applicant{jobInfo.applicants > 1 ? 's' : ''}
              </p>
            </div>
          </CircularProgressbarWithChildren>
        </button>
        <p className="mt-2 text-xs text-[#A6A6A6]">
          Posted {formattedPostedDate}
        </p>
        <p className="mt-2 text-xs" style={{ color: '#27AE60' }}>
          Closing in {formattedValidThrough}
        </p>
        <div className="mt-4 flex items-center gap-2">
          {/* <div
  className={clsx(
    'flex items-center justify-center gap-2 rounded-sm border border-black  px-2 py-1 font-[700] ',
    jobInfo.isSpotlighted && 'bg-[#2F80ED]'
  )}
>
  <Image
    src="/assets/sync.png"
    width={15.53}
    height={13.61}
    alt="setUpProfile"
  />
  {true ? (
    <p className="text-xs text-white">ATS Synced </p>
  ) : (
    <p className="text-xs text-black">Sync ATS</p>
  )}
</div> */}
          <div
            className={clsx(
              'flex gap-2 rounded-sm border border-black  px-2 py-1 font-[700] ',
              jobInfo.status === 'published' && 'bg-[#27AE60] text-white'
            )}
          >
            <Image
              src="/assets/spotlight.png"
              width={15.53}
              height={13.61}
              alt="setUpProfile"
            />
            {jobInfo.status === 'published' ? (
              <p className="text-xs text-white">Publish</p>
            ) : (
              <p className="text-xs text-black">Unpublished</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
