import { useQuery } from '@tanstack/react-query';


import { fetchData } from '@/utils';
import { Option } from '@/types';

interface IndustryResponse {
  status: string;
  message: string;
  data: Option[];
}

export const useIndustries = () => {
  return useQuery<IndustryResponse>({
    queryKey: ['industries'],
    queryFn: async () => fetchData('/common/industries'),
  });
};
