import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { educationKeys, fetchData } from '@/utils'
import { ManageEducationPayload } from '../types'

export const useEditEducation = (educationId: string | undefined) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: ManageEducationPayload) =>
      fetchData<ManageEducationPayload>(
        `/user/education/edit/${educationId}`,
        'PUT',
        payload
      ),
    {
      onSuccess: () => {
        toast.success('Education successfully edited.')
        queryClient.invalidateQueries({
          queryKey: educationKeys.all,
        })
      },
      onError: () => {
        toast.error('Education not edited.')
      },
    }
  )
}
