import { useQuery } from '@tanstack/react-query'
import { Education, Experience } from '../types'
import { fetchData, userKeys } from '@/utils'

interface Skills {
  id: string
  name: string
}

interface PublicProfileProps {
  firstName: string
  lastName: string
  email: string
  phone: string
  gender: string
  dateOfBirth: string
  yearsOfExperience: string
  location: string
  bio: string
  jobSearchStatus: string
  photoUrl: string
  resumeUrl: string
  website: string
  skills: Skills[]
  experience: Experience[]
  education: Education[]

}
interface PublicProfileResponse {
  status: string
  message: string
  data: PublicProfileProps
}

export const usePublicProfile = (userId: string) => {
  return useQuery<PublicProfileResponse>(
    {
      queryKey: userKeys.list(userId),
      queryFn: async () => fetchData(`/user/public-profile/${userId}`),

    }
  )
}
