import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

export const OnboardingDone = () => {
  const router = useRouter()

  React.useEffect(() => {
    const FOUR_SECONDS = 4000
    const timeout = setTimeout(() => {
      router.push('/job-seeker/dashboard')
    }, FOUR_SECONDS)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="flex pb-10 ">
      <div className="m-auto mt-[5rem] flex max-w-[450px] flex-col items-center text-center">
        <Image
          src="/assets/doneAnimation.gif"
          height={227}
          width={226}
          alt="setUpProfile"
        />
        <h2 className="text-[24px]">
          You have successfully created an account
        </h2>
        <p className="text-base">Congratulations!! Now you can Scout4Job 🎉</p>
      </div>
    </div>
  )
}
