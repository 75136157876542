import { AnimatePresence, motion } from 'framer-motion'

import { editPreference } from '@/utils'
import { FieldErrors } from 'react-hook-form'
import { PostAJobForm } from '@/features/recruiter'
import { Tooltip } from '@mui/material'


interface ErrorListModalProp {
  errors: FieldErrors<PostAJobForm>
}

export const ErrorListModal = ({ errors }: ErrorListModalProp) => {
  const ErrorNames = (error: string) => {
    if (error == 'address') {
      return 'Address,'
    }
    if (error == 'availableOpenings') {
      return 'Available Openings,'
    }
    if (error == 'currency') {
      return 'Currency,'
    }
    if (error == 'deadline') {
      return 'Deadline,'
    }
    if (error == 'location') {
      return 'Address,'
    }
    if (error == 'isSpotlighted') {
      return 'Spotlighted,'
    }
    if (error == 'minSalary') {
      return 'Min Salary,'
    }
    if (error == 'industries') {
      return 'Industries,'
    }
    if (error == 'typeOfEmployment') {
      return 'Employment type,'
    }
    if (error == 'jobTitle') {
      return 'Job Title,'
    }
    if (error == 'jobLevel') {
      return 'Job Level,'
    }
    if (error == 'minimumQualification') {
      return 'Minimum Qualification,'
    }
    if (error == 'jobDuration') {
      return 'Job Duration,'
    }
    if (error == 'jobSummary') {
      return 'Job Summary,'
    }
    if (error == 'experienceLength') {
      return 'Experience Length,'
    }
    if (error == 'salaryTerm') {
      return 'Salary Term,'
    }
    if (error == 'maxSalary') {
      return 'Maximum Salary,'
    }
    if (error == 'workPlaceType') {
      return 'Workplace Type,'
    }
    if (error == 'workExperience') {
      return 'Work Experience,'
    }
    if (error == 'typeOfEmployment') {
      return 'Type of Employment,'
    }
  }

  return (
    <AnimatePresence>
      <motion.div
        className=" absolute mt-10 sm:mt-0 top-[10%] left-[50%] z-50 m-auto h-auto w-[80%] rounded-lg border-r-2 border-b-2 border-red-400 bg-white shadow-lg"
        initial="initial"
        animate="show"
        exit="exit"
        variants={editPreference}
      >
        <div className="relative h-full w-full px-6 py-4 ">
          <div className="flex w-[90%] flex-wrap gap-x-4 gap-y-2 text-red-400 text-[8px] sm:text-xs">
            {Object.keys(errors).map((error, index) => {
              return <div key={index}>{ErrorNames(error)}</div>
            })}
          </div>
          <Tooltip title="You have un-filled fields" >
            <button className="absolute top-4 right-4 flex h-[25px] w-[25px] min-w-[25px] items-center justify-center rounded-full bg-red-600 font-Outfit font-bold text-white">
              !
            </button>
          </Tooltip>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
