import { useQuery } from '@tanstack/react-query'

import { jobApplicantKeys, fetchData } from '@/utils'
import { JobApplicant } from '@/features/job-seeker'
// import { companyIDStorage } from '@/features/auth'
import { useCompanyId } from '../hooks'

interface AllApplicantResponse {
  status: string
  message: string
  data: JobApplicant[]
}

export const useAllJobApplicants = () => {
  const companyId =  useCompanyId()
  return useQuery<AllApplicantResponse>({
    queryKey: jobApplicantKeys.list(companyId),
    queryFn: async () => fetchData(`/company/all-job-applicants/${companyId}`),
    enabled: !!companyId
  })
}
