import { useQuery } from '@tanstack/react-query'

import { companyJobKeys, fetchData } from '@/utils'
import { Job } from '../types'

interface JobsResponse {
  status: string
  message: string
  data: Job[]
}

export const useJobs = () => {
  return useQuery<JobsResponse>({
    queryKey: companyJobKeys.lists(),
    queryFn: async () => fetchData("/job/all-jobs"),
  })
}
