import { isValidPhoneNumber } from 'react-phone-number-input'
import { object, string, ref } from 'yup'

import { axiosInstance } from '@/lib/axios'

export const signUpValidationSchema = object({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  phone: string()
    .test('validation-phone', 'Invalid phone number', (value = '') => {
      return isValidPhoneNumber(value)
    })
    .required('Required'),
  email: string()
    .email('Email address is invalid')
    .required('Required')
    .test(
      'Unique Email',
      'An account with this email exists.', // <- key, message
      function (value) {
        return new Promise((resolve) => {
          axiosInstance
            .post('/auth/check-user-email', { email: value })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        })
      }
    ),
  password: string()
    .test(
      'validation-password',
      'Password must be at least 8 characters including at least one lowercase, one uppercase, one number and special character',
      (value = '') => {
        const regEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/
        return regEx.test(value)
      }
    )
    .required('Required'),
  confirmPassword: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required('Required'),
})

export const loginValidationSchema = object({
  email: string().email('Invalid email').required('Email is required'),
  password: string().required('Required'),
})

export const forgotPasswordValidationSchema = object({
  email: string().email('Invalid email').required('Email is required'),
})

export const resetPasswordValidationSchema = object({
  password: string()
    .test(
      'validation-password',
      'Password must be at least 8 characters including at least one lowercase, one uppercase, one number and special character',
      (value = '') => {
        const regEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/
        return regEx.test(value)
      }
    )
    .required('Required'),
  confirmPassword: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required('Required'),
})

export const addNewUserSchema = object({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  email: string()
    .email('Email address is invalid')
    .required('Required'),
 
  phone: string()
    .test('validation-phone', 'Invalid phone number', (value = '') => {
      return isValidPhoneNumber(value)
    })
    .required('Required'),
  password: string()
    .test(
      'validation-password',
      'Password must be at least 8 characters including at least one lowercase, one uppercase, one number and special character',
      (value = '') => {
        const regEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/
        return regEx.test(value)
      }
    )
    .required('Required'),
  confirmPassword: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required('Required'),
})
