import { Spinner } from "./Elements"

interface SkillsProps {
    title: string
    deleteSkill: ()=> void
    isLoading: boolean
}
export const SkillsComponent = ({deleteSkill, title, isLoading}:SkillsProps) => {
    return (
        <>
        
            <div
              className="flex items-center justify-between gap-2  overflow-hidden rounded-full bg-black py-2 px-4 text-sm group transition duration-100  ease-in-out"
            >
              <p className="w-fit whitespace-nowrap text-sm text-white ">
                {title}
              </p>
              <button type="button" onClick={deleteSkill} className="-mr-2 hidden text-[11px] text-white group-hover:block">
                {isLoading ? (<Spinner size="sm" className="text-current" />) : (<span>x</span>)}
                </button>
            </div>
      </>
    )
}