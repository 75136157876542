import { HiOutlineEnvelope } from 'react-icons/hi2'
import { BsDashCircle } from 'react-icons/bs'
import { IoMdCheckmark } from 'react-icons/io'
import Image from 'next/image'
import React from 'react'

interface OnboardingStep {
  title: string
  description: string
  id: number
}

interface OnboardingSideBarProps {
  currentStep: number | undefined
  onboardingContentSteps: OnboardingStep[]
}

export const OnboardingSideBar: React.FC<OnboardingSideBarProps> = ({
  currentStep = 0,
  onboardingContentSteps,
}) => {
  return (
    <div className="onboardBack sticky top-0 box-border  h-[100vh] min-w-[418px] px-14 py-16 pt-[52px] text-white">
      <div className="flex h-full flex-col justify-between">
        <div>
          <div>
            <Image
              src="/assets/logo.png"
              alt="scout4Job-logo"
              className="md:cursor-pointer"
              height={28.21}
              width={185.08}
            />
          </div>
          <div>
            {onboardingContentSteps.map((step, index) => {
              const completed = currentStep >= index
              return (
                <div key={step.id} className="mt-10 flex items-start gap-5">
                  {completed ? (
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-primary">
                      <IoMdCheckmark className="w-3" />
                    </div>
                  ) : (
                    <div className="flex h-5 w-5 items-center justify-center">
                      <BsDashCircle className="mt-[2px]" />
                    </div>
                  )}
                  <div>
                    <h3 className="whitespace-nowrap text-sm font-[600]">
                      {step.title}
                    </h3>
                    <p className="mt-4 whitespace-nowrap text-sm text-[#A6A6A6]">
                      {step.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex justify-between text-xs">
          <div className="flex items-center gap-2">
            <span>
              <HiOutlineEnvelope />
            </span>
            <p className=""> help@scout4job.com</p>
          </div>
          <p>© Scout4Job 2023</p>
        </div>
      </div>
    </div>
  )
}
