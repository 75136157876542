import {
  CalendarEdit,
  ExportCurve,
  ProfileDelete,
  ProfileTick,
} from 'iconsax-react'
import clsx from 'clsx'

const interViewModalicons = [
  <ExportCurve key={1} size="18" color="#27AE60" variant="Bulk" />,
  <CalendarEdit key={2} size="18" color="#E2B93B" variant="Bulk" />,
  <ProfileTick key={3} size="18" color="#27AE60" variant="Bulk" />,
  <ProfileDelete key={4} size="18" color="#EB5757" variant="Bulk" />,
]

export const InterviewModalOpenIcons = () => {
  return (
    <div className='flex items-center gap-3'>
      {interViewModalicons.map((icon, index) => (
        <button
          key={index}
          className={clsx(
            index === 0
              ? 'bg-[#27AE60]'
              : index === 1
              ? 'bg-[#E2B93B]'
              : index === 2
              ? 'bg-[#27AE60]'
              : 'bg-[#EB5757]',
            'flex h-[30px] w-[30px] items-center justify-center rounded-full bg-opacity-10'
          )}
        >
          {icon}
        </button>
      ))}
    </div>
  )
}
