import { useQuery } from '@tanstack/react-query';


import { companyLogoKeys, fetchData } from '@/utils';
import { CompanyLogo } from '@/types';

interface LevelResponse {
  status: string;
  message: string;
  data: CompanyLogo[];
}

export const useCompanyLogos = () => {
  return useQuery<LevelResponse>({
    queryKey: companyLogoKeys.lists(),
    queryFn: async () => fetchData('/common/company-logos'),
  });
};