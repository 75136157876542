import { useJobTypes } from '@/api'

export const useJobTypeOptions = () => {
  const { data, isLoading:jobTypeLoading } = useJobTypes()
  const jobTypes = data?.data.map(({ id, type }) => ({ id, name: type })) || []
  return {
    jobTypes,
    jobTypeLoading
  }
}
