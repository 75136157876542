import { RwhatImportantInJob } from '@/data'

export const RecruiterOnboardingCompanyCulture = () => {
  return (
    <div className="pb-10">
      <div className="m-auto max-w-[652px] text-center">
        <p>Step 3/5</p>
        <h2 className="text-[40px]">Tell us your company’s work culture</h2>
        <p className="">
          Tell us about your company’s work interests and preferences and we will{' '}
          <br /> find you the perfect candidate.
        </p>
      </div>
      <div className="mt-10">
        <p className="text-base font-bold">
          What type of employees do you prefer to work with?
        </p>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="employees-r" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Technical problem solvers
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="employees-r" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Product builders
            </label>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <p className="text-base font-bold">
          What type of goal driven employee are you looking for?
        </p>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="career-r" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Individual contributor
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="career-r" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Manager
            </label>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <p className="text-base font-bold">
          What would you describe that your work environment is like?
        </p>
        <div className="mt-4 items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="responsibity" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Clear role and set of responsibilities. Consistent feedback from
              management.
            </label>
          </div>
          <div className="mt-4 flex items-center gap-2">
            <input id="Co-founder" type="radio" name="responsibity" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Employees wear a lot of hats. Assignments often require employees
              to figure it out on their own.
            </label>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <p className="text-base font-bold">
          What type of flexibility are you willing to offer the candidate? (Max
          2)
        </p>
        <div className=" flex w-full flex-wrap gap-x-[10rem]">
          {RwhatImportantInJob.map((stuff, Index) => (
            <div
              className="mt-4 flex  w-[40%] items-center gap-6 self-start"
              key={Index}
            >
              <div className="round">
                <input type="checkbox" id="jobImportant" />
                <label htmlFor="jobImportant"></label>
              </div>
              <p className="text-sm">{stuff}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-10">
        <p className="text-base font-bold">
          How flexible is your company’s remote work policy?
        </p>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="work-policy-R" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Fully remote
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="work-policy-R" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Hybrid
            </label>
          </div>
          <div className="flex items-center  gap-2">
            <input id="Co-founder" type="radio" name="work-policy-R" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Onsite
            </label>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <p className="text-base font-bold">
          How quiet is your office environment?
        </p>
        <div className="mt-4 flex items-center gap-[4rem]">
          <div className="flex items-center gap-2">
            <input id="Intern" type="radio" name="quiet" /> {' '}
            <label className="text-sm" htmlFor="Intern">
              Very quiet
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input id="Co-founder" type="radio" name="quiet" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Quiet
            </label>
          </div>
          <div className="flex items-center  gap-2">
            <input id="Co-founder" type="radio" name="quiet" /> {' '}
            <label className="text-sm" htmlFor="Co-founder">
              Not so quiet
            </label>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <p className="text-base font-bold">
          * Describe what you are looking for in your next candidate
        </p>
        <p className="mt-4 text-[#A6A6A6]">
          Job seekers tell us this as one of the first things they want
        </p>
        <textarea
          name=""
          id=""
          placeholder="I'm looking for e.g, an enthusiastic engineering team to work for that will provide me with..."
          className="mt-4 h-[10rem] w-full resize-none rounded-sm border  border-[#A6A6A6] p-6 text-sm outline-none"
          // value={}
        ></textarea>
      </div>
    </div>
  )
}
