import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef } from 'react'
import { backdropVariant, reportJob } from '@/utils'
// import { BsXLg } from 'react-icons/bs'

import { Button } from '../Elements'
import Link from 'next/link'

interface LoginPopUpProps {
  openLoginPopUp: boolean
  setOpenLoginPopUp: (value: boolean) => void
  action: string

}

export const LoginPopUp: React.FC<LoginPopUpProps> = ({
  openLoginPopUp,
  setOpenLoginPopUp,
  action

}) => {
  const ref = useRef<HTMLInputElement>(null)



  return (
    <AnimatePresence>
      {openLoginPopUp && (
        <motion.div
          className="absolute bottom-0 left-0 z-[100] h-screen w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide scrollbar-hide absolute top-[50%] left-[50%] w-[90%] overflow-scroll rounded-lg  bg-white px-4 py-6 pt-7 md:w-[524px] md:px-8 md:pt-6"
            // style={{ height: 'calc(100vh - 156px)' }}
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={reportJob}
          >
            <div className='text-center'>
              <p className="mt-2 mb-10 text-[16px] font-medium ">You have to be logged in to {action}</p>
              {/* <span
                className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                onClick={() => setOpenLoginPopUp(false)}
              >
                <BsXLg />
              </span> */}
            </div>

            <div className='flex justify-center items-center gap-x-6'>

              <Button
                    type="button"
                    variant="outline"
                    onClick={() => setOpenLoginPopUp(false)}
                  >
                    Cancel
                  </Button>
              <Button type="submit" variant="primary">
                <Link href='/login'>
               Proceed to Login
                </Link>
              </Button>
            </div>


          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
