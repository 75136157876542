import { useLevels } from "@/api"


export const useLevelOptions = () => {
  const { data, isLoading:isLevelsLoading } = useLevels()
  const levelOptions = data?.data || []
  return {
    levelOptions,
    isLevelsLoading,
  }
}
