import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { fetchData } from '@/utils'
import { toast } from 'react-toastify'

interface ChangePasswordPayload {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export const useChangePassword = (userId: string) => {
  return useMutation<Response, AxiosError, ChangePasswordPayload>(
    (payload: ChangePasswordPayload) =>
      fetchData(`/user/change-password/${userId}`, 'PUT', payload),
    {
      onSuccess: () => {
        toast.success('Password successfully changed.')
      },
      onError: () => {
        toast.error('Current password not correct')
      },
    }
  )
}
