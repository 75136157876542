import * as React from 'react'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'

function valuetext(value: number) {
  return `${value} age`
}

interface RangeSliderProps {
  minValue: number
  maxValue: number
}

export const RangeSlider = ({ minValue, maxValue }: RangeSliderProps) => {
  const [value, setValue] = React.useState<number[]>([minValue, maxValue])

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[])
  }

  return (
    <Box sx={{ width: 220 }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  )
}
