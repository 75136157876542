import { Mobile, Sms } from 'iconsax-react'
import React from 'react'

import { IntervieweeData } from '@/features/job-seeker'
// import { Tooltip } from '@mui/material'

interface ApplicantInterviewSideViewProp {
  data: IntervieweeData | undefined
}

export const ApplicantInterviewSideView = ({
  data,
}: ApplicantInterviewSideViewProp) => {
  
  return (
    <div className="sideCard1 scrollbar-hide sticky top-0 h-full w-[350px] overflow-y-scroll rounded-lg bg-white px-6 pb-5 pt-[30px]">
      {/* name info */}
    { data && <div
        className="mx-auto flex w-fit flex-col items-center justify-center px-10 pb-6
text-center"
      >
        <div className='flex gap-6 items-center'>
          {/* <Tooltip title="Move to previous interviewee">
          <button className="rounded-full bg-black p-3">
            <Previous size="10" color="white" variant="Bulk" />
          </button>
          </Tooltip> */}

          <div className="">
            {data?.photoUrl == '' ? (
              <div>
                <p
                  style={{ background: 'black' }}
                  className="flex h-[98px] w-[98px] items-center justify-center rounded-full border-[3px] border-white text-sm font-bold"
                >
                  {data?.firstName} {data?.lastName}
                </p>
              </div>
            ) : (
              <img
                src={data?.photoUrl || ''}
                className="profile-picture h-[98px] w-[98px] rounded-full"
                alt="applicant"
              />
            )}
          </div>
          {/* <Tooltip title="Move to next interviewee">
          <button className=" rounded-full bg-black p-3">
            <Next size="10" color="white" variant="Bulk" />
          </button>
          </Tooltip> */}
        </div>
        <p className="text-bold">
          {data?.firstName} {data.lastName}
        </p>
        <p className="text-xs text-[#A6A6A6]">Lagos, Nigeria</p>
        <p className="mt-2 rounded-md bg-[#27AE60] bg-opacity-10 px-4 py-2 text-xs text-[#27AE60]">
          Interview Ongoing
        </p>
      </div>}

      {/* stages  */}
      {/* <div className="mt-6 pb-6">
        <div className=" flex items-center justify-center">
          {interViewStages.map((stage, index) => {
            return (
              <div key={index} className="flex items-center">
                {index > 0 && index < interViewStages.length && (
                  <span
                    className={clsx(
                      'h-[2px] w-3',
                      stage.activated ? 'bg-primary' : 'bg-[#E3E3E3]'
                    )}
                  />
                )}
                <Tooltip title={stage.name}>
                  <p
                    className={clsx(
                      'flex h-[30px] w-[30px] items-center justify-center rounded-md text-xs',
                      stage.activated
                        ? 'bg-primary text-white'
                        : 'bg-[#E3E3E3] text-[#A6A6A6]'
                    )}
                  >
                    {stage.id}
                  </p>
                </Tooltip>
              </div>
            )
          })}
        </div>
       <div className="mx-auto mt-8 w-[90%] rounded-md bg-black px-6 py-3">
          <p className="text-xs font-[600] text-white">
            Current Interview Round
          </p>
          <p className="mt-2 text-xs text-[#A6A6A6]">
            Technical Interview Round
          </p>
        </div>
        <div className="mx-10 border-b border-b-[#E5E5E5] pb-6"></div>
      </div> */}

      {/* Contacts */}
      <div className="mt-6 border-y border-y-[#E5E5E5] py-4">
        <p className="text-sm font-bold ">Contacts</p>
        <div>
          <div className="mt-4 flex items-center justify-between  text-xs">
            <div className="flex items-center gap-2">
              <Mobile size="17" color="#A6A6A6" variant="TwoTone" />
              <p className="text-xs text-[#A6A6A6]">Phone Number</p>
            </div>
            <p>{data?.phone}</p>
          </div>
          <div className="mt-5 flex items-center justify-between text-xs">
            <div className="flex items-center gap-2">
              <Sms size="17" color="#A6A6A6" variant="TwoTone" />
              <p className=" text-[#A6A6A6]">Email</p>
            </div>
            <p>{data?.email}</p>
          </div>
          {/*  <div className="mt-5 flex items-center justify-between text-xs">
            <div className="flex items-center gap-2">
              <Sms size="17" color="#A6A6A6" variant="TwoTone" />
              <p className=" text-[#A6A6A6]">LinkedIn</p>
            </div>
           <p>{data?.email}</p>
          </div> */}
        </div>
      </div>
      {/* <div className='mt-4'>
          <Image
          src="/assets/linkedin.png"
          height={20}
          width={20}
          alt="linkedin"
          />
        </div> */}

      <style>
        {`
            .sideCard1{
              height: calc(100vh - 2.5rem)
            }
            `}
      </style>
    </div>
  )
}
