import { AnimatePresence, motion } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import { backdropVariant, lookingFor } from '@/utils'

import { InterviewerDetails, InterviewerDetailsPayload } from '../../types'
import { InterviewerDetailsSchema } from '@/utils/validationSchema'
import { Button, InputField } from '@/components'
import { useRolesAndPermissions } from '@/api'
import { useInviteUser } from '../../api'
import { Option } from '@/types'

interface companyInviteMemberProps {
  companyInviteMember: boolean
  setCompanyInviteMember: (value: boolean) => void
  handleUpdateMember?: (option: Option) => void
}

export const CompanyInviteMember: React.FC<companyInviteMemberProps> = ({
  companyInviteMember,
  setCompanyInviteMember,
  handleUpdateMember,
}) => {
  const { data: InterviewerAccess } = useRolesAndPermissions()
  const interviewerAccessData = InterviewerAccess?.data || []
  const mutationAddInterviewer = useInviteUser()
  const [access, setAccess] = React.useState('')

  const ref = React.useRef<HTMLInputElement>(null)
  const {
    formState: { errors },
    register,
    reset,
    handleSubmit,
  } = useForm<InterviewerDetails>({
    resolver: yupResolver(InterviewerDetailsSchema),
  })

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        companyInviteMember &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setCompanyInviteMember(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [companyInviteMember])

  const onSubmit = (values: InterviewerDetails) => {
    const userAccessData = interviewerAccessData?.find(
      (element) => element.id === values.permission
    )
    const { email } = values
    const payload: InterviewerDetailsPayload = {
      email: email,
      roleId: userAccessData?.id || '',
    }
    mutationAddInterviewer.mutate(payload, {
      onSuccess: ({ data }) => {
        if (handleUpdateMember) {
          const option: Option = {
            name: data.email,
            id: data.id,
          }
          handleUpdateMember(option)
        }
        setCompanyInviteMember(false)
        reset()
      },
    })
  }

  return (
    <AnimatePresence>
      {companyInviteMember && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[50%] left-[50%]  w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={lookingFor}
          >
            <div>
              <div>
                <p className="text-base font-bold">Invite team member</p>
                <button
                  type="button"
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setCompanyInviteMember(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <div className="mt-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="items-start gap-10 pb-10">
                  <div className="flex-1">
                    <InputField
                      label="Email Address"
                      registration={{ ...register('email') }}
                      hasError={errors.email}
                      isRequired
                    />
                  </div>
                  <div className="mt-5">
                    <p className="flex items-center gap-1 text-sm font-semibold">
                      <span className="text-primary">*</span>
                      Interviewer permissions
                    </p>
                    <div className="flex items-center gap-6">
                      <div className="mt-2 flex w-full items-center gap-6 text-sm">
                        {interviewerAccessData?.map((data) => {
                          return (
                            <div
                              key={data.id}
                              className="flex items-center gap-2"
                            >
                              <button
                                type="button"
                                className="flex items-center space-x-2"
                                onClick={() => setAccess(data.id)}
                              >
                                <input
                                  type="radio"
                                  {...register('permission')}
                                  value={data.id}
                                  checked={data.id === access}
                                  id=""
                                />
                                <span>{data.name}</span>
                              </button>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    {!errors?.permission?.message ? (
                      interviewerAccessData?.map((data) => {
                        if (data.id == access)
                          return (
                            <p
                              key={data.id}
                              className="mt-3 text-xs text-primary"
                            >
                              {data.description}
                            </p>
                          )
                      })
                    ) : (
                      <p className="mt-3 text-xs text-red-600">
                        {errors?.permission?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-28 mt-0 flex w-fit justify-center sm:mb-2">
                  <Button
                    disabled={mutationAddInterviewer.isLoading}
                    isLoading={mutationAddInterviewer.isLoading}
                    className="w-full"
                    type="submit"
                  >
                    {mutationAddInterviewer.isLoading
                      ? 'Inviting...'
                      : 'Invite member'}
                  </Button>
                </div>
              </form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
