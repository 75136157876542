import { Avatar, Tooltip } from '@mui/material'
import { BsDot } from 'react-icons/bs'
import Image from 'next/image'
import React from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'

import { ModalAcceptOrDecline } from './ModalAcceptOrDecline'
import { InterviewDetailsJobSeekerModal } from '@/components'
import { JobSeekerApplicationDetailIcons } from '@/data'
import {
  AcceptInterviewPayload,
  InterviewScheduleOption,
  JobInterviewForJobSeekers,
  SlotOption,
} from '../types'
import { useAcceptInterview, useDeclineInterview } from '../api'
import { userIDStorage } from '@/features/auth'
import { toast } from 'react-toastify'
import { useClipboard } from '@/components/Elements/useClipboard'

interface JobSeekerInterviewComponentProp {
  data: JobInterviewForJobSeekers
  index: number
  key?: number
}
export const JobSeekerInterviewComponent = ({
  data,
  index,
}: JobSeekerInterviewComponentProp) => {
  const [
    showInterviewDetailsForJobSeeker,
    setShowInterviewDetailsForJobSeeker,
  ] = React.useState(false)
  const [acceptModal, setAcceptModal] = React.useState(false)
  const [declineModal, setDeclineModal] = React.useState(false)
  const [slotOptions, setSlotOptions] = React.useState<SlotOption[]>([])
  const userId = userIDStorage.getUserID()
  const { copy } = useClipboard()

  const {
    jobId,
    companyName,
    companyLocation,
    companyLogo,
    jobTitle,
    interviewRound,
    hasAccepted,
    interviewOption,
    interviewLink,
    interviewDate,
    interviewStartTime,
    interviewEndTime,
    interviewType,
    isAnonymous = false,
    hasDeclined,
  } = data || {}


  const interviewId = data?.id || ''
  const [reason, setReason] = React.useState('')
  const mutationAccept = useAcceptInterview(interviewId)
  const mutationDecline = useDeclineInterview(interviewId)


  const hasResponded = hasAccepted || hasDeclined

  const formattedStartDate = dayjs(interviewDate).format('MMM DD, YYYY')

  const formattedStartTime = dayjs(
    interviewDate + '' + interviewStartTime
  ).format('hh:mm A')
  const formattedEndTime = dayjs(interviewDate + ' ' + interviewEndTime).format(
    'hh:mm A'
  )

  const handleOpenDeclineModal = () => {
    setDeclineModal(true)
    setShowInterviewDetailsForJobSeeker(false)
  }

  const handleCloseDeclineModal = () => {
    setDeclineModal(false)
  }

  const handleCloseAcceptModal = () => {
    setAcceptModal(false)
  }

  const handleAccept = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    slots: InterviewScheduleOption[] = []
  ) => {
    let payload: AcceptInterviewPayload = {
      userId,
      jobId,
      interviewStartTime: '',
      interviewEndTime: '',
      interviewDate,
      updatedInterviewSchedule: [],
    }

    if (interviewOption === 'option 1') {
      payload = {
        ...payload,
        interviewStartTime,
        interviewEndTime,
      }
    } else if (
      interviewOption === 'option 2' ||
      interviewOption === 'option 3'
    ) {
      const selectedSlot = slotOptions.find((slot) => slot.isSelected)
      
      payload = {
        ...payload,
        interviewDate: selectedSlot?.interviewDate || '',
        interviewStartTime: selectedSlot?.interviewStartTime || '',
        interviewEndTime: selectedSlot?.interviewEndTime || '',
        updatedInterviewSchedule: slots,
      }
    }
    

    mutationAccept.mutate(payload, {
      onSuccess: () => {
        handleCloseAcceptModal()
        setShowInterviewDetailsForJobSeeker(false)
      },
    })
  }

  const handleDecline = () => {

    if (!reason) {
      return toast.error('Please provide a reason')
    }

    mutationDecline.mutate(
      {
        reason: reason,
      },
      {
        onSuccess: () => {
          handleCloseDeclineModal()
          setShowInterviewDetailsForJobSeeker(false)
          setReason('')
        },
      }
    )
  }

  const handleReason = (newReason: string) => {
    setReason(newReason)
  }

  const isPending = !hasAccepted && !hasDeclined

  return (
    <div
      className={clsx(
        'group relative flex flex-col items-stretch justify-start gap-6 border-b border-[#EFEFEF] py-8 shadow-md hover:bg-gray-100 max-[1040px]:p-8 md:shadow-none md:hover:bg-white lg:flex-row'
      )}
      key={index}
    >
      <div className="flex flex-[28%] items-start gap-4">
        {isAnonymous ? (
          <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
            <span className="text-2xl">A</span>
          </Avatar>
        ) : (
          <Image
            src={companyLogo}
            alt={`${companyName} logo`}
            height={50}
            width={50}
          />
        )}
        <div>
          <h2 className="mt-1 text-left text-xs font-bold sm:text-sm">
            {companyName}
          </h2>
          <p className="mt-0 flex items-center text-left text-[10px] text-gray-400 sm:text-xs">
            {isAnonymous ? 'Anonymous' : companyName}
            <BsDot className="text-left text-xs text-gray-400" />{' '}
            {companyLocation}
          </p>
        </div>
      </div>
      <div className="flex-[24%]  lg:border-x lg:border-[#E5E5E5] lg:pl-6">
        <p className="text-xs font-bold">{jobTitle}</p>
        <p className="text-xs my-1">{interviewRound}</p>
        <p
          className={clsx(
            'mt-1 inline-block px-3 py-1 text-xs',
            isPending && 'bg-gray-100 text-secondary',
            hasAccepted && 'bg-green-400 text-white',
            hasDeclined && 'bg-red-500 text-white'
          )}
        >
          {isPending ? (
            'Pending'
          ) : (
            <>
              {hasAccepted && 'Accepted'}
              {hasDeclined && 'Declined'}
            </>
          )}
        </p>
      </div>
      <div className="flex flex-[24%] flex-col  border-r-[#E5E5E5] lg:border-r ">
        {interviewDate && interviewStartTime && interviewEndTime && (
          <p className="mt-1 text-xs">
            {formattedStartDate} | {formattedStartTime} - {formattedEndTime}
          </p>
        )}
        {data?.interviewLink && (
          <button
          onClick={() => copy(interviewLink)}
            className="mt-1 text-xs text-left text-[#2F80ED] underline"
          >
            Interview Link
          </button>
        )}
        <p className="mt-1 text-xs capitalize">{interviewType}</p>
      </div>

      <div className="invisible ml-auto mr-auto flex flex-[24%]  justify-end gap-3 group-hover:visible">
        {JobSeekerApplicationDetailIcons.map((icon) => {
          const handleClick = () => {
            if (icon.id === 1) {
              setShowInterviewDetailsForJobSeeker(true)
            } else if (icon.id === 2) {
              setDeclineModal(true)
            } else if (icon.id === 3) {
              setAcceptModal(true)
            }
          }

          if (hasResponded && icon.id > 1) {
            return null
          }

          return (
            <Tooltip title={icon.name} key={icon.id}>
              <button onClick={handleClick}>
                <Image
                  src={icon.image}
                  alt="functions"
                  width={30}
                  height={30}
                />
              </button>
            </Tooltip>
          )
        })}
      </div>

      <ModalAcceptOrDecline
        isLoading={mutationAccept.isLoading}
        showDialog={acceptModal}
        closeModal={handleCloseAcceptModal}
        handleChangeStatus={handleAccept}
        isAccept
      />

      <ModalAcceptOrDecline
        isLoading={mutationDecline.isLoading}
        showDialog={declineModal}
        closeModal={handleCloseDeclineModal}
        handleChangeStatus={handleDecline}
        reason={reason}
        handleReason={handleReason}
        isAccept={false}
      />

      <InterviewDetailsJobSeekerModal
        interviewId={data.id}
        slotOptions={slotOptions}
        setSlotOptions={setSlotOptions}
        showInterviewDetailsForJobSeeker={showInterviewDetailsForJobSeeker}
        setShowInterviewDetailsForJobSeeker={
          setShowInterviewDetailsForJobSeeker
        }
        loadingObj={{
          accept: mutationAccept.isLoading,
          decline: mutationDecline.isLoading,
        }}
        handleAccept={handleAccept}
        handleDecline={handleOpenDeclineModal}
      />
    </div>
  )
}
