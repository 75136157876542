import { useQuery } from '@tanstack/react-query'

import { educationKeys, fetchData } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { Education } from '../types'

interface EducationResponse {
  status: string
  message: string
  data: Education[]
}

export const useEducation = () => {
  const userId = userIDStorage.getUserID();
  return useQuery<EducationResponse>({
    queryKey: educationKeys.list(userId),
    queryFn: async () => fetchData(`/user/education/${userId}`),
    enabled: !!userId
  })
}