import Link from 'next/link'
import { getFormattedDayMonthYearWords } from '@/utils'
import { FullPageLoader } from '../auth'
import { colorSet, colorSetWithOpacity } from '../recruiter'
import { Posts } from '@/types'
import { DocumentText } from 'iconsax-react'

interface ArticlesProp {
  filteredArticles: Posts[]
  postLoading: boolean
}
 
export const Articles = ({postLoading, filteredArticles:postData}:ArticlesProp ) => {


  return (
    <>
      {postLoading ? (
        <div>  
          <FullPageLoader bgColor='bg-white' height="h-[85vh]" />
        </div>
      ) : (
        postData.length > 0 ? <div className="mx-auto mb-12 grid max-w-[1500px] grid-cols-1 gap-8 lg:grid-cols-2  xl:grid-cols-3">
          {postData?.map(
            (
              { title, message, createdAt, tags, thumbnailUrl, uniqueSlug, minRead },
              index
            ) => {
              const messageTruncated = message?.slice(0, 120)

              return (
                <Link
                  href={`/resources/articles/${uniqueSlug}`}
                  key={index}
                  className=" overflow-hidden rounded-md bg-white shadow-md"
                >
                  <img
                    src={thumbnailUrl}
                    alt="article"
                    className="w-full bg-cover"
                    height={400}
                  />
                  <div className="px-6">
                    <div className="my-4 flex items-center space-x-3">
                      {tags?.map((tag, index) => {
                        const color =
                          colorSet[(index + 1 - 1) % colorSet.length]
                          const background = colorSetWithOpacity[(index + 1 - 1) % colorSetWithOpacity.length]
                        return (
                          <div
                            key={index}
                            className=" rounded-full font-[500] px-1.5 py-[2px] text-xs"
                            style={{ background: background, color: color }}
                          >
                            {tag}
                          </div>
                        )
                      })}
                    </div>
                    <p className="font-semibold">{title}</p>
                    <p
                      className="my-2 text-sm"
                      dangerouslySetInnerHTML={{
                        __html: messageTruncated + ' ....' || '',
                      }}
                    ></p>
                    <div className="my-3 flex items-center space-x-1">
                      <p className="text-sm text-gray-250 font-light">
                        {getFormattedDayMonthYearWords(createdAt)} | {minRead || ''} Min Read
                      </p>
                    </div>
                  </div>
                </Link>
              )
            }
          )}
        </div> : <div className="min-h-[500px] text-black font-bold flex items-center justify-center">
          <div className="flex items-center justify-center gap-3 flex-col">
            <p>No Articles</p>
            <DocumentText size="30" variant='Bulk' color="#000" />
          </div>
        </div>
      )}
    </>
  )
}
