import { Warning2 } from 'iconsax-react'
import React from 'react'

import { Button } from '..'
import { Modal } from '.'

interface Props {
  isScreening: boolean
  // isTalentPool: boolean
  isShortlisted: boolean
  isInterviewMany: boolean
  isLoading: boolean
  isDisqualifyMany: boolean
  openConfirmMoveMultipleApplicantModal: boolean
  closeModal: () => void
  applicantsCount: number
  handleMoveManyToShortlist: () => void
  handleManyDisqualify: () => void
  setIsManyShortlisted: React.Dispatch<React.SetStateAction<boolean>>
  setIsDisqualifyMany: React.Dispatch<React.SetStateAction<boolean>>
  setIsInterviewMany: React.Dispatch<React.SetStateAction<boolean>>
  handleMoveManyToInterview: () => void
  handleMoveManyToScreening: () => void
}

export const MoveMultipleModal: React.FC<Props> = ({
  // isTalentPool,
  isScreening,
  isShortlisted,
  isDisqualifyMany,
  handleMoveManyToShortlist,
  isLoading,
  openConfirmMoveMultipleApplicantModal,
  closeModal,
  applicantsCount,
  handleManyDisqualify,
  setIsManyShortlisted,
  setIsDisqualifyMany,
  setIsInterviewMany,
  isInterviewMany,
  handleMoveManyToInterview,
  handleMoveManyToScreening,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      setIsManyShortlisted(false)
      setIsDisqualifyMany(false)
      setIsInterviewMany(false)
      closeModal()
    }
  }

  const handleMove = isShortlisted
    ? handleMoveManyToShortlist
    : isDisqualifyMany
    ? handleManyDisqualify
    : isScreening
    ? handleMoveManyToScreening
    : isInterviewMany
    ? handleMoveManyToInterview
    : handleClose
  return (
    <Modal
      className="mt-48"
      variant="middle"
      showDialog={openConfirmMoveMultipleApplicantModal}
      closeModal={handleClose}
    >
      <div className="mx-auto flex h-[190px] w-[560px] items-center overflow-y-auto border border-gray-100 bg-white px-8">
        <div>
          <div className="flex items-start space-x-4">
            <Warning2 size="32" color="#FF8A65" />
            {isScreening && (
              <p className="text-[15px]">
                Are you sure you want to move{' '}
                <span className="font-semibold">
                  {applicantsCount} {applicantsCount === 1 ? 'Applicant' : 'Applicants'}
                </span>{' '}
                to the screening stage ?
              </p>
            )}
            {isShortlisted && (
              <p className="text-[15px]">
                Are you sure you want to shortlist{' '}
                <span className="font-semibold">
                  {applicantsCount} {applicantsCount === 1 ? 'Applicant' : 'Applicants'}
                </span>
                ?
              </p>
            )}
            {/* {isTalentPool && (
              <p className="text-[15px]">
                Are you sure you want to move{' '}
                <span className="font-semibold">
                  {applicantsCount} {applicantsCount === 1 ? 'Applicant' : 'Applicants'}
                </span>{' '}
                to talent pool
              </p>
            )} */}
            {isDisqualifyMany && (
              <p className="text-[15px]">
                Are you sure you want to disqualify{' '}
                <span className="font-semibold">
                  {applicantsCount} {applicantsCount === 1 ? 'Applicant' : 'Applicants'}
                </span>{' '}
              </p>
            )}
            {isInterviewMany && (
              <p className="text-[15px]">
                Are you sure you want to Schedule Interviews for these{' '}
                <span className="font-semibold">
                  {applicantsCount} {applicantsCount === 1 ? 'Applicant' : 'Applicants'}
                </span>{' '}
              </p>
            )}
          </div>

          <hr className="border-gray-450 my-6" />

          <div className="flex justify-end space-x-6">
            <Button
              disabled={isLoading}
              onClick={handleClose}
              variant="outline"
              size="xs"
            >
              Cancel
            </Button>
            <Button
              className="bg-error-300"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleMove}
              size="xs"
            >
              {isLoading ? 'Moving...' : 'Move'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
