import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { backdropVariant, publicProfile } from '@/utils'
import { BsDot, BsXLg } from 'react-icons/bs'
import { FullPageLoader } from '@/features/auth'
import { useViewCompanyProfile } from '@/features/job-seeker'
import { Profile2User } from 'iconsax-react'
import Image from 'next/image'
import { RiDoubleQuotesL } from 'react-icons/ri'
import { JobCard } from '../JobCard'
import { JobDetails } from './JobDetails'

interface CompanyProfileModalProp {
  setOpenViewCompanyProfile: (value: boolean) => void
  openViewCompanyProfile: boolean
  companyId: string
}

const ViewCompanyProfileJobSeeker = ({
  openViewCompanyProfile,
  setOpenViewCompanyProfile,
  companyId,
}: CompanyProfileModalProp) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const jobDetailIdRef = React.useRef('')

  const { data: companyData, isLoading } = useViewCompanyProfile(
    companyId as string
  )

  const {
    companyLogo,
    companyName,
    companyDescription,
    industry,
    companyLocation,
    perks,
    hired,
    applicants,
    jobs,
    employeeCount,
  } = companyData?.data || {}

  const handleOpenModal = (jobId: string) => {
    jobDetailIdRef.current = jobId
    setModalOpen(true)
  }

  return (
    <AnimatePresence>
      {openViewCompanyProfile && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            className="scrollbar-hide absolute bottom-0 left-0 z-50 h-full w-full overflow-scroll bg-[#EFEFEF] pb-16 text-black"
            variants={publicProfile}
          >
            <div className="bg-white py-6">
              <div className="mx-auto flex max-w-[1500px] justify-between px-4 sm:px-16 ">
                <p className="text-left text-2xl font-bold">Company Profile</p>
                <button
                  className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-gray-200 text-[9px]"
                  onClick={() => setOpenViewCompanyProfile(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>

            {isLoading ? (
              <FullPageLoader height="h-[85vh]" />
            ) : (
              <>
                <div className="mx-auto max-w-[1500px]">
                  <div className="px-4 sm:px-16">
                    <div className="grid-row mt-5 grid gap-6  lg:grid-cols-12 ">
                      <div className="col-span-8 rounded-lg bg-white px-6 py-6">
                        <div className="group my-2 flex items-center gap-6 border-b-[#E5E5E5] bg-white pb-4">
                          <div className="h-[80px] min-h-[80px] w-[80px] min-w-[80px] overflow-hidden rounded-md sm:h-[115px] sm:min-h-[115px] sm:w-[115px] sm:min-w-[115px]">
                            <img
                              className=""
                              alt=""
                              src={companyLogo || ''}
                            />
                          </div>
                          <div className=" sm:pr-10">
                            <div className="flex items-center gap-4">
                              <p className="font-bold sm:text-xl">
                                {companyName}
                              </p>
                            </div>
                            <p className="mt-2 text-xs">
                              {industry} Company in
                            </p>
                            <div className="mt-2 flex gap-1">
                              <p className="w-fit bg-[#EFEFEF] px-2 py-1 text-xs">
                                {companyLocation}
                              </p>
                            </div>
                            <div className="mt-1 hidden items-center gap-2 sm:flex">
                              <Profile2User
                                size="20"
                                color="#27AE60"
                                variant="Bulk"
                              />
                              <p className="mt-1 text-xs text-black">
                                {} {employeeCount} employees
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-1 flex items-center gap-2 sm:hidden">
                          <Profile2User
                            size="20"
                            color="#27AE60"
                            variant="Bulk"
                          />
                          <p className="mt-1 text-xs text-black">
                            {} {employeeCount} employees
                          </p>
                        </div>
                        <div className="mt-2 md:ml-[120px]">
                          <div className="group">
                            <div className="mt-3 flex items-center gap-10 md:-ml-8">
                              <div className="ml-[-8px] hidden h-[19px] w-[19px] items-center justify-center rounded-full bg-primary bg-opacity-10 text-xs text-primary md:flex">
                                <RiDoubleQuotesL />
                              </div>
                              <p className="text-sm text-[#A6A6A6]">
                                About {companyName}
                              </p>
                            </div>
                            <p className="mt-2 w-full pr-0 text-sm leading-6 sm:pr-4 md:ml-5">
                              {companyDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4 w-full rounded-lg bg-white py-6 sm:min-w-[399px]">
                        <p className="border-b border-b-[E5E5E5] px-8 pb-3 text-base font-bold">
                          Scouting Credentials
                        </p>
                        <div className="mt-2 px-8">
                          <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Jobs Posted:{' '}
                            <span className="font-bold">{' '} {jobs?.length}</span>
                          </p>
                          <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Candidates Applied:{' '}
                            <span className="font-bold">{' '} {applicants}</span>
                          </p>
                          <p className="mt-4 flex items-center text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Applicants Hired: <span> </span>{' '}
                            <span className="font-bold">{' '} {hired}</span>
                          </p>
                          {/* <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Hiring Rate: 0
                          </p>
                          <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Posting Rate: 0
                          </p> */}
                        </div>
                      </div>
                    </div>

                    {/* Office Media */}

                    {/* <div className=" mt-6 rounded-lg bg-white px-6 py-8 text-black">
                      <p className="text-base font-bold">Office Media</p>
                    </div> */}

                    {/* Perks and benefit */}

                    <div className=" mt-6 rounded-lg bg-white px-6 py-8 text-black">
                      <div className="flex items-center gap-3">
                        <p className="text-base font-bold">Perks and benefit</p>
                      </div>
                      {perks?.length === 0 ? (
                        <p className="text-center">No perks specified</p>
                      ) : (
                        <div className="grid grid-cols-1 items-start gap-y-4 gap-x-10 md:grid-cols-2">
                          {perks?.map((perk, index) => (
                            <div
                              key={index}
                              className="group flex items-center gap-4"
                            >
                              <div className="mt-8 flex items-start gap-3">
                                <div className="min-w-[40px] ">
                                  <Image
                                    src="/assets/badgeCompany.png "
                                    alt="badge"
                                    height={40}
                                    width={40}
                                  />
                                </div>
                                <div className="text-sm">
                                  <div className="flex items-center gap-6">
                                    <p className="text-sm font-bold">
                                      {perk.name}
                                    </p>
                                  </div>
                                  <p className="mt-1">{perk.description}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* company jobs */}
                    <div className=" mt-6 rounded-lg bg-white px-6 py-8 text-black">
                      <div className="flex items-center gap-3">
                        <p className="text-base font-bold">Job Postings</p>
                      </div>
                      <div className="grid grid-cols-1 items-center  gap-10 xl:grid-cols-2">
                        {jobs?.map((job) => (
                          <JobCard
                            key={job.id}
                            handleOpenModal={() => handleOpenModal(job.id)}
                            job={job}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div></div>
          </motion.div>
        </motion.div>
      )}

      <JobDetails
        jobId={jobDetailIdRef.current}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </AnimatePresence>
  )
}

export default ViewCompanyProfileJobSeeker
