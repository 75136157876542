import React from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx';
// import { useComponentVisible } from '@/hooks'


export interface DropDownInfo {
  linkName: string;
  linkUrl: string;
  icon: React.ReactNode;
}

interface IProps {
  dropDownInfo: DropDownInfo[];
  className: string;
}

export const Dropdown: React.FC<IProps> = ({
  dropDownInfo,
  className,
}) => {
  // const { isComponentVisible, ref, setIsComponentVisible } =
  //   useComponentVisible();
  const router = useRouter();
  const { pathname } = router;
  // React.useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     setIsComponentVisible(false);
  //   }
  // }, []);

  return (
 <div
      className="relative"
    >
      <div className="flex bg-transparent py-8 items-center space-x-3">
      
      </div>

      <div
        className={`absolute top-3 ${className} translate-y-0 scale-100 opacity-100   border border-gray-100 bg-white py-2 shadow-md transition duration-200 -ml-36`}
      >
        {dropDownInfo?.map((link) => {
          return (
            <Link key={link.linkName} href={link.linkUrl} className="flex items-center hover:bg-primary hover:bg-opacity-25">
              <div className="px-2">
                {link.icon}
              </div>
              
              <span
                className={clsx("block py-2 hover:text-primary font-medium", pathname === link.linkUrl ? 'text-primary' : 'text-black')}
              >
                {link.linkName}
              </span>
            </Link>
          );
        })}
      </div>
    </div>
  )
}
