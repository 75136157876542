import { Divide as Hamburger } from 'hamburger-react'
import { AnimatePresence, motion } from 'framer-motion'
import { useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'

// import CompanyProfileModal from '../Modals/CompanyProfileModal
import { useComponentVisible, useUserInfo } from '@/hooks'
import { EditProfile, PublicProfile } from '../Modals'
import { Notifications } from '@/features/job-seeker'
import { ProfileDropDown } from './ProfileDropDown'
import { SearchModal } from '../Modals/SearchModal'
import { useHandleLogout } from '@/features/auth'
import clsx from 'clsx'
import { backdropVariant, containerChild2, containerVariant2, mobileNavbarVariant } from '@/utils'
import { HeartTick, Notification } from 'iconsax-react'

export const NavDashboard = () => {
  const {
    ref: refDropdown,
    isComponentVisible: isDropdown,
    setIsComponentVisible: setIsDropdown,
  } = useComponentVisible()
  const {
    isComponentVisible: isEditProfile,
    setIsComponentVisible: setIsEditProfile,
  } = useComponentVisible()
  const {
    ref: refNotification,
    isComponentVisible: isNotification,
    setIsComponentVisible: setNotification,
  } = useComponentVisible()
  const { handleLogout } = useHandleLogout()
  const { user, isLoading: isLoadingUserInfo } = useUserInfo()
  const { photoUrl } = user || {}
  const [openPublicProfile, setOpenPublicProfile] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  // const [OpenCompanyEditProfileModal, setOpenCompanyEditProfileModal] = useState(true)
  const router = useRouter()
  const navLinksDash = [
    {
      name: 'Dashboard',
      url: '/job-seeker/dashboard',
    },
    {
      name: 'Jobs',
      url: '/job-seeker/dashboard/jobs',
    },
    {
      name: 'Applied',
      url: '/job-seeker/dashboard/applied',
    },
    {
      name: 'Profile',
      url: '/job-seeker/dashboard/profile',
    },
  ]

  const handleOpenProfileProfile = () => setOpenPublicProfile(true)

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen])

  const handleEditProfile = () => {
    setIsEditProfile(!isEditProfile)
  }

  return (
    <>
      <div className="absolute z-20 flex h-16 w-full items-center justify-between bg-white pl-1 pr-4 lg:hidden">
        <div className="">
          <Hamburger size={20} rounded toggled={isOpen} toggle={setOpen} />
        </div>
        <div className={clsx('block lg:hidden')}>
          {isLoadingUserInfo ? (
            '.....'
          ) : (
            <button onClick={() => setIsDropdown(!isDropdown)}>
              <img
                className="profile-picture h-[30px] w-[30px]"
                src={photoUrl || ''}
                alt="picture"
              />
            </button>
          )}
        </div>
      </div>
      <div className="absolute z-[100] top-0 hidden w-full bg-secondary px-[50px] py-6 text-white lg:block">
        <div className=" mx-auto flex max-w-[1500px] items-center justify-between ">
          <div className="flex justify-between gap-10">
            <Link href="/job-seeker/dashboard">
              <div className="hidden lg:block lg:w-[150px] lg:cursor-pointer">
                <Image
                  src="/assets/logo.png"
                  alt="scout4Job-logo"
                  className="cursor-pointer"
                  height={21.65}
                  width={142.05}
                />
              </div>
            </Link>
            <div className="flex items-center gap-10">
              {navLinksDash.map((link) => (
                <Link
                  className={
                    router.asPath == link.url
                      ? 'navactive nav-dashboard-hover text-sm text-white transition duration-300 ease-in-out hover:text-[white]'
                      : 'nav-dashboard-hover text-sm text-[#A6A6A6] transition duration-300 ease-in-out hover:text-[white] '
                  }
                  href={link.url}
                  key={link.name}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
          <div className=" flex items-center gap-8">
            <button
              onClick={handleOpenProfileProfile}
              className="rounded bg-white py-[9px] px-5 text-xs font-[800] text-black transition duration-100 ease-in-out hover:bg-primary hover:text-white"
            >
              Public Profile
            </button>
            <Link href="/job-seeker/dashboard/saved-jobs">
              {/* <Image
                src="/assets/heart.png"
                alt="heart"
                className="cursor-pointer"
                height={24}
                width={24}
              /> */}
              <HeartTick size="24" color="#fff"/>
            </Link>
            {/* <button onClick={() => setShowSearch(true)}>
              <Image
                src="/assets/search.png"
                alt="search icon"
                className="cursor-pointer"
                height={24}
                width={24}
              />
            </button> */}
            <div>
              <button
                className="mt-2"
                onClick={() => setNotification((prev) => !prev)}
              >
                {/* <Image
                  src="/assets/bell.png"
                  alt="bell icon"
                  className="cursor-pointer"
                  height={20}
                  width={20} 
                /> */}
                <Notification size="24" color="#fff"/>
              </button>
              <Notifications ref={refNotification} show={isNotification} />
            </div>
            <div>
              {isLoadingUserInfo ? (
                '.....'
              ) : (
                <button onClick={() => setIsDropdown(!isDropdown)}>
                  <img
                    className="profile-picture h-[40px] w-[40px]"
                    src={photoUrl || ''}
                    alt="picture"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* mobile Navbar */}
      <motion.div className="lg:hidden">
        <AnimatePresence>
        {isOpen && (
          <motion.div className="absolute top-0 left-0 z-20 h-full w-full bg-black bg-opacity-40 "
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
          >
            <motion.div
            initial="initial"
            animate="show"
            exit="exit"
            variants={mobileNavbarVariant}
              className={clsx(
                'absolute top-0 z-30 h-full w-[400px] max-w-[80%] flex-col bg-secondary  text-white lg:h-fit lg:w-full lg:max-w-full lg:py-6 '
                // isOpen ? 'w-0 opacity-0' : 'w-[400px] opacity-100'
              )}
              ref={ref}
            >
              <div className="flex w-full items-center justify-between border-b-[0.1px] border-b-[#2B2B2B] px-5 py-5 lg:w-fit lg:justify-evenly">
                <Link href="/job-seeker/dashboard">
                  <div className=" lg:w-[150px] lg:cursor-pointer">
                    <Image
                      src="/assets/logo.png"
                      alt="scout4Job-logo"
                      className="cursor-pointer"
                      height={21.65}
                      width={142.05}
                    />
                  </div>
                </Link>

                {/* notification and saved jobs in mobile */}

                <div className="flex items-end gap-4 lg:hidden">
                  <div className="flex items-center">
                    <button
                      className=""
                      onClick={() => setNotification((prev) => !prev)}
                    >
                      <Image
                        src="/assets/bell.png"
                        alt="bell icon"
                        className="cursor-pointer"
                        height={20}
                        width={20}
                      />
                    </button>
                    <Notifications
                      ref={refNotification}
                      show={isNotification}
                    />
                  </div>
                  <Link
                    className="block lg:hidden"
                    href="/job-seeker/dashboard/saved-jobs"
                    onClick={() => setOpen(false)}
                  >
                    <Image
                      src="/assets/heart.png"
                      alt="heart"
                      className="cursor-pointer"
                      height={25}
                      width={25}
                    />
                  </Link>
                </div>
              </div>
              <motion.div className="mt-10 flex flex-col items-start gap-10 px-5 lg:flex-row"
                 variants={containerVariant2}
                 initial="hidden"
                 animate="visible"
                 exit="exit"
              >
                {navLinksDash.map((link) => (
                  <motion.button
                  variants = {containerChild2}
                  key={link.name}
                  >
                  <Link
                  onClick={() => setOpen(false)}
                    className={
                      router.asPath == link.url
                        ? 'navactive nav-dashboard-hover text-sm text-white transition duration-300 ease-in-out hover:text-[white]'
                        : 'nav-dashboard-hover text-sm text-[#A6A6A6] transition duration-300 ease-in-out hover:text-[white] '
                    }
                    href={link.url}
                    key={link.name}
                  >
                    {link.name}
                  </Link>
                  </motion.button>
                ))}
                <motion.button
                initial={{y: 0, opacity: 0}}
                exit={{y: 0, opacity: 0}}
                animate = {{y: -10, opacity: 1, transition: {delay: 1.1}}}
                onClick={
                  () => {
                    handleOpenProfileProfile()
                    setOpen(false)
                  }
                }
                  className="rounded-lg bg-white py-[9px] px-5 text-xs font-[800] text-black transition duration-100 ease-in-out hover:bg-primary hover:text-white"
                >
                  View Public Profile
                </motion.button>
              </motion.div>{' '}
              <PublicProfile
                user={user}
                setOpenPublicProfile={setOpenPublicProfile}
                openPublicProfile={openPublicProfile}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      </motion.div>

      {/* modals */}
      <PublicProfile
        user={user}
        setOpenPublicProfile={setOpenPublicProfile}
        openPublicProfile={openPublicProfile}
      />
      {/* <ApplicantProfileModal
        applicant={user}
        openApplicantProfileModal={openApplicantProfileModal}
        setOpenApplicantProfileModal={setOpenApplicantProfileModal}
      /> */}
      <ProfileDropDown
        user={user}
        ref={refDropdown}
        openEditProfileModal={handleEditProfile}
        handleLogout={handleLogout}
        showDropDown={isDropdown}
        setIsDropdown={setIsDropdown}
      />
      <EditProfile
        photoUrl={user?.photoUrl}
        OpenEditProfileModal={isEditProfile}
        setOpenEditProfileModal={handleEditProfile}
      />
      <SearchModal setShowSearch={setShowSearch} showSearch={showSearch} />

      {/* <CompanyProfileModal /> */}

      {/* <CompanyEditProfile
        setOpenCompanyEditProfileModal={setOpenCompanyEditProfileModal}
        OpenCompanyEditProfileModal={OpenCompanyEditProfileModal}
      /> */}
     
    </>
  )
}


//               <div className="flex items-center">
//                 <button
//                   className=""
//                   onClick={() => setNotification((prev) => !prev)}
//                 >
//                   <Image
//                     src="/assets/bell.png"
//                     alt="bell icon"
//                     className="cursor-pointer"
//                     height={20}
//                     width={20}
//                   />
//                 </button>
//                 <Notifications
//                   ref={refNotification}
//                   show={isNotification}
//                 />
//               </div>
//               <Link
//                 className="block lg:hidden"
//                 href="/job-seeker/dashboard/saved-jobs"
//               >
//                 <Image
//                   src="/assets/heart.png"
//                   alt="heart"
//                   className="cursor-pointer"
//                   height={25}
//                   width={25}
//                 />
//               </Link>
//             </div>

//             {/*  */}
//           </div>

//           <div className="flex flex-col items-start gap-10 lg:flex-row">
//             {navLinksDash.map((link) => (
//               <Link
//                 className={
//                   router.asPath == link.url
//                     ? 'navactive nav-dashboard-hover text-sm text-white transition duration-300 ease-in-out hover:text-[white]'
//                     : 'nav-dashboard-hover text-sm text-[#A6A6A6] transition duration-300 ease-in-out hover:text-[white] '
//                 }
//                 href={link.url}
//                 key={link.name}
//               >
//                 {link.name}
//               </Link>
//             ))}
//           </div>
//         </div>
//         <div className=" mt-10 flex w-full items-center justify-start gap-9 lg:mt-0 lg:w-fit">
//           <button
//             onClick={handleOpenProfileProfile}
//             className="rounded-lg bg-white py-[9px] px-5 text-xs font-[800] text-black transition duration-100 ease-in-out hover:bg-primary hover:text-white"
//           >
//             View Public Profile
//           </button>
//           <Link
//             className="hidden lg:block"
//             href="/job-seeker/dashboard/saved-jobs"
//           >
//             <Image
//               src="/assets/heart.png"
//               alt="heart"
//               className="cursor-pointer"
//               height={24}
//               width={24}
//             />
//           </Link>
//           {/* <button onClick={() => setShowSearch(true)}>
//         <Image
//           src="/assets/search.png"
//           alt="search icon"
//           className="cursor-pointer"
//           height={24}
//           width={24}
//         />
//       </button> */}
//           <div className="hidden lg:block">
//             <button
//               className="mt-2"
//               onClick={() => setNotification((prev) => !prev)}
//             >
//               <Image
//                 src="/assets/bell.png"
//                 alt="bell icon"
//                 className="cursor-pointer"
//                 height={20}
//                 width={20}
//               />
//             </button>
//             <Notifications ref={refNotification} show={isNotification} />
//           </div>
//           <div className={clsx('hidden lg:block')}>
//             {isLoadingUserInfo ? (
//               '.....'
//             ) : (
//               <button onClick={() => setIsDropdown(!isDropdown)}>
//                 <img
//                   className="profile-picture h-[40px] w-[40px]"
//                   src={photoUrl || ''}
//                   alt="picture"
//                 />
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// )}
// {/* modals */}
// <PublicProfile
//   user={user}
//   setOpenPublicProfile={setOpenPublicProfile}
//   openPublicProfile={openPublicProfile}
// />
// {/* <ApplicantProfileModal
//   applicant={user}
//   openApplicantProfileModal={openApplicantProfileModal}
//   setOpenApplicantProfileModal={setOpenApplicantProfileModal}
// /> */}
// <ProfileDropDown
//   user={user}
//   ref={refDropdown}
//   openEditProfileModal={handleEditProfile}
//   handleLogout={handleLogout}
//   showDropDown={isDropdown}
// />
// <EditProfile
//   photoUrl={user?.photoUrl}
//   OpenEditProfileModal={isEditProfile}
//   setOpenEditProfileModal={handleEditProfile}
// />
// <SearchModal setShowSearch={setShowSearch} showSearch={showSearch} />

// {/* <CompanyProfileModal /> */}

// {/* <CompanyEditProfile
//   setOpenCompanyEditProfileModal={setOpenCompanyEditProfileModal}
//   OpenCompanyEditProfileModal={OpenCompanyEditProfileModal}
// /> */}
// <CompanyEditAboutModal
//   companyEditAboutModal={companyEditAboutModal}
//   setCompanyEditAboutModal={setCompanyEditAboutModa
