import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { fetchData } from '@/utils';

interface Industry {
  name: string;
}

export const useAddIndustry = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: Industry) =>
      fetchData<Industry>("/common/add-industry", 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['industries'],
        });
      },
      onError: () => {
        toast.error('Industry title not added.');
      },
    }
  );
};