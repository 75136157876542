import { useQuery } from '@tanstack/react-query';


import { fetchData } from '@/utils';
import { JobType } from '@/types';

interface JobTypeResponse {
  status: string;
  message: string;
  data: JobType[];
}

export const useJobTypes = () => {
  return useQuery<JobTypeResponse>({
    queryKey: ['job-types'],
    queryFn: async () => fetchData('/common/job-types'),
  });
};
