



import { allJobsForApprovalKeys, fetchData, interviewKeys, transformError } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ApprovalType {
    jobId: string | undefined | string[] 
    userInfo:  {userId: string, interviewId: string}[]
}
export const useSendForApproval = () => {
    const queryClient = useQueryClient();
    
    return useMutation<Response, AxiosError, ApprovalType>(
        (payload) => fetchData(`/company/applicants/forward-for-approval`, 'POST', payload),
        {
            onSuccess: () => {
                toast.success('Approval list sent')
                queryClient.invalidateQueries({
                    queryKey: allJobsForApprovalKeys.all
                });
                queryClient.invalidateQueries({
                    queryKey: interviewKeys.all
                });
                // queryClient.invalidateQueries({
                //     queryKey: savedJobKeys.detail(jobId)
                // });
            },
            onError: (error) => {
                toast.error(transformError(error))
            },
        }
    )
}
