import { useQuery } from '@tanstack/react-query'

import { fetchData, interviewJobKeys } from '@/utils'
import { JobSeekerInterviewInfo } from '../types'

interface InterviewJobResponse {
  status: string
  message: string
  data: JobSeekerInterviewInfo
}

export const useInterviewInfo = (interviewId: string) => {
  return useQuery<InterviewJobResponse>({
    queryKey: interviewJobKeys.detail(interviewId),
    queryFn: async () => fetchData(`/user/interview/${interviewId}`),
  })
}