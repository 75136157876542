import { isValidPhoneNumber } from 'react-phone-number-input'
import { boolean, object, string } from 'yup'

import { OPTIONS_VALIDATION, OPTION_VALIDATION } from '@/utils'
import { axiosInstance } from '@/lib/axios'

export const onboardingProfileDetailSchema = object({
  isEmployed: boolean(),
  address: string().required('Required'),
  currentJobType: object().when('isEmployed', (isEmployed, schema) => {
    if (isEmployed[0]) {
      return schema
        .shape({
          name: string().required('Required'),
          id: string().required('Required'),
        })
        .required('Required')
    }
    return schema
  }),
  currentJobTitle: object().when('isEmployed', (isEmployed, schema) => {
    if (isEmployed[0]) {
      return schema
        .shape({
          name: string().required('Required'),
          id: string().required('Required'),
        })
        .required('Required')
    }
    return schema
  }),
  currentOrganizationName: string().when('isEmployed', (isEmployed, schema) => {
    if (isEmployed[0]) {
      return schema.required('Required')
    }
    return schema
  }),
  experience: OPTION_VALIDATION,
  currentLevel: OPTION_VALIDATION,
  qualification: OPTION_VALIDATION,
  website: string()
  .url('Invalid URL. (website must start with http:// or https://)')
  .matches(/^(https?:\/\/)?([^\s/$.?#].[^\s]*)?$/, 'Website must start with http:// or https://')
  .nullable()
})

export const editProfileDetailSchema = object({
  isEmployed: boolean(),
  location: string().required('Required'),
  currentJobType: object().when('isEmployed', (isEmployed, schema) => {
    if (isEmployed[0]) {
      return schema
        .shape({
          name: string().required('Required'),
          id: string().required('Required'),
        })
        .required('Required')
    }
    return schema
  }),
  currentJobTitle: object().when('isEmployed', (isEmployed, schema) => {
    if (isEmployed[0]) {
      return schema
        .shape({
          name: string().required('Required'),
          id: string().required('Required'),
        })
        .required('Required')
    }
    return schema
  }),
  currentOrganizationName: string().when('isEmployed', (isEmployed, schema) => {
    if (isEmployed[0]) {
      return schema.required('Required')
    }
    return schema.notRequired()
  }),
  yearsOfExperience: OPTION_VALIDATION,
  currentLevel: OPTION_VALIDATION,
  qualification: OPTION_VALIDATION,
  website: string()
  .url('Invalid URL. (website must start with http:// or https://)')
  .matches(/^(https?:\/\/)?([^\s/$.?#].[^\s]*)?$/, 'Website must start with http:// or https://')
  .nullable(),
  linkedin: string()
  .url('Invalid URL. (link must start with http:// or https://)')
  .matches(/^(https?:\/\/)?([^\s/$.?#].[^\s]*)?$/, 'link must start with http:// or https://')
  .nullable()
})

export const onboardingJobPreferenceSchema = object({
  address: string().required('Required'),
  industries: OPTIONS_VALIDATION,
  jobType: OPTION_VALIDATION,
  jobTitles: OPTIONS_VALIDATION,
  level: OPTION_VALIDATION,
  workspaces: OPTIONS_VALIDATION,
  jobSearchStatus: OPTION_VALIDATION,
  currency: OPTION_VALIDATION,
  minimumPayment: string().required('Required'),
})

export const onboardingCompanyDetailSchema = object({
  companyName: string()
    .required('Required')
    .test(
      'Unique Company name',
      'An account with this name exists.', // <- key, message
      function (value) {
        return new Promise((resolve) => {
          axiosInstance
            .post('/company/check-company-name', { companyName: value })
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              if (
                error &&
                error.response?.data.message === 'Company name is taken.'
              ) {
                resolve(false)
              }
            })
        })
      }
    ),
  companyLocation: string().required('Required'),
  employeeCount: OPTION_VALIDATION,
  userRole: string().required('Required'),
  industry: OPTION_VALIDATION,
  companyDescription: string().required('Required'),
  companyWebsite: string()
  .url('Website must start with http:// or https://')
  .matches(/^(https?:\/\/)?([^\s/$.?#].[^\s]*)?$/, 'Website must start with http:// or https://')
  .nullable()
,
  companyPhone: string()
    .test('validation-phone', 'Invalid phone number', (value = '') => {
      return isValidPhoneNumber(value)
    })
    .required('Required'),
  companyEmail: string()
    .email('Email address is invalid')
    .required('Required')
    .test(
      'Unique Email',
      'An account with this email exists.', // <- key, message
      function (value) {
        return new Promise((resolve) => {
          axiosInstance
            .post('/company/check-company-email', { companyEmail: value })
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              if (
                error &&
                error.response?.data.message === 'Company email is taken.'
              ) {
                resolve(false)
              }
            })
        })
      }
    ),
})
