import { useQuery } from '@tanstack/react-query'

import { companyJobKeys, fetchData } from '@/utils'
import { JobDetail } from '../types'

interface JobDetailResponse {
  status: string
  message: string
  data: JobDetail
}

export const useJob = (jobId: string) => {
  return useQuery<JobDetailResponse>({
    queryKey: companyJobKeys.detail(jobId),
    queryFn: async () => fetchData(`/job/${jobId}`),
    enabled: !!jobId
  })
}
