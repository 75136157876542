import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar'

import { BsThreeDotsVertical } from 'react-icons/bs'
import Image from 'next/image'
import React, { Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import { ApplicantOptionInJobs } from '@/components'
import { CompanyJob } from '../types'
import { CountdownTimer } from './CountdownTimer'
import { useComponentVisible } from '@/hooks'
import { useChangeJobStatus } from '../api'

interface JobDisplayCardProp {
  index: number
  formattedPostedDate: string
  active: number[]
  changeColor: (value: number) => void
  percentageLeft: number
  jobInfo: CompanyJob
  setOpenViewJobPostModal: React.Dispatch<React.SetStateAction<boolean>>
  openViewJobPostModal: boolean
  jobPostDetail: CompanyJob
  handleSetId: (value: number) => void
  setOpenExtendDeadlineModal: (value: boolean) => void
  openExtendDeadlineModal: boolean
  jobPostId: string | undefined
  handleClick: (id: number) => void
  selectedJobId: number
  jobId: string[] | undefined
  setSelectedJobId: Dispatch<SetStateAction<number>>
}

export const JobDisplayCard = ({
  index,
  formattedPostedDate,
  changeColor,
  percentageLeft,
  jobInfo,
  jobPostDetail,
  openViewJobPostModal,
  setOpenViewJobPostModal,
  handleSetId,
  jobId,
  setSelectedJobId,
  setOpenExtendDeadlineModal,
  openExtendDeadlineModal,
}: JobDisplayCardProp) => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible()


    const jobIdMapped = jobId && jobId[index]
    const mutationChangeStatus = useChangeJobStatus(jobIdMapped)

   

    const handlePublish = () => {

      mutationChangeStatus.mutate(
        {
          status:'published'
        },
        {
          onSuccess: () => {
            setIsComponentVisible(false)
          },
        }
      )
    }
    const handleUnPublish = () => {

      mutationChangeStatus.mutate(
        {
          status: 'unpublished'
        },
        {
          onSuccess: () => {
            setIsComponentVisible(false)
          },
        }
      )
    }

  return (
    <div>
      <div className={clsx("relative mb-4 flex flex-col items-center rounded-md border border-[#efefef] py-6 px-6 shadow-lg transition duration-100 ease-in-out hover:shadow-sm", jobInfo?.isTreated ? 'bg-primary bg-opacity-10 border-b-2 border-b-primary' : 'bg-white')}>
        <div
          ref={ref}
          className={clsx('', isComponentVisible ? 'block' : 'hidden')}
        >
          <ApplicantOptionInJobs
            setOpenViewJobPostModal={setOpenViewJobPostModal}
            openViewJobPostModal={openViewJobPostModal}
            jobPostDetail={jobPostDetail}
            jobPostId={jobId && jobId[index]}
            title={jobInfo.title}
            isPublished={jobInfo.status === 'published'}
            setOpenExtendDeadlineModal={setOpenExtendDeadlineModal}
            openExtendDeadlineModal={openExtendDeadlineModal}
          />
        </div>
        <button
          className="absolute  top-2 right-0 p-5 text-[#A6A6A6] hover:text-black"
          onClick={() => {
            // handleClick(index)
            setSelectedJobId(index)
            setIsComponentVisible(!isComponentVisible)
          }}
        >
          <BsThreeDotsVertical />
        </button>

        <div
          className="mt-5 cursor-pointer text-center text-lg font-bold line-clamp-1"
          onClick={() => {
            setSelectedJobId(index)
            setOpenViewJobPostModal(!openViewJobPostModal)
          }}
        >
          {jobInfo.title}
        </div>
        <div className="mt-2 flex gap-2">
          <p
            style={{
              backgroundColor: 'rgba(229, 79, 32, 0.1)',
              color: '#E54F20',
            }}
            className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
          >
            {jobInfo.employmentType}
          </p>
          <p
            style={{
              backgroundColor: 'rgba(39, 174, 96, 0.1)',
              color: '#27AE60',
            }}
            className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
          >
            {jobInfo.workType}
          </p>
          <p
            style={{
              backgroundColor: 'rgba(229, 32, 222, 0.1)',
              color: '#E520DE',
            }}
            className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
          >
            {jobInfo.level}
          </p>
        </div>
        <div className="mt-4 flex flex-col items-center justify-center">
          <button
            style={{
              width: 121,
              height: 121,
              marginTop: '1rem',
            }}
            onClick={() => {
              handleSetId(index)
            }}
          >
            <CircularProgressbarWithChildren
              value={percentageLeft}
              strokeWidth={3}
              styles={buildStyles({
                textColor: 'black',
                pathColor: `${changeColor(percentageLeft)}`, //#E2B93B #27AE60
              })}
            >
              <div className="flex flex-col items-center">
                <p className="text-[32px] font-bold ">{jobInfo.applicants}</p>
                <p className="text-xs">
                  Applicant{jobInfo.applicants > 1 ? 's' : ''}
                </p>
              </div>
            </CircularProgressbarWithChildren>
          </button>
          <p className="mt-5 text-xs text-[#A6A6A6]">
            Published {formattedPostedDate}
          </p>
          <p className="mt-1 text-[13px]" style={{ color: '#27AE60' }}>
            <CountdownTimer targetDate={new Date(jobInfo.validThrough)} />
          </p>
          <div className="mt-4 flex items-center gap-2">
            {/* <div
                      className={clsx(
                        'flex items-center justify-center gap-2 rounded-sm border border-black  px-2 py-1 font-[700] ',
                        jobInfo.isSpotlighted && 'bg-[#2F80ED]'
                      )}
                    >
                      <Image
                        src="/assets/sync.png"
                        width={15.53}
                        height={13.61}
                        alt="setUpProfile"
                      />
                      {true ? (
                        <p className="text-xs text-white">ATS Synced </p>
                      ) : (
                        <p className="text-xs text-black">Sync ATS</p>
                      )}
                    </div> */}
            <div
              className={clsx(
                'flex gap-2 rounded-sm border border-black  px-2 py-1 font-[700] ',
                jobInfo.status === 'published' && 'bg-[#27AE60] text-white'
              )}
            >
              <Image
                src="/assets/spotlight.png"
                width={15.53}
                height={13.61}
                alt="setUpProfile"
              />
              {jobInfo.status === 'published' ? (
                <button onClick={handleUnPublish} className="text-xs text-white">Published</button>
              ) : (
                <button onClick={handlePublish} className="text-xs text-black">Unpublished</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobDisplayCard
