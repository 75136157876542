import React from 'react'

interface RadioButtonProps {
  name?: string
  value?: string
  checked: boolean
  onChange: () => void
}
export const RadioButton = ({
  name,
  value,
  checked,
  onChange,
}: RadioButtonProps) => {
  return (
    <div>
      <label className="inline-flex cursor-pointer items-center space-x-2">
        <input
          type="radio"
          className="form-radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        {/* <div
          className={`custom-radio ${
            checked ? 'custom-radio-selected' : 'custom-radio'
          }`}
        >
          <div className={`radio-dot ${checked ? 'radio-dot-selected' : ''}`} />
        </div> */}
        <span className="text-gray-800"></span>
      </label>
      <style jsx>
{`
          .custom-radio {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #a6a6a6;
            position: relative;
          }
          .custom-radio-selected {
            border: 2px solid #27ae60;
          }

          .radio-dot {
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .radio-dot-selected {
            background-color: #27ae60;
          }
        `}
      </style>
    </div>
  )
}
