import { AnimatePresence, motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import { backdropVariant, lookingFor } from '@/utils'
import {
  CompanyAboutForm,
  CompanyAboutPayload,
  useCompanyAbout,
} from '@/features/recruiter'
import { Button, TextAreaField } from '@/components'

interface CompanyEditAboutModalProps {
  companyEditAboutModal: boolean
  setCompanyEditAboutModal: (value: boolean) => void
  companyDescription: string
}

export const CompanyEditAboutModal: React.FC<CompanyEditAboutModalProps> = ({
  companyEditAboutModal,
  setCompanyEditAboutModal,
  companyDescription,
}) => {
  const {
    register,
    watch,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<CompanyAboutForm>({
    defaultValues: {
      companyAbout: '',
    },
  })

  const { mutate, isLoading } = useCompanyAbout()
  const ref = React.useRef<HTMLInputElement>(null)
  const companyAboutValue = watch('companyAbout')

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        companyEditAboutModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setCompanyEditAboutModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [companyEditAboutModal])

  React.useEffect(() => {
    if (companyDescription) {
      reset({
        companyAbout: companyDescription || ''
      })
    }
  }, [companyDescription])

  const onSubmit = (data: CompanyAboutForm) => {
    const payload: CompanyAboutPayload = {
      companyAbout: data.companyAbout,
    }
    mutate(payload, {
      onSuccess: () => {
        reset()
        setCompanyEditAboutModal(false)
      },
    })
  }

  return (
    <AnimatePresence>
      {companyEditAboutModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide font-smooth font-smooth-always absolute top-[50%] left-[50%]  w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={lookingFor}
          >
            <div>
              <div>
                <p className="text-base font-bold">About your company</p>
                <button
                  type="button"
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setCompanyEditAboutModal(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className=" mt-4 flex w-fit items-center gap-3 rounded-md border-2 border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 px-3 py-2 text-black ">
                <p className="text-xs ">
                  Our study shows that this is the first thing applicants look
                  at on your profile
                </p>
              </div>
              <div>
                <TextAreaField
                  className="mt-2"
                  id="companyAbout"
                  placeholder="Describe your role in the company"
                  registration={{ ...register('companyAbout') }}
                  hasError={errors.companyAbout}
                  rows={3}
                  limit={350}
                  value={companyAboutValue}
                />
              </div>
              <div className="mt-8">
                <Button>{isLoading ? 'Saving...' : 'Save'}</Button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
