import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { fetchData } from '@/utils'

interface FileUploadResponse {
  status: string
  message: string
  data: {
    fileUrl: string
  }
}

export const useFileUploadExternal = () => {

  return useMutation<FileUploadResponse, unknown, FormData>(
    (payload) => fetchData<FormData>(`/common/plain-upload-file`, 'POST', payload),
    {
      onError: () => {
        toast.error('File not uploaded.')
      },
    }
  )
}
