import { yupResolver } from '@hookform/resolvers/yup'
import { Control, useForm } from 'react-hook-form'

import {
  Button,
  CustomSelectFieldIndustry,
  InputField,
  InputJobTitlesAutoComplete,
  // InputLocationAutoComplete,
  SelectField,
} from '@/components'
import { onboardingJobPreferenceSchema } from '../../utils'
import {
  OnboardingJobPreferenceForm,
  OnboardingJobPreferencePayload,
} from '../../types'
import { useCurrencyOptions, useJobTypeOptions, useLevelOptions } from '@/hooks'
import { useCreateJobPreference } from '../../api'
import { LabelTitle } from '..'
import {
  EMPTY_OPTION,
  JOB_SEARCH_STATUS_OPTIONS,
  WORKSPACE_OPTIONS,
} from '@/utils'
import { ArrowUp } from 'iconsax-react'

export const OnboardingJobPreference = () => {
  const { levelOptions } = useLevelOptions()
  const { currencyOptions } = useCurrencyOptions()
  const { jobTypes } = useJobTypeOptions()
  const mutationCreateJobPreferences = useCreateJobPreference()
  const {
    control,
    formState: { errors },
    // watch,
    register,
    // setValue,
    handleSubmit,
  } = useForm<OnboardingJobPreferenceForm>({
    defaultValues: {
      address: '',
      level: EMPTY_OPTION,
      jobSearchStatus: EMPTY_OPTION,
      jobType: EMPTY_OPTION,
      currency: currencyOptions[0],
      industries: [],
      jobTitles: [],
      workspaces: [],
    },
    resolver: yupResolver(onboardingJobPreferenceSchema),
  })

  // const address = watch('address')

  // const handleAddressChange = (address: string) => {
  //   setValue('address', address)
  // }

  const isEmpty = () => {
    for (const prop in errors) {
      if (errors.hasOwnProperty(prop)) return false
    }
    return true
  }

  const onSubmit = (values: OnboardingJobPreferenceForm) => {
    const {
      address,
      early,
      large,
      seed,
      industries,
      currency,
      level,
      jobSearchStatus,
      jobTitles,
      jobType,
      minimumPayment,
      workspaces,
    } = values
    const midSize = values['mid-size']
    const veryLarge = values['very-large']
    const extraLarge = values['extra-large']

    const industryIds = industries.map((industry) => industry.id)

    const companySizes = [
      {
        companySize: 'seed',
        answer: seed || '',
      },
      {
        companySize: 'early',
        answer: early || '',
      },
      {
        companySize: 'mid-size',
        answer: midSize || '',
      },
      {
        companySize: 'large',
        answer: large || '',
      },
      {
        companySize: 'very-large',
        answer: veryLarge || '',
      },
      {
        companySize: 'extra-large',
        answer: extraLarge || '',
      },
    ]

    const payload: OnboardingJobPreferencePayload = {
      levelId: level.id,
      jobSearchStatus: jobSearchStatus.id,
      jobTitles: jobTitles.map((jobTitle) => jobTitle.id),
      typeId: jobType.id,
      workPlaces: workspaces.map((workspace) => workspace.id),
      location: address,
      industryId: industryIds,
      companySizes,
      minimumPay: {
        currency: currency.id?.toLowerCase(),
        value: Number(minimumPayment),
      },
    }

    mutationCreateJobPreferences.mutate(payload)
  }

  console.log({ errors })
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pb-10">
      <div className="m-auto max-w-[652px] text-center">
        <p>Step 2/4</p>
        <h2 className="py-2 text-2xl md:text-[40px] md:leading-10">
          Set your job search preferences
        </h2>
        <p className="">
          Your answers determine which jobs we recommend for you and which
          startups see your profile.
        </p>
      </div>

      <div className="mt-10 md:flex">
        <div className="box-border md:flex-1 md:pr-10 lg:border-r lg:pr-20">
          <div>
            <LabelTitle
              id="address"
              text="What location do you want to work in?"
              isRequired
            />
            {/* <InputLocationAutoComplete
              isError={!!errors.address}
              address={address}
              handleChange={handleAddressChange}
              handleSelect={handleAddressChange}
            /> */}
            <InputField
              className="mt-4"
              placeholder="eg. City, state, or country"
              registration={{ ...register('address') }}
              hasError={errors.address}
            />
          </div>
          <div className="mt-10">
            <LabelTitle
              id="industries"
              text="Which industries are you interested in?"
              isRequired
            />
            <CustomSelectFieldIndustry
              className="mt-4"
              control={control as unknown as Control}
              hasError={errors.industries}
              name="industries"
              isMultiple
            />
          </div>
          <div className="mt-10">
            <LabelTitle
              text="What type of job are you looking for?"
              isRequired
              id="jobType"
            />
            <SelectField
              className="mt-4"
              control={control as unknown as Control}
              hasError={errors.jobType}
              arr={jobTypes}
              name="jobType"
            />
          </div>
          <div className="mt-10">
            <LabelTitle
              text="What is the minimum monthly salary you will like to earn?"
              isRequired
              id="currency"
            />
            <div className="relative mt-2">
              <div className="absolute top-[1px] left-[2px] bottom-[1px] z-10 w-44 border-r border-gray-400">
                <SelectField
                  className="h-[44px] border-none"
                  isOptionClass="px-4"
                  control={control as unknown as Control}
                  hasError={errors.currency}
                  arr={currencyOptions}
                  name="currency"
                />
              </div>
              <InputField
                className="pl-48"
                isRequired
                type="number"
                registration={{ ...register('minimumPayment') }}
                canPressSpace={false}
                hasError={errors.minimumPayment}
              />
            </div>
          </div>
        </div>
        <div className="pt-10 md:flex-1 md:pt-0 md:pl-10 lg:pl-20">
          <div>
            <h4 className="">
              <span className="text-xl text-primary">*</span> What kind of role
              are you looking for?
            </h4>
            <InputJobTitlesAutoComplete
              className="mt-4"
              control={control as unknown as Control}
              hasError={errors.jobTitles}
              placeholder="Eg. Frontend developer"
              name="jobTitles"
              isMultiple
            />
          </div>
          <div className="mt-10">
            <LabelTitle
              text="What minimum level would you love to play?"
              isRequired
              id="level"
            />
            <SelectField
              className="mt-4"
              control={control as unknown as Control}
              hasError={errors.level}
              arr={levelOptions}
              name="level"
            />
          </div>
          <div className="mt-10">
            <LabelTitle
              text="What kind of workplace are you looking for?"
              isRequired
              id="workspaces"
            />
            <SelectField
              className="mt-4"
              control={control as unknown as Control}
              hasError={errors.workspaces}
              arr={WORKSPACE_OPTIONS}
              name="workspaces"
              isMultiple
            />
          </div>
          <div className="mt-10">
            <LabelTitle
              text="Where are you in your job search?"
              isRequired
              id="jobSearchStatus"
            />
            <SelectField
              className="mt-4"
              control={control as unknown as Control}
              hasError={errors.jobSearchStatus}
              arr={JOB_SEARCH_STATUS_OPTIONS}
              name="jobSearchStatus"
            />
          </div>
        </div>
      </div>
      <div className="mt-14">
        <h4>Would you like to work at companies of these sizes?</h4>
      </div>
      <div className="mt-4 flex items-center">
        <div className="flex-1" />
        <div className="flex flex-1 items-center justify-around">
          <p>Ideal</p>
          <p>Yes</p>
          <p>No</p>
        </div>
      </div>
      <div className="mt-5 flex items-center border-y-[0.5px] border-[#A6A6A6] py-5">
        <p className="text-s flex-1">Seed (1 - 10 employees)</p>
        <div className="flex w-full flex-1 justify-around">
          <input id="" type="radio" value="ideal" {...register('seed')} />
          <input id="" type="radio" value="yes" {...register('seed')} />
          <input id="" type="radio" value="no" {...register('seed')} />
        </div>
      </div>
      <div className="flex items-center border-b-[0.5px] border-[#A6A6A6] py-5">
        <p className="text-s flex-1">Early (11 - 50 employees)</p>
        <div className="flex w-full flex-1 justify-around">
          <input id="" type="radio" {...register('early')} value="ideal" />
          <input id="" type="radio" {...register('early')} value="yes" />
          <input id="" type="radio" {...register('early')} value="no" />
        </div>
      </div>
      <div className="flex items-center border-b-[0.5px] border-[#A6A6A6] py-5">
        <p className="text-s flex-1">Mid-size (51 - 200 employees)</p>
        <div className="flex w-full flex-1 justify-around">
          <input id="" type="radio" {...register('mid-size')} value="ideal" />
          <input id="" type="radio" {...register('mid-size')} value="yes" />
          <input id="" type="radio" {...register('mid-size')} value="no" />
        </div>
      </div>
      <div className="flex items-center border-b-[0.5px] border-[#A6A6A6] py-5">
        <p className="text-s flex-1">Large (201 - 500 employees)</p>
        <div className="flex w-full flex-1 justify-around">
          <input id="" type="radio" {...register('large')} value="ideal" />
          <input id="" type="radio" {...register('large')} value="yes" />
          <input id="" type="radio" {...register('large')} value="no" />
        </div>
      </div>
      <div className="flex items-center border-b-[0.5px] border-[#A6A6A6] py-5">
        <p className="text-s flex-1">Very Large (501 - 1000 employees)</p>
        <div className="flex w-full flex-1 justify-around">
          <input id="" type="radio" {...register('very-large')} value="ideal" />
          <input id="" type="radio" {...register('very-large')} value="yes" />
          <input id="" type="radio" {...register('very-large')} value="no" />
        </div>
      </div>
      <div className="flex items-center border-b-[0.5px] border-[#A6A6A6] py-5">
        <p className="text-s flex-1">Extra Large (1000+ employees)</p>
        <div className="flex w-full flex-1 justify-around">
          <input
            id=""
            type="radio"
            {...register('extra-large')}
            value="ideal"
          />
          <input id="" type="radio" {...register('extra-large')} value="yes" />
          <input id="" type="radio" {...register('extra-large')} value="no" />
        </div>
      </div>

      <div className="mx-auto mt-10 flex max-w-sm justify-center">
        <Button
          disabled={mutationCreateJobPreferences.isLoading}
          isLoading={mutationCreateJobPreferences.isLoading}
          className="w-full"
          type="submit"
        >
          {mutationCreateJobPreferences.isLoading
            ? 'Save and continue...'
            : 'Save and continue'}
        </Button>
      </div>
      {!isEmpty() && (
        <div className="mt-4 -mb-4 flex items-center justify-center gap-2">
          <p className="w-fit text-xs text-red-600">
            You have an unfilled text field
          </p>
          <ArrowUp size="20" className="animate-bounce text-red-600" />
        </div>
      )}
    </form>
  )
}
