import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchData, jobApplicantKeys, transformError,  } from '@/utils'
import { AxiosError } from 'axios'

interface ResumePayload {
  letterUrl: string
   
}

export const useSendOfferLetter = () => {
  const queryClient = useQueryClient()

  return useMutation<Response, AxiosError, ResumePayload>(
    (payload: ResumePayload) =>
      fetchData(
        `/company/applicants/send-offer-letter`,
        'POST',
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: jobApplicantKeys.all
      });
        // queryClient.invalidateQueries({
        //   queryKey: userKeys.all,
        // })
        toast.success('Offer letter sent')
      },
      onError: (error) => {
        toast.error(transformError(error))
      },
    }
  )
}

