import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { fetchData, talentPoolKeys, } from '@/utils'
import { TalentPoolPayload } from '../types'

export const useMoveToTalentPool = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: TalentPoolPayload) =>
      fetchData(`/company/applicants/move-to-talent-pool/${userId}`, 'POST', payload),
    {
      onSuccess: () => {
          toast.success('moved to talent-pool successfully')
          queryClient.invalidateQueries({
            queryKey: talentPoolKeys.all,
          });
      },
      onError: () => {
        toast.error('failed to move to talent pool')
      },
    }
  )
}
