import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { appliedJobKeys, fetchData, interviewJobKeys, interviewKeys } from '@/utils'
import { DeclineInterviewPayload } from '../types'


export const useDeclineInterview = (interviewId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload:DeclineInterviewPayload) => fetchData(`/user/interview/decline/${interviewId}`, 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Interview successfully declined')
        queryClient.invalidateQueries({
          queryKey: interviewJobKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: interviewKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: appliedJobKeys.all,
        })
      },
      onError: () => {
        toast.error('Interview not declined')
      },
    }
  )
}
