import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { companyJobKeys, fetchData } from '@/utils'
import { companyIDStorage } from '@/features/auth'
import { PostAJobFormPayload } from '../types'

export const usePostCompanyImage = () => {
  const queryClient = useQueryClient();
  const companyID = companyIDStorage.getCompanyID()
  return useMutation(
    (payload: PostAJobFormPayload) =>
      fetchData(`/company/media/add/${companyID}`, 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: companyJobKeys.list(companyID),
        });
        toast.success('image uploaded successfully')
      },
      onError: () => {
        toast.error('image upload failed')
      },
    }
  )
}
