import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { fetchData, interviewKeys, jobApplicantKeys } from '@/utils'
import { ScheduleInterviewPayload } from '../types'

export const useScheduleInterview = (jobId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: ScheduleInterviewPayload) =>
      fetchData(
        `/company/applicants/schedule-interview/${jobId}`,
        'POST',
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: interviewKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: jobApplicantKeys.all,
        })
        toast.success('Interview successfully scheduled')
      },
      onError: () => {
        toast.error('Interview scheduled failed')
      },
    }
  )
}
