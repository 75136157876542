import { Combobox, Transition } from '@headlessui/react'
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import React from 'react'
import clsx from 'clsx'

import { useAddIndustry } from '@/features/recruiter'
import { useIndustryOptions } from '@/hooks'
import { Option } from '@/types'

type HasErrorType = Merge<
  FieldError,
  (
    | Merge<
        FieldError,
        FieldErrorsImpl<{
          name: string
          id: string
        }>
      >
    | undefined
  )[]
>

type CustomSelectFieldIndustryProps = {
  className?: string
  placeholder?: string
  hasError?: HasErrorType | undefined
  hasBorder?: boolean
  isDisabled?: boolean
  isMultiple?: boolean
  background?: string
  setSearchIndustry?: React.Dispatch<React.SetStateAction<string>>
  openModal?: () => void
} & UseControllerProps

export const CustomSelectFieldIndustry: React.FC<
  CustomSelectFieldIndustryProps
> = (props) => {
  const mutationAdd = useAddIndustry()
  const { industryOptions } = useIndustryOptions()
  const {
    field: { value, onChange, ref },
  } = useController(props)
  const {
    className,
    hasError = false,
    placeholder = 'Search and select industry',
    hasBorder = true,
    isDisabled = false,
    isMultiple = false,
    background,
    setSearchIndustry,
  } = props
  const [query, setQuery] = React.useState('')

  const arr = industryOptions

  // setSearchIndustry && setSearchIndustry(value?.name || '') //also used to set the value for the search

  const filteredArr =
    query.length < 3
      ? []
      // : query === ''
      // ? arr
      : arr.filter((obj) =>
          String(obj.name)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  const hasMultipleOption = isMultiple && value && value.length > 0

  const handleAddIndustry = () => {
    mutationAdd.mutate({
      name: query,
    })
  }

  return (
    <div className="w-full">
      <Combobox value={value} onChange={onChange} multiple={isMultiple as true}>
        <div className="relative mt-1">
          <Combobox.Input
            className={clsx(
              'block h-12 w-full rounded-md border border-gray-250 px-4 text-left font-WorkSans text-sm text-[#6BF44] outline-none placeholder:font-light focus-within:border-secondary',
              hasError && 'border-red-500',
              isDisabled
                ? 'pointer-events-none cursor-not-allowed bg-gray-100'
                : 'bg-white',
              !hasBorder && 'border-transparent px-0 focus:border-transparent',
              className
            )}
            style={{ background: background }}
            placeholder={placeholder}
            displayValue={(option: Option | Option[]) => {
              return (option as Option).name
            }}
            onChange={(event) => {
              const newQuery = event.target.value
              setSearchIndustry && setSearchIndustry(newQuery || '') //using this to search for industries in company filter
              setQuery(newQuery)
            }}
            ref={ref}
          />

          {hasMultipleOption && (
            <div className="mt-2 flex flex-wrap gap-2">
              {value
                .filter((option: Option) => option.id !== 'all')
                .map((option: Option) => {
                  const handleRemove = (id: string) => {
                    const newValue = value.filter((o: Option) => o.id !== id)
                    onChange(newValue)
                  }
                  return (
                    <div
                      className="flex  items-center space-x-1 bg-secondary/20 pl-2 text-sm"
                      key={option.id}
                    >
                      <p className="py-1">{option.name}</p>
                      <button
                        type="button"
                        className="p-2 hover:bg-red-400 hover:text-white"
                        onClick={() => handleRemove(option.id)}
                      >
                        <span className="font-medium">x</span>
                      </button>
                    </div>
                  )
                })}
            </div>
          )}

          <Transition
            as={React.Fragment}
            enter="transition-opacity transition-transform duration-200 origin-center"
            enterFrom="scale-0 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              className={clsx(
                'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base ring-opacity-5 focus:outline-none sm:text-sm',
                query.length > 0 && 'border py-1 shadow-lg'
              )}
            >
              {filteredArr.length > 0 &&
                filteredArr.map((obj, index) => {
                  return (
                    <Combobox.Option
                      as="ul"
                      key={obj.id}
                      className={({ active }) =>
                        clsx(
                          'relative cursor-default select-none py-2 pl-10 pr-4',
                          active
                            ? 'bg-secondary bg-opacity-80 text-white'
                            : ' text-gray-900',
                          !(filteredArr.length - 1 === index) && 'border-b'
                        )
                      }
                      value={obj}
                    >
                      {({ selected, active }) => (
                        <li>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-secondary'
                              }`}
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          )}
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {obj?.name}
                          </span>
                        </li>
                      )}
                    </Combobox.Option>
                  )
                })}
              {filteredArr.length === 0 && (
                <>
                  {query.length > 0 && (
                    <button
                      onClick={handleAddIndustry}
                      type="button"
                      className="text-md w-full px-5 py-1 text-gray-50"
                      disabled={mutationAdd.isLoading}
                    >
                      {mutationAdd.isLoading ? 'Adding' : 'Add'} &lsquo;
                      <span className="font-bold capitalize text-green-700">
                        {query}
                      </span>
                      &lsquo;
                    </button>
                  )}
                </>
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
