import { useQuery } from '@tanstack/react-query'

import { appliedJobKeys, fetchData } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { Job } from '../types'

interface AppliedJobResponse {
  status: string
  message: string
  data: Job[]
}

export const useAppliedJobs = () => {
  const userId = userIDStorage.getUserID()
  return useQuery<AppliedJobResponse>({
    queryKey: appliedJobKeys.list(userId),
    queryFn: async () => fetchData(`/user/applied-jobs/${userId}`),
  })
}