import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { companyKeys, fetchData } from '@/utils'
// import { companyIDStorage } from '@/features/auth'
import { CompanyPerksPayload } from '../types'
import { useCompanyId } from '../hooks'

export const useEditPerk = (perkId:string) => {
  const queryClient = useQueryClient();
  const companyID =  useCompanyId()
  return useMutation(
    (payload: CompanyPerksPayload) =>
      fetchData(`/company/perks/edit/${perkId}`, 'PUT', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: companyKeys.list(companyID),
        });
        toast.success('Perk Updated successfully')
        // router.push('/recruiter/dashboard/jobs')
      },
      onError: () => {
        toast.error('Perk Update failed')
      },
    }
  )
}
