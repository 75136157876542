import { backdropVariant, InterviewDetailsModalAnim } from '@/utils'
import { AddCircle, Calendar2, Clock } from 'iconsax-react'
import { AnimatePresence, motion } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'
import clsx from 'clsx'


import { Button, TextAreaField } from '@/components'
import { JobInterviewForJobSeekers,  } from '@/features/job-seeker'
import { RescheduleJobSeekerSchema } from '@/utils/validationSchema'

interface RescheduleJobSeekerModalProps {
  reschdeduleJobInterview: boolean
  setReschdeduleJobInterview: (value: boolean) => void
  data: JobInterviewForJobSeekers | undefined
  jobTitle?: string | undefined
}
interface ReschdeduleReasonForm {
  time: string
  date: string
  reschdeduleReason: string
}

export const RescheduleJobSeekerModal: React.FC<RescheduleJobSeekerModalProps> = ({
  reschdeduleJobInterview,
  setReschdeduleJobInterview,
  data, 
  jobTitle
}) => {
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        reschdeduleJobInterview &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setReschdeduleJobInterview(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [reschdeduleJobInterview])

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<ReschdeduleReasonForm>({
    defaultValues: {},
    resolver: yupResolver(RescheduleJobSeekerSchema),
  })

  const reschdeduleReasonLimit = watch('reschdeduleReason')
  const interViewStages = [
    {
      name: 'Stage 1',
      id: '1',
      activated: true,
      passed: true,
    },
    {
      name: 'Stage 1',
      id: '1',
      activated: false,
      passed: false,
    },
    {
      name: 'Stage 1',
      id: '1',
      activated: false,
      passed: false,
    },
    {
      name: 'Stage 1',
      id: '1',
      activated: false,
      passed: false,
    },
    {
      name: 'Stage 1',
      id: '1',
      activated: false,
      passed: false,
    },
  ]

  return (
    <AnimatePresence>
      {reschdeduleJobInterview && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-0 right-0 h-screen w-[85%] lg:w-[662px] overflow-scroll  rounded-lg bg-white px-6 py-8"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={InterviewDetailsModalAnim}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2  ">
                <p className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-[#E2B93B] bg-opacity-10">
                  <Calendar2 size="18" color="#E2B93B" variant="Bulk" />
                </p>
                <h3 className="text-base font-bold">Reschedule meeting time</h3>
              </div>
              <div className="flex items-center gap-3">
                {/* <InterviewModalOpenIcons /> */}
                <button
                  type="button"
                  className=" rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setReschdeduleJobInterview(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <div>
              <div>
                <p className="mt-8 text-xl">
                  Interview for “{jobTitle}” interview
                </p>
                <div className="mt-3 flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <Calendar2 size="16" color="#000" variant="TwoTone" />
                    <p className="text-sm">Wednesday, 22nd Mar 2023</p>
                  </div>
                  <p>•</p>
                  <div className="flex items-center gap-2">
                    <Clock size="16" color="#000" variant="TwoTone" />
                    <p className="text-sm">
                      1:00 PM - 2:30 PM (1 hour, 30 mins)
                    </p>
                  </div>
                </div>
                <div className="mt-10 flex w-full flex-col py-6 ">
                <div className="flex items-start pb-5 gap-2">
                    <div>
                      {data?.photoUrl == '' ? (
                        <div>
                          <p
                            style={{ background: 'black' }}
                            className="-ml-[10px] flex h-[45px] w-[45px] items-center justify-center rounded-full border-[3px] border-white text-sm font-bold"
                          >
                            {data?.firstName} {data?.lastName}
                          </p>
                        </div>
                      ) : (
                        <img
                          src={data?.companyLogo || ''}
                          className="profile-picture h-[57px] w-[57px] rounded-full border-[3px] border-white"
                          alt="applicant"
                        />
                      )}
                    </div>
                    <div className=" pr-10">
                      <p className="text-sm font-bold">
                        {data?.companyName}
                      </p>
                      <p className="mt-1 text-xs">{jobTitle}</p>
                      <p className="mt-1 text-xs text-[#A6A6A6]">
                        {data?.companyLocation}
                      </p>
                    </div>
                  </div>

                  <div className=" flex  w-full items-center flex-wrap whitespace-nowrap gap-1 border-t border-b-[#E5E5E5] pt-6">
                    {interViewStages.map((stage) => (
                      <p
                        key={stage.id}
                        className={clsx(
                          stage.passed
                            ? 'bg-primary text-white'
                            : 'bg-[#191919] bg-opacity-10  text-black',
                          'mt-1 w-fit rounded-sm px-2 py-1 text-xs'
                        )}
                      >
                        {stage.name}
                      </p>
                    ))}
                    <AddCircle
                      className="ml-1"
                      size="18"
                      color="#27AE60"
                      variant="TwoTone"
                    />
                  </div>
                </div>
                <form action="">
                  <div className="mt-6">
                    <p className="text-sm font-bold">
                      <span className="text-sm text-primary">*</span> Interview
                      Date
                    </p>
                    <input
                      type="date"
                      className="mt-2 h-12 w-full rounded-md border border-gray-150 bg-white bg-transparent px-4 font-WorkSans outline-none placeholder:text-sm placeholder:text-gray-250 focus-within:border-secondary disabled:bg-gray-100"
                    />
                    <div className="">
                      <p className="mt-8 text-sm font-bold">
                        <span className="text-sm text-primary">*</span>{' '}
                        Interview Time
                      </p>
                      <div className="mt-2 flex items-center gap-2 ">
                        <input
                          type="time"
                          className="h-12 w-full rounded-md border border-gray-150 bg-white bg-transparent px-4 font-WorkSans outline-none placeholder:text-sm placeholder:text-gray-250 focus-within:border-secondary disabled:bg-gray-100"
                        />
                        <button className="whitespace-nowrap rounded-md bg-[#EB5757] px-5 py-3 text-xs font-bold text-white">
                          Delete Stage
                        </button>
                      </div>
                      
                    </div>
                  </div>
                  <div>
                    <p className="mt-8  text-sm font-bold">
                      Reason for rescheduling
                    </p>
                    <p className="text-xs">
                      {/* We’ve setup a template for you to use, but feel free to
                      customize to your taste */}
                    </p>
                    <div>
                      
                      <TextAreaField
                        className="txt-sm mt-2"
                        id="reschdeduleReason"
                        registration={{ ...register('reschdeduleReason') }}
                        hasError={errors.reschdeduleReason}
                        value={reschdeduleReasonLimit}
                      ></TextAreaField>
                    </div>
                    <Button className="mx-auto mt-10 mb-6 w-[365px] min-w-fit">
                      Done
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
