import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Image from 'next/image'

import { Button, Modal, TextAreaField } from '@/components'
import { DisqualifyApplicantSchema } from '../../utils'
import { Interview } from '@/features/job-seeker'

interface DisqualifiedApplicantModalProps {
  openDisqualifiedApplicantModal: boolean
  setOpenDisqualifiedApplicantModal: (value: boolean) => void
  data?: Interview | undefined
}

interface DisQualifyReasonsForm {
  disQualifyReasons: string
}

export const DisqualifiedApplicantModal: React.FC<
  DisqualifiedApplicantModalProps
> = ({
  openDisqualifiedApplicantModal,
  setOpenDisqualifiedApplicantModal,
  data,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<DisQualifyReasonsForm>({
    defaultValues: {
      disQualifyReasons:
        // 'Dear Candidate, Thank you for taking the time to consider [Company_name]. We wanted to let you know that we have chosen to move forward with a different candidate for the [Job_title] position. [Optionally, include feedback from the hiring process for candidates who may be suitable for future openings:] Our team was impressed by your skills and accomplishments. [It’s best to include something that specifically drew your attention.] We think you could be a good fit for other future openings and will reach out again if we find a good match. We wish you all the best in your job search and future professional endeavors. Regards, [Your name]',
        '',
    },
    resolver: yupResolver(DisqualifyApplicantSchema),
  })
  // const { mutate, isLoading } = useChangeApplicantStatus(id || '')

  const disQualifyReasonsLimit = watch('disQualifyReasons')

  const { firstName, lastName, email, phone } = data || {}

  const handleCloseModal = () => {
    setOpenDisqualifiedApplicantModal(false)
  }

  const onSubmit = (values: DisQualifyReasonsForm) => {
    console.log({ values })
  }

  return (
    <Modal
      className="mx-auto max-w-xl"
      variant="middle"
      showDialog={openDisqualifiedApplicantModal}
      closeModal={handleCloseModal}
    >
      <div className="bg-white p-4">
        <Image
          className="mx-auto"
          height={272}
          width={272}
          alt="applicant"
          src="/assets/Disqualified.gif"
        />
        <div className="flex flex-col items-center justify-center text-center">
          <h3 className="mt-3 text-[24px]">Uh Ohh!! Candidate Not Selected</h3>
          <p className="mt-8 text-sm">Reason for declining.</p>
        </div>
        <div className="my-4 flex flex-1  items-stretch gap-3">
          {data?.photoUrl == '' ? (
            <div>
              <p
                style={{ background: 'black' }}
                className="-ml-[10px] flex h-[45px] w-[45px] items-center justify-center rounded-full border-[3px] border-white text-sm font-bold"
              >
                {data?.firstName} {data?.lastName}
              </p>
            </div>
          ) : (
            <img
              src={data?.photoUrl || ''}
              className="profile-picture h-[45px] w-[45px] rounded-full border-[3px] border-white"
              alt="applicant"
            />
          )}
          <div className="flex flex-col items-stretch justify-between pr-6">
            <p className="text-sm font-bold">{firstName + ' ' + lastName}</p>
            <p className="mt-1 text-xs">{email}</p>
            <p className="mt-1 text-xs">{phone}</p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextAreaField
            className="mt-2 text-sm"
            id="disQualifyReasons"
            registration={{ ...register('disQualifyReasons') }}
            hasError={errors.disQualifyReasons}
            value={disQualifyReasonsLimit}
          ></TextAreaField>
          <div className="mt-10 mb-6 flex justify-center gap-5">
            <Button onClick={handleCloseModal}>Close</Button>
            <Button
              onClick={handleCloseModal}
              style={{ background: '#EB5757' }}
              variant="outline"
            >
              Discard Applicant
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
