import { useRouter } from 'next/router'
import React from 'react'

export function useMultiForm(steps: React.ReactElement[]) {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0)
  const [previousStepIndex] = React.useState(0)
  const router = useRouter()
  const currentStep = Number(router.query.step)

  const next = (step: number) => {
    router.push(`/recruiter/onboarding?step=${step}`)
  }

  const nextTwo = (step: number) => {
    router.push(`/job-seeker/onboarding?step=${step}`)
  }
  function back() {
    if (currentStepIndex === 0) {
      setCurrentStepIndex(previousStepIndex)
      return
    }
    setCurrentStepIndex((i) => {
      if (i <= 0) return i
      return i - 1
    })
  }
  function goTo(index: number) {
    setCurrentStepIndex(index)
  }
  return {
    currentStepIndex,
    step: steps[currentStep - 1],
    isFirstStep: currentStep === 1,
    isSecondStep: currentStep === 2,
    isThirdStep: currentStep === 3,
    isFourthStep: currentStep === 4,
    isFifthStep: currentStep === 5,
    isLastStep: currentStepIndex === steps.length - 1,
    teacherSignUpStep: currentStepIndex === 2,
    steps,
    goTo,
    next,
    next2: nextTwo,
    back,
  }
}
