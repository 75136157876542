import {
  AddCircle,
  CloseCircle,
  Edit,
  Gallery,
  Profile2User,
  Send2,
  Trash,
} from 'iconsax-react'
import { BsDot, BsXLg } from 'react-icons/bs'
import { backdropVariant, publicProfile } from '@/utils'
import { AnimatePresence, motion } from 'framer-motion'
import { RiDoubleQuotesL } from 'react-icons/ri'
import { useForm } from 'react-hook-form'
import Image from 'next/image'
import React from 'react'

import {
  CompanyEditAboutModal,
  CompanyEditPreferenceModal,
  CompanyEditProfile,
  CompanyManagePerksModal,
  CompanyPerkData,
  CompanyUploadMedia,
  useCompanyDetail,
  useDeleteCompanyPerk,
  useGetInvitedMembers,
  useInviteUser,
} from '@/features/recruiter'
import { InterviewerDetailsSchema } from '@/utils/validationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { FullPageLoader } from '@/features/auth'
import { Button } from '../Elements'
import { InputField } from '../Form'
import { useRolesAndPermissions } from '@/api'
import { useComponentVisible } from '@/hooks'
import { ModalDelete } from './ModalDelete'

interface CompanyProfileModalProp {
  setOpenCompanyProfile: (value: boolean) => void
  openCompanyProfile: boolean
}

const CompanyProfileModal = ({
  setOpenCompanyProfile,
  openCompanyProfile,
}: CompanyProfileModalProp) => {
  const [companyManagePerk, setCompanyManagePerk] = React.useState(false)
  const [OpenCompanyEditProfileModal, setOpenCompanyEditProfileModal] =
    React.useState(false)
  const [companyEditPreference, setCompanyEditPreference] =
    React.useState(false)
  const [companyEditAboutModal, setCompanyEditAboutModal] =
    React.useState(false)
  const [OpenCompanyUploadMediaModal, setOpenCompanyUploadMediaModal] =
    React.useState(false)
  const [access, setAccess] = React.useState('')
  const [inviteMember, setInviteMember] = React.useState(false)

  const { data: InterviewerAccess } = useRolesAndPermissions()
  const interviewerAccessData = InterviewerAccess?.data || []
  const [isEdit, setIsEdit] = React.useState(true)

  const { data: companyData, isLoading } = useCompanyDetail()
  const mutationDeletePerk = useDeleteCompanyPerk()
  const clickedPerkRef = React.useRef({ name: '', description: '', id: '' })
  const clickedPerk = clickedPerkRef.current

  const {
    isComponentVisible: showDialogDelete,
    setIsComponentVisible: setShowDialogDelete,
  } = useComponentVisible()

  const mutationAddInterviewer = useInviteUser()
  const { data: teamMembers } = useGetInvitedMembers()

  const {
    companyLogo,
    companyName,
    companyDescription,
    industry,
    companyLocation,
    perks,
    hired,
    applicants,
    jobs,
    employeeCount,
  } = companyData?.data || {}

  interface InterviewerDetails {
    email: string
    permission: string
  }

  interface InterviewerDetailsPayload {
    email: string
    roleId: string
  }
  const {
    formState: { errors },
    register,
    reset,
    handleSubmit,
  } = useForm<InterviewerDetails>({
    resolver: yupResolver(InterviewerDetailsSchema),
  })

  const handleChangePerk = (perk: CompanyPerkData) => {
    setIsEdit(true)
    clickedPerkRef.current = perk
  }

  const handleOpenDeletePerk = (id: string) => {
    clickedPerk.id = id
    setShowDialogDelete(true)
  }

  const handleCloseDialogDeletePerk = () => {
    clickedPerk.id = ''
    setShowDialogDelete(false)
  }

  const handleDeletePerk = () => {
    mutationDeletePerk.mutate(clickedPerk.id, {
      onSuccess: () => {
        handleCloseDialogDeletePerk()
      },
    })
  }
  const handleCloseAddInterviewer = () => {
    reset()
  }

  const onSubmit = (values: InterviewerDetails) => {
    const userAccessData = interviewerAccessData?.find(
      (element) => element.id === values.permission
    )
    const { email } = values
    const payload: InterviewerDetailsPayload = {
      email: email,
      roleId: userAccessData?.id || '',
    }
    mutationAddInterviewer.mutate(payload, {
      onSuccess: () => {
        reset()
      },
    })
  }

  return (
    <AnimatePresence>
      {openCompanyProfile && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            className="scrollbar-hide absolute bottom-0 left-0 z-50 h-full w-full overflow-scroll bg-[#EFEFEF] pb-16 text-black"
            variants={publicProfile}
          >
            <div className="bg-white py-6">
              <div className="mx-auto flex max-w-[1500px] justify-between px-4 sm:px-16 ">
                <p className="text-left text-2xl font-bold">Company Profile</p>
                <button
                  className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-gray-200 text-[9px]"
                  onClick={() => setOpenCompanyProfile(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            {isLoading ? (
              <FullPageLoader height="h-[85vh]" />
            ) : (
              <>
                <div className="mx-auto max-w-[1500px]">
                  <div className="px-4 sm:px-16">
                    <div className="mt-5 flex flex-col gap-6 lg:flex-row">
                      <div className="flex-1 rounded-lg bg-white px-6 py-6">
                        <div className="group my-2 flex items-center gap-6 border-b-[#E5E5E5] bg-white pb-4">
                          <button
                            className="overflow-hidden sm:min-h-[115px] min-w-[80px] min-h-[80px] h-[80px] w-[80px] sm:h-[115px] sm:min-w-[115px] sm:w-[115px] rounded-md"
                            onClick={() => setOpenCompanyEditProfileModal(true)}
                          >
                            <img
                              className="w-full h-full"
                              alt=""
                              src={companyLogo || ''}
                            />
                          </button>
                          <div className=" sm:pr-10">
                            <div className="flex items-center gap-4">
                              <p className="font-bold sm:text-xl">
                                {companyName}
                              </p>
                              <button
                                className="hidden transition duration-75 group-hover:block"
                                onClick={() =>
                                  setOpenCompanyEditProfileModal(true)
                                }
                              >
                                <Edit
                                  size="19"
                                  color="#27AE60"
                                  variant="Bulk"
                                />
                              </button>
                            </div>
                            <p className="mt-2 text-xs">
                              {industry} Company in
                            </p>
                            <div className="mt-2 flex gap-1">
                              <p className="w-fit bg-[#EFEFEF] px-2 py-1 text-xs">
                                {companyLocation}
                              </p>
                            </div>
                            <div className="mt-1 hidden items-center gap-2 sm:flex">
                              <Profile2User
                                size="20"
                                color="#27AE60"
                                variant="Bulk"
                              />
                              <p className="mt-1 text-xs text-black">
                                {} {employeeCount} employees
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-1 flex items-center gap-2 sm:hidden">
                          <Profile2User
                            size="20"
                            color="#27AE60"
                            variant="Bulk"
                          />
                          <p className="mt-1 text-xs text-black">
                            {} {employeeCount} employees
                          </p>
                        </div>
                        <div className="mt-2 md:ml-[120px]">
                          <div className="group">
                            <div className="mt-3 flex items-center gap-10 md:-ml-8">
                              <div className="ml-[-8px] hidden h-[19px] w-[19px] items-center justify-center rounded-full bg-primary bg-opacity-10 text-xs text-primary md:flex">
                                <RiDoubleQuotesL />
                              </div>
                              <p className="text-sm text-[#A6A6A6]">
                                About {companyName}
                              </p>
                              <button
                                className="hidden transition duration-75 group-hover:block"
                                onClick={() => setCompanyEditAboutModal(true)}
                              >
                                <Edit
                                  size="19"
                                  color="#27AE60"
                                  variant="Bulk"
                                />
                              </button>
                            </div>
                            <p className="mt-2 w-full pr-0 text-sm leading-6 sm:pr-4 md:ml-5">
                              {companyDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex-1 rounded-lg bg-white py-6 sm:min-w-[399px]">
                        <p className="border-b border-b-[E5E5E5] px-8 pb-3 text-base font-bold">
                          Scouting Credentials
                        </p>
                        <div className="mt-2 px-8">
                          <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Jobs Posted:{' '}
                            <span className="font-bold">{jobs}</span>
                          </p>
                          <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Candidates Applied:{' '}
                            <span className="font-bold">{applicants}</span>
                          </p>
                          <p className="mt-4 flex items-center text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Applicants Hired: <span> </span>{' '}
                            <span className="font-bold">{hired}</span>
                          </p>
                          <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Hiring Rate:
                          </p>
                          <p className="mt-4 flex text-sm">
                            <BsDot className="mr-[10px] text-xl" />
                            Posting Rate:
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Remote Policy */}
                    {/* <div className="mt-6 rounded-lg bg-white px-6 py-8 text-black">
                      <div className="flex justify-between">
                        <p className="text-base font-bold">Remote Policy</p>
                      </div>
                      <div className="mt-4 flex  flex-wrap gap-x-4 gap-y-3 text-left text-xs">
                        {companyProfileData.RemotePolicy.map((preference) => (
                          <div
                            className="flex flex-col justify-start"
                            key={preference.id}
                          >
                            <p className="w-fit bg-[#EFEFEF] px-2 py-1 text-xs">
                              {preference.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div> */}

                    {/* Office Media */}

                    <div className=" mt-6 rounded-lg bg-white px-6 py-8 text-black">
                      <p className="text-base font-bold">Office Media</p>
                      <button
                        onClick={() => setOpenCompanyUploadMediaModal(true)}
                        className="mt-4 flex h-[145px] w-[195px] items-center justify-center rounded-lg border-2 border-dashed border-primary bg-[#27AE60] bg-opacity-10"
                      >
                        <Gallery size="32" color="#27AE60" variant="Bulk" />
                      </button>
                    </div>

                    {/* Perks and benefit */}

                    <div className=" mt-6 rounded-lg bg-white px-6 py-8 text-black">
                      <div className="flex items-center gap-3">
                        <p className="text-base font-bold">Perks and benefit</p>
                        <button
                          onClick={() => {
                            handleChangePerk({
                              name: '',
                              description: '',
                              id: '',
                            })
                            setCompanyManagePerk(true)
                            setIsEdit(false)
                          }}
                        >
                          <AddCircle
                            size="24"
                            color="#27AE60"
                            variant="TwoTone"
                          />
                        </button>
                      </div>

                      <div className="grid grid-cols-1 items-start gap-y-4 gap-x-10 md:grid-cols-2">
                        {perks?.map((perk, index) => (
                          <div
                            onClick={() => handleChangePerk(perk)}
                            key={index}
                            className="group flex items-center gap-4"
                          >
                            <div className="mt-8 flex items-start gap-3">
                              <div className="min-w-[40px] ">
                                <Image
                                  src="/assets/badgeCompany.png "
                                  alt="badge"
                                  height={40}
                                  width={40}
                                />
                              </div>
                              <div className="text-sm">
                                <div className="flex items-center gap-6">
                                  <p className="text-sm font-bold">
                                    {perk.name}
                                  </p>
                                  <div className="flex items-stretch gap-4">
                                    <button
                                      className="hidden rounded-sm bg-primary bg-opacity-20 px-[3px] transition duration-75 group-hover:block"
                                      onClick={() => {
                                        setCompanyManagePerk(true)
                                      }}
                                    >
                                      <Edit
                                        size="15"
                                        color="#27AE60"
                                        variant="Bulk"
                                      />
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleOpenDeletePerk(perk.id)
                                      }
                                      className="hidden rounded-sm bg-red-400 bg-opacity-20 p-[3px] transition delay-[0.5] duration-75 group-hover:block"
                                    >
                                      <Trash
                                        size="15"
                                        variant="Bulk"
                                        color="#b30000"
                                      />
                                    </button>
                                  </div>
                                </div>
                                <p className="mt-1">{perk.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Recruiting Team */}
                    <div className=" mt-6 rounded-lg bg-white py-8 text-black">
                      <div className="flex items-center justify-between border-b px-6 pb-4">
                        <p className="text-base font-bold">Team Members</p>
                        <button
                          className="flex items-center gap-2"
                          onClick={() => setInviteMember(!inviteMember)}
                        >
                          {!inviteMember && (
                            <AddCircle
                              size="14"
                              color="#27AE60"
                              variant="TwoTone"
                            />
                          )}
                          {!inviteMember ? (
                            <p className="text-xs font-semibold text-primary">
                              Invite a new member
                            </p>
                          ) : (
                            <p
                              className="text-xs font-semibold text-primary"
                              onClick={handleCloseAddInterviewer}
                            >
                              Close
                            </p>
                          )}
                        </button>
                      </div>

                      {/* INTERVIEWER PERMISSION */}
                      {inviteMember && (
                        <div className="mt-8 px-6">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="items-start gap-10 pb-10 md:flex ">
                              <div className="flex-1">
                                <InputField
                                  label="Email Address"
                                  registration={{ ...register('email') }}
                                  hasError={errors.email}
                                  // errorMessage={errors.email?.message}
                                  isRequired
                                />
                              </div>
                              <div className="flex-1">
                                <p className="flex items-center gap-1 font-semibold">
                                  <span className="text-primary">*</span>
                                  Interviewer permissions
                                </p>
                                <div className="flex items-center gap-6">
                                  <div className="mt-2 flex w-full items-center gap-6 text-sm">
                                    {interviewerAccessData?.map((data) => {
                                      return (
                                        <div
                                          key={data.id}
                                          className="flex items-center gap-2"
                                        >
                                          <input
                                            onClick={() => setAccess(data.id)}
                                            id=""
                                            type="radio"
                                            {...register('permission')}
                                            value={data.id}
                                          />
                                          <p>{data.name}</p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                                {!errors?.permission?.message ? (
                                  interviewerAccessData?.map((data) => {
                                    if (data.id == access)
                                      return (
                                        <p
                                          key={data.id}
                                          className="mt-3 text-xs text-primary"
                                        >
                                          {data.description}
                                        </p>
                                      )
                                  })
                                ) : (
                                  <p className="mt-3 text-xs text-red-600">
                                    {errors?.permission?.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="mb-28 mt-0 flex w-fit justify-center sm:mb-2">
                              <Button
                                disabled={mutationAddInterviewer.isLoading}
                                isLoading={mutationAddInterviewer.isLoading}
                                className="w-full"
                                type="submit"
                              >
                                {mutationAddInterviewer.isLoading
                                  ? 'Inviting...'
                                  : 'Invite member'}
                              </Button>
                            </div>
                          </form>
                        </div>
                      )}

                      {/* <div className="scrollbar-hide mt-14 flex gap-5 overflow-scroll px-6">
                        {teamMembers?.data.map((details, index) => (
                          <div
                            className="relative mb-8 flex min-w-[295px] flex-col items-center overflow-hidden rounded-lg bg-white py-6 px-6 shadow-lg transition duration-100 ease-in-out hover:shadow-sm"
                            key={index}
                          >
                            <button className="absolute  top-2 right-0 p-5 text-[#A6A6A6] hover:text-black">
                              <BsThreeDotsVertical />
                            </button>
                            <h2 className="flex h-[97px] w-[97px] items-center justify-center rounded-full bg-black text-[32px] text-white ">
                              {details.firstName[0]}
                            </h2>

                            <p className="mt-3 text-center text-lg font-bold">
                              {details?.firstName || ''}
                            </p>
                            <p className="mt-1 text-center text-xs">{}</p>
                          </div>
                        ))}
                      </div> */}

                      <div className=" mt-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
                        {teamMembers?.data.map((details, index) => (
                          <div
                            className="rounded-lg border text-left sm:text-left sm:w-full bg-white py-4 px-6 shadow-lg transition duration-100 ease-in-out hover:shadow-sm"
                            key={index}
                          >
                            {details?.isActive ? (
                              <>
                                <div className=" flex flex-wrap sm:flex-nowrap justify-center sm:justify-start items-center space-x-3">
                                  <div>
                                    <p className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#27AE60] text-2xl font-medium text-white">
                                      {details.firstName[0]}
                                      {details.lastName[0]}
                                    </p>
                                  </div>

                                  <div>
                                    {' '}
                                    <p className="text-lg font-normal">
                                      {details?.firstName || ''}{' '}
                                      {details?.lastName || ''}
                                      <span className="ml-2 rounded bg-[#27AE60] bg-opacity-10 p-1 text-xs text-primary">
                                        {details?.roleName}
                                      </span>
                                    </p>
                                    <p className="mt-0 text-sm font-light">
                                      {details?.email}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flex justify-between">
                                  <div className="">
                                    <p className="text-sm">
                                      Invitation sent
                                    </p>
                                    <p className="text-md font-medium">
                                      {details?.email}
                                    </p>
                                    <p className="text-sm text-gray-250">
                                      {details?.createdAt}
                                    </p>
                                  </div>
                                  <div>
                                    <div className="flex space-x-1">
                                      <Send2 size="18" color="#27AE60" />
                                      <CloseCircle size="18" color="#FF8A65" />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </motion.div>
          <CompanyEditAboutModal
            companyEditAboutModal={companyEditAboutModal}
            setCompanyEditAboutModal={setCompanyEditAboutModal}
            companyDescription={companyDescription || ''}
          />
          <CompanyManagePerksModal
            companyManagePerk={companyManagePerk}
            setCompanyManagePerk={setCompanyManagePerk}
            clickedPerk={clickedPerk}
            isEdit={isEdit}
          />
          <CompanyEditProfile
            isLoadingCompanyData={isLoading}
            companyData={companyData?.data}
            OpenCompanyEditProfileModal={OpenCompanyEditProfileModal}
            setOpenCompanyEditProfileModal={setOpenCompanyEditProfileModal}
            companyDescription={companyDescription || ''}
          />
          <CompanyEditPreferenceModal
            companyEditPreference={companyEditPreference}
            setCompanyEditPreference={setCompanyEditPreference}
          />
          <ModalDelete
            title={clickedPerk.name}
            isLoading={mutationDeletePerk.isLoading}
            handleDelete={handleDeletePerk}
            showDialog={showDialogDelete}
            closeModal={handleCloseDialogDeletePerk}
          />
          <CompanyUploadMedia
            OpenCompanyUploadMediaModal={OpenCompanyUploadMediaModal}
            setOpenCompanyUploadMediaModal={setOpenCompanyUploadMediaModal}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CompanyProfileModal
