import { ExternalJob } from '@/types'
import { array, object, string } from 'yup'

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const OPTION_VALIDATION = object()
  .shape({
    name: string().required('Required'),
    id: string().required('Required'),
  })
  .required('Required')

export const OPTIONS_VALIDATION = array()
  .of(
    object().shape({
      name: string().required('Required'),
      id: string().required('Required'),
    })
  )
  .required('Required')

export const EXTERNAL_JOBS: ExternalJob[] = [
  {
    imageUrl:
      'https://res.cloudinary.com/softstructure/image/upload/v1688401386/scout4job/netflix_e2jel4.png',
    link: 'https://jobs.netflix.com/jobs/279107631',
    id: '1',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/softstructure/image/upload/v1684566720/scout4job/EY_-_senior_manager-01_kxss26.png',
    link: 'https://careers.ey.com/ey/job/Cape-Town-Senior-Manager-Supply-Chain-&-Operations-%28Cape-Town%29-West-8001/926902101/',
    id: '2',
  },
  {
    imageUrl:
      'https://res.cloudinary.com/softstructure/image/upload/v1684566720/scout4job/financial_analyst_-_workopolis-01_by8plh.png',
    link: 'https://www.workopolis.com/jobsearch/viewjob/C9SHa9UXcX1tVWdjL4ym7gCHmlB3mSgM77me9FoYmhdHhDZs7D45fC63Rhx9IClW?uilJobKey=3-0-1h0q3k6jnk26q801-1h0q3k6k0kf2r800-d9bf02ac5b4f96a6-',
    id: '3',
  },
]
export const websiteRoutes = ['/', '/jobs', '/recruiter', '/privacy-policy']

export const recruiterRoutes = [
  '/recruiter/dashboard',
  '/recruiter/settings',
  '/recruiter/dashboard/jobs',
  '/recruiter/dashboard/interview',
  '/recruiter/dashboard/talent-pool',
  '/recruiter/dashboard/all-applicants',
  '/recruiter/dashboard/jobs/[id]',
  '/recruiter/dashboard/jobs/applicants/[id]',
  '/recruiter/dashboard/company',
  '/recruiter/dashboard/post-job',
  '/recruiter/dashboard/approval',
  '/recruiter/dashboard/approval/applicants/[id]',
]

export const jobSeekerRoutes = [
  '/job-seeker/dashboard',
  '/job-seeker/dashboard/jobs',
  '/job-seeker/dashboard/applied',
  '/job-seeker/dashboard/saved-jobs',
  '/job-seeker/dashboard/profile',
  '/job-seeker/dashboard/settings',
]

export const publicRoutes = [
  ...websiteRoutes,
  '/login',
  'sign-up',
  '/jobs/[id]',
  '/resources',
  '/resources/articles',
  '/resources/discover-companies',
  '/resources/interview-questions',
  '/resources/salary-calculator',
  '/resources/articles/[id]',
]

export const protectedRoutes = [
  ...recruiterRoutes,
  ...jobSeekerRoutes,
  '/recruiter/dashboard/interview/schedule/[id]',
]

export const CVHeroSectionPoints = [
  {
    description:
      '350+ Word templates and examples that are suitable for a range of different career levels - from entry level to senior level - and industries.',
    id: '1',
  },
  {
    description:
      'Browse through the templates below and download one that you like. Edit it and replace the sample text with your own experience.',
    id: '2',
  },
  {
    description:
      'Download eye catching resumé templates tailored to help you get a job in no time',
    id: '3',
  },
]

export const CVCategories = [
  {
    name: 'All Templates',
    id: '1'
  },
  {
    name: 'Professional',
    id: '2'
  },
  {
    name: 'Modern',
    id: '3'
  },
  {
    name: 'Creative',
    id: '4'
  },
]

export const CVExamples = [
  {
    name: 'Resume template 1',
    category: 'professional',
    id: '1',
  },
  {
    name: 'Resume template 2',
    category: 'creative',
    id: '2',
  },
  {
    name: 'Resume template 3',
    category: 'professional',
    id: '3',
  },
  {
    name: 'Resume template 4',
    category: 'modern',
    id: '4',
  },
  {
    name: 'Resume template 5',
    category: 'professional',
    id: '5',
  },
]