

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import {  fetchData, imageKeys,  } from '@/utils'
import { useCompanyId } from '@/features/recruiter'




interface UploadResponse {
    status: string
    message: string
    data: {
      fileData: File[]
    }
  }

export const useUploadMedia = () => {
  const queryClient = useQueryClient()
  const companyID =  useCompanyId()
  const formData = new FormData();
  return useMutation<UploadResponse, unknown, FormData>(
      (payload) => {
        payload.forEach((file, index) => formData.append(`image${index}`, file)); 
        return (
            fetchData<FormData>(`/company/media/add/${companyID}`, 'POST', payload)
        )
    },
    {
      onSuccess: () => {
        toast.success('Company image uploaded successfully')
        queryClient.invalidateQueries({
          queryKey: imageKeys.list(companyID)
        })
      },
      onError: () => {
        toast.error('Company image not uploaded')
      },
    }
  )
}

