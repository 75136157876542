import { Briefcase, EmptyWallet, Teacher } from 'iconsax-react'
import Image from 'next/image'
import dayjs from 'dayjs'
import React from 'react'

import { EmptyState, PageSpinner } from '@/components'
import {
  useApplicantExperience,
  useGetEducation,
  useGetSkills,
  useGetUserProfile,
} from '../api'

interface ApplicantProfileViewProps {
  id: string
  showExperience?: boolean
}

export const ApplicantProfileView = ({
  id,
  showExperience,
}: ApplicantProfileViewProps) => {
  const { data: experienceData, isLoading: isLoadingExperience } =
    useApplicantExperience(id)

  const experiences = experienceData?.data || []
  const { data: userProfile } = useGetUserProfile(id as string)
  const { data: educationData } = useGetEducation(id)
  const educations = educationData?.data || []
  const { data: skillsData, isLoading: isLoadingSkills } = useGetSkills(id)
  const skills = skillsData?.data || []

  const {
    photoUrl,
    firstName,
    lastName,
    currentCompany,
    location,
    currentJob,
    yearsOfExperience,
    description,
    email,
    phone,
  } = userProfile?.data || {}


  return (
    <div className="scrollbar-hide bottom-0 left-0 w-full overflow-scroll px-5 py-6 text-black ">
      <div className="top-0 left-0 mx-auto max-w-[1500px] ">
        <div className="flex justify-between">
          <div className="flex items-center gap-5"></div>
        </div>
      </div>
      <div className="relative flex  items-start justify-between rounded-lg">
        <div
          className="  scrollbar-hide relative  flex-1 overflow-scroll"
          style={{ height: 'calc(100vh - 156px)' }}
        >
          <div className="rounded-lg border">
            <Image
              src="/assets/backdropProfile.png"
              alt="backdrop picture"
              height={20}
              width={1160}
              style={{ height: '100px' }}
            />
            <div className="flex flex-wrap gap-2 rounded-b-lg bg-white pb-0 text-black">
              <div className="h-fit w-fit rounded-full pl-[30px]">
                <img
                  className="profile-picture mt-[-40px] h-[85px] w-[85px] flex-1"
                  src={photoUrl || ''}
                  alt="profile picture"
                />
              </div>
              <div className="flex-1 pr-6 pb-6 text-left">
                <div className="flex items-end justify-between">
                  <div className="mb-2">
                    <p className="mt-3 text-xl font-medium">
                      {firstName} {lastName}
                    </p>
                    <p className="mt-1  text-xs">
                      {currentJob} • {yearsOfExperience} years
                    </p>
                    <p className="mt-1  text-xs">
                      {currentCompany}, {location}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs"><strong className=''>Email: </strong>{email}</p>
                    <p className="text-xs mt-2"><strong className=''>Phone: </strong>{phone}</p>
                  </div>
                </div>
                <hr />
                <p className="mt-2 w-full text-sm leading-6 ">{description}</p>
              </div>
            </div>
          </div>
          {/* <div className="mt-6 rounded-lg border bg-white px-6 py-8 text-black">
            <div className="flex justify-between">
              <p className="text-base font-bold">Work Preferences</p>
            </div>

          </div> */}

          {showExperience && (
            <div className="mt-6 rounded-lg border bg-white px-6  py-6 text-black">
              <div className="text flex justify-between">
                <p className="text-lg font-medium">Experience</p>
              </div>
              <div className="mt-0 grid grid-cols-2 gap-2">
                {!isLoadingExperience ? (
                  <>
                    {experiences.length > 0 ? (
                      experiences.map(
                        ({
                          companyName,
                          endDate,
                          startDate,
                          title,
                          workType,
                          id,
                        }) => {
                          return (
                            <div className="mt-8 flex gap-5" key={id}>
                              <div className="flex h-[50px] w-[50px] min-w-[50px] min-h-[50px] items-center justify-center rounded-full bg-[#27AE60] bg-opacity-10">
                                <Briefcase
                                  size="24"
                                  color="#27AE60"
                                  variant="Bold"
                                />
                              </div>
                              <div className="flex-1">
                                <p className="text-[16px]  font-normal">
                                  {title}
                                </p>
                                <p className="mb-1 text-sm">{companyName}</p>
                                <p className="mb-1 text-xs">
                                  {dayjs(startDate).format('MMMM, YYYY')} -{' '}
                                  {endDate
                                    ? dayjs(endDate).format('MMMM, YYYY')
                                    : 'Present'}
                                </p>
                                <p className="text-xs capitalize">{workType}</p>
                              </div>
                            </div>
                          )
                        }
                      )
                    ) : (
                      <EmptyState
                        className="mt-16"
                        maxWidthClass="max-w-[260px]"
                        icon={
                          <EmptyWallet
                            size="32"
                            className="mx-auto text-primary"
                            variant="Bulk"
                          />
                        }
                        description="No work experience"
                      />
                    )}
                  </>
                ) : (
                  <PageSpinner className="mt-14" />
                )}
              </div>
            </div>
          )}

          {/* education section */}
          <div className="mt-6 rounded-lg border bg-white px-6 py-8 text-black">
            <div className="text flex justify-between">
              <p className="text-lg font-medium">Education</p>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              {educations.map(
                ({
                  endDate,
                  grade,
                  institute,
                  qualification,
                  startDate,
                  id,
                  course
                }) => (
                  <div key={id} className="flex gap-4">
                    <div className="flex h-[50px] w-[50px] min-w-[50px] min-h-[50px] items-center justify-center rounded-full bg-[#27AE60] bg-opacity-10">
                      <Teacher size="24" color="#27AE60" variant="Bold" />
                    </div>
                    <div>
                      <p className="text-[16px] font-normal">{institute}</p>
                      <p className="mt-1 text-xs">{course}</p>
                      <p className="mt-1 text-xs">{qualification}</p>
                      <p className="mt-1 text-xs">
                        {dayjs(startDate).format('MMMM, YYYY')} -{' '}
                        {dayjs(endDate).format('MMMM, YYYY')}
                      </p>
                      <p className="mt-1 text-xs">{grade}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          {/* skills */}
          <div className="mt-6 rounded-lg border bg-white px-6 py-8 text-black">
            <div className="text flex justify-between">
              <p className="text-lg font-medium">Skills</p>
            </div>
            {isLoadingSkills ? (
              <PageSpinner className="mt-14" />
            ) : (
              <>
                {skills && skills?.length > 0 ? (
                  <>
                    <div className="mt-4 flex flex-wrap justify-start gap-4">
                      {skills?.map((info, index) => (
                        <div
                          key={index}
                          className="group flex items-center justify-between gap-2  overflow-hidden rounded-full bg-black py-2 px-4 text-sm  transition duration-100  ease-in-out"
                        >
                          <p className="w-fit whitespace-nowrap text-sm text-white ">
                            {info.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <EmptyState
                    className="mt-16"
                    maxWidthClass="max-w-[260px]"
                    icon={
                      <EmptyWallet
                        size="32"
                        className="mx-auto text-primary"
                        variant="Bulk"
                      />
                    }
                    description="No skills has been added yet."
                  />
                )}
              </>
            )}
          </div>
        </div>

        {/* second grid column  */}
      </div>
      <style>
        {`
          .shadowCard {
            box-shadow: 0px 0px 30px -15px rgba(0, 0, 0, 0.25);
          }
        `}
      </style>
    </div>
  )
}
