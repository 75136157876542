import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { experienceKeys, fetchData } from '@/utils';
import { ManageExperiencePayload } from '../types';


export const useAddExperience = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ManageExperiencePayload) =>
      fetchData<ManageExperiencePayload>("/user/add-work-experience", 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Experience successfully added.');
        queryClient.invalidateQueries({
          queryKey: experienceKeys.all,
        });
      },
      onError: () => {
        toast.error('Experience not added.');
      },
    }
  );
};