import React from 'react'
import clsx from 'clsx'

interface FullPageLoaderProps {
  bgColor?: string
  height?: string
}

export const FullPageLoader: React.FC<FullPageLoaderProps> = ({
  bgColor = 'bg-gray-100',
  height = 'h-screen'
}) => {
  return (
    <div className={height}>
      <div
        className={clsx(
          'inset-0  flex h-screen items-center justify-center',
          height,
          bgColor
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            margin: 'auto',

            display: 'block',
            shapeRendering: 'auto',
          }}
          width="80px"
          height="80px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(50 50) scale(0.74) translate(-50 -50)">
            <g>
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                calcMode="spline"
                dur="6.666666666666666s"
                values="0 50 50;90 50 50;180 50 50;270 50 50;360 50 50"
                keyTimes="0;0.25;0.5;0.75;1"
                keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
              ></animateTransform>
              <g>
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  dur="1.6666666666666665s"
                  repeatCount="indefinite"
                  calcMode="spline"
                  values="1;1;0.5"
                  keyTimes="0;0.5;1"
                  keySplines="1 0 0 1;1 0 0 1"
                ></animateTransform>
                <g transform="translate(25 25)">
                  <rect x="-25" y="-25" width="52" height="52" fill="#27ae60">
                    <animate
                      attributeName="fill"
                      dur="6.666666666666666s"
                      repeatCount="indefinite"
                      calcMode="spline"
                      values="#27ae60;#191919;#27ae60;#121212;#27ae60"
                      keyTimes="0;0.25;0.5;0.75;1"
                      keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
                    ></animate>
                  </rect>
                </g>
                <g transform="translate(25 75)">
                  <rect x="-25" y="-25" width="52" height="50" fill="#27ae60">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      dur="1.6666666666666665s"
                      repeatCount="indefinite"
                      calcMode="spline"
                      values="0;1;1"
                      keyTimes="0;0.5;1"
                      keySplines="1 0 0 1;1 0 0 1"
                    ></animateTransform>
                    <animate
                      attributeName="fill"
                      dur="6.666666666666666s"
                      repeatCount="indefinite"
                      calcMode="spline"
                      values="#27ae60;#191919;#27ae60;#121212;#27ae60"
                      keyTimes="0;0.25;0.5;0.75;1"
                      keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
                    ></animate>
                  </rect>
                </g>
                <g transform="translate(75 25)">
                  <rect x="-25" y="-25" width="50" height="52" fill="#27ae60">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      dur="1.6666666666666665s"
                      repeatCount="indefinite"
                      calcMode="spline"
                      values="0;1;1"
                      keyTimes="0;0.5;1"
                      keySplines="1 0 0 1;1 0 0 1"
                    ></animateTransform>
                    <animate
                      attributeName="fill"
                      dur="6.666666666666666s"
                      repeatCount="indefinite"
                      calcMode="spline"
                      values="#27ae60;#191919;#27ae60;#121212;#27ae60"
                      keyTimes="0;0.25;0.5;0.75;1"
                      keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
                    ></animate>
                  </rect>
                </g>
                <g transform="translate(75 75)">
                  <rect x="-25" y="-25" width="50" height="50" fill="#27ae60">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      dur="1.6666666666666665s"
                      repeatCount="indefinite"
                      calcMode="spline"
                      values="0;1;1"
                      keyTimes="0;0.5;1"
                      keySplines="1 0 0 1;1 0 0 1"
                    ></animateTransform>
                    <animate
                      attributeName="fill"
                      dur="6.666666666666666s"
                      repeatCount="indefinite"
                      calcMode="spline"
                      values="#27ae60;#191919;#27ae60;#121212;#27ae60"
                      keyTimes="0;0.25;0.5;0.75;1"
                      keySplines="0 1 0 1;0 1 0 1;0 1 0 1;0 1 0 1"
                    ></animate>
                  </rect>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}
