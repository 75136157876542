import { AnimatePresence, motion } from 'framer-motion'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import {
  ViewJobPostAnim,
  backdropVariant,
  getFormattedDayMonthYear,
  getFormattedHourMinSec,
} from '@/utils'
import { Button, InputDateField } from '@/components'
import { JobApplicant } from '@/features/job-seeker'
import { Control, useForm } from 'react-hook-form'
import { SendOfferModalForm } from '../../types'
import { toast } from 'react-toastify'
import { useSendOfferLetter } from '../../api'
import { sendOfferLetterSchema } from '../../utils'
import { yupResolver } from '@hookform/resolvers/yup'
// import { useObjectURL } from '@/hooks'
import { useFileUpload } from '@/api'
import { useRouter } from 'next/router'
import { useDropzone } from 'react-dropzone'
import { DOCUMENT_ACCEPT } from '@/features/onboarding'
import Image from 'next/image'
import clsx from 'clsx'

interface SendOfferProp {
  applicant: JobApplicant | undefined
  openSendOfferModal: boolean
  SetOpenSendOfferModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const SendOfferModal = ({
  applicant,
  openSendOfferModal,
  SetOpenSendOfferModal,
}: SendOfferProp) => {
  const ref = React.useRef<HTMLInputElement>(null)

  const router = useRouter()
  const { id: jobId } = router.query

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openSendOfferModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        SetOpenSendOfferModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  })

  const mutateSendOfferLetter = useSendOfferLetter()
  const [imgUrl, setImgUrl] = React.useState('')
  const [disabled, setDisabled] = React.useState(true)
  const mutationFileUpload = useFileUpload()

  const { watch, setValue, control, handleSubmit } =
    useForm<SendOfferModalForm>({
      defaultValues: {
        letterUrl: null,
      },
      resolver: yupResolver(sendOfferLetterSchema),
    })

  const letters = watch('letterUrl')
  // const letter = useObjectURL(letters && letters[0])

  const { acceptedFiles, isDragActive, getRootProps, getInputProps, open } =
    useDropzone({
      accept: DOCUMENT_ACCEPT,
      maxFiles: 1,
      onDropAccepted: (acceptedFiles: File[]) => {
        setValue('letterUrl', acceptedFiles)
      },

      onDropRejected: (error) => {
        if (error.length > 1) {
          toast.error('Multiple files not allowed!')
          return
        }

        // Just one file
        const { code, message } = error[0].errors[0]

        if (code === 'file-invalid-type') {
          toast.error(message)
          return
        }

        if (code === 'file-too-large') {
          toast.error('File must be 100kb or less')
        }
      },
    })

  React.useEffect(() => {
    if (letters && letters.length > 0) {
      const file = letters[0]

      const formData = new FormData()
      formData.append('file', file)
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setImgUrl(data.fileUrl)
          setDisabled(false)
        },
        onError: () => {
          setValue('letterUrl', null)
        },
      })
    }
  }, [letters])

  const onSubmit = (value: SendOfferModalForm) => {
    if (imgUrl === '') {
      return toast.error('Please upload filecommi t-m ')
    }

    const payload = {
      userId: applicant?.userId,
      jobId: jobId,
      letterUrl: imgUrl,
      deadline:
        getFormattedDayMonthYear(new Date(value.offerExpiryDate)) +
        ' ' +
        getFormattedHourMinSec(new Date(value.offerExpiryTime)).toString(),
    }
    mutateSendOfferLetter.mutate(payload, {
      onSuccess: () => {
        setValue('letterUrl', null)
        setValue('offerExpiryDate', '')
        setValue('offerExpiryTime', '')
      },
      // OnError: () => {
      //   toast.error('Error sending offer letter')
      // }
    })
  }

  return (
    <AnimatePresence>
      {openSendOfferModal && (
        <motion.div
          className=" scrollbar-hide absolute top-0 right-0 z-[100] h-[100vh] w-screen overflow-y-scroll bg-black bg-opacity-40 text-left text-black"
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            variants={ViewJobPostAnim}
            initial="initial"
            animate="show"
            ref={ref}
            exit="exit"
            className="absolute top-0 right-0 min-h-screen w-[85%] bg-white px-12 pb-10 md:w-[630px]"
          >
            <div className="sticky top-0 flex items-center justify-between gap-4 bg-white pt-[20px] pb-2">
              <h3>Send Offer</h3>
              <button
                className="top-14 right-0 rounded-full bg-gray-200 p-2 text-[9px] "
                onClick={() => SetOpenSendOfferModal(false)}
              >
                <BsXLg />
              </button>
            </div>
            <p className="mt-8 text-sm">
              Congrats on getting this far!! 🎉 <br /> Now you need to send your
              candidate an offer letter to secure the deal.
            </p>
            <div className="mt-8">
              <div className="my-6 flex items-start gap-4 border-b-[#E5E5E5] pb-6">
                <div>
                  {applicant?.photoUrl ? (
                    <img
                      src={applicant?.photoUrl}
                      alt="setUpProfile"
                      className="profile-picture mb-2 h-[60px] w-[60px]"
                    />
                  ) : (
                    <div>
                      <p className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-black text-2xl font-[600] text-white">
                        {applicant?.firstName[0]}
                      </p>
                    </div>
                  )}
                </div>
                <div className=" pr-10">
                  <p className="text-sm font-bold">
                    {applicant?.firstName || ''} {applicant?.lastName || ''}
                  </p>
                  <p className="mt-1 text-xs">{applicant?.email}</p>
                  <p className="mt-1 text-xs text-[#A6A6A6]">
                    {applicant?.location}
                  </p>
                </div>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className=" flex w-full items-center gap-6">
                  <div className="flex-1">
                    <InputDateField
                      label="Offer expiry date"
                      control={control as unknown as Control}
                      placeholder="Offer expiry date"
                      name={`offerExpiryDate`}
                      minDate={new Date()}
                      hasError={undefined}
                      isRequired
                    />
                  </div>
                  <div className="flex-1">
                    <InputDateField
                      control={control as unknown as Control}
                      placeholder="Start time"
                      hasError={undefined}
                      name="offerExpiryTime"
                      label="Offer expiry time"
                      showTimeSelect
                      showTimeSelectOnly
                      isRequired
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </div>
                </div>
                <div className="flex gap-4"></div>
                <div>
                  <p className="mt-8 mb-3 text-left text-sm font-[700]">
                    Attach Offer Letter
                  </p>
                  <>
                    <button
                      {...getRootProps({ className: 'dropzone' })}
                      className={clsx(
                        'padding mt-4 flex w-full flex-col items-center justify-center rounded-xl  border-2 border-dashed border-[#E6E5E5] py-6',
                        isDragActive && 'border-[#2F80ED] bg-[#F2F8FF]'
                      )}
                      onClick={open}
                      type="button"
                    >
                      <Image
                        src="/assets/uploadBig.png"
                        alt="jobLogo"
                        height={40}
                        width={40}
                      />
                      <div className="mt-6">
                        {acceptedFiles[0] ? (
                          <div>
                            <p className="text-[#2F80ED]">
                              {acceptedFiles[0].name}
                            </p>
                            {mutationFileUpload.isLoading && (
                              <p className="mt-2  text-xs text-gray-400">
                                Uploading...
                              </p>
                            )}
                          </div>
                        ) : (
                          <p className="text-center text-xs leading-7">
                            <span className="text-[#2F80ED]">Browse</span> or
                            drag & drop to upload your resume as a <br />{' '}
                            <span className="font-[700]">.doc, .docx</span> or{' '}
                            <span className="font-[700]">.pdf file</span>
                          </p>
                        )}
                      </div>
                    </button>
                    <input {...getInputProps()} />
                  </>
                </div>
                <div className="mt-8 flex items-center gap-4">
                  <Button
                    isLoading={
                      mutationFileUpload.isLoading ||
                      mutateSendOfferLetter.isLoading
                    }
                    type="submit"
                    disabled={disabled}
                  >
                    {mutationFileUpload.isLoading
                      ? 'Uploading...'
                      : mutateSendOfferLetter.isLoading
                      ? 'Sending'
                      : 'Send Offer'}
                  </Button>
                </div>
              </form>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
