import React from 'react'
import clsx from 'clsx'

interface LabelTitleProps {
  text: string
  isRequired?: boolean
  id: string
}

export const LabelTitle: React.FC<LabelTitleProps> = ({
  text,
  isRequired,
  id,
}) => {
  return (
    <label className="flex items-start space-x-[6px]" htmlFor={id}>
      <span
        className={clsx('text-xl text-primary', !isRequired && 'invisible')}
      >
        *{' '}
      </span>
      <p className="font-neue text-[16px]">{text}</p>
    </label>
  )
}
