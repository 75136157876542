import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import dayjs from 'dayjs'

import { Job } from '@/features/job-seeker'
import { BusinessAdsCard, ExternalJobCard, JobCard, JobDetails } from '.'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { BusinessAdsData, ExternalJob } from '@/types'
// import { EXTERNAL_JOBS } from '@/utils'
import { useFetchBusinessAds, useFetchExternalJob } from '@/api'

dayjs.extend(relativeTime)
interface JobsProps {
  jobs: Job[]
  currJobs: Job[]
}

export const Jobs: React.FC<JobsProps> = ({ currJobs }) => {
  const { data } = useFetchExternalJob()
  const { data: businessAdsData } = useFetchBusinessAds()
  const externalJobData = data?.data
  const businessAds = businessAdsData?.data || []

  const router = useRouter()

  const [modalOpen, setModalOpen] = React.useState(false)

  const jobDetailIdRef = React.useRef('')
  const isHomePage = router.pathname === '/'

  const handleOpenModal = (
    jobId: string,
    isEasyApply: boolean,
    companyName: string
  ) => {
    const companyNameNoSpace = companyName?.split(' ').join('')
    if (isEasyApply) {
      router.push(`/jobs/apply/${companyNameNoSpace}/${jobId}`)
    } else {
      jobDetailIdRef.current = jobId
      setModalOpen(true)
    }
  }

  let track = 0
  let adsTrack = 0

  const allJobs: (Job | ExternalJob | BusinessAdsData)[] = [] || []

  currJobs?.forEach((job, index) => {
    allJobs.push(job)
    const isOdd = index % 2 === 1
    const isEven = index % 2 === 0
    if (isOdd && externalJobData && externalJobData[track]) {
      allJobs?.push(externalJobData[track])
      track++
    }
    if (isEven && businessAds && businessAds[adsTrack]) {
      allJobs.push(businessAds[adsTrack])
      adsTrack++
    }
  })

  allJobs.forEach(function(job, i){
    if('isSpotlighted' in job && job.isSpotlighted === true){
      allJobs.splice(i, 1);
      allJobs.unshift(job);
    }
   });

  return (
    <>
      <div
        className={clsx(
          'grid-row-2 grid grid-cols-1 items-stretch gap-10',
          isHomePage ? 'xl:grid-cols-2' : 'xl:grid-cols-2'
        )}
      >
        {allJobs &&
          allJobs.map((job, index) => {
            const isLink = job && typeof job === 'object' && 'link' in job
            const isAds =
              job && typeof job === 'object' && 'businessLogo' in job
            if (isLink) {
              return <ExternalJobCard key={index} job={job} />
            }

            if (isAds) {
              return <BusinessAdsCard key={index} businessAds={job} />
            }

            return (
              <JobCard
                key={index}
                handleOpenModal={() =>
                  handleOpenModal(job?.slug, job.isEasyApply, job.companyName)
                }
                job={job}
              />
            )
          })}
        {/* {currJobs?.map((job, index) =>
          selectedFilters.length == 0 ? (
            <JobCard
              key={index}
              handleOpenModal={() => handleOpenModal(job.id)}
              job={job}
            />
          ) : (
            filteredJobs[index] && (
              <JobCard
                key={index}
                handleOpenModal={() => handleOpenModal(job.id)}
                job={job}
              />
            )
          )
        )} */}
      </div>
      <JobDetails
        jobId={jobDetailIdRef.current}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  )
}
