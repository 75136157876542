import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { fetchData, skillsKeys } from '@/utils';

interface AddSkillSet {
  name: string;
}

export const useAddSkillSet = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: AddSkillSet) =>
      fetchData<AddSkillSet>("/common/add-skill-set", 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: skillsKeys.all,
        });
      },
      onError: () => {
        toast.error('Skill not added.');
      },
    }
  );
};