import { axiosInstance } from '@/lib/axios'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { object, string } from 'yup'
import { OPTION_VALIDATION } from './constants'

export const InterviewerDetailsSchema = object({
  email: string().required('Required'),
  permission: string().required(
    'Interviewer permission is required please select one'
  ),
})

export const CompanyEditProfileSchema = object({
  industries: OPTION_VALIDATION,
  address: string().required('Required'),
  companyName: string().required('Required'),
  companyAbout: string().required('Required'),
  companyPhone: string().required('Required'),
  comapanyWebsite: string()
    .url('Invalid URL')
    .matches(
      /^(https?:\/\/)?([^\s/$.?#].[^\s]*)?$/,
      'Website must start with http:// or https://'
    )
    .nullable(),
})

export const CompanyEditPreference = object({
  companyLocation: string().required('Required'),
  remotePolicy: string().required('Required'),
  remoteLocation: string().required('Required'),
})

export const CompanyManagePerkSchema = object({
  perkName: string().required('Required'),
  perkDescription: string().required('Required'),
})

export const RescheduleJobSeekerSchema = object({
  time: string().required('Required'),
  date: string().required('Required'),
  reschdeduleReason: string().required('Required'),
})

export const InterviewFeedbackSchema = object({
  overallComment: string().required(
    'you have to add an overall recommendation and rating to proceed'
  ),
})

export const CustomApplyValidationNew = object({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  age: string().required('Required'),
  nyscDate: string().required('Required'),
  phone: string()
    .test('validation-phone', 'Invalid phone number', (value = '') => {
      return isValidPhoneNumber(value)
    })
    .required('Required'),

  email: string()
    .email('Email address is invalid')
    .required('Required')
    .test(
      'Unique Email',
      'An account with this email exists.', // <- key, message
      function (value) {
        return new Promise((resolve) => {
          axiosInstance
            .post('/auth/check-user-email', { email: value })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              resolve(false)
            })
        })
      }
    ),
  password: string()
    .test(
      'validation-password',
      'Password must be at least 8 characters including at least one lowercase, one uppercase, one number and special character',
      (value = '') => {
        const regEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/
        return regEx.test(value)
      }
    )
    .required('Required'),
    institution: OPTION_VALIDATION,
    state: OPTION_VALIDATION,
    grade: OPTION_VALIDATION,
    course: string().required('Required'),
})

export const CustomApplyValidationExisting = object({
  age: string().required('Required'),
  nyscDate: string().required('Required'),
  email: string()
  .email('Email address is invalid')
  .required('Required'),
  institution: OPTION_VALIDATION,
  state: OPTION_VALIDATION,
  grade: OPTION_VALIDATION,
  course: string().required('Required'),
})


