import { OPTIONS_VALIDATION, OPTION_VALIDATION } from '@/utils'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { boolean, date, object, ref, string } from 'yup'

export const manageExperienceSchema = object({
  jobTitle: OPTION_VALIDATION,
  typeOfEmployment: OPTION_VALIDATION,
  workCulture: OPTION_VALIDATION,
  companyName: string().required(),
  isCurrentlyEmployed: boolean(),
  startDate: date().required(),
  endDate: date().when('isCurrentlyEmployed', (isCurrentlyEmployed, schema) => {
    
    if (isCurrentlyEmployed[0]) {
      return schema.notRequired()
    }
    return schema.required('Required')
  }),
  // jobDescription: string().required(),
})
export const extentDeadlineSchema = object({
  deadline: date().required(),
})

export const bioSchema = object({
  bio: string().required()
})

export const manageEducationSchema = object({
  institute: OPTION_VALIDATION,
  course: string().required(),
  qualification: OPTION_VALIDATION,
  startDate: date().required(),
  endDate: date().required(),
  grade: string().required(),
})

export const manageSkillSchema = object({
  skills: OPTIONS_VALIDATION,
})

export const manageReportJobSchema = object({
  reason: string().required('Required'),
  comment: string().required('Required')
})

export const changePasswordSchema = object({
  currentPassword: string().required('Required'),
  newPassword: string()
    .test(
      'validation-password',
      'Password must be at least 8 characters including at least one lowercase, one uppercase, one number and special character',
      (value = '') => {
        const regEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/
        return regEx.test(value)
      }
    )
    .required('Required'),
  confirmPassword: string()
    .oneOf([ref('newPassword')], 'Passwords must match')
    .required('Required'),
})

export const basicInfoSchema = object({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  phone: string()
    .test('validation-phone', 'Invalid phone number', (value = '') => {
      return isValidPhoneNumber(value)
    })
    .required('Required'),
})

// schema for companyEditProfile
