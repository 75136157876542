import Image from 'next/image'
import { useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Button } from '@/components'
import { backdropVariant, editProfile } from '@/utils'
import { JobApplicant } from '@/features/job-seeker'

interface HiredApplicantModalProps {
  openHiredApplicantModal: boolean
  setOpenHiredApplicantModal: (value: boolean) => void
  data: JobApplicant | undefined
  jobTitle?: string | undefined
}

export const HiredApplicantModal: React.FC<HiredApplicantModalProps> = ({
  openHiredApplicantModal,
  setOpenHiredApplicantModal,
  data,
  jobTitle,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  const {
    firstName,
    lastName,
    email,
    phone,
    // interviewLink,
    // role,
    // interviewSchedule,
  } = data || {}



  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openHiredApplicantModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenHiredApplicantModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openHiredApplicantModal])

  //how to code select button

  return (
    <AnimatePresence>
      {openHiredApplicantModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide fixed top-[50%] left-[50%] flex max-h-[612px] w-[511px] flex-col items-center overflow-scroll  rounded-lg bg-white px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            style={{ height: 'calc(100vh - 156px)' }}
            variants={editProfile}
          >
            <div className="flex flex-col items-center justify-center text-center">
              {/* <Image  /> */}
              <Image
                height={213}
                width={206}
                alt="qualified"
                src="/assets/QualifiedCandiate.gif"
              />

              <h4 className="my-2 text-2xl">
                You have finally seen your new {jobTitle}
              </h4>

              <div className="my-2 flex items-center gap-4 border-b-[#E5E5E5] pb-6">
                <div className="flex flex-1  items-stretch gap-3">
                  {data?.photoUrl == '' ? (
                    <div>
                      <p
                        style={{ background: 'black' }}
                        className="-ml-[10px] flex h-[45px] w-[45px] items-center justify-center rounded-full border-[3px] border-white text-sm font-bold"
                      >
                        {data?.firstName} {data?.lastName}
                      </p>
                    </div>
                  ) : (
                    <img
                      src={data?.photoUrl || ''}
                      className="profile-picture h-[45px] w-[45px] rounded-full border-[3px] border-white"
                      alt="applicant"
                    />
                  )}
                  <div className="flex flex-col items-start justify-start pr-6">
                    <p className="text-sm font-bold">
                      {firstName + ' ' + lastName}
                    </p>
                    <p className="mt-1 text-xs">{email}</p>
                    <p className="mt-1 text-xs">{phone}</p>
                  </div>
                </div>

              </div>
              <p className="text-sm ">
                Congratulations!! on your new employee 🎉 Now you need to make a
                decision on the other applicants
              </p>

              <div className="mt-8 flex items-center gap-4">
                <Button onClick={() => setOpenHiredApplicantModal(false)}>
                  Done Hiring
                </Button>
                <Button  variant="outline">Keep Hiring</Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
