import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { AddInterviewFeedbackPayload } from '../types'
import { fetchData, interviewFeedbacksKeys, interviewInfoKeys } from '@/utils'

export const useAddInterviewFeedback = (interviewId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: AddInterviewFeedbackPayload) =>
      fetchData(
        `/company/applicants/add-interview-feedback/${interviewId}`,
        'POST',
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: interviewInfoKeys.all,
          
        })
        queryClient.invalidateQueries({
          queryKey: interviewFeedbacksKeys.all,
          
        })
        toast.success('Feedback submitted successfully')
      },
      onError: () => {
        toast.error('Feedback not submitted')
      },
    }
  )
}
