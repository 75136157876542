import { fetchData, skillsKeys, transformError } from "@/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const useDeleteSkill = () => {
    const queryClient = useQueryClient();
    return useMutation<Response, AxiosError, string>(
        (skillId: string) => fetchData(`/user/skills/delete/${skillId}`, 'DELETE'),
        {
            onSuccess: () => {
                toast.success('Skills successfully deleted');
                queryClient.invalidateQueries({
                    queryKey: skillsKeys.lists()
                });

            },
            onError: (error) => {
                toast.error(transformError(error))
            }
        }
    )
}