import React, { ReactNode } from 'react'
import { motion } from 'framer-motion'

// Define the type for the children prop
interface WrapperProps {
  children: ReactNode
}

// Word wrapper
const Wrapper: React.FC<WrapperProps> = (props) => {
  // We'll do this to prevent wrapping of words using CSS
  return <span className="word-wrapper">{props.children}</span>
}

// Map API "type" vaules to JSX tag names
const tagMap = {
  paragraph: 'p',
  heading1: 'h1',
  heading2: 'h2',
}

interface AnimatedCharactersProps {
  quote: {
    type: string
    text: string
  }[]
}

// AnimatedCharacters
// Handles the deconstruction of each word and character to setup for the
// individual character animations
export const AnimatedText: React.FC<AnimatedCharactersProps> = (props) => {
  // Framer Motion variant object, for controlling animation
  const item = {
    hidden: {
      y: '200%',
      color: '#000',
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
    visible: {
      y: 0,
      color: '#ccc9c9',
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
    },
  }

  return (
    <>
      {props.quote?.map((part, index) => {
        // Split each word of part.text into an array
        const splitWords = part.text?.split(' ')

        // Get the tag name from tagMap
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const TagName = tagMap[part.type]
        const Tag: React.ElementType = TagName as keyof JSX.IntrinsicElements

        return (
          <Tag key={index.toString()}>
            {splitWords.map((word, index) => {
              return (
                <Wrapper key={index.toString()}>
                  <span
                    style={{
                      overflow: 'hidden',
                      display: 'inline-block',
                    }}
                    key={index.toString()}
                  >
                    <motion.span
                      style={{ display: 'inline-block' }}
                      variants={item}
                    >
                       {`${word}\u00A0`}
                    </motion.span>{' '}
                  </span>
                </Wrapper>
              )
            })}
          </Tag>
        )
      })}
      <style jsx>{`
        p:nth-of-type(1) {
          font-size: 32px;
          line-height: 38px;
          font-weight: 100;
          color: '#ccc9c9';
        }
        p:nth-of-type(2) {
        }
        h2 {
          margin-top: 32px;
          color: white;
          font-size: 18px;
        }
      `}</style>
    </>
  )
}
