import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

import { fetchData, transformError } from '@/utils'
import { RegisterForm } from '../types'

type RegisterPayload = Omit<RegisterForm, 'confirmPassword'>

export const useRegister = () => {
  return useMutation<Response, AxiosError, RegisterPayload>(
    (payload) =>
      fetchData<RegisterPayload>('/auth/sign-up', 'POST', payload),
    {
      onError: (error) => {
        toast.error(transformError(error))
      },
    }
  )
}
