import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import {  companyKeys, transformError } from '@/utils';
import {fetchData} from '@/utils';
// import { companyIDStorage } from '@/features/auth';
import { useCompanyId } from '../hooks';

export const useDeleteCompanyPerk = () => {
  const queryClient = useQueryClient();
  const companyID =  useCompanyId()
  return useMutation<Response, AxiosError, string>(
    (perkId: string) => fetchData(`/company/perks/delete/${perkId}`, 'DELETE'),
    {
      onSuccess: () => {
        toast.success('Perk successfully deleted.');
        queryClient.invalidateQueries({
          queryKey: companyKeys.list(companyID),
        });
      },
      onError: (error) => {
        toast.error(transformError(error));
      },
    }
  );
};
