import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { appliedJobKeys, fetchData, transformError } from '@/utils';
import { AcceptOfferPayload } from '../types';
import { AxiosError } from 'axios';


export const useAcceptOffer = () => {
  const queryClient = useQueryClient();
  return useMutation<Response, AxiosError, AcceptOfferPayload>(
    (payload: AcceptOfferPayload) =>
      fetchData("/user/job/offer/accept", 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Job Offer Accepted');
        queryClient.invalidateQueries({
          queryKey: appliedJobKeys.all,
        });
      },
      onError: (error) => {
        toast.error(transformError(error));
      },
    }
  );
};