import { backdropVariant, editProfile, getFormattedDayMonthYear } from '@/utils'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import { BsXLg } from 'react-icons/bs'
import { InputDateField } from '../Form'
import { Control, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ExtendDeadlineForm, ExtendDeadlinePayload, extentDeadlineSchema, useExtendDeadline } from '@/features/job-seeker'
// import { ExtendDeadlinePayload, useExtendDeadline } from '@/features/job-seeker/api/useExtendDeadline'

interface ExtendDeadlineProps {
  openExtendDeadlineModal: boolean
  setOpenExtendDeadlineModal: (value: boolean) => void
  jobId: string | undefined

}
export const ExtendDeadline: React.FC<ExtendDeadlineProps> = ({
  openExtendDeadlineModal,
  setOpenExtendDeadlineModal,
  jobId
}) => {

  const ref = useRef<HTMLInputElement>(null)
  // const { data, isLoading: getJobLoading } = useJob(jobId || '')
  // const { validThrough } = data?.data || {}
  const mutateExtendDeadline = useExtendDeadline(jobId || '')

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openExtendDeadlineModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenExtendDeadlineModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openExtendDeadlineModal])

  const {
    reset,
    control,
    // setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ExtendDeadlineForm>({
    defaultValues: {
      deadline: null
    },
    resolver: yupResolver(extentDeadlineSchema),
  })

  // useEffect(() => {
  //   reset({
  //     deadline: validThrough ? new Date(validThrough) : null
  //   })
  // }, [getJobLoading])


  const onSubmit = (values: ExtendDeadlineForm) => {
    const { deadline } = values

    const payload: ExtendDeadlinePayload = {
      validThrough: getFormattedDayMonthYear(deadline) 
    }
    const handleSuccess = () => {
      setOpenExtendDeadlineModal(false)
      reset()
    }

    mutateExtendDeadline.mutate(payload, {
      onSuccess: handleSuccess
    })
  }
  return (
    <AnimatePresence>
      {openExtendDeadlineModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide z-[100]  absolute top-[50%]  left-[50%]  min-w-[500px] rounded-lg bg-white px-12 py-8"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={editProfile}
          >

            <div>
              <div>
                <p className="text-base font-bold">Extend Deadline</p>
                <button
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setOpenExtendDeadlineModal(false)}
                >
                  <BsXLg />
                </button>
              </div>
              <p className="mb-4 text-sm">
                Extend closing date for job opening
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex-1">
                <p className="mt-5 text-sm font-semibold">
                  <span className="text-sm text-primary">*</span> Deadline
                </p>
                <InputDateField
                  name="deadline"
                  className="mt-2"
                  control={control as unknown as Control}
                  placeholder="Deadline"
                  hasError={errors.deadline}
                  //maxDate={endDate || new Date()}
                  isRequired
                />
              </div>
              <div className="mt-2">
                <button type='submit'
                  disabled={mutateExtendDeadline.isLoading}
                  className="display mx-auto mt-[2rem] flex w-[232px] items-center justify-center gap-5 bg-secondary py-[12.5px] px-[38px] text-xs font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44] transition duration-200 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919] md:text-sm ">
                 {mutateExtendDeadline.isLoading ?"Saving" : "Save"}
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
