import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Link from 'next/link'
import React from 'react'

import { Button, InputField } from '@/components'
import { userIDStorage } from '@/features/auth'
import { changePasswordSchema } from '../utils'
import { ChangePasswordForm } from '../types'
import { useChangePassword } from '../api'
import { useToggle } from '@/hooks'

export const ChangePassword = () => {
  const { show: currentShow, handleToggle: handleCurrentToggle } = useToggle()
  const { show: newPasswordShow, handleToggle: handleNewPasswordToggle } =
    useToggle()
  const {
    show: confirmPasswordShow,
    handleToggle: handleConfirmPasswordToggle,
  } = useToggle()
  const userId = userIDStorage.getUserID()
  const { mutate, isLoading } = useChangePassword(userId)
  const {
    reset,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ChangePasswordForm>({
    resolver: yupResolver(changePasswordSchema),
  })

  const onSubmit = (payload: ChangePasswordForm) => {
    mutate(payload, {
      onSuccess: () => {
        reset()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mx-auto mt-6 w-[320px]">
      <div>
        <InputField
          label="Current Password"
          type={currentShow ? 'text' : 'password'}
          hasError={errors.currentPassword}
          registration={{ ...register('currentPassword') }}
          errorMessage={errors.currentPassword?.message}
          handleShowPassword={handleCurrentToggle}
          withIcon
        />
      </div>

      <div className="mt-4">
        <InputField
          label="New Password"
          type={newPasswordShow ? 'text' : 'password'}
          hasError={errors.newPassword}
          registration={{ ...register('newPassword') }}
          errorMessage={errors.newPassword?.message}
          handleShowPassword={handleNewPasswordToggle}
          withIcon
        />
      </div>

      <div className="mt-4">
        <InputField
          label="Confirm Password"
          type={confirmPasswordShow ? 'text' : 'password'}
          hasError={errors.confirmPassword}
          registration={{ ...register('confirmPassword') }}
          errorMessage={errors.confirmPassword?.message}
          handleShowPassword={handleConfirmPasswordToggle}
          withIcon
        />
      </div>

      <div className="mt-3 text-center">
        <Link
          href="/forgot-password"
          className="mt-3 text-center text-sm text-primary"
        >
          Forgot Password
        </Link>
      </div>
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        type="submit"
        className="mt-4 w-full"
      >
        {isLoading ? 'Saving...' : 'Save changes'}
      </Button>
    </form>
  )
}
