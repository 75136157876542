// import { useCompanyLogos } from '@/api'
import { companies } from '@/data'

import Image from 'next/image'
import React from 'react'


export const Infinite = () => {
  const companyLogos = companies;
  // const [companyLogos, setCompanyLogos] = React.useState(customers)
  // const { data: companyLogoData, isLoading: isLoadingCompanyLogo } =
  //   useCompanyLogos()

  // React.useEffect(() => {
  //   if (!isLoadingCompanyLogo && companyLogoData) {
  //     const newCompanyLogos = companyLogoData.data.map(
  //       ({ companyLogo }, index) => ({
  //         image: companyLogo,
  //         id: companyLogos.length + index + 1,
  //       })
  //     )
  //     setCompanyLogos([...companyLogos, ...newCompanyLogos])
  //   }
  // }, [isLoadingCompanyLogo])

  return (
    <div className=" relative z-40 mx-auto box-border h-[65px] w-full overflow-hidden px-3 md:w-[600px] ">
      <div
        className="absolute z-[100] ml-[-12px] h-[65px]  w-full md:w-[615px] "
        style={{
          background:
            'radial-gradient(circle, rgba(253,29,29,0) 65%, rgba(250,250,250,1) 97%, rgba(250,250,250,1) 100%)',
        }}
      />
      <div className="absolute flex w-[500%] items-center gap-14 pl-4">
        <div className="ribbon z-40 box-border flex items-center gap-10">
          {companyLogos.map((customer, index) => (
            <div
              key={index}
              className="relative"
              style={{
                width: 100,
                height: 55,
              }}
            >
              <Image
                src={customer.image}
                alt="customer-logo"
                className="object-contain"
                fill
              />
            </div>
          ))}
        </div>
        <div className="ribbon z-10 box-border flex items-center gap-10 pr-4">
          {companyLogos.map((customer, index) => (
            <div
              key={index}
              className="relative"
              style={{
                width: 100,
                height: 55,
              }}
            >
              <Image
                src={customer.image}
                alt="customer-logo"
                className="object-contain"
                fill
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
