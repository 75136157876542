import { useQuery } from '@tanstack/react-query'

import { savedJobKeys, fetchData } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { Job } from '../types'

interface SavedJobJobResponse {
  status: string
  message: string
  data: Job[]
}

export const useSavedJobs = () => {
  const userId = userIDStorage.getUserID()
  return useQuery<SavedJobJobResponse>({
    queryKey: savedJobKeys.list(userId),
    queryFn: async () => fetchData(`/user/saved-jobs/${userId}`),
  })
}