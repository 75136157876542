import { yupResolver } from '@hookform/resolvers/yup'
import { Control, useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import React from 'react'
import {
  CustomApplyValidationExisting,
  CustomApplyValidationNew,
} from '@/utils/validationSchema'
import { useFileUploadExternal } from '@/api'
import clsx from 'clsx'

import { DOCUMENT_ACCEPT } from '@/features/onboarding'
import { useToggle, useUserInfo } from '@/hooks'
import { CustomApplyForm, Option } from '@/types'
import {
  Button,
  InputDateField,
  InputField,
  InputFieldPhoneNo,
  InputUseInstitutionAutoComplete,
  SelectField,
} from '@/components'
import { getFormattedDayMonthYear } from '@/utils'
import { useEasyApply } from '../api'
import { GRADES_OPTIONS, STATE_IN_NIGERIA } from '@/data'

interface customApplyFormProps {
  jobId: string
  companyId: string
}
export const CustomJobApplyForm = ({
  jobId,
  companyId,
}: customApplyFormProps) => {
  const [signUpType, setSignUpType] = React.useState('new')

  const { user, isLoading: isLoadingUserInfo } = useUserInfo()
  const { isJobSeeker } = user || {}

  const loggedInUser = user && isJobSeeker

  const {
    register,
    setValue,
    watch,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<CustomApplyForm>({
    resolver: yupResolver(
      signUpType === 'new' && !loggedInUser
        ? CustomApplyValidationNew
        : CustomApplyValidationExisting
    ),
  })


  
  const [resumeUrl, setResumeUrl] = React.useState<string | null>(null)
  const [coverLetterUrl, setCoverLetterUrl] = React.useState<string | null>(
    null
  )
  const mutationFileUpload = useFileUploadExternal()
  const mutationCoverLetterUpload = useFileUploadExternal()

  const { show, handleToggle } = useToggle()

  const handleSelectApplicant = (signUpId: string) => {
    setSignUpType(signUpId)
    setValue('email', '')
    setValue('phone', '')
    setValue('institution.name', '')
    setValue('institution.id', '')
    setValue('institution.id', '')
    clearErrors()
  }

  React.useEffect(() => {
    if (loggedInUser) {
      setValue('firstName', user?.firstName)
      setValue('lastName', user?.lastName)
      setValue('email', user?.email)
    }
  }, [isLoadingUserInfo])

  const ApplicantStatus = [
    {
      signUpId: 'existing',
      signUpName: 'I have a Scout4job account',
      signUpDescription: 'Select if you have an account on Scout4job',
    },
    {
      signUpId: 'new',
      signUpName: "I'm new to Scout4job",
      signUpDescription: "Select if you don't have an account on Scout4job",
    },
  ]

  const { acceptedFiles, isDragActive, getRootProps, getInputProps, open } =
    useDropzone({
      accept: DOCUMENT_ACCEPT,
      maxFiles: 1,
      noClick: true,
      onDropAccepted: (acceptedFiles: File[]) => {
        setValue('resume', acceptedFiles)
      },

      onDropRejected: (error) => {
        if (error.length > 1) {
          toast.error('Multiple files not allowed!')
          return
        }

        // Just one file
        const { code, message } = error[0].errors[0]

        if (code === 'file-invalid-type') {
          toast.error(message)
          return
        }

        if (code === 'file-too-large') {
          toast.error('File must be 100kb or less')
        }
      },
    })

  const {
    // acceptedFiles: acceptedFilesCoverLetter,
    // isDragActive: isDragActiveCoverLetter,
    // getRootProps: getRootPropsCoverLetter,
    // getInputProps: getInputPropsCoverLetter,
    // open: openCoverLetter,
  } = useDropzone({
    accept: DOCUMENT_ACCEPT,
    maxFiles: 1,
    noClick: true,
    onDropAccepted: (acceptedFiles: File[]) => {
      setValue('coverLetter', acceptedFiles)
    },

    onDropRejected: (error) => {
      if (error.length > 1) {
        toast.error('Multiple files not allowed!')
        return
      }

      // Just one file
      const { code, message } = error[0].errors[0]

      if (code === 'file-invalid-type') {
        toast.error(message)
        return
      }

      if (code === 'file-too-large') {
        toast.error('File must be 100kb or less')
      }
    },
  })

  const resumes = watch('resume')
  const coverLetter = watch('coverLetter')
  const phone = watch('phone')

  const IHaveScout4JobAccount = loggedInUser || signUpType === 'existing'

  React.useEffect(() => {
    if (resumes && resumes.length > 0) {
      const formData = new FormData()
      formData.append('file', resumes[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setResumeUrl(data.fileUrl)
        },
        onError: () => {
          setValue('resume', null)
        },
      })
    }
  }, [resumes])

  React.useEffect(() => {
    if (coverLetter && coverLetter.length > 0) {
      const formData = new FormData()
      formData.append('file', coverLetter[0])
      mutationCoverLetterUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setCoverLetterUrl(data.fileUrl)
        },
        onError: () => {
          setValue('coverLetter', null)
        },
      })
    }
  }, [coverLetter])

  type FormValues = {
    location: string
    age: Date | null
    email: string
    firstName: string
    lastName: string
    nyscDate: Date | null
    password: string
    phone: string
    institution: Option
    course: string
    grade: Option
    state: Option
  }

  const isNewUser =
    signUpType === 'new' && !IHaveScout4JobAccount ? true : false

  const mutateEasyApply = useEasyApply()
  const onSubmit = (value: FormValues) => {
    const payload = {
      isNewUser: isNewUser,
      jobId: jobId || '',
      companyId: companyId || '',
      firstName: value.firstName || '',
      lastName: value.lastName || '',
      email: value.email || '',
      phone: value.phone || '',
      dateOfBirth: getFormattedDayMonthYear(value.age) || '',
      nyscCompletionDate: getFormattedDayMonthYear(value.nyscDate) || '',
      currentLocation: value.location || '',
      reason:
        'I am interested in the above role as I am confident I qualify for it',
      coverLetter: coverLetterUrl || '',
      jobResumeUrl: resumeUrl || '',
      password: value?.password || '',
      universityId: value?.institution.id || '',
      courseOfStudy: value?.course || '',
      classOfDegree: value?.grade?.name || '',
      stateOfResidence: value?.state?.name || '',
    }
    if(!resumeUrl){
      toast.error('Please upload your resume')
    }

    if (resumeUrl) {
      mutateEasyApply.mutate(payload)
    }
  }
  return (
    <div className="px-8">
      <div className="">
        {!user && (
          <ul className="flex items-stretch justify-center space-x-8 md:space-y-0">
            {ApplicantStatus.map(
              ({ signUpId, signUpName, signUpDescription }) => {
                const isActive = signUpId === signUpType
                return (
                  <button
                    key={signUpId}
                    onClick={() => handleSelectApplicant(signUpId)}
                    type="button"
                    className={clsx(
                      'flex items-center space-x-4 rounded-md border p-3 pr-6 text-left',
                      isActive && 'border-[#27AE60] bg-[#27AE60]/5'
                    )}
                  >
                    {isActive ? (
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 16 16"
                        fill="none"
                        className="hidden sm:block"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="15"
                          height="15"
                          rx="7.5"
                          stroke="#27AE60"
                        />
                        <rect
                          x="4"
                          y="4"
                          width="8"
                          height="8"
                          rx="4"
                          fill="#27AE60"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hidden sm:block"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="15"
                          height="15"
                          rx="7.5"
                          stroke="#E5E9F2"
                        />
                      </svg>
                    )}
                    <span>
                      <span
                        className={clsx(
                          'capitalized text-sm font-medium',
                          isActive && 'text-[#27AE60]'
                        )}
                      >
                        {signUpName}
                      </span>
                      <span className="block text-xs">{signUpDescription}</span>
                    </span>
                  </button>
                )
              }
            )}
          </ul>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={clsx(
            'grid grid-cols-1 gap-4 sm:grid-cols-2',
            loggedInUser ? 'mt-2' : 'mt-12'
          )}
        >
          {!IHaveScout4JobAccount && (
            <>
              <div className="">
                <InputField
                  label="First Name"
                  registration={{ ...register('firstName') }}
                  hasError={errors.firstName}
                  // errorMessage={errors.firstName?.message}
                  isRequired
                  isDisabled={loggedInUser}
                />
              </div>

              <div className="">
                <InputField
                  label="Last Name"
                  registration={{ ...register('lastName') }}
                  hasError={errors.lastName}
                  // errorMessage={errors.lastName?.message}
                  isRequired
                  isDisabled={loggedInUser}
                />
              </div>
            </>
          )}

          <div className="">
            <InputField
              label="Email Address"
              registration={{ ...register('email') }}
              hasError={errors.email}
              errorMessage={errors.email?.message}
              isRequired
              isDisabled={loggedInUser}
            />
          </div>

          {!IHaveScout4JobAccount && (
            <div className="">
              <InputField
                type={show ? 'text' : 'password'}
                label="Password"
                registration={{ ...register('password') }}
                hasError={errors.password}
                // errorMessage={errors.password?.message}
                handleShowPassword={handleToggle}
                withIcon
                isRequired
              />
            </div>
          )}
          <div>
            <p className="text-sm font-semibold">
              <span className="text-sm text-primary">*</span> State of Residence
            </p>
            <SelectField
              className="mt-2"
              control={control as unknown as Control}
              hasError={errors.state}
              arr={STATE_IN_NIGERIA}
              name="state"
            />
          </div>

          <div className="">
            <InputField
              label="Current Full Address"
              registration={{ ...register('location') }}
              hasError={errors.location}
              errorMessage={errors.location?.message}
              isRequired
            />
          </div>
          <div className="">
            <p className="text-sm font-semibold">
              <span className="text-sm text-primary">*</span> Date of birth
            </p>
            <InputDateField
              name="age"
              className="mt-2"
              control={control as unknown as Control}
              placeholder="Date of birth"
              hasError={errors.age}
              //maxDate={endDate || new Date()}
              isRequired
            />
          </div>
          {!IHaveScout4JobAccount && (
            <div className="">
              <InputFieldPhoneNo
                control={control as unknown as Control}
                defaultValue={phone}
                name="phone"
                label="Phone Number"
                hasError={errors.phone}
                errorMessage={errors.phone?.message}
                isRequired
              />
            </div>
          )}
       
            <div className="">
              <p className="text-sm font-semibold">
                <span className="text-sm text-primary">*</span> Institution Attended
              </p>
              <InputUseInstitutionAutoComplete
                className="mt-2"
                control={control as unknown as Control}
                hasError={errors.institution}
                name="institution"
              />
            </div>
            <div className="">
              <InputField
                label="Course of Study"
                registration={{ ...register('course') }}
                hasError={errors.course}
                errorMessage={errors.course?.message}
                isRequired
              />
            </div>
            <div>
              <p className="text-sm font-semibold">
                <span className="text-sm text-primary">*</span> Grade
              </p>
              <SelectField
                className="mt-2"
                control={control as unknown as Control}
                hasError={errors.grade}
                arr={GRADES_OPTIONS}
                name="grade"
              />
            </div>
          <div className={clsx('',)}>
            <p className="text-sm font-semibold">
              <span className="text-sm text-primary">*</span> NYSC Date of
              Completion
            </p>
            <InputDateField
              name="nyscDate"
              className={clsx("mt-2", IHaveScout4JobAccount && 'w-1/2')}
              control={control as unknown as Control}
              placeholder="Date of completion"
              hasError={errors.nyscDate}
              //maxDate={endDate || new Date()}
              isRequired
            />
          </div>
          <div className="">
            <p className="text-sm font-semibold">
              <span className="text-sm text-primary">*</span> Resume
            </p>
            <button
              {...getRootProps({ className: 'dropzone' })}
              className={clsx(
                'padding mt-2 flex h-[171px] w-full flex-col items-center justify-center rounded-xl border-2 border-dashed border-[#E6E5E5] py-6',
                isDragActive && 'border-[#2F80ED] bg-[#F2F8FF]'
              )}
              onClick={open}
              type="button"
            >
              <img
                src="/assets/uploadBig.png"
                alt="jobLogo"
                height={40}
                width={40}
              />
              <div className="mt-6 w-[250px]">
                {acceptedFiles[0] ? (
                  <div className="w-[250px] px-3">
                    <p className="text-[#2F80ED] line-clamp-2">
                      {acceptedFiles[0].name}
                    </p>
                    {mutationFileUpload.isLoading && (
                      <p className="mt-2  text-xs text-gray-400">
                        Uploading...
                      </p>
                    )}
                  </div>
                ) : (
                  <p className="mx-auto w-[200px] text-center text-xs leading-7">
                    <span className="text-[#2F80ED]">Browse</span> or drag &
                    drop to upload your resume as a <br />{' '}
                    <span className="font-[700]">.doc, .docx</span> or{' '}
                    <span className="font-[700]">.pdf file</span>
                  </p>
                )}
              </div>
            </button>
            <input {...getInputProps()} />
          </div>
          {/* <div className="">
            <p className="text-sm font-semibold">
             Cover Letter
            </p>
            <button
              {...getRootPropsCoverLetter({ className: 'dropzone' })}
              className={clsx(
                'padding mt-2 flex h-[171px] w-full flex-col items-center justify-center rounded-xl  border-2 border-dashed border-[#E6E5E5] py-6',
                isDragActiveCoverLetter && 'border-[#2F80ED] bg-[#F2F8FF]'
              )}
              onClick={openCoverLetter}
              type="button"
            >
              <img
                src="/assets/uploadBig.png"
                alt="jobLogo"
                height={40}
                width={40}
              />
              <div className="mt-6 w-[250px]">
                {acceptedFilesCoverLetter[0] ? (
                  <div className="w-[250px] px-3">
                    <p className="text-[#2F80ED] line-clamp-2">
                      {acceptedFilesCoverLetter[0].name}
                    </p>
                    {mutationCoverLetterUpload.isLoading && (
                      <p className="mt-2  text-xs text-gray-400">
                        Uploading...
                      </p>
                    )}
                  </div>
                ) : (
                  <p className="mx-auto w-[200px] text-center text-xs leading-7">
                    <span className="text-[#2F80ED]">Browse</span> or drag &
                    drop to upload your Cover letter as a <br />{' '}
                    <span className="font-[700]">.doc, .docx</span> or{' '}
                    <span className="font-[700]">.pdf file</span>
                  </p>
                )}
              </div>
            </button>
            <input {...getInputPropsCoverLetter()} />
          </div> */}
        </div>
        <Button
          disabled={
            mutationFileUpload.isLoading ||
            mutationCoverLetterUpload.isLoading ||
            mutateEasyApply.isLoading
          }
          type="submit"
          className="mx-auto my-8"
        >
          {mutationCoverLetterUpload.isLoading
            ? 'Uploading Cover letter...'
            : mutationFileUpload.isLoading
            ? 'Uploading Resume...'
            : mutateEasyApply.isLoading
            ? 'Submitting...'
            : 'Submit'}
        </Button>
      </form>
    </div>
  )
}
