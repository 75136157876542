import { useQuery } from '@tanstack/react-query'

import { interviewKeys, fetchData } from '@/utils'
import { NewInterview } from '@/features/job-seeker'

interface JobInterviewResponse {
  status: string
  message: string
  data: NewInterview[]
}

export const useJobInterviews = (jobId: string) => {
  return useQuery<JobInterviewResponse>({
    queryKey: interviewKeys.list(jobId),
    queryFn: async () =>
      fetchData(`/company/applicants/job-interview-schedules/${jobId}`),
  })
}
