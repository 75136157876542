import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { fetchData } from '@/utils';

interface JobTitle {
  name: string;
}

export const useAddJobTitle = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: JobTitle) =>
      fetchData<JobTitle>("/common/add-job-title", 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['job-titles'],
        });
      },
      onError: () => {
        toast.error('Job title not added.');
      },
    }
  );
};