import { useRouter } from 'next/router'
import React from 'react'

import { useResendVerificationCode, useVerificationCode } from '@/features/auth'

export const useVerification = () => {
  const router = useRouter()
  const [value, setValue] = React.useState('')
  const email = router.query.email as string | undefined;

  const mutationResendCode = useResendVerificationCode()
  const mutationVerification = useVerificationCode()

  const isVerificationButtonDisabled = React.useMemo(() => {
    return value.length !== 6 || !email
    }, [value, email]);

  React.useEffect(() => {
    if (value.length === 6 && email) {
      const payload = {
        email,
        verificationToken: value,
      }
      mutationVerification.mutate(payload)
    }
  }, [value])

  const handleResendCode = async () => {
    if (!email) return;
    mutationResendCode.mutate({ email })
  }

  return {
    value,
    setValue,
    handleResendCode,
    mutationResendCode,
    mutationVerification,
    isVerificationButtonDisabled
  }
}
