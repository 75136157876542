
import { BusinessAdsData } from "@/types";
import { businessAdsKeys, fetchData } from "@/utils";
import { useQuery } from "@tanstack/react-query";


interface BusinessAdsResponseProps {
    status: string;
    message: string;
    data: BusinessAdsData[];
}

export const useFetchBusinessAds = () => {
    return useQuery<BusinessAdsResponseProps>({
        queryKey: businessAdsKeys.lists(),
        queryFn: async () => fetchData('/common/display-advert') 
    });
}