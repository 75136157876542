import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { InterviewDetailsModalAnim, backdropVariant } from '@/utils'
import {
  emojis,
  jobSeekerFeedbackSubQuestion3,
  jobSeekerOption1,
  jobSeekerOption2,
  jobSeekerOption3,
  jobSeekerWithColors,
  jobSeekersFeedbackQuestions,
} from '@/data'
import clsx from 'clsx'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import { InputField, RoundCheckbox } from '../Form'
import { Controller, useForm } from 'react-hook-form'
import { Button } from '../Elements'
import { ArrowLeft, ArrowRight } from 'iconsax-react'
import { FeedbackFormProps, useSendFeedback } from '@/features/job-seeker'
import { userIDStorage } from '@/features/auth'
import Image from 'next/image'
// import { AddFeedbackForm } from '@/features/job-seeker'

interface AddFeedbackProp {
  openAddFeedbackModal: boolean
  setOpenAddFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddFeedbackModal: React.FC<AddFeedbackProp> = ({
  openAddFeedbackModal,
  setOpenAddFeedbackModal,
}) => {
  const [active, setActive] = React.useState<null | number>(null)
  const [showFeedBackSent, setShowFeedBackSent] = React.useState(false)
  const [selectedItems1, setSelectedItems1] = React.useState<string[]>()
  const [selectedItems2, setSelectedItems2] = React.useState<string[]>()
  const [selectedOpt1, setSelectedOpt1] = React.useState('')
  const [selectedOpt2, setSelectedOpt2] = React.useState('')
  const [percentage, setPercentage] = React.useState(0)
  const userId = userIDStorage.getUserID()
  const mutateSendFeedback = useSendFeedback()
  const isLoading = mutateSendFeedback.isLoading

  const ref = React.useRef<HTMLInputElement>(null)

  const { register, control, handleSubmit, watch } =
    useForm<FeedbackFormProps>()

  const question3Options = watch('questionThird')

  const hasUndefinedValues =
    question3Options &&
    Object?.values(question3Options).some((value) => value === undefined)

  const firstCondition = selectedOpt1.length > 0
  const secondCondition =
    selectedOpt1.length > 0 && selectedItems1 && selectedItems1?.length > 0
  const thirdCondition =
    selectedOpt1.length > 0 &&
    selectedItems1 &&
    selectedItems1?.length > 0 &&
    question3Options
  const fourthCondition =
    selectedOpt1.length > 0 &&
    selectedItems1 &&
    selectedItems1?.length > 0 &&
    question3Options &&
    selectedOpt2.length > 0
  const fifthCondition =
    selectedOpt1.length > 0 &&
    selectedItems1 &&
    selectedItems1?.length > 0 &&
    question3Options &&
    selectedOpt2.length > 0 &&
    selectedItems2 &&
    selectedItems2?.length > 0

  function progressPercentage() {
    if (firstCondition) {
      setPercentage(20)
    }
    if (secondCondition) {
      setPercentage(40)
    }
    if (thirdCondition) {
      setPercentage(60)
    }
    if (fourthCondition) {
      setPercentage(80)
    }
    if (fifthCondition) {
      setPercentage(100)
    }
  }

  React.useEffect(() => {
    progressPercentage()
  }, [
    selectedOpt1,
    selectedOpt2,
    question3Options,
    selectedItems1,
    selectedItems2,
  ])

  const question3 = question3Options && [
    `resumeUpload~${question3Options[0].name}`,
    `experienceUpload~${question3Options[1].name}`,
    `educationUpload~${question3Options[2].name}`,
    `skillUpload~${question3Options[3].name}`,
  ]

  const onSubmit = (values: FeedbackFormProps) => {
    const payload = {
      userId: userId,
      response: [
        {
          question: jobSeekersFeedbackQuestions[0].question,
          answer: [selectedOpt1] || [],
          comment: values.tellUsMore1,
        },
        {
          question: jobSeekersFeedbackQuestions[1].question,

          answer: selectedItems1 || [],
          comment: values.tellUsMore2,
        },
        {
          question: jobSeekersFeedbackQuestions[2].question,
          answer: question3 || [],
          comment: values.tellUsMore3,
        },
        {
          question: jobSeekersFeedbackQuestions[3].question,
          answer: [selectedOpt2] || [],
          comment: values.tellUsMore4,
        },
        {
          question: jobSeekersFeedbackQuestions[4].question,
          answer: selectedItems2 || [],
          comment: values.tellUsMore5,
        },
      ],
    }

    mutateSendFeedback.mutate(payload, {
      onSuccess: () => {
        setShowFeedBackSent(true)
      },
    })
  }

  const handleClick = (divIndex: number) => {
    if (divIndex === active) {
      // If the clicked div is already active, set it to inactive and hide its content
      setActive(null)
    } else {
      // Set the clicked div as active and toggle its content visibility
      setActive(divIndex)
    }
  }

  const handleCheckboxChange = (
    opt: string,
    setFunction: React.Dispatch<React.SetStateAction<string[] | undefined>>
  ) => {
    setFunction((prevSelectedItems) => {
      if (prevSelectedItems?.includes(opt)) {
        return prevSelectedItems.filter((item) => item !== opt)
      } else {
        return prevSelectedItems ? [...prevSelectedItems, opt] : [opt]
      }
    })
  }

  const handleGoBack = () => {
    if (active == null) {
      setActive(0)
    } else if (active == 0) {
      setActive(0)
    } else if (active > 0) {
      setActive(active - 1)
    }
  }

  const handleNext = () => {
    if (active == null) {
      setActive(0)
    } else {
      setActive(active + 1)
    }
  }

  return (
    <AnimatePresence>
      {openAddFeedbackModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide top-0 right-0 box-border h-screen w-screen overflow-scroll bg-[#EFEFEF]"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={InterviewDetailsModalAnim}
          >
            {/* black meter area */}
            <div className="fixed hidden h-screen w-[340px] min-w-[340px] bg-black lg:block">
              <img
                className="min-w=[178px] mx-auto mt-12 h-[27px] w-[178px]"
                src="/assets/logo-black.png"
                alt="jobLogo"
              />
              <div className="mx-auto mt-[150px] h-[159px] w-[159px]">
                <CircularProgressbarWithChildren
                  value={percentage}
                  strokeWidth={6}
                  styles={buildStyles({
                    textColor: 'black',
                    pathColor: `#27AE60`, //#E2B93B #27AE60
                    strokeLinecap: 'round',
                    pathTransitionDuration: 0.8,
                    trailColor: '#424141',
                  })}
                >
                  <div className="mt-4 flex flex-col items-center">
                    <p className="text-[40px] font-bold text-white">
                      {percentage}%
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="mx-auto mt-16 flex h-[79px] w-[254px] rounded-r-[5px] bg-[#2B2B2B]">
                <div className="-ml-[3px] h-full w-[6px] rounded-full bg-primary"></div>
                <div className="flex w-full flex-col justify-between py-4">
                  <div className="flex w-full items-center justify-between px-6 text-sm">
                    <p className="font-[600] text-primary">Experience</p>
                    <p className="rounded-md bg-primary bg-opacity-10 py-1 px-4 text-xs font-[600] text-primary">
                      4
                    </p>
                  </div>
                  <div className="flex w-full gap-2 px-6">
                    {Array(4)
                      .fill('')
                      .map((elem, index) => {
                        return (
                          <div
                            className={clsx(
                              'h-[6px] min-h-[6px] w-[6px] min-w-[6px] rounded-full transition-all',
                              !(percentage / 20 <= index)
                                ? 'bg-primary'
                                : 'bg-[#4F4F4F]',
                              active == index + 1 && 'w-[16px]'
                            )}
                            key={index}
                          >
                            {elem}
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-8 flex h-[79px] w-[254px] rounded-r-[5px] bg-[#2B2B2B]">
                <div
                  className={clsx(
                    '-ml-[3px] h-full w-[6px] rounded-full',
                    active == 5 ? 'bg-primary' : 'bg-[#2B2B2B]'
                  )}
                ></div>
                <div className="flex w-full flex-col justify-between py-4">
                  <div className="flex w-full items-center justify-between px-6 text-sm">
                    <p
                      className={clsx(
                        'font-[600] text-primary',
                        active == 5 ? 'text-primary' : 'text-white'
                      )}
                    >
                      Additional
                    </p>
                    <p
                      className={clsx(
                        'rounded-md  bg-opacity-10 py-1 px-4 text-xs font-[600] text-primary',
                        active == 5
                          ? 'bg-primary text-primary'
                          : 'bg-white text-white'
                      )}
                    >
                      1
                    </p>
                  </div>
                  <div className="flex w-full gap-2 px-6">
                    <div
                      className={clsx(
                        'h-[6px] min-h-[6px] w-[6px] min-w-[6px] rounded-full transition-all',
                        percentage / 20 == 5 ? 'bg-primary' : 'bg-[#4F4F4F]',
                        active == 5 && 'w-[16px]'
                      )}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            {/* black bar for small screen */}
            <div className="fixed block h-screen w-[5px] bg-black sm:w-[10px] lg:hidden">
              <div
                className="w-[5px] rounded-b-full bg-primary transition-all duration-[1] sm:w-[10px] "
                style={{ height: `${percentage}%` }}
              ></div>
            </div>
            {/* questions area */}
            {showFeedBackSent ? (
              <div className="ml-0 flex h-screen items-center justify-center overflow-scroll bg-[#fafafa] px-12 pb-10 lg:ml-[340px]">
                <button
                  onClick={() => setOpenAddFeedbackModal(false)}
                  className="font-500 absolute top-5 right-5 w-fit rounded bg-red-500 px-4 py-[6px] text-xs text-white"
                >
                  Close
                </button>

                <div className="m-auto mt-[5rem] flex max-w-[450px] flex-col items-center text-center">
                  <Image
                    src="/assets/doneAnimation.gif"
                    height={227}
                    width={226}
                    alt="setUpProfile"
                  />
                  <h2 className="text-[24px]">Feedback sent successfully</h2>
                </div>
              </div>
            ) : (
              <div className="ml-0 h-screen overflow-scroll px-6 sm:px-12 lg:ml-[340px]">
                <div className="mt-10 flex items-center justify-between">
                  <div className="flex gap-2 font-semibold">
                    <p>
                      Tell us your Experience using{' '}
                      <span className="text-primary">Scout4job</span>
                    </p>
                  </div>
                  <button
                    onClick={() => setOpenAddFeedbackModal(false)}
                    className="font-500 w-fit rounded bg-red-500 px-4 py-[6px] text-xs text-white"
                  >
                    Close
                  </button>
                </div>
                <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                  <div className="mt-10 block text-left">
                    <div className="items-start gap-8 md:flex">
                      <button
                        type="button"
                        onClick={() => handleClick(1)}
                        className={clsx(
                          'hidden h-[35px] min-h-[35px] w-[35px] min-w-[35px] items-center justify-center rounded-full font-semibold text-white sm:flex',
                          active == 1
                            ? 'bg-black'
                            : 'bg-[#191919] bg-opacity-20',
                          firstCondition && 'bg-primary'
                        )}
                      >
                        1
                      </button>
                      <div>
                        <button
                          className="text-left"
                          type="button"
                          onClick={() => handleClick(1)}
                        >
                          <p
                            className={clsx(
                              'text-xs font-semibold',
                              active == 1 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            QUESTION {1}/{jobSeekersFeedbackQuestions.length}
                          </p>
                          <p
                            className={clsx(
                              'mt-2 text-[20px] font-bold',
                              active == 1 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            {jobSeekersFeedbackQuestions[0].question}
                          </p>
                        </button>
                        {active == 1 && (
                          <>
                            <div className="mt-4 grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5">
                              {jobSeekerOption1.map((opt, index) => {
                                return (
                                  <button
                                    type="button"
                                    onClick={() => setSelectedOpt1(opt)}
                                    key={index}
                                    className="relative flex h-[135.12px] w-[114.59px] flex-col items-center justify-center gap-2 rounded-md border border-[#E6E5E5] bg-white"
                                  >
                                    <div className="absolute top-3 right-3">
                                      {/* radio input */}
                                      <button
                                        type="button"
                                        className={clsx(
                                          'z-1 relative flex h-4 w-4 min-w-[16px] items-center justify-center rounded-full border-[1.5px] bg-transparent',
                                          selectedOpt1 == opt
                                            ? 'border-primary'
                                            : 'border-[#a6a6a6]'
                                        )}
                                      >
                                        <div
                                          className={clsx(
                                            'z-1 relative h-2 w-2 min-w-[8px] rounded-full',
                                            selectedOpt1 === opt
                                              ? 'bg-primary'
                                              : 'bg-transparent'
                                          )}
                                        />
                                      </button>
                                    </div>
                                    <div>
                                      <img
                                        src={emojis[index]}
                                        alt="angry"
                                        className={clsx(
                                          'mt-4',
                                          selectedOpt1 === opt
                                            ? 'animate-bounce transition'
                                            : '',
                                          index == 3 && '-mb-[6px]'
                                        )}
                                      />
                                      {/* <img
                                    src="/assets/shadow.svg"
                                    className={clsx(
                                      'scale-shadow infinite mx-auto',
                                      selectedOpt1 === opt
                                        ? ''
                                        : ''
                                    )}
                                    alt=""
                                  /> */}
                                    </div>
                                    <p className="text-sm font-[600] text-[#4F4F4F]">
                                      {opt}
                                    </p>
                                  </button>
                                )
                              })}
                            </div>
                            <InputField
                              registration={{ ...register('tellUsMore1') }}
                              hasError={undefined}
                              className="mt-6"
                              placeholder="tell us more"
                            />
                            <div className="mt-6 flex gap-5">
                              <Button
                                type="button"
                                onClick={() => {
                                  if (selectedOpt1) {
                                    handleNext()
                                  }
                                }}
                                endIcon={
                                  <ArrowRight
                                    size="18"
                                    color="#fff"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Next
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* second question */}
                  <div className="mt-10 block text-left">
                    <div className="flex items-start gap-8">
                      <button
                        type="button"
                        onClick={() => {
                          if (selectedOpt1) {
                            handleClick(2)
                          }
                        }}
                        className={clsx(
                          'hidden h-[35px] min-h-[35px] w-[35px] min-w-[35px] items-center justify-center rounded-full text-left font-semibold text-white sm:flex',
                          active == 2
                            ? 'bg-black'
                            : 'bg-[#191919] bg-opacity-20',
                          secondCondition && 'bg-primary'
                        )}
                      >
                        2
                      </button>
                      <div>
                        <button
                          className="text-left"
                          type="button"
                          onClick={() => {
                            if (selectedOpt1) {
                              handleClick(2)
                            }
                          }}
                        >
                          <p
                            className={clsx(
                              'text-xs font-semibold',
                              active == 2 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            QUESTION {2}/{jobSeekersFeedbackQuestions.length}
                          </p>
                          <p
                            className={clsx(
                              'mt-2 text-[20px] font-bold',
                              active == 2 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            {jobSeekersFeedbackQuestions[1].question}
                          </p>
                        </button>
                        {active == 2 && (
                          <div className="mt-4">
                            {jobSeekerOption2.map((opt, index) => {
                              return (
                                <button
                                  type="button"
                                  className="mt-4 flex items-center gap-4 text-left"
                                  onClick={() =>
                                    handleCheckboxChange(opt, setSelectedItems1)
                                  }
                                  key={index}
                                >
                                  <RoundCheckbox
                                    checked={
                                      selectedItems1?.includes(opt) || false
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        opt,
                                        setSelectedItems1
                                      )
                                    }
                                  />
                                  <p className="text-sm" key={index}>
                                    {opt}
                                  </p>
                                </button>
                              )
                            })}
                            <InputField
                              registration={{ ...register('tellUsMore2') }}
                              hasError={undefined}
                              className="mt-6"
                              placeholder="tell us more"
                            />
                            <div className="mt-6 flex gap-5">
                              <Button
                                variant="outline"
                                type="button"
                                onClick={handleGoBack}
                                startIcon={
                                  <ArrowLeft
                                    size="18"
                                    color="#000"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Back
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  if (
                                    selectedItems1 &&
                                    selectedItems1?.length > 0
                                  ) {
                                    handleNext()
                                  }
                                }}
                                endIcon={
                                  <ArrowRight
                                    size="18"
                                    color="#fff"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* question 3 */}
                  <div className="mt-10 block text-left">
                    <div className="flex items-start gap-8">
                      <button
                        className={clsx(
                          'hidden h-[35px] min-h-[35px] w-[35px] min-w-[35px] items-center justify-center rounded-full font-semibold text-white sm:flex',
                          active == 3
                            ? 'bg-black'
                            : 'bg-[#191919] bg-opacity-20',
                          thirdCondition && 'bg-primary'
                        )}
                      >
                        3
                      </button>
                      <div>
                        <button
                          onClick={() => {
                            if (selectedItems1 && selectedItems1?.length > 0) {
                              handleClick(3)
                            }
                          }}
                          className="text-left"
                          type="button"
                        >
                          <p
                            className={clsx(
                              'text-xs font-semibold',
                              active == 3 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            QUESTION {3}/{jobSeekersFeedbackQuestions.length}
                          </p>
                          <p
                            className={clsx(
                              'mt-2 text-[20px] font-bold',
                              active == 3 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            {jobSeekersFeedbackQuestions[2].question}
                          </p>
                        </button>
                        {active == 3 && (
                          <div className="mt-4">
                            {jobSeekerFeedbackSubQuestion3.map(
                              (question, index) => {
                                return (
                                  <div key={index}>
                                    <p className="font mt-6 text-sm font-semibold">
                                      {question}
                                    </p>
                                    <Controller
                                      control={control}
                                      name={`questionThird.${index}.name`}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <div className="flex flex-wrap items-center gap-4">
                                            {jobSeekerWithColors.map(
                                              (opt, index) => {
                                                return (
                                                  <button
                                                    onClick={() =>
                                                      onChange(opt.name)
                                                    }
                                                    type="button"
                                                    className="mt-2 rounded-sm px-4 py-[6px] text-xs"
                                                    style={{
                                                      color:
                                                        value === opt.name
                                                          ? 'white'
                                                          : opt.color,
                                                      background:
                                                        value === opt.name
                                                          ? opt.color
                                                          : opt.bgColor,
                                                      border: `1px solid ${opt.color}`,
                                                    }}
                                                    key={index}
                                                  >
                                                    {opt.name}
                                                  </button>
                                                )
                                              }
                                            )}
                                          </div>
                                        )
                                      }}
                                    />
                                  </div>
                                )
                              }
                            )}
                            <InputField
                              registration={{ ...register('tellUsMore3') }}
                              hasError={undefined}
                              className="mt-6"
                              placeholder="tell us more"
                            />
                            <div className="mt-6 flex gap-5">
                              <Button
                                variant="outline"
                                type="button"
                                onClick={handleGoBack}
                                startIcon={
                                  <ArrowLeft
                                    size="18"
                                    color="#000"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Back
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  if (
                                    !hasUndefinedValues &&
                                    hasUndefinedValues !== undefined
                                  ) {
                                    handleNext()
                                  }
                                }}
                                endIcon={
                                  <ArrowRight
                                    size="18"
                                    color="#fff"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/*  Question 4 */}
                  <div className="mt-10 block text-left">
                    <div className="flex items-start gap-8">
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            !hasUndefinedValues &&
                            hasUndefinedValues !== undefined
                          ) {
                            handleClick(4)
                          }
                        }}
                        className={clsx(
                          'hidden h-[35px] min-h-[35px] w-[35px] min-w-[35px] items-center justify-center rounded-full font-semibold text-white sm:flex',
                          active == 4
                            ? 'bg-black'
                            : 'bg-[#191919] bg-opacity-20',
                          fourthCondition && 'bg-primary'
                        )}
                      >
                        4
                      </button>
                      <div>
                        <button
                          className="text-left"
                          type="button"
                          onClick={() => {
                            if (
                              !hasUndefinedValues &&
                              hasUndefinedValues !== undefined
                            ) {
                              handleClick(4)
                            }
                          }}
                        >
                          <p
                            className={clsx(
                              'text-xs font-semibold',
                              active == 4 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            QUESTION {4}/{jobSeekersFeedbackQuestions.length}
                          </p>
                          <p
                            className={clsx(
                              'mt-2 text-[20px] font-bold',
                              active == 4 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            {jobSeekersFeedbackQuestions[3].question}
                          </p>
                        </button>
                        {/* {active == 4 && (
                        <div className="mt-4">
                          {jobSeekerOption3.map((opt, index) => {
                            return (
                              <p className="mt-2 text-sm" key={index}>
                                {opt}
                              </p>
                            )
                          })}
                        </div>
                      )} */}
                        {active == 4 && (
                          <>
                            <div className="mt-4 grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5">
                              {jobSeekerOption1.map((opt, index) => {
                                return (
                                  <button
                                    type="button"
                                    onClick={() => setSelectedOpt2(opt)}
                                    key={index}
                                    className="relative flex h-[135.12px] w-[114.59px] flex-col items-center justify-center gap-2 rounded-md border border-[#E6E5E5] bg-white"
                                  >
                                    <div className="absolute top-3 right-3">
                                      {/* radio input */}
                                      <button
                                        type="button"
                                        className={clsx(
                                          'z-1 relative flex h-4 w-4 min-w-[16px] items-center justify-center rounded-full border-[1.5px] bg-transparent',
                                          selectedOpt2 == opt
                                            ? 'border-primary'
                                            : 'border-[#a6a6a6]'
                                        )}
                                      >
                                        <div
                                          className={clsx(
                                            'z-1 relative h-2 w-2 min-w-[8px] rounded-full',
                                            selectedOpt2 === opt
                                              ? 'bg-primary'
                                              : 'bg-transparent'
                                          )}
                                        />
                                      </button>
                                    </div>
                                    <img
                                      src={emojis[index]}
                                      alt="angry"
                                      className={clsx(
                                        'mt-4',
                                        selectedOpt2 === opt
                                          ? 'animate-bounce transition'
                                          : ''
                                      )}
                                    />
                                    <p className="text-sm font-[600] text-[#4F4F4F]">
                                      {opt}
                                    </p>
                                  </button>
                                )
                              })}
                            </div>
                            <InputField
                              registration={{ ...register('tellUsMore4') }}
                              hasError={undefined}
                              className="mt-6"
                              placeholder="tell us more"
                            />
                            <div className="mt-6 flex gap-5">
                              <Button
                                variant="outline"
                                type="button"
                                onClick={handleGoBack}
                                startIcon={
                                  <ArrowLeft
                                    size="18"
                                    color="#000"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Back
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  if (selectedOpt2) {
                                    handleNext()
                                  }
                                }}
                                endIcon={
                                  <ArrowRight
                                    size="18"
                                    color="#fff"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Next
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Question 5 */}
                  <div className="mt-10 block text-left">
                    <div className="flex items-start gap-8">
                      <button
                        onClick={() => {
                          if (selectedOpt2) {
                            handleClick(5)
                          }
                        }}
                        type="button"
                        className={clsx(
                          'hidden h-[35px] min-h-[35px] w-[35px] min-w-[35px] items-center justify-center rounded-full font-semibold text-white sm:flex',
                          active == 5
                            ? 'bg-black'
                            : 'bg-[#191919] bg-opacity-20',
                          fifthCondition && 'bg-primary'
                        )}
                      >
                        5
                      </button>
                      <div>
                        <button
                          onClick={() => {
                            if (selectedOpt2) {
                              handleClick(5)
                            }
                          }}
                          type="button"
                          className="text-left"
                        >
                          <p
                            className={clsx(
                              'text-xs font-semibold',
                              active == 5 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            QUESTION {5}/{jobSeekersFeedbackQuestions.length}
                          </p>
                          <p
                            className={clsx(
                              'mt-2 text-[20px] font-bold',
                              active == 5 ? 'text-black' : 'text-[#a5a5a5]'
                            )}
                          >
                            {jobSeekersFeedbackQuestions[4].question}
                          </p>
                        </button>
                        {active == 5 && (
                          <div className="mt-4">
                            {jobSeekerOption3.map((opt, index) => {
                              return (
                                <button
                                  type="button"
                                  className="mt-4 flex items-center gap-4 text-left"
                                  onClick={() =>
                                    handleCheckboxChange(opt, setSelectedItems2)
                                  }
                                  key={index}
                                >
                                  <RoundCheckbox
                                    checked={
                                      selectedItems2?.includes(opt) || false
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        opt,
                                        setSelectedItems2
                                      )
                                    }
                                  />
                                  <p className="text-sm" key={index}>
                                    {opt}
                                  </p>
                                </button>
                              )
                            })}
                            <InputField
                              registration={{ ...register('tellUsMore5') }}
                              hasError={undefined}
                              className="mt-6"
                              placeholder="tell us more"
                            />
                            <div className="mt-6 flex flex-col flex-col-reverse gap-5 sm:flex-row">
                              <Button
                                variant="outline"
                                type="button"
                                onClick={handleGoBack}
                                startIcon={
                                  <ArrowLeft
                                    size="18"
                                    color="#000"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Back
                              </Button>
                              <Button
                                isLoading={isLoading}
                                type="submit"
                                className="whitespace-nowrap text-xs sm:text-sm"
                                endIcon={
                                  <ArrowRight
                                    size="18"
                                    color="#fff"
                                    variant="TwoTone"
                                  />
                                }
                              >
                                Submit Feedback
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <style jsx>{`
                  @keyframes scale {
                    0% {
                      transform: scaleX(1);
                    }
                    100% {
                      transform: scaleX(0.5);
                    }
                  }
                `}</style>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
