import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { AnimatePresence, motion } from 'framer-motion'
// import { ProfileDelete, Trash } from 'iconsax-react'
import { BsXLg } from 'react-icons/bs'
import React from 'react'
import clsx from 'clsx'

import {
  Button,
  ErrorMessage,
  InputField,
  // SelectField,
  StarRatingInput,
  TextAreaField,
} from '@/components'
// import { ApplicantProfileView } from '../ApplicantProfileView'
import { interviewRoundProp } from '@/features/job-seeker'
import { backdropVariant, publicProfile2 } from '@/utils'
// import { overallRecommendation } from '@/data'
import {
  AddInterviewFeedbackForm,
  AddInterviewFeedbackPayload,
  InterviewScorePayload,
} from '../../types'
import { useAddInterviewFeedback } from '../../api'
import { toast } from 'react-toastify'
import { InterviewFeedbackSchema } from '@/utils/validationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { InfoCircle, Trash } from 'iconsax-react'
import { AddSkillHint } from './AddSkillHint'

interface FeedbackFormModalProps {
  //   applicant: JobApplicant | undefined
  openFeedbackFormModal: boolean
  setOpenFeedbackFormModal: (value: boolean) => void
  userId: string
  roundsData: interviewRoundProp
}

export const FeedbackFormModal: React.FC<FeedbackFormModalProps> = ({
  openFeedbackFormModal,
  setOpenFeedbackFormModal,
  // userId,
  roundsData,
}) => {
  const INTERVIEW_CONCLUSION = [
    {
      name: 'A Outstanding',
      value: 'outstanding',
      color: 'rgba(47, 128, 237, 1)',
      bgColor: 'rgba(47, 128, 237, 0.1)',
    },
    {
      name: 'B+ Excellent',
      value: 'excellent',
      color: 'rgba(39, 174, 96, 1)',
      bgColor: 'rgba(39, 174, 96, 0.1)',
    },
    {
      name: 'B good',
      value: 'good',
      color: 'rgba(226, 130, 59, 1)',
      bgColor: 'rgba(226, 130, 59, 0.1)',
    },
    {
      name: 'C Fair',
      value: 'fair',
      color: 'rgba(235, 87, 87, 1)',
      bgColor: 'rgba(235, 87, 87, 0.1)',
    },
  ]

  const { mutate, isLoading } = useAddInterviewFeedback(roundsData.id)
  // const [selectOverall, setSelectOverall] = React.useState('')

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<AddInterviewFeedbackForm>({
    defaultValues: {
      competence: [],
      overallComment: '',
      overallRecommendation: '',
      proKnowledge: [{ name: '', rating: 0 }],
      keySkillSet: [{ name: '', rating: 0 }],
    },
    resolver: yupResolver(InterviewFeedbackSchema),
  })
  const { fields } = useFieldArray({
    control,
    name: 'competence',
  })
  const {
    fields: proKnowledgeFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'proKnowledge',
  })

  const {
    fields: keySkillSetFields,
    append: appendSkillSet,
    remove: removeSkillSet,
  } = useFieldArray({
    control,
    name: 'keySkillSet',
  })

  const overallComment = watch('overallComment')
  const weakness = watch('weakness')
  const pointToClarify = watch('pointsToClarify')

  const transformed = roundsData?.interviewScorecard?.map((score) => {
    return {
      id: score.id,
      name: score.name,
      rating: 0,
      description: score.description,
      comment: '',
    }
  })

  React.useEffect(() => {
    setValue('competence', transformed)
  }, [])

  const onSubmit = (data: AddInterviewFeedbackForm) => {
    if (data.overallRecommendation === '') {
      return toast.error('Please select an overall recommendation')
    }

    const overallRating =
      data.competence.reduce((acc, curr) => {
        return acc + curr.rating
      }, 0) / data.competence.length

    const interviewScore: InterviewScorePayload[] = data.competence.map(
      (score) => {
        return {
          id: score.id,
          name: score.name,
          rating: score.rating,
          description: score.description,
          comment: score.comment,
        }
      }
    )

    const proKnowledgeData = data.proKnowledge.map((item) => {
      return { assessmentItem: item.name, rating: item.rating }
    })

    const keySkillSetData = data.keySkillSet.map((item) => {
      return { assessmentItem: item.name, rating: item.rating }
    })

    const payload: AddInterviewFeedbackPayload = {
      overallRating,
      interviewScore,
      overallComment: data.overallComment,
      overallRecommendation: data.overallRecommendation,
      pointToBeClarified: data.pointsToClarify,
      proceedToNextStep: data.nextStepOption === 'Yes' ? true : false,
      professionalKnowledge: proKnowledgeData,
      skillset: keySkillSetData,
      weaknesses: data.weakness,
    }

    mutate(payload, {
      onSuccess: () => {
        setOpenFeedbackFormModal(false)
        reset({
          overallRating: 0,
          competence: transformed,
          overallComment: '',
          overallRecommendation: '',
          proKnowledge: [{ name: '', rating: 0 }],
          keySkillSet: [{ name: '', rating: 0 }],
        })
      },
    })
  }

  // const SKILL_CONTENTS = [
  //   {
  //     id: '1',
  //     name: 'General concept',
  //   },
  //   {
  //     id: '2',
  //     name: 'Professional Knowledge gathered through practices',
  //   },
  //   {
  //     id: '3',
  //     name: 'Professional Knowledge gathered through independent work',
  //   },
  //   {
  //     id: '4',
  //     name: 'Extensive Professional Knowledge in the domain',
  //   },
  //   {
  //     id: '5',
  //     name: 'Cutting-edge Professional Knowledge in the domain',
  //   },
  // ]

  return (
    <AnimatePresence>
      {openFeedbackFormModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black "
          variants={backdropVariant}
          initial="initial"
          animate="show"
          exit="exit"
        >
          <motion.div
            className="absolute top-0 left-0 z-50 box-border w-full bg-white  text-black"
            variants={publicProfile2}
          >
            <div className="sticky top-0 left-0 z-[60] mx-auto h-[80px] border-b bg-white px-10 py-4 shadow-md">
              <div className="top-0 flex justify-between ">
                <div className="flex w-full items-center justify-between gap-8">
                  <h2 className="text-left text-2xl font-bold">
                    Interview Scoreboard
                  </h2>
                  <div className="ml-auto mr-8 flex items-center justify-between gap-6">
                    <Button
                      disabled={isLoading}
                      isLoading={isLoading}
                      className=" min-w-fit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {isLoading ? 'Submitting...' : 'Submit Feedback'}
                    </Button>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <button
                    type="button"
                    disabled={isLoading}
                    className="rounded-full bg-gray-200 p-2 text-[9px]"
                    onClick={() => setOpenFeedbackFormModal(false)}
                  >
                    <BsXLg />
                  </button>
                </div>
              </div>
            </div>

            {/* First Row */}
            <div className="relative mx-auto flex max-w-[1200px] flex-col items-start justify-between gap-8 rounded-lg lg:flex-row">
              {/* <div className="mt-12 w-[850px] min-w-[650px]">
                <ApplicantProfileView showExperience={false} id={userId} />
              </div> */}

              {/* second row */}
              <div className="scrollbar-hide sideCard  w-full overflow-y-scroll  border-l border-r border-[#E5E5E5] bg-white py-5">
                <div>
                  <div className="flex items-center justify-between">
                    {/* <div className="flex items-center gap-4">
                      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-[#000] bg-opacity-10">
                        <ProfileDelete size="15" color="#000" variant="Bulk" />
                      </div>
                      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-[#f60f0f] bg-opacity-10">
                        <Trash size="15" color="#f60f0f" variant="Bulk" />
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-4 flex items-start gap-4 rounded-md border border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 py-4 px-5">
                    <p className="flex max-h-[18px] min-w-[18px] items-center justify-center rounded-full border-2 border-[#E2B93B] text-[#E2B93B]">
                      i
                    </p>
                    <p className="text-sm text-black">
                      This scorecard is created to help you structure your
                      interview. Use this to submit your feedback after
                      evaluation. <br />
                      Feedback is used internally and will not be shared with
                      the candidates.
                    </p>
                  </div>
                </div>

                <div className=" relative">
                  {/* <button className="hint-button mt-4 ml-auto flex items-center justify-center gap-1 rounded-md bg-primary px-4 py-1">
                    <InfoCircle variant="Linear" size={14} color="#fff" />
                    <p className="text-sm font-[500] text-white">Hint</p>
                  </button>
                  <div className="hint-content absolute top-8 right-0 z-50">
                    <AddSkillHint />
                  </div> */}
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="">
                  <div className="mt-8 border-b bg-primary bg-opacity-10 px-12 pb-6 pt-4">
                    <div className="mt-6 flex items-center">
                      <h3 className="uppercase">Professional Knowledge</h3>
                    </div>

                    <p className="text-sm text-gray-250">
                      Required knowledge in the professional domain (Assess the
                      candidate&apos;s mastery of <br />
                      professional knowledge and write the point you interviewed
                      on in the respective column.
                    </p>
                    {proKnowledgeFields.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className={clsx("mt-1 flex items-center gap-20", index > 0 && 'mt-4 border-t pt-4')}
                        >
                          <div>
                            <InputField
                              registration={{
                                ...register(`proKnowledge.${index}.name`),
                              }}
                              hasError={undefined}
                              className="lg:w-[600px]"
                              placeholder="Assessment Item"
                            />
                            {/* <div className="w-full">
                              <SelectField
                                className="mt-4"
                                placeholder='Level'
                                control={control as unknown as Control}
                                hasError={undefined}
                                arr={SKILL_CONTENTS}
                                name={`proKnowledge.${index}.rating`}
                              />
                            </div> */}
                          </div>
                          {/* <div className="flex items-center justify-center py-6 px-10 ">
                            <Controller
                              control={control}
                              name={`proKnowledge.${index}.rating`}
                              render={({ field: { onChange, value } }) => {
                                const ratingText =
                                  value == 0
                                    ? 'Not Assessed'
                                    : value == 1
                                    ? 'Below Average'
                                    : value == 2
                                    ? 'Average'
                                    : value == 3
                                    ? 'Good'
                                    : value == 4
                                    ? 'Very Good'
                                    : 'Exceptional'

                                const statusColor =
                                  value == 0
                                    ? 'rgba(25, 25, 25, 0.1)'
                                    : value == 5
                                    ? 'rgba(47, 128, 237, 1)'
                                    : value == 4
                                    ? 'rgba(39, 174, 96, 1)'
                                    : value == 3
                                    ? 'rgba(226, 185, 59, 1)'
                                    : value == 2
                                    ? 'rgba(226, 130, 59, 1)'
                                    : 'rgba(235, 87, 87, 1)'
                                return (
                                  <div className="flex items-center gap-4">
                                    <StarRatingInput
                                      activeStar={value}
                                      setActiveStar={onChange}
                                      count={5}
                                    />
                                    <div className="min-w-[130px]">
                                      <p
                                        className={clsx(
                                          'w-fit px-4 py-[5px] text-xs',
                                          value == 0
                                            ? 'text-black'
                                            : 'text-white'
                                        )}
                                        style={{ background: statusColor }}
                                      >
                                        {ratingText}
                                      </p>
                                    </div>
                                  </div>
                                )
                              }}
                            />
                          </div> */}
                          <button
                            type="button"
                            className="-ml-12 flex h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-full bg-[#eb5757] bg-opacity-10"
                            onClick={() => remove(index)}
                          >
                            <Trash variant="Bold" color="#eb5757" size={14} />
                          </button>
                        </div>
                      )
                    })}
                    <button
                      type="button"
                      className="font-600 mt-0 text-sm text-primary underline"
                      onClick={() => append({ name: '', rating: 0 })}
                    >
                      Add Assessment item
                    </button>
                  </div>

                  <div className="border-b bg-[#E2B93B] bg-opacity-10 py-6 px-12 pb-6 pt-4">
                    <div className="mt-6 flex items-center">
                      <h3 className="uppercase">Key SkillSet</h3>
                      <div>
                        <button className="hint-button mx-2" type="button">
                          <InfoCircle
                            variant="Linear"
                            size={14}
                            color="#E2B93B"
                          />
                        </button>
                        <div className="hint-content absolute  z-50">
                          <AddSkillHint />
                        </div>
                      </div>
                    </div>
                    <p className="text-sm text-gray-250">
                      competency requirements meant to ensure work quality
                      (Assess the candidate&apos;s <br />
                      professional skills and write comprehensively in the
                      respective column.)
                    </p>
                    {keySkillSetFields.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className="mt-1 flex items-center gap-20"
                        >
                          <InputField
                            registration={{
                              ...register(`keySkillSet.${index}.name`),
                            }}
                            hasError={undefined}
                            className="lg:w-[600px]"
                            placeholder="Assessment Item"
                          />
                          <div className="flex items-center justify-center py-6 px-10">
                            <Controller
                              control={control}
                              name={`keySkillSet.${index}.rating`}
                              render={({ field: { onChange, value } }) => {
                                const ratingText =
                                  value == 0
                                    ? 'Not Assessed'
                                    : value == 1
                                    ? 'Below Average'
                                    : value == 2
                                    ? 'Average'
                                    : value == 3
                                    ? 'Good'
                                    : value == 4
                                    ? 'Very Good'
                                    : 'Exceptional'

                                const statusColor =
                                  value == 0
                                    ? 'rgba(25, 25, 25, 0.1)'
                                    : value == 5
                                    ? 'rgba(47, 128, 237, 1)'
                                    : value == 4
                                    ? 'rgba(39, 174, 96, 1)'
                                    : value == 3
                                    ? 'rgba(226, 185, 59, 1)'
                                    : value == 2
                                    ? 'rgba(226, 130, 59, 1)'
                                    : 'rgba(235, 87, 87, 1)'
                                return (
                                  <div className="flex items-center gap-4">
                                    <StarRatingInput
                                      activeStar={value}
                                      setActiveStar={onChange}
                                      count={5}
                                    />
                                    <div className="min-w-[130px]">
                                      <p
                                        className={clsx(
                                          'w-fit px-4 py-[5px] text-xs',
                                          value == 0
                                            ? 'text-black'
                                            : 'text-white'
                                        )}
                                        style={{ background: statusColor }}
                                      >
                                        {ratingText}
                                      </p>
                                    </div>
                                  </div>
                                )
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className="-ml-12 flex h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-full bg-[#eb5757] bg-opacity-10"
                            onClick={() => removeSkillSet(index)}
                          >
                            <Trash variant="Bold" color="#eb5757" size={18} />
                          </button>
                        </div>
                      )
                    })}
                    <button
                      type="button"
                      className="font-600 text-sm text-primary underline"
                      onClick={() => appendSkillSet({ name: '', rating: 0 })}
                    >
                      Add Assessment item
                    </button>
                  </div>

                  <div className="px-12">
                    <div className="mt-6 rounded-lg border bg-white px-10 py-4 shadow-md">
                      {fields.map((item, index) => {
                        const comment = watch(`competence.${index}.comment`)
                        return (
                          <div key={item.id} className="mb-6">
                            <button
                              type="button"
                              className="mb-2 flex items-center gap-4 "
                            >
                              <p className="text-left font-bold">{item.name}</p>
                              <Controller
                                control={control}
                                name={`competence.${index}.rating`}
                                render={({ field: { onChange, value } }) => {
                                  const ratingText =
                                    value == 0
                                      ? 'Not Assessed'
                                      : value == 1
                                      ? 'Below Average'
                                      : value == 2
                                      ? 'Average'
                                      : value == 3
                                      ? 'Good'
                                      : value == 4
                                      ? 'Very Good'
                                      : 'Exceptional'

                                  const statusColor =
                                    value == 0
                                      ? 'rgba(25, 25, 25, 0.1)'
                                      : value == 5
                                      ? 'rgba(47, 128, 237, 1)'
                                      : value == 4
                                      ? 'rgba(39, 174, 96, 1)'
                                      : value == 3
                                      ? 'rgba(226, 185, 59, 1)'
                                      : value == 2
                                      ? 'rgba(226, 130, 59, 1)'
                                      : 'rgba(235, 87, 87, 1)'
                                  return (
                                    <>
                                      <StarRatingInput
                                        activeStar={value}
                                        setActiveStar={onChange}
                                        count={5}
                                      />
                                      <div className="min-w-[130px]">
                                        <p
                                          className={clsx(
                                            'px-4 py-[5px] text-xs',
                                            value == 0
                                              ? 'text-black'
                                              : 'text-white'
                                          )}
                                          style={{ background: statusColor }}
                                        >
                                          {ratingText}
                                        </p>
                                      </div>
                                    </>
                                  )
                                }}
                              />
                            </button>

                            <TextAreaField
                              placeholder="Additional Note"
                              hasError={undefined}
                              registration={{
                                ...register(`competence.${index}.comment`),
                              }}
                              value={comment}
                              id={`competence.${index}.comment`}
                              className="resize-none"
                            />
                          </div>
                        )
                      })}
                    </div>
                    <div className="mt-6 rounded-lg border bg-white px-10 py-4 shadow-md">
                      <h4 className="mb-2">Weaknesses:</h4>

                      <TextAreaField
                        placeholder="Weakness"
                        hasError={undefined}
                        value={weakness}
                        registration={{
                          ...register('weakness'),
                        }}
                        id="weakness"
                        className="resize-none"
                      />

                      <hr className="my-4" />

                      <h4 className="mb-2">Points to be Clarified:</h4>

                      <TextAreaField
                        placeholder="Points to be clarified"
                        hasError={undefined}
                        value={pointToClarify}
                        registration={{
                          ...register('pointsToClarify'),
                        }}
                        id="weakness"
                        className="resize-none"
                      />

                      <hr className="my-4" />

                      <h4>Your Overall Comment &amp; Recommendation</h4>
                      <p className="mb-2 text-sm">
                        Your general observation about this candidate and your
                        suggestion on hiring them
                      </p>
                      {/* <div className="my-4 flex items-center gap-2">
                      {overallRecommendation.map((recommendation, index) => {
                        return (
                          <div key={index}>
                            <button
                              onClick={() => {
                                setValue(
                                  'overallRecommendation',
                                  recommendation.name
                                )
                                setSelectOverall(recommendation.name)
                              }}
                              type="button"
                              className={clsx(
                                'bg rounded-md bg-opacity-10 px-4 py-2 text-xs'
                              )}
                              style={
                                recommendation.name === selectOverall
                                  ? {
                                      color: 'white',
                                      backgroundColor: recommendation.color,
                                      border: '1px solid',
                                      borderColor: recommendation.color,
                                    }
                                  : {
                                      backgroundColor: recommendation.bgColor,
                                      color: recommendation.color,
                                      border: '1px solid',
                                      borderColor: recommendation.color,
                                    }
                              }
                            >
                              {recommendation.name}
                            </button>
                          </div>
                        )
                      })}
                    </div> */}

                      <TextAreaField
                        placeholder="Comment and Recommendation"
                        hasError={undefined}
                        value={overallComment}
                        registration={{
                          ...register(`overallComment`),
                        }}
                        id="overallComment"
                        className="resize-none"
                      />
                      <ErrorMessage>
                        {errors.overallComment?.message}
                      </ErrorMessage>

                      <hr className="my-4" />

                      <h4 className="mt-4">Interview Recommendation</h4>
                      <div className="mt-4 mb-4 w-[120px]">
                        {' '}
                        <Controller
                          control={control}
                          name="overallRecommendation"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <div className="flex items-center justify-between gap-4 text-xs">
                                {INTERVIEW_CONCLUSION.map((content, index) => {
                                  return (
                                    <button
                                      type="button"
                                      style={
                                        content.value === value
                                          ? {
                                              color: 'white',
                                              backgroundColor: content.color,
                                              border: '1px solid',
                                              borderColor: content.color,
                                            }
                                          : {
                                              backgroundColor: content.bgColor,
                                              color: content.color,
                                              border: '1px solid',
                                              borderColor: content.color,
                                            }
                                      }
                                      onClick={() => {
                                        onChange(content.value)
                                      }}
                                      key={index}
                                      className={clsx(
                                        'rounded-md border bg-opacity-10 px-4 py-2 capitalize',
                                        value === content.value
                                          ? 'border-0 bg-opacity-100 text-white'
                                          : ''
                                      )}
                                    >
                                      {content.value}
                                    </button>
                                  )
                                })}
                              </div>
                            )
                          }}
                        />
                      </div>

                      <h4 className="mt-8">Proceed to the next Step</h4>
                      <div className="mt-4 mb-8 w-[120px]">
                        {' '}
                        <Controller
                          control={control}
                          name="nextStepOption"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <div className="flex items-center justify-between gap-4 text-xs">
                                {['Yes', 'No'].map((content, index) => {
                                  return (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        onChange(content)
                                      }}
                                      key={index}
                                      className={clsx(
                                        'rounded-md border bg-opacity-10 px-4 py-2',
                                        value === content
                                          ? 'border-0 bg-opacity-100 text-white'
                                          : '',
                                        content.toLowerCase() === 'yes'
                                          ? 'border-primary bg-primary text-primary'
                                          : 'border-red-500 bg-red-500 text-red-500'
                                      )}
                                    >
                                      {content}
                                    </button>
                                  )
                                })}
                              </div>
                            )
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-6 rounded-lg bg-white px-10 py-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                    <h4>Comments</h4>

                    <TextAreaField
                      placeholder="Overall Comment"
                      hasError={undefined}
                      value={overallComment}
                      registration={{
                        ...register('comments'),
                      }}
                      id="comments"
                      className="resize-none"
                    />
                  </div> */}
                </form>
              </div>
              <style jsx>{`
                .sideCard {
                  height: calc(100vh - 0rem);
                }
                .hint-content {
                  display: none;
                }

                .hint-button:hover + .hint-content {
                  display: block;
                }
              `}</style>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

// <div key={scoreCardInfo.id} className="mt-6">
// <Controller
//   control={control}
//   name="dd"
//   render={({ field: { onChange, value } }) => (
//     <StarRatingComponent
//       scoreCardInfo={scoreCardInfo}
//       {...register(
//         scoreCardInfo.nameOfSkill + 'rating'
//       )}
//       {...{ value, onChange }}
//     />
//   )}
// />
// <p>Rating value:{ratingValue}</p>

// <textarea
//   className="mt-4 h-[99px] w-full rounded-md border px-4 py-2"
//   id={scoreCardInfo.nameOfSkill}
//   placeholder="Additional Note"
//   {...register(scoreCardInfo.nameOfSkill)}
// />
// </div>
