// import { AnimatePresence, motion } from 'framer-motion'
// import { useEffect, useRef } from 'react'

// import { backdropVariant, editProfile } from '@/utils'
// import { Interview } from '@/features/job-seeker'
// import { Filter } from '../Filter'

// interface FilterMobileProps {
//   openFilterMobile: boolean
//   setOpenFilterMobile: (value: boolean) => void
//   data?: Interview | undefined
//   option: string
//   setOption: (value: string) => void
//   selectedFilters: string[]
//   active: number[]
//   setActive: React.Dispatch<React.SetStateAction<number[]>>
//   setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>
//   setCurrencyOption: (value: string) => void
//   currencyOption: string
//   jobLength: number
// }

// export const FilterMobile: React.FC<FilterMobileProps> = ({
//   openFilterMobile,
//   setOpenFilterMobile,
//   // option,
//   // setOption,
//   // setSelectedFilters,
//   // setCurrencyOption,
//   // currencyOption,
//   // selectedFilters,
//   // active,
//   // setActive,
// }) => {
//   const ref = useRef<HTMLInputElement>(null)

//   useEffect(() => {
//     const checkIfClickedOutside = (
//       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//       e: React.ChangeEvent<HTMLInputElement> | any
//     ) => {
//       if (openFilterMobile && ref.current && !ref.current.contains(e.target)) {
//         setOpenFilterMobile(false)
//       }
//     }
//     document.addEventListener('mousedown', checkIfClickedOutside)

//     return () => {
//       document.removeEventListener('mousedown', checkIfClickedOutside)
//     }
//   }, [openFilterMobile])

//   return (
//     <AnimatePresence>
//       {openFilterMobile && (
//         <motion.div
//           className="fixed block md:hidden top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
//           initial="initial"
//           animate="show"
//           exit="exit"
//           variants={backdropVariant}
//         >
//           <motion.div
//             className="filterSkin md:hidden scrollbar-hide w-screen absolute top-[50%] h-screen left-[50%] flex-col items-center overflow-scroll  rounded-lg bg-white px-8 py-6"
//             ref={ref}
//             initial="initial"
//             animate="show"
//             exit="exit"
//             variants={editProfile}
//           >
//             <Filter
//               // selectedFilters={selectedFilters}
//               // setSelectedFilters={setSelectedFilters}
//               // setOption={setOption}
//               // option={option}
//               // active={active}
//               // setActive={setActive}
//               // currencyOption={currencyOption}
//               // setCurrencyOption={setCurrencyOption}
//               setOpenFilterMobile={setOpenFilterMobile}  />
//           </motion.div>
//         </motion.div>
//       )}
//       <style>
//         {`
//         .profileBack {
//           background: url(/assets/profileBack.png);
//           background-repeat: no-repeat;
//           background-size: cover;
//            background-position: center;
//         }
//         .filterSkin {
//           style: height: calc(100vh - 156px);
//         }
//         `}
//       </style>
//     </AnimatePresence>
//   )
// }

import React from 'react';

const FilterMobile = () => {
  return (
    <div>
      filter
    </div>
  );
}

export default FilterMobile;
