import { useResumeDetails } from '@/api'
import { Modal } from '@/components'
import React from 'react'
import { BsXLg } from 'react-icons/bs'

interface ConfirmFinalApprovalProps {
  showDialog: boolean
  closeModal: () => void
  resumeId: string
}

export const CvPreviewModal: React.FC<ConfirmFinalApprovalProps> = ({
  showDialog,
  closeModal,
  resumeId,
}) => {
  const resumeDetails = useResumeDetails(resumeId || '')
  const isLoading = resumeDetails?.isLoading
  const handleClose = () => {
    if (!isLoading) {
      closeModal()
    }
  }

  resumeDetails

  return (
    <Modal
      className="absolute bottom-0"
      variant="full"
      showDialog={showDialog}
      closeModal={handleClose}
    >
      <div className="mx-auto flex h-[85vh] w-screen rounded-t-[20px] bg-white px-8">
        <button
          className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
          onClick={closeModal}
        >
          <BsXLg />
        </button>
      </div>
    </Modal>
  )
}
