import { JobDataProps, jobDataScroll1, jobDataScroll2 } from '@/data'
import { Job, useJobs } from '@/features/job-seeker'
import Image from 'next/image'
import React from 'react'
import { BsDot } from 'react-icons/bs'
import { PageSpinner } from './PageSpinner'
import dayjs from 'dayjs'
import { Avatar } from '@mui/material'

export const VerticalInfinite = () => {
  const { data: jobData, isLoading: isLoadingJobs } = useJobs()
  const jobs = jobData?.data || []
  const [firstNineJobs, setFirstNineJobs] = React.useState<JobDataProps[]>([])
  const [lastNineJobs, setLastNineJobs] = React.useState<JobDataProps[]>([])

  const transformJobs = (jobs: Job[]): JobDataProps[] => {
    return jobs.map((job) => {
      const formattedPostedDate = dayjs(job.datePosted).fromNow()
      const formattedValidThrough = dayjs(job.validThrough).toNow(true)
      const locationArr = job.jobLocation?.split(',')
      const state = locationArr && locationArr[locationArr?.length - 2]
      const country = locationArr && locationArr[locationArr?.length - 1]
      const location = `${state} ${country}`
      return {
        isAnonymous: job.isAnonymous,
        applicants: job.applicants,
        title: job.title,
        description: job.summary,
        company: job.companyName,
        location,
        level: job.level,
        jobLogo: job.companyLogo,
        time: formattedPostedDate,
        deadline: formattedValidThrough,
        employmentType: job.employmentType,
        workType: job.workType,
      }
    })
  }


  React.useEffect(() => {
    if (!isLoadingJobs) {
      const firstNineJobs =
        jobs.length > 9 ? transformJobs(jobs.slice(0, 9)) : jobDataScroll1
      const lastNineJobs =
        jobs.length > 9 ? transformJobs(jobs.slice(0, 9)) : jobDataScroll2

      setFirstNineJobs(firstNineJobs)
      setLastNineJobs(lastNineJobs)
    }
  }, [isLoadingJobs])

  return (
    <div className="relative flex max-h-[80vh] gap-5 overflow-hidden">
      {isLoadingJobs ? (
        <div className="mr-32">
          <PageSpinner />
        </div>
      ) : (
        <>
          <div
            className="absolute top-0 z-50 h-full w-full"
            style={{
              background:
                'linear-gradient(0deg, rgba(250,250,250,1) 2%, rgba(255,255,255,0.02591792656587477) 31%, rgba(255,255,255,0.01295896328293733) 79%, rgba(255,255,255,0) 82%, rgba(250,250,250,1) 89%)',
            }}
          />
          <div className="ribbonJobs2 mt-[-150rem] box-border grid h-[50rem] max-w-[276px] grid-cols-1 items-center gap-5">
            {firstNineJobs.map((jobInfo, Index) => (
              <div
                key={Index}
                className="rounded-md bg-white p-3 shadow-lg transition duration-200 hover:shadow-sm sm:p-6"
              >
                <div className="flex items-center gap-6">
                  {jobInfo.isAnonymous ? (
                    <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                      <span className="text-2xl">A</span>
                    </Avatar>
                  ) : (
                    <>
                      {jobInfo.jobLogo ? (
                        <div className="max-h-[50px] max-w-[50px] overflow-hidden">
                          <Image
                            src={jobInfo.jobLogo}
                            alt={`${jobInfo.company} logo`}
                            height={50}
                            width={50}
                          />
                        </div>
                      ) : (
                        <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                          <span className="text-2xl">{jobInfo.company[0]}</span>
                        </Avatar>
                      )}
                    </>
                  )}
                  <div>
                    <h2 className="mt-2 text-left text-xs font-bold">
                      {jobInfo.title}
                    </h2>
                    <p className="flex items-center whitespace-nowrap text-left text-xs text-gray-400">
                      {jobInfo.isAnonymous ? 'Anonymous' : jobInfo.company}
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex flex-wrap gap-2 lowercase">
                  <div className="whitespace-nowrap bg-[#F2F7FF] py-1 px-2 text-[9px]  text-[#575698]">
                    {jobInfo.workType}
                  </div>
                  <div className="whitespace-nowrap bg-[#FCF8F3] py-1 px-2 text-[9px] text-[#ED7200]">
                    {jobInfo.employmentType}
                  </div>
                  <div className="whitespace-nowrap bg-[#D3FFE0] py-1 px-2 text-[9px] text-[#19BE48]">
                    {jobInfo.level}
                  </div>
                </div>
                <p className="mt-4 text-xs line-clamp-5">
                  {jobInfo.description}
                </p>
                <div className="items mt-5 flex items-center gap-1 text-xs">
                  <span className="text-gray-400 ">{jobInfo.time}</span>
                  <div className="text text-gray-400">
                    <BsDot />
                  </div>
                  <span className="text-blue-400">
                    {jobInfo.applicants} Applicants
                  </span>
                </div>
              </div>
            ))}
            {lastNineJobs.map((jobInfo, Index) => (
              <div
                key={Index}
                className="rounded-md bg-white p-3 shadow-lg transition duration-200 hover:shadow-sm sm:p-6"
              >
                <div className="flex items-center gap-6">
                  {jobInfo.isAnonymous ? (
                    <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                      <span className="text-2xl">A</span>
                    </Avatar>
                  ) : (
                    <>
                      {jobInfo.jobLogo ? (
                        <div className="max-h-[50px] max-w-[50px] overflow-hidden">
                          <Image
                            src={jobInfo.jobLogo}
                            alt={`${jobInfo.company} logo`}
                            height={50}
                            width={50}
                          />
                        </div>
                      ) : (
                        <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                          <span className="text-2xl">{jobInfo.company[0]}</span>
                        </Avatar>
                      )}
                    </>
                  )}
                  <div>
                    <h2 className="mt-2 text-left text-xs font-bold">
                      {jobInfo.title}
                    </h2>
                    <p className="flex items-center whitespace-nowrap text-left text-xs text-gray-400">
                      {jobInfo.isAnonymous ? 'Anonymous' : jobInfo.company}
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex flex-wrap gap-2 lowercase">
                  <div className="whitespace-nowrap bg-[#F2F7FF] py-1 px-2 text-[9px]  text-[#575698]">
                    {jobInfo.workType}
                  </div>
                  <div className="whitespace-nowrap bg-[#FCF8F3] py-1 px-2 text-[9px] text-[#ED7200]">
                    {jobInfo.employmentType}
                  </div>
                  <div className="whitespace-nowrap bg-[#D3FFE0] py-1 px-2 text-[9px] text-[#19BE48]">
                    {jobInfo.level}
                  </div>
                </div>
                <p className="mt-4 text-xs line-clamp-5">
                  {jobInfo.description}
                </p>
                <div className="items mt-5 flex items-center gap-1 text-xs">
                  <span className="text-gray-400 ">{jobInfo.time}</span>
                  <div className="text text-gray-400">
                    <BsDot />
                  </div>
                  <span className="text-blue-400">
                    {jobInfo.applicants} Applicants
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div className="ribbonJobs grid  max-w-[276px] grid-cols-1  items-center gap-5">
            {firstNineJobs.map((jobInfo, Index) => (
              <div
                key={Index}
                className="rounded-md bg-white p-3 shadow-lg transition duration-200 hover:shadow-sm sm:p-6"
              >
                <div className="flex items-center gap-6">
                  {jobInfo.isAnonymous ? (
                    <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                      <span className="text-2xl">A</span>
                    </Avatar>
                  ) : (
                    <>
                      {jobInfo.jobLogo ? (
                        <div className="max-h-[50px] max-w-[50px] overflow-hidden">
                          <Image
                            src={jobInfo.jobLogo}
                            alt={`${jobInfo.company} logo`}
                            height={50}
                            width={50}
                          />
                        </div>
                      ) : (
                        <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                          <span className="text-2xl">{jobInfo.company[0]}</span>
                        </Avatar>
                      )}
                    </>
                  )}
                  <div>
                    <h2 className="mt-2 text-left text-xs font-bold">
                      {jobInfo.title}
                    </h2>
                    <p className="flex items-center whitespace-nowrap text-left text-xs text-gray-400">
                      {jobInfo.isAnonymous ? 'Anonymous' : jobInfo.company}
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex flex-wrap gap-2 lowercase">
                  <div className="whitespace-nowrap bg-[#F2F7FF] py-1 px-2 text-[9px]  text-[#575698]">
                    {jobInfo.workType}
                  </div>
                  <div className="whitespace-nowrap bg-[#c3ecf2] py-1 px-2 text-[9px] text-[#20CEE5]">
                    {jobInfo.employmentType}
                  </div>
                  <div className="whitespace-nowrap bg-[#f7d2f5] py-1 px-2 text-[9px] text-[#E520DE]">
                    {jobInfo.level}
                  </div>
                </div>
                <p className="mt-4 text-xs line-clamp-5">
                  {jobInfo.description}
                </p>
                <div className="items mt-5 flex items-center gap-1 text-xs">
                  <span className="text-xs text-gray-400">{jobInfo.time}</span>
                  <div className="text-sm text-gray-400">
                    <BsDot />
                  </div>
                  <span className="text-blue-400">
                    {jobInfo.applicants} Applicants
                  </span>
                </div>
              </div>
            ))}

            {firstNineJobs.map((jobInfo, Index) => (
              <div
                key={Index}
                className="rounded-md bg-white p-3 shadow-lg transition duration-200 hover:shadow-sm sm:p-6"
              >
                <div className="flex items-center gap-6">
                  {jobInfo.isAnonymous ? (
                    <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                      <span className="text-2xl">A</span>
                    </Avatar>
                  ) : (
                    <>
                      {jobInfo.jobLogo ? (
                        <div className="max-h-[50px] max-w-[50px] overflow-hidden">
                          <Image
                            src={jobInfo.jobLogo}
                            alt={`${jobInfo.company} logo`}
                            height={50}
                            width={50}
                          />
                        </div>
                      ) : (
                        <Avatar sx={{ bgcolor: '#000', width: 50, height: 50 }}>
                          <span className="text-2xl">{jobInfo.company[0]}</span>
                        </Avatar>
                      )}
                    </>
                  )}
                  <div>
                    <h2 className="mt-2 text-left text-xs font-bold">
                      {jobInfo.title}
                    </h2>
                    <p className="flex items-center whitespace-nowrap text-left text-xs text-gray-400">
                      {jobInfo.isAnonymous ? 'Anonymous' : jobInfo.company}
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex flex-wrap gap-2 lowercase">
                  <div className="whitespace-nowrap bg-[#F2F7FF] py-1 px-2 text-[9px]  text-[#575698]">
                    {jobInfo.workType}
                  </div>
                  <div className="whitespace-nowrap bg-[#c3ecf2] py-1 px-2 text-[9px] text-[#20CEE5]">
                    {jobInfo.employmentType}
                  </div>
                  <div className="whitespace-nowrap bg-[#f7d2f5] py-1 px-2 text-[9px] text-[#E520DE]">
                    {jobInfo.level}
                  </div>
                </div>
                <p className="mt-4 text-xs line-clamp-5">
                  {jobInfo.description}
                </p>
                <div className="items mt-5 flex items-center gap-1 text-xs">
                  <span className="text-xs text-gray-400">{jobInfo.time}</span>
                  <div className="text-sm text-gray-400">
                    <BsDot />
                  </div>
                  <span className="text-blue-400">
                    {jobInfo.applicants} Applicants
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
