import PlacesAutocomplete from 'react-places-autocomplete'
import Image from 'next/image'
import React from 'react'
import clsx from 'clsx'

interface InputLocationAutoCompleteProps {
  address: string
  handleChange: (address: string) => void
  handleSelect: (address: string) => void
  isError: boolean
}

export const InputLocationAutoComplete: React.FC<InputLocationAutoCompleteProps> = ({
  address,
  handleChange,
  handleSelect,
  isError,
}) => {
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={200}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <div
            className={clsx(
              'mt-4 flex h-12 items-center gap-2 rounded-md border  bg-white px-4',
              !isError ? 'border-gray-150' : 'border-red-500'
            )}
          >
            <input
              {...getInputProps({
                placeholder: 'Tip: Choose a city, state, or country',
                className: 'w-full bg-transparent font-workSans h-12 text-sm outline-none',
                id: 'address',
              })}
            />
            <Image
              src="/assets/globalsearch.png"
              width={20}
              height={20}
              alt="setUpProfile"
            />
          </div>

          <div className="autocomplete-dropdown-container relative text-sm">
            {loading && <div className="mt-1 p-2 border bg-white">Loading...</div>}
            <div className="absolute z-10 mt-1 w-full">
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active p-2 border'
                  : 'suggestion-item p-2 border'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}
