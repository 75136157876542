import { Variants } from 'framer-motion'

export const glassAnimation: Variants = {
  initial: {},
  show: {
    clipPath: [
      'circle(150px at 200px 100px)',
      'circle(150px at 400px 100px)',
      'circle(150px at 400px 200px)',
      'circle(150px at 200px 100px)',
    ],
    type: 'spring',
    transition: {
      duration: 6,
      stiffness: 100,
      repeat: 3,
      repeatType: 'reverse',
      ease: 'easeInOut',
      repeatDelay: 2,
    },
  },
}

export const magnifierAnimation: Variants = {
  // initial: {x: 0, y: 0},
  show: {
    x: ['0px', '200px', '200px', '0px'],
    y: ['0px', '0px', '100px', '0px'],
    type: 'spring',
    transition: {
      duration: 6,
      stiffness: 100,
      repeat: 3,
      repeatType: 'reverse',
      ease: 'easeInOut',
      repeatDelay: 2,
    },
  }
}

export const backdropVariant: Variants = {
  initial: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: { delay: 0.85 },
  },
}

export const InterviewDetailsModalAnim: Variants = {
  initial: {
    x: '100vw',
  },
  show: {
    x: '0vw',
    type: 'spring',
    transition: { delay: 0.1, bounce: 0.2, duration: '0.55' },
  },
  exit: {
    x: '100vw',
    type: 'spring',
    transition: { bounce: 0.2, duration: '0.85' },
  },

}

export const jobDetailsModalVariant: Variants = {
  initial: {
    y: '100vh',
  },
  show: {
    y: '3vh',
    type: 'spring',
    transition: { delay: 0.5, bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '100vh',
    type: 'spring',
    transition: { bounce: 0.2, duration: '0.85' },
  },
}

export const mobileNavbarVariant: Variants = {
  initial: {
    opacity: 0,
    width: 0
  },
  show: {
    x: '0vw',
    type: 'spring',
    opacity: 1,
    width: 400,
    transition: { delay: 0.2, duration: '0.4' },
  },
  exit: {
    opacity: 0,
    type: 'spring',
    width: 0,
    transition: { bounce: 0.2, delay: 0.4, duration: '0.4' },
  },
}

export const containerVariant2 = {
  hidden: { opacity: 0, transition: { when: "afterChildren", } },
  visible: (i = 1) => ({
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 0.04 * i, delay: i * 0.3, when: "beforeChildren" },
  }),
  exit: (i = 1) => ({
    opacity: 0,
    transition: { staggerChildren: 0.12, delayChildren: 0.04 * i, delay: i * 0.3, when: "beforeChildren" },
  }),
}


export const containerChild2 = {
  visible: {
    opacity: 1,
    x: 0,
    y: -10,
    transition: {
      // type: "spring",
      damping: 12,
      stiffness: 100,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    x: 0,
    y: 0,
    transition: {
      // type: "spring",
      damping: 12,
      stiffness: 100,
      when: 'beforeChildren'
    }
  },
  hidden: {
    opacity: 0,
    y: 0,
    transition: {
      damping: 12,
      stiffness: 100,
    },
  }
}


export const publicProfile: Variants = {
  initial: {
    y: '100vh',
  },
  show: {
    y: '0vh',
    type: 'spring',
    transition: { delay: 0.5, bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '100vh',
    type: 'spring',
    transition: { bounce: 0.2, duration: '0.85' },
  },
}
export const publicProfile2: Variants = {
  initial: {
    y: '100vh',
  },
  show: {
    y: '0vh',
    type: 'spring',
    transition: { delay: 0, bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '100vh',
    type: 'spring',
    transition: { bounce: 0.2, duration: '0.85' },
  },
}
export const postAJobModal: Variants = {
  initial: {
    y: '100vh',
  },
  show: {
    y: '0vh',
    type: 'spring',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, },
  },
  exit: {
    y: '100vh',
    type: 'spring',
    transition: { bounce: 0.2, type: 'spring', },
  },
}

export const editPreference = {
  initial: {
    y: '80%',
    x: '-50%',
  },
  show: {
    y: '-50%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '80%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
}
export const editProfile = {
  initial: {
    y: '80%',
    x: '-50%',
  },
  show: {
    y: '-50%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '80%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' }
  },
}

export const reportJob = {
  initial: {
    y: '30%',
    x: '-50%',
  },
  show: {
    y: '-50%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '80%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
}

export const shareJob = {
  initial: {
    y: '30%',
    x: '-50%',
  },
  show: {
    y: '-50%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '80%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
}

export const lookingFor = {
  initial: {
    y: '130%',
    x: '-50%',
  },
  show: {
    y: '-50%',
    x: '-50%',
    transition: { type: 'spring', bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '130%',
    x: '-50%',
    transition: { type: 'spring', bounce: 0.2, duration: '0.85' },
  },
}
export const searchModal = {
  initial: {
    y: '120%',
    x: '-50%',
  },
  show: {
    y: '20%',
    x: '-50%',
    transition: { type: 'spring', bounce: 0.2, duration: '0.55' },
  },
  exit: {
    y: '120%',
    x: '-50%',
    transition: { duration: '0.55' },
  },
}

export const faqModal = {
  initial: {
    x: '60%',
  },
  show: {
    x: '0%',
    transition: {
      type: 'spring',
      ease: 'ease-out',
      bounce: 0.2,
      duration: '0.5',
    },
  },
  exit: {
    x: '60%',
    transition: { type: 'tween', duration: '0.6' },
  },
}

export const VerificationAnim = {
  exit: {
    y: '60%',
    opacity: 0,
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
}

export const ViewJobPostAnim = {
  initial: {
    x: '100%',
  },
  show: {
    x: '0%',
    transition: {
      type: 'spring',
      ease: 'ease-out',
      bounce: 0.2,
      duration: '1.1',
      delay: 0.6
    },
  },
  exit: {
    x: '100%',
    transition: { type: 'tween', duration: '0.8' },
  },
}


export const ViewIntervieweeModalAnim = {
  initial: {
    y: '80%',
    x: '-50%',
  },
  show: {
    y: '-50%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' },
  },
  exit: {
    y: '80%',
    x: '-50%',
    transition: { delay: 0.5, type: 'spring', bounce: 0.2, duration: '0.85' }
  },
}