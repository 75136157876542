import { Adsense } from '@ctrl/react-adsense'
// import { v4 as uuidv4 } from 'uuid';
import React from 'react'


export const GoogleAds = () => {

  return (
    <div className="adsbygoogle my-3 text-center">
      <Adsense
        client="ca-pub-9909105560907775"
        slot="1961649086"
        layout="in-article"
        // format="rectangle"
        responsive="true"
      />
    </div>
  )
}


