import { motion, AnimatePresence, Variants, VariantLabels } from 'framer-motion'
import ReactDOM from 'react-dom'
import React from 'react'
import clsx from 'clsx'

interface ModalProps {
  showDialog: boolean
  closeModal: () => void
  className?: string
  variant?: 'right' | 'middle' | 'full'
  children: React.ReactNode
}

const backdropVariants = {
  visible: { opacity: 1, transition: { duration: 0.4 } },
  hidden: { opacity: 0, transition: { duration: 0.4 } },
}

const animationMiddleVariants: Variants = {
  hidden: {
    y: '100vh',
    opacity: 0,
    transition: { duration: 0.4, type: 'spring', stiffness: 80 },
  },
  visible: {
    y: '0',
    opacity: 8,
    transition: { duration: 0.4, type: 'spring', stiffness: 80 },
  },
}

const animationRightVariants: Variants = {
  hidden: {
    x: '100vh',
    opacity: 0,
    transition: { duration: 0.5 },
  },
  visible: {
    x: '0',
    opacity: 100,
    transition: { duration: 0.5 },
  },
}

export const Modal: React.FC<ModalProps> = ({
  showDialog,
  closeModal,
  variant = 'center',
  className = '',
  children,
}) => {
  const [isBrowser, setIsBrowser] = React.useState(false)

  React.useEffect(() => {
    setIsBrowser(true)
  }, [])


  if (!isBrowser) return null

  let animationVariants: undefined | Variants
  let exitVariants: undefined | VariantLabels

  switch (variant) {
    case 'middle':
    case 'full':
      animationVariants = animationMiddleVariants
      exitVariants = animationMiddleVariants.hidden as VariantLabels
      break
    case 'right':
      animationVariants = animationRightVariants
      exitVariants = animationRightVariants.hidden as VariantLabels
      break
    default:
      break
  }

  return ReactDOM.createPortal(
    <AnimatePresence onExitComplete={closeModal}>
      {showDialog && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit={backdropVariants.hidden}
          className={clsx('fixed inset-0 z-[120] overflow-y-auto bg-black/40')}
        >
          <div
            className="fixed inset-0 overflow-y-auto overflow-x-hidden"
            >
            <motion.div
              className={clsx('', variant === 'middle' && 'mt-20', className)}
              variants={animationVariants}
              exit={exitVariants}
            >
              {children}
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('modal-root') as HTMLDivElement
  )
}
