import { fetchData, transformError } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { CreateJobAlertPayload } from '../types'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'



export const useJobAlert = () => {
//   const queryClient = useQueryClient()
  return useMutation<Response, AxiosError, CreateJobAlertPayload>(
    (payload) => fetchData('/user/apply-job', 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Application submitted successfully')
        // queryClient.invalidateQueries({
        //   queryKey: appliedJobKeys.all,
        // })
      },
      onError: (error) => {
        toast.error(transformError(error))
      },
    }
  )
}
