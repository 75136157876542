import React from 'react'
import InterviewSectionCard from './InterviewSectionCard'
import { useInterviewRounds } from '../api'
import { PageSpinner } from '@/components'

interface InterviewSectionViewProp {
  setOpenFeedbackFormModal: (value: boolean) => void
  openFeedbackFormModal: boolean
  id: string
  interviewId: string
  jobId: string | string[]
}

export const InterviewSectionView = ({
  setOpenFeedbackFormModal,
  id,
  interviewId,
  openFeedbackFormModal,
  jobId,
}: InterviewSectionViewProp) => {
  const userId = id

  

  const { data, isLoading } = useInterviewRounds(userId, jobId)
  const interviewRoundsData = data?.data.interviewRounds

  

  return (
    <div className="px-10 ">
      {isLoading ? (
        <PageSpinner className='mt-28' />
      ) : (
        <>
          {interviewRoundsData?.map((roundsData, index) => {
            const giveAccess = roundsData.interviewDate
            const hasDeclineReason = roundsData.reasonForDecline.trim().length > 0 
            const hasDeclined = roundsData.hasAccepted === false && hasDeclineReason
            const pending = roundsData.hasAccepted === false && !hasDeclineReason

        
            
            return (
              <InterviewSectionCard
              hasDeclined={hasDeclined}
              pending={pending}
                setOpenFeedbackFormModal={setOpenFeedbackFormModal}
                openFeedbackFormModal={openFeedbackFormModal}
                key={index}
                roundsData={roundsData}
                interviewId={interviewId}
                userId={userId}
                giveAccess={giveAccess}
              />
            )
          })}
        </>
      )}
    </div>
  )
}
