import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { companyIDStorage, userIDStorage } from '@/features/auth'
import { companyKeys, companyLogoKeys, fetchData, transformError } from '@/utils'
import { OnboardingCreateCompanyProfilePayload } from '../types'
import { AxiosError } from 'axios'

interface CompanyProfileResponse {
  message: string
  data: {
    companyId: string
  }
}

export const useOnboardingCreateCompanyProfile = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  const userID = userIDStorage.getUserID()
  if (!userID) router.push('/login')

  return useMutation<
    CompanyProfileResponse,
    AxiosError,
    OnboardingCreateCompanyProfilePayload
  >(
    (payload: OnboardingCreateCompanyProfilePayload) =>
      fetchData(`/company/create/${userID}`, 'POST', payload),
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries({
          queryKey: companyKeys.all,
        })
        queryClient.invalidateQueries({
          queryKey: companyLogoKeys.all,
        })
        companyIDStorage.setCompanyID(data.companyId)
        toast.success('Company profile created.')
        router.push('/recruiter/onboarding/2')
      },
      onError: (error: AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
