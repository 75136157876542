import { AnimatePresence, motion } from 'framer-motion'
import { Control, useForm } from 'react-hook-form'
import { BsXLg } from 'react-icons/bs'
import React from 'react'

import { backdropVariant, lookingFor, WORKSPACE_OPTIONS } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { CompanyEditPreferencePayload, CompanyPreferenceForm, useCompanyEditPreference } from '@/features/recruiter'
import { CompanyEditPreference } from '@/utils/validationSchema'
import { Button, InputLocationAutoComplete, Label, SelectField } from '@/components'

interface CompanyEditPreferenceProps {
  companyEditPreference: boolean
  setCompanyEditPreference: (value: boolean) => void
}

export const CompanyEditPreferenceModal: React.FC<
  CompanyEditPreferenceProps
> = ({ companyEditPreference, setCompanyEditPreference }) => {
  const {
    watch,
    formState: { errors },
    setValue,
    control,
    reset,
    handleSubmit,
  } = useForm<CompanyPreferenceForm>({
    defaultValues: {
      companyLocation: '',
      remotePolicy: '',
      remoteLocation: '',
    },
    resolver: yupResolver(CompanyEditPreference),
  })

  const { mutate, isLoading } = useCompanyEditPreference()
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        companyEditPreference &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setCompanyEditPreference(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [companyEditPreference])

  const onSubmit = (data: CompanyPreferenceForm) => {
    const { remotePolicy, companyLocation, remoteLocation } = data

    const payload: CompanyEditPreferencePayload = {
      remotePolicy,
      companyLocation,
      remoteLocation,
    }
    mutate(payload, {
      onSuccess: () => {
        reset()
        setCompanyEditPreference(false)
      },
    })
  }
  const companyLocation = watch('companyLocation')
  const remoteLocation = watch('remoteLocation')

  const handleCompanyLocationChange = (companyLocation: string) => {
    setValue('companyLocation', companyLocation)
  }

  const handleRemoteLocationChange = (remoteLocation: string) => {
    setValue('remoteLocation', remoteLocation)
  }

  return (
    <AnimatePresence>
      {companyEditPreference && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  absolute top-[50%] left-[50%]  w-[524px] overflow-scroll  rounded-lg bg-white px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={lookingFor}
          >
            <div>
              <div>
                <p className="text-base font-bold">About your company</p>
                <button
                  type="button"
                  className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
                  onClick={() => setCompanyEditPreference(false)}
                >
                  <BsXLg />
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className=" mt-4 flex w-fit items-center gap-3 rounded-md border-2 border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 px-3 py-2 text-black ">
                <p className="text-xs ">
                  We push your jobs to applicants that match your office
                  preferences
                </p>
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">
                  <span className="text-sm text-primary">*</span>Prefered
                  Location
                </p>
                <InputLocationAutoComplete
                  isError={!!errors.companyLocation}
                  address={companyLocation}
                  handleChange={handleCompanyLocationChange}
                  handleSelect={handleCompanyLocationChange}
                />
              </div>
              <div className="flex-1">
                <Label isRequired>Remote Policy</Label>
                <SelectField
                  className="mt-2"
                  control={control as unknown as Control}
                  hasError={errors.remotePolicy}
                  arr={WORKSPACE_OPTIONS}
                  name="remotePolicy"
                />
              </div>
              <div>
                <p className="mt-6 text-sm font-bold">
                  Hiring region for remote workers
                </p>
                <InputLocationAutoComplete
                  isError={!!errors.remoteLocation}
                  address={remoteLocation}
                  handleChange={handleRemoteLocationChange}
                  handleSelect={handleRemoteLocationChange}
                />
              </div>
              <div className="mt-8">
                <Button>{isLoading ? 'Saving...' : 'Save'}</Button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
