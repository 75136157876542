import { Sms, UsdCoin } from 'iconsax-react'

export const ActivityLog = () => {
  const activityData = [
    {
      activityTitle: 'Offer Accepted',
      activityDescription: 'Candidate has accepted your offer',
      date: '29th Mar, 2019',
      time: '10:00 am',
    },
    {
      activityTitle: 'Offer Sent',
      activityDescription: 'Candidate has received your offer request',
      date: '29th Mar, 2019',
      time: '9:00 am',
    },
    {
      activityTitle: 'Interview Rounds Completed',
      activityDescription:
        'Candidate has passed through all the interview rounds Waiting for a managerial feedback on hire.',
      date: '28th Mar, 2019',
      time: '10:00 am',
    },
    {
      activityTitle: 'Offer Accepted',
      activityDescription: 'Candidate has accepted your offer',
      date: '29th Mar, 2019',
      time: '10:00 am',
    },
  ]

  return (
    <div className="">
      <div className="grid grid-cols-4 rounded-xl bg-black px-8 py-7 text-white">
        <div className="text-left">
          <p className="text-sm font-semibold">Experience Level</p>
          <p className="mt-2 text-sm font-bold">Mid Level(2 - 5 years)</p>
        </div>
        <div className="text-left">
          <p className="text-sm font-semibold">Agreed Pay</p>
          <div className="mt-2 flex items-center gap-3">
            <UsdCoin size="21" color="#E2B93B" variant="Bulk" />
            <p className=" text-sm font-bold">750k/Month</p>
          </div>
        </div>
        <div className="text-left">
          <p className="text-sm font-semibold">Employment Type</p>
          <p className="mt-2 text-sm font-bold">Full Time</p>
        </div>
        <div className="text-left">
          <p className="text-sm font-semibold">Interview Rounds</p>
          <p className="mt-2 text-sm font-bold"></p>
        </div>
      </div>

      {/* Activity */}

      {activityData.map((data, index) => {
        return (
          <div key={index}>
            {/* date and time */}

            <div className="flex items-start mt-10 gap-8">
              <div className='w-[150px]'>
                <p className="text-xs font-bold">{data.date}</p>
                <p className="mt-1 text-xs font-normal">{data.time}</p>
              </div>
              <div>
                <p className='bg-[#27AE60] p-2 rounded-full bg-opacity-20'>
                  <Sms size="15" color="#27AE60" variant="Bulk" />
                </p>
              </div>
              <div className='ml-4'>
                <p className="text-sm font-bold">{data.activityTitle}</p>
                <p className="mt-1 text-xs font-normal">
                  {data.activityDescription}
                </p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ActivityLog
