import { AllCompanyList } from "@/types";
import { companyKeys, fetchData } from "@/utils";
import { useQuery } from "@tanstack/react-query";


interface CompanyListResponse {
    status: string;
    message: string;
    data: AllCompanyList[];
}

export const useAllCompanies = () => {
    return useQuery<CompanyListResponse>({
        queryKey: companyKeys.lists(),
        queryFn: async () => fetchData('/common/companies') 
    });
}