import React from 'react'
import { PageSpinner } from './PageSpinner'
import { Avatar } from '@mui/material'
import Image from 'next/image'
import { addCommaToNumber, checkIfDateIsAfter } from '@/utils'
import { CgAsterisk } from 'react-icons/cg'
import dynamic from 'next/dynamic'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import {
  ApplyJobForm,
  useApplyToAJob,
  // useCheckSavedJobsStatus,
  useHasApplied,
  useJob,
  // usePostSavedJobs,
  // useUnSaveJob,
} from '@/features/job-seeker'
import { DOCUMENT_ACCEPT } from '@/features/onboarding'
import clsx from 'clsx'
import { useAuth } from '@/contexts/Authentication'
import { useRouter } from 'next/router'
import { useFileUpload } from '@/api'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Tooltip } from '@mui/material'
// import { FiLink2 } from 'react-icons/fi'
import dayjs from 'dayjs'

dayjs.extend(relativeTime)
import { convertToHTML } from 'draft-convert'
import { EditorState } from 'draft-js'
import { BsDot } from 'react-icons/bs'
import { Button } from './Elements'
import { LoginPopUp, ReportJob, ShareJob } from './Modals'
import Link from 'next/link'
import { Dislike, Share } from 'iconsax-react'
import { useUserInfo } from '@/hooks'
import ViewCompanyProfileJobSeeker from './Modals/ViewCompanyProfileJobSeeker'
// import { useQueryClient } from '@tanstack/react-query'
// import Link from 'next/link'

const Editor = dynamic(
  () => import('react-draft-wysiwyg').then((mod) => mod.Editor),
  {
    ssr: false,
  }
)

interface JobProps {
  jobId: string
  setOpenReportJob: (value: boolean) => void
  openReportJob: boolean
  setOpenShareJob: (value: boolean) => void
  openShareJob: boolean
  openCompanyProfile: boolean
  setOpenCompanyProfile: (value: boolean) => void
  modalOpen: boolean
}

export const InternalJobs: React.FC<JobProps> = ({
  jobId,
  setOpenReportJob,
  openReportJob,
  setOpenShareJob,
  openShareJob,
  openCompanyProfile,
  setOpenCompanyProfile,
  modalOpen,
}) => {
  const router = useRouter()
  const { data: appliedData } = useHasApplied(jobId)
  const isApplied = appliedData?.data.hasApplied
  const applicationStatus = appliedData?.data.applicationStatus
  const { authState } = useAuth()
  const [openLoginPopUp, setOpenLoginPopUp] = React.useState(false)
  const [openLoginPopUpCompanyProfile, setOpenLoginPopUpCompanyProfile] =
    React.useState(false)

  modalOpen

  // const mutateJobSaveStatus = useCheckSavedJobsStatus(jobId)
  // const isSaved = mutateJobSaveStatus?.data?.data?.isSaved

  // const mutatePostSavedJobs = usePostSavedJobs()
  // const mutateUnSaveJob = useUnSaveJob()

  const [resumeUrl, setResumeUrl] = React.useState<string | null>(null)
  const { reset, watch, setValue, handleSubmit } = useForm<ApplyJobForm>({
    defaultValues: {
      resume: null,
    },
  })

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )
  const [convertedContent, setConvertedContent] = React.useState('')
  // const [saveState, setSaveState] = React.useState<boolean | undefined>(isSaved)

  const { data, isLoading } = useJob(jobId)
  const mutationFileUpload = useFileUpload()
  const mutationApplyToAJob = useApplyToAJob()

  const {
    companyId,
    companyName,
    companyLocation,
    companyLogo,
    title,
    applicants,
    availableOpenings,
    datePosted,
    description,
    jobLocation,
    // employeeCount,
    employmentType,
    level,
    summary,
    validThrough,
    experience,
    minQualification,
    salary,
    workType,
    isAnonymous,
  } = data?.data || {}

  const { currency, min, max, term, hidden } = salary || {}
  const formattedDeadline = dayjs(validThrough).fromNow()

  const handleOpenReportModal = () => {
    if (authState.isAuthenticated === false) {
      setOpenLoginPopUp(true)
    } else {
      setOpenReportJob(true)
    }
  }

  const handleOpenShareModal = () => setOpenShareJob(true)

  const handleOpenCompanyProfile = () => {
    if (authState.isAuthenticated === false) {
      setOpenLoginPopUpCompanyProfile(true)
    } else {
      setOpenCompanyProfile(true)
    }
  }

  const isApplicationClosed = checkIfDateIsAfter(validThrough)
  authState.isAuthenticated
  const formattedPostedDate = dayjs(datePosted).fromNow()

  const locationArr = companyLocation?.split(',') || []
  const state = locationArr[locationArr.length - 2]
  const country = locationArr[locationArr.length - 1]

  const { user } = useUserInfo()

  const { resumeUrl: activeResumeUrl } = user || {}

  const { acceptedFiles, isDragActive, getRootProps, getInputProps, open } =
    useDropzone({
      accept: DOCUMENT_ACCEPT,
      maxFiles: 1,
      noClick: true,
      onDropAccepted: (acceptedFiles: File[]) => {
        setValue('resume', acceptedFiles)
      },

      onDropRejected: (error) => {
        if (error.length > 1) {
          toast.error('Multiple files not allowed!')
          return
        }

        // Just one file
        const { code, message } = error[0].errors[0]

        if (code === 'file-invalid-type') {
          toast.error(message)
          return
        }

        if (code === 'file-too-large') {
          toast.error('File must be 100kb or less')
        }
      },
    })

  const resumes = watch('resume')

  React.useEffect(() => {
    if (resumes && resumes.length > 0) {
      const formData = new FormData()
      formData.append('file', resumes[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data }) => {
          setResumeUrl(data.fileUrl)
        },
        onError: () => {
          setValue('resume', null)
        },
      })
    }
  }, [resumes])

  // React.useEffect(() => {
  //   const checkIfClickedOutside = (
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     e: React.ChangeEvent<HTMLInputElement> | any
  //   ) => {
  //     if (modalOpen && ref.current && !ref.current.contains(e.target)) {
  //       setModalOpen(false)
  //     }
  //   }
  //   document.addEventListener('mousedown', checkIfClickedOutside)

  //   return () => {
  //     document.removeEventListener('mousedown', checkIfClickedOutside)
  //   }
  // }, [modalOpen])

  React.useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent())
    setConvertedContent(html)
  }, [editorState])

  const onSubmit = () => {
    if (convertedContent === '<p></p>') {
      return toast.error('Tell us why you are a good fit for the job')
    }

    if (mutationFileUpload.isLoading) {
      return toast.error('Please wait while your resume is uploading!')
    }

    const payload = {
      companyId: companyId as string,
      jobId,
      reason: convertedContent,
      jobResumeUrl: resumeUrl || '',
    }
    if (authState.isAuthenticated === false) {
      router.push('/login')
    } else {
      mutationApplyToAJob.mutate(payload, {
        onSuccess: () => {
          reset()
          setEditorState(EditorState.createEmpty())
        },
      })
    }
  }

  // const queryClient = useQueryClient()

  const applicationStatusText =
    String(applicationStatus).toLowerCase() === 'applied'
      ? 'review'
      : applicationStatus

  const postedDate = dayjs(datePosted)
  const now = dayjs()
  const differenceInHours = now.diff(postedDate, 'hour')
  const showNumberOfApplicant =
    differenceInHours < 12 ||
    (applicants && differenceInHours > 12 && applicants > 0)
      ? true
      : false

  // const handleSaveJobs = (payload: { jobId: string }) => {
  //   if (authState.isAuthenticated === false) {
  //     router.push('/login')
  //   }
  //   const handleError = () => {
  //     setSaveState(false)
  //   }
  //   const handleSuccess = () => {
  //     setSaveState(true)
  //     queryClient.invalidateQueries(savedJobsStatus.detail(jobId))
  //   }
  //   mutatePostSavedJobs.mutate(payload, {
  //     onError: handleError,
  //     onSuccess: handleSuccess,
  //   })
  // }

  // const handleUnSaveJobs = (payload: string) => {
  //   if (authState.isAuthenticated === false) {
  //     router.push('/login')
  //   }
  //   const handleError = () => {
  //     setSaveState(true)
  //   }
  //   const handleSuccess = () => {
  //     setSaveState(false)
  //     queryClient.invalidateQueries(savedJobsStatus.detail(jobId))
  //   }
  //   mutateUnSaveJob.mutate(payload, {
  //     onError: handleError,
  //     onSuccess: handleSuccess,
  //   })
  // }

  return (
    <div>
      {isLoading ? (
        <PageSpinner className="mt-16" />
      ) : (
        <div className="top-0 left-0 mx-auto flex max-w-[1500px] flex-col items-start justify-between gap-10 xl:flex-row ">
          <div className="lg:flex-1">
            <div className="border bg-white  p-12 shadow-lg">
              <div className="flex flex-col justify-start gap-10 rounded-md transition duration-200 md:flex-row md:justify-between md:gap-0 ">
                <div className="flex h-fit items-center gap-6">
                  <div>
                    {isAnonymous ? (
                      <Avatar sx={{ bgcolor: '#000', width: 60, height: 60 }}>
                        <span className="text-2xl">A</span>
                      </Avatar>
                    ) : (
                      <>
                        {companyLogo ? (
                          <Image
                            src={companyLogo}
                            alt={`${companyName} logo`}
                            height={60}
                            width={60}
                          />
                        ) : (
                          <Avatar
                            sx={{ bgcolor: '#000', width: 60, height: 60 }}
                          >
                            <span className="text-2xl">
                              {companyName && companyName[0]}
                            </span>
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <div className="flex items-start justify-between">
                      <div>
                        <h2 className="text-left text-sm font-bold sm:text-base">
                          {title}
                        </h2>
                        <p className="flex items-center text-left text-xs text-gray-400 sm:text-sm">
                          {isAnonymous ? (
                            'Anonymous'
                          ) : (
                            <button
                              type="button"
                              onClick={handleOpenCompanyProfile}
                              className="hover:underline"
                            >
                              {companyName}{' '}
                            </button>
                          )}
                          <BsDot className="text-left text-lg text-gray-400" />
                          {state}
                          {state && country && ','} {country}
                        </p>
                      </div>
                      <div>
                        {/* <div className="">
                          {saveState ? (
                            <button onClick={() => handleUnSaveJobs(jobId)}>
                              <Save2
                                size="20"
                                color="#2F80ED"
                                variant="Bold"
                                className=""
                              />
                            </button>
                          ) : (
                            <button
                              onClick={() => handleSaveJobs({ jobId: jobId })}
                            >
                              <Save2
                                size="20"
                                color="#2F80ED"
                                variant="Outline"
                              />
                            </button>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className="items mt-2 flex flex-wrap items-center gap-1 whitespace-nowrap text-[10px]">
                      <span className="text-xs text-gray-400">
                        Posted {formattedPostedDate}
                      </span>
                      <div className="text-base text-gray-400">
                        <BsDot />
                      </div>
                      <span className="text-xs text-[#EB5757]">
                        {isApplicationClosed ? (
                          'Application Closed'
                        ) : (
                          <p>Closing {formattedDeadline}</p>
                        )}
                      </span>
                      <div className="text-base text-gray-400">
                        <BsDot />
                      </div>
                      <span className="text-xs text-gray-400">
                        {availableOpenings}{' '}
                        {availableOpenings && Number(availableOpenings) > 1
                          ? 'Openings'
                          : 'Opening'}
                      </span>
                      {showNumberOfApplicant && (
                        <>
                          <div className="text-xs text-gray-400">
                            <BsDot />
                          </div>
                          <span className="text-blue-400">
                            {applicants} Applicant
                            {applicants && applicants > 1 ? 's' : ''}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between">
                  <div className="flex items-center justify-between space-x-2">
                    <button
                      type="button"
                      className="flex items-center gap-1 rounded-lg bg-[#16BF44] py-1 px-2 text-sm text-white"
                      onClick={handleOpenShareModal}
                    >
                      <Share size="18" color="#ffff" />
                      Share
                    </button>
                    <button
                      type="button"
                      onClick={handleOpenReportModal}
                      className="flex items-center gap-1 rounded-lg border border-[#FF8A65] py-1 px-2 text-sm text-[#FF8A65]"
                    >
                      <Dislike size="18" color="#FF8A65" />
                      Report
                    </button>
                  </div>
                </div>
              </div>

              <div className=" mt-10 grid gap-4 rounded-lg border border-[#E6E5E5] py-6 px-4  md:grid-cols-3 md:flex-row">
                <div className="border-r border-[#E6E5E5] px-2">
                  <p className="whitespace-nowrap  text-xs ">Job Location</p>
                  <Tooltip title={jobLocation}>
                    <p className=" mt-1 cursor-pointer truncate whitespace-nowrap text-sm font-medium">
                      {jobLocation}
                    </p>
                  </Tooltip>
                  <hr className="my-2" />
                  <p className="whitespace-nowrap  text-xs ">
                    Minimum Qualification
                  </p>
                  <p className=" mt-1  whitespace-nowrap  text-sm font-medium ">
                    {minQualification}
                  </p>
                </div>
                <div className="border-r border-[#E6E5E5] px-2">
                  <p className="whitespace-nowrap text-xs">Experience Level</p>
                  <p className=" mt-1 whitespace-nowrap text-sm  font-medium line-clamp-1">
                    {level}
                  </p>
                  <hr className="my-2" />
                  <p className="whitespace-nowrap  text-xs ">
                    Experience Length
                  </p>
                  <p className=" mt-1  whitespace-nowrap  text-sm font-medium ">
                    {experience}{' '}
                  </p>
                </div>
                <div className=" px-2">
                  <p className="whitespace-nowrap  text-xs ">Employment Type</p>
                  <p className=" mt-1  whitespace-nowrap text-sm font-medium capitalize">
                    {employmentType} ({workType})
                  </p>
                  <hr className="my-2" />
                  <p className="whitespace-nowrap  text-xs ">Salary</p>
                  {hidden ? (
                    <p className="mt-1 whitespace-nowrap text-sm font-medium capitalize">
                      Confidential
                    </p>
                  ) : (
                    <>
                      <p className=" mt-1  whitespace-nowrap text-sm font-medium line-clamp-1 ">
                        {currency}
                        {addCommaToNumber(min || 0)}
                        {(max || 0) > 0 && (
                          <span>
                            {' - '}
                            {currency}
                            {addCommaToNumber(max || 0)}{' '}
                          </span>
                        )}{' '}
                        / <span>{term}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="mt-5 border-b p-2 text-sm font-light">
                <h5 className="text-[16px] font-normal">Job Summary</h5>
                <p className="mb-4 mt-2 leading-6 ">{summary}</p>
              </div>
              {/* TODO: SANITIZE HTML */}
              <h5 className="pt-6 text-[16px] font-normal">
                Job Description/Requirements
              </h5>
              <div
                className="job-description mt-2 text-base"
                dangerouslySetInnerHTML={{ __html: description || '' }}
              ></div>
            </div>
          </div>

          <div className="rounded-lg bg-white shadow-lg">
            {authState.isAuthenticated === false ? (
              <div className="flex w-full items-center justify-center py-16 text-center text-sm  lg:h-[700px] lg:w-[400px] lg:py-0">
                <div className="text-center">
                  <p className="mt-7 mb-2 text-center font-light text-gray-400">
                    You have to be logged in to apply for this job.
                  </p>
                  <div className="mx-auto">
                    <Button type="submit" variant="primary">
                      <Link href="/login">Apply for this Job</Link>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              (!isApplied || isApplicationClosed) && (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-full rounded-lg py-12 px-2 md:w-[500px] md:border md:border-[#E6E5E5] md:px-8"
                >
                  <h3 className="text-base">Application</h3>
                  <p className="mt-2 rounded border border-[#E2B93B] bg-[#E2B93B] bg-opacity-10 px-4 py-1 text-xs text-[#E2B93B]">
                    Having a completed profile improves your chances as an
                    applicant
                  </p>
                  <div className="mt-6">
                    <div className="flex gap-2">
                      <CgAsterisk className="text-red-600" />
                      <p className="text-xs">
                        Let us know why you are a good fit
                      </p>
                    </div>

                    <Editor
                      defaultEditorState={editorState}
                      onEditorStateChange={setEditorState}
                      wrapperClassName="wrapper-class mt-4 rounded-md border border-[#ccc] focus-within:border-secondary disabled:bg-gray-100"
                      editorClassName="editor-class px-5 -mt-3 min-h-[150px]"
                      toolbarClassName="toolbar-class flex"
                      toolbar={{
                        options: ['inline', 'list'],
                        inline: {
                          options: ['bold', 'italic', 'underline'],
                        },
                        list: { options: ['unordered', 'ordered'] },
                      }}
                    />
                  </div>
                  <div>
                    <div className="mt-6 flex gap-2">
                      <p className="text-xs">Resume</p>
                    </div>
                    <>
                      {activeResumeUrl === null || activeResumeUrl === '' ? (
                        <>
                          <button
                            {...getRootProps({ className: 'dropzone' })}
                            className={clsx(
                              'padding mt-4 flex w-full flex-col items-center justify-center rounded-xl  border-2 border-dashed border-[#E6E5E5] py-6',
                              isDragActive && 'border-[#2F80ED] bg-[#F2F8FF]'
                            )}
                            onClick={open}
                            type="button"
                          >
                            <Image
                              src="/assets/uploadBig.png"
                              alt="jobLogo"
                              height={40}
                              width={40}
                            />
                            <div className="mt-6">
                              {acceptedFiles[0] ? (
                                <div>
                                  <p className="text-[#2F80ED]">
                                    {acceptedFiles[0].name}
                                  </p>
                                  {mutationFileUpload.isLoading && (
                                    <p className="mt-2  text-xs text-gray-400">
                                      Uploading...
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <p className="text-center text-xs leading-7">
                                  <span className="text-[#2F80ED]">Browse</span>{' '}
                                  or drag & drop to upload your resume as a{' '}
                                  <br />{' '}
                                  <span className="font-[700]">
                                    .doc, .docx
                                  </span>{' '}
                                  or{' '}
                                  <span className="font-[700]">.pdf file</span>
                                </p>
                              )}
                            </div>
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-1 items-center justify-end gap-3 text-xs">
                            <button
                              type="button"
                              onClick={open}
                              className="text-primary transition-all hover:text-green-700"
                            >
                              Update Resumé
                            </button>
                          </div>

                          <div className="padding border-bg-[#F2F8FF] mt-4 flex w-full flex-col items-center justify-center  rounded-xl border-2 border-dashed py-6">
                            <Image
                              src="/assets/uploadBig.png"
                              alt="jobLogo"
                              height={40}
                              width={40}
                            />
                            <div className="mt-6">
                              {acceptedFiles[0] ? (
                                <div>
                                  <p className="text-[#2F80ED]">
                                    {acceptedFiles[0].name}
                                  </p>
                                  {mutationFileUpload.isLoading && (
                                    <p className="mt-2  text-xs text-gray-400">
                                      Uploading...
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="text-center">
                                  <p className="text-xs">
                                    The resume in your profile will be submitted
                                    for this job
                                  </p>
                                  <button
                                    type="button"
                                    onClick={open}
                                    className="text-sm text-primary transition-all hover:text-green-700"
                                  >
                                    Click to update resumé
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      <input {...getInputProps()} />
                    </>
                  </div>
                  <div className="mt-5 flex justify-end">
                    <Button
                      disabled={
                        mutationApplyToAJob.isLoading ||
                        mutationFileUpload.isLoading
                      }
                      isLoading={mutationApplyToAJob.isLoading}
                      type="submit"
                    >
                      {mutationApplyToAJob.isLoading
                        ? 'Applying...'
                        : mutationFileUpload.isLoading
                        ? 'Uploading...'
                        : 'Apply'}
                    </Button>
                  </div>
                </form>
              )
            )}

            {isApplied && (
              <div className="flex w-full items-center justify-center rounded-xl bg-gray-100 py-16 text-center text-sm text-secondary lg:h-[600px] lg:w-[400px] lg:py-0">
                <div className="text-center">
                  <Image
                    className="mx-auto"
                    src="/assets/medal.png"
                    width={96}
                    height={96}
                    alt=""
                  />
                  <h3 className="mt-7 mb-3 text-base font-normal">
                    You have applied for this job.
                  </h3>
                  <p>Application is in {applicationStatusText}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <ReportJob
        openReportJob={openReportJob}
        setOpenReportJob={setOpenReportJob}
        jobId={jobId}
      />

      <LoginPopUp
        openLoginPopUp={openLoginPopUp}
        setOpenLoginPopUp={setOpenLoginPopUp}
        action="report this job"
      />

      <LoginPopUp
        openLoginPopUp={openLoginPopUpCompanyProfile}
        setOpenLoginPopUp={setOpenLoginPopUpCompanyProfile}
        action="view this company's profile"
      />

      <ShareJob
        openShareJob={openShareJob}
        setOpenShareJob={setOpenShareJob}
        jobId={jobId}
      />

      <ViewCompanyProfileJobSeeker
        openViewCompanyProfile={openCompanyProfile}
        setOpenViewCompanyProfile={setOpenCompanyProfile}
        companyId={companyId ?? ''}
      />
    </div>
  )
}
