import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import {
  DefaultOptions,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

interface ReactQueryProviderProps {
  children: React.ReactNode;
}

const ReactQueryProvider: React.FunctionComponent<ReactQueryProviderProps> = ({ children }) => {
  const twelveHoursInMs = 1000 * 60 * 60 * 12;

  const defaultOptions: DefaultOptions = {
    queries: {
      refetchOnWindowFocus: true,
      retry: 0,
      // Globally default to 12 hrs
      staleTime: twelveHoursInMs,
      cacheTime: twelveHoursInMs,
    },
  };

  const queryCache = new QueryCache({
    onError: (error) => {
      console.log({error});
    },
  });

  const [queryClient] = React.useState(() => new QueryClient({ defaultOptions, queryCache }));

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
