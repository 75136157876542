import { useQuery } from '@tanstack/react-query'

import { fetchData, skillsKeys } from '@/utils'
import { userIDStorage } from '@/features/auth'
import { Skill } from '../types'

interface SkillsResponse {
  status: string
  message: string
  data: Skill[]
}

export const useSkills = () => {
  const userId = userIDStorage.getUserID()
  return useQuery<SkillsResponse>({
    queryKey: skillsKeys.list(userId),
    queryFn: async () => fetchData(`/user/skills/${userId}`),
    enabled: !!userId,
  })
}
