import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { fetchData, savedJobKeys, transformError } from '@/utils';
import { SavedJobsProps} from '../types';
import { userIDStorage } from '@/features/auth';
import { AxiosError } from 'axios';


export const usePostSavedJobs = () => {
  const queryClient = useQueryClient();
  const userId = userIDStorage.getUserID()
  return useMutation<Response, AxiosError, SavedJobsProps>(
    (payload: SavedJobsProps) =>
      fetchData<SavedJobsProps>("/user/save-job", 'POST', payload),
    {
      onSuccess: () => {
        toast.success('Jobs successfully saved.');
        queryClient.invalidateQueries({
            queryKey: savedJobKeys.list(userId),
        });
        queryClient.invalidateQueries({
            queryKey: savedJobKeys.details(),
        });
      },
      onError: (error) => {
        toast.error(transformError(error));
      },
    }
  );
};