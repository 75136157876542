import { useQuery } from '@tanstack/react-query'

import { jobApplicantKeys, fetchData } from '@/utils'
import { JobData } from '@/features/job-seeker'

interface JobApplicantResponse {
  status: string
  message: string
  data: JobData
}

export const useJobApplicants = (id: string) => {
  return useQuery<JobApplicantResponse>({
    queryKey: jobApplicantKeys.list(id),
    queryFn: async () => fetchData(`/company/job-applicants/${id}`),
  })
}
