import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { BsXLg } from 'react-icons/bs'
import { GiCheckMark } from 'react-icons/gi'

import { backdropVariant, editPreference } from '@/utils'
import Image from 'next/image'
import Link from 'next/link'

interface EditPreferencesProps {
  openMobileAppModal: boolean
  setOpenMobileAppModal: (value: boolean) => void
}

export const MobileAppDownload: React.FC<EditPreferencesProps> = ({
  openMobileAppModal,
  setOpenMobileAppModal,
}) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openMobileAppModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenMobileAppModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openMobileAppModal])

  const modalBullets = [
    'Stay updated with personalized job recommendations, tailored to match your preferences and qualifications.',
    'Receive instant notifications for new job postings, ensuring you never miss out on exciting career prospects.',
    'Our intuitive interface simplifies the application process, enabling you to submit your resume and cover letter with just a few taps.',
    'Track your application status and communicate directly with employers, enhancing your chances of landing your dream job.',
  ]

  modalBullets

  return (
    <AnimatePresence>
      {openMobileAppModal && (
        <motion.div
          className="fixed top-0 left-0 z-[200] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="sm:scrollbar-hide absolute top-[50%] left-[50%] z-50 m-auto h-[595px] max-h-[595px] w-[90%] overflow-y-scroll rounded-lg md:w-[932px]"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            variants={editPreference}
          >
            <button
              type="button"
              className="absolute top-7 right-7 rounded-full bg-gray-200 p-2 text-[9px]"
              onClick={() => setOpenMobileAppModal(false)}
            >
              <BsXLg />
            </button>
            <div className="scrollbar-hide flex items-stretch gap-0 overflow-hidden">
              <div className="relative hidden h-[595px] w-[345px] min-w-[345px] items-center justify-center bg-[#191919] md:flex">
                <Image
                  src="/assets/mobileAppAdd.png"
                  width={208}
                  height={402}
                  alt="mobileApp"
                  className="relative z-[10]"
                />
                <Image
                  src="/assets/blackLine.svg"
                  className="absolute -top-1 -left-2"
                  width={365}
                  height={595}
                  alt="backdrop"
                />
              </div>
              <div className=" bg-white px-8 py-12">
                <h3 className="text-[20px]">
                  Don’t miss any job interview invite:
                </h3>

                <h3 className="text-[20px]">
                  We are now available on your mobile devices
                </h3>
                <div>
                  <p className="mt-4 text-sm">
                    Great news! We are excited to announce that scout4job is now
                    available on Android and iOS devices.
                  </p>
                  <div className="mt-4 text-sm">
                    {modalBullets.map((bullet, index) => (
                      <div key={index} className="mt-2 flex items-start gap-2">
                        <p className="mt-1 rounded-sm bg-primary p-1">
                          <GiCheckMark color="white" size="7" />
                        </p>
                        <p className="text-sm">{bullet}</p>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 text-sm">
                    <p>
                      Download our android app now and access all the benefits!
                    </p>
                  </div>
                </div>
                <nav className="my-8 mt-14">
                  <ul className="flex">
                    <li className="px-1.5">
                      <Link
                        className="decoration-barbie  underline-offset-2 hover:underline"
                        href="https://apps.apple.com/app/scout4job/id6449973743"
                        target="_blank"
                        rel="noopener nofollow"
                      >
                        <Image
                          src="https://res.cloudinary.com/scout4job/image/upload/v1692276489/app-store_ds5b9e_vuizdl.png"
                          alt="app"
                          width={120}
                          height={120}
                        />
                      </Link>
                    </li>
                    <li className="px-1.5">
                      <Link
                        className="decoration-barbie underline-offset-2 hover:underline"
                        href="https://play.google.com/store/apps/details?id=com.scout4job"
                        target="_blank"
                        rel="noopener nofollow"
                      >
                        <Image
                          src="https://res.cloudinary.com/scout4job/image/upload/v1692276490/play-store_fcph2r_rrcqdm.png"
                          alt="app"
                          width={120}
                          height={120}
                        />
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
