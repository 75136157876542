import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import React from 'react'

import { backdropVariant, VerificationAnim } from '@/utils'

interface VerificationProps {
  showVerification: boolean
  setShowVerification: (value: boolean) => void
}

export const Verification: React.FC<VerificationProps> = ({
  showVerification,
  setShowVerification,
}) => {
  const ref = React.useRef<HTMLInputElement>(null)

  return (
    <AnimatePresence>
      {showVerification && (
        <motion.div
          className="absolute top-0 left-0 z-[100] flex h-[100vh] w-full items-center  justify-center bg-black bg-opacity-40 text-left text-black backdrop-blur-sm"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide absolute mx-auto w-[524px] overflow-scroll  rounded-lg bg-white px-8 pb-8 pt-16"
            ref={ref}
            exit="exit"
            variants={VerificationAnim}
          >
            <div className="flex  justify-center">
              <Image
                src="/assets/verification.png"
                height={40}
                width={37}
                alt="logo"
              />
            </div>
            <h2 className="mt-7 text-center text-[32px]">
              Enter verification code
            </h2>
            <p className="text-center">
              A 6-digit code has been sent to your email, please check to access
              account
            </p>
            <div className="my-16 flex justify-center gap-2">
              <input
                className="h-[46px] w-12 rounded-md border border-gray-400 outline-primary"
                type="number"
              />
              <input
                className="h-[46px] w-12 rounded-md border border-gray-400 outline-primary"
                type="number"
              />
              <input
                className="h-[46px] w-12 rounded-md border border-gray-400 outline-primary"
                type="number"
              />
              <input
                className="h-[46px] w-12 rounded-md border border-gray-400 outline-primary"
                type="number"
              />
              <input
                className="h-[46px] w-12 rounded-md border border-gray-400 outline-primary"
                type="number"
              />
              <input
                className="h-[46px] w-12 rounded-md border border-gray-400 outline-primary"
                type="number"
              />
            </div>
            <button
              onClick={() => setShowVerification(false)}
              className="display witems-center mx-auto mt-[2rem] flex w-[240px] justify-center gap-5 bg-secondary py-[12.5px] px-[30px] text-xs font-bold text-white shadow-[-6px_6px_0px_0px_#16BF44]  transition duration-100 hover:bg-[#16BF44] hover:shadow-[-6px_6px_0px_0px_#191919] md:text-sm "
            >
              Verify
            </button>
            <p className="mt-6 text-center text-base">
              Didn’t get code?{' '}
              <span className="text-primary ">Resend Code</span>{' '}
            </p>
            <p className="mt-8 text-center  text-sm">
              Need help? contact us at help@scout4job.com
            </p>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
