import * as React from 'react'
import clsx from 'clsx'

import { Spinner } from '.'

const variants = {
  primary:
    'bg-secondary text-white shadow-[-6px_6px_0px_0px_#16BF44] disabled:hover:shadow-[-6px_6px_0px_0px_#16BF44] hover:bg-primary disabled:hover:bg-secondary hover:shadow-[-6px_6px_0px_0px_#191919]',
  outline:
    'bg-white  text-secondary shadow-[-6px_6px_0px_0px_#191919] disabled:hover:shadow-[-6px_6px_0px_0px_#191919] hover:shadow-[6px_6px_0px_0px_#191919]',
  underline: 'text-info-100 underline text-sm hover:no-underline',
  danger: 'bg-red-600 text-white border-red-600',
  dangerTwo: 'bg-red-600 text-white border-red-600 shadow-[-6px_6px_0px_0px_#191919] disabled:hover:shadow-[-6px_6px_0px_0px_#191919] hover:shadow-[6px_6px_0px_0px_#191919]'
}

const sizes = {
  xs: 'py-2 px-3 md:px-4 xl:px-5 text-[13px]',
  sm: 'py-2 px-4 md:px-6 text-sm',
  md: 'py-2 px-6 md:px-10 text-[15px]',
  lg: 'py-3 px-8 lg:px-12 text-lg',
}

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined }

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants
  size?: keyof typeof sizes
  isLoading?: boolean
} & IconProps

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    const isUnderlineVariant = variant === 'underline'
    const isOutlineVariant = variant === 'outline'
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          !isUnderlineVariant &&
            'flex items-center justify-center gap-5 py-[12.5px] px-[38px] text-xs font-bold  transition duration-200  disabled:cursor-not-allowed  disabled:opacity-70 md:text-sm',
          variant === 'primary' && isOutlineVariant && 'border-transparent',
          variants[variant],
          !isUnderlineVariant && sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner size={size} className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    )
  }
)

Button.displayName = 'Button'
