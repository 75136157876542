import { useQuery } from '@tanstack/react-query'

import { educationKeys, fetchData } from '@/utils'
import { Education } from '../../job-seeker/types'

interface EducationResponse {
  status: string
  message: string
  data: Education[]
}

export const useGetEducation = (userId: string) => {

  return useQuery<EducationResponse>({
    queryKey: educationKeys.list(userId),
    queryFn: async () => fetchData(`/user/education/${userId}`),
    enabled: !!userId
  })
}