import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { PostAJobFormPayload } from '../types'
import { companyJobKeys, fetchData, transformError } from '@/utils'
import { AxiosError } from 'axios'


export const useEditAJob = (jobId:string) => {
  const router = useRouter()
  const queryClient = useQueryClient()

  return useMutation(
    (payload: PostAJobFormPayload) =>
      fetchData(`/job/edit/${jobId}`, 'PUT', payload),
    {
      onSuccess: () => {
        toast.success('Job edited successfully')
        queryClient.invalidateQueries({
          queryKey: companyJobKeys.all,
        })
        router.push('/recruiter/dashboard/jobs')
      },
      onError: (error:AxiosError) => {
        toast.error(transformError(error))
      },
    }
  )
}
