import React from 'react';
import dayjs from 'dayjs';
import { AllJobsForApprovalData } from '@/features/job-seeker';
import Link from 'next/link';


interface TitleProp  {
    job: AllJobsForApprovalData,
}



export const SimpleJobTile = ({job}:TitleProp) => {

  const {approveCount, datePosted,employmentType, title, workType, level, } = job
  const formattedPostedDate = dayjs(datePosted).format('MMMM D, YYYY h:mm A')

  return (
    <div className="relative mb-4 flex flex-col items-center rounded-md border border-[#efefef] bg-white py-6 px-6 shadow-lg transition duration-100 ease-in-out hover:shadow-sm">
      
      <div
          className="mt-5 cursor-pointer text-center text-lg font-bold line-clamp-1"
        
        >
         {title}
        </div>
        <div className="mt-2 flex gap-2">
          <p
            style={{
              backgroundColor: 'rgba(229, 79, 32, 0.1)',
              color: '#E54F20',
            }}
            className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
          >
            {employmentType}
          </p>
          <p
            style={{
              backgroundColor: 'rgba(39, 174, 96, 0.1)',
              color: '#27AE60',
            }}
            className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
          >
            {workType}
          </p>
          <p
            style={{
              backgroundColor: 'rgba(229, 32, 222, 0.1)',
              color: '#E520DE',
            }}
            className="flex w-fit items-center justify-center whitespace-nowrap rounded-sm py-1 px-3 text-[10px] capitalize"
          >
            {level}
          </p>
        </div>
        <Link href={`/recruiter/dashboard/approval/applicants/${job?.id}`} className='w-[121px] flex items-center justify-center mt-4 h-[121px] rounded-full bg-white border-4 border-primary text-[32px] font-bold'>
            {approveCount}
        </Link>
        <p className="my-5 text-xs text-[#A6A6A6]">
            Published {formattedPostedDate}
          </p>
    </div>
  );
}

export default SimpleJobTile;
