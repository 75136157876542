import Image from 'next/image'
import { useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, InputField } from '@/components'
import { backdropVariant, editProfile } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { discardEmployeeSchema } from '../../utils'
import { useForm } from 'react-hook-form'

interface DiscardemployeeProps {
  openDiscardEmployee: boolean
  setOpenDiscardEmployee: (value: boolean) => void
}

interface DiscardEmployeeForm {
    discardEmployee: string
}

export const DiscardEmployee: React.FC<DiscardemployeeProps> = ({
  openDiscardEmployee,
  setOpenDiscardEmployee,
}) => {
  const ref = useRef<HTMLInputElement>(null)



  const { register,
    formState: { errors },} =
    useForm<DiscardEmployeeForm>({
  
      resolver: yupResolver(discardEmployeeSchema),
    })

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        openDiscardEmployee &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenDiscardEmployee(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openDiscardEmployee])

  //how to code select button

  return (
    <AnimatePresence>
      {openDiscardEmployee && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide  jus absolute top-[50%]  left-[50%] flex max-h-[612px] w-[511px] flex-col items-center overflow-scroll  rounded-lg bg-[#FAFAFA] px-8 py-6"
            ref={ref}
            initial="initial"
            animate="show"
            exit="exit"
            style={{ height: 'calc(100vh - 156px)' }}
            variants={editProfile}
          >
            <div className="flex flex-col items-center justify-center text-center">
              {/* <Image  /> */}
              <Image
                height={213}
                width={206}
                alt="qualified"
                src="/assets/confrmDisqualify.gif"
              />

              <h4 className="my-2 text-2xl">Discard Employee</h4>

              <p className="mt-4 text-sm">
                Can you tell us why you want to discard this employee?
              </p>

              <div className="my-2  flex items-center gap-4 border-b-[#E5E5E5] pb-6">
                <div>
                  <Image
                    height={57}
                    width={57}
                    alt="applicant"
                    src="/assets/applicants1.png"
                  />
                </div>
                <div className=" pr-10">
                  <p className="text-sm font-bold">Bolatito Godfrey</p>
                  <p className="mt-1 text-xs">Senior UI/UX Designer</p>
                  <p className="mt-1 text-xs text-[#A6A6A6]">
                    Vanilla Inc • Lagos
                  </p>
                </div>
              </div>
              <div>
                <div className="mt-6 flex w-[320px] items-center gap-2">
                  <input
                    id="discardReason "
                    className="w-10px"
                    type="radio"
                    name="discardReason"
                    value="HTML"
                  />
                  <label className="text-sm" htmlFor="discardReason">
                    too loud in the office
                  </label>
                </div>
                <div className="mt-6 flex items-center gap-2">
                  <input
                    id="discardReason"
                    className="w-10px"
                    type="radio"
                    name="discardReason"
                    value="HTML"
                  />
                  <label className="text-sm" htmlFor="discardReason">
                    Not compentent for the job
                  </label>
                </div>
                <div className="mt-6 flex items-center gap-2">
                  <input
                    id="discardReason"
                    className="w-10px"
                    type="radio"
                    name="discardReason"
                    value="HTML"
                  />
                  <label className="text-sm" htmlFor="discardReason">
                    Lied in their resume
                  </label>
                </div>
                <div className="mt-6 flex items-center gap-2">
                  <input
                    id="discardReason"
                    className="w-10px"
                    type="radio"
                    name="discardReason"
                    value="HTML"
                  />
                  <label className="text-sm" htmlFor="discardReason">
                    other
                  </label>
                </div>
                <div className="flex-1">
                  <InputField
                    className="mt-4"
                    placeholder="tell us your reason"
                    registration={{ ...register('discardEmployee') }}
                    hasError={errors.discardEmployee}
                  />
                </div>
              </div>
              <div className="mt-8 w-full flex items-center gap-4">
                <Button
                className='flex-1'
                  variant="outline"
                  onClick={() => setOpenDiscardEmployee(false)}
                >
                  Cancel
                </Button>
                <Button
                className='flex-1'
                  variant="outline"
                  style={{ background: '#EB5757', color: 'white' }}
                >
                  Discard
                </Button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
