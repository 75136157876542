import React from 'react'
import { Chart, ArcElement, ChartOptions, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
// Register the ArcElement

interface ChartData {
  labels: string[]
  datasets: {
    data: (number | undefined)[]
    backgroundColor: string[]
    borderColor: string[]
    borderWidth: number
    cutout: string
    offset: number
    borderRadius: number
  }[]
}

interface RecruiterChartProp {
  data: ChartData
}

export const RecruiterChart = ({ data }: RecruiterChartProp) => {
  Chart.register(ArcElement, Tooltip)

  const options: ChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Chart Title',
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 10)',
        callbacks: {
          // label: (context) => {
          //   // const label = data.labels[context.dataIndex] || '';
          //   // const value = (selectedSymbol + formatNumber(data.datasets[0].data[context.dataIndex])) || 0;
          //   // return `${value}`;
          // },
        },
      },
    },
  }

  return (
    <div className="relative z-40 h-[280px] w-[280px] min-w-[280px]">
      <Doughnut data={data} options={options} />
    </div>
  )
}
