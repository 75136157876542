import { WORK_PLACE_CULTURE } from '@/data'
import { useCurrencyOptions, useJobTypeOptions, useLevelOptions } from '@/hooks'
import clsx from 'clsx'
// import { Slider } from '@mui/material'
import { useState, useEffect } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
// import { Button } from './Elements'

type SelectedFilterProps = {
  segment: string
  keywords: string[]
}
interface FilterProp {
  selectedFilters: SelectedFilterProps[]
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<SelectedFilterProps[]>
  >
  setOpenFilterMobile?: (value: boolean) => void
}

export const Filter = ({
  setSelectedFilters,
  selectedFilters,
}: // setOpenFilterMobile,
FilterProp) => {
  const { currencyOptions } = useCurrencyOptions()
  const { jobTypes, jobTypeLoading } = useJobTypeOptions()
  const { levelOptions, isLevelsLoading } = useLevelOptions()
  const [openCulture, setOpenCulture] = useState(false)
  const [openEmployment, setOpenEmployment] = useState(false)
  const [openJobLevel, setOpenJobLevel] = useState(false)
  const [currencyDropDown, setCurrencyDropDown] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState<
    { name: string; id: string }[]
  >([])

  useEffect(() => {
    setFilteredData(
      currencyOptions?.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
  }, [searchTerm])

  const checkedSVG = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#27AE60" />
      <path
        d="M14 8L9.1875 13L7 10.7273"
        stroke="#FEFEFE"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

  const handleCheckboxChange = (segment: string, name: string) => {
    setSelectedFilters((prevFilters) => {
      const existingFilterIndex = prevFilters?.findIndex(
        (filter) => filter.segment === segment
      )

      if (existingFilterIndex !== -1) {
        // If the filter exists, update its keywords
        const updatedFilter = {
          ...prevFilters[existingFilterIndex],
          keywords:
            segment === 'currency'
              ? [name] // If the segment is 'currency', replace the existing keyword with the new one
              : prevFilters[existingFilterIndex].keywords.includes(name)
              ? prevFilters[existingFilterIndex].keywords.filter(
                  (keyword) => keyword !== name
                )
              : [...prevFilters[existingFilterIndex].keywords, name],
        }
        const updatedFilters = [...prevFilters]
        updatedFilters[existingFilterIndex] = updatedFilter

        // If the updated filter has no keywords, remove it from the array
        if (updatedFilter.keywords.length === 0) {
          updatedFilters.splice(existingFilterIndex, 1)
        }

        return updatedFilters
      } else {
        // If the filter doesn't exist, add a new one
        return [...prevFilters, { segment, keywords: [name] }]
      }
    })
  }

  const handleClickCurrency = (id: string, name: string) => {
    if (searchTerm === name) {
      setSearchTerm('')
    } else {
      setSearchTerm(name)
    }
    handleCheckboxChange('CURRENCY', id)
  }

  const handleClear = () => {
    setSelectedFilters([])
    setOpenCulture(false)
    setCurrencyDropDown(false)
    setOpenJobLevel(false)
    setOpenEmployment(false)
  }

  return (
    <div className="sticky top-[96px] hidden w-[449px] lg:block max-h-screen overflow-y-scroll">
      <div className="flex h-fit w-[319px] items-center justify-between rounded-t-lg border-b bg-white py-4 px-8">
        <h3>Filter</h3>
        <button
          className="text-sm text-red-400"
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
      <div className="select-none rounded-b-lg bg-white px-8 pb-8 pt-4 md:w-[319px] md:overflow-y-scroll ">
        <div>
          {/* <div className="relative text-xs">
          <p className="text-left text-xs font-[700]">Duration</p>
          <div
            className="mt-2 flex h-[40px] w-full items-center justify-between rounded-md bg-black px-3 text-white"
            onClick={() => setOptionShow(!optionShow)}
          >
            <p className="pl-2">{option}</p>
            <FaChevronUp
              className={
                optionShow
                  ? 'mr-[5px] text-xs text-gray-400 transition duration-200 ease-in-out'
                  : 'mr-[5px] rotate-180  text-xs text-gray-400 transition duration-200 ease-in-out'
              }
            />
          </div>
          {optionShow && (
            <div className="absolute z-30 flex w-full flex-col rounded-lg bg-white py-4 text-left shadow-md">
              {filterSort.map((category) => (
                <p
                  key={category}
                  className="w-full px-3 py-2 font-[500] hover:bg-[#eeeeee]"
                  onClick={() => {
                    setOption(category)
                    setOptionShow(false)
                  }}
                >
                  {category}
                </p>
              ))}
            </div>
          )}
        </div> */}

          {/* Office culture */}
          <div className="">
            <button
              className="mt-[10px] mb-[15px] flex w-full justify-between"
              onClick={() => setOpenCulture(!openCulture)}
            >
              <p className="text-xs font-[700]">Office Culture</p>
              <div className="">
                <FaChevronDown
                  className={clsx(
                    'mr-[5px] text-xs text-gray-400 transition-all duration-300 ease-in-out',
                    openCulture && 'rotate-180'
                  )}
                />
              </div>
            </button>

            {/*  */}

            <div
              className={clsx(
                'mt-[15px] overflow-y-scroll transition-all duration-300 ease-in-out',
                openCulture ? 'max-h-[200px]' : 'max-h-0'
              )}
            >
              {WORK_PLACE_CULTURE.map((option, index) => (
                <div
                  className="mt-[14px] flex items-center justify-between"
                  key={index}
                >
                  <div className="flex ">
                    <div className="flex items-center gap-6">
                      <div className="flex items-center gap-6">
                        <button
                          onClick={() =>
                            handleCheckboxChange(
                              'WORKPLACE_CULTURE',
                              option.name
                            )
                          }
                          className={clsx(
                            'h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full'
                            // !selectedFilters?.keywords?.includes(option.name) &&
                            //   'border'
                          )}
                        >
                          {selectedFilters
                            .find(
                              (filter) => filter.segment === 'WORKPLACE_CULTURE'
                            )
                            ?.keywords.includes(option.name) ? (
                            checkedSVG
                          ) : (
                            <div className="h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full border border-[#A6A6A6]"></div>
                          )}
                        </button>
                        <p className="text-xs capitalize">{option.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Type of Employment */}
          <div className="">
            <button
              className="mt-[30px] mb-[15px] flex w-full justify-between"
              onClick={() => setOpenEmployment(!openEmployment)}
            >
              <p className="text-xs font-[700]">Type of Employment</p>
              <div className="">
                <FaChevronDown
                  className={clsx(
                    'mr-[5px] text-xs text-gray-400 transition-all duration-300 ease-in-out',
                    openEmployment && 'rotate-180'
                  )}
                />
              </div>
            </button>

            {/*  */}

            <div
              className={clsx(
                'mt-[15px] overflow-y-scroll transition-all duration-300 ease-in-out',
                openEmployment ? 'max-h-[200px]' : 'max-h-0'
              )}
            >
              {jobTypeLoading === false &&
                jobTypes.map((option, index) => (
                  <div
                    className="mt-[14px] flex items-center justify-between"
                    key={index}
                  >
                    <div className="flex ">
                      <div className="flex items-center gap-6">
                        <div className="flex items-center gap-6">
                          <button
                            onClick={() =>
                              handleCheckboxChange(
                                'EMPLOYMENT_TYPE',
                                option.name
                              )
                            }
                            className={clsx(
                              'h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full'
                              // !selectedFilters?.keywords?.includes(option.name) &&
                              //   'border'
                            )}
                          >
                            {selectedFilters
                              .find(
                                (filter) => filter.segment === 'EMPLOYMENT_TYPE'
                              )
                              ?.keywords.includes(option.name) ? (
                              checkedSVG
                            ) : (
                              <div className="h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full border border-[#A6A6A6]"></div>
                            )}
                          </button>
                          <p className="text-xs capitalize">{option.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* Job Level */}
          <div className="">
            <button
              className="mt-[30px] mb-[15px] flex w-full justify-between"
              onClick={() => setOpenJobLevel(!openJobLevel)}
            >
              <p className="text-xs font-[700]">Job Level</p>
              <div className="">
                <FaChevronDown
                  className={clsx(
                    'mr-[5px] text-xs text-gray-400 transition-all duration-300 ease-in-out',
                    openJobLevel && 'rotate-180'
                  )}
                />
              </div>
            </button>

            {/*  */}

            <div
              className={clsx(
                'mt-[15px] overflow-y-scroll transition-all duration-300 ease-in-out',
                openJobLevel ? 'max-h-[200px]' : 'max-h-0'
              )}
            >
              {isLevelsLoading === false &&
                levelOptions?.map((option, index) => (
                  <div
                    className="mt-[14px] flex items-center justify-between"
                    key={index}
                  >
                    <div className="flex ">
                      <div className="flex items-center gap-6">
                        <div className="flex items-center gap-6">
                          <button
                            onClick={() =>
                              handleCheckboxChange('JOB_LEVEL', option.name)
                            }
                            className={clsx(
                              'h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full'
                              // !selectedFilters?.keywords?.includes(option.name) &&
                              //   'border'
                            )}
                          >
                            {selectedFilters
                              .find((filter) => filter.segment === 'JOB_LEVEL')
                              ?.keywords.includes(option.name) ? (
                              checkedSVG
                            ) : (
                              <div className="h-[20px] min-h-[20px] w-[20px] min-w-[20px] rounded-full border border-[#A6A6A6]"></div>
                            )}
                          </button>
                          <p className="text-xs capitalize">{option.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* currency section */}
        <div className="">
          <div
            onClick={() => setCurrencyDropDown(!currencyDropDown)}
            className="mt-[30px] mb-[15px] flex cursor-pointer justify-between"
          >
            <p className="text-xs font-[700]">Currency</p>

            <button>
              <FaChevronUp
                className={
                  currencyDropDown
                    ? 'mr-[5px] text-xs text-gray-400 transition duration-300 ease-in-out'
                    : 'mr-[5px] rotate-180  text-xs text-gray-400 transition duration-300 ease-in-out'
                }
              />
            </button>
          </div>

          {/* currency type drop down options */}
          {currencyDropDown && (
            <div className="relative rounded-full text-xs text-white">
              <div>
                <div className="flex items-center justify-between rounded-full bg-black px-4 py-1">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for country or currency"
                    className="h-8 w-full bg-black pl-2 outline-none"
                  />
                  <FaChevronDown className="text-gray-400" />
                </div>
                <div className=" z-30 flex max-h-[300px] w-full flex-col overflow-y-scroll rounded-lg bg-white py-4 text-left shadow-md">
                  {filteredData.map((item, index) => (
                    <button
                      onClick={() => handleClickCurrency(item.id, item.name)}
                      className="py-2 px-4 text-left text-xs text-black hover:bg-black hover:text-white"
                      key={index}
                    >
                      <p>{item.name}</p>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Salary Range options */}
        {/* <div className="mt-10 pb-24">
        <div
          className="mt-[30px] mb-[15px] flex justify-between"
          onClick={() => setSalaryDropDown(!salaryDropDown)}
        >
          <p className="text-xs font-[700]">Salary Range</p>
          <FaChevronUp
            className={
              salaryDropDown
                ? 'mr-[5px] text-xs text-gray-400 transition duration-300 ease-in-out'
                : 'mr-[5px] rotate-180  text-xs text-gray-400 transition duration-300 ease-in-out'
            }
          />
        </div>
        {salaryDropDown && (
          <div>
            {salaryCategories.map((currency, Index) => (
              <div className="" key={Index}>
                {currency.options.map((option, Index) => (
                  <div className="mt-[14px] flex justify-between" key={Index}>
                    <div className="flex items-center gap-6">
                      <div className="round">
                        <input type="checkbox" id={option.id + ''} />
                        <label htmlFor={option.id}></label>
                      </div>
                      <p className="text-xs">{option.type}</p>
                    </div>
                    <div className="rounded-sm bg-[#e7e6e6] p-1 px-[6px] text-xs text-[#4F4F4F]">
                      {option.available}
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className="pt-10">
              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={value}
                onChange={handleChange}
                getAriaValueText={valuetext}
                valueLabelDisplay="on"
                sx={{ color: '#27AE60' }}
              />
            </div>
          </div>
        )}
      </div> */}
        {/* <Button
        onClick={() => setOpenFilterMobile && setOpenFilterMobile(false)}
        className="mx-auto mt-10 md:hidden"
      >
        Save
      </Button> */}
      </div>
    </div>
  )
}
