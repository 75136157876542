import { backdropVariant, editProfile } from '@/utils'
import { AnimatePresence, motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import React from 'react'
import { useUploadMedia } from '@/features/job-seeker'
import { Gallery } from 'iconsax-react'
import { Button } from '@/components'

interface CompanyUploadMediaProps {
  OpenCompanyUploadMediaModal: boolean
  setOpenCompanyUploadMediaModal: (value: boolean) => void
}

type FormData = {
  image: FileList
}



export const CompanyUploadMedia = ({
  setOpenCompanyUploadMediaModal,
  OpenCompanyUploadMediaModal,
}: CompanyUploadMediaProps) => {
  const ref = React.useRef<HTMLInputElement>(null)

  const [previews, setPreviews] = React.useState<string[]>([])

  // const [files, setFiles] = React.useState<File[]>([])
  const [uploadState] = React.useState<
    'idle' | 'loading' | 'error' | 'success'
  >('idle')

  const mutationUpload = useUploadMedia()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>()

  const useObjectURLs = (selectedFiles: FileList | undefined | null) => {
    
  
    // create previews as a side effect, whenever selected files are changed
    React.useEffect(() => {
      if (!selectedFiles || selectedFiles.length === 0) {
        setPreviews([])
        return
      }
  
      const objectUrls = Array.from(selectedFiles).map((file) =>
        URL.createObjectURL(file)
      )
      setPreviews(objectUrls)
  
      // free memory when ever this component is unmounted or the files change
      return () => {
        objectUrls.forEach(URL.revokeObjectURL)
      }
    }, [selectedFiles])
  
    return previews
  }

  const selectedFiles = watch('image')
  useObjectURLs(selectedFiles)

  const onSubmit = async (data: FormData) => {
    const formData = new FormData()
    Array.from(data.image).forEach((file) =>
      console.log(formData.append('image', file))
    )
    mutationUpload.mutate(formData)
  }

  React.useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (
        OpenCompanyUploadMediaModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setOpenCompanyUploadMediaModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [OpenCompanyUploadMediaModal])

  return (
    <AnimatePresence>
      {OpenCompanyUploadMediaModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide absolute top-[50%] left-[50%] h-auto w-[624px] overflow-scroll rounded-lg bg-white px-8 py-6"
            initial="initial"
            animate="show"
            exit="exit"
            ref={ref}
            variants={editProfile}
          >
            {' '}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <p className="text-sm font-bold">Upload Company media</p>
                {errors.image && <span>This field is required</span>}
              </div>
              <label
                htmlFor="media-picture"
                className="id= mt-4 flex h-[145px] w-full cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-primary bg-[#27AE60] bg-opacity-10"
              >
                <input
                  id="media-picture"
                  type="file"
                  className="sr-only"
                  {...register('image', { required: true })}
                  accept='image/png, image/jpeg'
                  multiple
                />
                <Gallery size="60" color="#27AE60" variant="Bulk" />
              </label>
              <div className="flex h-[12rem] gap-4 mt-3 items-center overflow-x-scroll">
                {previews && previews.map((preview) => (
                  <div className='overflow-hidden shadow-lg rounded-lg h-[5rem] min-w-[7rem] border border-gray-150 flex items-center justify-center' key={preview}>
                    <img
                      className="object-cover"
                      key={preview}
                      src={preview}
                      alt="Selected file preview"
                    />
                    <button onClick={() => setPreviews(previews.filter((e) => e !== preview))}>Delete</button>
                  </div>
                ))}
              </div>
              <Button
                className="mx-auto mt-8"
                type="submit"
                disabled={uploadState === 'loading'}
              >
                {uploadState === 'loading' ? 'Uploading...' : 'Upload Images'}
              </Button>
            </form>
            {uploadState === 'error' && <div>Failed to upload images</div>}
            {uploadState === 'success' && (
              <div>Images uploaded successfully</div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
