import { useQuery } from '@tanstack/react-query'

import { fetchData, resumeTemplateKeys } from '@/utils'
import { ResumeDetails } from '@/types'

interface ResumeDetailsResponse {
  status: string
  message: string
  data: ResumeDetails
}

export const useResumeDetails = (resumeId: string) => {
  return useQuery<ResumeDetailsResponse>({
    queryKey: resumeTemplateKeys.list(resumeId),
    queryFn: async () =>
      fetchData(`/resume-template/${resumeId}`),
  })
}
