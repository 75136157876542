// Auth strategy inspired by: https://theodorusclarence.com/blog/nextjs-redirect-no-flashing.

import { useRouter } from 'next/router'
import React from 'react'

import {
  axiosInstance,
  deleteAxiosDefaultToken,
  setAxiosDefaultToken,
} from '@/lib/axios'
import {
  AuthAction,
  AuthState,
  getUser,
  storage,
  tokenStorage,
  userIDStorage,
} from '@/features/auth'

const initialAuthState: AuthState = {
  user: null,
  isAuthenticated: false,
  isLoading: true,
}

type AuthDispatch = React.Dispatch<AuthAction> | null

const authReducer: React.Reducer<AuthState, AuthAction> = (
  state: AuthState,
  action: AuthAction
) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }
    case 'LOGOUT':
      storage.clear()
      action.clearCache()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }
    case 'STOP_LOADING':
      return {
        ...state,
        isLoading: false,
      }
    default:
      throw new Error('Unknown action type')
  }
}

const AuthContext = React.createContext<{authState: AuthState, authDispatch: AuthDispatch}>({
  authState: initialAuthState,
  authDispatch: null,
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter()
  const [authState, authDispatch] = React.useReducer(
    authReducer,
    initialAuthState
  )

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = tokenStorage.getToken()

        if (token === null || token === undefined) {
          return
        }

        const userID = userIDStorage.getUserID()

        if (token) {
          setAxiosDefaultToken(token, axiosInstance)
          const user = await getUser(userID)
          authDispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          })
        }
      } catch (err) {
        tokenStorage.clearToken()
        deleteAxiosDefaultToken()
        router.push(`/login?source=${router.asPath}`)
      } finally {
        authDispatch({ type: 'STOP_LOADING' })
      }
    }

    fetchUser()
  }, [])

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
