import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { searchModal } from '@/utils'
import { BsXLg } from 'react-icons/bs'

interface MultipleSelectApplicantOptionsProps {
  handleClearSelectApplicants: () => void
  numberOfSelectedApplicants: number
  handleOpenMoveManyToShortlistModal: () => void
  handleOpenScheduleInterviewConfirm: () => void
  activeFilter: number
  handleOpenMoveManyToScreeningModal: () => void
  handleDisqualifyManyModal: () => void
}

export const MultipleSelectApplicantOptions = ({
  handleClearSelectApplicants,
  numberOfSelectedApplicants,
  activeFilter,
  handleOpenScheduleInterviewConfirm,
  handleOpenMoveManyToShortlistModal,
  handleOpenMoveManyToScreeningModal,
  handleDisqualifyManyModal,
}: MultipleSelectApplicantOptionsProps) => {
  return (
    <AnimatePresence>
      {numberOfSelectedApplicants > 0 && (
        <motion.div
          className="scrollbar-hide absolute bottom-[5%]  left-[50%] w-[90%] overflow-scroll rounded-lg bg-[#191919] px-1 py-2 pr-4 lg:max-w-[838px]"
          initial="initial"
          animate="show"
          exit="exit"
          variants={searchModal}
        >
          <div className="mx-auto flex min-h-[45px] items-start justify-between gap-8 border-r-[0] bg-transparent px-2 text-white lg:w-[838px] lg:items-center ">
            <div className="flex items-center gap-2">
              <button
                onClick={handleClearSelectApplicants}
                className="text-xs text-white"
              >
                <BsXLg />
              </button>
              <p className="ml-6 rounded-sm bg-[#2B2B2B] px-3 py-[1.5px] text-sm font-semibold text-white">
                {numberOfSelectedApplicants}
              </p>
              <p className="text-sm">Selected</p>
            </div>
            <div className="flex flex-wrap items-center gap-6 px-4">
              {(activeFilter === 0 || activeFilter === 1 )&& (
                <button
                  onClick={handleOpenMoveManyToShortlistModal}
                  className="rounded-md bg-white px-4 py-2 text-xs font-semibold text-black"
                >
                  Shortlist Applicants
                </button>
              )}
              {activeFilter !== 3 && (
                <button
                  onClick={handleOpenScheduleInterviewConfirm}
                  className="rounded-md bg-primary px-4 py-2 text-xs font-semibold"
                >
                  Schedule Interview
                </button>
              )}
              {activeFilter == 3 && (
                <button
                  onClick={handleOpenMoveManyToScreeningModal}
                  className="rounded-md bg-white text-black px-4 py-2 text-xs font-semibold"
                >
                  Screen Applicants
                </button>
              )}
              {/* <button className="rounded-md bg-[#EB5757] px-4 py-2 text-xs font-semibold">
                Move to Talent Pool
              </button> */}
              {activeFilter !== 3 && (
                <button
                  onClick={handleDisqualifyManyModal}
                  className="rounded-md bg-[#EB5757] px-4 py-2 text-xs font-semibold"
                >
                  Disqualify Applicants
                </button>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
