import { Messages3 } from 'iconsax-react'
import React from 'react'
import clsx from 'clsx'
import { getFormattedTimeAgoText } from '@/utils'
import Link from 'next/link'

import { useNotifications } from '@/features/job-seeker'
import dayjs from 'dayjs'
import { PageSpinner } from '@/components'

interface NotificationsProps {
  show: boolean
}

export const Notifications = React.forwardRef<
  HTMLDivElement,
  NotificationsProps
>(({ show }, ref) => {
  const { data: notificationsData, isLoading } = useNotifications(true)
  const notifications = notificationsData?.data || []

  const sortedNotifications = notifications?.sort((a, b) => {
    return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()
  })

  const isEmpty = notifications && notifications?.length === 0

  const url = process.env.NEXT_PUBLIC_WEBSITE_URL as string

  return (
    <div
      ref={ref}
      className={clsx(
        'absolute top-[50px] left-1/2 z-50 w-[360px] -translate-x-1/2 rounded-sm bg-white text-left  text-black shadow-md transition duration-200',
        show
          ? 'translate-y-0 scale-100 opacity-100'
          : '-translate-y-2 scale-0 opacity-0'
      )}
    >
      <div className="h-1 rounded-t-sm bg-gray-50"></div>
      <div className="flex justify-between space-x-4 bg-gray-350 py-3 px-6">
        <p className="text-[16px] font-medium">Notifications</p>
      </div>
      <hr />
      <div
        className={clsx(
          'lg:overflow-y-auto',
          isEmpty ? 'flex h-20' : 'lg:h-[350px]'
        )}
      >
        {isLoading ? (
          <PageSpinner />
        ) : (
          <div className="text-primary-500 space-y-3 pt-3 text-[12px]">
            {!isEmpty ? (
              <>
                {sortedNotifications.map(
                  ({ id, createdAt, read, jobId, companyMessage }) => (
                    <div
                      className={clsx(
                        'border-b',
                        read === true ? 'bg-white' : ''
                      )}
                      key={id}
                    >
                      <div className="flex items-start space-x-4 px-4 pb-3">
                        <div>
                          <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#27AE60] bg-opacity-10">
                            <Messages3
                              size="20"
                              color="#27AE60"
                              variant="Bold"
                            />
                          </div>
                        </div>
                        <div>
                          <p>{companyMessage}</p>
                          <p className="mt-0 text-gray-250">
                            {getFormattedTimeAgoText(createdAt)}
                          </p>
                          <Link
                            href={`${url}/recruiter/dashboard/jobs/applicants/${jobId}`}
                          >
                            <button className="mt-3 rounded-md bg-black py-1 px-2 text-[10px] text-white transition duration-100 ease-in-out hover:bg-primary hover:text-white">
                              View Details
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <p className="-mt-3 inline-block px-6 pt-6 text-center">
                No notifications
              </p>
            )}
          </div>
        )}
      </div>
      <div className="bg-gray-25 rounded-b-lg p-2 text-center">
        {/* <p className="text-sm font-light">View All</p> */}
      </div>
    </div>
  )
})

Notifications.displayName = 'Notifications'
