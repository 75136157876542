import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { fetchData, userKeys } from '@/utils'
import { JoinCompanyPayload } from '../types'

export const useJoinCompany = (userId = '') => {
  const queryClient = useQueryClient()
  const router = useRouter()
  return useMutation(
    (payload: JoinCompanyPayload) =>
      fetchData(`/user/join-company/${userId}`, 'POST', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: userKeys.all,
        })
        toast.success('Successfully joined company')
        router.push('/login')
      },
      onError: () => {
        toast.error('Error joining company')
      },
    }
  )
}
