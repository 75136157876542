import { useRouter } from 'next/router'
import React from 'react'

import { useFilterBySearch } from '@/hooks'
import { useCompanies, useCompanyJobs } from '../api'
import { filterJob } from '@/utils'

export const useAppliedJobs = () => {
  const { searchTerm, handleSearchChange } = useFilterBySearch()
  const router = useRouter()
  const [active, setActive] = React.useState<number[]>([])
  const [openViewJobPostModal, setOpenViewJobPostModal] = React.useState(false)
  const {data:companyData} = useCompanies();
  const companyId = companyData?.data[0]?.id;
  const { data: companyJobsData, isLoading } = useCompanyJobs(companyId)
  const [selectedJobId, setSelectedJobId] = React.useState(1)

  const jobId = companyJobsData?.data?.map((data) => data?.id)

  const jobs = companyJobsData?.data || []

  const filteredJobs = jobs.filter((job) => filterJob(job, searchTerm))

  const handleClick = (id: number) => {
    const index = active.indexOf(id)
    if (index !== -1) {
      setActive((active) => [
        ...active.slice(0, index),
        ...active.slice(index + 1),
      ])
    } else {
      setActive([id])
      setSelectedJobId(id)
    }
  }

  const handleSetId = (index: number) => {
    jobId !== undefined &&
      router.push(`/recruiter/dashboard/jobs/applicants/${jobId[index]}`)
  }

  const handlePostJob = () => {
    router.push('/recruiter/dashboard/post-job')
  }

  const isJobsEmpty = jobs.length === 0

  return {
    searchTerm,
    handleSearchChange,
    active,
    handleClick,
    handleSetId,
    handlePostJob,
    isJobsEmpty,
    openViewJobPostModal,
    setOpenViewJobPostModal,
    selectedJobId,
    filteredJobs,
    isLoading,
    jobId,
    jobs
  }
}
