import { Control, useFieldArray, useFormContext } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { Trash } from 'iconsax-react'
import Image from 'next/image'
import React from 'react'
import clsx from 'clsx'

import {
  Label,
  Checkbox,
  InputField,
  SelectField,
  TextAreaField,
  SelectFieldWithInput,
} from '@/components'
import { DOCUMENT_ACCEPT } from '@/features/onboarding'
import { EMPTY_OPTION, INTERVIEW_TYPE_OPTIONS } from '@/utils'
import { ScheduleInterviewForm } from '../types'
import { useGetInvitedMembers } from '../api'
import { useFileUpload } from '@/api'
import { Option } from '@/types'


interface InterviewDetailFormProps {
  handleOpenInviteModal: (index: number, idx: number) => void
  index: number
  setFileLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const InterviewDetailForm: React.FC<InterviewDetailFormProps> = ({
  index,
  handleOpenInviteModal,
  setFileLoading
}) => {
  const [isSelectAll, setIsSelectAll] = React.useState(false)
  const membersData = useGetInvitedMembers()
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ScheduleInterviewForm>()
  const mutationFileUpload = useFileUpload()
  
  const interviewMessageLimit = watch(`interviewRounds.${index}.instruction`)
  const interviewScoreCards = watch(
    `interviewRounds.${index}.interviewScoreCards`
  )

  const { fields, append, remove } = useFieldArray({
    control,
    name: `interviewRounds.${index}.interviewers`,
  })

  const referenceFiles = watch(`interviewRounds.${index}.referenceFiles`)
  // const referenceFileUrl = watch(`interviewRounds.${index}.referenceFileUrl`)

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    accept: DOCUMENT_ACCEPT,
    maxFiles: 1,
    onDropAccepted: (acceptedFiles: File[]) => {
      setValue(`interviewRounds.${index}.referenceFiles`, acceptedFiles)
    },

    onDropRejected: (error) => {
      if (error.length > 1) {
        toast.error('Multiple files not allowed!')
        return
      }

      // Just one file
      const { code, message } = error[0].errors[0]

      if (code === 'file-invalid-type') {
        toast.error(message)
        return
      }

      if (code === 'file-too-large') {
        toast.error('File must be 100kb or less')
      }
    },
  })

  const membersOption: Option[] =
    membersData.data?.data.map((member) => {
      const name = `${member.firstName} ${member.lastName}`
      return {
        name: `${member.firstName ? name : 'Unverified user -'} (${
          member.email
        })`,
        id: member.id,
      }
    }) || []

    React.useEffect(() => {
      
      const isReferenceFile = referenceFiles && referenceFiles.length > 0
    if (isReferenceFile && !mutationFileUpload.isLoading) {
      const formData = new FormData()
      formData.append('file', referenceFiles[0])
      mutationFileUpload.mutate(formData, {
        onSuccess: ({ data: { fileUrl } }) => {
          setValue(`interviewRounds.${index}.referenceFileUrl`, fileUrl)
          setFileLoading(false)
        },
        onError: () => {
          setValue(`interviewRounds.${index}.referenceFiles`, null)
          setFileLoading(false)
        },
      })
    }
  }, [referenceFiles])

  const interviewRoundErrorObj = errors.interviewRounds?.[index]

  const interviewType = watch(`interviewRounds.${index}.interviewType`)?.id

  const isInterviewLinkRequired =
    interviewType === 'web conference' || interviewType === 'video conference' || interviewType === 'online assessment test'

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setIsSelectAll(isChecked)

    const newInterviewScoreCards = interviewScoreCards.map((item) => {
      if (isChecked) {
        return {
          ...item,
          status: true,
        }
      }
      return {
        ...item,
        status: false,
      }
    })

    setValue(
      `interviewRounds.${index}.interviewScoreCards`,
      newInterviewScoreCards
    )
  }

  React.useEffect(() => {
    mutationFileUpload.isLoading && setFileLoading(true)
  }, [mutationFileUpload])

  return (
    <>
      <hr className="mt-7 border-[#E5E5E5]" />
      <div className="mt-7 grid gap-7 sm:grid-cols-2 sm:gap-5">
        <div className="flex-1">
          <Label id="interviewType" isRequired>
            Interview Type
          </Label>
          <SelectField
            control={control as unknown as Control}
            hasError={interviewRoundErrorObj?.interviewType}
            arr={INTERVIEW_TYPE_OPTIONS}
            name={`interviewRounds.${index}.interviewType`}
          />
        </div>

      { isInterviewLinkRequired && <div className="flex-1">
          <InputField
            label={interviewType ==='online assessment test' ? 'Assessment Link' : 'Meeting Link'}
            hasError={interviewRoundErrorObj?.interviewLink}
            registration={{
              ...register(`interviewRounds.${index}.interviewLink`),
            }}
            isRequired={isInterviewLinkRequired}
          />
        </div>}
      </div>
      <div className="mt-7">
        <div className="flex items-center space-x-5">
          <h4 className="text-sm">Interview Scorecard</h4>
          <Checkbox
            checked={isSelectAll}
            onChange={handleSelectAll}
            id="selectAll"
          >
            <span className="-ml-0.5 flex items-center text-sm text-green-500">
              Select all
            </span>
          </Checkbox>
        </div>
        <p className="text-xs">
          Select the competencies to focus in this interview
        </p>
        <ul className="mt-2 flex flex-wrap gap-5">
          {interviewScoreCards.map(({ name, status, id }) => {
            const isActive = status

            const handleInterviewCard = (scoreCardId: string) => {
              const newInterviewScoreCards = interviewScoreCards.map((item) => {
                if (item.id === scoreCardId) {
                  return {
                    ...item,
                    status: !item.status,
                  }
                }
                return item
              })

              setValue(
                `interviewRounds.${index}.interviewScoreCards`,
                newInterviewScoreCards
              )
            }

            return (
              <li key={id}>
                <button
                  type="button"
                  onClick={() => handleInterviewCard(id)}
                  className="flex items-center space-x-3 rounded bg-secondary p-4 text-left text-sm text-white"
                >
                  {isActive ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="10" cy="10" r="10" fill="#27AE60" />
                      <path
                        d="M14 8L9.1875 13L7 10.7273"
                        stroke="#FEFEFE"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <span className="block h-4 w-4 rounded-full border border-[#FAFAFA]"></span>
                  )}
                  <span>{name}</span>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="mt-7 text-sm">
        <h3>Involve Team Members</h3>
        <p className="mt-1 text-gray-250">
          Enter your team member&lsquo;s email, we will send them an invite to
          join your interviewing team
        </p>
      </div>
      <div>
        {fields.map((field, idx) => {
          return (
            <div className="mt-4 flex items-center space-x-3" key={field.id}>
              <div className="flex-1">
                <SelectFieldWithInput
                  control={control as unknown as Control}
                  hasError={undefined}
                  arr={membersOption}
                  placeholder="Select member"
                  name={`interviewRounds.${index}.interviewers.${idx}`}
                  openModal={() => handleOpenInviteModal(index, idx)}
                />
              </div>
              <div className="mt-4 w-10">
                <button type="button" onClick={() => remove(index)}>
                  <Trash className="text-red-500" size={15} />
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <button
        type="button"
        onClick={() => append(EMPTY_OPTION)}
        className="mt-4 text-xs text-primary"
      >
        + Add field
      </button>

      <div className="my-8">
        <label htmlFor="instruction" className="text-sm font-bold">
          {interviewType ==='online assessment test' ? 'Assessment Instruction' : 'Interview Instruction'}
        </label>
        <TextAreaField
          className="mt-2 text-sm"
          id="instruction"
          placeholder="Interview instructions"
          registration={{ ...register(`interviewRounds.${index}.instruction`) }}
          hasError={undefined}
          value={interviewMessageLimit}
          limit={10000}
        />
      </div>

      <div>
        <label className="text-sm font-bold">Attach Reference File</label>

        <button
          {...getRootProps({ className: 'dropzone' })}
          className={clsx(
            'padding mt-4 flex w-full flex-col items-center justify-center rounded-xl  border-2 border-dashed border-[#E6E5E5] py-6 sm:py-10',
            isDragActive && 'border-[#2F80ED] bg-[#F2F8FF]'
          )}
          type="button"
          onClick={open}
        >
          <Image
            src="/assets/uploadBig.png"
            alt="jobLogo"
            height={70}
            width={70}
          />
          <div className="mt-5">
            {referenceFiles && referenceFiles[0] ? (
              <div>
                <p className="text-[#2F80ED]">{referenceFiles[0].name}</p>
              </div>
            ) : (
              <p className="px-3 text-center text-sm leading-7 sm:px-7">
                <span className="text-[#2F80ED]">Browse</span> or drag & drop to
                upload your document as a{' '}
                <span className="font-[700]">.doc, .docx</span> or{' '}
                <span className="font-[700]">.pdf file</span>
              </p>
            )}
          </div>
        </button>
        <input {...getInputProps()} />
      </div>
    </>
  )
}
