import { AnimatePresence, motion } from 'framer-motion'
import clsx from 'clsx'
import React from 'react'

import { backdropVariant, InterviewDetailsModalAnim } from '@/utils'
import { NewInterview } from '@/features/job-seeker'
import { RoundCheckbox } from '@/components'
import { nav } from './InterviewDetails'
import { InterviewNav } from '../InterviewNav'
import { ApplicantProfileView } from '../ApplicantProfileView'
import {
  useGetInvitedMembers,
  useGetUserProfile,
  useScorecards,
  useSendForApproval,
} from '../../api'
import Image from 'next/image'
import { InterviewSectionView } from '../InterviewSectionView'
import { InterviewAnalysis } from '../InterviewAnalysis'
import ActivityLog from '../ActivityLog'
import { SelectedApplicantsActionModal } from './SelectedApplicantsActionModal'
import { ConfirmForApprovalModal } from './ConfirmForApprovalModal'
import { ConfirmDiscardApplicantModal } from './ConfirmDiscardApplicantModal'
import { AddMemberAccessToApproveModal } from './AddMemberAccessToApproveModal'
import { toast } from 'react-toastify'
import { useRouter } from 'next/router'
import { colorSet } from '../NewInterviewComponent'

interface InterviewMoreInfoModalProps {
  openInterviewMoreInfoModal: boolean
  setShowInterviewMoreInfoModal: (value: boolean) => void
  data: NewInterview
  jobTitle: string
  jobId: string | string[]
}

export interface MembersToBeGiveAccessInterface {
  id: string
  name: string
  email: string
}
;[]

export const InterviewMoreInfoModal: React.FC<InterviewMoreInfoModalProps> = ({
  openInterviewMoreInfoModal,
  setShowInterviewMoreInfoModal,
  jobTitle,
  jobId,
  data,
}) => {
  const router = useRouter()
  const { jobId: jobIdFromData, interviews: applicantsData } = data
  const usedJobId = jobId.length == 0 ? jobIdFromData : jobId

  const [selected, setSelected] = React.useState(0)
  const [userId, setUserId] = React.useState(applicantsData[0].userId)
  const [selectedItems, setSelectedItems] = React.useState<
    { userId: string; interviewId: string }[]
  >([])
  const [selectedMembers, setSelectedMembers] = React.useState<
    { id: string; name: string; email: string }[]
  >([])
  const [activeNavId, setActiveNavId] = React.useState('1')
  const [interviewId, setInterviewId] = React.useState(
    applicantsData[0].interviewId
  )
  interviewId
  const [openFeedbackFormModal, setOpenFeedbackFormModal] =
    React.useState(false)

  const [showInterviewDetails, setShowInterviewDetails] = React.useState(false)
  const [showSelectedApplicantsAction, setShowSelectedApplicantsAction] =
    React.useState(false)
  const [showConfirmApprovalModal, setShowConfirmApprovalModal] =
    React.useState(false)
  const [showConfirmDiscardModal, setShowConfirmDiscardModal] =
    React.useState(false)
  const [showAddMembers, setShowAddMembers] = React.useState(false)
  const companyMembers = useGetInvitedMembers()
  const showCancelButton = false

  const { data: userProfile } = useGetUserProfile(userId as string)
  const mutateSendForApproval = useSendForApproval()
  const approvalLoading = mutateSendForApproval.isLoading
  const resumeUrl = userProfile?.data?.resumeUrl
  const { data: scoreCards } = useScorecards()
  const scoreCardData = scoreCards?.data
  const membersToBeGivenAccess = companyMembers?.data?.data || []

  const handleApplicantClick = (
    index: number,
    userId: string,
    interviewId: string
  ) => {
    setSelected(index)
    setUserId(userId)
    setInterviewId(interviewId)
  }

  const handleShowConfirmDiscardModal = () => {
    setShowConfirmDiscardModal(true)
  }
  const handleShowMembersModal = () => {
    setShowAddMembers(true)
  }
  const selectedApplicantsIdArrays = selectedItems.map((items) => items.userId)

  const serializedApplicantsArray = JSON.stringify(selectedApplicantsIdArrays)

  const handleScheduleNewInterview = () => {
    router.push(
      `/recruiter/dashboard/interview/schedule/${usedJobId}?applicantId=${encodeURIComponent(
        serializedApplicantsArray
      )}`
    )
  }

  const handleSendForApproval = () => {
    const payload = {
      jobId: jobId,
      userInfo: selectedItems,
      teams: selectedMembers,
    }
    if (selectedItems.length <= 0) {
      toast.error('select an applicant')
    }

    mutateSendForApproval.mutate(payload, {
      onSuccess: () => {
        setShowConfirmApprovalModal(false)
        setSelectedItems([])
        setShowAddMembers(false)
      },
    })
  }

  const handleShowActionTab = () => {
    selectedItems.length > 0
      ? setShowSelectedApplicantsAction(true)
      : setShowSelectedApplicantsAction(false)
  }

  React.useEffect(() => {
    handleShowActionTab()
  }, [selectedItems])

  const handleCheckboxChange = (userId: string, interviewId: string) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItem = { userId, interviewId }
      const itemExists = prevSelectedItems.some(
        (item) => item.userId === userId && item.interviewId === interviewId
      )

      if (itemExists) {
        return prevSelectedItems.filter(
          (item) => item.userId !== userId || item.interviewId !== interviewId
        )
      } else {
        return [...prevSelectedItems, updatedItem]
      }
    })
  }

  const handleAddMemberAccessChange = (
    teamMember: MembersToBeGiveAccessInterface
  ) => {
    if (selectedMembers.some((member) => member.id === teamMember.id)) {
      setSelectedMembers(
        selectedMembers.filter((member) => member.id !== teamMember.id)
      )
    } else {
      setSelectedMembers([...selectedMembers, teamMember])
    }
  }

  // console.log({selectedMembers})

  // console.log({userId, jobId})

  return (
    <AnimatePresence>
      {openInterviewMoreInfoModal && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide absolute top-0 right-0 box-border h-screen w-[100%] overflow-scroll bg-[#EFEFEF] pb-4"
            initial="initial"
            animate="show"
            exit="exit"
            variants={InterviewDetailsModalAnim}
          >
            <div className=" border-b-2 bg-white py-4 px-5">
              <div className="mx-auto flex w-full max-w-[1500px] items-center justify-between">
                <h2 className="text-2xl text-black">{jobTitle}</h2>
                <button
                  onClick={() => {
                    setShowInterviewMoreInfoModal(false)
                    setSelectedItems([])
                  }}
                  className="rounded-md bg-red-600 px-8 py-2 text-xs font-bold text-white transition hover:bg-red-400"
                >
                  Close
                </button>
              </div>
            </div>
            <div className="mainContent mx-auto mt-6 flex max-w-[1500px] flex-grow gap-6">
              {/* Applicants section */}

              <div className="w-[469px] min-w-[469px] rounded-md bg-white px-6 py-5">
                <div>
                  <div className="flex items-center justify-between">
                    <p className="text-[20px] font-bold">Applicants</p>
                    {applicantsData?.length === 0 ? (
                      <p className="w-fit rounded-sm bg-[#191919] bg-opacity-10 px-2 py-1 text-sm">
                        No Interviewees
                      </p>
                    ) : applicantsData?.length > 1 ? (
                      <p className="w-fit rounded-sm bg-[#191919] bg-opacity-10 px-2 py-1 text-sm">
                        {applicantsData?.length} Interviewees
                      </p>
                    ) : (
                      <p className="w-fit rounded-sm bg-[#191919] bg-opacity-10 px-2 py-1 text-sm">
                        {applicantsData?.length} Interviewee
                      </p>
                    )}
                  </div>
                </div>

                {applicantsData.map((applicantInfo, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() =>
                        handleApplicantClick(
                          index,
                          applicantInfo.userId,
                          applicantInfo.interviewId
                        )
                      }
                      className={clsx(
                        'flex w-full items-center justify-between gap-4 rounded-md border-b border-b-[#e8e7e7] px-4 py-4',
                        index == 0 && 'mt-4',
                        // selected === index && applicantInfo.isApproved
                        //   ? 'bg-primary text-white'
                        selected === index && applicantInfo.isDiscarded
                          ? 'bg-red-600 text-white'
                          : selected === index && applicantInfo.isForwarded
                          ? 'bg-primary text-white'
                          : selected === index
                          ? 'bg-black text-white'
                          : 'bg-white text-black'
                      )}
                    >
                      <div className="flex items-center gap-4">
                        {applicantInfo.isForwarded ||
                        applicantInfo.isDiscarded ? (
                          <p className="w-5"></p>
                        ) : (
                          <RoundCheckbox
                            checked={selectedItems.some(
                              (item) =>
                                item.userId === applicantInfo.userId &&
                                item.interviewId === applicantInfo.interviewId
                            )}
                            onChange={() =>
                              handleCheckboxChange(
                                applicantInfo.userId,
                                applicantInfo.interviewId
                              )
                            }
                          />
                        )}
                        <div className="flex items-center gap-4">
                          <div className="flex gap-4">
                            <div>
                              {applicantInfo.photoUrl ? (
                                <img
                                  src={applicantInfo.photoUrl}
                                  alt="applicants"
                                  className={clsx(
                                    'h-[45px] w-[45px] min-w-[45px] rounded-full border-2 border-white object-cover'
                                  )}
                                />
                              ) : (
                                <p
                                  className={clsx(
                                    'flex h-[45px] w-[45px] min-w-[45px] items-center justify-center rounded-full'
                                  )}
                                  style={{
                                    backgroundColor:
                                      colorSet[(index + 1 - 1) % colorSet.length],
                                  }}
                                >
                                  {applicantInfo.firstName[0]}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col items-start">
                              <p className="text-sm font-bold">
                                {applicantInfo.firstName}{' '}
                                {applicantInfo.lastName}
                              </p>
                              <p className="text-sm">{applicantInfo.email}</p>
                            </div>
                          </div>
                        </div>
                        {/* <div>buttons</div> */}
                      </div>
                      {selected !== index &&
                        applicantInfo.isForwarded &&
                        !applicantInfo.isDiscarded && (
                          <p
                            className={clsx(
                              'item-center flex justify-center rounded-[4px] px-3 py-1 text-[8px] text-white',
                              'bg-primary'
                            )}
                          >
                            {'Forwarded'}
                          </p>
                        )}
                      {selected !== index &&
                        applicantInfo.isDiscarded &&
                        !applicantInfo.isForwarded && (
                          <p
                            className={clsx(
                              'item-center flex justify-center rounded-[4px] px-3 py-1 text-[8px] text-white',
                              'bg-red-600'
                            )}
                          >
                            {'Discarded'}
                          </p>
                        )}
                      {selected !== index &&
                        applicantInfo.isDiscarded &&
                        applicantInfo.isForwarded && (
                          <p
                            className={clsx(
                              'item-center flex justify-center rounded-[4px] px-3 py-1 text-[8px] text-white',
                              'bg-red-600'
                            )}
                          >
                            {'Discarded'}
                          </p>
                        )}
                    </button>
                  )
                })}
                {/* <button onClick={handleFormSubmit}>Submit</button> */}
              </div>

              {/* Interview details */}

              <div className="relative w-full">
                <div className="sticky top-0 border-b-4 bg-white pl-10 pt-5">
                  <InterviewNav
                    showInterviewDetails={showInterviewDetails}
                    nav={nav}
                    activeNavId={activeNavId}
                    setActiveNavId={setActiveNavId}
                    setShowInterviewDetails={setShowInterviewDetails}
                    showCancelButton={showCancelButton}
                  />
                </div>
                <div className="mainContent scrollbar-hide w-full overflow-y-scroll rounded-md bg-white px-10 ">
                  {activeNavId == '1' && (
                    <div>
                      <ApplicantProfileView
                        showExperience={true}
                        id={userId || ''}
                      />
                    </div>
                  )}

                  {activeNavId == '2' && (
                    <div className="px-10">
                      <div className="mt-8 h-[80%] w-full rounded-lg border border-green-600 bg-white px-8 pt-5">
                        {resumeUrl ? (
                          <div className="p-5">
                            <iframe
                              src={resumeUrl || '-'}
                              style={{ width: '100%', height: '725px' }}
                            ></iframe>
                          </div>
                        ) : (
                          <div className="grid  place-items-center py-80">
                            <Image
                              src="/assets/upload.png"
                              height={40}
                              width={40}
                              alt="school logo"
                              className="mt-10 mb-4"
                            />
                            <p className="text-xs">No resume to show</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {activeNavId == '3' && (
                    <div>
                      <InterviewSectionView
                        openFeedbackFormModal={openFeedbackFormModal}
                        setOpenFeedbackFormModal={setOpenFeedbackFormModal}
                        id={userId || ''}
                        interviewId={interviewId || ''}
                        jobId={usedJobId || ''}
                      />
                    </div>
                  )}
                  {activeNavId == '4' && (
                    <div>
                      <InterviewAnalysis
                        scoreCardData={scoreCardData || []}
                        interviewId={interviewId || ''}
                      />
                    </div>
                  )}
                  {activeNavId == '5' && (
                    <div className="mt-5">
                      <ActivityLog />
                    </div>
                  )}
                </div>
                <SelectedApplicantsActionModal
                  showSelectedApplicantsAction={showSelectedApplicantsAction}
                  selectedItems={selectedItems.length}
                  setSelectedItems={setSelectedItems}
                  handleShowConfirmDiscardModal={handleShowConfirmDiscardModal}
                  approvalLoading={approvalLoading}
                  isApproveStage={false}
                  handleShowMembersModal={handleShowMembersModal}
                  handleScheduleNewInterview={handleScheduleNewInterview}
                />
              </div>
            </div>
          </motion.div>

          <style>
            {`
                .mainContent{
                  height: calc(100vh - 6.8rem)
                }
                `}
          </style>
          <ConfirmForApprovalModal
            isLoading={approvalLoading}
            closeModal={() => setShowConfirmApprovalModal(false)}
            showDialog={showConfirmApprovalModal}
            handleSendForApproval={handleSendForApproval}
          />
          <AddMemberAccessToApproveModal
            setShowAddMembers={setShowAddMembers}
            showAddMember={showAddMembers}
            membersToBeGivenAccess={membersToBeGivenAccess}
            // handleShowMembersModal={handleShowMembersModal}
            approvalLoading={approvalLoading}
            handleSendForApproval={handleSendForApproval}
            handleAddMemberAccessChange={handleAddMemberAccessChange}
            selectedMembers={selectedMembers}
          />
          <ConfirmDiscardApplicantModal
            closeModal={() => setShowConfirmDiscardModal(false)}
            showDialog={showConfirmDiscardModal}
            jobId={jobId}
            userInfo={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
