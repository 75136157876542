import { useQueryClient } from '@tanstack/react-query'

import { useAuth } from '@/contexts/Authentication'

export const useHandleLogout = () => {
  const queryClient = useQueryClient()
  const { authState, authDispatch } = useAuth()

  const handleClearQueries = () => {
    queryClient.removeQueries()
  }

  const handleLogout = () => {
    if (authDispatch) {
      authDispatch({ type: 'LOGOUT', clearCache: handleClearQueries })
    }
  }

  return {
    authState,
    handleLogout,
  }
}
