import { Button, Label, Modal } from '@/components'
import clsx from 'clsx'
import { Warning2 } from 'iconsax-react'
import React from 'react'
import { InterviewScheduleOption } from '../types'

interface ModalAcceptOrDeclineProps {
  reason?: string
  isAccept: boolean
  isLoading: boolean
  showDialog: boolean
  handleChangeStatus: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, slots?: InterviewScheduleOption[]) => void
  handleReason?: (reason: string) => void
  closeModal: () => void
}

export const ModalAcceptOrDecline: React.FC<ModalAcceptOrDeclineProps> = ({
  reason,
  isAccept,
  isLoading,
  showDialog,
  handleReason,
  handleChangeStatus,
  closeModal,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      closeModal()
    }
  }
  return (
    <Modal
      className="mt-48"
      variant="middle"
      showDialog={showDialog}
      closeModal={handleClose}
    >
      <div className={clsx("mx-auto flex w-[560px] items-center overflow-y-auto border border-gray-100 bg-white px-8", handleReason ? 'h-[350px]' : 'h-[200px]')}>
        <div>
          <div className="flex items-start space-x-4">
            <Warning2 size="32" color="#FF8A65" />
            <p className="text-[15px]">
              Are you sure you want to {isAccept ? 'accept' : 'decline'}{' '}
              interview request?
            </p>
          </div>

          {handleReason && (
            <>
              <Label isRequired className="mt-5">Reason for declining</Label>
              <textarea
                className="w-full border border-gray-100 resize-none text-sm outline-none focus:border-gray-300 p-2"
                value={reason}
                onChange={(e) => handleReason(e.target.value)}
                rows={4}
                required
              ></textarea>
            </>
          )}
          <hr className={clsx("border-gray-450", handleReason ? 'my-6' : 'my-3')} />

          <div className="flex justify-end space-x-6">
            <Button
              disabled={isLoading}
              onClick={handleClose}
              variant="outline"
              size="xs"
            >
              Not Yet
            </Button>
            <Button
              className="bg-error-300"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleChangeStatus}
              size="xs"
            >
              {isAccept ? (
                <>{isLoading ? 'Accepting...' : 'Accept'}</>
              ) : (
                <>{isLoading ? 'Declining...' : 'Decline'}</>
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
