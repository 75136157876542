import { Button } from '@/components'
import clsx from 'clsx'
import { Warning2 } from 'iconsax-react'
import React from 'react'
// import { HiredApplicantModal } from './HiredApplicantModal'
import { AnimatePresence, motion } from 'framer-motion'
// import { JobApplicant } from '@/features/job-seeker'
import { backdropVariant, editProfile } from '@/utils'

interface ModalConfirmHireProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  showDialog: boolean
  setOpenHiredApplicantModal: React.Dispatch<React.SetStateAction<boolean>>
  applicantName: string
  jobTitle: string
//   data: JobApplicant
//   openHiredApplicantModal: boolean
  handleHireClick: () => void
}

export const ModalConfirmHire: React.FC<ModalConfirmHireProps> = ({
  isLoading,
  setShowModal,
  showDialog,
  applicantName,
  jobTitle,
//   data,
//   setOpenHiredApplicantModal,
  handleHireClick,
//   openHiredApplicantModal,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      setShowModal(false)
    }
  }

  return (
    <AnimatePresence>
      {showDialog && (
        <motion.div
          className="fixed top-0 left-0 z-[100] h-[100vh] w-full bg-black bg-opacity-40 text-left text-black"
          initial="initial"
          animate="show"
          exit="exit"
          variants={backdropVariant}
        >
          <motion.div
            className="scrollbar-hide fixed top-[30%] left-[50%] flex h-auto w-auto flex-col items-center overflow-scroll rounded-lg bg-white px-8 py-6"
            initial="initial"
            animate="show"
            exit="exit"
            variants={editProfile}
          >
            <div
              className={clsx(
                'mx-auto flex w-[560px] items-center overflow-y-auto border border-gray-100 bg-white py-6 px-8'
              )}
            >
              <div>
                <div className="flex items-start space-x-4">
                  <Warning2 size="32" color="#FF8A65" />
                  <p className="text-[15px]">
                    Are you sure you want to Hire {applicantName} as your new {jobTitle}
                  </p>
                </div>

                <div className="flex justify-end space-x-6">
                  <Button
                    disabled={isLoading}
                    onClick={handleClose}
                    variant="outline"
                    size="xs"
                  >
                    Not Yet
                  </Button>
                  <Button
                    className="bg-error-300"
                    isLoading={isLoading}
                    onClick={handleHireClick}
                    size="xs"
                  >
                    {isLoading ? 'Accepting...' : 'Accept'}
                  </Button>
                </div>
              </div>
            </div>
            {/* <HiredApplicantModal
              openHiredApplicantModal={openHiredApplicantModal}
              setOpenHiredApplicantModal={setOpenHiredApplicantModal}
              jobTitle={jobTitle}
              data={data}
            /> */}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
