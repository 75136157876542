import React, { useState, useRef, useEffect, useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Divide as Hamburger } from 'hamburger-react'
import { ArrowDown2, Heart, Notification } from 'iconsax-react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'
import clsx from 'clsx'

import { Notifications, Company, useCompanies } from '@/features/recruiter'
import { companyIDStorage, useHandleLogout } from '@/features/auth'
import { useComponentVisible, useUserInfo } from '@/hooks'
import { SearchModal } from '../Modals/SearchModal'
import { RecruiterProfileDropDown } from '.'
import { PublicProfile } from '..'
import {
  backdropVariant,
  containerChild2,
  containerVariant2,
  mobileNavbarVariant,
} from '@/utils'
import { CompanyIdContext } from '@/features/CompanyIdContext'

export const RecruiterNavDashboard = () => {
  const { setContextCompanyID } = useContext(CompanyIdContext)
  const { isComponentVisible, setIsComponentVisible } = useComponentVisible()
  const {
    ref: refDropdown,
    isComponentVisible: isComponentVisibleDropdown,
    setIsComponentVisible: setIsComponentVisibleDropdown,
  } = useComponentVisible()
  const {
    ref: refNotification,
    isComponentVisible: isNotification,
    setIsComponentVisible: setNotification,
  } = useComponentVisible()
  const [isOpen, setOpen] = useState(false)

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: React.ChangeEvent<HTMLInputElement> | any
    ) => {
      if (isOpen && ref?.current && !ref?.current?.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen])

  const { data, isLoading: isLoadingCompanies } = useCompanies()
  const { user } = useUserInfo()
  const { handleLogout } = useHandleLogout()
  const router = useRouter()
  const companyID = companyIDStorage.getCompanyID()
  const [openPublicProfile, setOpenPublicProfile] = React.useState(false)
  const [showSearch, setShowSearch] = React.useState(false)
  const [selectedCompany, setSelectedCompany] = React.useState(0)
  const [currentCompany, setCurrentCompany] = React.useState<
    Company | undefined
  >()

  const navLinksDash = [
    {
      name: 'Dashboard',
      url: '/recruiter/dashboard',
    },
    {
      name: 'Jobs',
      url: '/recruiter/dashboard/jobs',
    },
    // {
    //   name: 'Approval',
    //   url: '/recruiter/dashboard/approval',
    // },
    {
      name: 'Interviews',
      url: '/recruiter/dashboard/interview',
    },
    {
      name: 'Talent Pool',
      url: '/recruiter/dashboard/talent-pool',
    },
  ]

  const allCompanies = data?.data || []

  React.useEffect(() => {
    if (isLoadingCompanies) return
    if (companyID) {
      const index = allCompanies?.findIndex(
        (company) => company?.id === companyID
      )
      const company = allCompanies.find((company) => company.id === companyID)
      setSelectedCompany(index)
      setCurrentCompany(company)
    } else {
      const index = 0
      const companyId = allCompanies[index]?.id
      const company = allCompanies.find((company) => company.id === companyId)
      setSelectedCompany(index)
      setCurrentCompany(company)
      companyIDStorage.setCompanyID(companyId)
    }
  }, [companyID, isLoadingCompanies])

  return (
    <>
      <div className="absolute top-0 left-0 z-30 flex h-16 w-full items-center justify-between bg-white pl-1 pr-4 lg:hidden">
        <Hamburger size={20} rounded toggled={isOpen} toggle={setOpen} />
        <div className="pr-1">
          <button
            onClick={() =>
              setIsComponentVisibleDropdown(!isComponentVisibleDropdown)
            }
            className=" text flex items-center gap-4 border-[#E6E5E5] pl-8 text-left lg:border-l"
          >
            {isLoadingCompanies ? (
              '----'
            ) : (
              <>
                <img
                  className="profile-picture h-[35px] w-[35px] rounded-full lg:h-[40px] lg:w-[40px]"
                  src={currentCompany?.companyLogo || ''}
                  alt="profile picture"
                />
                {/* <p>
                  <span className="text-sm font-bold">
                    {currentCompany?.companyName}
                  </span>
                  <span className="block text-[13px]">
                    {currentCompany?.companyEmail}
                  </span>
                </p> */}
              </>
            )}
          </button>
          <div className="z-10 -ml-[230px]">
            <RecruiterProfileDropDown
              ref={refDropdown}
              company={currentCompany}
              showDropDown={isComponentVisibleDropdown}
              setShowDropDown={setIsComponentVisibleDropdown}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      </div>

      <div className="absolute top-0 left-0 z-40 hidden w-full border-b bg-white px-[50px] py-6 text-black lg:block">
        <div className=" mx-auto flex max-w-[1500px] items-center justify-between">
          <div className="flex justify-between gap-10">
            <Link href="/recruiter/dashboard">
              <div className="hidden lg:block lg:w-[150px] lg:cursor-pointer">
                <Image
                  src="/assets/logo-black.png"
                  alt="scout4Job-logo"
                  className="cursor-pointer"
                  height={21.65}
                  width={142.05}
                />
              </div>
            </Link>
          </div>

          {isLoadingCompanies ? (
            '...'
          ) : (
            <div className="relative">
              <button
                onClick={() => setIsComponentVisible(!isComponentVisible)}
                className="relative flex w-[222px] items-center justify-between gap-2 truncate rounded-[5px] bg-[#191919] px-2 py-2 text-xs text-white"
              >
                <span>{allCompanies[selectedCompany]?.companyName}</span>
                <ArrowDown2 size="12" color="#A6A6A6" className="mx-3" />
              </button>
              {isComponentVisible && (
                <div className="absolute left-0 top-5 z-20 mt-4 flex w-[222px] flex-col  rounded-lg border border-gray-100 bg-white py-3 pb-2 shadow-lg">
                  <div className="max-h-[400px] overflow-y-scroll">
                    {allCompanies &&
                      allCompanies?.map((company) => {
                        const handleSelectCompany = (id: string) => {
                          const index = allCompanies?.findIndex(
                            (company) => company?.id === companyID
                          )
                          setSelectedCompany(index)

                          setContextCompanyID(id)
                          companyIDStorage.setCompanyID(id)

                          setIsComponentVisible(false)
                        }

                        return (
                          <button
                            onClick={() =>
                              handleSelectCompany(company?.id || '')
                            }
                            className="flex w-full items-center justify-between overflow-x-hidden px-4 py-2 hover:bg-gray-100"
                            key={company.id}
                          >
                            <p className="flex items-center space-x-2">
                              <Image
                                height={30}
                                width={30}
                                src={
                                  (company?.companyLogo &&
                                    company?.companyLogo) ||
                                  ''
                                }
                                alt="logo"
                              />
                              <span className="truncate whitespace-nowrap text-sm font-[500] text-black">
                                {company.companyName}
                              </span>
                            </p>
                            {allCompanies[selectedCompany]?.id ===
                              company?.id && (
                              <span className="block h-3 w-3 rounded-full bg-black"></span>
                            )}
                          </button>
                        )
                      })}
                  </div>
                  <Link
                    href="/recruiter/onboarding/1"
                    className="mx-4 mt-3 block rounded-[5px] bg-primary bg-opacity-10 py-2 text-center text-xs font-[500] text-primary"
                  >
                    + New Company
                  </Link>
                </div>
              )}
            </div>
          )}

          <div className="flex items-center gap-10">
            {navLinksDash.map((link) => (
              <Link
                className={
                  router.asPath == link.url
                    ? 'nav-dashboard-recruiter-hover navactiveRecruiter text-sm font-bold text-black transition duration-300 ease-in-out hover:text-[black]'
                    : 'nav-dashboard-recruiter-hover text-sm  transition duration-300 ease-in-out hover:text-[black] '
                }
                href={link.url}
                key={link.name}
              >
                {link.name}
              </Link>
            ))}
          </div>
          <div className=" flex items-center gap-6">
            {/* <button onClick={() => setShowSearch(true)}>
              <Image
                src="/assets/rsearch.png"
                alt="search"
                className="cursor-pointer"
                height={24}
                width={24}
              />
            </button> */}
            <div className="relative">
              <button onClick={() => setNotification((prev) => !prev)}>
                {/* <Image
                  src="/assets/rnotification.png"
                  alt="notification"
                  className="cursor-pointer"
                  height={24}
                  width={24}
                /> */}
                <Notification size="24" color="#000" />
              </button>
              <Notifications ref={refNotification} show={isNotification} />
            </div>
            <button
              onClick={() =>
                setIsComponentVisibleDropdown(!isComponentVisibleDropdown)
              }
              className=" flex items-center gap-4 border-l border-[#E6E5E5] pl-8 text-left"
            >
              {isLoadingCompanies ? (
                '----'
              ) : (
                <>
                  <img
                    className="profile-picture h-[40px] w-[40px] rounded-full"
                    src={currentCompany?.companyLogo || ''}
                    alt=""
                  />
                  <p>
                    <span className="text-sm font-bold">
                      {currentCompany?.companyName}
                    </span>
                    <span className="block text-[13px]">
                      {currentCompany?.companyEmail}
                    </span>
                  </p>
                </>
              )}
            </button>

            <RecruiterProfileDropDown
              ref={refDropdown}
              company={currentCompany}
              setShowDropDown={setIsComponentVisibleDropdown}
              showDropDown={isComponentVisibleDropdown}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      </div>

      {/* mobile navbar */}

      <motion.div className="lg:hidden">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className={clsx(
                'absolute top-0 left-0 z-40 h-full w-full bg-black bg-opacity-40'
              )}
              initial="initial"
              animate="show"
              exit="exit"
              variants={backdropVariant}
            >
              <motion.div
                initial="initial"
                animate="show"
                exit="exit"
                variants={mobileNavbarVariant}
                className={clsx(
                  'absolute top-0 z-30 h-full w-[400px] max-w-[80%] flex-col bg-white  text-white lg:h-fit lg:w-full lg:max-w-full lg:py-6 '
                  // isOpen ? 'w-0 opacity-0' : 'w-[400px] opacity-100'
                )}
                ref={ref}
              >
                <div className="flex w-full items-center justify-between border-b-[0.1px] border-b-[#2B2B2B] px-5 py-5 lg:w-fit lg:justify-evenly">
                  <Link href="/recruiter/dashboard">
                    <div className=" lg:w-[150px] lg:cursor-pointer">
                      <Image
                        src="/assets/logo-black.png"
                        alt="scout4Job-logo"
                        className="cursor-pointer"
                        height={21.65}
                        width={142.05}
                      />
                    </div>
                  </Link>

                  {/* notification and saved jobs in mobile */}

                  <div className="flex items-end gap-4 lg:hidden">
                    <div className="flex items-center">
                      <button
                        className=""
                        onClick={() => setNotification((prev) => !prev)}
                      >
                        <Notification size="26" color="#000" variant="Bold" />
                      </button>
                      <Notifications
                        ref={refNotification}
                        show={isNotification}
                      />
                    </div>
                    <Link
                      className="block lg:hidden"
                      href="/recruiter/dashboard/talent-pool"
                      onClick={() => setOpen(false)}
                    >
                      <Heart size="26" color="#000" variant="Bold" />
                    </Link>
                  </div>
                </div>
                <motion.div
                  className="mt-10 flex flex-col items-start gap-10 px-5 lg:flex-row"
                  variants={containerVariant2}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  {navLinksDash.map((link) => (
                    <motion.button variants={containerChild2} key={link.name}>
                      <Link
                        onClick={() => setOpen(false)}
                        className={
                          router.asPath == link.url
                            ? 'nav-dashboard-recruiter-hover navactiveRecruiter text-sm font-bold text-black transition duration-300 ease-in-out hover:font-bold hover:text-[black]'
                            : 'nav-dashboard-recruiter-hover text-sm text-black transition duration-300 ease-in-out hover:font-bold hover:text-[black] '
                        }
                        href={link.url}
                      >
                        {link.name}
                      </Link>
                    </motion.button>
                  ))}
                </motion.div>{' '}
                <PublicProfile
                  user={user}
                  setOpenPublicProfile={setOpenPublicProfile}
                  openPublicProfile={openPublicProfile}
                />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {/* modal */}
      <PublicProfile
        user={user}
        setOpenPublicProfile={setOpenPublicProfile}
        openPublicProfile={openPublicProfile}
      />
      <SearchModal setShowSearch={setShowSearch} showSearch={showSearch} />
    </>
  )
}
